import { UserTimeAccess2Dto, UserTimeAccessDayDto } from '@treasury/api/channel';
import { DomainEntity } from '../../shared';
import { isEdited } from '../helpers/is-edited';
import { nullUserTimeAccess } from './null-user-time-access';

enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}

export type UserAccessDay = {
    label: string;
    value: DayOfWeek;
    startTime: { value: string; edited: boolean };
    endTime: { value: string; edited: boolean };
};

export class UserTimeAccess extends DomainEntity<UserTimeAccess2Dto> {
    constructor(dto: UserTimeAccess2Dto) {
        super(dto);
    }

    public createDefault() {
        return new UserTimeAccess(nullUserTimeAccess as any) as this;
    }

    get isRestricted() {
        return this.dto.timeRestricted;
    }

    get accessDays() {
        return this.dto.userTimeAccessDays?.map((day: UserTimeAccessDayDto) =>
            this.buildAccessDay(day)
        );
    }

    private buildAccessDay(day: UserTimeAccessDayDto) {
        return {
            label: DayOfWeek[day.dayOfWeek],
            value: day.dayOfWeek,
            startTime: {
                value: day.beginTime,
                edited: isEdited(
                    day.beginTime,
                    this.dto.userTimeAccessDaysPending
                        ? this.dto.userTimeAccessDaysPending[day.dayOfWeek].beginTime
                        : null
                ),
            },
            endTime: {
                value: day.endTime,
                edited: isEdited(
                    day.endTime,
                    this.dto.userTimeAccessDaysPending
                        ? this.dto.userTimeAccessDaysPending[day.dayOfWeek].endTime
                        : null
                ),
            },
        };
    }
}
