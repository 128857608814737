import '@vaadin/confirm-dialog';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { TmBaseComponent } from '..';

export const tagName = 'tm-confirm-dialog';
@customElement(tagName)
export class TmTextField extends TmBaseComponent {
    @property({ type: Boolean })
    opened = false;

    @property({ type: String })
    header?: string;

    @property({ type: String })
    message?: string | null;

    @property({ type: String })
    confirmText = 'Confirm';

    @property({ type: String })
    confirmTheme = 'primary';

    @property({ type: Boolean })
    cancel?: boolean;

    @property({ type: String })
    cancelText = 'Cancel';

    @property({ type: String })
    cancelTheme = 'tertiary';

    @property({ type: Boolean })
    reject?: boolean;

    @property({ type: String })
    rejectText = 'Reject';

    @property({ type: String })
    rejectTheme = 'error tertiary';

    render() {
        return html`
            <vaadin-confirm-dialog
                @confirm=${(e: CustomEvent) => {
                    const cancelEvent = new CustomEvent('confirm', {
                        detail: e.detail,
                    });
                    this.dispatchEvent(cancelEvent);
                }}
                @cancel=${(e: CustomEvent) => {
                    const cancelEvent = new CustomEvent('cancel', {
                        detail: e.detail,
                    });
                    this.dispatchEvent(cancelEvent);
                }}
                @reject=${(e: CustomEvent) => {
                    const cancelEvent = new CustomEvent('confirm', {
                        detail: e.detail,
                    });
                    this.dispatchEvent(cancelEvent);
                }}
                .opened=${this.opened}
                header=${this.header}
                message=${this.message}
                confirm-text=${this.confirmText}
                confirm-theme=${this.confirmTheme}
                cancel-button-visible=${ifDefined(this.cancel)}
                cancel-text=${this.cancelText}
                cancel-theme=${this.cancelTheme}
                reject-button-visible=${ifDefined(this.reject)}
                reject-text=${this.rejectText}
                reject-theme=${this.rejectTheme}
            >
            </vaadin-confirm-dialog>
        `;
    }

    static get styles() {
        return [
            css`
                vaadin-confirm-dialog-overlay::part(header) {
                    font-size: 2rem;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmTextField;
    }
}
