import { AbstractConstructor, enforceArray, exists } from '@treasury/utils';
import { adoptStyles, LitElement } from 'lit';
import baseStyles from '../styles/styles.css';

export const StyleMixin = <T extends AbstractConstructor<LitElement>>(superClass: T) => {
    abstract class StyleMixin extends superClass {
        connectedCallback() {
            super.connectedCallback();
            if (this.renderRoot && this.renderRoot instanceof ShadowRoot) {
                const subclass = Object.getPrototypeOf(this).constructor;
                const subclassStyles = exists(subclass.styles) ? enforceArray(subclass.styles) : [];
                adoptStyles(this.renderRoot, [baseStyles, ...subclassStyles]);
            }
        }
    }
    return StyleMixin;
};
