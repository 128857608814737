/* eslint-disable @treasury/no-date */
import {
    FrequencyDay,
    FrequencyRange,
    FrequencyType,
    MonthlyFrequency,
    OneTimeFrequency,
    TwiceMonthlyFrequency,
    WeeklyFrequency,
} from '@treasury/presentation/components/tm-frequency.types';

/**
 * Added frequency types
 * - every four weeks
 * - every other month
 * - every 3 months
 */
export enum BillPayFrequencyType {
    OneTime = 'OneTime',
    Weekly = 'Weekly',
    EveryTwoWeeks = 'EveryTwoWeeks',
    EveryFourWeeks = 'EveryFourWeeks',
    TwiceAMonth = 'TwiceAMonth',
    Monthly = 'Monthly',
    EveryOtherMonth = 'EveryOtherMonth',
    Quarterly = 'Quarterly',
    EveryThreeMonths = 'EveryThreeMonths',
    EverySixMonths = 'EverySixMonths',
    Yearly = 'Yearly',
}

export const BillPayFrequencyTypeLabels = {
    [FrequencyType.OneTime]: 'One Time',
    [FrequencyType.Weekly]: 'Weekly',
    [FrequencyType.EveryTwoWeeks]: 'Every Two Weeks',
    [BillPayFrequencyType.EveryFourWeeks]: 'Every Four Weeks',
    [FrequencyType.TwiceAMonth]: 'Twice a Month',
    [FrequencyType.Monthly]: 'Monthly',
    [BillPayFrequencyType.EveryOtherMonth]: 'Every Other Month',
    [FrequencyType.Quarterly]: 'Quarterly',
    [BillPayFrequencyType.EveryThreeMonths]: 'Every Three Months',
    [FrequencyType.EverySixMonths]: 'Every Six Months',
    [FrequencyType.Yearly]: 'Yearly',
};

export type EveryFourWeeks = FrequencyRange & {
    type: BillPayFrequencyType.EveryFourWeeks;
};

export type EveryOtherMonth = MonthlyFrequency & {
    type: BillPayFrequencyType.EveryOtherMonth;
};

export type EveryThreeMonths = FrequencyRange & {
    type: BillPayFrequencyType.EveryThreeMonths;
};

export interface BillPayWeeklyFrequency extends FrequencyRange {
    type: FrequencyType.Weekly | FrequencyType.EveryTwoWeeks | EveryFourWeeks;
    repeatOn: FrequencyDay;
}

export interface BillPayMonthly extends FrequencyRange {
    type: FrequencyType.Monthly | EveryOtherMonth | EveryThreeMonths;
    repeatOn?: number;
    repeatOnLastBusinessDay: boolean;
}

export interface BillPayFrequencyBase {
    type: BillPayFrequencyType;
    startDate: Date;
}

export interface BillPayFrequencyRange extends BillPayFrequencyBase {
    type:
        | BillPayFrequencyType.EveryFourWeeks
        | BillPayFrequencyType.EveryOtherMonth
        | BillPayFrequencyType.EveryThreeMonths
        | BillPayFrequencyType.EverySixMonths
        | BillPayFrequencyType.EveryTwoWeeks
        | BillPayFrequencyType.Monthly
        | BillPayFrequencyType.OneTime
        | BillPayFrequencyType.Quarterly
        | BillPayFrequencyType.TwiceAMonth
        | BillPayFrequencyType.Weekly
        | BillPayFrequencyType.Yearly;
    endDate?: Date;
    perpetual?: boolean;
}

export type BillPayFrequencyOptions =
    | OneTimeFrequency
    | BillPayFrequencyRange
    | WeeklyFrequency
    | MonthlyFrequency
    | TwiceMonthlyFrequency;
