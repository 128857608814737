import '@treasury/presentation/components/tm-text-field';
import { html } from 'lit';

export default {
    title: 'Verify Phone Number',

    renderBody(context: any) {
        return html`
            <style>
                p {
                    margin: 0px 0px 18px;
                }
                input {
                    font-size: 16px;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                    width: calc(100% - 20px);
                    margin-bottom: 18px;
                    padding: 8px;
                }
            </style>
            <p>
                ${context.verificationMethod.aMessageWasSentToYourDevice} We need to verify your
                identity for the security of the account. Please enter your One Time Password below.
            </p>
            <div>
                <tm-text-field
                    label="One Time Password"
                    aria-label="one time password"
                    .maxlength=${context.verificationMethod.oneTimePasswordLength}
                    allowedCharacters="[0-9]"
                    @value-changed=${(e: CustomEvent) => {
                        context.oneTimePassword = e.detail.value;
                    }}
                    @keydown=${(e: KeyboardEvent) => {
                        if (
                            e.code === 'Enter' &&
                            context.isOneTimePasswordValid &&
                            !context.isLoading
                        ) {
                            context.verify();
                        }
                    }}
                >
                </tm-text-field>
            </div>

            <div>Phone number: ${context.phoneNumber.formatted}</div>
        `;
    },

    renderActions(context: any) {
        return html`<div class="w-full flex justify-between">
            <tm-button
                importance="primary"
                @click=${context.verify}
                ?disabled=${!context.isOneTimePasswordValid || context.isLoading}
                >Verify Number
            </tm-button>
            <tm-button importance="tertiary" @click=${context.cancel}>Cancel</tm-button>
        </div>`;
    },
};
