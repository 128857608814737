import { ACCEPTED_ATTACHMENT_EXTENSIONS } from '@treasury/domain/channel/types/arp/constants';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/forms/tm-form-row';
import '@treasury/presentation/components/tm-text-area';
import { TmTextAreaValueChanged } from '@treasury/presentation/components/tm-text-area';
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../../../components/jhd-icon';
import '../../../../components/jhd-text-input';
import './check-exception-file-upload';

export const tagName = 'check-exception-comment-editor';
@customElement(tagName)
export class CheckExceptionCommentEditor extends TmBaseComponent {
    @property()
    commentText = '';

    @state()
    attachment?: File;

    addAttachment() {
        const attachmentEvent = new CustomEvent('attachment-changed', {
            detail: this.attachment,
        });
        this.dispatchEvent(attachmentEvent);
    }

    textChangeEventHandler(e: TmTextAreaValueChanged) {
        const valueChangedEvent = new CustomEvent('value-changed', {
            detail: e.detail.value,
        });
        this.dispatchEvent(valueChangedEvent);
    }

    renderTextarea() {
        return html`
            <tm-form-row label="Comment">
                <div class="relative flex flex-col justify-start w-full">
                    <tm-text-area
                        class="w-full"
                        @value-changed=${this.textChangeEventHandler}
                        .maxLength=${50}
                        .value=${this.commentText}
                    ></tm-text-area>
                    <div class="pr-4 pb-1 text-right w-11/12">
                        <span class="text-[--secondary-text-color] text-xs"
                            >${this.commentText?.length ?? 0}/50</span
                        >
                    </div>
                </div>
            </tm-form-row>
        `;
    }

    renderAttachment() {
        return html`
            <check-exception-file-upload
                .icon="${true}"
                buttonText="Add Attachment"
                buttonIcon="paperclip"
                .accepted=${ACCEPTED_ATTACHMENT_EXTENSIONS}
                @filesUploaded=${(e: CustomEvent<{ files: File[] }>) => {
                    [this.attachment] = e.detail.files;
                    this.addAttachment();
                }}
            ></check-exception-file-upload>
        `;
    }

    render() {
        return html`
            <div class="flex-col w-full items-center">
                ${this.renderTextarea()}
                <div>${this.renderAttachment()}</div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: CheckExceptionCommentEditor;
    }
}
