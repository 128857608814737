import { NotificationsService } from '@treasury/domain/channel/services/notifications/notifications-service';
import { NotificationService, TmContainer } from '@treasury/presentation';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-confirm-dialog';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-table-header';
import '@treasury/presentation/components/tm-tabs';
import { InjectProperty } from '@treasury/utils';
import { PropertyValueMap, css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { filterTableResults } from '../../../utilities/table-result-filter';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import '../partials/notifications-filter-sheet';
import '../partials/notifications-table';
import { Notification } from '../types/notification.type';

export const tagName = 'notifications-container';
@customElement(tagName)
export class NotificationsContainer extends TmContainer {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @state()
    private allNotifications: Notification[] = [];

    @state()
    private readNotifications: Notification[] = [];

    @state()
    private unreadNotifications: Notification[] = [];

    @state()
    private importantNotifications: Notification[] = [];

    @state()
    private activeNotifications: Notification[] = [];

    @state()
    private tabs = [
        { label: 'All', id: 'filteredNotifications' },
        { label: 'Unread', id: 'unreadNotifications' },
        { label: 'Read', id: 'readNotifications' },
        { label: 'Important', id: 'importantNotifications' },
    ];

    @state()
    private activeTab = 'filteredNotifications';

    @state()
    private activeTabLabel = 'All';

    @state()
    private selectedNotifications: Notification[] = [];

    @state()
    private filteredNotifications: Notification[] = [];

    @state()
    private filterString: CustomEvent<any> | undefined;

    @state()
    private matchingActiveNotifications: Notification[] = [];

    @state()
    private notificationsFilterSheetOpen = false;

    @state()
    private selectedNotificationTypes: string[] = [];

    @state()
    private noFilterResults = false;

    @state()
    private notificationsPendingDelete: Notification[] = [];

    async firstUpdated() {
        this.headerService.configure({ title: 'Notifications' });
        await this.fetchNotifications();
    }

    async fetchNotifications() {
        await this.tryFetch(
            () => NotificationsService.getNotifications(),
            n => {
                this.allNotifications = n;
                this.activeNotifications = this.allNotifications;
                this.filteredNotifications = this.allNotifications;
                this.filterNotifications();
                this.splitNotificationsByState();
            },
            (err: any) => {
                this.notificationService.renderError(err);
            }
        );
    }

    protected updated(changed: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        if (changed.has('activeNotifications')) {
            this.matchingActiveNotifications = this.activeNotifications;
            if (this.filterString) {
                this.filterResults(this.filterString);
            }
        }
    }

    splitNotificationsByState() {
        this.readNotifications = this.filteredNotifications.filter(
            (notification: Notification) => notification.isRead
        );
        this.unreadNotifications = this.filteredNotifications.filter(
            (notification: Notification) => !notification.isRead
        );
        this.importantNotifications = this.filteredNotifications.filter(
            (notification: Notification) => notification.isImportant
        );
        this.activeNotifications = this[this.activeTab as keyof this] as unknown as Notification[];
    }

    filterNotifications() {
        this.loading = true;
        this.filteredNotifications = this.allNotifications.filter((n: Notification) => {
            if (!this.selectedNotificationTypes.length) return true;
            return this.selectedNotificationTypes.includes(n.functionality);
        });
        if (this.filteredNotifications.length) {
            this.splitNotificationsByState();
            this.noFilterResults = false;
        } else {
            this.noFilterResults = true;
        }
        this.loading = false;
        this.notificationsFilterSheetOpen = false;
    }

    async updateNotificationsState(
        notifications: Notification[],
        action: 'read' | 'unread' | 'delete'
    ) {
        try {
            const notificationIds = notifications.map(notification => ({
                id: notification.id,
            }));
            if (action === 'delete') {
                this.loading = true;
                await NotificationsService.deleteNotifications(notificationIds);
                await this.fetchNotifications();
            } else {
                const isRead = action === 'read';
                await NotificationsService.updateNotificationReadStatus(notificationIds, isRead);
                await this.fetchNotifications();
            }
        } catch (err) {
            this.notificationService.renderError(err as Error);
        } finally {
            this.loading = false;
        }
    }

    filterResults(e: CustomEvent) {
        this.matchingActiveNotifications = filterTableResults(e, this.activeNotifications, [
            'createdOn',
            'detail',
            'functionality',
            'subject',
        ]);
    }

    onFiltersClick() {
        this.notificationsFilterSheetOpen = true;
    }

    async onConfirmNotificationDelete() {
        await this.updateNotificationsState(this.notificationsPendingDelete, 'delete');
        this.notificationsPendingDelete = [];
    }

    renderTableHeader() {
        const tabLabel = this.tabs.find(tab => tab.id === this.activeTab)?.label;
        return html`
            <tm-table-header
                .placeholder=${`Search ${tabLabel}`}
                .filterButton=${true}
                @filterButtonClick=${() => this.onFiltersClick()}
                @filterStringChange=${(e: CustomEvent) => {
                    this.filterString = e;
                    this.filterResults(e);
                }}
            ></tm-table-header>
        `;
    }

    renderTabs() {
        return html` <tm-tabs
            .activeTab=${this.activeTab}
            .tabs=${this.tabs}
            @switchTab=${(event: CustomEvent) => {
                this.fetchNotifications();
                this.activeTab = event.detail.activeTab;
                this.activeTabLabel = event.detail.label;
                this.activeNotifications = this[
                    this.activeTab as keyof this
                ] as unknown as Notification[];
            }}
        ></tm-tabs>`;
    }

    renderNoNotificationsMessage() {
        if (
            !this.activeTabLabel ||
            this.matchingActiveNotifications.length ||
            this.noFilterResults ||
            this.loading
        )
            return nothing;
        return html` <p class="text-center p-3">
            <em
                >There are no
                ${this.activeTabLabel !== 'All' ? `${this.activeTabLabel.toLowerCase()}` : ''}
                notifications.</em
            >
        </p>`;
    }

    renderNotificationsTable() {
        if (this.noFilterResults) return nothing;
        return html` <notifications-table
            .notifications=${this.matchingActiveNotifications}
            .loading=${this.loading}
            @selected-items-changed=${(e: CustomEvent) => {
                this.selectedNotifications = e.detail;
            }}
            @opened-item-changed=${(e: CustomEvent) => {
                this.updateNotificationsState(e.detail, 'read');
            }}
            @opened-item-deleted=${(e: CustomEvent) => {
                this.notificationsPendingDelete = [e.detail];
            }}
        ></notifications-table>`;
    }

    renderDeleteConfirmDialog() {
        const notificationsLabel =
            this.notificationsPendingDelete.length === 1
                ? `this notification`
                : `these notifications`;
        return html`<tm-confirm-dialog
            .opened=${!!this.notificationsPendingDelete.length}
            message=${`Are you sure you want to delete ${notificationsLabel}?`}
            cancel
            @cancel=${() => (this.notificationsPendingDelete = [])}
            confirmText="Delete"
            confirmTheme="primary error"
            @confirm=${async () => this.onConfirmNotificationDelete()}
        >
        </tm-confirm-dialog>`;
    }

    renderFooter() {
        if (this.noFilterResults || !this.matchingActiveNotifications.length) return nothing;
        return html` <tm-footer
            direction="row"
            visible
            .buttonConfig=${[
                {
                    text: 'Mark as Read',
                    onClick: () =>
                        this.updateNotificationsState(this.selectedNotifications, 'read'),
                    disabled: this.selectedNotifications.length === 0 || this.loading,
                    importance: 'secondary',
                    size: 'small',
                    classes: 'mx-1',
                },
                {
                    text: 'Mark as Unread',
                    onClick: () =>
                        this.updateNotificationsState(this.selectedNotifications, 'unread'),
                    disabled: this.selectedNotifications.length === 0 || this.loading,
                    importance: 'secondary',
                    size: 'small',
                    classes: 'mx-1',
                },
                {
                    text: 'Delete',
                    onClick: () => (this.notificationsPendingDelete = this.selectedNotifications),
                    disabled: this.selectedNotifications.length === 0 || this.loading,
                    importance: 'secondary',
                    error: true,
                    size: 'small',
                    classes: 'mx-1',
                },
            ] as ButtonConfig[]}
        ></tm-footer>`;
    }

    renderNoFilterResultsMessage() {
        if (!this.noFilterResults) return nothing;
        return html` <p class="text-center p-3">
            <em
                >No results for found for the selected notification
                ${this.selectedNotificationTypes.length === 1 ? 'type' : 'types'}. Please select
                another notification type.</em
            >
        </p>`;
    }

    renderNotificationFilterSheet() {
        return html`<notifications-filter-sheet
            .open=${this.notificationsFilterSheetOpen}
            @close=${() => {
                this.notificationsFilterSheetOpen = false;
            }}
            @applyFilters=${(e: CustomEvent) => {
                this.selectedNotificationTypes = e.detail.notificationTypes;
                this.filterNotifications();
            }}
        ></notifications-filter-sheet>`;
    }

    render() {
        return html`<tm-body .padding=${false}
                >${this.renderTabs()} ${this.renderTableHeader()}
                ${this.renderNoFilterResultsMessage()} ${this.renderNoNotificationsMessage()}
                ${this.renderNotificationsTable()}</tm-body
            >
            ${this.renderFooter()}
            ${this.renderNotificationFilterSheet()}${this.renderDeleteConfirmDialog()}`;
    }

    static get styles() {
        return [
            css`
                :host {
                    position: relative;
                    height: 100%;
                    overflow: hidden;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: NotificationsContainer;
    }
}
