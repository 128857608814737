/* eslint-disable @treasury/no-date */
import { FrequencyModelDto } from '@treasury/api/channel';
import { DashboardService } from '@treasury/domain/channel/services/dashboard/dashboard-service';
import { UsersService } from '@treasury/domain/channel/services/users/users-service';
import { AuditModel } from '@treasury/domain/loans';
import { TmBaseComponent } from '@treasury/presentation';
import { actionIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-footer';
import { InjectProperty } from '@treasury/utils';
import { format } from 'date-fns/format';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/jhd-status';
import '../../pending-approvals/partials/approve-or-reject-prompt';
import { TransferPaymentDto } from '../types/transfer-payment-dto.type';

export const tagName = 'transfer-detail-bottom-sheet';
@customElement(tagName)
export class TransferDetailBottomSheet extends TmBaseComponent {
    @property({ type: Boolean })
    bottomSheetOpen = false;

    @InjectProperty()
    private declare dashboardService: DashboardService;

    @property({ type: Object })
    transfer = {} as TransferPaymentDto;

    @state()
    auditOpen = false;

    @state()
    approversOpen = false;

    @state()
    loading = false;

    @state()
    selectedAction = '';

    @state()
    showApproveOrRejectPrompt = false;

    private async approveOrReject({
        items,
        paymentType = 'transfer',
    }: {
        items: any[];
        paymentType: string;
    }) {
        try {
            this.loading = true;
            await this.dashboardService.approveOrRejectPayment(items, paymentType);
            this.dispatchEvent(new CustomEvent('update-transfer'));
            this.dispatchEvent(
                new CustomEvent('approve-or-reject', { composed: true, bubbles: true })
            );
            this.bottomSheetOpen = false;
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    private toggleAudit() {
        this.auditOpen = !this.auditOpen;
    }

    renderApprovalInformation() {
        if (this.transfer?.status !== 'PendingApproval') return nothing;
        return html`<treasury-pending-approval-template
            class="mb-2"
            .payment=${this.transfer}
            .approvals=${this.transfer.completedApprovals}
            .eligibleApproversFunction=${async () =>
                (await UsersService.fetchAvailableApprovers({
                    approvalEntity: 'internalTransfer',
                    productId: this.transfer.id,
                    updatedBy: parseInt(this.transfer.updatedBy),
                })) as unknown as string[]}
        ></treasury-pending-approval-template>`;
    }

    renderTransferDetails() {
        if (!this.transfer) return nothing;
        const transferStatus =
            this.transfer.status === 'PendingApproval' ? 'Pending Approval' : this.transfer.status;
        return html`<div class="transfer-detail-wrapper pl-6 pr-4 bg-[--primary-background]">
            <div class="detail-header flex items-start justify-between py-4">
                <div class="detail-amount">
                    <div class="text-lg font-medium mb-1">Transfer</div>
                    <div class="text-3xl font-normal">
                        ${new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }).format(this.transfer.amount)}
                    </div>
                </div>

                <div class="detail-status flex items-center my-1 text-xs">
                    <jhd-status .status=${this.transfer.status} showLabel></jhd-status>
                </div>
            </div>
            <div class="detail-content py-4 border-t border-dashed border-[--border-color]">
                <jhd-data-field
                    .label=${'From'}
                    .value=${this.transfer.fromAccount.accountDisplayLabel}
                ></jhd-data-field>
                <jhd-data-field
                    .label=${'To'}
                    .value=${this.transfer.toAccount.accountDisplayLabel}
                ></jhd-data-field>
                <jhd-data-field
                    .label=${'Frequency'}
                    .value=${this.transfer.parentFrequencyModel
                        ? (this.transfer.parentFrequencyModel as FrequencyModelDto).summary
                        : this.transfer.frequencyModel.summary}
                ></jhd-data-field>
                <jhd-data-field
                    .label=${'Transfer Date'}
                    .value=${format(new Date(this.transfer.transferDate), 'P')}
                ></jhd-data-field>
                <jhd-data-field
                    .label=${'Transaction ID'}
                    .value=${this.transfer.transactionId}
                ></jhd-data-field>
                <jhd-data-field
                    .label=${'Creation Date'}
                    .value=${format(new Date(this.transfer.createdDate), 'P')}
                ></jhd-data-field>
                <jhd-data-field
                    .label=${'Created By'}
                    .value=${this.transfer.audit[this.transfer.audit.length - 1].createdBy}
                ></jhd-data-field>
                <jhd-data-field .label=${'Memo'} .value=${this.transfer.memo}></jhd-data-field>
                ${this.renderAudit()}
            </div>
        </div>`;
    }

    renderAuditData() {
        if (!this.auditOpen) return nothing;
        const auditList = this.transfer.audit.map(
            (auditItem: AuditModel) =>
                html`<div class="audit-item pb-2">
                    <span class="audit-date mr-1">${format(new Date(auditItem.date), 'Pp')}</span>
                    <span>${auditItem.createdBy} : ${auditItem.comments}</span>
                </div>`
        );
        return html`<div class="mt-6 pb-2">
            <div class="pb-2 font-medium">Audit</div>
            <div class="max-h-36 overflow-y-auto font-normal text-sm">${auditList}</div>
        </div>`;
    }

    renderAudit() {
        if (!this.transfer?.audit) return nothing;
        const auditButtonText = this.auditOpen ? 'Hide Audit' : 'View Audit';
        return html`${this.renderAuditData()}
            <tm-button class="mt-2" @click=${() => this.toggleAudit()}>
                ${auditButtonText}
            </tm-button> `;
    }

    renderApproveOrRejectPrompt() {
        return html`<approve-or-reject-prompt
            .selectedAction=${this.selectedAction}
            .selectedApprovals=${[this.transfer]}
            .paymentType=${'transfer'}
            .showApproveOrRejectPrompt=${this.showApproveOrRejectPrompt}
            @approve-or-reject=${(e: CustomEvent) => {
                const selectedState = this.selectedAction === 'approve' ? 'Approved' : 'Rejected';
                this.approveOrReject({
                    items: [{ ...this.transfer, selectedState }],
                    paymentType: 'transfer',
                });
                this.showApproveOrRejectPrompt = false;
            }}
            @close=${() => {
                this.showApproveOrRejectPrompt = false;
            }}
        ></approve-or-reject-prompt>`;
    }

    renderButtonBar() {
        if (!this.transfer) return nothing;
        const pendingApproval = this.transfer.status === 'PendingApproval';
        if (!pendingApproval) return nothing;
        const canApprove = this.transfer?.permissions.some(
            (p: { permission: string }) => p.permission === 'Approve'
        );
        // NOTE: API doesn't return rejected permission ever.
        // Have to make the assumption that if a user can approve, they can reject
        const canReject = this.transfer?.permissions.some(
            (p: { permission: string }) => p.permission === 'Approve'
        );

        const rejectButton = canReject
            ? html`<tm-button
                  error
                  importance="primary"
                  size="large"
                  class="w-full m-1"
                  @click=${() => {
                      this.selectedAction = 'reject';
                      this.showApproveOrRejectPrompt = true;
                  }}
                  >Reject</tm-button
              >`
            : nothing;
        const approveButton = canApprove
            ? html`<tm-button
                  success
                  importance="primary"
                  size="large"
                  class="w-full m-1"
                  @click=${() => {
                      this.selectedAction = 'approve';
                      this.showApproveOrRejectPrompt = true;
                  }}
                  >Approve</tm-button
              >`
            : nothing;

        if (!canApprove && !canReject) return nothing;
        return html`<tm-footer direction="row"> ${rejectButton}${approveButton} </tm-footer>`;
    }

    renderBlockingLoader() {
        if (!this.loading) return nothing;
        return html`<tm-blocking-loader></tm-blocking-loader>`;
    }

    render() {
        return html`<tm-bottom-sheet
            .open=${this.bottomSheetOpen}
            .flex=${true}
            @close=${() => {
                this.bottomSheetOpen = false;
                this.dispatchEvent(new CustomEvent('close'));
            }}
            ><span slot="header-center">Transfer Detail</span
            ><span slot="header-right" class="header-icon">${actionIcon}</span>
            <tm-body .background=${false} .padding=${false}
                >${this.renderApprovalInformation()}${this.renderTransferDetails()}${this.renderApproveOrRejectPrompt()}</tm-body
            >
            ${this.renderButtonBar()}${this.renderBlockingLoader()}
        </tm-bottom-sheet>`;
    }

    static get styles() {
        return [
            css`
                .detail-status svg {
                    display: inline-block;
                }
                .transfer-data-wrapper {
                    background-color: #fff;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    min-height: 300px;
                }
                .detail-amount,
                .status {
                    color: var(--header-text-color);
                }
                .header-icon path {
                    fill: var(--nav-text-color);
                }
                .audit-date {
                    color: var(--secondary-text-color, #444444);
                }
                .max-h-36 {
                    max-height: 9rem;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TransferDetailBottomSheet;
    }
}
