import { Injectable } from '@treasury/utils/dependency-injection';
import { dateDistance } from '@treasury/utils/functions';
/* eslint-disable import/extensions */
import {
    CompanyAccountModelDto,
    CompanyAccountsClient,
    DashboardAccountsGroupModelDto,
    DashboardClient,
    LookupModelDto,
    NotificationModelDto,
    NotificationsClient,
    ProcessingCutoffDto,
} from '@treasury/api/channel';
import { TmHttpClient } from '@treasury/core/http';
import { Account } from './account.entity';
import { PaymentType } from './types/payment.types';

@Injectable()
export class DashboardService {
    constructor(
        private dashboardClient: DashboardClient,
        private notificationsClient: NotificationsClient,
        private companyAccountsClient: CompanyAccountsClient,
        private readonly http: TmHttpClient
    ) {}

    async getMyAccounts() {
        const response = await this.dashboardClient.dashboardGetAccountGroups();
        return response.data.map((accountGroup: DashboardAccountsGroupModelDto) => {
            const accounts = accountGroup?.accounts?.map(
                (account: CompanyAccountModelDto) => new Account(account)
            );
            return {
                name: accountGroup.name,
                accounts,
            };
        });
    }

    getPendingApprovalPaymentsByType(type: PaymentType) {
        return this.dashboardClient.dashboardGetPaymentPendingApproval({ type });
    }

    // NOTE: didn't see this one in the API project...
    logNotificationActivity() {
        return this.http.request(`navigations/auditUserActivity/notifications`, {
            method: 'POST',
        });
    }

    async markNotificationsAsRead(notificationIds: LookupModelDto[]) {
        return this.notificationsClient.notificationsSetRead({ value: true }, notificationIds);
    }

    async archiveNotifications(notificationIds: LookupModelDto[]) {
        return this.notificationsClient.notificationsArchive(notificationIds);
    }

    async getRecentNotifications() {
        const response = await this.notificationsClient.notificationsGetRecent();
        if (!response.data.topMessages) return {};
        const { topMessages, totalUnreadCount } = response.data;
        return {
            notifications: topMessages.map((notification: NotificationModelDto) => ({
                ...notification,
                // move formatting to view model
                // eslint-disable-next-line @treasury/no-date
                createdOn: `${dateDistance(new Date(notification.createdOn), new Date())} ago`,
            })),
            count: totalUnreadCount,
        };
    }

    async getCutoffTimes() {
        const timeConversion = (time: any) => {
            let formattedTime = time;
            formattedTime = formattedTime
                .toString()
                .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [formattedTime];
            if (formattedTime.length > 1) {
                formattedTime = formattedTime.slice(1);
                formattedTime[4] = +formattedTime[0] < 12 ? ' AM' : ' PM';
                formattedTime[0] = +formattedTime[0] % 12 || 12;
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const secondsRemoved = formattedTime.splice(3, 1);
            return formattedTime.join('');
        };
        const response = await this.companyAccountsClient.companyAccountsGetCutoffTimes();
        const cutoffTimes = response.data.processingCutoffs?.map((cutoff: ProcessingCutoffDto) => ({
            cutoffTime: timeConversion(cutoff.cutoffTime),
            label: cutoff.productType,
        }));

        return {
            cutoffTimes,
            timeZone: response.data.timeZone,
        };
    }
}
