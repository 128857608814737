import { AlarmClock } from '@treasury/alarm-clock';
import { html } from 'lit';

export const ACH_EXCEPTION_START = 'achExceptionStart';
export const ACH_EXCEPTION_CUTOFF = 'achExceptionCutoff';
export const ACH_EXCEPTION_CUTOFF_MINUS_30 = 'achExceptionCutoff-30Minutes';

export function listenToCutoffs(context: any, handler: () => void) {
    const fiClock = AlarmClock.getInstance();
    context.listenTo(fiClock, ACH_EXCEPTION_START, handler);
    context.listenTo(fiClock, ACH_EXCEPTION_CUTOFF, handler);
}

function renderOutsideCutoffTime(message: string) {
    return html`
        <div class="outside-cutoff-time">
            <p class="message">${message}</p>
        </div>
    `;
}

export function evalAfterCutoff() {
    const fiClock = AlarmClock.getInstance();
    if (fiClock.isAfter(ACH_EXCEPTION_CUTOFF)) {
        return renderOutsideCutoffTime(
            `ACH exception items are disabled because the current time is past the ${fiClock.getAlarm(
                ACH_EXCEPTION_CUTOFF
            )} Cut-Off time (${fiClock.timeZone})`
        );
    }
    return undefined;
}

export function evalBeforeCutoff() {
    const fiClock = AlarmClock.getInstance();
    if (fiClock.isBefore(ACH_EXCEPTION_START)) {
        return renderOutsideCutoffTime(
            `ACH exception items are not available at this time. Please try again after ${fiClock.getAlarm(
                ACH_EXCEPTION_START
            )} (${fiClock.timeZone}).`
        );
    }
    return undefined;
}
