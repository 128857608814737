/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { ErrorHttpContentDto, InstitutionCheckExceptionReturnReasonCollectionDto } from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class InstitutionCheckExceptionReturnReasonClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags InstitutionCheckExceptionReturnReason
     * @name InstitutionCheckExceptionReturnReasonGetInstitutionCheckExceptionReturnReason
     * @summary Retrieve all of the Check Exception Return Reasons for the current users institution.
     * @request GET:/InstitutionCheckExceptionReturnReason
     */
    institutionCheckExceptionReturnReasonGetInstitutionCheckExceptionReturnReason = (
        params: RequestParams = {}
    ) =>
        this.http.request<InstitutionCheckExceptionReturnReasonCollectionDto, ErrorHttpContentDto>({
            path: `/InstitutionCheckExceptionReturnReason`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags InstitutionCheckExceptionReturnReason
     * @name InstitutionCheckExceptionReturnReasonSaveInstitutionCheckExceptionReturnReason
     * @summary Saves the collection of Check Exception return reasons and returns an updated version of the collection.
     * @request POST:/InstitutionCheckExceptionReturnReason
     */
    institutionCheckExceptionReturnReasonSaveInstitutionCheckExceptionReturnReason = (
        returnReasons: InstitutionCheckExceptionReturnReasonCollectionDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<InstitutionCheckExceptionReturnReasonCollectionDto, ErrorHttpContentDto>({
            path: `/InstitutionCheckExceptionReturnReason`,
            method: 'POST',
            body: returnReasons,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
