/* eslint-disable no-use-before-define */
import { ObservationSource } from '@treasury/utils';
import { DiContainer, Injectable } from '@treasury/utils/dependency-injection';
import { MitekCaptureResult } from './mitek-capture-result';
import * as mitekScienceSDK from './mitek/mitek-science-sdk';

@Injectable()
export class CheckCaptureService {
    private readonly displayStartedStream = new ObservationSource<boolean>();

    private readonly processingStartedStream = new ObservationSource<boolean>();

    private readonly captureCompletedStream = new ObservationSource<MitekCaptureResult>();

    public readonly displayStarted$ = this.displayStartedStream.toObservable();

    public readonly processingStarted$ = this.processingStartedStream.toObservable();

    public readonly captureCompleted$ = this.captureCompletedStream.toObservable();

    static getInstance() {
        return getServiceInstance();
    }

    public async startCapture(
        documentType: 'CHECK_FRONT' | 'CHECK_BACK',
        videoContainerId?: string,
        autoCapture = true
    ) {
        mitekScienceSDK.on('CAMERA_DISPLAY_STARTED', () => {
            this.displayStartedStream.emit(true);
        });

        mitekScienceSDK.on('FRAME_PROCESSING_STARTED', () => {
            this.processingStartedStream.emit(true);
        });

        mitekScienceSDK.cmd('CAPTURE_AND_PROCESS_FRAME', {
            mode: autoCapture ? 'AUTO_CAPTURE' : 'MANUAL_CAPTURE',
            documentType,
            mitekSDKPath: '/mitek/mitekSDK',
            options: {
                qualityPercent: 80,
                videoContainerId,
            },
        });

        mitekScienceSDK.on('FRAME_CAPTURE_RESULT', (result: MitekCaptureResult) => {
            this.captureCompletedStream.emit(result);
        });
    }

    public async stopCapture() {
        mitekScienceSDK.cmd('SDK_STOP');
        this.displayStartedStream.emit(false);
    }

    public async manualCapture() {
        mitekScienceSDK.cmd('CAPTURE_FRAME');
    }
}

async function getServiceInstance() {
    return (await DiContainer.getInstance()).get(CheckCaptureService);
}
