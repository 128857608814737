/* eslint-disable @treasury/no-date */
import { clone } from '@treasury/utils/functions';
import { nullTransferTemplateDetails } from './transfer-template-details.dto';

export class TransferTemplateDetails {
    // eslint-disable-next-line no-useless-constructor
    constructor(protected dto = clone(nullTransferTemplateDetails)) {}

    public get id() {
        return this.dto.id;
    }

    public get name() {
        return this.dto.templateName;
    }

    public set name(name) {
        this.dto.templateName = name;
    }

    public get type() {
        return this.dto.transferType;
    }

    public get transferFromAccount() {
        return this.dto.transferFromAccount;
    }

    public get transferToAccount() {
        return this.dto.transferToAccount;
    }

    public get memo() {
        return this.dto.memo;
    }

    public get transferAccounts() {
        return this.dto.templateTransferInfo;
    }

    public get totalTransferAmount() {
        return this.dto.totalTransferAmount || 0;
    }

    public get status() {
        return this.dto.status;
    }

    public get audit() {
        return this.dto.audit;
    }

    public get updatedBy() {
        return this.dto.updatedBy || 0;
    }

    public get permissions() {
        return this.dto.permissions || [];
    }
}
