/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    AccountModelDto,
    BaiConfigurationModelDto,
    CompanyUserChallengeSettingsModelDto,
    DownloadRequestDto,
    EntitlementGroupModelDto,
    ErrorHttpContentDto,
    FiPermissionModelDto,
    GetAchCompanyByMatchTypeParametersResponseDto,
    HealthCheckActionResponseDto,
    HolidayCalendarModelDto,
    InstitutionMessagingCenterModelDto,
    InstitutionModelDto,
    LoginIdPasswordParametersModelDto,
    LookupModelDto,
    ModelStateDictionaryDto,
    NavigationMenuModelDto,
    NotificationConfigurationModelDto,
    OkResultDto,
    ProcessingTimesConfigurationModelDto,
    ProductFeatureProcessingTimesModelDto,
    QboSettingsModelDto,
    ReportFilterBaseDto,
    ReportModelBaseDto,
    RoleModelDto,
    SecCodeModelDto,
    TermsAndConditionsModelDto,
    TransactionCodeGroupModelDto,
    TransactionCodeModelDto,
    UserApprovalSettingsModelDto,
    WireCompanyModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class ApiClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags TermsAndConditions
     * @name TermsAndConditionsGet
     * @summary Retrieves the Terms and Conditions.
     * @request GET:/api/TermsAndConditions
     */
    termsAndConditionsGet = (params: RequestParams = {}) =>
        this.http.request<TermsAndConditionsModelDto, any>({
            path: `/api/TermsAndConditions`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags TermsAndConditions
     * @name TermsAndConditionsPost
     * @summary Posts terms and conditions.
     * @request POST:/api/TermsAndConditions
     */
    termsAndConditionsPost = (
        model: TermsAndConditionsModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<TermsAndConditionsModelDto, any>({
            path: `/api/TermsAndConditions`,
            method: 'POST',
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Health
     * @name HealthGet
     * @summary Gets a the information for the health check.
     * @request GET:/api/health/api/health
     */
    healthGet = (
        query?: {
            /**
             * Skip database connectivity.
             * @default true
             */
            skipDatabaseConnectivity?: boolean;
            /**
             * Skip redis connectivity.
             * @default true
             */
            skipRedisConnectivity?: boolean;
            /**
             * Skip package information.
             * @default true
             */
            skipPackageInformation?: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<HealthCheckActionResponseDto, any>({
            path: `/api/health/api/health`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags BaiConfiguration
     * @name BaiConfigurationGetBaiConfiguration
     * @summary Returns BAI configurations.
     * @request GET:/api/BaiConfiguration
     */
    baiConfigurationGetBaiConfiguration = (params: RequestParams = {}) =>
        this.http.request<BaiConfigurationModelDto, any>({
            path: `/api/BaiConfiguration`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags BaiConfiguration
     * @name BaiConfigurationSaveBaiConfiguration
     * @summary Save configurations related to BAI.
     * @request POST:/api/BaiConfiguration
     */
    baiConfigurationSaveBaiConfiguration = (
        baiConfigurationModel: BaiConfigurationModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<BaiConfigurationModelDto, any>({
            path: `/api/BaiConfiguration`,
            method: 'POST',
            body: baiConfigurationModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyAdminSettings
     * @name CompanyAdminSettingsGet
     * @summary Gets The Company Admin Settings.
     * @request GET:/api/CompanyAdminSettings
     */
    companyAdminSettingsGet = (params: RequestParams = {}) =>
        this.http.request<UserApprovalSettingsModelDto[], any>({
            path: `/api/CompanyAdminSettings`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyAdminSettings
     * @name CompanyAdminSettingsApprovalSettings
     * @summary Updates the Approval Settings with the specified information.
     * @request PUT:/api/CompanyAdminSettings/{id}
     */
    companyAdminSettingsApprovalSettings = (
        id: number,
        approvalSettings: UserApprovalSettingsModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<UserApprovalSettingsModelDto[], any>({
            path: `/api/CompanyAdminSettings/${id}`,
            method: 'PUT',
            body: approvalSettings,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUserChallengeSettings
     * @name CompanyUserChallengeSettingsGet
     * @summary End point to get company user challenge settings.
     * @request GET:/api/CompanyUserChallengeSettings
     */
    companyUserChallengeSettingsGet = (params: RequestParams = {}) =>
        this.http.request<CompanyUserChallengeSettingsModelDto, any>({
            path: `/api/CompanyUserChallengeSettings`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CompanyUserChallengeSettings
     * @name CompanyUserChallengeSettingsPut
     * @summary Update company user challenge settings.
     * @request PUT:/api/CompanyUserChallengeSettings
     */
    companyUserChallengeSettingsPut = (
        companyUserChallengeSettings: CompanyUserChallengeSettingsModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyUserChallengeSettingsModelDto, any>({
            path: `/api/CompanyUserChallengeSettings`,
            method: 'PUT',
            body: companyUserChallengeSettings,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Download
     * @name DownloadPost
     * @summary End point to get downloads.
     * @request POST:/api/Download
     */
    downloadPost = (request: DownloadRequestDto | null, params: RequestParams = {}) =>
        this.http.request<File, any>({
            path: `/api/Download`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags EntitlementGroups
     * @name EntitlementGroupsGet
     * @summary Returns the permissions for the logged in user.
     * @request GET:/api/EntitlementGroups
     */
    entitlementGroupsGet = (params: RequestParams = {}) =>
        this.http.request<EntitlementGroupModelDto[], any>({
            path: `/api/EntitlementGroups`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Entitlements
     * @name EntitlementsGet
     * @summary Returns the permissions for the logged in user.
     * @request GET:/api/Entitlements
     */
    entitlementsGet = (params: RequestParams = {}) =>
        this.http.request<FiPermissionModelDto[], any>({
            path: `/api/Entitlements`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags FiPermission
     * @name FiPermissionGet
     * @summary Returns all the FI Parameters
     * @request GET:/api/FiPermission
     */
    fiPermissionGet = (params: RequestParams = {}) =>
        this.http.request<FiPermissionModelDto[], any>({
            path: `/api/FiPermission`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags HolidayCalendars
     * @name HolidayCalendarsGet
     * @summary Retrieves the Holiday calendar dates.
     * @request GET:/api/HolidayCalendars
     */
    holidayCalendarsGet = (params: RequestParams = {}) =>
        this.http.request<HolidayCalendarModelDto[], any>({
            path: `/api/HolidayCalendars`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags HolidayCalendars
     * @name HolidayCalendarsPost
     * @summary Save the passed Holiday Calendar.
     * @request POST:/api/HolidayCalendars
     */
    holidayCalendarsPost = (
        holidayCalendarMessage: HolidayCalendarModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<HolidayCalendarModelDto, any>({
            path: `/api/HolidayCalendars`,
            method: 'POST',
            body: holidayCalendarMessage,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags HolidayCalendars
     * @name HolidayCalendarsPut
     * @summary Updates the passed holiday calendar model.
     * @request PUT:/api/HolidayCalendars/{id}
     */
    holidayCalendarsPut = (
        id: number,
        holidayCalendarModel: HolidayCalendarModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<HolidayCalendarModelDto, any>({
            path: `/api/HolidayCalendars/${id}`,
            method: 'PUT',
            body: holidayCalendarModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags HolidayCalendars
     * @name HolidayCalendarsDelete
     * @summary Delete the Holiday calendar for the specified Id.
     * @request DELETE:/api/HolidayCalendars/{id}
     */
    holidayCalendarsDelete = (id: number, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/api/HolidayCalendars/${id}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ContentManagementConfiguration
     * @name ContentManagementConfigurationGet
     * @summary Gets content management data.
     * @request GET:/api/ContentManagementConfiguration
     */
    contentManagementConfigurationGet = (params: RequestParams = {}) =>
        this.http.request<InstitutionModelDto, any>({
            path: `/api/ContentManagementConfiguration`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ContentManagementConfiguration
     * @name ContentManagementConfigurationPost
     * @summary Save content management data.
     * @request POST:/api/ContentManagementConfiguration
     */
    contentManagementConfigurationPost = (
        model: InstitutionModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<InstitutionModelDto, any>({
            path: `/api/ContentManagementConfiguration`,
            method: 'POST',
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags QboSettings
     * @name QboSettingsGet
     * @summary Gets QBO settings
     * @request GET:/api/QboSettings
     */
    qboSettingsGet = (params: RequestParams = {}) =>
        this.http.request<File | null, any>({
            path: `/api/QboSettings`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags QboSettings
     * @name QboSettingsPost
     * @summary Posts QBO settings.
     * @request POST:/api/QboSettings
     */
    qboSettingsPost = (settings: QboSettingsModelDto | null, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/api/QboSettings`,
            method: 'POST',
            body: settings,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags LoginParameters
     * @name LoginParametersGet
     * @summary Provides the Login parameter for the specified type.
     * @request GET:/api/LoginParameters
     */
    loginParametersGet = (
        query: {
            /** Parameters for FI company or FI. */
            type: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<LoginIdPasswordParametersModelDto, any>({
            path: `/api/LoginParameters`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags LoginParameters
     * @name LoginParametersPost
     * @summary Adds the passed Login parameter.
     * @request POST:/api/LoginParameters
     */
    loginParametersPost = (
        loginParameter: LoginIdPasswordParametersModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<LoginIdPasswordParametersModelDto, any>({
            path: `/api/LoginParameters`,
            method: 'POST',
            body: loginParameter,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags LoginParameters
     * @name LoginParametersPut
     * @summary Updates the modifed login parameter.
     * @request PUT:/api/LoginParameters/{id}
     */
    loginParametersPut = (
        id: number,
        loginParameter: LoginIdPasswordParametersModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<LoginIdPasswordParametersModelDto, any>({
            path: `/api/LoginParameters/${id}`,
            method: 'PUT',
            body: loginParameter,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags LoginParameters
     * @name LoginParametersDefaults
     * @summary Provides the Login parameter for the specified type.
     * @request GET:/api/LoginParameters/{id}
     */
    loginParametersDefaults = (
        id: number,
        query: {
            isCompanyParameter: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<LoginIdPasswordParametersModelDto, any>({
            path: `/api/LoginParameters/${id}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Navigations
     * @name NavigationsGet
     * @summary Gets navigation menu.
     * @request GET:/api/Navigations
     */
    navigationsGet = (params: RequestParams = {}) =>
        this.http.request<NavigationMenuModelDto, any>({
            path: `/api/Navigations`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags NotificationConfiguration
     * @name NotificationConfigurationGet
     * @summary Returns FI notification configuration.
     * @request GET:/api/NotificationConfiguration
     */
    notificationConfigurationGet = (params: RequestParams = {}) =>
        this.http.request<NotificationConfigurationModelDto, any>({
            path: `/api/NotificationConfiguration`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags NotificationConfiguration
     * @name NotificationConfigurationPut
     * @summary Updates FI notification configurations.
     * @request PUT:/api/NotificationConfiguration
     */
    notificationConfigurationPut = (
        notificationConfiguration: NotificationConfigurationModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<NotificationConfigurationModelDto, any>({
            path: `/api/NotificationConfiguration`,
            method: 'PUT',
            body: notificationConfiguration,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Roles
     * @name RolesGetAll
     * @summary Returns the roles.
     * @request GET:/api/Roles
     */
    rolesGetAll = (params: RequestParams = {}) =>
        this.http.request<RoleModelDto[], any>({
            path: `/api/Roles`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Roles
     * @name RolesPost
     * @summary Create the passed role.
     * @request POST:/api/Roles
     */
    rolesPost = (roleModel: RoleModelDto | null, params: RequestParams = {}) =>
        this.http.request<RoleModelDto, any>({
            path: `/api/Roles`,
            method: 'POST',
            body: roleModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Roles
     * @name RolesGet
     * @summary Returns the role for the specified Id.
     * @request GET:/api/Roles/{id}
     */
    rolesGet = (id: number, params: RequestParams = {}) =>
        this.http.request<RoleModelDto, any>({
            path: `/api/Roles/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Roles
     * @name RolesPut
     * @summary Update the passed role.
     * @request PUT:/api/Roles/{id}
     */
    rolesPut = (id: number, roleModel: RoleModelDto | null, params: RequestParams = {}) =>
        this.http.request<RoleModelDto, any>({
            path: `/api/Roles/${id}`,
            method: 'PUT',
            body: roleModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Roles
     * @name RolesDelete
     * @summary Delete the role for the specified Id.
     * @request DELETE:/api/Roles/{id}
     */
    rolesDelete = (id: number, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/api/Roles/${id}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags MessagingCenter
     * @name MessagingCenterGet
     * @summary Gets messages
     * @request GET:/api/MessagingCenter
     */
    messagingCenterGet = (params: RequestParams = {}) =>
        this.http.request<LoginIdPasswordParametersModelDto, any>({
            path: `/api/MessagingCenter`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags MessagingCenter
     * @name MessagingCenterPost
     * @summary Save messages
     * @request POST:/api/MessagingCenter
     */
    messagingCenterPost = (
        model: InstitutionMessagingCenterModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<InstitutionMessagingCenterModelDto, any>({
            path: `/api/MessagingCenter`,
            method: 'POST',
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionCodeGroups
     * @name TransactionCodeGroupsGet
     * @summary Retrieves all the Transaction code groups.
     * @request GET:/api/TransactionCodeGroups
     */
    transactionCodeGroupsGet = (params: RequestParams = {}) =>
        this.http.request<TransactionCodeGroupModelDto[], any>({
            path: `/api/TransactionCodeGroups`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionCodeGroups
     * @name TransactionCodeGroupsPost
     * @summary Add a new Transaction code group.
     * @request POST:/api/TransactionCodeGroups
     */
    transactionCodeGroupsPost = (
        transactionCodeGroupMessage: TransactionCodeGroupModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<TransactionCodeGroupModelDto, ModelStateDictionaryDto>({
            path: `/api/TransactionCodeGroups`,
            method: 'POST',
            body: transactionCodeGroupMessage,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionCodeGroups
     * @name TransactionCodeGroupsPut
     * @summary Updates the passed transaction code group.
     * @request PUT:/api/TransactionCodeGroups/{id}
     */
    transactionCodeGroupsPut = (
        id: number,
        transactionCodeGroupMessage: TransactionCodeGroupModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<TransactionCodeGroupModelDto, ModelStateDictionaryDto>({
            path: `/api/TransactionCodeGroups/${id}`,
            method: 'PUT',
            body: transactionCodeGroupMessage,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionCodeGroups
     * @name TransactionCodeGroupsDelete
     * @summary Deletes the transaction group for the specified Id.
     * @request DELETE:/api/TransactionCodeGroups/{id}
     */
    transactionCodeGroupsDelete = (id: number, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/api/TransactionCodeGroups/${id}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionCodes
     * @name TransactionCodesGet
     * @summary Retrieves the synched Transaction Codes.
     * @request GET:/api/TransactionCodes
     */
    transactionCodesGet = (params: RequestParams = {}) =>
        this.http.request<any, TransactionCodeModelDto[]>({
            path: `/api/TransactionCodes`,
            method: 'GET',
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionCodes
     * @name TransactionCodesPut
     * @summary Updates the passed transaction code.
     * @request PUT:/api/TransactionCodes/{id}
     */
    transactionCodesPut = (
        id: number,
        transactionCodeModel: TransactionCodeModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<TransactionCodeModelDto, ModelStateDictionaryDto>({
            path: `/api/TransactionCodes/${id}`,
            method: 'PUT',
            body: transactionCodeModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ProcessingTimes
     * @name ProcessingTimesGet
     * @summary Gets FI processing time.
     * @request GET:/api/ProcessingTimes
     */
    processingTimesGet = (params: RequestParams = {}) =>
        this.http.request<ProcessingTimesConfigurationModelDto, any>({
            path: `/api/ProcessingTimes`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ProcessingTimes
     * @name ProcessingTimesPut
     * @summary Updates FI processing time.
     * @request PUT:/api/ProcessingTimes/{id}
     */
    processingTimesPut = (
        id: number,
        processingTimes: ProductFeatureProcessingTimesModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ProductFeatureProcessingTimesModelDto, any>({
            path: `/api/ProcessingTimes/${id}`,
            method: 'PUT',
            body: processingTimes,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ProcessingTimes
     * @name ProcessingTimesGet2
     * @summary Gets processing time.
     * @request GET:/api/ProcessingTimes/{id}
     */
    processingTimesGet2 = (id: number, params: RequestParams = {}) =>
        this.http.request<ProductFeatureProcessingTimesModelDto, any>({
            path: `/api/ProcessingTimes/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsGet
     * @summary Returns all the Accounts associated with Company from CORE.
     * @request GET:/api/Accounts
     */
    accountsGet = (
        query: {
            accountType: string | null;
            /** Unique Core id for an Company. */
            cifNumber: string | null;
            includeHoldingCompanies: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<AccountModelDto[], any>({
            path: `/api/Accounts`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags WireCompanies
     * @name WireCompaniesAccounts
     * @summary Return accounts for specified wire company Id
     * @request GET:/api/WireCompanies
     */
    wireCompaniesAccounts = (
        query: {
            /** Core Company Id */
            coreCompanyId: string | null;
            /**
             * Parent CompanyId
             * @format int32
             */
            parentCompanyId: number;
            isDli: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<WireCompanyModelDto, any>({
            path: `/api/WireCompanies`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Reports
     * @name ReportsGetReports
     * @summary Gets reports
     * @request GET:/api/Reports
     */
    reportsGetReports = (params: RequestParams = {}) =>
        this.http.request<ReportModelBaseDto[], any>({
            path: `/api/Reports`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Reports
     * @name ReportsGetReportData
     * @summary Posts report data.
     * @request POST:/api/Reports
     */
    reportsGetReportData = (reportFilter: ReportFilterBaseDto, params: RequestParams = {}) =>
        this.http.request<any, any>({
            path: `/api/Reports`,
            method: 'POST',
            body: reportFilter,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchCompany
     * @name AchCompanyGetAchCompanyByMatchTypeParameters
     * @summary Retrieves a collection of companies by their match type parameters.
     * @request GET:/api/AchCompany
     */
    achCompanyGetAchCompanyByMatchTypeParameters = (
        query?: {
            name?: string | null;
            identifier?: string | null;
            secCode?: string | null;
            entryDescription?: string | null;
            discretionaryData?: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<GetAchCompanyByMatchTypeParametersResponseDto, any>({
            path: `/api/AchCompany`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Ach
     * @name AchCompaniesPost
     * @request POST:/api/Ach/{id}
     */
    achCompaniesPost = (
        id: number,
        query: {
            /** @format int32 */
            actionId: number;
        },
        status: LookupModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<OkResultDto, ErrorHttpContentDto>({
            path: `/api/Ach/${id}`,
            method: 'POST',
            query: query,
            body: status,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchSecCodes
     * @name AchSecCodesGet
     * @summary Returns the available SEC Code from Core for ACH.
     * @request GET:/api/AchSecCodes
     */
    achSecCodesGet = (params: RequestParams = {}) =>
        this.http.request<SecCodeModelDto[], any>({
            path: `/api/AchSecCodes`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
