import { TmBaseComponent } from '@treasury/presentation';
import { actionIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-bottom-sheet';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../containers/user-container';

export const tagName = 'user-detail-bottom-sheet';
@customElement(tagName)
export class UserDetailBottomSheet extends TmBaseComponent {
    @property({ type: Object })
    public user: any = {};

    @property()
    public bottomSheetOpen = false;

    renderUserDetail() {
        if (!this.user) return nothing;
        return html`<user-container .userId=${this.user.id}></user-container>`;
    }

    render() {
        return html`<tm-bottom-sheet
            .open=${this.bottomSheetOpen}
            @close=${() => {
                this.bottomSheetOpen = false;
                this.user = {};
                this.dispatchEvent(new CustomEvent('close'));
            }}
        >
            <span slot="header-center">User Detail</span
            ><span slot="header-right">${actionIcon}</span>
            ${this.renderUserDetail()}</tm-bottom-sheet
        >`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserDetailBottomSheet;
    }
}
