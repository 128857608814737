import '@vaadin/button';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { TmBaseComponent } from '../tm-base.component';
import { ButtonTheme } from './tm-button.types';

export const tagName = 'tm-button';
@customElement(tagName)
export class TmButton extends TmBaseComponent {
    constructor() {
        super();
        this.addEventListener('click', event => {
            if (this.disabled) {
                event.preventDefault();
                event.stopImmediatePropagation();
                event.stopPropagation();
            }
        });
    }

    @property({ type: String, reflect: true })
    public importance?: 'primary' | 'secondary' | 'tertiary' = 'secondary';

    @property({ type: Boolean })
    public disabled = false;

    @property({ type: String, reflect: true })
    public size?: 'large' | 'normal' | 'small' = 'normal';

    @property({ type: Boolean })
    public error = false;

    @property({ type: Boolean })
    public success = false;

    @property({ type: Boolean })
    public contrast = false;

    @property({ type: Boolean })
    public isIcon = false;

    get successOrError() {
        if (this.success && this.error) {
            throw new Error('Button can not be of type success and error');
        }
        if (this.success) return 'success';
        if (this.error) return 'error';
        return undefined;
    }

    get theme() {
        const { importance, size, successOrError } = this;
        const theme: ButtonTheme = { importance, size, successOrError };
        if (this.isIcon) return 'icon';
        return Object.values(theme)
            .map(val => val)
            .join(' ');
    }

    render() {
        return html`
            <vaadin-button
                part="component"
                theme=${this.theme}
                ?disabled=${this.disabled}
                class="w-full"
            >
                <slot></slot>
            </vaadin-button>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: inline-block;
                    --lumo-primary-color: var(--color-primary);
                    --lumo-primary-contrast-color: var(--white);
                    --lumo-font-size-m: 1rem;
                    --lumo-font-size-l: 1rem;
                }
                :host[disabled] {
                    pointer-events: none;
                    cursor: not-allowed;
                }
                :host vaadin-button[theme~='primary'][disabled] {
                    background-color: var(--tm-button-primary-disabled-background);
                    color: var(--tm-button-primary-disabled-color);
                }
                :host vaadin-button[theme~='secondary'] {
                    background-color: var(--tm-button-secondary-background);
                    border: 1px solid var(--tm-button-border);
                    color: var(--tm-button-secondary-color);
                }
                :host vaadin-button[theme~='secondary'][theme~='error'] {
                    color: var(--lumo-error-color);
                }
                :host vaadin-button[theme~='secondary'][disabled] {
                    background-color: var(--tm-button-secondary-disabled-background);
                    border-color: transparent;
                    color: var(--tm-button-secondary-disabled-color);
                }
                :host vaadin-button[theme='icon'] {
                    background-color: transparent;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmButton;
    }
}
