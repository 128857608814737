import { css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { TmBaseComponent } from '../../tm-base.component';

export const tagName = 'tm-section';
@customElement(tagName)
export class TmSection extends TmBaseComponent {
    render() {
        return html`<slot></slot>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    background-color: var(--primary-background);
                    box-shadow: var(--card-shadow);
                    height: 100%;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmSection;
    }
}
