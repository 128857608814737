import { Account } from '@treasury/domain/dashboard';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-checkbox';
import '@vaadin/grid';
import { columnBodyRenderer, GridColumnBodyLitRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { UserAccountReconViewModel } from '../data/user-account-recon-view-model';

export const tagName = 'user-account-recon';
@customElement(tagName)
export class UserAccountRecon extends TmBaseComponent {
    @property({ type: Object })
    public config!: UserAccountReconViewModel;

    renderEnabledColumn(account: { name: string; number: string; enabled: boolean }) {
        return html`<div class="flex flex-col">
            <tm-checkbox .checked=${account.enabled} disabled></tm-checkbox>
        </div>`;
    }

    renderAccountNameAndNumber(account: Account) {
        return html`<div class="flex flex-col">
            <span class="text-sm font-normal">${account.name}</span
            ><small class="text-xs">${account.number}</small>
        </div>`;
    }

    render() {
        if (!this.config?.accounts?.length)
            return html`<div class="italic w-full text-center">
                No Account Reconciliation Reporting configured.
            </div>`;
        return html`<vaadin-grid all-rows-visible .items=${this.config?.accounts}
            ><vaadin-grid-column
                ${columnBodyRenderer(
                    this.renderEnabledColumn as GridColumnBodyLitRenderer<{
                        name: string;
                        number: string;
                        enabled: boolean;
                    }>
                )}
            ></vaadin-grid-column
            ><vaadin-grid-column
                ${columnBodyRenderer(
                    this.renderAccountNameAndNumber as GridColumnBodyLitRenderer<Account>
                )}
            ></vaadin-grid-column
        ></vaadin-grid>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserAccountRecon;
    }
}
