import { css, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { TmBaseComponent } from '../tm-base.component';

export const tagName = 'tm-icon';
@customElement(tagName)
export class TmIcon extends TmBaseComponent {
    @property({ type: Object })
    public icon!: TemplateResult<2>;

    @property()
    public width = '';

    @property()
    public height = '';

    @property()
    public color = '';

    firstUpdated() {
        this.setIconColor();
        this.setIconSize();
    }

    updated(changedProps: Map<string, unknown>) {
        this.setIconColor();
        this.setIconSize();
    }

    private setIconSize() {
        const svg = this.shadowRoot?.querySelector('svg');
        if (svg) {
            svg.style.width = this.width;
            svg.style.height = this.height;
        }
    }

    private setIconColor() {
        const path = this.shadowRoot?.querySelector('path');
        if (this.color && path) {
            path.style.fill = this.color;
        }
    }

    render() {
        return this.icon;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: inline-block;
                }
                path {
                    fill: var(--icon-fill-color, var(--color-primary));
                }
                svg {
                    transform: var(--icon-svg-transform, scale(1));
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmIcon;
    }
}
