import { PaymentModelDto } from '@treasury/api/channel';
import { getPaymentMethodString, getStatusString } from '@treasury/domain/bill-pay';
import { formatUsdCurrency } from '@treasury/utils';
import { format } from 'date-fns';

export class BillPayWorkflowPaymentViewModel {
    constructor(value: PaymentModelDto) {
        this.value = value;
    }

    private readonly value: PaymentModelDto;

    get amount() {
        return formatUsdCurrency(this.value.amount || 0);
    }

    get comment() {
        return this.value.comment;
    }

    get estimatedArrivalDate() {
        if (!this.value.estimatedArrivalDate) return '';
        return format(new Date(this.value.estimatedArrivalDate), 'P');
    }

    get fromAccount() {
        return this.value.fromAccount?.name;
    }

    get payee() {
        return this.value.payee?.name;
    }

    get paymentMethod() {
        return getPaymentMethodString(this.value.paymentMethod || 0);
    }

    get paymentType() {
        return this.value.paymentType;
    }

    get processDate() {
        if (!this.value.processDate) return '';
        return format(new Date(this.value.processDate), 'P');
    }

    get recurrence() {
        return this.value.recurrence;
    }

    get status() {
        return getStatusString(this.value.status || 0);
    }
}
