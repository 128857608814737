// TODO: there's no endpoint to fetch a Loan by ID, will use a bottom sheet for now.

import { NavigationService } from '@treasury/core/navigation';
import { LoanDto, LoanService } from '@treasury/domain/loans';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-blocking-loader';
import { InjectProperty } from '@treasury/utils';
import { html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../../components/jhd-header-bar';

export const tagName = 'loan-container';
@customElement(tagName)
export class LoanContainer extends TmBaseComponent {
    @state()
    loading = true;

    @state()
    loan: LoanDto = {} as LoanDto;

    @state()
    id = '';

    @InjectProperty()
    private declare service: LoanService;

    @InjectProperty()
    private declare navService: NavigationService;

    async firstUpdated() {
        const { params } = await this.navService.getRouteData<{ id: string }>();
        this.id = params.id;
        this.loading = true;
        this.loan = (await this.service.getLoanPaymentDetails(this.id)) as unknown as LoanDto;
        this.loading = false;
    }

    renderBlockingLoader() {
        if (!this.loading) return nothing;
        return html`<tm-blocking-loader></tm-blocking-loader>`;
    }

    render() {
        return html`${this.renderBlockingLoader()}
            <div>Loan Detail Container</div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: LoanContainer;
    }
}
