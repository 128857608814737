import { toOrdinalSuffix } from '@treasury/utils/functions/strings.helpers';
import { format } from 'date-fns';
import { Frequency, FrequencyDay, FrequencyType, FrequencyTypeLabels, MonthlyFrequency, TwiceMonthlyFrequency, WeeklyFrequency } from './tm-frequency.types';


const formatFrequencyDays = (frequency: Frequency) => {
    if (!frequency) return;
    switch (frequency.type) {
        case 'Weekly':
        case 'EveryTwoWeeks':
            const weekday = FrequencyDay[(frequency as WeeklyFrequency).repeatOn];
            return ` on ${weekday}s`;
       case 'TwiceAMonth':
           let secondDayOfTheMonth = (frequency as TwiceMonthlyFrequency).repeatOnLastBusinessDay ? 'last business day' : toOrdinalSuffix((frequency as TwiceMonthlyFrequency).repeatOn[1] as number);
            return ` on the ${toOrdinalSuffix((frequency as TwiceMonthlyFrequency).repeatOn[0])} and 
            ${ secondDayOfTheMonth }`;
        case 'Monthly':
            let dayOfTheMonth =  (frequency as MonthlyFrequency).repeatOnLastBusinessDay ? 'last business day' : toOrdinalSuffix((frequency as MonthlyFrequency).repeatOn as number);
            return ` on the ${dayOfTheMonth}`;
        default:
            return '';
    }
};

const formatFrequencyStartEnd = (frequency: Frequency) => {

    const startDate = format(frequency.startDate, 'M/d/yyyy');
    if (frequency.type === 'OneTime') {     
        return ` on ${startDate}`;
    }

    if (frequency.perpetual) {
        return ` starting on ${startDate}`;
    }

    if(frequency.endDate){
        const endDate = format(frequency.endDate, 'M/d/yyyy');
        return ` from ${startDate} to ${endDate}`;
    }
};


export const summarizeFrequency = (frequency: Frequency) => {
    if (!frequency) return '';

    return `Occurs ${FrequencyTypeLabels[frequency.type as FrequencyType].toLowerCase()}${formatFrequencyDays(
        frequency
    )}${formatFrequencyStartEnd(frequency)}`;
};