/* eslint-disable @treasury/no-date */
import { Frequency } from '@treasury/domain/channel/types/frequency';
import { FieldType } from '@treasury/FDL';
import accountingNotation from './lib/formatters/accountingNotation.formatter.js';
import amountRangeAccountingNotation from './lib/formatters/amount-range-accounting-notation.formatter.js';
import appendPercentSign from './lib/formatters/appendPercentSign.formatter.js';
import currencyNoSymbolFormatter from './lib/formatters/currencyNoSymbol.formatter';
import dateFormatter from './lib/formatters/date.formatter.js';
import dateTimeFormatter from './lib/formatters/dateTime.formatter.js';
import phoneFormatter from './lib/formatters/phone.formatter.js';
import socialSecurityMask from './lib/formatters/social-security-mask.formatter.js';
import timeFormatter from './lib/formatters/time.formatter.js';
import usdFormat from './lib/formatters/usd.formatter.js';
import yesNoFormatter from './lib/formatters/yesNo.formatter.js';
import reverseAccountingNotation from './lib/parsers/accountingNotation.parser.js';
import parsePhone from './lib/parsers/phone.parser.js';
import parseSSN from './lib/parsers/ssn.parser.js';
import reverseUsdFormat from './lib/parsers/usd.parser.js';
import compareDates from './lib/utils/compare-dates.js';
import compareStrings from './lib/utils/compare-strings.js';
import { dateOptionDateFormatter } from './lib/utils/date-options-model.js';
import alphanumeric from './lib/validators/alphanumeric.validator';
import { effectiveDateIsRequired } from './lib/validators/frequency/effective-date-is-required';
import { endOnIsRequired } from './lib/validators/frequency/end-on-is-required';
import { noEndBeforeStart } from './lib/validators/frequency/no-end-before-start';
import { repeatOnDayIsRequired } from './lib/validators/frequency/repeat-on-day-is-required';
import { repeatOnDaysIsRequired } from './lib/validators/frequency/repeat-on-days-is-required';
import { repeatOnIsRequired } from './lib/validators/frequency/repeat-on-is-required';
import { startOnIsRequired } from './lib/validators/frequency/start-on-is-required';
import isNegative from './lib/validators/isNegative.js';
import mustBeNumeric from './lib/validators/mustBeNumeric.validator.js';
import mustBePositive from './lib/validators/mustBePositive.validator.js';
import { phoneValidator } from './lib/validators/phone.validator.js';
import { rangeValidator } from './lib/validators/range.validator.js';
import ssnValidator from './lib/validators/ssn-format.validator.js';

export const string = new FieldType<string>().with
    .defaultValue('')
    .and.compareFunction(compareStrings);

export const boolean = new FieldType<boolean>().with.schema('boolean').and.defaultValue(false);

export const yesNoBoolean = boolean.with.formatter(yesNoFormatter);

export const textarea = new FieldType<string>().with
    .rowCount(2)
    .and.description('intended to return a text-area component with a default row count of 2');

export const list = new FieldType<unknown[]>();

export const file = new FieldType<File | File[]>().with.schema('file');

export const number = new FieldType<number>().with
    .cellClass('numeric')
    .thatHas.textAlign('right')
    .and.validator(mustBeNumeric)
    .and.exampleValue(1234.56)
    .and.conditionalCellClass(isNegative, 'negative')
    .and.defaultValue(0)
    .and.parser(parseFloat)
    .and.inputMask(/[0-9$.,]/);

export const socialSecurityNumber = new FieldType<string>().with
    .cellClass('ssn')
    .and.exampleValue('123-45-6789')
    .and.defaultValue('')
    .and.placeholder('***-**-****')
    .and.formatter(socialSecurityMask)
    .and.formatOnChange()
    .and.parser(parseSSN)
    .and.validator(ssnValidator)
    .thatHas.maxLength(11);

export const date = new FieldType<Date>().with
    .formatter(dateFormatter)
    .and.cellClass('date')
    .and.minColumnWidth(50)
    .and.targetColumnWidth(80)
    .and.maxColumnWidth(200)
    .and.defaultValue(new Date())
    .and.exampleValue(new Date())
    .and.compareFunction(compareDates);

export const time = new FieldType<number>().with
    .formatter(timeFormatter)
    .and.cellClass('time')
    .and.minColumnWidth(100)
    .and.targetColumnWidth(100)
    .and.maxColumnWidth(100);

export const dateTime = new FieldType<Date>().with
    .formatter(dateTimeFormatter)
    .and.cellClass('dateTime')
    .and.minColumnWidth(100)
    .and.targetColumnWidth(100)
    .and.maxColumnWidth(100);

export const money = number.with
    .formatter(usdFormat)
    .and.formatter(accountingNotation)
    .and.parser(reverseUsdFormat)
    .and.parser(reverseAccountingNotation)
    .and.minColumnWidth(50)
    .and.targetColumnWidth(80)
    .and.maxColumnWidth(200)
    .and.defaultValue(0)
    .and.inputMask(/[0-9.,$]/)
    .and.selectOnFocus();

export const currencyNoSymbol = number.with
    .formatter(currencyNoSymbolFormatter)
    .and.minColumnWidth(50)
    .and.targetColumnWidth(80)
    .and.maxColumnWidth(200)
    .and.defaultValue(0)
    .and.inputMask(/[0-9.]/);

export const currency = money;

export const amountRangePrint = new FieldType<number>().with
    .formatter(usdFormat)
    .and.formatter(amountRangeAccountingNotation)
    .and.minColumnWidth(50)
    .and.targetColumnWidth(80)
    .and.maxColumnWidth(200);

export const enumeration = string.with
    .cellClass('enum')
    .and.minColumnWidth(50)
    .and.targetColumnWidth(80)
    .and.maxColumnWidth(200)
    .and.exampleValue('CR');

export const id = enumeration.with.exampleValue('12345');

export const percent = number.with
    .validator(mustBePositive)
    .and.formatter(appendPercentSign)
    .and.minColumnWidth(50)
    .and.targetColumnWidth(50)
    .and.maxColumnWidth(100);

export const checkNumber = number.with.cellClass('check-number').and.validator(mustBePositive);

export const frequency = new FieldType<Frequency>().with
    .schema('frequency')
    .with.validator(noEndBeforeStart)
    .with.validator(effectiveDateIsRequired)
    .with.validator(repeatOnIsRequired)
    .with.validator(repeatOnDaysIsRequired)
    .with.validator(repeatOnDayIsRequired)
    .with.validator(startOnIsRequired)
    .with.validator(endOnIsRequired)
    .that.usesCustomPrint();

const range = new FieldType<number>().with.schema('range').thatHas.options([
    { text: 'Amount Range', value: 'range' },
    { text: 'Specific Amount', value: 'specific' },
]);

export const amountRange = new FieldType<number>().with
    .formatter(usdFormat)
    .and.formatter(accountingNotation)
    .and.parser(reverseUsdFormat)
    .and.parser(reverseAccountingNotation)
    .and.minColumnWidth(50)
    .and.targetColumnWidth(80)
    .and.maxColumnWidth(200)
    .and.inputMask(/[0-9.,$]/)
    .with.schema('range')
    .thatHas.options([
        { text: 'Amount Range', value: 'range' },
        { text: 'Specific Amount', value: 'specific' },
    ])
    .and.validator(rangeValidator)
    .and.selectOnFocus()
    .and.description(
        'the default amount range field. This should be extended to fit the implementations in channel and back office for most amount range inputs'
    );

export const datePicker = new FieldType<string>().with
    .formatter(dateOptionDateFormatter())
    .and.schema('datepicker')
    .with.defaultValue('$TODAY');

export const dateRangePicker = datePicker.with.range();

export const multiDate = new FieldType<string>().with
    .formatter(dateOptionDateFormatter())
    .and.schema('multi-date')
    .with.defaultValue('$TODAY');

export const dateRangePickerNoDefault = dateRangePicker.with.defaultValue('');

export const dateRangePickerDefaultToday = dateRangePicker.with.defaultValue('$TODAY');

export const specificDate = datePicker.with.options(['today', 'specific']).and.parseDynamicRange();

export const dateRange = datePicker.with
    .options(['today', 'specific', 'range', 'week-to-date', 'month-to-date', 'year-to-date'])
    .and.parseDynamicRange();

export const streetAddress = new FieldType<string>().with
    .validator({
        name: 'length',
        validate: value => value?.length >= 1 && value?.length <= 35,
    })
    .thatHas.maxLength(35)
    .and.validator(alphanumeric);

export const city = new FieldType<string>().with
    .validator({
        name: 'length',
        validate: value => value?.length >= 1 && value?.length <= 33,
    })
    .thatHas.maxLength(33)
    .with.validator(alphanumeric);

export const zipCode = new FieldType<string>().with
    .validator({
        name: 'length',
        validate: value => value?.length >= 1 && value?.length <= 33,
    })
    .thatHas.maxLength(33);

export const count = number.with
    .cellClass('count')
    .and.description(
        'basic numeric field, the cell class added centers the value when the field is present in a table'
    );

export const phone = new FieldType<string>().with
    .inputMask(/[0-9\-() ]/)
    .and.formatter(phoneFormatter)
    .and.formatOnChange()
    .and.validator(phoneValidator)
    .and.parser(parsePhone);

/**
 * Common Aliases
 */

export const status = count;

export const link = string;

export const amount = money;

export const audit = string;

export default {
    string,
    FieldType,
    boolean,
    checkNumber,
    city,
    zipCode,
    date,
    time,
    dateTime,
    enum: enumeration,
    id,
    list,
    money,
    currencyNoSymbol,
    currency,
    number,
    percent,
    frequency,
    range,
    dateRange,
    datePicker,
    multiDate,
    dateRangePickerNoDefault,
    dateRangePickerDefaultToday,
    streetAddress,
    count,
    status,
    amount,
    amountRange,
    amountRangePrint,
    textarea,
    socialSecurityNumber,
    link,
    specificDate,
    phone,
    yesNoBoolean,
    file,
};
