import { CheckException, ExceptionDecisionStatus } from '@treasury/domain/arp';
import { TmBaseComponent } from '@treasury/presentation';
import { css, html, PropertyValueMap } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
// eslint-disable-next-line import/no-duplicates
import '../../components/jhd-decision-toggle';
// eslint-disable-next-line import/no-duplicates
import { JhdDecisionToggleChangeEvent } from '../../components/jhd-decision-toggle';
import '../../components/jhd-icon';
import { JhaApprovalToggleStatus } from '../types';

/**
 * Pos Pay exceptions interface for jhd-decision-toggle.
 * Wraps jhd-decision-toggle component and provides an API to Pos Pay Domain Entity object for external state management.
 */
export const tagName = 'decision-toggle';
@customElement(tagName)
export class DecisionToggle extends TmBaseComponent {
    @property({ type: Object })
    public exception!: CheckException;

    @property()
    decisionStatus!: ExceptionDecisionStatus;

    @state()
    decision: JhaApprovalToggleStatus = JhaApprovalToggleStatus.NA;

    @property({ type: Boolean })
    readonly = false;

    @property()
    modified = false;

    protected updated(
        _changedProperties: PropertyValueMap<DecisionToggle> | Map<PropertyKey, unknown>
    ): void {
        if (
            _changedProperties.has('exception') ||
            _changedProperties.has('decisionStatus') ||
            _changedProperties.has('modified')
        ) {
            if (this.exception.decisionStatus === ExceptionDecisionStatus.Pay) {
                this._payException();
            } else if (this.exception.decisionStatus === ExceptionDecisionStatus.Return) {
                this._returnException();
            }
            this.modified = this.exception.decisionModified;
        }
    }

    payException() {
        this.exception.pay();
        this.modified = this.exception.decisionModified;
        this._payException();
        this.dispatchEvent(new CustomEvent<CheckException>('change', { detail: this.exception }));
    }

    private _payException() {
        this.decision = JhaApprovalToggleStatus.Approved;
    }

    returnException() {
        this.exception.return();
        this.modified = this.exception.decisionModified;
        this._returnException();
        this.dispatchEvent(new CustomEvent<CheckException>('change', { detail: this.exception }));
    }

    private _returnException() {
        this.decision = JhaApprovalToggleStatus.Rejected;
    }

    acknowledgeException() {
        this.exception.acknowledged = !this.exception.acknowledged;
    }

    render() {
        return html`
            <jhd-decision-toggle
                .decision=${this.decision}
                @toggle-change=${(changeEvent: JhdDecisionToggleChangeEvent) => {
                    const { selection } = changeEvent.detail;
                    if (selection === JhaApprovalToggleStatus.Approved) {
                        this.payException();
                    } else if (selection === JhaApprovalToggleStatus.Rejected) {
                        this.returnException();
                    }
                }}
                .originalState=${this.exception.originalApprovalStatus}
                .modified=${this.modified}
                .filledBackground=${this.exception.decidedToday}
                .protected=${this.exception.protected}
                ?readonly=${this.readonly}
            ></jhd-decision-toggle>
        `;
    }

    static get styles() {
        return [
            css`
                .decision-icons {
                    display: flex;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: DecisionToggle;
    }
}
