import { PaymentTypes } from '@treasury/domain/channel/types/payment';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-footer';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import './approval-review-table';

export const tagName = 'approval-review-bottom-sheet';
@customElement(tagName)
export class ApprovalReviewBottomSheet extends TmBaseComponent {
    @state()
    private open = false;

    @property({ type: Array })
    public decisionedPayments = [];

    @property({ type: String })
    public activeTab = '';

    @property({ type: Boolean })
    public approvalSuccess = false;

    @property({ type: Boolean })
    public submittingApprovals = false;

    renderReviewTitle() {
        switch (this.activeTab) {
            case PaymentTypes.ACH:
                return html`ACH Payments`;
            case PaymentTypes.Transfer:
                return html`Transfers`;
            case PaymentTypes.Wire:
                return html`Wires`;
            case PaymentTypes.Loan:
                return html`Loans`;
            case 'user':
                return html`Users`;
            default:
                return nothing;
        }
    }

    renderBlockingLoader() {
        if (this.submittingApprovals) {
            return html`<tm-blocking-loader></tm-blocking-loader>`;
        }
        return nothing;
    }

    render() {
        return html`<tm-bottom-sheet
            .open=${this.open}
            @close=${() => {
                this.open = false;
                this.dispatchEvent(new CustomEvent('close'));
            }}
            ><div slot="header-center">Review Approvals</div>
            <div slot="header-right">&nbsp;</div>
            <div id="content">
                ${this.renderBlockingLoader()}
                <div class="px-5 py-4 font-medium border-b border-[--border-color]">
                    ${this.renderReviewTitle()}
                </div>
                <approval-review-table
                    .activeTab=${this.activeTab}
                    .activeItems=${this.decisionedPayments}
                ></approval-review-table>
            </div>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: `Submit (${this.decisionedPayments.length})`,
                        onClick: () => {
                            this.dispatchEvent(
                                new CustomEvent('approve-or-reject', {
                                    detail: { type: this.activeTab },
                                })
                            );
                        },
                    },
                ]}
            >
            </tm-footer>
        </tm-bottom-sheet>`;
    }

    static get styles() {
        return [
            css`
                #confirmation-container,
                #content {
                    background-color: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: ApprovalReviewBottomSheet;
    }
}
