/* eslint-disable no-use-before-define */
import 'reflect-metadata';
import { exists } from '../functions';
import { AbstractConstructor, Constructor } from '../types';
import { FACTORY_DEPS, IS_INJECTABLE_KEY, TOKEN_INJECTABLES } from './di.constants';
import { DiToken, InjectionToken, SymbolToken, TokenDependency } from './di.types';

/**
 * Determine if a constructor has been decorated and is a known provider
 * that can be injected into dependents.
 *
 * @returns `true` if the constructor is able to be injected into dependents.
 */
export function isInjectable(ctor: Constructor<unknown> | AbstractConstructor<unknown>) {
    return Reflect.getMetadata(IS_INJECTABLE_KEY, ctor) === true;
}

export function getTokenInjectables(
    ctor: Constructor<unknown> | AbstractConstructor<unknown>
): { [index: number]: TokenDependency } | undefined {
    return Reflect.getMetadata(TOKEN_INJECTABLES, ctor);
}

/**
 * Get a list of all types associated with a method or constructor.
 *
 * @param ctor Constructor decorated as `Injectable`.
 * @returns An ordered list of DI tokens for each dependency required by the source constructor.
 */
export function getDependencies(ctor: Constructor | AbstractConstructor): DiToken<unknown>[] {
    const factoryDeps: Constructor[] = Reflect.getMetadata(FACTORY_DEPS, ctor);
    const ctorTypes: Constructor[] | object[] = Reflect.getMetadata('design:paramtypes', ctor);
    const dependencies = factoryDeps || ctorTypes || [];
    const injectables = getTokenInjectables(ctor) || {};

    const undefinedIndex = dependencies.indexOf(undefined as any);
    if (undefinedIndex >= 0) {
        throw new Error(
            `Found an undefined dependency for class ${ctor.name} at position ${undefinedIndex}. This is likely due to circular imports.`
        );
    }

    const unknownIndex = dependencies.indexOf(Object);
    if (unknownIndex >= 0 && !exists(injectables[unknownIndex])) {
        throw new Error(
            `Found an unknown dependency for class ${ctor.name} at position ${unknownIndex}. This is likely due to circular imports.`
        );
    }

    return dependencies.map((d, i) => (exists(injectables[i]) ? injectables[i].token : d));
}

export function isSymbolToken<T>(token: DiToken<T>): token is SymbolToken<T> {
    return typeof token === 'string' || token instanceof InjectionToken;
}

export function getTokenName(token: DiToken<unknown>) {
    return isSymbolToken(token) ? token.toString() : token.name;
}
