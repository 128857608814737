import { UserInformationModelDto, UserModelDto } from '@treasury/api/channel';
import { UserRequests } from '@treasury/domain/channel/requests/users/users';
import { AccountService } from '@treasury/domain/channel/services';
import { ChangePasswordDto, NewUserAccountDto } from '@treasury/domain/channel/types/user-account';
import { Injectable } from '@treasury/utils';

@Injectable()
export class UserProfileClient {
    // eslint-disable-next-line no-useless-constructor
    constructor(private readonly accountService: AccountService) {}

    public async getCurrentUser() {
        return this.accountService.getCurrentUser();
    }

    public async getPasswordRequirements(institution: string) {
        return this.accountService.getPasswordRequirements(institution);
    }

    public async changePassword(body: ChangePasswordDto) {
        return this.accountService.changePassword(body);
    }

    public async verifyUser(user: UserModelDto) {
        return this.accountService.verifyUser(user);
    }

    public async createUser(body: NewUserAccountDto) {
        return UserRequests.createUser(body);
    }

    public async searchUsers() {
        return UserRequests.getUsers();
    }
}
