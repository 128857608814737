import { FdlRecordEvent, string } from '@treasury/FDL';
import { deepEquals } from '@treasury/utils';
import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { until } from 'lit/directives/until.js';
import { paymentFrequencyFormatter } from '../formatters/paymentFrequency.formatter.js';
// eslint-disable-next-line import/extensions
import { ListeningElementMixin } from '../components';
import './omega-additional-info-complex.js';
import './omega-additional-info.js';
import './omega-checkbox-group.js';
import './omega-checkbox.js';
import './omega-datepicker.js';
import './omega-file-upload.js';
// eslint-disable-next-line import/extensions
import './omega-frequency/omega-frequency';
import './omega-multi-date.ts';
import './omega-radio-group.js';
import './omega-range.js';
import './omega-select.js';
import './omega-textarea.js';
import './omega-toggle.js';
import './omega-tooltip.js';

const ListeningElement = ListeningElementMixin(LitElement);

const warnedAboutLabels = new Set();

const OmegaFieldType = {
    None: 'none',
    PlainText: 'text',
    Template: 'template',
    Input: 'input',
    FormElement: 'form-element',
    Frequency: 'frequency',
    DatePicker: 'date-picker',
    File: 'file',
    Toggle: 'toggle',
    Info: 'additional-info',
    TextArea: 'text-area',
    Checkbox: 'checkbox',
    Range: 'range',
};

class OmegaField extends ListeningElement {
    static get properties() {
        return {
            label: String, // deprecated, use FDL's .with.label() instead
            hideLabel: { type: Boolean, reflect: true }, // only used in standard-column.js -- maybe we could use a CSS custom property instead?
            record: Object,
            fieldType: Object,
            fieldName: String,
            fieldModel: Object,
            field: { type: String, reflect: true },
            fields: { type: Array, reflect: true },
            readOnly: Boolean, // deprecated, use FDL's .thatIs.readOnlyWhen() instead
            required: { attribute: true, type: Boolean }, // only used in create-template-step.js
            visible: { attribute: true, type: Boolean },
            inline: { type: Boolean, reflect: true },
            optionsLoading: { type: Boolean },
            value: String,
            disabled: { type: Boolean, attribute: true },
            items: Array,
            iconMessage: String,
            truncateText: { type: Boolean, reflect: true },
            activeDatePickerTab: String,
        };
    }

    constructor() {
        super();
        this.truncateText = false;
        this.controlType = OmegaFieldType.None;

        /**
         * @type string[] | undefined
         */
        this.fields = undefined;

        /**
         * Reference to the rendered component for this field.
         */
        this.elementRef = createRef();
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-field--input',
        };
    }

    get isMultiField() {
        return Array.isArray(this.fields);
    }

    get label() {
        return this.fieldType?.label(this.record) || this.deprecatedLabelProperty;
    }

    set label(label) {
        if (!warnedAboutLabels.has(`${this.field}-${label}`)) {
            warnedAboutLabels.add(`${this.field}-${label}`);
        }

        const oldLabel = this.label;
        this.deprecatedLabelProperty = label;
        this.requestUpdate('label', oldLabel);
    }

    async firstUpdated() {
        if (!this.record) {
            throw new Error('Cannot create an <omega-field>. No record provided.');
        }

        if (this.isMultiField) {
            return;
        }

        this.fieldType = this.fieldType || this.record.fieldTypeForField(this.field);
        this.inline = this.fieldType.inline(this.record);
        this.disabled = this.fieldType.isDisabled(this.record);
        this.fieldName = this.field;
        this.fieldModel = this.record.field(this.field);
        this.value = this.record.getField(this.fieldName);

        if (this.fieldType.hasOptions()) {
            this.optionsLoading = true;
            this.items = await this.fieldType.options(this.record, this.field);
            this.fieldType.properties.filter();
            this.optionsLoading = false;
        }
        if (this.fieldType.tabs()) {
            const firstTab = this.fieldType.tabs()[0].id;
            this.record.addField('dateType', string, firstTab);
            this.activeDatePickerTab = firstTab;
        }

        this.createEventListeners();
    }

    updated(changedProps) {
        if (changedProps.has('record')) {
            /* TODO Should not need to remove / recreate event listeners on update.  This has to do with omega-table pagination reusing omega-field components and not running the firstUpdate function. See bug #483468 */
            this.removeAllListeners();
            this.createEventListeners();
            if (this.isMultiField) {
                return;
            }

            this.required = this.fieldType.required(this.record);
            this.hideLabel = this.hideLabel ?? this.fieldType.hideLabel(this.record);
            this.visible = this.fieldType.visible(this.record);
            this.readOnly = this.fieldType.readonly(this.record) || this.readOnly;
            this.readOnlyException = this.fieldType.readonlyexception(this.record);
            this.disabled = this.fieldType.isDisabled(this.record);
            this.label = this.fieldType.label(this.record) || this.label;
            this.inline = this.fieldType.inline(this.record);
            this.segmented = this.fieldType.segmented(this.record);
            this.empty = this.fieldType.empty(this.record, this.field);
            this.fieldModel = this.record.field(this.field);
            this.value = this.record.getField(this.field);
        }
    }

    createEventListeners() {
        this.listenTo(this.record, FdlRecordEvent.Change, detail => this.listenFunction(detail));
        this.listenTo(this.record, FdlRecordEvent.Blur, detail => this.onRecordBlur(detail));
        this.listenTo(this.record, FdlRecordEvent.Reset, detail => this.onRecordReset(detail));
    }

    async listenFunction({ detail }) {
        const { field } = detail;
        const relevantField = this.fields ? this.fields.includes(field) : field === this.field;
        const newValue = this.record.getField(field);

        if (relevantField && !deepEquals(newValue, this.value)) {
            this.value = newValue;
        }

        if (this.isMultiField) {
            return;
        }

        this.required = this.fieldType.required(this.record);
        this.hideLabel = this.hideLabel ?? this.fieldType.hideLabel(this.record);
        this.visible = this.fieldType.visible(this.record);
        this.readOnly = this.fieldType.readonly(this.record);
        this.readOnlyException = this.fieldType.readonlyexception(this.record);
        this.label = this.fieldType.label(this.record) ?? this.label;
        this.inline = this.fieldType.inline(this.record);
        this.empty = this.fieldType.empty(this.record, this.field);
        this.disabled = this.fieldType.isDisabled(this.record);
        this.fieldModel = this.record.field(this.field);
        this.activeDatePickerTab = this.record.getField('dateType');
        const noOptionsPresent =
            !this.items || this.items.length === 0 || this.fieldType.fetchOptionsOnChange;
        if (this.fieldType.hasOptions() && noOptionsPresent) {
            this.items = await this.fieldType.options(this.record);
        }
    }

    async onRecordBlur() {
        this.optionsLoading = true;
        if (this.fieldType.hasOptions()) {
            this.items = await this.fieldType.options(this.record);
            this.optionsLoading = false;
        }
    }

    onRecordReset() {
        const { value } = this.elementRef;
        if (value && value.reset) {
            value.reset();
        }
    }

    onKeyDown(e) {
        this.dispatchEvent(new CustomEvent('keydown', e));
    }

    onChange(e, value) {
        this.record.setField(this.field, value);
        this.fieldType.onValueChange(this.record);
        this.dispatchEvent(new CustomEvent('change', e));
    }

    /**
     *
     * @param {string} field
     * @param {*} value
     * @param {CustomEvent} originalEvent
     */
    onMultiChange(field, value, originalEvent) {
        this.record.setField(field, value);
        const unchangedFields = this.fields.filter(f => f !== field);
        unchangedFields.forEach(f => this.record.setField(f, undefined));

        this.fieldType.onValueChange(this.record);
        const { type, detail } = originalEvent;
        this.dispatchEvent(
            new CustomEvent(type, {
                detail,
            })
        );
    }

    type(text) {
        this.record.setField(this.field, text);
    }

    renderSelectionControl(field, fieldType, value) {
        if (fieldType.schema() === 'range') {
            return html`<omega-range
                .values=${value}
                .fieldType=${fieldType}
                .field=${field}
                .options=${until(fieldType.options(this.record), [])}
                .segmented=${fieldType.segmented(this.record)}
                ?disabled=${this.disabled}
                @change=${event => this.onChange(event, event.detail.values)}
            ></omega-range>`;
        }

        // the multiple values option has to win out over the inline property here so if multi is true we just ignore inline
        if (this.inline && fieldType.hasMultipleValues()) {
            return html`<omega-checkbox-group
                label=""
                orientation="row"
                .options=${until(fieldType.options(this.record), [])}
                .values=${value}
                ?disabled=${this.disabled}
                @change=${event => this.onChange(event, event.detail.values)}
            ></omega-checkbox-group>`;
        }
        if (!this.inline || fieldType.hasMultipleValues()) {
            return html`<omega-select
                .value=${value}
                .placeholder=${fieldType.placeholder()}
                .searchConfig=${fieldType.searchConfig()}
                .items=${until(this.items, null)}
                .loading=${this.optionsLoading}
                .hasFilter=${fieldType.hasFilter()}
                .hasSearch=${fieldType.hasSearch()}
                .hideSelectAll=${fieldType.properties.options.hideSelectAll}
                ?disabled=${this.disabled || this.optionsLoading}
                ?multiple=${fieldType.hasMultipleValues()}
                .maxValueCount=${fieldType.maxValueCount()}
                .validate=${v => {
                    field.isValidValue(v);
                }}
                .valid=${field.valid}
                .hashFunction=${fieldType.properties.hashFunction}
                @change=${event => this.onChange(event, event.target.value)}
                @action=${({ detail }) => {
                    if (typeof detail.action === 'function') detail.action(this.record);
                }}
                @blur=${() => {
                    this.record.announceBlur(this.field);
                }}
            ></omega-select>`;
        }
        return html`<omega-radio-group
            label=""
            orientation="row"
            .radios=${until(fieldType.options(this.record), [])}
            .value=${value}
            .items=${until(fieldType.options(this.record), [])}
            .hasFilter=${fieldType.hasFilter()}
            ?multiple=${fieldType.hasMultipleValues()}
            ?disabled=${this.disabled}
            .name=${`${this.field}-radio`}
            @change=${event => this.onChange(event, event.target.value)}
        ></omega-radio-group>`;
    }

    renderFormElement(fieldType, fieldName) {
        const { name, properties } = fieldType.properties.formElement;
        const input = document.createElement(name);
        Object.keys(properties).forEach(key => {
            input[key] = properties[key];
        });
        input.maxlength = fieldType.maxLength();
        input.minlength = fieldType.minLength();
        input.allowInputChar = fieldType.allowInputChar;

        input.disabled = fieldType.isDisabled(this.record);

        input.isAvailableOption = v =>
            fieldType.validate(this.field, v, v, this.record).length === 0;

        if (fieldType.hasOptions()) {
            fieldType.options(this.record).then(options => {
                input.options = options;
            });
        }

        input.value = this.record.getField(fieldName);

        this.listenTo(input, 'input', event => {
            this.record.setField(fieldName, event.target.value);
        });

        this.listenTo(input, 'change', event => {
            this.record.setField(fieldName, event.target.value);
        });

        this.listenTo(this.record, 'change', () => {
            input.value = this.record.getField(fieldName);
            input.disabled = fieldType.isDisabled(this.record);
        });

        return input;
    }

    renderPrintedField() {
        if (this.fieldType.hasMultipleValues()) {
            const field = this.record.getField(this.fieldName);
            if (!Array.isArray(field)) {
                throw new Error(
                    `Expected "${
                        this.fieldName
                    }" field to have multiple values. Got "${JSON.stringify(field)}".`
                );
            }
            field.map(value => html`${this.fieldType.print(value, this.record)}<br />`);
        }

        const textValue = this.record.print(this.fieldName);
        const addTitle = this.truncateText && this.controlType === OmegaFieldType.PlainText;
        return html`<span title=${ifDefined(addTitle ? textValue : undefined)}>${textValue}</span>`;
    }

    renderFrequency(value) {
        const frequency = paymentFrequencyFormatter(value);
        if (!deepEquals(value, frequency)) {
            this.record.setField(this.field, frequency);
        }

        return html`<omega-frequency
            .annotation=${this.fieldType.frequencyAnnotation(this.record)}
            .frequency=${frequency}
            .frequencyTypes=${until(this.fieldType.options(this.record))}
            ?readonly=${this.readOnly}
            ?readonlyexception=${this.readOnlyException}
            .required=${this.required}
            .validate=${v => {
                this.fieldModel.isValidValue(v);
            }}
            .record=${this.record}
            @change=${event => this.onChange(event, event.detail)}
            .disabled=${this.disabled}
            .isStartDateInvalid=${this.record
                .fieldTypeForField(this.field)
                .selectionDisabledFunctions()?.isStartDateInvalid}
            .isEndDateInvalid=${this.record
                .fieldTypeForField(this.field)
                .selectionDisabledFunctions()?.isEndDateInvalid}
            .isDisabledEndDate=${this.record
                .fieldTypeForField(this.field)
                .selectionDisabledFunctions()?.isDisabledEndDate}
            .isDisabledStartDate=${this.record
                .fieldTypeForField(this.field)
                .selectionDisabledFunctions()?.isDisabledStartDate}
        ></omega-frequency>`;
    }

    renderControl() {
        const schema = this.fieldType.schema();

        if (this.fieldType.properties.formElement) {
            this.controlType = OmegaFieldType.FormElement;
            return this.renderFormElement(this.fieldType, this.fieldName);
        }
        if (this.readOnly && schema !== 'frequency') {
            const fieldType = this.record.fieldTypeForField(this.fieldName);
            this.controlType = fieldType.hasCustomTemplate()
                ? OmegaFieldType.Template
                : OmegaFieldType.PlainText;
            return this.renderPrintedField();
        }

        if (schema === 'frequency') {
            this.controlType = OmegaFieldType.Frequency;
            return this.renderFrequency(this.value);
        }
        if (this.fieldType.hasOptions() && schema !== 'datepicker') {
            this.controlType = OmegaFieldType.DatePicker;
            return this.renderSelectionControl(this.fieldModel, this.fieldType, this.value);
        }
        if (schema === 'file') {
            this.controlType = OmegaFieldType.File;
            return html`<omega-file-upload
                .disabled=${this.disabled}
                .multiple=${this.fieldType.hasMultipleValues()}
                .max=${this.fieldType.maxValueCount()}
                @filesUploaded=${event => {
                    this.onChange(event, event.detail.files);
                }}
                @invalidFileSize=${() => this.dispatchEvent(new CustomEvent('invalidFileSize'))}
                .files=${this.record.getField(this.field)}
            ></omega-file-upload>`;
        }
        if (this.fieldType.toggle()) {
            this.controlType = OmegaFieldType.Toggle;
            return html`<omega-toggle
                .label=${this.label}
                .record=${this.record}
                .checked=${this.value}
                .hideLabel=${!!this.label}
                @change=${event => this.onChange(event, event.detail.value || event.detail.checked)}
                ?disabled=${this.disabled}
            ></omega-toggle>`;
        }
        if (schema === 'boolean') {
            this.controlType = OmegaFieldType.Checkbox;
            return html`<omega-checkbox
                .label=${this.label}
                ?checked=${this.value}
                .value=${this.value}
                @toggle=${event => this.record.setField(this.field, event.target.checked)}
                ?disabled=${this.disabled}
            ></omega-checkbox>`;
        }
        if (schema === 'range') {
            this.controlType = OmegaFieldType.Range;
            return html`<omega-range
                .values=${this.value}
                .fieldType=${this.fieldType}
                .field=${this.fieldModel}
                .options=${until(this.fieldType.options(this.record), [])}
                .segmented=${this.fieldType.segmented(this.record)}
                ?disabled=${this.disabled}
                @change=${event => this.onChange(event, event.detail.values)}
            ></omega-range>`;
        }
        if (schema === 'datepicker') {
            this.controlType = OmegaFieldType.DatePicker;
            return html`<omega-datepicker
                ?required=${this.required}
                .range=${this.fieldType.hasRange()}
                .valid=${this.fieldModel.valid}
                .value=${this.value}
                .tabs=${this.fieldType.tabs()}
                .activeTab=${this.activeDatePickerTab}
                .placeholder=${this.fieldType.placeholder()}
                ?disabled=${this.disabled}
                .parseDynamicRange=${this.fieldType.getParseDynamicRange()}
                .dateDisabledFunction=${this.fieldType.selectionDisabledFunctions()
                    .dateDisabledFunction}
                .optionOrder=${until(this.fieldType.options(this.record), [])}
                @change=${event => {
                    this.onChange(event, event.detail.value);
                }}
                @switchTab=${({ detail }) => {
                    const { activeTab } = detail;
                    this.activeDatePickerTab = activeTab;
                    this.record.setField('dateType', this.activeDatePickerTab);
                }}
            ></omega-datepicker>`;
        }

        if (schema === 'multi-date') {
            if (!this.isMultiField) {
                throw new Error(
                    'Attempted to render <omega-multi-date> without providing a multi-filter.'
                );
            }

            const { dateDisabledFunction } = this.fieldType.selectionDisabledFunctions();
            return html`<omega-multi-date
                ${ref(this.elementRef)}
                .value=${this.value}
                .items=${this.items}
                .range=${this.fieldType.hasRange()}
                .placeholder=${this.fieldType.placeholder()}
                .disableDateFn=${dateDisabledFunction}
                @change=${({ detail }) =>
                    this.onMultiChange(detail.fieldName, detail.date, detail.originalEvent)}
            ></omega-multi-date>`;
        }

        if (this.fieldType.hasMultipleValues()) {
            this.controlType = OmegaFieldType.Info;
            return html`<omega-additional-info
                .label=${this.label}
                .field=${this.fieldModel}
                .maxLength=${this.fieldType.maxLength()}
                @change=${event => {
                    this.fieldModel.value = event.detail.value;
                    this.fieldType.onValueChange(this.record);
                }}
            ></omega-additional-info>`;
        }
        if (this.fieldType.rowCount() > 1) {
            this.controlType = OmegaFieldType.TextArea;
            return html`<omega-textarea
                rows=${this.fieldType.rowCount()}
                label=${this.label}
                .field=${this.fieldModel}
                ?disabled=${this.disabled}
                .validate=${v => {
                    this.fieldModel.isValidValue(v);
                }}
                .valid=${this.fieldModel.valid}
                .maxLength=${this.fieldType?.maxLength()}
            ></omega-textarea>`;
        }

        this.controlType = OmegaFieldType.Input;
        return html`<omega-input
            label=${this.label}
            .type=${this.fieldType.type()}
            .placeholder=${this.fieldType.placeholder()}
            .field=${this.fieldModel}
            .minLength=${this.fieldType.minLength()}
            .maxLength=${this.fieldType.maxLength()}
            ?disabled=${this.disabled}
            .validate=${v => {
                this.fieldModel.isValidValue(v);
            }}
            .value=${this.value}
            .selectOnFocus=${this.fieldType.selectOnFocus()}
            .formatOnChange=${this.fieldType.formatOnChange()}
            @keydown=${this.onKeyDown}
            @blur=${() => this.record.announceBlur(this.field)}
        ></omega-input>`;
    }

    renderInfoIcon() {
        const iconMessageSettings = this.fieldType.iconMessage();
        if (this.iconMessage || typeof iconMessageSettings === 'string') {
            return html`<omega-tooltip
                icon="info-circle"
                message=${this.iconMessage || iconMessageSettings}
                direction="top-right"
            ></omega-tooltip>`;
        }
        if (iconMessageSettings) {
            return html`<omega-tooltip
                icon="info-circle"
                direction=${iconMessageSettings.direction ?? 'top-right'}
                ?light=${iconMessageSettings.light ?? false}
            >
                <div slot="content">${iconMessageSettings.message}</div>
            </omega-tooltip>`;
        }
        return nothing;
    }

    renderLabel() {
        if (!this.label) return nothing;
        if (this.hideLabel) return nothing;

        if (this.field) {
            const fieldType = this.record.fieldTypeForField(this.field);
            if (fieldType.schema() === 'boolean' && !fieldType.toggle() && !this.readOnly)
                return nothing;
        }

        const requiredIndicator =
            this.required && !this.readOnly
                ? html`<span class="required-indicator">&nbsp;*</span>`
                : nothing;

        return html`<div class="label">
            <label for=${this.field} class="label">${this.label}${requiredIndicator}</label
            >${this.renderInfoIcon()}
        </div>`;
    }

    render() {
        if (!this.visible) return nothing;
        if (!this.fieldType) return nothing;

        // must be called before classes are set based on controlType
        const control = this.renderControl();
        const classes = {
            control: true,
            ellipsis: this.truncateText && this.controlType === OmegaFieldType.PlainText,
        };

        return html` ${this.renderLabel()}
            <div class=${classMap(classes)} id=${this.field}>${control}</div>`;
    }

    static get styles() {
        return css`
            * {
                box-sizing: border-box;
            }
            :host {
                display: flex;
                flex-flow: row;
                flex-wrap: var(--omega-field-flex-wrap, wrap);
                align-items: center;
                color: var(--omega-field-color);
                font-weight: var(--omega-field-font-weight);
            }

            .ellipsis {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .label {
                flex: 1 1 var(--omega-field-label-width, 200px);
                font-size: var(--omega-label, 14px);
                font-weight: var(--omega-label-font-weight, 400);
                color: var(--omega-label-color);
                margin-bottom: var(--omega-field-label-margin-bottom, 4px);
                max-width: var(--omega-field-label-max-width, none);
            }

            .required-indicator {
                color: var(--omega-error);
            }
            .control {
                font-size: var(--omega-field-control-font-size);
                font-weight: var(--omega-field-control-font-weight);
                flex: 3 1 var(--omega-field-control-width, 250px);
                max-width: var(--omega-field-control-max-width, none);
            }
            omega-checkbox {
                margin-right: var(--omega-field-checkbox-margin-right);
            }
            :host([no-align]) {
                align-items: unset;
            }
            :host([no-align]) .label {
                padding-top: 7px;
            }
        `;
    }
}

window.customElements.define('omega-field', OmegaField);
// eslint-disable-next-line @treasury/filename-match-export
export default OmegaField;
