import { BatchModelDto, ItemImageModelDto, ItemModelDto } from '@treasury/api/channel';
import { RemoteDepositCaptureService } from '@treasury/domain/channel/services';
import { LabeledList, TmBaseComponent } from '@treasury/presentation';
import { checkmarkIcon, circleCloseIcon, clockIcon } from '@treasury/presentation/assets/icons';
import { InjectProperty } from '@treasury/utils';
import { format } from 'date-fns';
import { PropertyValueMap, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';

export const tagName = 'deposit-detail';

@customElement(tagName)
export class DepositDetail extends TmBaseComponent {
    @InjectProperty()
    private declare readonly remoteDepositCaptureService: RemoteDepositCaptureService;

    @property({ attribute: false })
    public bottomSheetOpen = false;

    @property({ attribute: false })
    public deposit = {} as BatchModelDto;

    @state()
    private checks?: ItemModelDto[];

    @state()
    private selectedCheck?: ItemModelDto;

    @state()
    private selectedCheckImages?: ItemImageModelDto;

    @state()
    private depositDate = '';

    @state()
    private depositEventsOpen = false;

    @state()
    private checkDetailOpen = false;

    async updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>) {
        if (_changedProperties.has('deposit') && this.deposit) {
            this.depositDate = format(
                new Date(this.deposit.events?.[this.deposit.events.length - 1].date ?? ''),
                'MM/dd/yyyy'
            );
            this.checks = await this.remoteDepositCaptureService.getItems(
                this.deposit.batchReference as string
            );
        }
        if (_changedProperties.has('selectedCheck')) {
            this.selectedCheckImages = (await this.remoteDepositCaptureService.getItemImages(
                this.selectedCheck?.itemReference as string
            )) as ItemImageModelDto;
        }
    }

    private getStatusColor(status: string | undefined) {
        switch (status) {
            case 'Processing':
                return '--warning-color';
            case 'Accepted':
            case 'Deposited':
                return '--success-color';
            case 'Rejected':
                return '--error-color';
            default:
                return '';
        }
    }

    private renderStatusIcon(status: string | undefined) {
        let statusIcon;
        const color = `var(${this.getStatusColor(status)})`;
        switch (status) {
            case 'Processing':
                statusIcon = clockIcon;
                break;
            case 'Accepted':
            case 'Deposited':
                statusIcon = checkmarkIcon;
                break;
            case 'Rejected':
                statusIcon = circleCloseIcon;
                break;
            default:
                statusIcon = html``;
        }

        return html`<tm-icon class="mr-1" .icon=${statusIcon} .color=${color}></tm-icon>`;
    }

    private renderDepositEvents() {
        return this.deposit.events?.map(
            event =>
                html`<div class="text-left mr-3 py-1 border-b border-[--border-color]">
                        <span class="text-sm font-medium"
                            >${format(new Date(event.date), 'PP')}</span
                        ><span class="ml-1 text-xs text-[--secondary-text-color]"
                            >${format(new Date(event.date), 'pp')}</span
                        >
                    </div>
                    <div class="text-sm text-right py-1">${event.description}</div>`
        );
    }

    private renderChecks() {
        return this.checks?.map(
            check =>
                html` <button
                    class="flex justify-between w-full py-2 text-sm"
                    @click=${() => {
                        this.selectedCheck = check;
                        this.checkDetailOpen = true;
                    }}
                >
                    <div class="flex items-center text-left mr-3">
                        <div class="mr-1">${this.renderStatusIcon(check.status)}</div>
                        <div>
                            <div class="text-medium">${check.itemReference}</div>
                            <div
                                class="text-xs"
                                style="color: var(${this.getStatusColor(check.status)})"
                            >
                                ${check.status}
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-end text-right">
                        <span class="font-medium">
                            ${check.amount.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}
                        </span>
                    </div>
                </button>`
        );
    }

    private renderCheckDetail() {
        return html`<div class="flex justify-between pb-2">
                <div class="left">
                    <div class="text-3xl font-light">
                        ${this.selectedCheck?.amount.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </div>
                </div>
                <div class="text-sm">
                    <div class="flex items-center">
                        ${this.renderStatusIcon(this.selectedCheck?.status)}
                        <div>${this.selectedCheck?.status}</div>
                    </div>
                </div>
            </div>
            <tm-labeled-list
                class="mb-4 pb-4 border-b border-dashed border-[--border-color]"
                .list=${new LabeledList(
                    {
                        itemReference: this.selectedCheck?.itemReference,
                    },
                    ['itemReference']
                )}
            ></tm-labeled-list>
            <h4 class="pb-2 font-medium">Check Images</h4>
            <div class="my-2">
                <img
                    src="${ifDefined(this.selectedCheckImages?.frontImage)}"
                    alt="Front of check ${this.selectedCheckImages?.itemReference}"
                />
            </div>
            <div class="my-2">
                <img
                    src="${ifDefined(this.selectedCheckImages?.backImage)}"
                    alt="Back of check ${this.selectedCheckImages?.itemReference}"
                />
            </div>`;
    }

    public render() {
        if (!this.deposit) return nothing;
        return html`<tm-section class="pt-6 px-6">
                <div class="flex justify-between pb-2">
                    <div class="left">
                        <div class="text-xl">Deposit Total</div>
                        <div class="text-3xl font-light">
                            ${this.deposit.totalAmount.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}
                        </div>
                    </div>
                    <div class="text-sm">
                        <div class="flex items-center">
                            ${this.renderStatusIcon(this.deposit.status)}${this.deposit.status}
                        </div>
                    </div>
                </div>
                <tm-labeled-list
                    class="pb-4 border-b border-dashed border-[--border-color]"
                    .list=${new LabeledList(
                        {
                            depositDate: this.depositDate,
                            location: this.deposit.locationName,
                        },
                        ['location', 'depositDate']
                    )}
                ></tm-labeled-list>
                <tm-button
                    class="w-full"
                    importance="secondary"
                    @click=${() => {
                        this.depositEventsOpen = true;
                    }}
                >
                    Deposit Events
                </tm-button>
                <div class="py-4">
                    <h4 class="font-medium pb-2">
                        Checks <tm-badge>${this.deposit.totalCount}</tm-badge>
                    </h4>
                    ${this.renderChecks()}
                </div>
            </tm-section>

            <tm-bottom-sheet
                .open=${this.depositEventsOpen}
                @close=${() => (this.depositEventsOpen = false)}
            >
                <span slot="header-center">Deposit Events</span>
                <tm-section class="pt-6 px-6">
                    <div class="grid grid-cols-[auto_1fr] gap-x-3">
                        ${this.renderDepositEvents()}
                    </div>
                </tm-section>
            </tm-bottom-sheet>

            <tm-bottom-sheet
                .open=${this.checkDetailOpen}
                @close=${() => (this.checkDetailOpen = false)}
            >
                <span slot="header-center">Check Detail</span>
                <tm-section class="pt-6 px-6">${this.renderCheckDetail()}</tm-section>
            </tm-bottom-sheet>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    position: relative;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: DepositDetail;
    }
}
