import { EntitlementsClient, ProductPermissionModelDto } from '@treasury/api/channel';
import { Injectable } from '@treasury/utils';
import { Entitlement } from './entitlement.type';

@Injectable()
export class EntitlementService {
    constructor(private entitlementsClient: EntitlementsClient) {}

    private initialized = false;

    public userEntitlements: ProductPermissionModelDto[] = [];

    public async init() {
        if (this.initialized) {
            return;
        }
        this.userEntitlements = (await this.entitlementsClient.entitlementsGet()).data;
        this.initialized = true;
    }

    private guardInit() {
        if (!this.initialized) {
            throw new Error('Entitlement service is not initialized.');
        }
    }

    public hasEntitlement(entitlement: Entitlement) {
        this.guardInit();
        return !!this.userEntitlements.find(x => x.permission === entitlement);
    }

    public hasAllEntitlements(...entitlements: Entitlement[]) {
        this.guardInit();
        const userPermissions: string[] = this.userEntitlements.map(x => x.permission);
        return !!entitlements.every(entitlement => userPermissions.includes(entitlement));
    }

    public hasAnyEntitlement(...entitlements: Entitlement[]) {
        this.guardInit();
        const userPermissions: string[] = this.userEntitlements.map(x => x.permission);
        return !!entitlements.some(entitlement => userPermissions.includes(entitlement));
    }

    public reset() {
        this.userEntitlements = [];
        this.initialized = false;
    }
}
