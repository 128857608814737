/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { InformationCenterConfigurationModelDto } from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class InformationCenterConfigurationsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags InformationCenterConfigurations
     * @name InformationCenterConfigurationsGetAll
     * @summary Returns the Info center configurations.
     * @request GET:/InformationCenterConfigurations
     */
    informationCenterConfigurationsGetAll = (params: RequestParams = {}) =>
        this.http.request<InformationCenterConfigurationModelDto[], any>({
            path: `/InformationCenterConfigurations`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags InformationCenterConfigurations
     * @name InformationCenterConfigurationsPost
     * @summary Creates the info center.
     * @request POST:/InformationCenterConfigurations
     */
    informationCenterConfigurationsPost = (
        infocenterModel: InformationCenterConfigurationModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<InformationCenterConfigurationModelDto, any>({
            path: `/InformationCenterConfigurations`,
            method: 'POST',
            body: infocenterModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags InformationCenterConfigurations
     * @name InformationCenterConfigurationsDelete
     * @summary Deletes the information center for the specified Id.
     * @request DELETE:/InformationCenterConfigurations/{id}
     */
    informationCenterConfigurationsDelete = (id: number, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/InformationCenterConfigurations/${id}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags InformationCenterConfigurations
     * @name InformationCenterConfigurationsGet
     * @summary Returns the info center for the specified info center id.
     * @request GET:/InformationCenterConfigurations/{id}
     */
    informationCenterConfigurationsGet = (id: number, params: RequestParams = {}) =>
        this.http.request<InformationCenterConfigurationModelDto, any>({
            path: `/InformationCenterConfigurations/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags InformationCenterConfigurations
     * @name InformationCenterConfigurationsPut
     * @summary Updates the passed info center.
     * @request PUT:/InformationCenterConfigurations/{id}
     */
    informationCenterConfigurationsPut = (
        id: number,
        infocenterModel: InformationCenterConfigurationModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<InformationCenterConfigurationModelDto, any>({
            path: `/InformationCenterConfigurations/${id}`,
            method: 'PUT',
            body: infocenterModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags InformationCenterConfigurations
     * @name InformationCenterConfigurationsResequence
     * @summary Returns the info center for the specified info center id.
     * @request GET:/InformationCenterConfigurations/Resequence
     */
    informationCenterConfigurationsResequence = (
        query: {
            /**
             * Info center id.
             * @format int32
             */
            id: number;
            /** up/down */
            direction: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<InformationCenterConfigurationModelDto[], any>({
            path: `/InformationCenterConfigurations/Resequence`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
}
