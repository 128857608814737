/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { WireTemplate } from '@treasury/domain/wires';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-loader';
import '@treasury/presentation/components/tm-table-header';
import '@vaadin/grid';
import { columnBodyRenderer, GridColumnBodyLitRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { css, html, nothing, PropertyValueMap } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { filterTableResults } from '../../../../utilities/table-result-filter';
import '../../../components/jhd-status';
import { WireAction } from '../../wire-workflow/types/wire-workflow-parameters.type';

export const tagName = 'wire-templates-table';
@customElement(tagName)
export class WireTemplatesTable extends TmBaseComponent {
    @property({ type: Array })
    wireTemplates: WireTemplate[] = [];

    @property({ type: String, reflect: true })
    action?: WireAction;

    @property({ type: Boolean })
    loading = true;

    @state()
    private fieldsToFilter = ['name', 'debitAccountName', 'beneficiaryName', 'type'];

    @state()
    private filteredWireTemplates: WireTemplate[] = [];

    protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        if (_changedProperties.has('wireTemplates')) {
            this.filteredWireTemplates = this.wireTemplates;
        }
    }

    onTemplateClick(wireTemplate: WireTemplate) {
        if (!wireTemplate) return;
        this.dispatchEvent(
            new CustomEvent('wireTemplateClick', {
                detail: { value: wireTemplate },
            })
        );
    }

    onFiltersClick() {
        this.dispatchEvent(new CustomEvent('filterTemplatesClick'));
    }

    filterResults(e: CustomEvent) {
        this.filteredWireTemplates = filterTableResults(
            e,
            this.wireTemplates,
            this.fieldsToFilter
        ) as WireTemplate[];
    }

    // TODO: finish and get proper icons for all statuses
    renderStatusIcon(wireTemplate: WireTemplate) {
        return html`<jhd-status class="px-4" .status=${wireTemplate.status}></jhd-status>`;
    }

    // TODO: add conditionally rendered columns for larger screens
    renderTemplateNameDebitBeneficiaryColumn(wireTemplate: WireTemplate) {
        return html`<div class="py-2 pr-4 border-b border-[--border-color]">
            <div class="flex justify-between">
                <div class="template-name font-bold">${wireTemplate.name}</div>
                <div class="template-wire-type-column text-right font-bold">
                    ${wireTemplate.isInternational ? 'International' : 'Domestic'}
                </div>
            </div>
            <div
                class="template-debit-beneficiary text-xs text-[--secondary-text-color] whitespace-normal"
            >
                from ${wireTemplate.debitAccount?.accountDisplayLabel} to
                ${wireTemplate.beneficiary?.name}
            </div>
        </div>`;
    }

    renderStatusColumn() {
        if (this.action === 'initiate') return nothing;
        return html`<vaadin-grid-column
            flex-grow="0"
            width="3.5rem"
            ${columnBodyRenderer(
                this.renderStatusIcon as GridColumnBodyLitRenderer<WireTemplate>,
                []
            )}
        ></vaadin-grid-column>`;
    }

    renderTable() {
        if (this.loading) return html`<tm-loader card></tm-loader>`;
        if (!this.filteredWireTemplates.length) {
            return html`<div class="flex justify-center min-w-full mt-10">
                <div class="text-gray-600 italic">No wire templates found</div>
            </div>`;
        }
        return html`<vaadin-grid
            class="border-y border-[--border-color]"
            .items=${this.filteredWireTemplates}
            @active-item-changed=${({ detail }: CustomEvent) => {
                this.onTemplateClick(detail.value);
            }}
        >
            ${this.renderStatusColumn()}
            <vaadin-grid-column
                flex-grow="1"
                auto-width
                ${columnBodyRenderer(
                    this
                        .renderTemplateNameDebitBeneficiaryColumn as GridColumnBodyLitRenderer<WireTemplate>,
                    []
                )}
            ></vaadin-grid-column>
        </vaadin-grid>`;
    }

    render() {
        return html`<div class="pwa-table-wrapper">
            <tm-table-header
                .filterButton=${true}
                @filterButtonClick=${this.onFiltersClick}
                @filterStringChange=${(e: CustomEvent) => this.filterResults(e)}
            ></tm-table-header>
            ${this.renderTable()}
        </div>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    --lumo-body-text-color: var(--primary-text-color);
                }
                vaadin-grid-cell-content {
                    padding: 6px 16px 6px 0px;
                }
                vaadin-grid::part(body-cell) {
                    border-top: none;
                }

                :host([action='initiate']) vaadin-grid-cell-content {
                    padding-left: 16px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: WireTemplatesTable;
    }
}
