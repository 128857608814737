import '@vaadin/date-picker';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { TmInputFieldBase } from './tm-input-field.base';

export const tagName = 'tm-date-picker';
@customElement(tagName)
export class TmDatePicker extends TmInputFieldBase {
    @property({ type: String })
    min?: string | null;

    @property({ type: String })
    max?: string | null;

    render() {
        return html`
            <vaadin-date-picker
                @value-changed=${(e: CustomEvent) => {
                    const valueChangedEvent = new CustomEvent('value-changed', {
                        detail: e.detail === '' ? false : e.detail,
                    });
                    this.dispatchEvent(valueChangedEvent);
                }}
                @change=${(e: CustomEvent) => {
                    const userChangeEvent = new CustomEvent('change', {
                        detail: e.detail === '' ? false : e.detail,
                    });
                    this.dispatchEvent(userChangeEvent);
                }}
                class="w-full"
                part="component"
                label=${this.label}
                .value=${this.value}
                .max=${this.max}
                .min=${this.min}
                placeholder=${ifDefined(this.placeholder)}
                helper-text=${ifDefined(this.helperText)}
                ?required=${this.required}
                ?readonly=${this.readonly}
                ?disabled=${this.disabled}
            >
            </vaadin-date-picker>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmDatePicker;
    }
}
