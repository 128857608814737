import { OffsetAccount } from '@treasury/domain/ach';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-table-header';
import { GridActiveItemChangedEvent } from '@vaadin/grid';
import { columnBodyRenderer, GridColumnBodyLitRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { filterTableResults } from '../../../utilities/table-result-filter';

export type OffsetAccountEvent = CustomEvent<OffsetAccount>;

export const tagName = 'ach-offset-accounts-table';
@customElement(tagName)
export class AchOffsetAccountsTable extends TmBaseComponent {
    @property()
    protected readonly verifiedPropNames: (keyof this)[] = ['accounts'];

    @property({ type: Array })
    accounts!: OffsetAccount[];

    @state()
    filteredAccounts: OffsetAccount[] = [];

    @state()
    searchTerm = '';

    firstUpdated() {
        this.filteredAccounts = this.accounts;
    }

    filterResults(e: CustomEvent) {
        this.filteredAccounts = filterTableResults(e.detail.value, this.accounts, [
            'name',
            'availableBalance',
        ]) as OffsetAccount[];
    }

    renderName(account: OffsetAccount) {
        return html` <div class="flex flex-col">
            <span class="font-medium text-sm overflow-hidden text-ellipsis">${account.name}</span>
            <span class="text-xs">x${account.number}</span>
        </div>`;
    }

    renderTable() {
        if (this.accounts.length === 0) {
            return html`<div class="mx-auto w-full text-center mt-2 empty-message">
                No Offset Accounts Found
            </div>`;
        }
        type CustomLitRenderer = GridColumnBodyLitRenderer<OffsetAccount>;
        return html`<vaadin-grid
            all-rows-visible
            class="border-y border-neutral-200 "
            .items=${this.filteredAccounts}
            @active-item-changed=${(e: GridActiveItemChangedEvent<OffsetAccount>) => {
                if (e.detail.value) {
                    this.dispatchEvent(
                        new CustomEvent('select-offset-account', {
                            detail: e.detail.value,
                            bubbles: true,
                        }) as OffsetAccountEvent
                    );
                }
                this.dispatchEvent(new CustomEvent('close', { bubbles: true }));
            }}
        >
            <vaadin-grid-column
                ${columnBodyRenderer(this.renderName as CustomLitRenderer, [])}
            ></vaadin-grid-column>
        </vaadin-grid>`;
    }

    render() {
        return html`<div class="pwa-table-wrapper">
            <tm-table-header
                .filterButton=${true}
                @filterButtonClick=${() =>
                    this.dispatchEvent(new CustomEvent('filter-offset-accounts'))}
                @filterStringChange=${(e: CustomEvent) => this.filterResults(e)}
            ></tm-table-header>
            ${this.renderTable()}
        </div>`;
    }

    static get styles() {
        return [
            css`
                vaadin-grid-cell-content {
                    padding: 6px 16px 6px 0;
                }
                vaadin-grid::part(first-column-cell) {
                    margin-left: 16px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchOffsetAccountsTable;
    }
}
