import { deepEquals } from '@treasury/utils';
import '@vaadin/multi-select-combo-box';
import { MultiSelectComboBox } from '@vaadin/multi-select-combo-box';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';
import { TmInputFieldBase } from './tm-input-field.base';

export interface MultiSelectItem<T = any> {
    label: string;
    value: T;
    id?: number;
}

export const tagName = 'tm-multi-select';
@customElement(tagName)
export class TmMultiSelect extends TmInputFieldBase {
    @property({ type: String })
    placeholder = 'Type to search';

    private _items: MultiSelectItem[] = [];

    private _selectedItems: MultiSelectItem[] = [];

    @property({ type: Object })
    get items() {
        return this._items;
    }

    set items(values: MultiSelectItem[]) {
        const oldVal = this.items;
        this._items = values.map((item, i) => {
            if (typeof item === 'object' && !('id' in item)) {
                item.id = i + 1;
            }
            return item;
        }) as unknown as MultiSelectItem[];

        if (this.allowSelectAll) {
            this._items.unshift(this.selectAllItem);
        }

        if (!deepEquals(this.items, oldVal)) {
            this.requestUpdate('items', oldVal);
        }
    }

    @property({ type: Object })
    get selectedItems() {
        return this._selectedItems;
    }

    set selectedItems(items: MultiSelectItem[]) {
        const prevLength = this._selectedItems.length;
        if (
            this.allowSelectAll &&
            !this.includesSelectAll(items) &&
            items.length === this.items.length - 1
        ) {
            this._selectedItems = [...items];
            this._selectedItems.push(this.selectAllItem);
        } else {
            this._selectedItems = items;
        }

        if (this.includesSelectAll(this.selectedItems)) {
            this.setAttribute('allSelected', '');
            if (this.inputRef.value) {
                const selectAllChip: any = this.inputRef.value.querySelector(
                    'vaadin-multi-select-combo-box-chip[title="Select All"]'
                );
                if (selectAllChip) {
                    selectAllChip.label = this.allSelectedText;
                }
            }
        } else {
            this.removeAttribute('allSelected');
        }

        if (this.inputRef.value && this._selectedItems.length !== prevLength) {
            this.inputRef.value.selectedItems = this._selectedItems;
        }
    }

    @property({ type: Boolean })
    allowSelectAll = false;

    @property({ type: Boolean })
    allSelectedText = 'All Selected';

    private selectAllItem: MultiSelectItem = {
        label: 'Select All',
        value: 'All',
        id: 0,
    };

    inputRef: Ref<MultiSelectComboBox> = createRef();

    includesSelectAll = (items: MultiSelectItem[]) => items.some(item => item.id === 0);

    isSameItem = (a: MultiSelectItem, b: MultiSelectItem) => a.id === b.id;

    onSelectAll(newlySelectedItems: MultiSelectItem[]) {
        if (newlySelectedItems.length > this.selectedItems.length) {
            let selectedItem = newlySelectedItems.find(
                curItem => !this.selectedItems.some(prevItem => this.isSameItem(curItem, prevItem))
            ) as MultiSelectItem;
            if (selectedItem.id === 0) {
                // 'Select All' needs to be added by the Setter so that it's at the end of the array and the chip displays
                const allItemsMinusSelectAll: MultiSelectItem[] = this.items.filter(
                    (item: MultiSelectItem) => item.id !== 0
                );
                this.selectedItems = [...allItemsMinusSelectAll];
            } else {
                this.selectedItems = newlySelectedItems;
            }
            return this.selectedItems;
        }

        if (newlySelectedItems.length < this.selectedItems.length) {
            let unselectedItem = this.selectedItems.find(
                prevItem => !newlySelectedItems.some(curItem => this.isSameItem(curItem, prevItem))
            ) as MultiSelectItem;
            if (unselectedItem.id === 0) {
                this.selectedItems = [];
            } else {
                this.selectedItems = newlySelectedItems.filter(
                    (item: MultiSelectItem) => item.id !== 0
                );
            }
        }

        return this.selectedItems;
    }

    /** Example for possible future feature that would allow for the display of disabled items **/
    /* private _boundComboBoxRenderer = this.comboBoxRenderer.bind(this);

    comboBoxRenderer(root, comboBox, model) {
        const {label, disabled} = model.item;
        const item = root.getRootNode().host;
        
        // toggle disabled state
        if (disabled) {
          item.setAttribute('disabled', '');
          item.style.pointerEvents = 'none';
          item.style.opacity = '0.75';
        } else {
          item.removeAttribute('disabled');
          item.style.pointerEvents = '';
          item.style.opacity = '';
        }
        
        root.textContent = label;
      } */

    render() {
        return html`
            <vaadin-multi-select-combo-box
                ${ref(this.inputRef)}
                @selected-items-changed=${(e: CustomEvent) => {
                    let selectedItems = e.detail.value;
                    if (deepEquals(this.selectedItems, selectedItems)) {
                        return;
                    }

                    if (this.allowSelectAll) {
                        selectedItems = this.onSelectAll(selectedItems);
                        selectedItems = selectedItems.filter(
                            (item: MultiSelectItem) => item.id !== 0
                        );
                    }

                    const changeEvent = new CustomEvent('selected-items-changed', {
                        detail: selectedItems,
                    });
                    this.dispatchEvent(changeEvent);
                }}
                class="w-full"
                part="component"
                label=${this.label}
                .value=${this.value}
                .items=${this.items}
                .selectedItems=${this.selectedItems}
                itemIdPath="id"
                placeholder=${this.placeholder}
                helper-text=${ifDefined(this.helperText)}
                ?required=${this.required}
                ?readonly=${this.readonly}
                ?disabled=${this.disabled}
                ?has-placeholder=${!!this.placeholder}
            >
            </vaadin-multi-select-combo-box>
        `;
    }

    static get styles() {
        return [
            ...super.styles,
            css`
                :host([allSelected])
                    vaadin-multi-select-combo-box
                    [slot='chip']:not([title='Select All']),
                :host([allSelected]) vaadin-multi-select-combo-box [slot='overflow'] {
                    display: none;
                }

                :host([allSelected])
                    vaadin-multi-select-combo-box
                    [slot='chip'][title='Select All']
                    [part='Label'] {
                    color: blue;
                }

                vaadin-multi-select-combo-box [slot='overflow']::before,
                vaadin-multi-select-combo-box [slot='overflow']::after {
                    border-left: calc(var(--lumo-space-s) / 4) solid;
                    border-color: var(--lumo-contrast-30pct);
                }

                vaadin-multi-select-combo-box-chip:last-of-type {
                    margin-right: 10px;
                }

                vaadin-multi-select-combo-box-chip::part(label) {
                    position: inherit;
                    top: inherit;
                    left: inherit;
                }

                [focused]::part(label),
                [has-value]::part(label),
                [has-placeholder]::part(label) {
                    padding-bottom: 2px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmMultiSelect;
    }
}
