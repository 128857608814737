/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable lit/no-native-attributes */
import { fontAwesome } from '@treasury/omega/css/icons/font-awesome.js';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { TmBaseComponent } from '..';

export const tagName = 'tm-tooltip-rich';
@customElement(tagName)
export class TmTooltipRich extends TmBaseComponent {
    @property({ type: Boolean, reflect: true })
    isClickable = false;

    @property({ type: String })
    direction = 'top';

    @property({ type: String })
    icon = '';

    @property({ type: Boolean, reflect: true })
    light = false;

    @property({ type: String })
    message = '';

    @property({ type: Boolean, reflect: true })
    show = false;

    @property({ type: String })
    class = '';

    @state()
    private _boundListenForDocumentClicks: EventListenerOrEventListenerObject =
        this._listenForDocumentClicks.bind(this) as any;

    @state()
    private _boundListenForKeydown: EventListenerOrEventListenerObject =
        this._listenForKeydown.bind(this) as any;

    @state()
    private tooltipBody: HTMLElement | undefined | null = undefined;

    @state()
    relativePosition: DOMRect = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        toJSON: () => '',
    };

    @state()
    boundDismiss: () => void = this._hideTooltip.bind(this);

    @state()
    width = 0;

    @state()
    height = 0;

    connectedCallback(): void {
        // eslint-disable-next-line wc/guard-super-call
        super.connectedCallback();
        this._boundListenForDocumentClicks = this._listenForDocumentClicks.bind(this) as any;
        this._boundListenForKeydown = this._listenForKeydown.bind(this) as any;
    }

    firstUpdated() {
        this.tooltipBody = this.shadowRoot?.querySelector('.tm-tooltip-rich');
        this._setDimensions();
        this.relativePosition = this.getBoundingClientRect();
        this._setTooltipClass();
        if (this.isClickable) {
            this.addEventListener('click', this._showTooltip);
        }
        if (this.show) {
            this._addOrRemoveListeners(this.show);
        }
    }

    _toggleShow() {
        if (this.isClickable) {
            this.show = !this.show;
        }

        this._addOrRemoveListeners(this.show);
    }

    _addOrRemoveListeners(show: boolean) {
        if (show) {
            this.focus();
            document.addEventListener('keydown', this._boundListenForKeydown);
            document.addEventListener('click', this._boundListenForDocumentClicks);
        } else {
            document.removeEventListener('keydown', this._boundListenForKeydown);
            document.removeEventListener('click', this._boundListenForDocumentClicks);
        }
    }

    _listenForDocumentClicks(e: MouseEvent) {
        const shouldClose = !(e.composedPath() || []).includes(this);

        if (shouldClose) {
            e.preventDefault();
            this._hideTooltip();
        }
    }

    _listenForKeydown(e: KeyboardEvent) {
        if (e.key === 'Escape') {
            this._hideTooltip();
        }
    }

    _showTooltip() {
        if (this.isClickable) {
            this.show = true;
            this._addOrRemoveListeners(this.show);
            this.removeEventListener('click', this._showTooltip);
        }
    }

    _hideTooltip() {
        if (this.isClickable) {
            this.show = false;
            window.requestAnimationFrame(() => {
                this.addEventListener('click', this._showTooltip);
            });
        }
    }

    _setDimensions() {
        this.height =
            parseInt(
                window.getComputedStyle(this.tooltipBody || document.createElement('div')).height,
                10
            ) +
            parseInt(
                window.getComputedStyle(this.tooltipBody || document.createElement('div'))
                    .paddingTop,
                10
            ) +
            parseInt(
                window.getComputedStyle(this.tooltipBody || document.createElement('div'))
                    .paddingBottom,
                10
            );
        this.width =
            parseInt(
                window.getComputedStyle(this.tooltipBody || document.createElement('div')).width,
                10
            ) +
            parseInt(
                window.getComputedStyle(this.tooltipBody || document.createElement('div'))
                    .paddingRight,
                10
            ) +
            parseInt(
                window.getComputedStyle(this.tooltipBody || document.createElement('div'))
                    .paddingLeft,
                10
            );
    }

    _setTooltipClass() {
        const direction = this._setTooltipDirection();
        const brightness = this.light ? 'tooltip-light' : '';
        this.class = `tm-tooltip-rich ${brightness} ${direction}`;
    }

    _setTooltipDirection() {
        if (this.direction === undefined) return this._calcTooltipDirection();
        if (this.direction.trim().length === 0) return this._calcTooltipDirection();
        return `tooltip-${this.direction}`;
    }

    _calcTooltipDirection() {
        if (this.height > this.relativePosition.y) return 'tooltip-bottom';
        if (this.width / 2 > this.relativePosition.x) return 'tooltip-right';
        if (this.width > window.innerWidth - this.relativePosition.x) return 'tooltip-left';
        return 'tooltip-top';
    }

    renderIcon() {
        if (!this.icon) return nothing;
        return html`<i
            class="tm-tooltip-rich-icon fa fa-${this.icon}"
            @click=${this._toggleShow}
            @keypress=${(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    this._toggleShow();
                }
            }}
        ></i>`;
    }

    renderTooltipContent() {
        if (this.message) return html`${this.message}`;
        if (!this.isClickable) {
            return html`<div>
                <slot name="content"></slot>
            </div> `;
        }
        return html`<div>
            <button
                id="tooltip-close-button"
                class="tm-tooltip-rich-close-button"
                @click=${this.boundDismiss}
            >
                <i class="fa fa-times tm-tooltip-rich-close" role="button" aria-label="Close"></i>
            </button>
            <slot name="content"></slot>
        </div>`;
    }

    renderTooltipTrigger() {
        if (this.isClickable) {
            return html`<button id="tooltip-trigger" @click=${this._toggleShow}>
                <slot></slot>
            </button>`;
        }
        return html`<div id="tooltip-trigger">
            <slot></slot>
        </div>`;
    }

    render() {
        return html`<span>
            ${this.renderIcon()} ${this.renderTooltipTrigger()}
            <div class=${this.class}>${this.renderTooltipContent()}</div>
        </span>`;
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                .isClickable {
                    cursor: pointer;
                }
                :host {
                    --tm-tooltip-rich-transition: all 0.1s cubic-bezier(0.84, -0.18, 0.65, 1.13);
                    --tm-tooltip-rich-background-color: var(--omega-secondary);
                }
                :host([light]) {
                    --tm-tooltip-rich-background-color: var(--primary-background);
                }
                :host([icon]) {
                    display: inline-block;
                }

                .tm-tooltip-rich-icon {
                    font-size: 16px;
                    color: var(--omega-secondary-lighten-100);
                }

                span {
                    display: var(--tooltip-span-display, flex);
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    white-space: nowrap;
                    min-width: 25px;
                }

                #tooltip-trigger {
                    background: none;
                    border: none;
                    margin: 0;
                    padding: 0;
                }

                .tm-tooltip-rich.tooltip-light {
                    --tm-tooltip-rich-background-color: var(--primary-background);
                    color: var(--omega-text-default);
                    border-radius: 7px;
                    filter: drop-shadow(0 0 5px var(--omega-secondary-lighten-200));
                }

                .tm-tooltip-rich {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    text-align: left;
                    white-space: normal;
                }

                .tm-tooltip-rich:focus-within {
                    outline: none;
                }

                .tm-tooltip-rich::before,
                .tm-tooltip-rich {
                    opacity: 0;
                    position: absolute;
                    z-index: -100;
                }

                .tm-tooltip-rich::before {
                    border-style: solid;
                    border-width: 16px 12px 0 12px;
                    border-color: var(--tm-tooltip-rich-background-color) transparent transparent
                        transparent;
                    content: '';
                    transition: var(--tooltip-transition);
                    bottom: -8px;
                }

                .tm-tooltip-rich.tooltip-bottom-right::before,
                .tm-tooltip-rich.tooltip-bottom-left::before,
                .tm-tooltip-rich.tooltip-bottom::before {
                    border-width: 0 12px 16px 12px;
                    border-color: transparent transparent var(--tm-tooltip-rich-background-color)
                        transparent;
                    top: -8px;
                    bottom: auto;
                }

                .tm-tooltip-rich.tooltip-top-right::before,
                .tm-tooltip-rich.tooltip-bottom-right::before {
                    left: 3px;
                }
                .tm-tooltip-rich.tooltip-top-left::before,
                .tm-tooltip-rich.tooltip-bottom-left::before {
                    right: 3px;
                }

                .tm-tooltip-rich.tooltip-right-bottom::before,
                .tm-tooltip-rich.tooltip-right-top::before,
                .tm-tooltip-rich.tooltip-right::before {
                    border-width: 12px 16px 12px 0;
                    border-color: transparent var(--tm-tooltip-rich-background-color) transparent
                        transparent;
                    left: -8px;
                    bottom: auto;
                }

                .tm-tooltip-rich.tooltip-left-bottom::before,
                .tm-tooltip-rich.tooltip-left-top::before,
                .tm-tooltip-rich.tooltip-left::before {
                    border-width: 12px 0 12px 16px;
                    border-color: transparent transparent transparent
                        var(--tm-tooltip-rich-background-color);
                    right: -8px;
                    bottom: auto;
                }

                .tm-tooltip-rich.tooltip-right-top::before,
                .tm-tooltip-rich.tooltip-left-top::before {
                    top: var(--tm-tooltip-rich-indicator-top, 45px);
                }

                .tm-tooltip-rich.tooltip-right-bottom::before,
                .tm-tooltip-rich.tooltip-left-bottom::before {
                    bottom: 45px;
                    top: auto;
                }

                .tm-tooltip-rich {
                    background: var(--tm-tooltip-rich-background-color);
                    border-radius: 0.25em;
                    bottom: calc(100% + 12px);
                    color: var(--omega-info-lighten-300);
                    font-size: 12px;
                    padding: 16px;
                    transition: var(--tooltip-transition);
                    width: max-content;
                    visibility: hidden;
                }

                .tm-tooltip-rich.tooltip-bottom {
                    top: calc(100% + 12px);
                    bottom: auto;
                }

                .tm-tooltip-rich.tooltip-right-top,
                .tm-tooltip-rich.tooltip-right-bottom,
                .tm-tooltip-rich.tooltip-right {
                    left: calc(100% + 12px);
                    bottom: auto;
                }

                .tm-tooltip-rich.tooltip-left-bottom,
                .tm-tooltip-rich.tooltip-left-top,
                .tm-tooltip-rich.tooltip-left {
                    right: calc(100% + 12px);
                    bottom: auto;
                }

                .tm-tooltip-rich.tooltip-right-top,
                .tm-tooltip-rich.tooltip-left-top {
                    top: var(--tm-tooltip-rich-left-top-top, -50px);
                }

                .tm-tooltip-rich.tooltip-right-bottom,
                .tm-tooltip-rich.tooltip-left-bottom {
                    bottom: -50px;
                    top: auto;
                }

                .tm-tooltip-rich.tooltip-bottom-right {
                    left: 0;
                    top: calc(100% + 12px);
                    bottom: auto;
                }

                .tm-tooltip-rich.tooltip-top-right {
                    left: 0;
                }

                .tm-tooltip-rich.tooltip-top-left {
                    right: 0;
                }

                .tm-tooltip-rich.tooltip-bottom-left {
                    right: -4px;
                    top: calc(100% + 12px);
                    bottom: auto;
                }

                .tm-tooltip-rich-close-button {
                    position: absolute;
                    right: 10px;
                    top: 7px;
                    background: none;
                    border: none;
                    padding: 0;
                }
                .tm-tooltip-rich-close {
                    font-weight: normal;
                    color: var(--omega-text-tertiary);
                    cursor: pointer;
                    font-size: var(--omega-label);
                }

                :host([show]) .tm-tooltip-rich::before,
                :host([show]) .tm-tooltip-rich,
                :host(:not([isClickable]):hover) .tm-tooltip-rich::before,
                :host(:not([isClickable]):focus-within) .tm-tooltip-rich::before,
                :host(:not([isClickable]):hover) .tm-tooltip-rich,
                :host(:not([isClickable]):focus-within) .tm-tooltip-rich {
                    opacity: 1;
                    z-index: 100;
                    visibility: visible;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmTooltipRich;
    }
}
