export const nullPendingUser = {
    id: 0,
    userId: undefined,
    userName: undefined,
    isLocked: false,
    isMfaLocked: false,
    isAdmin: true,
    isSuperUser: true,
    status: undefined,
    publishState: undefined,
    isActive: true,
    isEditor: false,
    isDeleted: undefined,
};
