/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    ErrorHttpContentDto,
    GetCreatedByUsersForIssuedItemFilesResponseDto,
    PagedListModelOfIssuedItemFileDto,
    SearchIssuedItemFileDetailsRequestDto,
    SearchIssuedItemFileDetailsResponseDto,
    SearchIssuedItemFilesActivityRequestDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class IssuedItemFileClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags IssuedItemFile
     * @name IssuedItemFileGetCreatedByUsers
     * @summary Retrieves list of eligible users that have created an issued item file.
     * @request GET:/issuedItemFile/createdByUsers
     */
    issuedItemFileGetCreatedByUsers = (params: RequestParams = {}) =>
        this.http.request<GetCreatedByUsersForIssuedItemFilesResponseDto, ErrorHttpContentDto>({
            path: `/issuedItemFile/createdByUsers`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags IssuedItemFile
     * @name IssuedItemFileSearchIssuedItemFilesActivity
     * @summary Retrieves list of issued item file records matching the search criteria.
     * @request POST:/issuedItemFile/searchIssuedItemFilesActivity
     */
    issuedItemFileSearchIssuedItemFilesActivity = (
        searchIssuedItemFilesActivityRequest: SearchIssuedItemFilesActivityRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<PagedListModelOfIssuedItemFileDto, ErrorHttpContentDto>({
            path: `/issuedItemFile/searchIssuedItemFilesActivity`,
            method: 'POST',
            body: searchIssuedItemFilesActivityRequest,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags IssuedItemFile
     * @name IssuedItemFileSearchIssuedItemFileDetails
     * @summary Retrieves an issued item file and its details list records matching the search criteria.
     * @request POST:/issuedItemFile/searchFileDetails
     */
    issuedItemFileSearchIssuedItemFileDetails = (
        searchIssuedItemFileDetailsRequest: SearchIssuedItemFileDetailsRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<SearchIssuedItemFileDetailsResponseDto, ErrorHttpContentDto>({
            path: `/issuedItemFile/searchFileDetails`,
            method: 'POST',
            body: searchIssuedItemFileDetailsRequest,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
