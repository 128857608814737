import { AchLimits } from '@treasury/domain/users/product-features';
import { formatUsdCurrency } from '@treasury/utils';

export type UserAchLimitsCompany = {
    companyName: string;
    companyId: string;
    id: number;
    transactionLimit: {
        value: string;
        edited: boolean;
    };
    dailyLimit: {
        value: string;
        edited: boolean;
    };
    companyDailyLimit: string;
};

export class UserAchLimitsViewModel {
    constructor(private readonly value: AchLimits) {}

    get dailyLimit() {
        return formatUsdCurrency(this.value.dailyLimit);
    }

    get achLimits() {
        return {
            initiationLimit: formatUsdCurrency(
                Number(this.value.achLimits.initiationLimit.value) || 0
            ),
            initiationLimitEdited: this.value.achLimits.initiationLimit.edited,
            approvalLimit: formatUsdCurrency(Number(this.value.achLimits.approvalLimit.value) || 0),
            approvalLimitEdited: this.value.achLimits.approvalLimit.edited,
        };
    }

    get userAchCompanies() {
        return this.value.userAchCompanies?.map(company => ({
            companyName: company.companyName,
            companyId: company.companyId,
            id: company.id,
            transactionLimit: {
                value: formatUsdCurrency(Number(company.dailyLimit.value) || 0),
                edited: company.dailyLimit.edited,
            },
            dailyLimit: {
                value: formatUsdCurrency(Number(company.transactionLimit.value) || 0),
                edited: company.transactionLimit.edited,
            },
            companyDailyLimit: formatUsdCurrency(company.companyDailyLimit),
        }));
    }
}
