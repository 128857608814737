import { Payee } from '@treasury/domain/bill-pay';

export class BillPayPayeeViewModel {
    constructor(readonly value: Payee) {}

    get nickName() {
        return this.value.nickName;
    }

    get name() {
        return this.value.name;
    }

    get paymentMethod() {
        return this.value.paymentMethod;
    }

    get account() {
        return this.value.account;
    }

    get accountName() {
        return this.value.account?.name;
    }

    get accountNumber() {
        return this.value.account?.accountNumber;
    }

    get accountType() {
        return this.value.account?.accountType;
    }

    get phoneNumber() {
        return this.value.phoneNumber;
    }

    get address() {
        if (this.value.address) {
            const { line1, line2, city, state, postalCode, country } = this.value.address;
            return `${line1}${line2 ? `, ${line2}` : ''}, ${city}, ${state} ${postalCode}${
                country ? `, ${country}` : ''
            }`;
        }
        return '';
    }

    get id() {
        return this.value.id;
    }

    get defaultPayFromAccount() {
        return this.value.defaultPayFromAccount;
    }

    get status() {
        return this.value.status;
    }
}
