import { PaymentRecipientDto as Recipient } from '@treasury/domain/channel/types/ach';
import { LabeledList, TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/forms/tm-form-row';
import '@treasury/presentation/components/layout/tm-content';
import '@treasury/presentation/components/layout/tm-section';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-labeled-list';
import { yesNoFormatter } from '@treasury/utils';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export const tagName = 'ach-recipient-detail';
@customElement(tagName)
export class AchRecipientDetail extends TmBaseComponent {
    @property()
    public recipient?: Recipient;

    render() {
        if (!this.recipient) return nothing;
        const recipient = {
            ...this.recipient,
            idNumber: this.recipient.id,
            prenote: yesNoFormatter(this.recipient.prenote),
            transactionType: this.recipient.transactionType === 'CR' ? 'Credit' : 'Debit',
        };
        return html`<tm-content>
            <tm-section>
                <div class="flex justify-between my-2 px-4">
                    <div class="text-lg font-medium">${this.recipient?.name}</div>
                    <div class="flex items-center">
                        <div
                            class="bg-[--status-chip-background] text-[--status-chip-color] p-1 text-xs uppercase"
                        >
                            ${recipient.transactionType}
                        </div>
                    </div>
                </div>

                <tm-labeled-list
                    class="px-4"
                    .list=${new LabeledList(recipient, [
                        'idNumber',
                        'accountNumber',
                        'accountType',
                        'routingNumber',
                        'prenote',
                    ])}
                ></tm-labeled-list>

                <tm-form-row label="Amount">
                    <tm-currency-field
                        large
                        .fieldId=${this.recipient.id}
                        .value=${this.recipient.amount.toString() || ''}
                        @value-changed=${({ detail }: CustomEvent) => {
                            if (this.recipient) {
                                this.recipient.amount = detail;
                            }
                        }}
                    ></tm-currency-field>
                </tm-form-row>

                <tm-form-row label="Hold">
                    <tm-checkbox
                        .value=${this.recipient.hold}
                        .checked=${this.recipient.hold}
                        @checked-changed=${(e: CustomEvent) => {
                            if (this.recipient) {
                                this.recipient.hold = e.detail.value;
                            }
                        }}
                    ></tm-checkbox>
                </tm-form-row>

                <tm-form-row label="Addenda">
                    <tm-text-field
                        large
                        class="grow"
                        placeholder="optional"
                        .value=${this.recipient.addendaBody || ''}
                        @value-changed=${({ detail }: CustomEvent) => {
                            if (this.recipient) {
                                this.recipient.addendaBody = detail.value;
                            }
                        }}
                    ></tm-text-field>
                </tm-form-row>
            </tm-section>
        </tm-content>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchRecipientDetail;
    }
}
