import { ListeningElement } from '@treasury/omega/components/listening-element';
import { OmegaAlertConfig, OmegaAlertService } from '@treasury/omega/services/omega-alert';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { nothing } from 'lit';
import { state } from 'lit/decorators.js';
import { StyleMixin } from './mix-ins/style-mixin';

/**
 * Treasury web component base that providing common
 * functionality and abstractions across PWA components.
 *
 * Cross-cutting functionality includes:
 *   * Alerting (to be deprecated)
 *   * Event subscription management
 *   * Required property verification
 */
export abstract class TmBaseComponent extends StyleMixin(ListeningElement) {
    @state()
    protected alert?: Partial<OmegaAlertConfig>;

    @InjectProperty()
    private declare readonly alertService: OmegaAlertService;

    /**
     * @deprecated Stopgap convenience method.
     * `OmegaAlertService` should eventually be consumed directly for displaying alerts.
     */
    protected renderAlert(alert?: Partial<OmegaAlertConfig>) {
        alert = alert || this.alert;
        if (!alert) {
            return nothing;
        }

        return this.alertService.renderAlert(alert);
    }
}
