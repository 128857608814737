import { TmBaseComponent } from '@treasury/presentation';
import {
    circleCheckmarkIcon,
    circleCloseIcon,
    circleWarningIcon,
    clockIcon,
    informationIcon,
} from '@treasury/presentation/assets/icons';
import { InjectProperty } from '@treasury/utils';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { HeaderBarService } from '../services/jhd-header-bar.service';

export enum ConfirmationType {
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
    Pending = 'pending',
}

export const tagName = 'confirmation-container';
@customElement(tagName)
export class ConfirmationContainer extends TmBaseComponent {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @property()
    confirmationType: ConfirmationType = ConfirmationType.Success;

    @property()
    headingText = '';

    @property()
    subheadingText = '';

    get iconConfig() {
        switch (this.confirmationType) {
            case ConfirmationType.Success:
                return { icon: circleCheckmarkIcon, color: `#047712` };
            case ConfirmationType.Error:
                return { icon: circleCloseIcon, color: `#c91d07` };
            case ConfirmationType.Warning:
                return { icon: circleWarningIcon, color: `#ec9241` };
            case ConfirmationType.Pending:
                return { icon: informationIcon, color: `#0164cc` };
            default:
                return { icon: circleCheckmarkIcon, color: `#047712` };
        }
    }

    protected firstUpdated(): void {
        this.headerService.configure({
            backAction: null,
            title: '',
            subtitle: '',
            empty: true,
        });
    }

    render() {
        return html`
            <div class="flex items-center flex-col">
                <jhd-icon
                    class="confirmation-icon pt-12"
                    .color=${this.iconConfig.color}
                    .icon=${this.iconConfig.icon}
                ></jhd-icon>
                <h2 class="pt-12 text-lg">${this.headingText}</h2>
                <p class="text-md text-[--secondary-text-color]">${this.subheadingText}</p>
            </div>
            <div class="p-3">
                <slot></slot>
            </div>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    height: 100%;
                    background-color: var(--primary-background);
                }

                .confirmation-icon {
                    --icon-svg-transform: scale(5);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: ConfirmationContainer;
    }
}
