/* eslint-disable import/no-duplicates */
import { WireModelDto } from '@treasury/api/channel';
import { NavigationService } from '@treasury/core/navigation';
import { FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-loader';
import '@treasury/presentation/components/tm-table-header';
import { InjectProperty } from '@treasury/utils';
import '@vaadin/grid';
import { columnBodyRenderer, GridColumnBodyLitRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { filterTableResults } from '../../../../utilities/table-result-filter';
import '../../../components/jhd-status';
import { getStatusColorToken } from '../../../components/jhd-status';
import { WirePaymentTableViewModel, WirePaymentViewModel } from '../data/wire-payment-view-model';

export const tagName = 'wire-payments-table';
@customElement(tagName)
export class WirePaymentsTable extends TmBaseComponent {
    @InjectProperty()
    private declare featureFlagService: FeatureFlagService;

    @property({ type: Array })
    wirePayments: WireModelDto[] = [];

    @state()
    private wirePaymentViewModels: WirePaymentViewModel[] = [];

    @state()
    private filteredWirePaymentViewModels: WirePaymentViewModel[] = [];

    @InjectProperty()
    private declare readonly navService: NavigationService;

    @property({ type: Boolean })
    loading = true;

    @state()
    private fieldsToFilter = ['beneficiaryName', 'status', 'amount', 'debitAccount', 'paymentDate'];

    updated(changedProps: Map<string, any>) {
        if (changedProps.has('wirePayments')) {
            this.buildWireViewModels();
        }
    }

    buildWireViewModels() {
        this.wirePaymentViewModels = this.wirePayments.map(
            (wirePayment: any) => new WirePaymentTableViewModel(wirePayment)
        );
        this.filteredWirePaymentViewModels = this.wirePaymentViewModels;
    }

    wireClicked(wirePayment: WirePaymentViewModel) {
        if (!wirePayment) return;
        this.navService.navigate(`/wires/${wirePayment.id}`);
    }

    filterResults(e: CustomEvent) {
        this.filteredWirePaymentViewModels = filterTableResults(
            e,
            this.wirePaymentViewModels,
            this.fieldsToFilter
        );
    }

    renderStatusIconColumn(wirePayment: WirePaymentViewModel) {
        return html`<jhd-status .status=${wirePayment.status}></jhd-status>`;
    }

    renderPaymentNameAndStatusColumn(wirePayment: WirePaymentViewModel) {
        return html`<div class="flex -ml-4">
            <div class="flex flex-col">
                <span class="payment-name text-sm font-medium">${wirePayment.beneficiaryName}</span>
                <small class="payment-transaction-id text-xs text-gray-500"
                    >From ${wirePayment.debitAccount}</small
                >
                <small
                    class="payment-status text-xs"
                    style=${`color: var(${getStatusColorToken(wirePayment.status)})`}
                    >${wirePayment.status}</small
                >
            </div>
        </div>`;
    }

    renderAmountAndAccountColumn(wirePayment: WirePaymentViewModel) {
        return html`<div class="flex flex-col">
            <span class="payment-amount text-right text-sm font-medium">${wirePayment.amount}</span>
            <small class="payment-transaction-id text-right text-xs text-gray-500"
                >${wirePayment.paymentDate}</small
            >
        </div>`;
    }

    renderTable() {
        if (this.loading) return html`<tm-loader card></tm-loader>`;
        if (!this.wirePayments.length)
            return html`<div class="mx-auto w-full text-center mt-5 empty-message">
                No Wires Found
            </div>`;
        return html` <vaadin-grid
            class="border-y border-[--border-color]"
            .items=${this.filteredWirePaymentViewModels}
            @active-item-changed=${({ detail }: CustomEvent) => this.wireClicked(detail.value)}
            .style=${`height: ${61 * this.filteredWirePaymentViewModels.length + 10}px;`}
        >
            <vaadin-grid-column
                flex-grow="0"
                width="3.5rem"
                ${columnBodyRenderer(
                    this.renderStatusIconColumn as GridColumnBodyLitRenderer<WirePaymentViewModel>,
                    []
                )}
            ></vaadin-grid-column>
            <vaadin-grid-column
                flex-grow="1"
                ${columnBodyRenderer(
                    this
                        .renderPaymentNameAndStatusColumn as GridColumnBodyLitRenderer<WirePaymentViewModel>,
                    []
                )}
            ></vaadin-grid-column>
            <vaadin-grid-column
                ${columnBodyRenderer(
                    this
                        .renderAmountAndAccountColumn as GridColumnBodyLitRenderer<WirePaymentViewModel>,
                    []
                )}
            ></vaadin-grid-column>
        </vaadin-grid>`;
    }

    render() {
        return html`<div class="pwa-table-wrapper">
            <tm-table-header
                .filterButton=${true}
                @filterButtonClick=${() =>
                    this.dispatchEvent(new CustomEvent('filter-wire-payments'))}
                @filterStringChange=${(e: CustomEvent) => this.filterResults(e)}
                ><h2 class="font-medium">Wire Activity</h2></tm-table-header
            >
            ${this.renderTable()}
        </div>`;
    }

    static get styles() {
        return [
            css`
                .payment-amount {
                    text-align: right;
                }
                .table-header {
                    color: #7f7676;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15px 10px 0 10px;
                }
                .empty-message,
                .table-header {
                    color: var(--header-text-color);
                }
                button[disabled] {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
                vaadin-grid::part(first-column-cell) {
                    border-top: none;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: WirePaymentsTable;
    }
}
