import '@vaadin/select';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { TmInputFieldBase } from './tm-input-field.base';
export interface SelectItem {
    label?: string;
    value?: string;
    component?: string;
    disabled?: boolean;
}

export const tagName = 'tm-select';
@customElement(tagName)
export class TmSelect extends TmInputFieldBase {
    @property({ type: Object })
    items: SelectItem[] = [];

    render() {
        return html`
            <vaadin-select
                @value-changed=${(e: CustomEvent) => {
                    const valueChangedEvent = new CustomEvent('value-changed', {
                        detail: e.detail,
                    });
                    this.dispatchEvent(valueChangedEvent);
                }}
                @change=${(e: CustomEvent) => {
                    const userChangeEvent = new CustomEvent('change', {
                        detail: e.detail,
                    });
                    this.dispatchEvent(userChangeEvent);
                }}
                class="w-full"
                part="component"
                label=${this.label}
                .value=${this.value}
                .items=${this.items}
                placeholder=${ifDefined(this.placeholder)}
                helper-text=${ifDefined(this.helperText)}
                ?required=${this.required}
                ?readonly=${this.readonly}
                ?disabled=${this.disabled}
            >
            </vaadin-select>
        `;
    }

    static get styles() {
        return [
            ...super.styles,
            css`
                vaadin-select-value-button::part(label) {
                    position: inherit;
                    padding: 0px !important;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmSelect;
    }
}
