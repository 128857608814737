import { PropertyValueMap, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { chevronBackIcon, chevronForwardIcon } from '../assets/icons';
import { TmBaseComponent } from '../tm-base.component';

const KEYCODE = {
    DOWN: 40,
    LEFT: 37,
    RIGHT: 39,
    UP: 38,
    ENTER: 13,
    TAB: 9,
};

export const tagName = 'tm-tabs';
@customElement(tagName)
export class TmTabs extends TmBaseComponent {
    @property({ type: Array })
    public tabs = [] as Array<HTMLElement>;

    @property()
    public activeTab?: string | number;

    @state()
    private isScrollStart = false;

    @state()
    private isScrollEnd = false;

    get activeTabElements() {
        return Array.from(
            this.shadowRoot?.querySelectorAll(
                '.tab:not([disabled])'
            ) as unknown as Array<HTMLElement>
        );
    }

    protected firstUpdated(): void {
        const container = this.shadowRoot?.querySelector('.scroll-container') as HTMLElement;
        container.addEventListener('scroll', () => {
            this.checkScroll();
        });
    }

    protected updated(changedProperties: PropertyValueMap<any>): void {
        if (changedProperties.has('tabs')) {
            this.checkScroll();
        }
    }

    checkScroll() {
        const container = this.shadowRoot?.querySelector('.scroll-container') as HTMLElement;
        const content = this.shadowRoot?.querySelector('.scroll-content') as HTMLElement;
        const scrollBuffer = 10;

        this.isScrollStart = container.scrollLeft < scrollBuffer;
        this.isScrollEnd =
            content.offsetWidth - container.scrollLeft < container.offsetWidth + scrollBuffer;
    }

    isActiveTab(id: string | number) {
        return this.activeTab == id;
    }

    handleKeyDown(e: any) {
        const idx = this.activeTabElements.findIndex(tab => tab.id === e.target.id);
        if (e.keyCode === KEYCODE.TAB) return;
        e.preventDefault();
        switch (e.keyCode) {
            case KEYCODE.DOWN:
            case KEYCODE.RIGHT:
                this.activeTabElements[(idx + 1) % this.activeTabElements.length].focus();
                break;
            case KEYCODE.UP:
            case KEYCODE.LEFT:
                this.activeTabElements[
                    (idx + this.activeTabElements.length - 1) % this.activeTabElements.length
                ].focus();
                break;
            case KEYCODE.ENTER:
                e.target.click();
                break;
            default:
                break;
        }
    }

    handleTabClick(e: any) {
        this.activeTab = e.target.id;
        this.dispatchEvent(
            new CustomEvent('switchTab', {
                bubbles: true,
                composed: true,
                detail: { activeTab: this.activeTab },
            })
        );
    }

    renderScrollIcon(direction: string, isHidden: boolean) {
        return html`<div
            class=${classMap({
                'scroll-indicator absolute top-0 w-6 h-full flex items-center pointer-events-none bg-[--tm-tabs-scroll-indicator]':
                    true,
                'left-0': direction === 'back',
                'right-0': direction === 'forward',
                invisible: isHidden,
            })}
        >
            ${direction === 'back' ? chevronBackIcon : chevronForwardIcon}
        </div>`;
    }

    renderTabs() {
        return this.tabs.map(
            (tab: any) =>
                html`<button
                    id=${tab.id}
                    class=${classMap({
                        'tab p-4 border-b-4 text-sm whitespace-nowrap': true,
                        'tab-active': this.isActiveTab(tab.id),
                        'font-medium': this.isActiveTab(tab.id),
                        'border-[--tm-tabs-item-border]': this.isActiveTab(tab.id),
                        'text-[--tm-tabs-item-text-active]': this.isActiveTab(tab.id),
                        'border-transparent': !this.isActiveTab(tab.id),
                        'text-[--tm-tabs-item-text]': !this.isActiveTab(tab.id) && !tab.disabled,
                        'bg-[--tm-tabs-item-disabled-background]': tab.disabled,
                        'text-[--tm-tabs-item-text-disabled]': tab.disabled,
                        'cursor-not-allowed': tab.disabled,
                    })}
                    role="tab"
                    aria-selected=${this.isActiveTab(tab.id)}
                    ?disabled=${tab.disabled}
                    @keydown=${this.handleKeyDown}
                    @click=${this.handleTabClick}
                >
                    ${tab.label}
                </button>`
        );
    }

    render() {
        return html`<div class="wrapper relative border-b border-[--tm-tabs-container-border]">
            <div class="scroll-container flex" role="tablist">
                <div class="scroll-content flex">${this.renderTabs()}</div>
            </div>
            ${this.renderScrollIcon('back', this.isScrollStart)}
            ${this.renderScrollIcon('forward', this.isScrollEnd)}
        </div>`;
    }

    static get styles() {
        return [
            css`
                .scroll-container {
                    overflow-x: scroll;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                }

                .scroll-container::-webkit-scrollbar {
                    display: none;
                }

                .scroll-indicator path {
                    fill: var(--tm-tabs-scroll-icon);
                }

                .tab:focus {
                    outline: none;
                    background: var(--tm-tabs-item-focus);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmTabs;
    }
}
