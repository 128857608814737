/* eslint-disable import/extensions */
import { TmBaseComponent } from '@treasury/presentation';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export const tagName = 'jhd-chip';
@customElement(tagName)
export class JhdChip extends TmBaseComponent {
    @property({ type: String, reflect: true })
    value: string | string[] = '';

    render() {
        const displayValue = Array.isArray(this.value)
            ? this.value.map(value => html`${value}<br />`)
            : this.value;

        return html`<span class="chip inline-block text-xs rounded p-1">${displayValue}</span>`;
    }

    static get styles() {
        return [
            css`
                .chip {
                    background-color: var(--status-chip-background);
                    color: var(--status-chip-color);
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: JhdChip;
    }
}
