export enum PaymentTypes {
    Transfer = 'transfer',
    Loan = 'loanpayment',
    Wire = 'wire',
    ACH = 'ach',
    User = 'user',
}

export const paymentTypes = [
    PaymentTypes.Transfer,
    PaymentTypes.Loan,
    PaymentTypes.Wire,
    PaymentTypes.ACH,
    // PaymentTypes.User,
];
