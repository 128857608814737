/* eslint-disable @treasury/no-date */
import { LookupModelDto } from '@treasury/api/channel';
import { clone, exists } from '@treasury/utils/functions';
import { WireTemplateEntryType, nullWireAccount, nullWireCompany, nullWireTemplate } from '.';
import { nullBeneficiary } from '../channel/types';
/**
 * Represents the client-side contract for Wires templates.
 */
export class WireTemplate {
    // eslint-disable-next-line no-useless-constructor
    constructor(protected dto = clone(nullWireTemplate)) {}

    private _isChecked = false;

    public readonly type: WireTemplateEntryType = this.dto.isInternational
        ? 'International'
        : 'Domestic';

    public get name() {
        return this.dto.name || '';
    }

    public set name(templateName) {
        this.dto.name = templateName;
    }

    public get id() {
        return this.dto.id || 0;
    }

    public set id(id) {
        this.dto.id = id;
    }

    public get createdBy() {
        return this.dto.createdBy || 0;
    }

    public set createdBy(createdBy) {
        this.dto.createdBy = createdBy;
    }

    public get numberOfApprovalsNeeded() {
        return this.dto.numberOfApprovalsNeeded || 0;
    }

    public set numberOfApprovalsNeeded(numberOfApprovalsNeeded) {
        this.dto.numberOfApprovalsNeeded = numberOfApprovalsNeeded;
    }

    public get completedApprovalCount() {
        return this.dto.completedApprovalCount || 0;
    }

    public set completedApprovalCount(completedApprovalCount) {
        this.dto.completedApprovalCount = completedApprovalCount;
    }

    public get isPendingEdit() {
        return this.dto.isPendingEdit || false;
    }

    public set isPendingEdit(isPendingEdit) {
        this.dto.isPendingEdit = isPendingEdit;
    }

    public get isInternational() {
        return this.dto.isInternational || false;
    }

    public set isInternational(isInternational) {
        this.dto.isInternational = isInternational;
    }

    public get wireCompanyName() {
        return this.wireCompany.name || '';
    }

    public set wireCompanyName(name) {
        this.wireCompany.name = name;
    }

    public get debitAccountName() {
        return this.debitAccount.name || this.debitAccount.number || '';
    }

    public set debitAccountName(name) {
        this.debitAccount.name = name;
    }

    public get beneficiaryName() {
        return this.beneficiary.name || '';
    }

    public set beneficiaryName(name) {
        this.beneficiary.name = name;
    }

    public get notes() {
        return this.dto.notes || [];
    }

    public set notes(notes) {
        this.dto.notes = notes;
    }

    public get additionalInformation() {
        return this.dto.notes?.map(note => ({
            string: null,
            value: note,
        })) as LookupModelDto[] | undefined;
    }

    public get referenceBeneficiary() {
        return this.dto.referenceBeneficiary || '';
    }

    public set referenceBeneficiary(name) {
        this.dto.referenceBeneficiary = name;
    }

    public get status() {
        return this.dto.status || '';
    }

    public set status(templateStatus) {
        this.dto.status = templateStatus;
    }

    public get permissions() {
        return this.dto.permissions || [];
    }

    public set permissions(permissions) {
        this.dto.permissions = permissions;
    }

    public get purpose() {
        return this.dto.purpose;
    }

    public set purpose(purpose) {
        this.dto.purpose = purpose;
    }

    public get audit() {
        return this.dto.audit;
    }

    public set audit(audit) {
        this.dto.audit = audit;
    }

    public get isChecked() {
        return this._isChecked;
    }

    public set isChecked(isChecked) {
        this._isChecked = isChecked;
    }

    public get wireCompany() {
        if (!exists(this.dto.wireCompany)) {
            this.dto.wireCompany = clone(nullWireCompany);
        }

        return this.dto.wireCompany;
    }

    public set wireCompany(wireCompany) {
        this.dto.wireCompany = wireCompany;
    }

    public get debitAccount() {
        if (!exists(this.dto.debitAccount)) {
            this.dto.debitAccount = clone(nullWireAccount);
        }

        return this.dto.debitAccount;
    }

    public set debitAccount(debitAccount) {
        this.dto.debitAccount = debitAccount;
    }

    public get beneficiary() {
        if (!exists(this.dto.beneficiary)) {
            this.dto.beneficiary = clone(nullBeneficiary);
        }

        return this.dto.beneficiary;
    }

    public set beneficiary(beneficiary) {
        this.dto.beneficiary = beneficiary;
    }

    public toDto() {
        return this.dto;
    }

    public clone() {
        return new WireTemplate(clone(this.dto));
    }
}
