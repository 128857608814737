import { AchException } from '@treasury/domain/arp';
import { LabeledList, NotificationService, TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/forms/tm-form-row';
import '@treasury/presentation/components/tm-badge';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-confirm-dialog';
import '@treasury/presentation/components/tm-footer';
import '@treasury/presentation/components/tm-labeled-list';
import { toTitleCase } from '@treasury/utils';
import { html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { AchExceptionVm } from '../view-models/ach-exception';

export const tagName = 'ach-exception-detail';
@customElement(tagName)
export class AchExceptionDetail extends TmBaseComponent {
    @property()
    selectedException?: AchException;

    @property({ type: Boolean })
    open!: boolean;

    @property({ type: Boolean })
    hideActions = false;

    @property({ type: Boolean })
    loading = false;

    @state()
    showConfirm = false;

    @state()
    decision = '';

    close() {
        this.dispatchEvent(new CustomEvent('close'));
    }

    renderConfirmDialog() {
        return html`<tm-confirm-dialog
            .opened=${this.showConfirm}
            message="Are you sure you want to decision this ACH Exception?"
            cancel
            @cancel=${() => {
                this.showConfirm = false;
            }}
            .confirmText=${toTitleCase(this.decision)}
            @confirm=${() => {
                this.dispatchEvent(new CustomEvent(this.decision));
            }}
        ></tm-confirm-dialog>`;
    }

    renderLoader() {
        if (!this.loading) return nothing;
        return html`<tm-blocking-loader></tm-blocking-loader>`;
    }

    renderActions() {
        if (this.hideActions) return nothing;
        return html`<tm-footer
            direction="row"
            .buttonConfig=${[
                {
                    onClick: () => {
                        this.selectedException?.pay();
                        this.showConfirm = true;
                        this.decision = 'pay';
                    },
                    success: true,
                    text: 'Pay',
                    classes: 'mr-1',
                },
                {
                    onClick: () => {
                        this.selectedException?.return();
                        this.showConfirm = true;
                        this.decision = 'return';
                    },
                    error: true,
                    text: 'Return',
                    classes: 'ml-1',
                },
            ]}
        ></tm-footer>`;
    }

    render() {
        if (!this.selectedException) return nothing;

        const vm = new AchExceptionVm(this.selectedException);
        const list = new LabeledList(vm, ['achCompanyName', 'description'], {
            achCompanyName: 'ACH Company',
        });
        const label = html`<span class="text-lg">${vm.accountName}</span>`;

        return html`${this.renderConfirmDialog()}<tm-bottom-sheet
                @close=${() => this.close()}
                .open=${this.open}
            >
                <span slot="header-center">Exception Detail</span>
                ${this.renderLoader()}
                <tm-section>
                    <tm-form-row .label=${label}>
                        <div class="flex flex-shrink justify-end">
                            <tm-badge>${vm.secCode}</tm-badge>
                        </div>
                        <div slot="extra" class="pb-4 text-xl amount-field">${vm.amount}</div>
                        <div slot="summary" class="py-4">
                            <tm-labeled-list .list=${list}></tm-labeled-list>
                        </div>
                    </tm-form-row>
                </tm-section>
                ${this.renderActions()}
            </tm-bottom-sheet>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchExceptionDetail;
    }
}
