import { StatusCode } from '@treasury/domain/channel/services/login';
import { html, nothing, TemplateResult } from 'lit';
import { LoginContainer } from '../login-container';

const renderChangePassword = (context: LoginContainer) => html`
    <change-password
        .statusCode=${context.statusCode}
        .loading=${context.loading}
        @submit=${context.updatePassword}
    ></change-password>
`;
interface LoginStep {
    isDialog?: boolean;
    title: string;
    render: (context: LoginContainer) => TemplateResult | typeof nothing | Promise<void>;
}

export const LoginPhases: Partial<Record<StatusCode, LoginStep>> = {
    [StatusCode.Allow]: {
        title: '',
        render: (context: LoginContainer) => context.completeLogin(),
    },
    [StatusCode.Enrollment]: {
        title: 'Security Questions',
        render: (context: LoginContainer) => html`
            <security-questions-enrollment
                .securityQuestions=${context.loginResult?.data}
                .loading=${context.loading}
                @submit=${context.saveSecurityQuestions}
            ></security-questions-enrollment>
        `,
    },
    [StatusCode.ChallengeQuestions]: {
        title: 'Security Questions',
        render: (context: LoginContainer) => html`
            <security-questions
                .securityQuestions=${context.loginResult?.data}
                .loading=${context.loading}
                @submit=${context.verifySecurityQuestions}
            ></security-questions>
        `,
    },
    [StatusCode.Register]: {
        title: 'Register',
        render: (context: LoginContainer) => html`
            <secure-token-registration
                .loading=${context.loading}
                @register=${context.registerSecureToken}
            ></secure-token-registration>
        `,
    },
    [StatusCode.ForgotPassword]: {
        title: 'Forgot Password',
        render: () => html` <forgot-password></forgot-password> `,
    },
    [StatusCode.UserVerification]: {
        title: 'User Verification',
        render: (context: LoginContainer) => html`
            <user-verification
                .loading=${context.loading}
                @submit=${context.submitUserVerificationForm}
            ></user-verification>
        `,
    },
    [StatusCode.UserSelection]: {
        title: 'User Selection',
        render: context =>
            html`<user-selection
                .userOptions=${context.userOptions}
                @selected=${(e: { detail: { userCompanyUniqueId: string } }) =>
                    context.userSelected(e.detail.userCompanyUniqueId)}
            ></user-selection>`,
    },
    [StatusCode.RegisterOutOfBand]: {
        title: 'Phone Numbers for Authentication',
        render: (context: LoginContainer) => html`
            <oob-registration
                .client=${context.client}
                .loading=${context.loading}
                @done=${context.completeOutOfBandConfig}
            ></oob-registration>
        `,
    },
    [StatusCode.RegisterSecureToken]: {
        title: 'Secure Token Registration',
        render: (context: LoginContainer) => html`
            <secure-token-registration
                .client=${context.client}
                .loading=${context.loading}
                @submit=${context.registerSecureToken}
            ></secure-token-registration>
        `,
    },
    [StatusCode.ResetPassword]: {
        title: 'Change Password',
        render: (context: LoginContainer) => renderChangePassword(context),
    },
    [StatusCode.ResetPasswordEnrollment]: {
        title: 'Change Password',
        render: (context: LoginContainer) => renderChangePassword(context),
    },
    [StatusCode.ChallengeResetPassword]: {
        title: 'Change Password',
        render: (context: LoginContainer) => renderChangePassword(context),
    },
    [StatusCode.ChallengeResetExpiredPassword]: {
        title: 'Change Password',
        render: (context: LoginContainer) => renderChangePassword(context),
    },
    [StatusCode.ResetExpiredPassword]: {
        title: 'Change Password',
        render: (context: LoginContainer) => renderChangePassword(context),
    },
    [StatusCode.Locked]: {
        title: 'User Is Locked Out',
        render: (context: LoginContainer) => html`<login-locked></login-locked>`,
    },
    [StatusCode.Loading]: {
        title: 'Logging in...',
        render: (context: LoginContainer) =>
            html` <style>
                    omega-progress {
                        margin: auto;
                        border-width: 2px;
                        border-style: solid;
                        border-color: var(--omega-progress-color, var(--omega-border-color));
                        border-right-color: transparent !important;
                    }
                </style>
                <omega-progress card></omega-progress>`,
    },
    [StatusCode.Login]: {
        title: 'Login',
        render: (context: LoginContainer) => html`
            <treasury-login
                .loading=${context.loading}
                @login=${context.login}
                @forgot-password=${context.forgotPassword}
            ></treasury-login>
        `,
    },
};
