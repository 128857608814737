import { FiDate } from '@treasury/domain/dates';
import { format } from 'date-fns';
import { getFormattedStatus } from '../../components/jhd-status';
import { TransferPaymentDto } from '../types/transfer-payment-dto.type';

export type TransferPaymentViewModel = {
    transferDate: string;
    fromAccount: string;
    toAccount: string;
    amount: string;
    status: string;
    id: number;
};

export class TransferPaymentTableViewModel {
    constructor(dto: TransferPaymentDto) {
        this.transferDate = format(new FiDate(dto.transferDate).date, 'P');
        this.fromAccount = dto.fromAccount.accountDisplayLabel;
        this.toAccount = dto.toAccount.accountDisplayLabel;
        this.amount = dto.amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        this.status = getFormattedStatus(dto.status);
        this.id = dto.id;
    }

    transferDate: string;

    fromAccount: string;

    toAccount: string;

    amount: string;

    status: string;

    id: number;
}
