import { AchException } from '@treasury/domain/arp';
import { PayOrReturnChoice } from '@treasury/domain/channel/mappings/ach';
import { TmContainer } from '@treasury/presentation';
import { html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../partials/ach-exception-detail';
import '../partials/ach-exceptions-activity-table';
import '../partials/ach-exceptions-table';
import { AchExceptionActivityVm } from '../view-models/ach-exception-activity';

enum AchExceptionsConfig {
    List = 'list',
    Review = 'review',
    Confirm = 'confirm',
}

export const tagName = 'ach-exception-workflow-container';
@customElement(tagName)
export class AchExceptionWorkflowContainer extends TmContainer {
    @property({ type: String })
    step: AchExceptionsConfig = AchExceptionsConfig.List;

    @property({ type: Array })
    achExceptions: AchException[] = [];

    @state()
    private achExceptionActivity: AchExceptionActivityVm[] = [];

    @property({ type: Array })
    accounts: string[] = [];

    @property({ type: Boolean })
    decisioningDisabled = false;

    @property({ type: String })
    activeTab: 'ach-exceptions' | 'ach-exceptions-activity' = 'ach-exceptions';

    @property({ type: Array })
    secCodes: string[] = [];

    @property({ type: Boolean })
    primaryActionLoading = false;

    @state()
    decisionedAchExceptions: AchException[] = [];

    protected updated(changedProperties: Map<string | number | symbol, unknown>): void {
        if (changedProperties.has('step') && this.step === AchExceptionsConfig.Confirm) {
            this.decisionedAchExceptions = [];
        }
    }

    clearAllDecisioned() {
        this.decisionedAchExceptions = this.achExceptions.filter(
            ae => ae.decisionChoice !== PayOrReturnChoice.Unselected
        );
    }

    renderListTable() {
        if (this.activeTab === 'ach-exceptions') {
            return html`<ach-exceptions-table
                    .loading=${this.loading}
                    .readonly=${this.decisioningDisabled}
                    .achExceptions=${this.achExceptions}
                    .accounts=${this.accounts}
                    .secCodes=${this.secCodes}
                    @decision=${() => {
                        this.clearAllDecisioned();
                    }}
                    @selected=${(event: CustomEvent<AchException>) => {
                        this.dispatchEvent(new CustomEvent('selected', { detail: event.detail }));
                    }}
                    @decisionAll=${(event: CustomEvent<PayOrReturnChoice>) => {
                        this.dispatchEvent(
                            new CustomEvent('decisionAll', { detail: event.detail })
                        );
                        this.clearAllDecisioned();
                    }}
                    @clearAll=${() => {
                        this.dispatchEvent(
                            new CustomEvent('decisionAll', { detail: PayOrReturnChoice.Unselected })
                        );
                        this.clearAllDecisioned();
                    }}
                >
                </ach-exceptions-table>
                <tm-footer
                    .buttonConfig=${[
                        {
                            text: `Review${
                                this.decisionedAchExceptions?.length
                                    ? ` (${this.decisionedAchExceptions.length})`
                                    : ''
                            }`,
                            onClick: () => {
                                this.dispatchEvent(new CustomEvent('review'));
                            },
                            disabled: !this.decisionedAchExceptions.length,
                        },
                    ]}
                ></tm-footer>`;
        }
        return html`<ach-exceptions-activity-table
            .loading=${this.loading}
            .readonly=${this.decisioningDisabled}
            .achExceptions=${this.achExceptionActivity}
            .accounts=${this.accounts}
            .secCodes=${this.secCodes}
        ></ach-exceptions-activity-table>`;
    }

    render() {
        switch (this.step) {
            case AchExceptionsConfig.List:
                return html` ${this.renderListTable()}`;

            case AchExceptionsConfig.Review:
                return html`
                    <ach-exceptions-table
                        .loading=${this.loading}
                        .achExceptions=${this.achExceptions?.filter(
                            ae => ae.decisionChoice !== PayOrReturnChoice.Unselected
                        )}
                        @selected=${(event: CustomEvent<AchException>) => {
                            this.dispatchEvent(
                                new CustomEvent('selected', { detail: event.detail })
                            );
                        }}
                        .actionName=${'Confirm'}
                        .actionLoading=${this.primaryActionLoading}
                        .readonly=${true}
                        @action=${(event: CustomEvent<AchException[]>) => {
                            this.dispatchEvent(new CustomEvent('action', { detail: event.detail }));
                        }}
                    >
                    </ach-exceptions-table>
                    <tm-footer
                        .buttonConfig=${[
                            {
                                text: `Decision${
                                    this.decisionedAchExceptions?.length
                                        ? ` (${this.decisionedAchExceptions.length})`
                                        : ''
                                }`,
                                onClick: () => {
                                    this.dispatchEvent(
                                        new CustomEvent('confirm', {
                                            detail: this.decisionedAchExceptions,
                                        })
                                    );
                                },
                                disabled: this.loading,
                            },
                        ]}
                        ><div
                            class="background-white text-center w-full bg-[--primary-background] p-2"
                        >
                            <span class="text-sm text-gray-600"
                                >ACH items can only be decisioned one time. Select 'Decision' to
                                continue.</span
                            >
                        </div></tm-footer
                    >
                `;

            case AchExceptionsConfig.Confirm:
                return html`<confirmation-container>
                        <div>Your exceptions have been successfully decisioned.</div>
                    </confirmation-container>
                    <tm-footer
                        .buttonConfig=${[
                            {
                                text: 'View ACH Exceptions',
                                onClick: () => {
                                    this.step = AchExceptionsConfig.List;
                                    this.dispatchEvent(new CustomEvent('list'));
                                },
                                importance: 'secondary',
                            },
                            {
                                text: 'View Check Exceptions',
                                onClick: () => {
                                    this.step = AchExceptionsConfig.List;
                                    this.dispatchEvent(new CustomEvent('check-exceptions'));
                                },
                                importance: 'secondary',
                            },
                        ]}
                    ></tm-footer>`;
            default:
                return nothing;
        }
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchExceptionWorkflowContainer;
    }
}
