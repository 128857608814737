import OmegaFileUpload from '@treasury/omega/components/omega-file-upload';
import { attachIcon } from '@treasury/presentation/assets/icons';
import { StyleMixin } from '@treasury/presentation/mix-ins/style-mixin';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export const tagName = 'check-exception-file-upload';
@customElement(tagName)
export class CheckExceptionFileUpload extends StyleMixin(OmegaFileUpload) {
    @property()
    accepted: string[] = [];

    _fileUpload(e: Event) {
        const target = <HTMLInputElement>e.target;
        if (target && target.files?.length) {
            this.files = [...target.files];
            target.value = '';
            if (this._hasInvalidFileSizes(this.files)) {
                this.dispatchEvent(
                    new CustomEvent('invalidFileSize', { bubbles: true, composed: true })
                );
                return;
            }
        } else {
            this.files = [];
        }

        this.dispatchEvent(
            new CustomEvent('filesUploaded', {
                bubbles: true,
                composed: true,
                detail: { files: this.files },
            })
        );
    }

    render() {
        const accepted =
            Array.isArray(this.accepted) && this.accepted.length > 0
                ? this.accepted.join(', ')
                : '*';
        const file = this.files[0]?.name ?? 'Attachment...';
        return html`
            <input
                type="file"
                id=${this.id}
                ?disabled=${this.disabled}
                ?multiple=${false}
                .accept=${accepted}
                @change=${this._fileUpload}
            />
            <label for=${this.id}>
                <div class="flex items-center w-full h-full px-4">
                    <jhd-icon .icon=${attachIcon} class="m-1"></jhd-icon>
                    <div class="font-medium text-sm">${file}</div>
                </div>
            </label>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                    cursor: pointer;
                    height: 52px;
                    width: 100%;
                }
                .label-content {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 0 16px;
                }
                input {
                    display: none;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: CheckExceptionFileUpload;
    }
}
