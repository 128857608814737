import { CompanyAccountModelDto } from '@treasury/api/channel';
import { TmContainer } from '@treasury/presentation';
import '@treasury/presentation/components/layout/tm-section';
import '@treasury/presentation/components/tm-bottom-sheet';
import {
    CurrencyRange,
    CurrencyRangeType,
} from '@treasury/presentation/components/tm-currency-range';
import { DateRange, DateRangeType } from '@treasury/presentation/components/tm-date-range';
import '@treasury/presentation/components/tm-footer';
import '@treasury/presentation/components/tm-multi-select';
import { MultiSelectItem } from '@treasury/presentation/components/tm-multi-select';
import '@treasury/presentation/components/tm-select';
import { SelectItem } from '@treasury/presentation/components/tm-select';
import '@treasury/presentation/components/tm-text-field';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { defaultAchExceptionsActivitySearchRequest } from '../data/default-ach-exceptions-activity-search-request';

const defaultAmountRange: CurrencyRange = {
    rangeType: CurrencyRangeType.SpecificAmount,
};

const defaultPostedRange: DateRange = {
    rangeType: DateRangeType.None,
};

export const tagName = 'ach-exceptions-activity-filter-sheet';
@customElement(tagName)
export class AchExceptionsActivityFilterSheet extends TmContainer {
    @property({ type: Boolean })
    open = false;

    @property({ type: Object })
    filterRequest = defaultAchExceptionsActivitySearchRequest;

    @property({ type: Array })
    accounts: CompanyAccountModelDto[] = [];

    @property({ type: Array })
    secCodes: { secCode: string; description: string }[] = [];

    @state()
    private accountItems: MultiSelectItem[] = [];

    @state()
    private selectedAccounts: MultiSelectItem[] = [];

    @state()
    private secCodeItems: MultiSelectItem[] = [];

    @state()
    private selectedSecCodes: MultiSelectItem[] = [];

    @state()
    private amountRange: CurrencyRange = defaultAmountRange;

    @state()
    private postedDate: DateRange = defaultPostedRange;

    @state()
    private description = '';

    @state()
    private achExceptionDecisionStatus = 'all';

    @state()
    private achExceptionDecisionStatusItems: SelectItem[] = [
        { value: 'all', label: 'All' },
        { value: 'Pay', label: 'Pay' },
        { value: 'Reject', label: 'Reject' },
        { value: 'NoDecision', label: 'No Decision' },
    ];

    firstUpdated() {
        this.accountItems = this.accounts.map(account => ({
            value: account,
            label: account.accountDisplayLabel ?? account.name,
        }));

        this.secCodeItems = this.secCodes.map(secCode => ({
            value: secCode,
            label: secCode.description,
        }));

        this.selectedAccounts = this.accountItems;

        this.selectedSecCodes = this.secCodeItems;

        this.filter();
    }

    filter() {
        if (!this.selectedAccounts.length) this.selectedAccounts = this.accountItems;
        if (!this.selectedSecCodes.length) this.selectedSecCodes = this.secCodeItems;
        this.dispatchEvent(
            new CustomEvent('filter-activity', {
                detail: {
                    accounts: this.selectedAccounts.map(a => a.value),
                    secCodes: this.selectedSecCodes.map(s => s.value),
                    amountRange: this.amountRange,
                    postedDate: this.postedDate,
                    description: this.description,
                    achExceptionDecisionStatus: this.achExceptionDecisionStatus,
                },
                bubbles: true,
                composed: true,
            })
        );
    }

    render() {
        return html`<tm-bottom-sheet
            .open=${this.open}
            @close=${() => {
                this.dispatchEvent(new CustomEvent('close'));
            }}
        >
            <div slot="header-center">Filter ACH Exception Activity</div>
            <div>
                <tm-section class="p-3">
                    <tm-select
                        class="mb-3 pt-3"
                        required
                        label="Decision"
                        .items=${this.achExceptionDecisionStatusItems}
                        .value=${this.achExceptionDecisionStatus}
                        @value-changed=${(e: CustomEvent) => {
                            this.achExceptionDecisionStatus = e.detail.value;
                        }}
                    ></tm-select>
                    <tm-multi-select
                        class="mb-3"
                        required
                        .items=${this.accountItems}
                        .allowSelectAll=${true}
                        .allSelectedText=${'All Accounts'}
                        .selectedItems=${this.selectedAccounts}
                        @selected-items-changed=${(e: CustomEvent) => {
                            this.selectedAccounts = e.detail;
                        }}
                        label="Accounts"
                    ></tm-multi-select>
                    <tm-multi-select
                        class="mb-3"
                        required
                        .items=${this.secCodeItems}
                        .allowSelectAll=${true}
                        .allSelectedText=${'All SEC Codes'}
                        .selectedItems=${this.selectedSecCodes}
                        @selected-items-changed=${(e: CustomEvent) => {
                            this.selectedSecCodes = e.detail;
                        }}
                        label="SEC Codes"
                    ></tm-multi-select>
                    <tm-currency-range
                        class="mb-3"
                        label="Amount"
                        .currencyRangeOptions=${[
                            CurrencyRangeType.SpecificAmount,
                            CurrencyRangeType.AmountRange,
                        ]}
                        .range=${this.amountRange}
                        @selection=${(e: CustomEvent) => {
                            this.amountRange = e.detail;
                        }}
                    ></tm-currency-range>
                    <tm-date-range
                        class="mb-3"
                        label="Posted Date"
                        .range=${this.postedDate}
                        @selection=${(e: CustomEvent) => {
                            this.postedDate = e.detail;
                        }}
                    ></tm-date-range>
                    <tm-text-field
                        class="pb-3"
                        label="Entry Description"
                        .value=${this.description}
                        @value-changed=${(e: CustomEvent) => {
                            this.description = e.detail.value;
                        }}
                    ></tm-text-field>
                </tm-section>
                <tm-footer class="absolute"
                    ><tm-button
                        size="large"
                        class="w-full"
                        importance="primary"
                        .disabled=${!this.selectedAccounts.length || !this.selectedSecCodes.length}
                        @click=${() => {
                            this.filter();
                            this.dispatchEvent(new CustomEvent('close'));
                        }}
                        >Apply Filters</tm-button
                    ></tm-footer
                >
            </div>
        </tm-bottom-sheet>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchExceptionsActivityFilterSheet;
    }
}
