/* eslint-disable @typescript-eslint/no-unused-vars */

import { CompanyAccountModelDto, WireCompanyModelDto } from '@treasury/api/channel';
import { ConfigurationService } from '@treasury/core/config';
import { NavigationService } from '@treasury/core/navigation';
import { WireTemplate, WireTemplateQueryDto, WiresService } from '@treasury/domain/wires';
import { TmBaseComponent } from '@treasury/presentation';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../../components/jhd-header-bar';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import { WireAction } from '../wire-workflow/types/wire-workflow-parameters.type';
import { defaultTemplateFilters } from './data/default-template-filters';
import './partials/wire-template-detail-sheet';
import './partials/wire-templates-filters-sheet';
import './partials/wire-templates-table';
import { WireTemplatesParameters } from './types/wire-templates-parameters.type';

export const tagName = 'wire-templates-container';
@customElement(tagName)
export class WireTemplatesContainer extends TmBaseComponent {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare wireService: WiresService;

    @InjectProperty()
    private declare config: ConfigurationService;

    @InjectProperty()
    private declare navService: NavigationService;

    @state()
    headerTitle = 'Wire Templates';

    @state()
    wireTemplates: WireTemplate[] = [];

    @state()
    selectedWireTemplate: WireTemplate = new WireTemplate();

    @state()
    templateDetailsSheetOpen = false;

    @state()
    transferAccounts: CompanyAccountModelDto[] = [];

    @state()
    action!: WireAction;

    @state()
    filterSheetOpen = false;

    @state()
    loading = true;

    @state()
    templateSelection = false;

    async firstUpdated() {
        try {
            await this.setAction();
            this.configureHeader();
            this.transferAccounts = await this.wireService.fetchTransferAccounts();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }

    private async setAction() {
        const { params } = await this.navService.getRouteData<WireTemplatesParameters>();
        const { action } = params; // action isn't present in params without a refresh

        this.action = action?.toLowerCase() as WireAction;
        if (this.action === 'initiate') {
            this.templateSelection = true;
        }
    }

    configureHeader() {
        this.headerService.configure({
            title: this.templateSelection ? 'Select Wire Template' : 'Wire Templates',
            backAction: this.templateSelection ? () => this.navService.navigate('/wires') : null,
        });
    }

    async filterTemplates(filters: WireTemplateQueryDto) {
        this.loading = true;
        try {
            this.wireTemplates = await this.wireService.searchTemplates(filters);
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    routeToWireInitiation(wireTemplateId: number) {
        this.navService.navigate(`/wires/template/initiate/${wireTemplateId}/usd`, {
            debitAccounts: this.transferAccounts,
        });
    }

    onWireTemplateClick(wireTemplate: WireTemplate) {
        if (this.action === 'initiate') {
            this.routeToWireInitiation(wireTemplate.id);
        } else {
            this.selectedWireTemplate = wireTemplate;
            this.templateDetailsSheetOpen = true;
            this.requestUpdate();
        }
    }

    renderTable() {
        return html` <wire-templates-table
            .action=${this.action}
            .loading=${this.loading}
            .wireTemplates=${this.wireTemplates}
            @wireTemplateClick=${(e: CustomEvent) => {
                this.onWireTemplateClick(e.detail.value);
            }}
            @filterTemplatesClick=${() => {
                this.filterSheetOpen = true;
            }}
        ></wire-templates-table>`;
    }

    renderDetailSheet() {
        return html`<wire-template-detail-sheet
            .open=${this.templateDetailsSheetOpen}
            .wireTemplate=${this.selectedWireTemplate}
            @close=${() => {
                this.templateDetailsSheetOpen = false;
            }}
        >
        </wire-template-detail-sheet>`;
    }

    renderFiltersSheet() {
        return html`<wire-templates-filters-sheet
            .open=${this.filterSheetOpen}
            .templateSelection=${this.templateSelection}
            @close=${() => {
                this.filterSheetOpen = false;
            }}
            @applyFilters=${(e: CustomEvent) => {
                this.filterTemplates(e.detail as WireTemplateQueryDto);
            }}
        ></wire-templates-filters-sheet>`;
    }

    render() {
        return [this.renderTable(), this.renderDetailSheet(), this.renderFiltersSheet()];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: WireTemplatesContainer;
    }
}
