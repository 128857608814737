import { NavigationService } from '@treasury/core/navigation';
import { AchPayment } from '@treasury/domain/ach';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import { InjectProperty } from '@treasury/utils';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/confirmation-container';
import './ach-payment-data';

export const tagName = 'ach-payment-confirm';
@customElement(tagName)
export class AchPaymentConfirm extends TmBaseComponent {
    @property()
    public payment!: AchPayment;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    get isPending() {
        return this.payment.status.toLowerCase() === 'pendingapproval';
    }

    renderRecipients() {
        const recipients = this.payment.recipients?.map(r => {
            return html`<div class="flex justify-between py-1">
                <div class="payment-recipient-name text-sm text-[--secondary-text-color]">
                    ${r.name}
                </div>
                <div class="payment-recipient-amount text-sm font-medium">
                    ${new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }).format(r.amount)}
                    <span class="text-xs font-normal">${r.transactionType}</span>
                </div>
            </div>`;
        });
        return html`<div
            class="payment-recipients py-4 border-t border-dashed border-[--border-color]"
        >
            <div class="pb-2 font-medium">Payment Recipients</div>
            ${recipients}
        </div>`;
    }

    render() {
        return html`
            <tm-section>
                <confirmation-container
                    .headingText=${'ACH Payment Submitted'}
                    .subheadingText=${'Your ACH payment has been submitted'}
                >
                    <div class="pl-3 pr-1">
                        <ach-payment-data .payment=${this.payment}></ach-payment-data>
                        ${this.renderRecipients()}
                    </div>
                </confirmation-container>
            </tm-section>
            <tm-footer
                .buttonConfig=${[
                    {
                        importance: 'secondary',
                        text: 'Make another ACH payment',
                        onClick: () => {
                            this.dispatchEvent(new CustomEvent('createAchTransfer'));
                        },
                    },
                    {
                        importance: 'secondary',
                        text: 'View ACH payment activity',
                        onClick: () => {
                            this.navService.navigate(`/ach`);
                        },
                    },
                ]}
            ></tm-footer>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchPaymentConfirm;
    }
}
