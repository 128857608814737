import { html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { TmBaseComponent } from '../../tm-base.component';
import { summarizeFrequency } from '../tm-frequency.summarizer';
import { Frequency } from '../tm-frequency.types';
import './tm-slider';

export type FrequencyEvent = CustomEvent<Frequency>;

export const tagName = 'tm-form-frequency';
@customElement(tagName)
export class TmFormFrequency extends TmBaseComponent {
    @property({ type: String })
    public dateLabel?: string;

    @property({ type: String })
    public minDate?: string;

    @property({ type: Boolean })
    public disabled?: boolean;

    @state()
    private frequency?: Frequency;

    @state()
    private disableSubmitButton = false;

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('close-tm-slider', this.handleSliderClose);
    }

    // Has to be in light dom so global custom border styles can affect it
    protected createRenderRoot() {
        return this;
    }

    handleSelection(e: CustomEvent<Frequency | false>) {
        if (!e.detail) {
            this.disableSubmitButton = true;
            return;
        }
        this.disableSubmitButton = false;
        this.frequency = e.detail;
    }

    private handleSliderClose() {
        this.dispatchEvent(new CustomEvent('close', { bubbles: true }));
        this.dispatchEvent(
            new CustomEvent('select-frequency', {
                detail: this.frequency,
                bubbles: true,
            }) as FrequencyEvent
        );
    }

    renderFrequencySummary() {
        if (!this.frequency) return nothing;
        return html`<div slot="summary" class="text-sm py-4">
            ${summarizeFrequency(this.frequency)}
        </div>`;
    }

    render() {
        return html`
            <tm-form-row label="Frequency">
                <tm-slider
                    header="Select Frequency"
                    .value=${this.frequency ? 'Change Frequency' : 'Select Frequency'}
                    @selection=${this.handleSelection}
                    .disabled=${this.disabled}
                    .showControls=${true}
                    .disableSubmitButton=${this.disableSubmitButton}
                >
                    <tm-section class="p-4">
                        <tm-frequency
                            .frequency=${ifDefined(this.frequency)}
                            dateLabel=${ifDefined(this.dateLabel)}
                            .minDate=${ifDefined(this.minDate)}
                        ></tm-frequency>
                    </tm-section>
                </tm-slider>
                ${this.renderFrequencySummary()}
            </tm-form-row>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmFormFrequency;
    }
}
