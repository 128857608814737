import { PayeeListModel } from '@treasury/domain/bill-pay/payee-list.entity';

export class BillPayPayeeTableViewModel {
    constructor(readonly value: PayeeListModel) {}

    get nickName() {
        return this.value.nickName;
    }

    get name() {
        return this.value.name;
    }

    get paymentMethod() {
        return this.value.paymentMethod;
    }

    get id() {
        return this.value.id;
    }
}
