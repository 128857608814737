/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { AchCompanyModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class AchCompaniesClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AchCompanies
     * @name AchCompaniesSearch
     * @summary Search for Ach Companies for passed criteria.
     * @request GET:/achCompanies/achCompanies/search
     */
    achCompaniesSearch = (
        query: {
            /** Contains search on Company Name */
            companyName: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<AchCompanyModelDto, any>({
            path: `/achCompanies/achCompanies/search`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchCompanies
     * @name AchCompaniesSearchAll
     * @summary Search for Ach Companies for passed criteria.
     * @request GET:/achCompanies/achCompanies/{companyId}/search
     */
    achCompaniesSearchAll = (
        companyId: number,
        query: {
            /** Contains search on Company Name */
            companyName: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<AchCompanyModelDto[], any>({
            path: `/achCompanies/achCompanies/${companyId}/search`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
}
