import { AchExceptionDetail } from '../../mappings/ach';
import AchFilterRequests from '../../requests/ach/ach-filter-requests';
import { PositivePayExceptionRequests } from '../../requests/positive-pay/positive-pay-exception-requests.js';
import { AchExceptionDetailDto, AchExceptionsQueryDto } from '../../types';
import { AchExceptionDetailForApiDto } from '../../types/arp/ach-exception-detail-for-api.dto.js';

export default class AchExceptionsService {
    static async findAchExceptions(filter: AchExceptionsQueryDto) {
        const exceptions = await AchFilterRequests.findAchExceptions(filter);
        let recordCount = 0;
        if (exceptions?.achExceptionDetails) {
            recordCount = exceptions.achExceptionDetails.length;
        } else {
            exceptions.achExceptionDetails = [];
        }
        return {
            data: (exceptions.achExceptionDetails as AchExceptionDetailDto[]).map(
                dto => new AchExceptionDetail(dto)
            ),
            totalCount: recordCount,
        };
    }

    static async saveAchExceptions(records: AchExceptionDetailForApiDto[]) {
        return PositivePayExceptionRequests.saveAchExceptions(records);
    }
}
