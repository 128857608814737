/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { ElectronicStatementsDownloadModelDto, ReportInformationModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class WausaustatementsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags ElectronicStatements
     * @name ElectronicStatementsDownloadElectronicDocument2
     * @summary Gets the electronic document report.
     * @request POST:/wausaustatements/download
     */
    electronicStatementsDownloadElectronicDocument2 = (
        model: ElectronicStatementsDownloadModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<File, any>({
            path: `/wausaustatements/download`,
            method: 'POST',
            body: model,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ElectronicStatements
     * @name ElectronicStatementsGetElectronicDocumentList2
     * @summary Gets the electronic document list.
     * @request GET:/wausaustatements/{productName}/reportlist/{accountId}
     */
    electronicStatementsGetElectronicDocumentList2 = (
        productName: string,
        accountId: number,
        params: RequestParams = {}
    ) =>
        this.http.request<ReportInformationModelDto[], any>({
            path: `/wausaustatements/${productName}/reportlist/${accountId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
