import { ApiClient as BoApiClient } from '@treasury/api/bo';
import { ApiClient as ChannelApiClient } from '@treasury/api/channel';
import { AppType, ConfigurationService } from '@treasury/core/config';
import { Injectable, WindowService, combine, fromEvent } from '@treasury/utils';

@Injectable()
export class StatusService {
    constructor(
        private readonly config: ConfigurationService,
        private readonly window: WindowService,
        private readonly channelApi: ChannelApiClient,
        private readonly boApi: BoApiClient
    ) {}

    public readonly online$ = this.createOnlineObservable();

    private createOnlineObservable() {
        const online$ = fromEvent(this.window, 'online').pipe(() => true);
        const offline$ = fromEvent(this.window, 'offline').pipe(() => false);

        return combine(online$, offline$).pipe(async isOnline => {
            if (!isOnline) {
                return false;
            }

            // verify connectivity to the API even after an online event
            try {
                const { success } = await this.getApiStatus();
                return success;
            } catch {
                console.warn('Could not communicate with the TM API.');
                return false;
            }
        });
    }

    private async getApiStatus() {
        const config = {
            skipDatabaseConnectivity: true,
            skipRedisConnectivity: false,
            skipPackageInformation: true,
        };

        const response =
            this.config.app === AppType.BackOffice
                ? this.boApi.healthGet(config)
                : this.channelApi.healthGet(config);

        return (await response).data;
    }
}
