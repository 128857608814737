import { InjectProperty } from '@treasury/utils';
import '@vaadin/upload';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { NotificationService } from '../services';
import { TmInputFieldBase } from './tm-input-field.base';

export const tagName = 'tm-upload';
@customElement(tagName)
export class TmUpload extends TmInputFieldBase {
    @InjectProperty()
    private declare readonly notificationService: NotificationService;

    @property({ type: String })
    accept?: string;

    @property({ type: Number })
    minFiles?: number;

    @property({ type: Number })
    maxFileSize?: number;

    render() {
        return html`
            <vaadin-upload
                @files-changed=${(e: CustomEvent) => {
                    const filesChangedEvent = new CustomEvent('files-changed', {
                        detail: e.detail,
                    });
                    this.dispatchEvent(filesChangedEvent);
                }}
                @file-reject=${(e: CustomEvent) => {
                    this.notificationService.renderError({
                        content: `${e.detail.error} The file type "${e.detail.file.type}" is not supported.`,
                        position: 'top-stretch',
                    });
                }}
                class="w-full"
                no-auto
                accept=${ifDefined(this.accept)}
                minFiles=${ifDefined(this.minFiles)}
                maxFileSize=${ifDefined(this.maxFileSize)}
            >
            </vaadin-upload>
        `;
    }

    static get styles() {
        return [
            ...super.styles,
            css`
                *::part(start-button),
                *::part(status),
                vaadin-progress-bar {
                    display: none;
                }

                *::part(label) {
                    position: inherit;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmUpload;
    }
}
