import { TermsAndConditionsClient, TermsAndConditionsModelDto } from '@treasury/api/channel';
import { ConfigurationService } from '@treasury/core/config';
import { TmBaseComponent } from '@treasury/presentation';
import { businessIcon, mailIcon, mobileIcon } from '@treasury/presentation/assets/icons';
import { equalHousingLender } from '@treasury/presentation/assets/images';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-card';
import '@treasury/presentation/components/tm-footer';
import { InjectProperty } from '@treasury/utils';
import { css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';

export const tagName = 'about-container';
@customElement(tagName)
export class AboutContainer extends TmBaseComponent {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare config: ConfigurationService;

    @InjectProperty()
    private declare termsAndConditionsService: TermsAndConditionsClient;

    @state()
    private termsAndConditionsOpen = false;

    @state()
    private privacyStatementOpen = false;

    @state()
    private termsAndConditions?: TermsAndConditionsModelDto;

    async firstUpdated() {
        this.headerService.configure({ title: 'About' });
        this.termsAndConditions = (
            await this.termsAndConditionsService.termsAndConditionsGet({
                blankIfUserAccepted: false,
            })
        ).data;
    }

    handleKeyup() {
        // eslint-disable-next-line no-console
        console.log('key up');
    }

    render() {
        return html`<div class="header flex flex-1 pt-2 pb-20">
                <img
                    src="/pwa/assets/branding/${this.config.institutionId}/logo.webp"
                    alt="logo"
                    class="logo mt-5 ml-auto mr-auto relative"
                />
            </div>
            <div class="mx-2">
                <tm-card class="about-wrapper">
                    <a href="#">
                        <span class="flex items-center my-5 ml-2"
                            >${businessIcon} &nbsp;5024 Parkway Plaza Blvd <br />
                            Charlotte, NC 28217</span
                        >
                    </a>
                    <a href="tel:5551234567" @keyup=${this.handleKeyup}>
                        <span class="flex items-center my-5 ml-2"
                            >${mobileIcon} &nbsp;(555) 123-4567</span
                        >
                    </a>
                    <a
                        href="mailto:dummy.treasurysupport@jackhenry.com "
                        @keyup=${this.handleKeyup}
                    >
                        <span class="flex items-center my-5 ml-2"
                            >${mailIcon} &nbsp;dummy.treasurysupport@jackhenry.com</span
                        >
                    </a>
                </tm-card>
            </div>
            <section id="buttons" class="flex flex-col items-center justify-center">
                <tm-button
                    link
                    @click=${() => {
                        this.privacyStatementOpen = true;
                    }}
                    >Privacy Statement</tm-button
                >
                <tm-button
                    link
                    @click=${() => {
                        this.termsAndConditionsOpen = true;
                    }}
                    >Terms and Conditions</tm-button
                >
            </section>
            <tm-bottom-sheet
                horizontal
                .open=${this.privacyStatementOpen}
                @close=${() => {
                    this.privacyStatementOpen = false;
                }}
                ><h3 slot="header-center">Privacy Statement</h3>
                <tm-body
                    >The primary licensor for this financial institution’s mobile banking service is
                    Jack Henry & Associates, Inc. (the "Provider"). Provider may access personal
                    information while you use its mobile application. Provider may ask for a mobile
                    phone number or email address. Provider will use this contact information to
                    alert you about app-related events or actions that require your attention.
                    Provider may collect and store the following information: application version,
                    device system name, device system version and device model for the purpose of
                    internal reporting, troubleshooting and tracking application usage per
                    individual applications and version. In addition to this Mobile Application
                    Privacy Policy, your financial institution maintains a privacy policy covering
                    the personal and financial information related to your use of the financial
                    institution’s services and products, including such information that may be
                    gathered through use of this mobile banking service. A copy of that privacy
                    policy is available from your financial institution.</tm-body
                >
            </tm-bottom-sheet>
            <tm-bottom-sheet
                horizontal
                .open=${this.termsAndConditionsOpen}
                @close=${() => {
                    this.termsAndConditionsOpen = false;
                }}
                ><h3 slot="header-center">Terms and Conditions</h3>
                <tm-body> ${unsafeHTML(this.termsAndConditions?.text)} </tm-body></tm-bottom-sheet
            >
            <tm-footer class="absolute">
                <div class="flex items-center">
                    <span class="text-sm"
                        >Member FDIC | ${equalHousingLender} Equal Housing Lender</span
                    >
                </div>
            </tm-footer>`;
    }

    static get styles() {
        return [
            css`
                .header {
                    background: var(--background-gradient);
                    box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.25);
                    border-top: 1px solid #fff;
                }
                .about-wrapper {
                    margin-top: -60px;
                }
                a {
                    cursor: pointer;
                }
                svg {
                    display: inline-block;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AboutContainer;
    }
}
