import { PayeeModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';
import { getPaymentMethodString } from './payment-list.entity';

export enum PayeeStatus {
    Activated = 0,
    NotActivated = 1,
    Deleted = 2,
}

export class Payee extends DomainEntity<PayeeModelDto> {
    constructor(payee: PayeeModelDto) {
        super(payee);
    }

    public createDefault() {
        return new Payee({} as PayeeModelDto) as this;
    }

    public get account() {
        return this.dto.account;
    }

    public get address() {
        return this.dto.address;
    }

    public get email() {
        return this.dto.email;
    }

    public get estimatedArrivalDay() {
        return this.dto.estimatedArrivalDay;
    }

    public get firstAvailableEstimatedArrivalDate() {
        return this.dto.firstAvailableEstimatedArrivalDate;
    }

    public get firstAvailableProcessDate() {
        return this.dto.firstAvailableProcessDate;
    }

    public get keyword() {
        return this.dto.keyword;
    }

    public get name() {
        return this.dto.name;
    }

    public get nickName() {
        return this.dto.nickName;
    }

    public get paymentMethod() {
        return getPaymentMethodString(this.dto.paymentMethod || 0);
    }

    public get phoneNumber() {
        return this.dto.phoneNumber;
    }

    public get status() {
        return this.dto.status;
    }

    get defaultPayFromAccount() {
        return this.dto.defaultPayFromAccount;
    }

    public get id() {
        return this.dto.id;
    }
}
