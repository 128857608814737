/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    CancelWireModelDto,
    CurrencyModelDto,
    ErrorHttpContentDto,
    FileFormatModelDto,
    FileImportLayoutFieldModelDto,
    LookupListModelDto,
    LookupModelDto,
    PagedListModelOfWireFileModelDto,
    ProductTypeModelDto,
    RateQuoteModelDto,
    RequestQuoteModelDto,
    UserModelDto,
    WireFileModelDto,
    WireFileSearchCriteriaModelDto,
    WireListModelDto,
    WireModelDto,
    WireParseResultModelDto,
    WireProcessResultModelDto,
    WireSearchCriteriaModelDto,
    WireSearchModelDto,
    WireTemplateModelDto,
    WireTemplateSearchModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class WiresClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Wires
     * @name WiresGetAll
     * @summary Gets all Wires.
     * @request GET:/Wires
     */
    wiresGetAll = (params: RequestParams = {}) =>
        this.http.request<WireModelDto[], ErrorHttpContentDto>({
            path: `/Wires`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresPost
     * @summary Creates a Wire.
     * @request POST:/Wires
     */
    wiresPost = (wire: WireModelDto, params: RequestParams = {}) =>
        this.http.request<WireModelDto, ErrorHttpContentDto>({
            path: `/Wires`,
            method: 'POST',
            body: wire,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresDelete
     * @summary Cancels a wire.
     * @request DELETE:/Wires
     */
    wiresDelete = (cancelWireModel: CancelWireModelDto, params: RequestParams = {}) =>
        this.http.request<WireModelDto, ErrorHttpContentDto>({
            path: `/Wires`,
            method: 'DELETE',
            body: cancelWireModel,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresGet
     * @summary Gets a Wire.
     * @request GET:/Wires/{id}
     */
    wiresGet = (id: number, params: RequestParams = {}) =>
        this.http.request<WireModelDto, ErrorHttpContentDto>({
            path: `/Wires/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresPut
     * @summary Updates a Wire.
     * @request PUT:/Wires/{id}
     */
    wiresPut = (id: number, wire: WireModelDto, params: RequestParams = {}) =>
        this.http.request<WireModelDto, ErrorHttpContentDto>({
            path: `/Wires/${id}`,
            method: 'PUT',
            body: wire,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresSearch
     * @summary Searches for Wires.
     * @request POST:/Wires/Search
     */
    wiresSearch = (
        query: {
            /**
             * Type: OneTime, Recurring.
             *
             */
            type: string | null;
        },
        wireSearchCriteria: WireSearchCriteriaModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<WireModelDto[], ErrorHttpContentDto>({
            path: `/Wires/Search`,
            method: 'POST',
            query: query,
            body: wireSearchCriteria,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresGetByType
     * @summary Gets a Wire with a specific type.
     * @request GET:/Wires/GetByType
     */
    wiresGetByType = (
        query: {
            /**
             * Type: OneTime, Recurring.
             *
             */
            type: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<WireModelDto[], ErrorHttpContentDto>({
            path: `/Wires/GetByType`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresApprove
     * @summary Approves a Wire.
     * @request POST:/Wires/{id}/Approve
     */
    wiresApprove = (id: number, model: LookupModelDto, params: RequestParams = {}) =>
        this.http.request<WireModelDto, ErrorHttpContentDto>({
            path: `/Wires/${id}/Approve`,
            method: 'POST',
            body: model,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresReject
     * @summary Rejects a Wire.
     * @request POST:/Wires/{id}/Reject
     */
    wiresReject = (id: number, model: LookupModelDto, params: RequestParams = {}) =>
        this.http.request<WireModelDto, ErrorHttpContentDto>({
            path: `/Wires/${id}/Reject`,
            method: 'POST',
            body: model,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresApproveTemplate
     * @summary Approves a Wire Template.
     * @request POST:/wires/approveTemplate/{id}
     */
    wiresApproveTemplate = (id: number, comments: LookupModelDto, params: RequestParams = {}) =>
        this.http.request<WireTemplateModelDto, ErrorHttpContentDto>({
            path: `/wires/approveTemplate/${id}`,
            method: 'POST',
            body: comments,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresRejectTemplate
     * @summary Rejects a Wire Template.
     * @request POST:/wires/rejectTemplate/{id}
     */
    wiresRejectTemplate = (id: number, comments: LookupModelDto, params: RequestParams = {}) =>
        this.http.request<WireTemplateModelDto, ErrorHttpContentDto>({
            path: `/wires/rejectTemplate/${id}`,
            method: 'POST',
            body: comments,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresCancel
     * @summary Cancels a Wire.
     * @request PUT:/wires/cancel
     */
    wiresCancel = (cancelWireModel: CancelWireModelDto, params: RequestParams = {}) =>
        this.http.request<WireModelDto, ErrorHttpContentDto>({
            path: `/wires/cancel`,
            method: 'PUT',
            body: cancelWireModel,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresGetNewWireQuote
     * @summary Gets a Rate Quote for a Wire.
     * @request POST:/wires/getNewWireQuote
     */
    wiresGetNewWireQuote = (model: RequestQuoteModelDto | null, params: RequestParams = {}) =>
        this.http.request<RateQuoteModelDto, ErrorHttpContentDto>({
            path: `/wires/getNewWireQuote`,
            method: 'POST',
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresAcceptNewWireQuote
     * @summary Accepts a Wire Rate Quote
     * @request POST:/wires/acceptNewWireQuote
     */
    wiresAcceptNewWireQuote = (wire: WireModelDto, params: RequestParams = {}) =>
        this.http.request<WireModelDto, ErrorHttpContentDto>({
            path: `/wires/acceptNewWireQuote`,
            method: 'POST',
            body: wire,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresBatch
     * @summary Approve or Reject the passed Wires.
     * @request POST:/Wires/{id}/Batch
     */
    wiresBatch = (
        id: number,
        query: {
            /**
             * Approve/Reject.
             *
             */
            action: string | null;
        },
        model: LookupListModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<WireListModelDto, ErrorHttpContentDto>({
            path: `/Wires/${id}/Batch`,
            method: 'POST',
            query: query,
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description If there are ErrorSummaryModel's (balance or limit) on one or more of the wires, they will be specified in one of two ways: 1) For ErrorSummaryModel applicable to an individual wire (ex. per transaction limit), the error summary and details will be specified on the WireModel's ErrorSummaryModel. 2) ForErrorSummaryModel applicable to multiple wires (ex. company product limit), the error summary and details will be specified on the WireListModel's ErrorSummaryModel.
     *
     * @tags Wires
     * @name WiresInitiateBulkWires
     * @summary Initiates bulk wires.
     * @request POST:/wires/initiateBulk
     */
    wiresInitiateBulkWires = (wires: WireListModelDto | null, params: RequestParams = {}) =>
        this.http.request<WireListModelDto, ErrorHttpContentDto>({
            path: `/wires/initiateBulk`,
            method: 'POST',
            body: wires,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresGetInternationalWireCurrencies
     * @summary Returns currencies which can be used for processing international wires (Fx foreign currencies and/or USD).
     * @request GET:/wires/getInternationalWireCurrencies
     */
    wiresGetInternationalWireCurrencies = (params: RequestParams = {}) =>
        this.http.request<CurrencyModelDto[], ErrorHttpContentDto>({
            path: `/wires/getInternationalWireCurrencies`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresGetTemplate
     * @summary Returns the details of a wire template.
     * @request GET:/wires/getTemplate/{id}
     */
    wiresGetTemplate = (id: number, params: RequestParams = {}) =>
        this.http.request<WireTemplateModelDto, ErrorHttpContentDto>({
            path: `/wires/getTemplate/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresPostAll
     * @summary Search for wire templates based on search criteria.
     * @request POST:/wires/wireTemplateSearch
     */
    wiresPostAll = (
        wireSearchCriteria: WireTemplateSearchModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<WireTemplateModelDto[], ErrorHttpContentDto>({
            path: `/wires/wireTemplateSearch`,
            method: 'POST',
            body: wireSearchCriteria,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresValidateTemplateName
     * @summary Validate a Wire Template Name.
     * @request GET:/wires/validateTemplateName
     */
    wiresValidateTemplateName = (
        query: {
            /**
             * Wire Template Name.
             *
             */
            templateName: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorHttpContentDto>({
            path: `/wires/validateTemplateName`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresCreateTemplate
     * @summary Creates a Wire Template.
     * @request POST:/wires/createTemplate
     */
    wiresCreateTemplate = (wireTemplate: WireTemplateModelDto, params: RequestParams = {}) =>
        this.http.request<WireTemplateModelDto, ErrorHttpContentDto>({
            path: `/wires/createTemplate`,
            method: 'POST',
            body: wireTemplate,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresUpdateTemplate
     * @summary Updates a Wire Template.
     * @request PUT:/wires/updatetemplate/{id}
     */
    wiresUpdateTemplate = (
        id: number,
        wireTemplate: WireTemplateModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<WireTemplateModelDto, ErrorHttpContentDto>({
            path: `/wires/updatetemplate/${id}`,
            method: 'PUT',
            body: wireTemplate,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresDeleteTemplate
     * @summary Deletes a Wire Template.
     * @request DELETE:/wires/template/{id}
     */
    wiresDeleteTemplate = (id: number, params: RequestParams = {}) =>
        this.http.request<boolean, ErrorHttpContentDto>({
            path: `/wires/template/${id}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresGetFileFormat
     * @summary Gets a File Format
     * @request GET:/wires/fileFormat/{id}
     */
    wiresGetFileFormat = (id: number, params: RequestParams = {}) =>
        this.http.request<FileFormatModelDto, ErrorHttpContentDto>({
            path: `/wires/fileFormat/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * @description If request does not have following format defined it will be defaulted.. "Amount" will be defaulted to "DecimalIncluded" "Effective Date" will be defaulted to "MM/DD/YYYY"
     *
     * @tags Wires
     * @name WiresUpdateFileFormat
     * @summary Updates a File Format.
     * @request PUT:/wires/fileFormat/{id}
     */
    wiresUpdateFileFormat = (
        id: number,
        fileFormatModel: FileFormatModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorHttpContentDto>({
            path: `/wires/fileFormat/${id}`,
            method: 'PUT',
            body: fileFormatModel,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresDeleteFileFormat
     * @summary Deletes a File Format.
     * @request DELETE:/wires/fileFormat/{id}
     */
    wiresDeleteFileFormat = (id: number, params: RequestParams = {}) =>
        this.http.request<boolean, ErrorHttpContentDto>({
            path: `/wires/fileFormat/${id}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresGetFileFormats
     * @summary Gets all File Formats.
     * @request GET:/wires/fileFormat
     */
    wiresGetFileFormats = (params: RequestParams = {}) =>
        this.http.request<FileFormatModelDto[], ErrorHttpContentDto>({
            path: `/wires/fileFormat`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * @description If request does not have following format defined it will be defaulted.. "Amount" will be defaulted to "DecimalIncluded" "Effective Date" will be defaulted to "MM/DD/YYYY"
     *
     * @tags Wires
     * @name WiresCreateFileFormat
     * @summary Creates a File Format.
     * @request POST:/wires/fileFormat
     */
    wiresCreateFileFormat = (fileFormatModel: FileFormatModelDto, params: RequestParams = {}) =>
        this.http.request<boolean, ErrorHttpContentDto>({
            path: `/wires/fileFormat`,
            method: 'POST',
            body: fileFormatModel,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresGetFileFormatFields
     * @summary Gets all File Format Fields.
     * @request GET:/wires/fileFormatField
     */
    wiresGetFileFormatFields = (params: RequestParams = {}) =>
        this.http.request<FileImportLayoutFieldModelDto, ErrorHttpContentDto>({
            path: `/wires/fileFormatField`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * @description Bulk Wire File Format: Wire Amount  DecimalIncluded, WholeDollar, ImpliedDecimal. If no value is provided, the default value of DecimalIncluded will be utilized. Bulk Wire File Format: Effective Date  Date format. If no value is provided, the default value of MM/DD/YYYY will be utilized.
     *
     * @tags Wires
     * @name WiresParseIssuedItems
     * @summary Returns Parsed value as list of Wires or list of errors on parsing.
     * @request POST:/wires/parseWireFile
     */
    wiresParseIssuedItems = (params: RequestParams = {}) =>
        this.http.request<WireParseResultModelDto, ErrorHttpContentDto>({
            path: `/wires/parseWireFile`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresSearchFile
     * @summary Searches for Upload Wire Files.
     * @request POST:/wires/fileSearch
     */
    wiresSearchFile = (
        wireFileSearchCriteria: WireFileSearchCriteriaModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<PagedListModelOfWireFileModelDto, ErrorHttpContentDto>({
            path: `/wires/fileSearch`,
            method: 'POST',
            body: wireFileSearchCriteria,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresSearchFileAll
     * @summary Get DomesticWireFileUploadUsers.
     * @request GET:/wires/getDomesticWireFileUploadUsers
     */
    wiresSearchFileAll = (params: RequestParams = {}) =>
        this.http.request<UserModelDto[], ErrorHttpContentDto>({
            path: `/wires/getDomesticWireFileUploadUsers`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * @description Bulk Wire File Format: Wire Amount  DecimalIncluded, WholeDollar, ImpliedDecimal. If no value is provided, the default value of DecimalIncluded will be utilized. Bulk Wire File Format: Effective Date  Date format. If no value is provided, the default value of MM/DD/YYYY will be utilized.
     *
     * @tags Wires
     * @name WiresProcessWireFile
     * @summary Returns processed value as list of Wires or list of errors on parsing.
     * @request POST:/wires/processWireFile/{id}
     */
    wiresProcessWireFile = (id: string, params: RequestParams = {}) =>
        this.http.request<WireProcessResultModelDto, ErrorHttpContentDto>({
            path: `/wires/processWireFile/${id}`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresGetWireFile
     * @summary Gets the wire file uploaded to the system.
     * @request POST:/wires/wireFile/{id}
     */
    wiresGetWireFile = (id: string, params: RequestParams = {}) =>
        this.http.request<WireFileModelDto, ErrorHttpContentDto>({
            path: `/wires/wireFile/${id}`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresDeleteWireFile
     * @summary Deletes the wire file uploaded to the system.
     * @request DELETE:/wires/wireFile/{id}
     */
    wiresDeleteWireFile = (id: string, params: RequestParams = {}) =>
        this.http.request<boolean, ErrorHttpContentDto>({
            path: `/wires/wireFile/${id}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresSearchWithTotals
     * @summary Searches for Wires and results will give wires and Total/Counts based on the wire status.
     * @request POST:/Wires/Search/v2
     */
    wiresSearchWithTotals = (
        query: {
            /**
             * Type: OneTime, Recurring.
             *
             */
            type: string | null;
        },
        wireSearchCriteria: WireSearchCriteriaModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<WireSearchModelDto, ErrorHttpContentDto>({
            path: `/Wires/Search/v2`,
            method: 'POST',
            query: query,
            body: wireSearchCriteria,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Wires
     * @name WiresGetCorpaySsoLogin
     * @summary Gets the corpay sso login url.
     * @request GET:/wires/corpay/sso
     */
    wiresGetCorpaySsoLogin = (params: RequestParams = {}) =>
        this.http.request<ProductTypeModelDto, ErrorHttpContentDto>({
            path: `/wires/corpay/sso`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
