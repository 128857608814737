export const transferTemplateStatusItems = [
    {
        label: 'Pending Approval',
        value: {
            key: 'PendingApproval',
            value: 'Pending Approval',
        },
    },
    {
        label: 'Ready',
        value: {
            key: 'Ready',
            value: 'Ready',
        },
    },
    {
        label: 'Rejected',
        value: {
            key: 'Rejected',
            value: 'Rejected',
        },
    },
];
