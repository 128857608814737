import { CompleteSecureTokenConfigRequestModelDto } from '@treasury/api/channel';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-password-field';
import '@treasury/presentation/components/tm-text-field';
import { css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';

enum TokenRegistrationActionType {
    RemindMeLater = 1,
    StopReminders = 2,
    Register = 3,
}

export const tagName = 'secure-token-registration';
@customElement(tagName)
export class SecureTokenRegistration extends TmBaseComponent {
    @state()
    private loading = false;

    @state()
    private credentialId: string | null = '';

    @state()
    private token: string | null = '';

    @state()
    private pin: string | null = '';

    @state()
    private confirmPin = '';

    @state()
    private actionType?: TokenRegistrationActionType;

    get pinsMatch() {
        return this.pin === this.confirmPin;
    }

    get validFields() {
        return (
            this.credentialId?.length === 12 &&
            this.token?.length === 6 &&
            this.pin?.length === 4 &&
            this.pinsMatch
        );
    }

    keydown(e: KeyboardEvent) {
        if (e.code === 'Enter') {
            this.register();
        }
    }

    submit() {
        this.dispatchEvent(
            new CustomEvent('submit', {
                detail: {
                    actionType: this.actionType,
                    credentialId: this.credentialId,
                    token: this.token,
                    pin: this.pin,
                } as CompleteSecureTokenConfigRequestModelDto,
            })
        );
    }

    register() {
        this.actionType = TokenRegistrationActionType.Register;
        if (this.validFields) {
            this.submit();
        }
    }

    remindMeLater() {
        this.actionType = TokenRegistrationActionType.RemindMeLater;
        this.credentialId = null;
        this.token = null;
        this.pin = null;
        this.submit();
    }

    renderInstructions() {
        return html`
            <p>
                If you have received or installed your Secure Token/Credential, register it by
                entering the information requested below. If you do not wish to register your Secure
                Token/Credential at this time, or if you have not yet received or installed it,
                select Remind me later.
            </p>

            <ol>
                <li>
                    If you have a key fob, enter the Serial Number, which is located on the back of
                    the fob after the letters "S/N". If you are using a virtual token, enter the
                    Credential ID, which begins with 4 letters.
                </li>
                <li>
                    Enter your Credential/Token Code. This is the random, six-digit code that is
                    displayed on your credential.
                </li>
                <li>
                    Create a 4-digit PIN and enter it twice to confirm it. You will use this PIN in
                    conjunction with the random code generated by your Secure Token/Credential.
                </li>
            </ol>
        `;
    }

    renderPinMismatchWarning() {
        if (!this.pinsMatch && this.pin?.length === 4 && this.confirmPin.length === 4) {
            return html`<span style="color:red">Pins Do Not Match </span>`;
        }
        return nothing;
    }

    render() {
        if (this.loading) return html`<omega-progress card></omega-progress>`;
        return html`
            <div id="content" class="m-4 mb-auto">
                <section>${this.renderInstructions()}</section>

                <tm-text-field
                    label="Serial Number / Credential ID"
                    .value=${this.credentialId ?? ''}
                    @value-changed=${(e: CustomEvent) => {
                        this.credentialId = e.detail.value;
                    }}
                    @keydown=${this.keydown}
                    allowedCharacters="[a-zA-Z0-9]"
                    maxlength="12"
                    minlength="12"
                    required
                    class="pb-1"
                >
                </tm-text-field>
                <tm-text-field
                    label="Token / Credential Code"
                    .value=${this.token ?? ''}
                    @value-changed=${(e: CustomEvent) => {
                        this.token = e.detail.value;
                    }}
                    @keydown=${this.keydown}
                    allowedCharacters="[0-9]"
                    maxlength="6"
                    minlength="6"
                    required
                    class="pb-1"
                >
                </tm-text-field>
                <tm-password-field
                    label="PIN"
                    .value=${this.pin ?? ''}
                    @value-changed=${(e: CustomEvent) => {
                        this.pin = e.detail.value;
                    }}
                    @keydown=${this.keydown}
                    allowedCharacters="[0-9]"
                    maxlength="4"
                    minlength="4"
                    required
                    outline
                    class="pb-1"
                >
                </tm-password-field>
                <tm-password-field
                    label="Confirm PIN"
                    .value=${this.confirmPin}
                    @value-changed=${(e: CustomEvent) => {
                        this.confirmPin = e.detail.value;
                    }}
                    @keydown=${this.keydown}
                    allowedCharacters="[0-9]"
                    maxlength="4"
                    minlength="4"
                    required
                    class="pb-1"
                >
                </tm-password-field>
                ${this.renderPinMismatchWarning()}
            </div>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: 'Register',
                        onClick: () => this.register(),
                        disabled: !this.validFields,
                    },
                    {
                        importance: 'tertiary',
                        text: 'Remind me later',
                        onClick: () => this.remindMeLater(),
                    },
                ] as ButtonConfig[]}
            >
            </tm-footer>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                ol {
                    display: block;
                    list-style-type: decimal;
                    margin-block-start: 1em;
                    margin-block-end: 1em;
                    margin-inline-start: 0px;
                    margin-inline-end: 0px;
                    padding-inline-start: 40px;
                }
                #content {
                    padding-bottom: var(--sticky-header-height, 0);
                }

                tm-footer {
                    margin-top: 1em;
                }

                @media only screen and (min-width: 640px) {
                    #content {
                        padding-bottom: 0px;
                    }

                    tm-footer {
                        position: static;
                    }
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: SecureTokenRegistration;
    }
}
