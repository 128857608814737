import TermsAndConditionsService from '@treasury/domain/channel/services/account/terms-and-conditions-service';
import DownloadService from '@treasury/domain/channel/services/download/download-service.js';
import {
    AuditCode,
    ResourceType,
    UserActivityService,
} from '@treasury/domain/channel/services/user-activity';
import { FiDate } from '@treasury/domain/dates';

// eslint-disable-next-line @treasury/filename-match-export
export default class TermsAndConditionsClient {
    getTermsAndConditions() {
        return TermsAndConditionsService.getTermsAndConditions();
    }

    checkTermsAndConditions() {
        return TermsAndConditionsService.checkTermsAndConditions();
    }

    acceptTermsAndConditions() {
        return TermsAndConditionsService.acceptTermsAndConditions();
    }

    declineTermsAndConditions() {
        return UserActivityService.userInteractionAudit(
            ResourceType.AccountResources,
            AuditCode.DeclineTermsAndConditions
        );
    }

    downloadTermsAndConditions(outputType: any) {
        const fileSuffix = new FiDate(new Date()).toIsoDate();
        return DownloadService.download(
            fileSuffix,
            outputType,
            'TermsAndConditions',
            { page: 'TermsAndConditions' },
            []
        );
    }
}
