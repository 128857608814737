import { PendingLoan } from '@treasury/domain/approvals/pending-loan.entity';
import { ApprovalOptions, ApprovalVm } from '@treasury/presentation';
import { formatUsdCurrency } from '@treasury/utils';

export class PendingLoanViewModel extends ApprovalVm<PendingLoan> {
    constructor(loan: PendingLoan) {
        super(loan, ApprovalOptions.NA);
    }

    get amount() {
        return formatUsdCurrency(this.value.amount);
    }
}
