/* eslint-disable import/extensions */
import { NavigationService } from '@treasury/core/navigation';
import { WireTemplate } from '@treasury/domain/wires';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-bottom-sheet';
import { InjectProperty } from '@treasury/utils';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../components/jhd-status';

export const tagName = 'wire-template-detail-sheet';
@customElement(tagName)
export class WireTemplateDetailSheet extends TmBaseComponent {
    @property({ type: Boolean })
    open = false;

    @property({ type: Object })
    wireTemplate: WireTemplate = new WireTemplate();

    @property({ type: Boolean })
    initiateOnly = false;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    routeToWireInitiation() {
        this.navService.navigate(`/wires/template/initiate/${this.wireTemplate.id}/usd`);
    }

    approveTemplate() {
        /* Service request to approve template followed by success/error message */
    }

    rejectTemplate() {
        /* Service request to reject template followed by success/error message */
    }

    renderInitiateButton() {
        if (this.wireTemplate.status !== 'Ready') {
            return nothing;
        }
        return html`<omega-button type="primary" @click=${() => this.routeToWireInitiation()}
            >Initiate</omega-button
        >`;
    }

    renderApproveRejectButtons() {
        if (this.wireTemplate.status !== 'Pending Approval') {
            return nothing;
        }
        return html`<omega-button type="primary" @click=${() => this.approveTemplate()}
                >Approve</omega-button
            ><omega-button type="primary" @click=${() => this.rejectTemplate()}
                >Reject</omega-button
            >`;
    }

    renderActionButtons() {
        return html`<div
            class="fixed inset-x-0 bottom-0 left-0 w-full bg-white border-t border-[--border-color]"
        >
            ${this.renderInitiateButton}${this.renderApproveRejectButtons}
        </div>`;
    }

    render() {
        return html`<tm-bottom-sheet
            .open=${this.open}
            @close=${() => {
                this.open = false;
                this.dispatchEvent(new CustomEvent('close'));
            }}
        >
            <p slot="header-center">${this.wireTemplate.name}</p>
            <p slot="header-right">
                <jhd-status .status=${this.wireTemplate.status} showLabel></jhd-status>
            </p>
        </tm-bottom-sheet>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    --navbar-height: 60px;
                }
                path {
                    fill: #ffffff;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: WireTemplateDetailSheet;
    }
}
