/* eslint-disable lines-between-class-members */
import { FetchFn } from '@treasury/utils';
import { ConfigurationService } from '../config';
import { TmHttpCache } from './tm-http-cache';
import { TmHttpClient } from './tm-http-client';
import {
    TmHttpConfig,
    TmHttpConfigArrayBuffer,
    TmHttpConfigBlob,
    TmHttpConfigRaw,
    TmHttpConfigText,
    TmHttpResponseType,
} from './tm-http-client.types';

/**
 * A Treasury Management HTTP client utilizing caching.
 */
export class TmHttpClientCached extends TmHttpClient {
    constructor(
        fetchFn: FetchFn,
        configService: ConfigurationService,
        private cache = new TmHttpCache()
    ) {
        super(fetchFn, configService);
    }

    public request(url: string, config: TmHttpConfigText<object>): Promise<string>;
    public request(url: string, config: TmHttpConfigArrayBuffer<object>): Promise<ArrayBuffer>;
    public request(url: string, config: TmHttpConfigRaw<object>): Promise<Response>;
    public request(url: string, config: TmHttpConfigBlob<object>): Promise<Blob>;
    public request<T>(url: string, config?: TmHttpConfig<object> | undefined): Promise<T>;
    public async request<T extends object>(url: string, config?: TmHttpConfig) {
        const normalizedConfig = this.normalizeConfig(config);
        const body = this.extractBody(normalizedConfig);
        const { maxAgeInSeconds = 0, method, responseType } = normalizedConfig;
        const preserveResponse = responseType === TmHttpResponseType.Raw;
        const cacheParams = { url, method, body };
        const cacheEntry =
            body instanceof FormData
                ? undefined
                : this.cache.getEntry(cacheParams, maxAgeInSeconds);

        if (cacheEntry) {
            const response = await cacheEntry.responsePromise;
            return preserveResponse
                ? response.clone()
                : this.transformResponse<T>(response, responseType);
        }

        const responsePromise = super.request(url, {
            ...normalizedConfig,
            responseType: TmHttpResponseType.Raw,
        });

        // TODO: Only cache data if specifically requested (ttl > 0)
        const shouldCache = !(body instanceof FormData) && maxAgeInSeconds > 0;
        if (shouldCache) {
            this.cache.addEntry(cacheParams, responsePromise);
        }

        try {
            const response = await responsePromise;

            // remove bad responses or ones with no content
            if (!response.ok || response.status === 204) {
                this.cache.deleteEntry(cacheParams);
            }

            return this.transformResponse<T>(response, responseType);
        } catch (e) {
            this.cache.deleteEntry(cacheParams);
            throw e;
        }
    }

    public resetCachedValue(endpoint: string, config: TmHttpConfig) {
        const { method } = config;
        const body = method === 'GET' ? undefined : config.body;

        this.cache.deleteEntry({ url: endpoint, method, body });
    }

    public clearCache() {
        this.cache.clear();
    }
}
