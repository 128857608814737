import JhaDatePicker from '@banno/jha-wc/src/jha-date-picker/JhaDatePicker.js';
import { StyleMixin } from '@treasury/presentation/mix-ins/style-mixin';
import { css } from 'lit';
import { customElement } from 'lit/decorators.js';

export const tagName = 'jhd-date-picker-calendar';
@customElement(tagName)
export class JhdDatePickerCalendar extends StyleMixin(JhaDatePicker) {
    static get styles() {
        return [
            ...super.styles,
            css`
                button[disabled] {
                    color: var(--jha-date-picker-disabled-color, var(--jha-text-neutral, #bbbec1));
                    opacity: 0.4;
                }
                *[data-day] {
                    min-height: var(--jha-date-picker-data-day-min-height, 42px);
                    min-width: var(--jha-date-picker-data-day-min-width, 44px);
                }

                :host([show-today]) .day[in-range] button[isToday] {
                    background: var(--jha-date-picker-in-range, #e8f3f9);
                    color: var(--jha-text-base, #565b5f);
                }

                :host([show-today]) .day[in-range] button[selected],
                :host([show-today]) .day[in-range] button[secondary],
                .day button[selected],
                .day button[range-end],
                :host([show-today]) button[selected] {
                    background: var(
                        --jha-date-picker-in-range-selected,
                        var(--jha-color-primary, #3aaeda)
                    );
                    border-radius: var(--jha-date-picker-in-range-border-radius, 30px);
                    color: var(--jha-text-white, #ffffff);
                    opacity: 1;
                    border: none;
                }

                :host([show-today]) .day:not([in-range]) button[isToday] {
                    background: transparent;
                    border: 2px solid var(--jha-border-color, #e4e7ea);
                    color: var(--jha-color-primary, #3aaeda);
                }

                .day button[range-end],
                :host([show-today]) button,
                :host([show-today]) .day[in-range] button[isToday] {
                    border: none;
                }

                .day button {
                    color: var(--jha-text-base, #565b5f);
                }

                div[in-range] button::before {
                    left: 0;
                    width: 50%;
                }
                div[in-range] button::after {
                    width: 50%;
                }
                @media (max-width: 512px) {
                    div[in-range] button::after {
                        left: 50%;
                        width: 80%;
                    }
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: JhdDatePickerCalendar;
    }
}
