/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { DashboardService } from '@treasury/domain/channel/services/dashboard/dashboard-service';
import { TmBaseComponent } from '@treasury/presentation';
import { clockIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-icon';
import '@treasury/presentation/components/tm-loader';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/jhd-tooltip';

export const tagName = 'cutoff-times-tooltip';
@customElement(tagName)
export class CutoffTimesTooltip extends TmBaseComponent {
    @state()
    loading = false;

    @state()
    private cutoffTimes: {
        timeZone: string;
        cutoffTimes: { label: string; cutoffTime: string }[];
    } = { timeZone: '', cutoffTimes: [] };

    @property({ type: Boolean })
    public showCutoffTooltip = false;

    @InjectProperty()
    private declare readonly client: DashboardService;

    async firstUpdated() {
        this.loading = true;
        this.cutoffTimes = await this.client.getCutoffTimes();
        this.loading = false;
    }

    renderCutoffTimes() {
        const cutoffTimeList = this.cutoffTimes.cutoffTimes.map(
            (cutoffTime: { label: string; cutoffTime: string }) =>
                html`<div
                    class="flex justify-between cutoff-time mt-1 text-[--header-text-color] leading-6"
                >
                    <span>${cutoffTime.label}</span>
                    <span>${cutoffTime.cutoffTime}</span>
                </div>`
        );
        return html`<div
                class="cutoff-header flex flex-row items-center pb-1 text-sm font-medium text-[--header-text-color] border-b border-[--border-color]"
            >
                <span><jhd-icon class="tooltip-icon" .icon=${clockIcon}></jhd-icon></span>
                <span
                    >&nbsp;Cut-Off Times
                    <span class="cutoff-time-zone text-xs font-normal"
                        >${this.cutoffTimes.timeZone}</span
                    ></span
                >
            </div>
            ${cutoffTimeList}`;
    }

    render() {
        if (this.loading) return html`<tm-loader></tm-loader>`;
        return html`<jhd-tooltip direction="bottom-left" light>
            <tm-icon class="nav-bar-icon" .icon=${clockIcon}></tm-icon>
            <style>
                .tooltip-content {
                    width: 196px;
                    margin-top: -7px;
                }
            </style>
            <div class="tooltip-content" slot="content">${this.renderCutoffTimes()}</div>
        </jhd-tooltip>`;
    }

    static get styles() {
        return [
            css`
                .nav-bar-icon {
                    --icon-fill-color: var(--header-bar-button-color);
                }

                tm-loader {
                    --loader-color: var(--header-bar-button-color);
                }

                .tooltip-icon {
                    --icon-fill-color: var(--secondary-text-color);
                    position: relative;
                    top: 2px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: CutoffTimesTooltip;
    }
}
