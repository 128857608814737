/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-extraneous-dependencies */
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-table-header';
import '@vaadin/grid';
import { GridActiveItemChangedEvent } from '@vaadin/grid';
import { columnBodyRenderer, GridColumnBodyLitRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { filterTableResults } from '../../../utilities/table-result-filter';
import { BillPayAccountTableViewModel } from '../data/bill-pay-account-table-view-model';

export const tagName = 'business-bill-pay-accounts-table';
@customElement(tagName)
export class BusinessBillPayAccountsTable extends TmBaseComponent {
    @property({ type: Array })
    accounts: BillPayAccountTableViewModel[] = [];

    @state()
    filteredAccounts: BillPayAccountTableViewModel[] = [];

    @state()
    fieldsToFilter = ['name', 'accountNumber', 'accountType', 'routingNumber'];

    firstUpdated() {
        this.filteredAccounts = this.accounts;
    }

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        if (changedProperties.has('accounts')) {
            this.filteredAccounts = this.accounts;
        }
    }

    filterResults(e: CustomEvent) {
        this.filteredAccounts = filterTableResults(
            e.detail.value,
            this.accounts,
            this.fieldsToFilter
        );
    }

    renderNameAndNumber(account: BillPayAccountTableViewModel) {
        return html`<div class="flex flex-col">
            <span class="text-sm font-normal">${account.name}</span>
            <span class="text-xs">${account.accountNumber}</span>
        </div>`;
    }

    renderBalance(account: BillPayAccountTableViewModel) {
        return html`<div class="flex flex-col">
            <span class="text-sm font-normal">${account.accountType}</span>
            <span class="text-xs">${account.routingNumber}</span>
        </div>`;
    }

    renderTable() {
        if (!this.filteredAccounts) return nothing;
        if (this.filteredAccounts?.length === 0) {
            return html`<div class="mx-auto w-full text-center mt-2 empty-message">
                No Accounts Found
            </div>`;
        }
        type CustomLitRenderer = GridColumnBodyLitRenderer<BillPayAccountTableViewModel>;
        return html`<vaadin-grid
            all-rows-visible
            .items=${this.filteredAccounts}
            class="border-y border-neutral-200"
            @active-item-changed=${(
                e: GridActiveItemChangedEvent<BillPayAccountTableViewModel>
            ) => {
                if (e.detail.value) {
                    this.dispatchEvent(
                        new CustomEvent('account-selected', {
                            detail: e.detail.value,
                            bubbles: true,
                        })
                    );
                }
                this.dispatchEvent(new CustomEvent('close', { bubbles: true }));
            }}
            ><vaadin-grid-column
                flex-grow="1"
                ${columnBodyRenderer(this.renderNameAndNumber as CustomLitRenderer, [])}
            ></vaadin-grid-column
            ><vaadin-grid-column
                text-align="end"
                flex-grow="0"
                ${columnBodyRenderer(this.renderBalance as CustomLitRenderer, [])}
            ></vaadin-grid-column
        ></vaadin-grid>`;
    }

    render() {
        return html`<div class="pwa-table-wrapper">
            <tm-table-header
                @filterStringChange=${(e: CustomEvent) => this.filterResults(e)}
            ></tm-table-header
            >${this.renderTable()}
        </div>`;
    }

    static get styles() {
        return [
            css`
                vaadin-grid-cell-content {
                    padding: 6px 16px 6px 0;
                }
                vaadin-grid::part(first-column-cell) {
                    margin-left: 16px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: BusinessBillPayAccountsTable;
    }
}
