import { Injectable } from '../dependency-injection';
import { getSearchParams } from '../functions';

/**
 * Wrapper for access to the window object.
 * Provides an injectable abstraction for properties and methods available on global state.
 */
@Injectable()
export class WindowService extends EventTarget {
    private window = window;

    // eslint-disable-next-line @treasury/no-date
    public readonly Date = Date;

    public get document() {
        return this.window.document;
    }

    public get location() {
        return this.window.location;
    }

    public get history() {
        return this.window.history;
    }

    public get console() {
        return this.window.console;
    }

    public get searchParams() {
        return getSearchParams();
    }

    public print() {
        this.window.print();
    }

    public addEventListener(...args: Parameters<Window['addEventListener']>) {
        this.window.addEventListener(...args);

        return {
            unsubscribe: () => this.window.removeEventListener(...args),
        };
    }

    public removeEventListener(...args: Parameters<Window['removeEventListener']>): void {
        this.window.removeEventListener(...args);
    }

    public createTimer(handler: TimerHandler, duration = 0) {
        const id = this.window.setTimeout(handler, duration);

        return {
            stop: () => this.window.clearTimeout(id),
        };
    }
}
