import {
    CompanyAccountModelDto,
    FrequencyModelDto,
    LookupModelDto,
    WireCompanyModelDto,
    WireConfigurationDto,
} from '@treasury/api/channel';
import { defaultFrequency, Wire, WiresService, WireType } from '@treasury/domain/wires';
import { LabeledList, TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/forms/tm-form-frequency';
import '@treasury/presentation/components/forms/tm-form-row';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-currency-field';
import '@treasury/presentation/components/tm-frequency';
import {
    mapFrequencyModelDtoToFrequency,
    mapFrequencyToFrequencyModelDto,
} from '@treasury/presentation/components/tm-frequency.mappings';
import '@treasury/presentation/components/tm-labeled-list';
import '@treasury/presentation/components/tm-multi-line-text-input';
import '@treasury/presentation/components/tm-text-field';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { ActiveDateFilterFunction } from '../../../components/jhd-date-picker/date-picker.types';
import '../../shared/partials/wire-template-read-only';
import { DebitAccountViewModel, WireCompanyViewModel } from '../../shared/types/wire-fields';
import { WireAction, WireWorkflow } from '../../wire-workflow/types/wire-workflow-parameters.type';

export const tagName = 'wire-editable';
@customElement(tagName)
export class WireEditable extends TmBaseComponent {
    @InjectProperty()
    private declare wireService: WiresService;

    @property({ type: Object })
    wire = new Wire();

    @property({ type: Object })
    wireConfiguration!: WireConfigurationDto;

    @property()
    wireType = WireType.Domestic;

    @property()
    action?: WireAction;

    @property()
    workflow?: WireWorkflow;

    @property({ type: Object })
    wireCompany: WireCompanyModelDto | null = null;

    @state()
    private wireCompaniesMap = new Map<string, WireCompanyModelDto>();

    @state()
    wireCompanyOptions = [] as WireCompanyViewModel[];

    @property({ type: Object })
    debitAccount: CompanyAccountModelDto | null = null;

    @property()
    debitAccounts: CompanyAccountModelDto[] = [];

    @state()
    private debitAccountsMap = new Map<string, CompanyAccountModelDto>();

    @state()
    private debitAccountOptions = [] as DebitAccountViewModel[];

    @property({ type: Object })
    frequency: FrequencyModelDto = defaultFrequency;

    @state()
    availableStartDateFilter: ActiveDateFilterFunction = (date: Date) => true;

    @state()
    maxStartDate: Date | null = null;

    @state()
    minStartDate: Date = new Date();

    @state()
    loading = true;

    @property({ type: Number })
    amount = 0;

    @property({ type: String })
    purpose = '';

    @property({ type: Array })
    additionalInformation?: LookupModelDto[] | null = [
        { id: 0, updatedDate: '' },
    ] as LookupModelDto[];

    @property({ type: String })
    referenceBeneficiary = '';

    @property()
    wireCompanies: Array<WireCompanyModelDto> = [];

    private purposeMaxLength = 30;

    private referenceBeneficiaryMaxLength = 16;

    private additionalInfoMaxLines = 4;

    private additionalInfoMaxLineLength = 35;

    public async firstUpdated() {
        this.loading = true;
        try {
            this.wireCompanies.forEach(company => {
                this.wireCompaniesMap.set(company.id.toString(), company);
            });
            if (!this.wireConfiguration) {
                this.wireConfiguration = await this.wireService.getWireConfiguration();
            }
            this.filterWireCompanies();

            this.debitAccounts.forEach(debitAccount => {
                this.debitAccountsMap.set(debitAccount.id.toString(), debitAccount);
                this.debitAccountOptions.push(new DebitAccountViewModel(debitAccount));
            });
        } catch (e) {
            console.error(e);
        } finally {
            this.calcMaxStartDate();
            this.hydrateFromWire();
            this.loading = false;
        }
    }

    protected updated(changedProperties: Map<string, unknown>) {
        if (changedProperties.has('wireType')) {
            this.filterWireCompanies();
        }

        if (changedProperties.has('wire')) {
            this.hydrateFromWire();
        } else {
            this.fireChangeEvent();
        }

        if (changedProperties.has('loading')) {
            this.dispatchEvent(
                new CustomEvent('loadingStatusChange', {
                    bubbles: true,
                    composed: true,
                    detail: this.loading,
                })
            );
        }

        super.updated(changedProperties);
    }

    private hydrateFromWire() {
        this.wireType = this.wire.isInternational ? WireType.International : WireType.Domestic;
        this.wireCompany = this.wire.wireCompany;
        this.debitAccount = this.wire.debitAccount;
        this.amount = this.wire.amount;
        this.frequency = this.wire.frequency as FrequencyModelDto;
        this.purpose = this.wire.purpose ?? '';
        this.additionalInformation = this.wire.additionalInformation;
        this.referenceBeneficiary = this.wire.referenceBeneficiary ?? '';
    }

    private calcMaxStartDate() {
        if (this.wireConfiguration.maximumFutureDays) {
            const date = new Date();
            date.setDate(date.getDate() + this.wireConfiguration.maximumFutureDays ?? 0);
            // eslint-disable-next-line prefer-destructuring
            this.maxStartDate = date;
            this.requestUpdate();
        }
    }

    private filterWireCompanies() {
        this.wireCompanyOptions = [];
        const internationalUsd =
            this.wireType === WireType.International && this.wireConfiguration?.fiDliUsdEnabled;
        const filteredCompanies = this.wireCompanies.filter(
            company => company.isDliWireCompany === internationalUsd
        );

        filteredCompanies.forEach(company => {
            this.wireCompanyOptions.push(new WireCompanyViewModel(company));
        });
    }

    private onWireCompanyChange(e: Event) {
        const id = (e.target as HTMLSelectElement).value;
        const wireCompany = this.wireCompaniesMap.get(id);
        if (!wireCompany) {
            return;
        }
        this.wireCompany = wireCompany;
        this.requestUpdate();
        this.fireChangeEvent();
    }

    private isWireCompanyDto(toBeDetermined: any): toBeDetermined is WireCompanyModelDto {
        return (
            toBeDetermined instanceof Object &&
            'id' in toBeDetermined &&
            'isDliWireCompany' in toBeDetermined
        );
    }

    private onDebitAccountChange(e: Event) {
        const id = (e.target as HTMLSelectElement).value;
        const debitAccount = this.debitAccountsMap.get(id);
        if (!debitAccount) {
            return;
        }
        this.debitAccount = debitAccount;
        this.fireChangeEvent();
    }

    private isValidWire() {
        return (
            this.wireCompany &&
            this.debitAccount &&
            this.amount > 0 &&
            this.purpose.length &&
            this.frequency
        );
    }

    private fireChangeEvent() {
        const wireOutput = this.isValidWire()
            ? new Wire({
                  ...this.wire.toDto(),
                  wireCompany: this.wireCompany,
                  debitAccount: this.debitAccount,
                  amount: this.amount,
                  frequency: this.frequency as FrequencyModelDto,
                  purpose: this.purpose,
                  additionalInformation: this.additionalInformation,
                  referenceBeneficiary: this.referenceBeneficiary,
              })
            : false;

        this.dispatchEvent(
            new CustomEvent('wireChange', {
                detail: {
                    value: wireOutput,
                },
            })
        );
    }

    renderHeader() {
        if (this.workflow === 'template' && this.action === 'initiate' && this.wire.wireTemplate) {
            const templateDetails = {
                wireCompany: this.wire.wireCompany ? this.wire.wireCompany.name : '',
                debitAccount: this.wire.debitAccount
                    ? (this.wire.debitAccount.accountDisplayLabel as string)
                    : '',
                type: this.wire.wireTemplate?.isInternational ? 'International' : 'Domestic',
                beneficiary: this.wire.beneficiary?.name ? this.wire.beneficiary.name : '',
            };
            const templateSummary = new LabeledList(
                templateDetails,
                ['wireCompany', 'debitAccount', 'type', 'beneficiary'],
                undefined,
                { beneficiary: 'beneficiaryClick' }
            );
            return html`
                <tm-form-row label="Template">
                    <tm-slider
                        header="Template Details"
                        .value=${ifDefined(this.wire.wireTemplate.name)}
                    >
                        <tm-body
                            ><wire-template-read-only
                                .wireTemplate=${this.wire.wireTemplate}
                            ></wire-template-read-only
                        ></tm-body>
                    </tm-slider>
                    <tm-labeled-list
                        slot="summary"
                        class="py-4"
                        .list=${templateSummary}
                    ></tm-labeled-list>
                </tm-form-row>
            `;
        }
    }

    renderReferenceBeneficiaryField() {
        if (this.wireType === WireType.International) return nothing;
        return html`
            <tm-form-row
                label="Reference Beneficiary"
                helperText="Information enabling the beneficiary to identify transfer."
            >
                <tm-text-field
                    .value=${this.referenceBeneficiary}
                    .maxLength=${this.referenceBeneficiaryMaxLength}
                    .pattern=${/^[a-zA-Z0-9!\"#$%\'\‘\′()\+\/\:\;\<=>?@_`~\-\.&\, ]+$/}
                    @value-changed=${(e: CustomEvent) => {
                        this.referenceBeneficiary = e.detail.value;
                    }}
                    .disabled=${!this.isWireCompanyDto(this.wire.wireCompany)}
                    .helperText="${!!this.referenceBeneficiary.length
                        ? `${this.referenceBeneficiary.length}/${this.referenceBeneficiaryMaxLength}`
                        : ''}"
                    class="helper-align-right"
                ></tm-text-field>
            </tm-form-row>
        `;
    }

    render() {
        if (this.loading) return nothing;
        return html`
            ${this.renderHeader()}
            <tm-form-row label="Amount" required>
                <tm-currency-field
                    .value=${this.amount > 0 ? this.amount.toString() : '0'}
                    @value-changed=${({ detail }: CustomEvent) => {
                        this.amount = detail;
                    }}
                    .disabled=${!this.isWireCompanyDto(this.wire.wireCompany)}
                    class="max-w-[130px]"
                ></tm-currency-field>
            </tm-form-row>
            <tm-form-frequency
                required
                dateLabel="Payment Date"
                .frequency=${!!this.frequency
                    ? mapFrequencyModelDtoToFrequency(this.frequency)
                    : undefined}
                @select-frequency=${(e: CustomEvent) => {
                    this.frequency = !!e.detail
                        ? (mapFrequencyToFrequencyModelDto(e.detail) as FrequencyModelDto)
                        : e.detail;
                }}
                .disabled=${!this.isWireCompanyDto(this.wire.wireCompany) ||
                this.wire.type === 'International'}
            ></tm-form-frequency>
            <tm-form-row label="Purpose" required>
                <tm-text-field
                    .maxLength=${this.purposeMaxLength}
                    .value=${this.purpose}
                    @value-changed=${(e: CustomEvent) => {
                        this.purpose = e.detail.value;
                    }}
                    .disabled=${!this.isWireCompanyDto(this.wire.wireCompany)}
                    .helperText="${!!this.purpose.length
                        ? `${this.purpose.length}/${this.purposeMaxLength}`
                        : ''}"
                    class="helper-align-right"
                ></tm-text-field>
            </tm-form-row>

            ${this.renderReferenceBeneficiaryField()}
            <tm-form-row
                label="Additional Information"
                helperText="Specific information that you wish to communicate to the beneficiary can be entered in the Additional Information fields."
            >
                <tm-multi-line-text-input
                    placeholder="Line"
                    .maxLines=${this.additionalInfoMaxLines}
                    .characterLimit=${this.additionalInfoMaxLineLength}
                    .pattern="${/^[a-zA-Z0-9!\"#$%\'()\+\/\:\;\\<=>?@_`^~\-\.&\, ]+$/}"
                    .value=${this.additionalInformation ?? []}
                    @change=${(e: CustomEvent) => {
                        this.additionalInformation = e.detail;
                    }}
                    .disabled=${!this.isWireCompanyDto(this.wire.wireCompany)}
                ></tm-multi-line-text-input>
            </tm-form-row>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: WireEditable;
    }
}
