/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    CompanyModelDto,
    DateTypeModelDto,
    FilterLookupModelCollectionDto,
    GetAccountsRequestModelDto,
    GetAchCompanyListByParentCompanyModelDto,
    GetAchCompanyListByParentCompanyRequestModelDto,
    GetBoReportUserListForCompaniesRequestModelDto,
    GetBoReportUserListForCompaniesResponseModelDto,
    GetCompanyListByFiModelDto,
    GetInternalTransferStatusTypesResponseModelDto,
    PositivePayCompanyCollectionDto,
    ReportUserModelCollectionDto,
    UserActivityTypeModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class ReportFilterClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags ReportFilter
     * @name ReportFilterActivityTypes
     * @summary Gets user activity types
     * @request GET:/ReportFilter/ActivityTypes
     */
    reportFilterActivityTypes = (params: RequestParams = {}) =>
        this.http.request<UserActivityTypeModelDto[], any>({
            path: `/ReportFilter/ActivityTypes`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ReportFilter
     * @name ReportFilterGetCompanies
     * @summary Given a companyType query string returns a filtered list of companies
     * @request GET:/ReportFilter/Companies
     */
    reportFilterGetCompanies = (
        query: {
            companyType: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyModelDto[], any>({
            path: `/ReportFilter/Companies`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * @description Error Codes and Responses | Response Code | Response Message | Remediation | |---|---|---| | 1025 | GetPositivePayCompaniesAccessFailure | Failure result returned by the GetPositivePayCompaniesAccess class. |
     *
     * @tags ReportFilter
     * @name ReportFilterGetCheckExceptionsCompanies
     * @summary Gets the Positive Pay companies that have the WorkCheckException product option enabled.
     * @request GET:/ReportFilter/Companies/CheckExceptions
     */
    reportFilterGetCheckExceptionsCompanies = (params: RequestParams = {}) =>
        this.http.request<PositivePayCompanyCollectionDto, any>({
            path: `/ReportFilter/Companies/CheckExceptions`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * @description Error Codes and Responses | Response Code | Response Message | Remediation | |---|---|---| | 1025 | GetPositivePayCompaniesAccessFailure | Failure result returned by the GetPositivePayCompaniesAccess class. |
     *
     * @tags ReportFilter
     * @name ReportFilterGetAchExceptionsCompanies
     * @summary Gets the Positive Pay companies that have the WorkAchException product option enabled.
     * @request GET:/ReportFilter/Companies/AchExceptions
     */
    reportFilterGetAchExceptionsCompanies = (params: RequestParams = {}) =>
        this.http.request<PositivePayCompanyCollectionDto, any>({
            path: `/ReportFilter/Companies/AchExceptions`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * @description Error Codes and Responses | Response Code | Response Message | Remediation | |---|---|---| | 1025 | GetPositivePayCompaniesAccessFailure | Failure result returned by the GetPositivePayCompaniesAccess class. |
     *
     * @tags ReportFilter
     * @name ReportFilterGetAchExceptionFiltersCompanies
     * @summary Gets the Positive Pay companies that have the WorkAchFilter product option enabled.
     * @request GET:/ReportFilter/Companies/AchExceptionFilters
     */
    reportFilterGetAchExceptionFiltersCompanies = (params: RequestParams = {}) =>
        this.http.request<PositivePayCompanyCollectionDto, any>({
            path: `/ReportFilter/Companies/AchExceptionFilters`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ReportFilter
     * @name ReportFilterGetDateTypes
     * @summary Gets date types
     * @request GET:/ReportFilter/DateTypes
     */
    reportFilterGetDateTypes = (params: RequestParams = {}) =>
        this.http.request<DateTypeModelDto[], any>({
            path: `/ReportFilter/DateTypes`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ReportFilter
     * @name ReportFilterGetCompaniesByFiId
     * @summary Gets a list of companies for the user's institution id.
     * @request POST:/ReportFilter/companiesByFiId
     */
    reportFilterGetCompaniesByFiId = (params: RequestParams = {}) =>
        this.http.request<GetCompanyListByFiModelDto, any>({
            path: `/ReportFilter/companiesByFiId`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ReportFilter
     * @name ReportFilterGetAchCompaniesByParentCompany
     * @summary Gets a list of ach companies for selected parent company guid.
     * @request POST:/ReportFilter/achCompanyListByParentCompany
     */
    reportFilterGetAchCompaniesByParentCompany = (
        request: GetAchCompanyListByParentCompanyRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<GetAchCompanyListByParentCompanyModelDto, any>({
            path: `/ReportFilter/achCompanyListByParentCompany`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ReportFilter
     * @name ReportFilterGetChannelReportUsers
     * @summary Gets a list of channel users.
     * @request GET:/ReportFilter/Users/Channel
     */
    reportFilterGetChannelReportUsers = (
        query?: {
            companyIds?: number[] | null;
            companyUniqueIds?: string[] | null;
            companyGuidUniqueIds?: string[] | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<ReportUserModelCollectionDto, any>({
            path: `/ReportFilter/Users/Channel`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ReportFilter
     * @name ReportFilterGetChannelUsersForCompanies
     * @summary Gets a list of channel users belonging to the specified companies.
     * @request POST:/ReportFilter/Users/Channel/Companies
     */
    reportFilterGetChannelUsersForCompanies = (
        request: GetBoReportUserListForCompaniesRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<GetBoReportUserListForCompaniesResponseModelDto, any>({
            path: `/ReportFilter/Users/Channel/Companies`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ReportFilter
     * @name ReportFilterGetInternalTransferTypes
     * @summary Gets a list of internal transfer status types.
     * @request POST:/ReportFilter/Statuses/TransferPayment
     */
    reportFilterGetInternalTransferTypes = (params: RequestParams = {}) =>
        this.http.request<GetInternalTransferStatusTypesResponseModelDto, any>({
            path: `/ReportFilter/Statuses/TransferPayment`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ReportFilter
     * @name ReportFilterGetAccounts
     * @summary Gets a list of accounts for the authorized users' FI.
     * @request POST:/ReportFilter/accounts
     */
    reportFilterGetAccounts = (
        request: GetAccountsRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<FilterLookupModelCollectionDto, any>({
            path: `/ReportFilter/accounts`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
