import { ConfigurationService } from '@treasury/core/config';
import { NavigationService } from '@treasury/core/navigation';
import { TmBaseComponent } from '@treasury/presentation';
import { arrowMergeIcon, circlePlusIcon, helpIcon } from '@treasury/presentation/assets/icons';
import { InjectProperty } from '@treasury/utils';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import './quick-deposit-container';

export const tagName = 'deposit-container';
@customElement(tagName)
export class DepositContainer extends TmBaseComponent {
    @InjectProperty()
    private declare readonly headerService: HeaderBarService;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    @InjectProperty()
    private declare readonly config: ConfigurationService;

    @state()
    private captureResult = {};

    @state()
    private disabled = false;

    @state()
    private checkSide = 'front' || 'back';

    @state()
    private isQuickDepositOpen = false;

    firstUpdated() {
        this.headerService.configure({
            title: 'Create Deposit',
            menuItems: [{ title: 'Help', icon: helpIcon, action: () => alert('TODO') }],
        });
    }

    renderRouteActionItem() {
        return html`
            <jhd-route-action-item
                .icon=${circlePlusIcon}
                .action=${'Quick Deposit'}
                .actionDescription=${'Deposit a single check'}
                @click=${() => {
                    this.isQuickDepositOpen = true;
                    this.navService.navigate(`/quick-deposit`);
                }}
                }
            ></jhd-route-action-item>
            <jhd-route-action-item
                .icon=${arrowMergeIcon}
                .action=${'Batch Deposit'}
                .actionDescription=${'Deposit multiple checks'}
                @click=${() => this.navService.navigate(`/batch-deposit`)}
                }
            ></jhd-route-action-item>
        `;
    }

    renderQuickDeposit() {
        if (!this.isQuickDepositOpen) return nothing;
        return html`
            <quick-deposit-container
                .isOpen=${this.isQuickDepositOpen}
                @close=${() => {
                    this.isQuickDepositOpen = false;
                    this.navService.navigate(`/deposit`);
                }}
            ></quick-deposit-container>
        `;
    }

    render() {
        return html`
            ${this.renderRouteActionItem()}
            <div class="bg-white p-6 h-full flex flex-col text-center ">
                <span class="text-sm">
                    Before depositing your check, please endorse it by doing the following:
                </span>
                <ol class=" w-52 pl-8 pt-4">
                    <li class="pb-4 flex  items-center ">
                        <span class="circle mr-2">1</span>Sign the check
                    </li>
                    <li class="pb-4 flex  items-center">
                        <span class="circle mr-2">1</span> Under your signature, write <br />
                        "For Mobile Deposit Only".
                    </li>
                </ol>
                <img alt="Back of endorsed check" src="/assets/images/check-signature.webp" />
            </div>
            ${this.renderQuickDeposit()}
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    height: 100%;
                }
                .circle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background-color: var(--jha-color-primary);
                    color: white;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: DepositContainer;
    }
}
