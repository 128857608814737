/* eslint-disable import/extensions */
import { TmBaseComponent } from '@treasury/presentation';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export const tagName = 'jhd-data-field';
@customElement(tagName)
export class JhdDataField extends TmBaseComponent {
    @property({ type: String, reflect: true })
    label = '';

    @property({ type: String, reflect: true })
    value: string | string[] | object[] = '';

    @property({ type: Boolean, reflect: true })
    edited = false;

    @property({ type: String })
    valueProperty = '';

    @property({ type: Boolean, reflect: true })
    isClickable = false;

    @property({ type: Boolean, reflect: true })
    unemphasize = false;

    get displayValue() {
        if (this.valueProperty && Array.isArray(this.value)) {
            // @ts-ignore
            return this.value.map((item: object) => html`${item[this.valueProperty]}<br />`);
        }
        if (Array.isArray(this.value)) {
            return this.value.map(value => html`${value}<br />`);
        }
        return this.value;
    }

    renderValue() {
        if (!this.edited) return html`${this.displayValue}`;
        return html`<span class="edited p-1">${this.displayValue}</span>`;
    }

    render() {
        return html`<div class="flex text-sm">
            <span class="label mr-3">${this.label}</span
            ><span
                @click=${() => {
                    if (this.isClickable) {
                        this.dispatchEvent(new Event('dataFieldClick'));
                    }
                }}
                @keydown=${(e: KeyboardEvent) => {
                    if (this.isClickable && e.key === 'Enter') {
                        this.dispatchEvent(new Event('dataFieldClick'));
                    }
                }}
                class="value"
                >${this.renderValue()}</span
            >
        </div>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    margin: 12px 0;
                }
                .label {
                    color: var(--secondary-text-color, #444444);
                    flex: 1 1 var(--jhd-field-label-width, 100px);
                }
                .value {
                    color: var(--header-text-color, #444444);
                    flex: 3 1 var(--jhd-field-control-width, 150px);
                }
                .edited {
                    background: rgba(255, 153, 0, 0.25);
                    mix-blend-mode: darken;
                }
                :host([isClickable]) .value {
                    color: var(--primary-action-color, var(--primary-button-color));
                    cursor: pointer;
                }

                :host([unemphasize]) .value {
                    color: var(--jha-text-light, var(--brand-text-light, #999999));
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: JhdDataField;
    }
}
