import { svg } from 'lit';

export const documentIcon = svg`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 8.75H9C8.58579 8.75 8.25 8.41421 8.25 8C8.25 7.58579 8.58579 7.25 9 7.25H15C15.4142 7.25 15.75 7.58579 15.75 8C15.75 8.41421 15.4142 8.75 15 8.75ZM15 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H15C15.4142 11.25 15.75 11.5858 15.75 12C15.75 12.4142 15.4142 12.75 15 12.75ZM9 16.75H15C15.4142 16.75 15.75 16.4142 15.75 16C15.75 15.5858 15.4142 15.25 15 15.25H9C8.58579 15.25 8.25 15.5858 8.25 16C8.25 16.4142 8.58579 16.75 9 16.75ZM18 20.75H6C5.58579 20.75 5.25 20.4142 5.25 20V4C5.25 3.58579 5.58579 3.25 6 3.25H18C18.4142 3.25 18.75 3.58579 18.75 4V20C18.75 20.4142 18.4142 20.75 18 20.75ZM6.75 19.25H17.25V4.75H6.75V19.25Z"
        fill="#786E5C"
    />
</svg>`;
