/* eslint-disable no-use-before-define */
import 'reflect-metadata';

import { exists, isConstructor, isPrimitiveCtor, isSubclassOf, noOp } from '../functions';
import { AbstractConstructor, Constructor, CtorsOf } from '../types';
import {
    DependencyDefinition,
    DependencyFactory,
    DiToken,
    FactoryDefinition,
    InjectionToken,
} from './di.types';

import { ObservationSource } from '../types/observable';
import { DependencyGraph, DependencyNode } from './dependency-graph';
import { getTokenName, isInjectable } from './di.helpers';

const diStream = new ObservationSource<DiContainer>();

export const di$ = diStream.toObservable();
/**
 * Registry of services available for injection.
 */
export class DiContainer {
    private readonly registry = new Map<DiToken<unknown>, DependencyDefinition<unknown>>();

    private readonly factories = new Map<DiToken<unknown>, FactoryDefinition<unknown>>();

    public static getInstance() {
        return di$.toPromise();
    }

    /**
     * Initialize an application-wide, singleton DI container instance or provide your own.
     */
    public static async init(instance = new DiContainer()) {
        const promise = di$.toPromise();
        diStream.emit(instance);

        return promise.then(noOp);
    }

    public has<T>(token: DiToken<T>) {
        return this.registry.has(token);
    }

    /**
     * Provide a subclass constructor to fulfill the instance of a parent class token.
     *
     * Exists to support the
     * [Liskov substitution principle](https://en.wikipedia.org/wiki/Liskov_substitution_principle)
     * and [polymorphic code](https://en.wikipedia.org/wiki/Polymorphism_(computer_science)).
     *
     * @param ctorToken The constructor by which the dependency will be injected.
     * @param implementationCtor
     */
    public provide<T, I extends T>(
        ctorToken: Constructor<T> | AbstractConstructor<T>,
        implementationCtor: Constructor<I>
    ): this;
    /**
     * Provide an already-existing dependency that is accessible with the provided token.
     *
     * @param token An `InjectToken` reference to look up the dependency with.
     * @param instance An optional instance to associate with the token if the default value
     * should be overridden.
     */
    // eslint-disable-next-line lines-between-class-members
    public provide<T>(token: InjectionToken<T>, instance?: T): this;
    /**
     * Provide an already-existing dependency that is accessible with the provided key.
     *
     * @param key A string value key to look up the dependency with.
     * @param instance An instance of the dependency available with the provided string.
     */
    // eslint-disable-next-line lines-between-class-members
    public provide<T>(key: string, instance: T): this;
    // eslint-disable-next-line lines-between-class-members
    /**
     * Provide a dependency using a constructable token.
     * @param constructor A constructor to be used as a DI token and—optionally—as a constructor for instantiation.
     * @param instance An optional instance of the class to use associated with provided type.
     * This will be used instead of the container instantiating the class itself.
     */
    // eslint-disable-next-line lines-between-class-members
    public provide<T>(constructor: Constructor<T> | AbstractConstructor<T>, instance?: T): this;
    // eslint-disable-next-line lines-between-class-members
    public provide<T>(token: DiToken<T>, instanceOrCtor?: T | Constructor<T>) {
        if (typeof token === 'string') {
            if (!instanceOrCtor) {
                throw new Error(
                    `Dependency ${token} is being provided as a string token but no instance was given.`
                );
            }
        } else if (token instanceof InjectionToken) {
            instanceOrCtor = instanceOrCtor || token.getValue();
        } else if (isConstructor<T>(instanceOrCtor) && isSubclassOf(token, instanceOrCtor)) {
            this.setSubclassToken(token, instanceOrCtor);
            return this;
        } else if (!instanceOrCtor && !isInjectable(token)) {
            throw new Error(`Cannot register ${token.name} as a dependency. It is not decorated.`);
        }

        this.setToken(token, instanceOrCtor);

        return this;
    }

    /**
     * Provide a dependency via a factory function.
     *
     * Useful for when an instance needs to be created at run time or
     * it has constructor arguments that cannot be fulfilled entirely via DI.
     *
     * @param token The DI token to associate the instance with.
     * @param dependencies Any dependencies that the factory requires to create its instance.
     * @param factory A function to invoke once to create a singleton instance of the provided dependency.
     */
    public provideFactory<T, Deps extends Array<any>>(
        token: DiToken<T>,
        dependencies: CtorsOf<Deps> | Readonly<CtorsOf<Deps>>,
        factory: DependencyFactory<T, Deps>
    ) {
        this.guardDependency(token);
        this.factories.set(token, {
            factory,
            dependencies: dependencies as Constructor<any>[],
        });
    }

    /**
     * Retrieve the instance associated with the provided DI token.
     */
    public get<T>(token: DiToken<T>): T {
        const hasFactory = this.factories.has(token);
        const knownToken = this.registry.has(token);
        const tokenName = getTokenName(token);

        if (!(knownToken || hasFactory)) {
            throw new Error(`Unknown dependency. Cannot retrieve: ${tokenName}.`);
        }

        const def = this.registry.get(token) as DependencyDefinition<T> | undefined;

        if (def && !def.instantiable) {
            throw new Error(`Could not retrieve ${tokenName}. It is not instantiable.`);
        }

        return this.instantiate<T>(token);
    }

    private setToken<T>(token: DiToken<T>, instance: T) {
        this.guardDependency(token);
        this.registry.set(token, {
            token,
            instance,
            instantiable: true,
        });
    }

    /**
     * Register a constructor token with a subclass implementation.
     *
     * @param token The constructor used to retrieve the dependency.
     * @param subclassCtor The constructor to instantiate fulfill the dependency.
     */
    private setSubclassToken<T>(
        token: Constructor<T> | AbstractConstructor<T>,
        subclassCtor: Constructor<T>
    ) {
        this.guardDependency(token);
        this.registry.set(token, {
            token: subclassCtor,
            instantiable: true,
        });
        this.registry.set(subclassCtor, {
            token: subclassCtor,
            instantiable: false,
        });
    }

    /**
     * Instantiate a class decorated with `@Injectable`.
     */
    private instantiate<T>(token: DiToken<T>) {
        const tokenName = getTokenName(token);
        const def = this.registry.get(token) as DependencyDefinition<T>;

        if (def?.instance) {
            return def.instance;
        }

        // the token registered in the definition is the source of truth,
        // not the one used to look it up (although they are often the same)
        if (def && def.token !== token) {
            const aliasToken = def.token;
            const aliasDef = this.registry.get(aliasToken);

            if (aliasDef?.instance) {
                return aliasDef.instance as T;
            }

            token = aliasToken;
        }

        if (typeof token === 'function') {
            if (isPrimitiveCtor(token)) {
                throw new Error(`Cannot instantiate ${tokenName}. It is a primitive type.`);
            }

            const hasFactory = this.factories.has(token);
            if (!hasFactory && !isInjectable(token)) {
                throw new Error(`Cannot instantiate ${tokenName}. It is not decorated.`);
            }
        }

        const graph = new DependencyGraph(token);
        if (graph.cycle) {
            throw new Error(
                `Cannot instantiate ${tokenName}. It has a cyclic dependency: ${graph.cycle.join(
                    ' => '
                )}`
            );
        }

        const depStack = graph.createDependencyStack();
        this.processDependencyStack(depStack);

        return this.registry.get(token)?.instance as T;
    }

    /**
     * Starts at the bottom of a dependency graph (no dependencies) and
     * works up by instantiating and placing each into registry
     *
     * @param depStack List of dependencies in order of fulfillment (least -> dependencies, root).
     */
    private processDependencyStack(depStack: DependencyNode[]) {
        /**
         * The token which we are processing dependencies for.
         * Always the last element of the dependency stack.
         */
        const rootDependency = depStack[depStack.length - 1];
        const tokenName = rootDependency.name;

        depStack.forEach(node => {
            const { token } = node;
            const depName = getTokenName(token);
            const hasFactory = this.factories.has(token);

            if (!this.registry.has(token) && !hasFactory) {
                throw new Error(
                    `Cannot instantiate ${tokenName}. Its dependency ${depName} is not decorated or it was not manually provided.`
                );
            }

            const def = this.registry.get(token) || {
                token,
                instantiable: true,
            };

            const hasInstance = exists(def.instance);
            if (hasInstance) {
                return;
            }

            const instance = hasFactory
                ? this.instantiateFromFactory(token)
                : this.instantiateFromNode(node);

            this.registry.set(token, {
                ...def,
                instance,
            });
        });
    }

    private instantiateFromNode<T>(node: DependencyNode) {
        const aliasToken = this.registry.get(node.token)?.token;
        if (aliasToken && aliasToken !== node.token) {
            return this.instantiate(aliasToken) as T;
        }

        const { children } = node;
        const dependencies = children.map(child => {
            // child dependencies should have previously been instantiated
            // based on order of a previously-processed dependency stack
            const instance = this.registry.get(child.token)?.instance;

            if (!instance) {
                throw new Error(
                    `Cannot instantiate ${node.name}. Dependency ${child.name} is not registered.`
                );
            }

            return instance;
        });

        return Reflect.construct(node.token as Constructor, dependencies) as T;
    }

    private instantiateFromFactory<T>(token: DiToken<T>) {
        const factoryDef = this.factories.get(token);
        if (!factoryDef) {
            const tokenName = getTokenName(token);
            throw new Error(`Could not instantiate ${tokenName} from factory.`);
        }

        const { dependencies, factory } = factoryDef;
        const depInstances = dependencies.map(d => this.instantiate(d));
        return factory(...depInstances) as T;
    }

    private guardDependency<T>(token: DiToken<T>) {
        if (this.registry.has(token) || this.factories.has(token)) {
            const tokenName = getTokenName(token);
            console.warn(`Attempted to register ${tokenName} more than once.`);
        }
    }
}
