import { NavigationService } from '@treasury/core/navigation';
import {
    TransferAccount,
    TransferAccountDto,
    TransferService,
    TransferTemplateDetails,
    TransferTransaction,
    TransferTransactionDetails,
} from '@treasury/domain/transfers';
import { TmBaseComponent } from '@treasury/presentation';
import { FrequencyEvent } from '@treasury/presentation/components/forms/tm-form-frequency';
import '@treasury/presentation/components/forms/tm-form-row';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-currency-field';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-frequency';
import {
    Frequency,
    OneTimeTodayFrequency,
} from '@treasury/presentation/components/tm-frequency.types';
import '@treasury/presentation/components/tm-text-field';
import { LabeledList } from '@treasury/presentation/view-models';
import { exists } from '@treasury/utils';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { format } from 'date-fns';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import '../containers/transfer-template-container';
import { AccountViewModel } from '../data/account-view-model';
import { TransferAccountVm } from '../data/transfer-account-view-model';

export const tagName = 'create-transfer-payment-step';
@customElement(tagName)
export class CreateTransferPaymentStep extends TmBaseComponent {
    @InjectProperty()
    private declare transferService: TransferService;

    @InjectProperty()
    private declare navService: NavigationService;

    @property({ type: Object })
    public transfer?: TransferTransaction;

    @property({ type: Object })
    public template?: TransferTemplateDetails;

    @state()
    private loading = true;

    @state()
    private fromAccount?: TransferAccountVm;

    @state()
    private toAccount?: TransferAccountVm;

    @state()
    private fromAccountOptions: AccountViewModel[] = [];

    @state()
    private toAccountOptions: AccountViewModel[] = [];

    @property()
    accountsMap = new Map<string, TransferAccountVm>();

    @state()
    private fromAccounts = [] as TransferAccountVm[];

    @state()
    private toAccounts = [] as TransferAccountVm[];

    @state()
    private transactions = [] as TransferTransactionDetails[];

    @state()
    private frequency?: Frequency = OneTimeTodayFrequency;

    @state()
    private amount: number | undefined;

    @state()
    private toAccountsOpen = false;

    @state()
    private memo?: string | null = '';

    @state()
    private isFormValid = false;

    @property()
    isTemplate = false;

    @state()
    private templateMemosOpen = false;

    @state()
    private toAccountsDisabled = true;

    @state()
    private transferTotal: number | undefined;

    @state()
    private transferType?: string;

    @state()
    private formDataValid = {
        fromAccount: false,
        toAccount: false,
        amount: false,
        frequency: false,
    };

    @state()
    private formData?:
        | {
              fromAccount: TransferAccountVm;
              toAccount: TransferAccountVm;
              frequency: Frequency;
              amount: string;
              memo: string;
              isTemplate: boolean;
              transactions: [];
              transferType: string;
              transferTotal: string;
              templateName?: string;
          }
        | undefined;

    private onReviewClick = () => {
        const {
            fromAccount,
            toAccount,
            frequency,
            amount,
            memo,
            isTemplate,
            transactions,
            transferType,
            transferTotal,
        } = this;
        const formData = {
            fromAccount,
            toAccount,
            frequency,
            amount,
            memo,
            isTemplate,
            transactions,
            transferType,
            transferTotal,
            templateName: this.transfer?.templateName,
        };
        this.dispatchEvent(
            new CustomEvent('submit', { detail: { transfer: this.transfer, formData } })
        );
    };

    private memoMaxLength = 30;

    async firstUpdated() {
        this.transferType = 'one';
        this.loading = true;

        await this.buildAccountsMap();
        if (this.formData) {
            this.transferType = this.formData.transferType;
            this.fromAccount = this.formData.fromAccount;
            this.toAccount = this.formData.toAccount;
            this.frequency = this.formData.frequency;
            this.toAccountsDisabled = false;
            this.amount = this.formData.amount ? Number(this.formData.amount) : undefined;
            this.memo = this.formData.memo ? this.formData.memo : undefined;
        }

        this.loading = false;
    }

    private async buildAccountsMap() {
        if (this.accountsMap.size > 0) {
            this.accountsMap.forEach(account => {
                this.fromAccountOptions.push(new AccountViewModel(account));
            });
            return;
        }
        if (this.isTemplate) {
            this.fromAccounts = [];
        }
        try {
            const fromAccounts = await this.transferService.getTransferFromAccounts();
            this.fromAccounts = fromAccounts.map(
                (transferAccount: TransferAccount) => new TransferAccountVm(transferAccount.toDto())
            );
            this.fromAccounts.forEach(account => {
                this.accountsMap.set(account.entityId, account);
                this.fromAccountOptions.push(new AccountViewModel(account));
            });
            this.dispatchEvent(new CustomEvent('setAccounts', { detail: this.accountsMap }));
        } catch (e) {
            console.log(e);
        }
    }

    updated(changedProperties: Map<string, unknown>) {
        if (changedProperties.has('transfer') && this.isTemplate) {
            this.transferType = this.transfer?.type;
            switch (this.transfer?.type) {
                case 'one':
                    this.fromAccount = new TransferAccountVm(
                        this.transfer?.transactions[0].fromAccount as unknown as TransferAccountDto
                    );
                    this.toAccount = new TransferAccountVm(
                        this.transfer?.transactions[0].toAccount as unknown as TransferAccountDto
                    );
                    this.amount = this.transfer?.transactions[0].amount;
                    this.transferTotal = this.amount;
                    this.memo = this.transfer?.transactions[0].memo;
                    this.toAccountsDisabled = !this.toAccount;
                    break;
                case 'oneToMany':
                    this.fromAccount = new TransferAccountVm(
                        this.transfer?.transactions[0].fromAccount as unknown as TransferAccountDto
                    );
                    this.frequency = undefined;
                    this.transactions = this.transfer?.transactions
                        .slice(1)
                        .map(transaction => transaction as unknown as TransferTransactionDetails);
                    this.calculateTransferTotal();
                    break;
                case 'manyToOne':
                    this.toAccount = new TransferAccountVm(
                        this.transfer?.transactions[0].toAccount as unknown as TransferAccountDto
                    );
                    this.frequency = undefined;
                    this.transactions = this.transfer?.transactions
                        .slice(1)
                        .map(transaction => transaction as unknown as TransferTransactionDetails);
                    this.calculateTransferTotal();
                    break;
                default:
                    return;
            }
        }

        this.formDataValid.fromAccount = !!this.fromAccount;
        this.formDataValid.toAccount = !!this.toAccount;
        this.formDataValid.amount = !!this.amount;
        if (this.transferType !== 'one') {
            this.formDataValid.amount = this.transactions.every(item => !!item.amount);
            if (this.transferType === 'oneToMany')
                this.formDataValid.toAccount = this.transactions.every(item => !!item.toAccount);
            if (this.transferType === 'manyToOne')
                this.formDataValid.fromAccount = this.transactions.every(
                    item => !!item.fromAccount
                );
        }
        this.formDataValid.frequency = !!this.frequency;
        this.checkFormValidity();
    }

    async fetchToAccounts() {
        if (exists(this.fromAccount)) {
            this.loading = true;
            const toAccounts = await this.transferService.getTransferToAccounts(
                this.fromAccount?.transferAccountId
            );
            this.toAccounts = toAccounts.map(
                (transferAccount: TransferAccount) => new TransferAccountVm(transferAccount.toDto())
            );
            this.toAccounts.forEach(account => {
                this.accountsMap.set(account.entityId, account);
                this.toAccountOptions.push(new AccountViewModel(account));
            });
            this.loading = false;
        }
    }

    private async onAccountChange(accountType: string, id: string) {
        const account = this.accountsMap.get(id);
        if (!account || this.isTemplate) {
            return;
        }
        if (accountType === 'from') {
            this.fromAccount = account;
            if (
                this.toAccount &&
                this.toAccount.accountUniqueId === this.fromAccount.accountUniqueId
            ) {
                this.toAccount = undefined;
            }
            this.toAccountOptions = [];
            this.loading = true;
            await this.fetchToAccounts();
            this.loading = false;
            this.toAccountsDisabled = false;
        }

        if (accountType === 'to') {
            this.toAccount = account;
            this.toAccountsOpen = false;
        }

        this.checkFormValidity();
    }

    calculateTransferTotal() {
        let total = 0;
        this.transfer?.transactions.forEach(num => {
            if (num.amount && num.amount > 0) {
                total += num.amount;
            }
        });
        this.transferTotal = total;
    }

    checkFormValidity() {
        this.isFormValid =
            this.formDataValid.fromAccount &&
            this.formDataValid.toAccount &&
            this.formDataValid.amount &&
            (this.transferType !== 'one' || this.formDataValid.frequency);
    }

    handleSelectAccount(e: Event, type: string, company: AccountViewModel) {
        e.target?.dispatchEvent(
            new CustomEvent('select-account', {
                detail: { type, company: company.value },
                bubbles: true,
            })
        );
        e.target?.dispatchEvent(new CustomEvent('close', { bubbles: true }));
    }

    renderAccountList(type: string) {
        const accountList = type === 'from' ? this.fromAccountOptions : this.toAccountOptions;
        return accountList.map(
            company =>
                html`<div
                    class="company flex justify-between border-b border-[--border-color] ml-4 py-3 pr-4"
                    @click=${(e: Event) => this.handleSelectAccount(e, type, company)}
                    @keyup=${(e: KeyboardEvent) =>
                        e.key === 'Enter' && this.handleSelectAccount(e, type, company)}
                >
                    <div>
                        <div class="font-medium text-sm">${company.name}</div>
                        <div class="text-xs">${company.label}</div>
                    </div>
                    <div class="text-right">
                        <div class="font-medium text-sm">
                            ${company.balance.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })}
                        </div>
                        <div class="text-xs">Available</div>
                    </div>
                </div> `
        );
    }

    renderManualAccounts() {
        if (this.isTemplate) return nothing;
        const fromAccount = this.fromAccount
            ? html`<div class="account-name text-[--primary-action-color]">
                      ${this.fromAccount.name}
                      <span class="account-label text-[--secondary-text-color]"
                          >${this.fromAccount.accountDisplayLabel}</span
                      >
                  </div>
                  <div class="account-balance text-[--secondary-text-color]">
                      ${this.fromAccount.availableBalance.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                      })}
                  </div>`
            : nothing;
        const toAccount = this.toAccount
            ? html`<div class="account-name text-[--primary-action-color]">
                      ${this.toAccount.name}
                      <span class="account-label text-[--secondary-text-color]"
                          >${this.toAccount.accountDisplayLabel}</span
                      >
                  </div>
                  <div class="account-balance text-[--secondary-text-color]">
                      ${this.toAccount.availableBalance.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                      })}
                  </div>`
            : nothing;

        return html`
            <tm-form-row label="Transfer From" required noWrap>
                <tm-slider
                    header="Select From Account"
                    .value=${fromAccount}
                    @select-account=${({ detail }: CustomEvent) =>
                        this.onAccountChange(detail.type, detail.company)}
                    ?disabled=${this.isTemplate || this.loading}
                >
                    <tm-section class="p-4"> ${this.renderAccountList('from')} </tm-section>
                </tm-slider>
            </tm-form-row>
            <tm-form-row label="Transfer To" required noWrap>
                <tm-slider
                    header="Select To Account"
                    .value=${toAccount}
                    @select-account=${({ detail }: CustomEvent) =>
                        this.onAccountChange(detail.type, detail.company)}
                    .disabled=${this.toAccountsDisabled || this.isTemplate || this.loading}
                >
                    <tm-section class="p-4"> ${this.renderAccountList('to')} </tm-section>
                </tm-slider>
            </tm-form-row>
        `;
    }

    renderManualAmount() {
        if (this.isTemplate || this.loading) return nothing;
        return html` <tm-form-row label="Amount" required>
            <tm-currency-field
                .value=${this.amount && this.amount > 0 ? this.amount.toString() : '0'}
                @value-changed=${({ detail }: CustomEvent) => {
                    this.amount = detail;
                    this.checkFormValidity();
                }}
                class="max-w-[130px]"
            ></tm-currency-field>
        </tm-form-row>`;
    }

    renderTemplateData() {
        if (!this.isTemplate) return nothing;

        const account = this.transfer?.type === 'manyToOne' ? this.toAccount : this.fromAccount;
        const direction = this.transfer?.type === 'manyToOne' ? 'To' : 'From';
        if (!account) return nothing;

        const templateSummary = new LabeledList(
            account as TransferAccountVm,
            ['accountDisplayLabel', 'formattedAvailableBalance'],
            {
                accountDisplayLabel: `${direction} Account`,
                formattedAvailableBalance: 'Available Balance',
            }
        );
        return html`<tm-form-row label="Template">
            <tm-slider header="Template Detail" .value=${this.transfer?.templateName}>
                <transfer-template-container
                    .template=${this.template}
                ></transfer-template-container
            ></tm-slider>
            <tm-labeled-list slot="summary" class="py-4" .list=${templateSummary}></tm-labeled-list>
        </tm-form-row>`;
    }

    renderManualMemo() {
        if (this.isTemplate) return nothing;
        return html`
            <tm-form-row label="Memo">
                <tm-text-field
                    .maxLength=${this.memoMaxLength}
                    .value=${this.memo}
                    @value-changed=${(e: CustomEvent) => {
                        if (e.detail.value) this.memo = e.detail.value;
                    }}
                    .helperText="${this.memo && !!this.memo.length
                        ? `${this.memo.length}/${this.memoMaxLength}`
                        : ''}"
                    class="helper-align-right"
                ></tm-text-field>
            </tm-form-row>
        `;
    }

    renderMultipleTemplateMemos(text: string, i: number) {
        if (!this.templateMemosOpen) return nothing;
        return html`
            <tm-text-field
                placeholder="Memo"
                .maxLength=${this.memoMaxLength}
                .value=${text || ''}
                @value-changed=${(e: CustomEvent) => {
                    this.transactions[i].memo = e.detail.value;
                    this.requestUpdate('transactions');
                }}
                .helperText="${this.transactions[i].memo && !!this.transactions[i].memo!.length
                    ? `${this.transactions[i].memo!.length}/${this.memoMaxLength}`
                    : ''}"
                class="form-row-slotted helper-align-right"
            ></tm-text-field>
        `;
    }

    renderSingleTemplateAccount() {
        return html`<div class="border-b border-[--border-color]">
            <div class="flex justify-between items-center">
                <div class="account flex flex-col">
                    <div class="account-name text-sm font-medium">
                        ${this.toAccount?.accountDisplayLabel}
                    </div>
                    <div class="account-balance text-xs text-[--secondary-text-color]">
                        Available
                        ${this.toAccount?.availableBalance.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </div>
                </div>
                <div class="select-item flex flex-col">
                    <tm-currency-field
                        class="form-row-slotted max-w-[130px]"
                        .value=${this.transfer?.transactions[0].amount
                            ? this.transfer.transactions[0].amount.toString()
                            : ''}
                        @value-changed=${({ detail }: CustomEvent) => {
                            this.amount = detail;
                            if (this.transfer) {
                                this.transfer.transactions[0].amount = detail;
                            }
                            this.checkFormValidity();
                            this.calculateTransferTotal();
                        }}
                    ></tm-currency-field>
                </div>
            </div>
            <tm-text-field
                class="form-row-slotted helper-align-right"
                placeholder="Memo"
                maxLength=${this.memoMaxLength}
                .value=${this.memo || ''}
                @value-changed=${(e: CustomEvent) => {
                    if (e.detail.value) this.memo = e.detail.value;
                }}
                .helperText="${this.memo && !!this.memo.length
                    ? `${this.memo.length}/${this.memoMaxLength}`
                    : ''}"
            ></tm-text-field>
        </div> `;
    }

    renderMultipleTemplateAccounts() {
        return html`
            ${this.transactions.map((item, i: number) => {
                const account =
                    this.transfer?.type === 'manyToOne' ? item.fromAccount : item.toAccount;
                return html`<div class="border-b border-[--border-color]">
                    <div class="flex justify-between items-center">
                        <div class="account flex flex-col">
                            <div class="account-name text-sm font-medium">
                                ${account?.accountDisplayLabel}
                            </div>
                            <div class="account-balance text-xs text-[--secondary-text-color]">
                                Available
                                ${account?.availableBalance?.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}
                            </div>
                        </div>
                        <div class="select-item flex flex-col">
                            <tm-currency-field
                                class="form-row-slotted max-w-[130px]"
                                .value=${item.amount ? item.amount.toString() : ''}
                                @value-changed=${({ detail }: CustomEvent) => {
                                    item.amount = detail;
                                    this.transactions[i].amount = detail;
                                    this.checkFormValidity();
                                    this.calculateTransferTotal();
                                }}
                            ></tm-currency-field>
                        </div>
                    </div>
                    ${this.renderMultipleTemplateMemos(item.memo ? item.memo : '', i)}
                </div>`;
            })}
        `;
    }

    renderTemplateAccounts() {
        if (!this.isTemplate) return nothing;
        const direction = this.transfer?.type === 'manyToOne' ? 'from' : 'to';
        const memosLabel = this.templateMemosOpen ? 'Hide memos' : '+ Add memos';

        return html`<div
                class="flex justify-between items-center border-b border-[--border-color] ml-4 pt-4 pb-2 pr-4"
            >
                <div class="pt-7 pr-4">Transfer ${direction}</div>
                <tm-button
                    size="small"
                    importance="tertiary"
                    class=${classMap({
                        'template-memo-toggle pt-7 text-sm': true,
                        hidden: this.transfer?.type === 'one',
                    })}
                    @click=${() => {
                        this.templateMemosOpen = !this.templateMemosOpen;
                    }}
                >
                    ${memosLabel}
                </tm-button>
            </div>
            <div class="border-b border-[--border-color] ml-4 pb-4 pr-4">
                ${this.transfer?.type === 'one'
                    ? this.renderSingleTemplateAccount()
                    : this.renderMultipleTemplateAccounts()}
                <div class="text-right text-xl py-4">
                    <span class="text-sm">TOTAL</span>
                    ${this.transferTotal?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                </div>
            </div> `;
    }

    renderFrequency() {
        if (this.loading || this.transferType !== 'one') return nothing;
        return html`<tm-form-frequency
            dateLabel="Transfer Date"
            .frequency=${this.frequency}
            @select-frequency=${(e: FrequencyEvent) => {
                this.frequency = e.detail;
                this.formDataValid.frequency = !!this.frequency;
                this.checkFormValidity();
            }}
        ></tm-form-frequency>`;
    }

    renderTransferDate() {
        if (this.transferType === 'one') return nothing;
        return html` <tm-form-row label="Transfer Date" required noWrap
            ><tm-date-picker
                class="max-w-[140px]"
                .value=${format(new Date(), 'yyyy-MM-dd')}
                @value-changed=${(e: CustomEvent) => {
                    this.transactions.map(
                        transaction => (transaction.transferDate = e.detail.value)
                    );
                }}
            ></tm-date-picker
        ></tm-form-row>`;
    }

    renderBlockingLoader() {
        if (!this.loading) return nothing;
        return html`<tm-blocking-loader></tm-blocking-loader>`;
    }

    render() {
        return html`
            <tm-body .padding=${false}
                ><form class="payment-data">
                    ${this.renderBlockingLoader()} ${this.renderManualAccounts()}
                    ${this.renderManualAmount()} ${this.renderTemplateData()}
                    ${this.renderFrequency()} ${this.renderTransferDate()}
                    ${this.renderManualMemo()} ${this.renderTemplateAccounts()}
                </form></tm-body
            >
            <tm-footer
                visible
                .buttonConfig=${[
                    {
                        text: 'Review',
                        disabled: !this.isFormValid,
                        onClick: this.onReviewClick,
                    } as ButtonConfig,
                ]}
            >
            </tm-footer>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
                .select-item {
                    color: var(--primary-action-color);
                }
                .action-icon svg {
                    width: 100%;
                    height: auto;
                }
                .button-disabled {
                    opacity: 0.25;
                }
                .hidden {
                    display: none;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: CreateTransferPaymentStep;
    }
}
