import { AuthenticationService } from '@treasury/domain/services/authentication';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Commands, Context, RedirectResult } from '@vaadin/router';
import { PageEnabled } from './pageEnabled';

export class AuthGuard implements PageEnabled {
    public async pageEnabled(
        context: Context,
        commands: Commands,
        pathRedirect?: string
    ): Promise<RedirectResult | undefined> {
        const token = sessionStorage.getItem('Jha-Treasury-JhaAuthToken');
        let isAuthenticated = false;
        if ((context as any).path.includes('terms-and-conditions')) {
            isAuthenticated = !!token;
        } else {
            const termsAndConditions = sessionStorage.getItem('termsAndConditions');
            isAuthenticated = !!token && !!termsAndConditions;
        }

        const authService = await AuthenticationService.getInstance();
        if (!isAuthenticated) {
            authService.invalidate();
            // eslint-disable-next-line no-console
            console.warn('User not authorized', context.pathname);
            return commands.redirect(pathRedirect || '/');
        }

        if (!authService.authenticated) {
            authService.authenticate();
        }

        return undefined;
    }
}
