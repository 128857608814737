/* eslint-disable no-use-before-define */
import { AppType, ConfigurationService } from '@treasury/core/config';
import {
    Action,
    InjectByToken,
    Injectable,
    InjectionToken,
    ObservationSource,
    WindowService,
    combineMulti,
    exists,
    fromEvent,
    throttle,
} from '@treasury/utils';
import { AuditCode, ResourceType, UserActivityService } from '../../channel/services/user-activity';
import { LogoutReason } from './logout.types';

const inputEvents = [
    'mousemove' as const,
    'keydown' as const,
    'mousedown' as const,
    'touchstart' as const,
];

type InputEvent = (typeof inputEvents)[number];

export interface IdleDialogResult {
    getDetail: () => Promise<'continue' | 'logout'>;
    close: () => void;
}

interface IdleDialogConfig {
    logoSource: string;
}

export interface OpenIdleDialogFn {
    (config: IdleDialogConfig): Promise<IdleDialogResult>;
}

export const OpenIdleDialogToken = new InjectionToken<OpenIdleDialogFn>('OpenIdleDialog');

/**
 * The minimum amount of time (in milliseconds) to show the idle dialog before automatically logging out.
 */
const MIN_DISPLAY_TIME = 60000;
/**
 * The minimum amount of time (milliseconds) to wait for idle users.
 */
const MIN_IDLE_TIME = MIN_DISPLAY_TIME + 30000;
/**
 * The default amount of time (in milliseconds) to wait for inactivity.
 */
const DEFAULT_IDLE_TIME = 240000;

@Injectable()
export class IdleSignoutService {
    constructor(
        private readonly config: ConfigurationService,
        private readonly window: WindowService,
        @InjectByToken(OpenIdleDialogToken) private readonly openIdleDialog: OpenIdleDialogFn
    ) {}

    private expiration = new Date();

    private initialized = false;

    private paused = false;

    /**
     * The amount of time (in milliseconds) to wait until the idle dialog is shown.
     */
    private idleTime = DEFAULT_IDLE_TIME;

    /**
     * The amount of time (in milliseconds) to show the idle dialog for.
     */
    private displayTime = MIN_DISPLAY_TIME;

    /**
     * The amount of time (in milliseconds) that a user may be idle.
     */
    private idleDuration = this.idleTime + this.displayTime;

    private closeDialogFn?: IdleDialogResult['close'];

    private windowVisible$ = fromEvent(this.window, 'visibilitychange').pipe(
        () => this.window.document.visibilityState === 'visible'
    );

    private inputEvent$ = this.createInputObservable(inputEvents);

    /**
     * Handle to the most recent set timer.
     */
    private timerHandle?: ReturnType<WindowService['createTimer']>;

    /**
     * Event listener subscription handles.
     */
    private subs: { unsubscribe: Action }[] = [];

    private sessionEndSource = new ObservationSource<LogoutReason>();

    public sessionEnd$ = this.sessionEndSource.toObservable();

    private get dialogOpen() {
        return exists(this.closeDialogFn);
    }

    private get logoSource() {
        return this.config.institutionId
            ? `/assets/branding/${this.config.institutionId}/logo.webp`
            : '';
    }

    /**
     * The amount of time (in milliseconds) remaining until the user is logged out from idling.
     */
    public get timeRemaining() {
        return Math.max(this.expiration.getTime() - Date.now(), 0);
    }

    private init(duration: number, displayTime = MIN_DISPLAY_TIME) {
        if (this.initialized) {
            return;
        }

        this.initialized = true;
        this.displayTime = displayTime;
        this.idleDuration = duration;

        const visibilityHandle = this.windowVisible$.subscribe(isVisible =>
            this.onVisibilityChanged(isVisible)
        );
        const inputHandle = this.inputEvent$.subscribe(() => {
            this.startIdleTimer();
        });
        this.subs.push(visibilityHandle, inputHandle);
    }

    /**
     * Start a fresh idle timer from the beginning of duration.
     */
    private startIdleTimer() {
        if (!this.initialized) {
            return;
        }

        this.expiration = new Date(Date.now() + this.idleDuration);
        this.idleTime = getIdleDuration(this.idleDuration, this.displayTime);
        this.startTimer(this.idleTime, () => this.onTimeElapsed());
    }

    private finalize() {
        if (!this.initialized) {
            return;
        }

        this.unregisterListeners();
        this.initialized = false;
    }

    public start(duration: number, displayTime?: number) {
        this.init(duration, displayTime);
        this.startIdleTimer();
    }

    public stop() {
        this.stopTimer();
        this.dismissIdleWarningModal();
        this.finalize();
    }

    /**
     * Resume monitoring the idle expiration window.
     *
     * Continues any previously paused idle timeout tracking,
     * taking into how account how much time remains from the
     * the time `start()` was first called.
     */
    public resume() {
        if (!this.initialized || exists(this.timerHandle)) {
            return;
        }

        this.paused = false;
        const { timeRemaining, displayTime } = this;

        if (timeRemaining <= 0) {
            this.endSession('Forced');
        } else if (timeRemaining > 0 && timeRemaining <= displayTime) {
            this.onTimeElapsed();
        } else {
            this.idleTime = getIdleDuration(timeRemaining, displayTime);
            this.startTimer(this.idleTime, () => this.onTimeElapsed());
        }
    }

    /**
     * Pause actively monitoring the current user session for idleness.
     * May be restarted by calling `resume()`.
     */
    public pause() {
        this.paused = true;
        this.stopTimer();
    }

    private onTimeElapsed() {
        this.stopTimer();
        this.openIdleWarningModal();

        // automatically log the user out after the dialog has been displayed
        // for a minimum amount of time and no choice is made
        this.startTimer(this.displayTime, () => this.endSession('Forced'));
    }

    private onVisibilityChanged(windowVisible: boolean) {
        if (windowVisible) {
            this.resume();
        } else {
            this.pause();
        }
    }

    private async openIdleWarningModal() {
        if (this.dialogOpen || this.paused) {
            return;
        }

        try {
            const result = await this.openIdleDialog({
                logoSource: this.logoSource,
            });
            this.closeDialogFn = () => result.close();

            const detail = await result.getDetail();

            if (detail === 'continue') {
                this.startIdleTimer();
            } else if (detail === 'logout') {
                if (this.config.app === AppType.Channel) {
                    UserActivityService.userInteractionAudit(
                        ResourceType.SessionResources,
                        AuditCode.SessionExpiredUserInitiated
                    );
                }

                this.endSession('UserInitiated');
            }
        } finally {
            this.dismissIdleWarningModal();
        }
    }

    private endSession(reason: LogoutReason) {
        this.initialized = false;
        this.stop();
        this.sessionEndSource.emit(reason);
    }

    private stopTimer() {
        if (this.timerHandle) {
            this.timerHandle.stop();
        }
        this.timerHandle = undefined;
    }

    private startTimer(duration: number, callback: () => void) {
        this.stopTimer();
        this.timerHandle = this.window.createTimer(() => callback(), duration);
    }

    private dismissIdleWarningModal() {
        if (!this.closeDialogFn) {
            return;
        }

        this.closeDialogFn();
        this.closeDialogFn = undefined;
    }

    private createInputObservable(events: InputEvent[]) {
        const observables = events.map(event => fromEvent(this.window, event));
        const combined = combineMulti(...observables);
        const filtered = combined.filter(() => !this.dialogOpen);
        return throttle(filtered, 6000);
    }

    private unregisterListeners() {
        this.subs.forEach(handle => {
            handle.unsubscribe();
        });
        this.subs = [];
    }
}

/**
 * Calculate the amount of time (in milliseconds) to wait before showing the idle logout dialog.
 *
 * This will be the remaining idle time (usually the amount specified by the server)
 * minus the `displayTime` value to give the user time to read and respond
 * to the idle log out message before being sent back to `/login`.
 */
function getIdleDuration(duration: number, displayTime: number) {
    return Math.max(duration - displayTime, MIN_IDLE_TIME);
}
