/* eslint-disable max-classes-per-file */
import {
    InternalTransferTransactionModelDto,
    InternalTransfersClient,
} from '@treasury/api/channel';
import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { addWeeks, format } from 'date-fns';
import { TransferTemplateDetails } from '.';
import { Transaction } from './one-to-one-transfer.dto';
import { TransferAccount } from './transfer-account.entity';
import {
    createOneToOneTransfer,
    createTransfer,
    getTransferFromAccounts,
    getTransferPayment,
    getTransferToAccounts,
} from './transfer-payment-requests';

enum AccountOperator {
    To = 'To',
    From = 'From',
    Both = 'Both',
}

enum DateOperator {
    SpecificDate = 'SpecificDate',
    DateRange = 'DateRange',
}

enum AmountOperator {
    SpecificAmount = 'SpecificAmount',
    Range = 'Range',
}

// TODO: add proper types from data-contracts (InternalTransferAddTransactionModelDto, InternalTransferAddTransactionDetailModelDto)
// Adding them now breaks the expected response in the presentation layer

class InternalTransfersFilter {
    /** @format date-time */
    transferDateFrom? = format(new Date(), 'MM/dd/yyyy');

    /** @format date-time */
    transferDateTo? = format(addWeeks(new Date(), 1), 'MM/dd/yyyy');

    accountOperator? = AccountOperator.Both;

    /** @format decimal */
    fromAmount?: number;

    /** @format decimal */
    toAmount?: number;

    transactionId?: string;

    /** @format date-time */
    createdDateFrom?: string;

    /** @format date-time */
    createdDateTo?: string;

    transferDateOperator? = DateOperator.DateRange;

    amountOperator? = AmountOperator.SpecificAmount;

    createdDateOperator? = DateOperator.SpecificDate;

    status? = [];

    fromAccounts? = [];

    toAccounts? = [];

    bothAccounts? = [];

    batchId?: string;

    paymentOptionType? = [];
}

@Injectable()
export class TransferService {
    // eslint-disable-next-line no-useless-constructor
    constructor(
        private internalTransfersClient: InternalTransfersClient,
        private http: TmHttpClient
    ) {}

    public async getTransferActivity(filter = new InternalTransfersFilter()) {
        return (await this.internalTransfersClient.internalTransfersFilterTransfers(filter)).data;
    }

    public async getTransferFromAccounts() {
        const accounts = await getTransferFromAccounts();
        return accounts.map(account => new TransferAccount(account));
    }

    public async getTransferToAccounts(oppositeAccountId: number) {
        const accounts = await getTransferToAccounts(oppositeAccountId);
        return accounts.map(account => new TransferAccount(account));
    }

    public async createOneToOneTransfer(transfer: any) {
        const request = {
            securityMessage: {
                actionType: 'Create One Time Transfer',
                hasAlternate: false,
                message: null,
                methodUsed: null,
                oneTimePassword: null,
                status: null,
                errorCode: null,
            },
            transactions: [
                {
                    ...transfer,
                    toAccount: transfer.toAccount.dto ? transfer.toAccount.dto : transfer.toAccount,
                    fromAccount: transfer.fromAccount.dto
                        ? transfer.fromAccount.dto
                        : transfer.fromAccount,
                    frequency: transfer.frequency,
                    transferDate: transfer.frequency.valueDate ?? format(new Date(), 'MM/dd/yyyy'),
                },
            ],
            templateName: null,
            type: 'one',
        };
        const response = await createOneToOneTransfer(request);
        // NOTE: hack to get the correct account display labels to show since the service POST response sends the wrong displayLabel
        const mappedTransactions = response.transactions.map((transaction: Transaction) => ({
            ...transaction,
            fromAccount: transfer.fromAccount.dto ? transfer.fromAccount.dto : transfer.fromAccount,
            toAccount: transfer.toAccount.dto ? transfer.toAccount.dto : transfer.toAccount,
        }));
        response.transactions = mappedTransactions;
        return response;
    }

    public async createOneToManyTransfer(transfer: any) {
        const request = {
            securityMessage: {
                actionType: 'Create One To Many Transfer',
                hasAlternate: false,
                message: null,
                methodUsed: null,
                oneTimePassword: null,
                status: null,
                errorCode: null,
            },
            ...transfer,
            templateName: null,
            type: 'oneToMany',
        };
        const response = (await createTransfer(request)) as unknown as any;
        // NOTE: hack to get the correct account display labels to show since the service POST response sends the wrong displayLabel
        const mappedTransactions = response.transactions.map(
            (transaction: Transaction, i: number) => ({
                ...transaction,
                fromAccount: transfer.transactions[i].fromAccount,
                toAccount: transfer.transactions[i].toAccount,
            })
        );
        response.transactions = mappedTransactions;
        return response;
    }

    public async createManyToOneTransfer(transfer: any) {
        const request = {
            securityMessage: {
                actionType: 'Create Many to One Transfer',
                hasAlternate: false,
                message: null,
                methodUsed: null,
                oneTimePassword: null,
                status: null,
                errorCode: null,
            },
            ...transfer,
            templateName: null,
            type: 'manyToOne',
        };
        const response = (await createTransfer(request)) as unknown as any;
        // NOTE: hack to get the correct account display labels to show since the service POST response sends the wrong displayLabel
        const mappedTransactions = response.transactions.map(
            (transaction: Transaction, i: number) => ({
                ...transaction,
                fromAccount: transfer.transactions[i].fromAccount,
                toAccount: transfer.transactions[i].toAccount,
            })
        );
        response.transactions = mappedTransactions;
        return response;
    }

    public async createManyToManyTransfer(transfer: any) {
        const request = {
            securityMessage: {
                actionType: 'Create Many to Many Transfer',
                hasAlternate: false,
                message: null,
                methodUsed: null,
                oneTimePassword: null,
                status: null,
                errorCode: null,
            },
            transactions: [
                {
                    ...transfer,
                    toAccount: transfer.toAccount.dto,
                    fromAccount: transfer.fromAccount.dto,
                    frequency: transfer.frequency,
                    transferDate: transfer.frequency.valueDate ?? format(new Date(), 'MM/dd/yyyy'),
                },
            ],
            templateName: null,
            type: 'many',
        };
        const response = (await createTransfer(request)) as unknown as any;
        // NOTE: hack to get the correct account display labels to show since the service POST response sends the wrong displayLabel
        const mappedTransactions = response.transactions.map(
            (transaction: Transaction, i: number) => ({
                ...transaction,
                fromAccount: transfer.transactions[i].fromAccount,
                toAccount: transfer.transactions[i].toAccount,
            })
        );
        response.transactions = mappedTransactions;
        return response;
    }

    public async getTransferPaymentById(id: string) {
        return getTransferPayment(id);
    }

    public async getTransferTemplateById(id: string) {
        const response = await this.internalTransfersClient.internalTransfersGetTransferTemplate(
            Number(id)
        );
        return response.data;
    }

    // NOTE: The transfer template API endpoint urls are seemingly misnamed.  The 'internalTransfersGetTransferTemplate' request return the details DTO and the 'internalTransfersGetTransferTemplateDetails' returns the base template
    public async getTransferTemplateDetails(id: string) {
        const response = await this.internalTransfersClient.internalTransfersGetTransferTemplate(
            Number(id)
        );
        return new TransferTemplateDetails(response.data);
    }

    public async getTransferTemplate(id: string) {
        const response =
            await this.internalTransfersClient.internalTransfersGetTransferTemplateDetails(
                Number(id),
                {
                    type: null,
                }
            );
        return response.data;
    }

    public async getTransferTemplates(body: any) {
        return this.http.request(`internalTransfers/getTransferTemplates`, {
            method: 'POST',
            body,
        });
    }
}
