import { BillPayAccountModel, PayeeListModel } from '@treasury/domain/bill-pay';
import { TmBaseComponent } from '@treasury/presentation';
import { FrequencyEvent } from '@treasury/presentation/components/forms/tm-form-frequency';
import '@treasury/presentation/components/forms/tm-form-row';
import '@treasury/presentation/components/forms/tm-slider';
import '@treasury/presentation/components/tm-footer';
import { Frequency } from '@treasury/presentation/components/tm-frequency.types';
import { DeepReactive } from '@treasury/utils/lit-helpers';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../components/bill-pay-form-frequency';
import '../containers/business-bill-pay-payees-container';
import { BillPayAccountTableViewModel } from '../data/bill-pay-account-table-view-model';
import './business-bill-pay-accounts-table';

export const tagName = 'business-bill-pay-create-step';
@customElement(tagName)
export class BusinessBillPayCreateStep extends TmBaseComponent {
    @DeepReactive()
    private payment = {
        payee: {} as PayeeListModel,
        fromAccount: {} as BillPayAccountModel,
        amount: 0,
        frequency: undefined as Frequency | undefined,
        comment: '',
    };

    @property()
    billPayAccounts?: BillPayAccountTableViewModel[];

    get hasFrequency() {
        if (!this.payment.frequency) return false;
        return Object.keys(this.payment.frequency).length > 0;
    }

    get isValid() {
        return (
            Object.keys(this.payment.payee).length &&
            Object.keys(this.payment.fromAccount).length &&
            this.payment.amount &&
            this.hasFrequency
        );
    }

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        if (changedProperties.has('payment')) {
            this.dispatchEvent(new CustomEvent('payment-changed', { detail: this.payment }));
        }
    }

    render() {
        return html` <div class="form-wrapper pt-2 pb-5">
                <tm-form-row label="Payee">
                    <tm-slider
                        .placeholder=${'Select payee'}
                        header="Select Payee"
                        .value=${this.payment.payee.name}
                        ><business-bill-pay-payees-container
                            is-workflow
                            @payee-selected=${(e: CustomEvent) => {
                                this.payment.payee = e.detail;
                            }}
                        ></business-bill-pay-payees-container></tm-slider
                ></tm-form-row>
                <tm-form-row label="From Account">
                    <tm-slider
                        .placeholder=${'Select account'}
                        header="Select From Account"
                        .value=${this.payment.fromAccount.accountNumber}
                        ><business-bill-pay-accounts-table
                            .accounts=${this.billPayAccounts}
                            @account-selected=${(e: CustomEvent) => {
                                this.payment.fromAccount = e.detail;
                            }}
                        ></business-bill-pay-accounts-table></tm-slider
                ></tm-form-row>
                <div class="p-3">
                    <tm-currency-field
                        label="Amount"
                        .value=${this.payment.amount}
                        @value-changed=${(e: CustomEvent) => {
                            this.payment.amount = e.detail;
                        }}
                    ></tm-currency-field>
                </div>
                <bill-pay-form-frequency
                    label="Frequency"
                    .frequency=${this.payment.frequency}
                    @select-frequency=${(e: FrequencyEvent) => {
                        this.payment.frequency = e.detail;
                    }}
                ></bill-pay-form-frequency>
                <div class="p-3">
                    <tm-text-field
                        label="Memo"
                        .value=${this.payment.comment}
                        @value-changed=${(e: CustomEvent) => {
                            this.payment.comment = e.detail.value;
                        }}
                    ></tm-text-field>
                </div>
            </div>
            <tm-footer
                class="fixed bottom-0 w-full"
                .buttonConfig=${[
                    {
                        text: 'Review',
                        onClick: () => {
                            this.dispatchEvent(
                                new CustomEvent('continue', { detail: this.payment })
                            );
                        },
                        disabled: !this.isValid,
                    },
                ]}
            ></tm-footer>`;
    }

    static get styles() {
        return [
            css`
                .form-wrapper {
                    background-color: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: BusinessBillPayCreateStep;
    }
}
