import { LookupModelDto } from '@treasury/api/channel';
import { nullLookupModelDto } from '@treasury/domain/wires/wire.dto';
import { css, html, nothing, PropertyValueMap } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { TmBaseComponent } from '..';
import { deleteIcon, plusIcon } from '../assets/icons';
import './tm-button';
import './tm-text-field';

export const tagName = 'tm-multi-line-text-input';
@customElement(tagName)
export class TmMultiLineTextInput extends TmBaseComponent {
    @property()
    label!: string;

    @property({ type: Array })
    value: LookupModelDto[] = [{ ...nullLookupModelDto }];

    @property({ type: Boolean })
    required = false;

    @property({ type: Boolean })
    disabled = false;

    @property({ type: Number })
    maxLines?: number;

    @property({ type: Number })
    minLines = 1;

    @property({ type: String })
    tooltip?: string;

    @property({ type: String })
    placeholder?: string;

    @property({ type: Number, reflect: true })
    characterLimit?: number;

    @property({ type: String, reflect: true })
    pattern?: string;

    @property({ type: Boolean })
    formRowSlotted = false;

    protected firstUpdated(): void {
        if (this.maxLines && Number(this.minLines) > Number(this.maxLines)) {
            console.error(
                'Minimum lines must be less than maximum lines for multi-line text input'
            );
        }
        if (this.value.length < Number(this.minLines)) {
            while (this.value.length < Number(this.minLines)) {
                this.value.push({ ...nullLookupModelDto });
            }
            this.requestUpdate();
        }
    }
    protected updated(changedProperties: PropertyValueMap<any>): void {
        if (changedProperties.has('value')) {
            this.dispatchEvent(new CustomEvent('change', { detail: this.value }));
        }
    }

    renderItemAddBtn(index: number) {
        if (
            (this.maxLines && this.value.length === Number(this.maxLines)) ||
            index < this.value.length - 1
        )
            return nothing;
        return html`
            <tm-button
                @click=${() => {
                    this.value.push({ ...nullLookupModelDto });
                    this.requestUpdate('value');
                }}
                isIcon
            >
                ${plusIcon}
            </tm-button>
        `;
    }

    renderItemDelBtn(index: number) {
        if (this.value.length <= this.minLines) return nothing;
        return html`
            <tm-button
                @click=${() => {
                    this.value.splice(index, 1);
                    this.requestUpdate('value');
                }}
                isIcon
            >
                ${deleteIcon}
            </tm-button>
        `;
    }

    renderItemActions(index: number) {
        if (this.formRowSlotted) return nothing;
        return [this.renderItemAddBtn(index), this.renderItemDelBtn(index)];
    }

    renderFormRowActions() {
        if (!this.formRowSlotted) return nothing;
        return html`<div class="form-row-actions flex justify-between">
            ${this.renderFormRowAddBtn()} ${this.renderFormRowDelBtn()}
        </div>`;
    }

    renderFormRowAddBtn() {
        if (this.maxLines && this.value.length === Number(this.maxLines)) return nothing;
        return html`<button
            @click=${() => {
                this.value.push({ ...nullLookupModelDto });
                this.requestUpdate('value');
            }}
        >
            ${plusIcon} Add Line
        </button>`;
    }

    renderFormRowDelBtn() {
        if (this.value.length <= this.minLines) return nothing;
        return html`<button
            @click=${() => {
                this.value.pop();
                this.requestUpdate('value');
            }}
        >
            ${deleteIcon} Remove Line
        </button>`;
    }

    renderItem(item: LookupModelDto, index: number) {
        return html`
            <tm-text-field
                class=${classMap({
                    'mb-0': true,
                    'flex-auto': true,
                    'form-row-slotted': this.formRowSlotted,
                    'helper-align-right': this.formRowSlotted && !!this.characterLimit,
                })}
                .label=${index === 0 ? this.label : ''}
                .placeholder=${!!this.placeholder ? `${this.placeholder} ${index + 1}` : ''}
                .required=${index === 0 ? this.required : false}
                .disabled=${this.disabled}
                .value=${item.value || ''}
                .maxLength=${this.characterLimit}
                .pattern=${this.pattern}
                @value-changed=${(e: CustomEvent) => {
                    const old = this.value;
                    if (this.value[index]) {
                        this.value[index].value = e.detail.value;
                    }
                    this.requestUpdate('value');
                }}
                .tooltip=${index === 0 ? this.tooltip : undefined}
                .helperText=${item.value ? `${item.value.length}/${this.characterLimit}` : ''}
                filled
            ></tm-text-field>
            <div class="action-buttons">${this.renderItemActions(index)}</div>
        `;
    }

    render() {
        return html`
            ${this.value.map(
                (item, i) => html`
                    <div
                        class="tm-multi-line-item flex relative items-baseline w-full m-0"
                        ?disabled=${this.disabled}
                    >
                        ${this.renderItem(item, i)}
                    </div>
                `
            )}
            ${this.renderFormRowActions()}
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    margin-bottom: 16px;
                }

                .tm-multi-line-item[disabled] .action-buttons {
                    display: none;
                }

                .tm-multi-line-item tm-button:first-of-type {
                    margin-left: 4px;
                }

                .form-row-actions {
                    margin-top: 8px;
                }

                .form-row-actions button {
                    display: flex;
                    color: var(--primary-action-color);
                }

                .form-row-actions button svg {
                    height: 16px;
                    margin-top: 2px;
                }

                .form-row-actions button path {
                    fill: var(--primary-action-color);
                }

                .form-row-actions button:first-of-type {
                    margin-right: 16px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmMultiLineTextInput;
    }
}
