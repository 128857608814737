import '@vaadin/password-field';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { TmInputFieldBase } from './tm-input-field.base';

export const tagName = 'tm-password-field';
@customElement(tagName)
export class TmPasswordField extends TmInputFieldBase {
    @property({ type: String })
    allowedCharacters?: string;

    @property({ type: Number })
    minLength?: number;

    @property({ type: Number })
    maxLength?: number;

    @property({ type: Boolean })
    passwordVisible?: boolean;

    render() {
        return html`
            <vaadin-password-field
                @value-changed=${(e: CustomEvent) => {
                    const valueChangedEvent = new CustomEvent('value-changed', {
                        detail: e.detail,
                    });
                    this.dispatchEvent(valueChangedEvent);
                }}
                @focus=${(e: CustomEvent) => {
                    const focusEvent = new CustomEvent('focus', {
                        detail: e.detail,
                    });
                    this.dispatchEvent(focusEvent);
                }}
                part="component"
                class="w-full"
                label=${this.label}
                value=${this.value}
                placeholder=${ifDefined(this.placeholder)}
                allowed-char-pattern=${ifDefined(this.allowedCharacters)}
                .minlength=${this.minLength}
                .maxlength=${this.maxLength}
                ?required=${this.required}
                ?passwordVisible=${this.passwordVisible}
            >
            </vaadin-password-field>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmPasswordField;
    }
}
