import {
    AccountDto,
    AchExceptionDetailDataDto,
    AchExceptionDetailSearchModelDto,
    AchExceptionsDecisionsActivityRequestModelDto,
    ArpClient,
    CompanyAccountsClient,
    DashboardClient,
    ErrorResponseModelDto,
} from '@treasury/api/channel';
import { HttpResponse } from '@treasury/api/channel/generated/http-client';
import { InjectProperty, Injectable } from '@treasury/utils/dependency-injection';
import { AchException, CheckException } from '../../../arp';
import { mapAchExceptionFiltersToRequest } from '../../../arp/mappings/ach-exception-filters-mapping';
import { mapAchExceptionsActivityFiltersToRequest } from '../../../arp/mappings/ach-exceptions-activity-filters-mapping';
import { PayOrReturnChoice } from '../../mappings/ach';
import { PositivePayExceptionRequests } from '../../requests';
import CheckExceptionsServices from './check-exceptions-services';

@Injectable()
export default class PositivePayService {
    constructor(private readonly dashboardClient: DashboardClient) {}

    @InjectProperty()
    private declare arpClient: ArpClient;

    @InjectProperty()
    private declare companyAccountsClient: CompanyAccountsClient;

    async getAchExceptions() {
        const response = (await this.dashboardClient.dashboardGetWorkExceptions({
            type: 'achExceptions',
        })) as HttpResponse<AchExceptionDetailDataDto[], ErrorResponseModelDto>;
        if (response.data == null || response.data === undefined) return [];
        return response.data.map((d: AchExceptionDetailDataDto) => new AchException(d));
    }

    async searchAchExceptions(params: AchExceptionDetailSearchModelDto) {
        const request = mapAchExceptionFiltersToRequest(params);
        const response = await this.arpClient.arpFindAchExceptions(request);
        if (!response.data.achExceptionDetails) return [];
        // Fixes bad typing from the API
        return response.data.achExceptionDetails.map(
            d => new AchException(d as unknown as AchExceptionDetailDataDto)
        );
    }

    async searchAchExceptionsActivity(
        params: AchExceptionsDecisionsActivityRequestModelDto | null
    ) {
        const request = mapAchExceptionsActivityFiltersToRequest(
            params
        ) as unknown as AchExceptionsDecisionsActivityRequestModelDto;
        const response = await this.arpClient.arpFindAchExceptionsDecisionsActivity(request);
        return response.data.items;
    }

    async searchPaginatedAchExceptionsActivity(
        params: AchExceptionsDecisionsActivityRequestModelDto | null
    ) {
        const request = mapAchExceptionsActivityFiltersToRequest(
            params
        ) as unknown as AchExceptionsDecisionsActivityRequestModelDto;
        const response = await this.arpClient.arpFindAchExceptionsDecisionsActivity(request);
        return {
            achExceptionDecisions: response.data.items,
            count: response.data.totalCount,
        };
    }

    async getArpSecCodes() {
        const response = await this.arpClient.arpGetAllSecCodes();
        return response.data;
    }

    async getArpAchExceptionAccounts() {
        const response = await this.arpClient.arpGetArpAccounts('WorkAchException');
        return response.data;
    }

    async getReturnReasons() {
        return CheckExceptionsServices.getCheckExceptionReturnReasons();
    }

    async requestExceptionCorrection(file: any, correctionRequest: any) {
        return CheckExceptionsServices.saveCorrectionRequest(file, correctionRequest);
    }

    async getCutoffTimes() {
        const response = await this.companyAccountsClient.companyAccountsGetCutoffTimes();
        return response.data;
    }

    async getCheckExceptions() {
        const arpExceptionAccounts = await PositivePayExceptionRequests.getArpExceptionAccounts();
        const params = {
            parameters: {
                account: arpExceptionAccounts.map((account: AccountDto) => account.accountUniqueId),
            },
        };
        return CheckExceptionsServices.searchCheckExceptions(params);
    }

    async updateExceptionStatuses(exceptions: AchException[]) {
        const updated = exceptions
            .filter(e => e.decisionStatusId !== PayOrReturnChoice.Unselected)
            .map(e => {
                const dto = e.toAchExceptionDetailModelDto();
                dto.achExceptionDecisionStatusId = e.decisionStatusId;
                return dto;
            });

        const response = await this.arpClient.arpSaveAchExceptions(updated);
        if (!response.data) return false;
        if (response.data.failedDecisionsCount > 0) {
            return false;
        }
        return response.data;
    }

    async submitCheckExceptions(checkExceptions: CheckException[]): Promise<boolean> {
        return CheckExceptionsServices.submitCheckExceptionDecisions(checkExceptions);
    }
}
