/* eslint-disable no-unused-expressions */
import { ConfigurationService } from '@treasury/core/config';
import { NavigationService } from '@treasury/core/navigation';
import { DashboardRequests } from '@treasury/domain/channel/requests/dashboard/dashboard';
import { DashboardProduct } from '@treasury/domain/channel/types/dashboard/dashboard';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { NotificationService, TmBaseComponent } from '@treasury/presentation';
import { achIcon, billsIcon, transferIcon, wiresIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-action-tile';
import { InjectProperty } from '@treasury/utils';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/jhd-timeline';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import { MenuItem } from '../../services/types/jhd-header-bar.type';
import '../components/accounts-widget';
import '../components/approval-widget';
import '../components/cutoff-times-tooltip';
import '../components/deposits-widget';
import '../components/messages-tooltip';
import '../components/notifications-tooltip';
import '../components/positive-pay-decision-widget';
import '../components/total-cash-position-graph';

export const tagName = 'jhd-dashboard';
@customElement(tagName)
export class JhdDashboard extends TmBaseComponent {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare featureFlagService: FeatureFlagService;

    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare config: ConfigurationService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @state()
    private showCutoffTooltip = false;

    @state()
    private showNotificationsTooltip = false;

    @property({ type: Array })
    private menuItems = this.createMenuItems();

    @state()
    bottomSheetOpen = false;

    @state()
    isPwaBetaBank = false;

    @state()
    configuredProducts: Map<string, boolean> = new Map();

    async firstUpdated() {
        // eslint-disable-next-line no-restricted-globals
        this.showNotificationsTooltip = screen.width > 1100;
        const configuredProducts = (await DashboardRequests.getProducts()) as DashboardProduct[];
        // There's a duplicate ACH product in the list...
        const filteredProducts = configuredProducts.filter(
            (product: DashboardProduct) => product.id !== 1627726
        );
        this.configuredProducts = new Map(
            filteredProducts.map(product => [product.type, product.status === 'Active'])
        );
        this.headerService.configure({ title: 'Dashboard', menuItems: this.menuItems });
        this.isPwaBetaBank = await this.featureFlagService.isEnabled(Feature.IsPwaBetaBank);
    }

    private createMenuItems(): MenuItem[] {
        return [
            {
                title: 'Messages',
                icon: html`<messages-tooltip></messages-tooltip>`,
                action: () => this.navService.navigate(`/message-center`),
            },
            {
                title: 'Notifications',
                icon: html`<notifications-tooltip
                    .institution=${this.config.institutionId}
                    .showNotificationsTooltip=${this.showNotificationsTooltip}
                ></notifications-tooltip>`,
                action: () => {
                    // eslint-disable-next-line no-unused-expressions
                    this.showNotificationsTooltip
                        ? null
                        : this.navService.navigate(`/notifications`);
                },
            },
            {
                title: 'Cutoff Times',
                icon: html`<cutoff-times-tooltip
                    .showCutoffTooltip=${this.showCutoffTooltip}
                ></cutoff-times-tooltip>`,
                action: () => {
                    this.showCutoffTooltip = true;
                },
            },
        ];
    }

    renderPositivePayWidget() {
        const posPayProduct = this.configuredProducts.get('ArpProductFeature');
        if (!posPayProduct) return nothing;
        return html`
            <positive-pay-decision-widget
                .disabled=${this.isPwaBetaBank}
            ></positive-pay-decision-widget>
        `;
    }

    renderDepositsWidget() {
        const depositsProduct = this.configuredProducts.get(
            'RemoteDepositCaptureSsoProductFeature'
        );
        if (!depositsProduct) return nothing;
        return html`<deposits-widget .disabled=${this.isPwaBetaBank}></deposits-widget>`;
    }

    renderActionTile(
        tileTitle: string,
        icon: any,
        actionPath: string,
        productType: string,
        disabled: boolean
    ) {
        const visible = this.configuredProducts.get(productType);
        if (!visible) return nothing;
        return html`
            <tm-action-tile
                .tileTitle=${tileTitle}
                .icon=${icon}
                .action=${() => {
                    this.navService.navigate(actionPath);
                }}
                .disabled=${disabled}
            ></tm-action-tile>
        `;
    }

    render() {
        return html`
            <div class="header-pad flex flex-1 pt-2 pb-24">&nbsp;</div>
            <slot></slot>
            <div class="action-tiles flex m-1 items-center justify-center">
                ${this.renderActionTile('ACH Payments', achIcon, '/ach', 'ACH', false)}
                ${this.renderActionTile(
                    'Business Bill Pay',
                    billsIcon,
                    '/business-bill-pay',
                    'BillPayProductFeature',
                    this.isPwaBetaBank
                )}
                ${this.renderActionTile(
                    'Transfers',
                    transferIcon,
                    '/transfers',
                    'InternalTransferProduct',
                    false
                )}
                ${this.renderActionTile(
                    'Wires',
                    wiresIcon,
                    '/wires',
                    'WireTransferProductFeature',
                    false
                )}
            </div>
            <div class="widget-wrapper">
                <div class="main-widgets-wrapper">
                    <accounts-widget></accounts-widget>
                </div>
                <div class="shortcut-widgets-wrapper">
                    <approval-widget .products=${this.configuredProducts}></approval-widget>
                    ${this.renderPositivePayWidget()} ${this.renderDepositsWidget()}
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    --widget-gutter: 10px;
                }
                .header-pad {
                    background: var(--header-background);
                    box-shadow: var(--content-shadow);
                    margin-bottom: -118px;
                }
                tm-action-tile {
                    margin: 0 5px;
                }
                .widget-wrapper {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    width: calc(100vw - (var(--widget-gutter * 2)));
                    align-items: flex-start;
                    margin: var(--widget-gutter);
                    // margin-top: -85px;
                }
                .main-widgets-wrapper {
                    flex: 1;
                }

                .main-widgets-wrapper > *,
                .shortcut-widgets-wrapper > * {
                    margin-bottom: var(--widget-gutter);
                }
                .shortcut-widgets-wrapper {
                    margin: 0;
                    width: 100%;
                }

                @media screen and (min-width: 800px) {
                    .shortcut-widgets-wrapper {
                        margin-right: 0;
                        margin-left: var(--widget-gutter);
                        width: unset;
                        flex: 2 4 auto;
                    }
                    .main-widgets-wrapper {
                        flex: 2 1 0;
                    }
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: JhdDashboard;
    }
}
