import { PendingTransfer } from '@treasury/domain/approvals/pending-transfer.entity';
import { ApprovalOptions, ApprovalVm } from '@treasury/presentation';
import { formatUsdCurrency } from '@treasury/utils/functions/currency.helpers';

export class PendingTransferViewModel extends ApprovalVm<PendingTransfer> {
    constructor(transfer: PendingTransfer) {
        super(transfer, ApprovalOptions.NA);
    }

    get amount() {
        return formatUsdCurrency(this.value.amount);
    }
}
