/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    AchCompanyModelDto,
    CompanyAccountModelDto,
    DomesticBankModelCollectionDto,
    ErrorHttpContentDto,
    ErrorResponseModelDto,
    NumberKeyCollectionDto,
    SecCodeModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class AchClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Ach
     * @name AchPost
     * @summary Saves the ACH Company To ACH Product Feature.
     * @request POST:/ACH/{id}
     */
    achPost = (id: number, achCompanies: AchCompanyModelDto[] | null, params: RequestParams = {}) =>
        this.http.request<AchCompanyModelDto[], ErrorHttpContentDto>({
            path: `/ACH/${id}`,
            method: 'POST',
            body: achCompanies,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Ach
     * @name AchCompaniesAllPost
     * @summary Saves the ACH Companies For Ach Product.
     * @request POST:/ACH/{id}/Companies
     */
    achCompaniesAllPost = (
        id: number,
        achCompanies: AchCompanyModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchCompanyModelDto[], ErrorHttpContentDto>({
            path: `/ACH/${id}/Companies`,
            method: 'POST',
            body: achCompanies,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Ach
     * @name AchCompaniesDelete
     * @request DELETE:/ACH/{id}/companies/{actionId}
     */
    achCompaniesDelete = (id: number, actionId: number, params: RequestParams = {}) =>
        this.http.request<boolean, ErrorHttpContentDto>({
            path: `/ACH/${id}/companies/${actionId}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Ach
     * @name AchCompaniesAllPut
     * @request PUT:/ACH/{id}/companies/{actionId}
     */
    achCompaniesAllPut = (
        id: number,
        actionId: number,
        secCodes: SecCodeModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<SecCodeModelDto[], ErrorHttpContentDto>({
            path: `/ACH/${id}/companies/${actionId}`,
            method: 'PUT',
            body: secCodes,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Ach
     * @name AchUpdateStatus
     * @request PUT:/ACH/{id}/companies/{actionId}/updateStatus
     */
    achUpdateStatus = (
        id: number,
        actionId: number,
        achCompany: AchCompanyModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchCompanyModelDto, ErrorHttpContentDto>({
            path: `/ACH/${id}/companies/${actionId}/updateStatus`,
            method: 'PUT',
            body: achCompany,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Ach
     * @name AchUpdatePaymentProcessingStatus
     * @summary Updates ACH payment processing status.
     * @request PUT:/ACH/companies/{actionId}/updatePaymentProcessingStatus
     */
    achUpdatePaymentProcessingStatus = (
        actionId: number,
        achCompany: AchCompanyModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchCompanyModelDto, ErrorHttpContentDto>({
            path: `/ACH/companies/${actionId}/updatePaymentProcessingStatus`,
            method: 'PUT',
            body: achCompany,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Ach
     * @name AchSecCodes
     * @summary get fi sec codes
     * @request GET:/ACH/{id}/secCodes
     */
    achSecCodes = (id: number, params: RequestParams = {}) =>
        this.http.request<SecCodeModelDto[], ErrorHttpContentDto>({
            path: `/ACH/${id}/secCodes`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Ach
     * @name AchOffsetAccounts
     * @summary Gets offset accounts.
     * @request GET:/ach/{id}/offsetaccounts/{actionId}
     */
    achOffsetAccounts = (id: number, actionId: number, params: RequestParams = {}) =>
        this.http.request<any, ErrorHttpContentDto>({
            path: `/ach/${id}/offsetaccounts/${actionId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Ach
     * @name AchOffsetAccountsAll
     * @summary Updates given collection of off set accounts.
     * @request PUT:/ach/{id}/offsetaccounts/{actionId}
     */
    achOffsetAccountsAll = (
        id: number,
        actionId: number,
        managedAccountIds: NumberKeyCollectionDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyAccountModelDto[], ErrorHttpContentDto>({
            path: `/ach/${id}/offsetaccounts/${actionId}`,
            method: 'PUT',
            body: managedAccountIds,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description Returns all domestic banks.  The banks collection can include the bank for the logged in BackOffice institution; for that bank, IsOnUs flag will be true.
     *
     * @tags Ach
     * @name AchGetDomesticBanks
     * @summary Retrieves all domestic banks for ACH processing.
     * @request GET:/ach/domesticBanks
     */
    achGetDomesticBanks = (params: RequestParams = {}) =>
        this.http.request<
            DomesticBankModelCollectionDto,
            ErrorHttpContentDto | ErrorResponseModelDto
        >({
            path: `/ach/domesticBanks`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
