import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-blocking-loader';
import '@vaadin/grid';
import { columnBodyRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import '../../components/jhd-data-field';
import { UserWireLimitsViewModel, WireAccount } from '../data/user-wire-limits-view-model';

export const tagName = 'user-wire-limits';
@customElement(tagName)
export class UserWireLimits extends TmBaseComponent {
    @property({ type: Object })
    public config!: UserWireLimitsViewModel;

    renderLimitHeader() {
        if (!this.config) return nothing;
        return html`<div class="wire-limits p-3">
            <h3 class="text-lg pb-2">Wire Limits</h3>
            <jhd-data-field
                .label=${'Product Daily Limit'}
                .value=${this.config.dailyLimit}
            ></jhd-data-field>
            <jhd-data-field
                .label=${'Daily Initiation Limit'}
                .value=${this.config.wireLimits?.initiationLimit?.value}
                .edited=${this.config.wireLimits?.initiationLimit?.edited}
            ></jhd-data-field>
            <jhd-data-field
                .label=${'Daily Approval Limit'}
                .value=${this.config.wireLimits?.approvalLimit?.value}
                .edited=${this.config.wireLimits?.approvalLimit?.edited}
            ></jhd-data-field>
        </div>`;
    }

    renderAccountCol(account: WireAccount) {
        return html`<div class="flex flex-col">
            <span class="text-sm font-normal">${account.accountName}</span>
            <span class="text-xs font-normal text-gray-500">${account.accountNumber}</span>
            <span class="text-xs font-normal text-gray-500">${account.accountDailyLimit}</span>
        </div>`;
    }

    renderLimitsCol(account: WireAccount) {
        return html`<div class="flex flex-col">
            <span
                class=${classMap({
                    'text-sm': true,
                    'font-normal': true,
                    edited: account.transactionLimit.edited,
                })}
                >transaction limit: ${account.transactionLimit.value}</span
            >
            <span
                class=${classMap({
                    'text-sm': true,
                    'font-normal': true,
                    edited: account.dailyLimit.edited,
                })}
                >daily limit: ${account.dailyLimit.value}</span
            >
        </div>`;
    }

    renderAccountLimitsTable() {
        if (!this.config) return nothing;
        if (!this.config.userWireAccounts?.length)
            return html`<h3 class="italic text-lg w-full text-center">No Accounts Available.</h3>`;
        return html`<vaadin-grid all-rows-visible .items=${this.config.userWireAccounts}>
            <vaadin-grid-column
                flex-grow="0"
                ${columnBodyRenderer(this.renderAccountCol)}
            ></vaadin-grid-column>
            <vaadin-grid-column
                flex-grow="1"
                text-align="end"
                ${columnBodyRenderer(this.renderLimitsCol)}
            ></vaadin-grid-column>
        </vaadin-grid>`;
    }

    render() {
        return html`${this.renderLimitHeader()}${this.renderAccountLimitsTable()}`;
    }

    static get styles() {
        return [
            css`
                .wire-limits {
                    background-color: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserWireLimits;
    }
}
