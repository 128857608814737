import { UserWireLimits } from '@treasury/domain/users/product-features';
import { formatUsdCurrency } from '@treasury/utils';

export type WireAccount = {
    accountName: string;
    accountNumber: string;
    id: number;
    transactionLimit: {
        value: string;
        edited: boolean;
    };
    dailyLimit: {
        value: string;
        edited: boolean;
    };
    accountDailyLimit: number;
};

export class UserWireLimitsViewModel {
    constructor(private readonly value: UserWireLimits) {}

    get dailyLimit() {
        return formatUsdCurrency(this.value.dailyLimit);
    }

    get wireLimits() {
        return {
            initiationLimit: {
                value: formatUsdCurrency(Number(this.value.wireLimits.initiationLimit.value) || 0),
                edited: this.value.wireLimits.initiationLimit.edited,
            },
            approvalLimit: {
                value: formatUsdCurrency(Number(this.value.wireLimits.approvalLimit.value) || 0),
                edited: this.value.wireLimits.approvalLimit.edited,
            },
        };
    }

    get userWireAccounts() {
        return this.value.userWireAccounts?.map(account => ({
            accountName: account.accountName,
            accountNumber: account.accountNumber,
            id: account.id,
            transactionLimit: {
                value: formatUsdCurrency(Number(account.transactionLimit.value) || 0),
                edited: account.transactionLimit.edited,
            },
            dailyLimit: {
                value: formatUsdCurrency(Number(account.dailyLimit.value) || 0),
                edited: account.dailyLimit.edited,
            },
            accountDailyLimit: formatUsdCurrency(account.accountDailyLimit),
        }));
    }
}
