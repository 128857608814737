import { AuthenticationService } from '@treasury/domain/services/authentication';
import { Feature } from '@treasury/domain/services/feature-flags';
import { TmBaseComponent } from '@treasury/presentation';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { until } from 'lit/directives/until.js';
import PhoneNumber from '../data/phone-number';
import './oob-registration-dialog';

export const tagName = 'oob-registration';
@customElement(tagName)
export default class OobRegistration extends TmBaseComponent {
    constructor() {
        super();
        this.verificationMethods = {
            Text: {
                id: 'Text',
                isVerified: false,
                label: 'Text Message',
                singular: 'a text message (SMS)',
                plural: 'text messages',
                aMessageWasSentToYourDevice: 'A text message (SMS) was sent to your phone.',
                retryLabel: 'Retry Text Message',
                oneTimePasswordLength: 8,
                other: {},
                phoneNumber: new PhoneNumber(''),
            },

            Call: {
                id: 'Call',
                isVerified: false,
                label: 'Automated Phone Call',
                singular: 'an automated phone call',
                plural: 'automated phone calls',
                aMessageWasSentToYourDevice: 'A phone call was made to the number provided below.',
                retryLabel: 'Call Again',
                oneTimePasswordLength: 6,
                other: {},
                phoneNumber: new PhoneNumber(''),
            },
        };

        this.verificationMethods.Text.other = this.verificationMethods.Call;
        this.verificationMethods.Call.other = this.verificationMethods.Text;
    }

    @InjectProperty()
    private declare authService: AuthenticationService;

    @state()
    verificationMethods;

    @state()
    client: any;

    @state()
    dialogOpened = false;

    @state()
    selectedVerificationMethod: any;

    @state()
    isUisEnabled = false;

    get textMessage() {
        return this.verificationMethods.Text;
    }

    get phoneCall() {
        return this.verificationMethods.Call;
    }

    async firstUpdated() {
        await this.loadConfig();
        // this.isUisEnabled = (await this.authService.uisMetadataPromise).isUisEnabled;
    }

    async loadConfig() {
        const config = await this.client.fetchOutOfBandConfig();

        this.verificationMethods.Text.phoneNumber = new PhoneNumber(
            config.callVerificationMethod?.phoneNumber ?? ''
        );
        this.verificationMethods.Call.phoneNumber = new PhoneNumber(
            config.textVerificationMethod?.phoneNumber ?? ''
        );
        this.requestUpdate();
    }

    remindMeLater() {
        this.client.oobRemindMeLater().then(() => this.done());
    }

    async removeVerificationMethod(verificationMethod: any) {
        /* eslint-disable no-param-reassign */
        await this.client.removeVerificationMethod(verificationMethod.id);

        verificationMethod.phoneNumber = new PhoneNumber('');
        verificationMethod.isVerified = false;

        this.requestUpdate();
    }

    done() {
        this.dispatchEvent(new CustomEvent('done'));
    }

    renderAddNumberButton(verificationMethod: any) {
        if (!verificationMethod.phoneNumber) return nothing;
        if (verificationMethod.phoneNumber.isNotBlank && verificationMethod.isVerified)
            return html` <p>Phone number: ${verificationMethod.phoneNumber}</p>
                <div class="card-footer">
                    <tm-button
                        importance="tertiary"
                        @click=${() => {
                            console.log(verificationMethod);
                            this.selectedVerificationMethod = verificationMethod;
                            this.dialogOpened = true;
                        }}
                        >Edit Phone Number</tm-button
                    >
                    <tm-button
                        importance="tertiary"
                        @click=${() => this.removeVerificationMethod(verificationMethod)}
                        >Remove</tm-button
                    >
                </div>`;
        return html`<div class="card-footer">
            <tm-button
                importance="primary"
                @click=${() => {
                    this.selectedVerificationMethod = verificationMethod;
                    this.dialogOpened = true;
                }}
                >Add Phone Number</tm-button
            >
        </div>`;
    }

    renderDoneButton() {
        if (!(this.textMessage.isVerified || this.phoneCall.isVerified)) return nothing;
        return html`<tm-button @click=${() => this.done()}>Done</tm-button>`;
    }

    renderRemindMeLaterButton() {
        if (this.textMessage.isVerified || this.phoneCall.isVerified) return nothing;
        return html`<tm-button importance="tertiary" @click=${() => this.remindMeLater()}
            >Remind Me Later</tm-button
        >`;
    }

    renderRegistrationDialog() {
        return html`<oob-registration-dialog
            .dialogOpened=${this.dialogOpened}
            @dialogClosed=${() => {
                this.dialogOpened = false;
            }}
            .verificationMethod=${this.selectedVerificationMethod}
            .client=${this.client}
        >
        </oob-registration-dialog>`;
    }

    renderHeaderText() {
        const legacyText = `For additional authentication purposes, please provide
        phone numbers to receive text messages (SMS) and automated phone calls. You may
        be prompted to verify your identity by responding to a text message or automated
        phone call at login or when initiating transactions.`;

        const uisText = `For additional authentication purposes, when working with transactions, please provide
        phone numbers to receive text messages (SMS) and automated phone calls. You may
        be prompted to verify your identity by responding to a text message or automated
        phone call at login or when working with transactions.`;

        const promise = this.authService.uisMetadataPromise;
        promise.then(resp => (resp && resp.isUisEnabled ? uisText : legacyText));

        return html`${until(promise, '')}`;
    }

    render() {
        return html`
            ${this.renderRegistrationDialog()}
            <div class="content">
                <p class="heading-text">${this.renderHeaderText()}</p>
                <div class="card">
                    <h4>Text Message (SMS)</h4>
                    <p>Get a prompt via text message and reply to verify your identity.</p>

                    ${this.renderAddNumberButton(this.textMessage)}
                </div>
                <div class="card">
                    <h4>Automated Phone Call</h4>
                    <p>
                        Receive a prompt via automated phone call and reply to verify your identity.
                    </p>
                    ${this.renderAddNumberButton(this.phoneCall)}
                </div>
                <p>
                    You can only enter this information one time. You must contact your financial
                    institution to change your security phone numbers.
                </p>
            </div>

            <footer>${this.renderDoneButton()} ${this.renderRemindMeLaterButton()}</footer>
        `;
    }

    static get styles() {
        return [
            css`
                .content {
                    padding: 15px;
                }
                footer {
                    padding: 7px 15px;
                    border-top: 1px solid #ccc;
                    text-align: right;
                }
                .card {
                    border: 1px solid #ccc;
                    margin-bottom: 30px;
                }
                .card h4 {
                    background: #eee;
                    border-bottom: 1px solid #ccc;
                    margin: 0;
                    padding: 7px 15px;
                    font-size: 16px;
                }
                .heading-text {
                    margin: 0 0 30px;
                }
                .card p {
                    margin: 15px;
                }
                .card-footer {
                    padding: 7px 15px;
                    border-top: 1px solid #ccc;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: OobRegistration;
    }
}
