import { ConfigurationService } from '@treasury/core/config';
import { TmBaseComponent } from '@treasury/presentation';
import { arrowBackIcon } from '@treasury/presentation/assets/icons';
import { InjectProperty } from '@treasury/utils';
import { css, html, nothing, TemplateResult } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { HeaderBarService } from '../services/jhd-header-bar.service';
import { LayoutService } from '../services/layout.service';
import { HeaderConfig, MenuItem } from '../services/types/jhd-header-bar.type';

export const tagName = 'jhd-header-bar';
@customElement(tagName)
export class JhdHeaderBar extends TmBaseComponent {
    @InjectProperty()
    private declare layoutService: LayoutService;

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare config: ConfigurationService;

    @state()
    backAction: (() => void) | null = null;

    @state()
    pageTitle: string | TemplateResult = '';

    @state()
    subtitle = '';

    @state()
    menuItems!: MenuItem[];

    @state()
    isSidebarOpen = false;

    @state()
    empty = false;

    firstUpdated() {
        this.layoutService.isSidebarOpen$.subscribe(isSidebarOpen => {
            this.isSidebarOpen = isSidebarOpen;
            const sidebarToggle = this.shadowRoot?.querySelector('.toggle-icon');
            sidebarToggle?.classList.toggle('toggle--active', isSidebarOpen);
        });

        this.headerService.config$.subscribe(
            ({ backAction, title, subtitle, menuItems, empty }: HeaderConfig) => {
                this.backAction = backAction;
                this.pageTitle = title;
                this.subtitle = subtitle;
                this.menuItems = menuItems;
                this.empty = empty;
            },
            true
        );
        this.setStyle();
    }

    protected updated(): void {
        this.setStyle();
    }

    private setStyle() {
        if (this.empty) {
            this.style.height = '20px';
            this.style.background = '#fff';
        } else {
            this.removeAttribute('style');
        }
    }

    renderAction() {
        const action = this.backAction
            ? html`<button
                  id="back-button"
                  class="cursor-pointer border-none outline-none"
                  @click=${this.backAction}
              >
                  ${arrowBackIcon}
              </button>`
            : html`<div
                  class="toggle-container relative w-6 h-6"
                  @click=${() => this.layoutService.toggleSidebar()}
                  @keyup=${(e: KeyboardEvent) =>
                      e.key === 'Return' && this.layoutService.toggleSidebar()}
              >
                  <div
                      class=${classMap({
                          'toggle-icon': true,
                          empty: this.empty,
                      })}
                  ></div>
              </div>`;
        return html`<div
            class="action-container flex items-center fixed w-6 h-12 cursor-pointer z-10"
        >
            ${action}
        </div>`;
    }

    renderTitle() {
        const subtitle = this.subtitle
            ? html`<br /><small class="text-xs">${this.subtitle}</small>`
            : nothing;
        return html`
            <div
                class=${classMap({
                    'header-state-back': !!this.backAction,
                })}
            >
                <div class="flex justify-center mx-10">
                    <img
                        src="/pwa/assets/branding/${this.config.institutionId}/logo.webp"
                        alt="logo"
                        class="logo h-8"
                    />
                    <div class="spacer px-3 pt-1">|</div>
                    <div class="header-title text-lg font-normal flex flex-col">
                        ${this.pageTitle}${subtitle}
                    </div>
                </div>
            </div>
        `;
    }

    renderMenuItems() {
        const menuItems = this.menuItems?.map(
            item => html`
                <button
                    title=${item.title}
                    class="header-button cursor-pointer border-none outline-none p-2 last-of-type:mr-0"
                    @click=${() => item.action()}
                >
                    ${item.icon}
                </button>
            `
        );
        return html`<div class="header-actions flex flex-1 justify-end">${menuItems}</div>`;
    }

    render() {
        return html`
            <div class="header-bar relative right-0 flex flex-row justify-start items-center">
                ${this.renderAction()}${this.renderTitle()}${this.renderMenuItems()}
            </div>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    position: sticky;
                    top: 0;
                    z-index: 15;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: var(--navbar-height, 40px);
                    background: var(--header-background);
                    margin-block-start: 0em;
                    margin-block-end: 0px;
                    padding: var(--navbar-padding, 15px) !important;
                }
                .header-bar {
                    color: var(--header-bar-text-color);
                    height: 60px;
                }
                .header-button {
                    display: inline-block;
                    position: relative;
                    z-index: 1;
                    margin: 0px;
                }
                .header-button:focus {
                    outline: none;
                    box-shadow: none;
                }
                path {
                    fill: var(--white);
                }
                button {
                    flex: 0;
                    background: none;
                }
                .toggle-icon,
                .toggle-icon::before,
                .toggle-icon::after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -1px);
                    transform-origin: center center;
                    width: 16px;
                    height: 2px;
                    background-color: var(--white);
                    border-radius: 10px;
                    transition: 0.9s;
                }
                .toggle-icon.empty,
                .toggle-icon.empty::before,
                .toggle-icon.empty::after {
                    background-color: #000;
                }
                .toggle-icon::before {
                    transform: translate(-50%, -6px) rotate(0deg);
                }
                .toggle-icon::after {
                    transform: translate(-50%, 4px) rotate(0deg);
                }
                .toggle-icon.toggle--active {
                    background-color: transparent;
                }
                .toggle-icon.toggle--active::before {
                    transform: translate(-50%, 0px) rotate(45deg);
                }
                .toggle-icon.toggle--active::after {
                    transform: translate(-50%, 0px) rotate(-45deg);
                }
                .header-state-back {
                    position: absolute;
                    width: 100%;
                    text-align: center;
                }

                @media (max-width: 500px) {
                    img {
                        display: none;
                    }
                    .spacer {
                        display: none;
                    }
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: JhdHeaderBar;
    }
}
