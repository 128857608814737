import { arrayToDict, exists, getKeys } from '@treasury/utils';
import { Injectable } from '@treasury/utils/dependency-injection';
import { FiDate } from '../../../dates';
import {
    IIssuedItem,
    IssuedItemLegacy,
    IssuedItemPendingStatus,
    IssuedItemSaved,
    convertToSaveDto,
    createIssuedItem,
} from '../../mappings/arp';
import {
    ArpIssuedItemsRequests,
    mapIssuedItemToVoidQuery,
    saveIssuedItems,
    searchIssuedItemsVoids,
} from '../../requests/arp';
import { AccountDto, IssuedItemsQueryDto, hasNullAccount } from '../../types';
import { DownloadFormat, DownloadService } from '../download';

@Injectable()
export class ArpService {
    public async getIssuedItems(query: IssuedItemsQueryDto) {
        const dtos = (await ArpIssuedItemsRequests.searchIssuedItems(query)) || [];
        return dtos.map(createIssuedItem);
    }

    /**
     * Given a list of pending, partial items-to-be-voided (e.g., from the issued item creation view),
     * search for any checks that match the provided criteria.
     *
     * @returns A list of potential check matches for each user-entered voided item.
     */
    public async getMatchesForVoids(items: IIssuedItem[], maxMatches = 10) {
        if (items.some(i => i.type !== 'Void')) {
            throw new Error(`Only issued item voids may be searched on.`);
        }

        if (items.length < 1) {
            return [];
        }

        const voids = items.map(mapIssuedItemToVoidQuery);
        const response = await searchIssuedItemsVoids({
            issuedItemsVoids: arrayToDict(voids, (elem, i) => ({
                key: i.toString(),
                value: elem,
            })),
            maxMatches,
        });

        if (!response.success) {
            throw new Error(response.responseDetailCollection[0].responseMessage);
        }

        const { searchIssuedItemsVoids: dtos } = response;
        return getKeys(dtos).map(k => {
            const { hasMore: hasMoreMatches, issuedItems: matchDtos } = dtos[k];
            const i = parseInt(k.toString(), 10);

            if (Number.isNaN(i)) {
                throw new Error(`Could not parse response key ${k} into a numeric value.`);
            }

            let matches = (matchDtos || []).map(createIssuedItem);
            // eslint-disable-next-line no-return-assign
            matches.forEach(item => (item.type = 'Void'));

            const original = items[i];
            if (matches.length > 1) {
                original.pendingStatus = hasMoreMatches
                    ? IssuedItemPendingStatus.TooManyMatches
                    : IssuedItemPendingStatus.HasPossibleMatches;

                const allFinalized = matches.every(
                    ({ itemStatus }) => itemStatus === 'Cleared' || itemStatus === 'Void'
                );
                if (allFinalized) {
                    original.pendingStatus = IssuedItemPendingStatus.HasOnlyFinalizedMatches;
                }
            }
            // if there is exactly 1 match, update the original with its data
            else if (matches.length === 1) {
                const { itemEntryType: originalEntryType } = original;
                original.copyFrom(matches[0]);

                // maintain original entry type
                original.itemEntryType = originalEntryType;

                matches = [];
            }

            return {
                original,
                matches,
            };
        });
    }

    public async saveIssuedItems(items: IIssuedItem[], fileName?: string) {
        const dtos = items.map(item => convertToSaveDto(item));
        const response = await saveIssuedItems({
            uploadFileName: fileName,
            issuedItems: dtos,
        });
        const { issuedItems, issuedItemErrors } = response;

        return issuedItems.map(item => {
            const error = issuedItemErrors.find(err => err.issuedItemId === item.id);
            return new IssuedItemSaved(item, error);
        });
    }

    public downloadIssuedItemsReport(outputType: DownloadFormat, filterModel: IssuedItemsQueryDto) {
        const pageId = 'IssuedItemsActivity';
        const fileSuffix = new FiDate(new Date()).toIsoDate();

        DownloadService.download<IssuedItemsQueryDto>(
            fileSuffix,
            outputType,
            pageId,
            {
                ...filterModel,
                page: pageId,
            },
            ['PDF', 'CSV']
        );
    }

    /**
     * Parse a text file into issued items using the ID of a well-known format.
     *
     * @param formatId The ID of the saved issued item format.
     * @param data A CSV file conforming to the format corresponding to the provided `formatId`.
     * @param account An optional account DTO object to fill in the parsed issued items with, if
     * not provided in the file.
     * @returns A list of `IssuedItem` instances hydrated from the provided file or a list of errors
     * if the provided file could not be parsed.
     */
    public async parseIssuedItems(formatId: string, data: File, account?: AccountDto) {
        const formatIdNumeric = parseInt(formatId, 10);
        const response = await ArpIssuedItemsRequests.parseIssuedItems(formatIdNumeric, data);
        const { issuedItems: dtos, fileErrors } = response;

        if (dtos) {
            if (exists(account) && !dtos.every(hasNullAccount)) {
                throw new Error(
                    'Account parameter was provided but was also specified in parsed file.'
                );
            }

            const items = dtos.map(dto => new IssuedItemLegacy(dto));
            items.forEach(i => {
                i.itemEntryType = 'Upload';
                if (account) {
                    i.account = account;
                }
            });

            return items.map(i => i.convertFromLegacy());
        }

        if (fileErrors) {
            return fileErrors;
        }

        throw new Error('Invalid parsed item response.');
    }

    public async getSavedFormats() {
        return ArpIssuedItemsRequests.getSavedFormats();
    }
}
