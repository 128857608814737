/* eslint-disable import/no-unresolved */
import { TmBaseComponent } from '@treasury/presentation';
import '@vaadin/dialog';
import { DialogOpenedChangedEvent } from '@vaadin/dialog';
import { dialogFooterRenderer, dialogRenderer } from '@vaadin/dialog/lit.js';
import { css, CSSResult, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import enter from './oob-registration-dialog-states/enter';
import selectPreference from './oob-registration-dialog-states/select-preference';
import verify from './oob-registration-dialog-states/verify';

export interface PhoneNumber {
    value: string;
    formatted: string;
}

export const tagName = 'oob-registration-dialog';
@customElement(tagName)
export default class OobRegistrationDialog extends TmBaseComponent {
    @state()
    isLoading = false;

    @property({ type: Boolean })
    useForBoth = false;

    @property({ type: Boolean })
    dialogOpened = false;

    @property({ type: String })
    oneTimePassword = '';

    @property({ type: Object })
    state: any = enter;

    @state()
    private preferredMethod = '';

    @property({ type: Object })
    client: any;

    @property({ type: Object })
    verificationMethod: any;

    @property({ type: String })
    errorMessage?: string;

    @state()
    phoneNumber?: PhoneNumber;

    get title() {
        return this.state.title;
    }

    get isOneTimePasswordValid() {
        return this.oneTimePassword.length === this.verificationMethod.oneTimePasswordLength;
    }

    requestOtherContactMethod() {
        this.verificationMethod = this.verificationMethod.other;
        this.state = enter;
    }

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        if (changedProperties.has('isLoading')) {
            this.dispatchEvent(
                new CustomEvent('loadingStatusChange', {
                    bubbles: true,
                    composed: true,
                    detail: this.isLoading,
                })
            );
        }
    }

    async sendOneTimePassword() {
        this.isLoading = true;
        try {
            const response = await this.client.sendOneTimePassword(
                this.phoneNumber!.value,
                this.verificationMethod.id,
                this.useForBoth
            );

            if (response.status === 'Success') {
                this.state = verify;
                this.errorMessage = undefined;
            } else {
                this.state = enter;
                this.errorMessage = response.message;
            }
        } catch (e: any) {
            console.log(e);
            this.state = enter;
            this.errorMessage = e.message;
        }
        this.isLoading = false;
    }

    async verify() {
        this.isLoading = true;
        const response = await this.client.validateOneTimePassword(this.oneTimePassword);

        if (response.status === 'Success') {
            this.verificationMethod.isVerified = true;
            this.preferredMethod = this.verificationMethod.id;
            this.verificationMethod.phoneNumber = this.phoneNumber!.value;
            if (this.useForBoth) {
                this.verificationMethod.other.isVerified = true;
                this.verificationMethod.other.phoneNumber = this.phoneNumber!.value;
            }
            this.errorMessage = undefined;
            this.state = selectPreference;
        } else {
            // cspell:ignore passcode
            this.errorMessage = response.message ?? 'Invalid passcode';
            this.state = enter;
        }
        this.isLoading = false;
    }

    async finish() {
        this.isLoading = true;
        await this.client.setOutOfBandPreferredMethod(this.preferredMethod);
        this.isLoading = false;
        this.dispatchEvent(new CustomEvent('done', { bubbles: true, composed: true }));
        this.dialogOpened = false;
    }

    cancel() {
        this.phoneNumber = undefined;
        this.errorMessage = undefined;
        this.dialogOpened = false;
        this.state = enter;
    }

    renderDialogBody() {
        return this.state.renderBody(this);
    }

    renderDialogFooter() {
        return this.state.renderActions(this);
    }

    render() {
        if (!this.verificationMethod) return nothing;
        return html`<vaadin-dialog
            id="verification-dialog"
            header-title="${this.title}"
            .opened=${this.dialogOpened}
            @opened-changed=${(event: DialogOpenedChangedEvent) => {
                if (!event.detail.value) {
                    this.dispatchEvent(
                        new CustomEvent('dialogClosed', { bubbles: true, composed: true })
                    );
                }
            }}
            ${dialogRenderer(this.renderDialogBody, [this.state, this.errorMessage])}
            ${dialogFooterRenderer(this.renderDialogFooter, [
                this.isOneTimePasswordValid,
                this.phoneNumber,
                this.isLoading,
                this.state,
            ])}
        ></vaadin-dialog>`;
    }

    // eslint-disable-next-line @treasury/style-includes-host-display
    static get styles() {
        return [
            css`
                .omega-dialog {
                    min-width: 520px;
                    border-radius: 0px;
                    padding: 0;
                }

                .dialog-header {
                    display: flex;
                    border-bottom: 1px solid #ccc;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 15px;
                }
                .dialog-header-title {
                    font-size: 22px;
                    font-weight: 600;
                    color: var(--omega-text-default);
                }

                .body {
                    padding: 15px;
                }

                footer {
                    width: 100%;
                    border-top: 1px solid #ccc;
                    padding: 8px;
                }
            `,
        ] as CSSResult[];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: OobRegistrationDialog;
    }
}
