import { CustomerModelDto, LocationModelDto } from '@treasury/api/channel';
import { TmBaseComponent } from '@treasury/presentation';
import { chevronForwardIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-currency-field';
import '@treasury/presentation/components/tm-footer';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

interface FormData {
    amount: number;
    location: string | undefined;
    customer: string | undefined;
    transactionId: string | undefined;
    frontImage: string | undefined;
    rearImage: string | undefined;
}

export const tagName = 'quick-deposit-create-step';
@customElement(tagName)
export class QuickDepositCreateStep extends TmBaseComponent {
    @property({ type: Array })
    locations?: LocationModelDto[] = [];

    @property({ type: Array })
    customers?: CustomerModelDto[] = [];

    @state()
    private location?: LocationModelDto;

    @state()
    private customer?: CustomerModelDto;

    @state()
    private amount = 0;

    @state()
    private frontImage = '';

    @state()
    private rearImage = '';

    @state()
    private formData: FormData = {
        amount: 0,
        location: undefined,
        customer: undefined,
        transactionId: '',
        frontImage: '',
        rearImage: '',
    };

    private onReviewClick() {
        const { location, customer, amount } = this;
        const formData = {
            customer,
            location,
            amount,
        };
        this.dispatchEvent(new CustomEvent('submit', { detail: formData }));
    }

    private disableReviewButton() {
        return (
            !this.formData.amount ||
            !this.formData.location ||
            !this.formData.frontImage ||
            !this.formData.rearImage
        );
    }

    private receiveImage({ detail }: CustomEvent) {
        const { checkSide, image } = detail;
        if (checkSide === 'front') {
            this.frontImage = image;
            this.formData.frontImage = image;
        } else {
            this.rearImage = image;
            this.formData.rearImage = image;
        }
    }

    renderLocationColumn(location: LocationModelDto) {
        return html`<div class="flex flex-col">
            <span class="font-medium text-sm overflow-hidden text-ellipsis">${location.name}</span>
        </div>`;
    }

    renderCustomerColumn(customer: CustomerModelDto) {
        return html`<div class="flex flex-col">
            <span class="font-medium text-sm overflow-hidden text-ellipsis"
                >${customer.firstName} ${customer.lastName}</span
            >
            <span class="text-xs">x${customer.customerReference}</span>
        </div>`;
    }

    renderSelectLocation() {
        return html`<tm-form-row label="Location">
            <tm-slider
                header="Select Location"
                placeholder="Select Location"
                .value=${this.formData.location}
            >
                <tm-list-layout
                    .columns=${[this.renderLocationColumn]}
                    .items=${this.locations}
                    .searchFields=${['name']}
                    @select-list-item=${(e: CustomEvent<LocationModelDto>) => {
                        this.location = e.detail;
                        this.formData.location = e.detail?.name ?? '';
                        e.currentTarget?.dispatchEvent(new CustomEvent('close', { bubbles: true }));
                    }}
                ></tm-list-layout>
            </tm-slider>
        </tm-form-row>`;
    }

    private renderSelectCustomer() {
        return html`<tm-form-row label="Customer">
            <tm-slider
                header="Select Customer"
                placeholder="Select Customer"
                .value=${this.formData.customer}
            >
                <tm-list-layout
                    .columns=${[this.renderCustomerColumn]}
                    .items=${this.customers}
                    .searchFields=${['firstName', 'lastName']}
                    @select-list-item=${(e: CustomEvent<CustomerModelDto>) => {
                        this.customer = e.detail;
                        this.formData.customer = `${e.detail?.firstName ?? ''} ${
                            e.detail?.lastName ?? ''
                        }`;
                        e.currentTarget?.dispatchEvent(new CustomEvent('close', { bubbles: true }));
                    }}
                ></tm-list-layout>
            </tm-slider>
        </tm-form-row>`;
    }

    public render() {
        return html`
            <form>
                <tm-body>
                    <div class="flex justify-between items-center border-b ml-4 pr-4">
                        <div>Amount</div>
                        <tm-currency-field
                            .value=${this.amount ? this.amount.toString() : ''}
                            @value-changed=${({ detail }: CustomEvent) => {
                                this.amount = detail;
                                this.formData.amount = detail;
                            }}
                        ></tm-currency-field>
                    </div>
                    ${this.renderSelectLocation()} ${this.renderSelectCustomer()}
                    <div class="flex justify-between items-center border-b ml-4 pr-4">
                        <div>Front</div>
                        <div class="flex items-center text-sm">
                            <tm-check-capture
                                .mode=${'capture'}
                                checkSide="front"
                                @image-uploaded=${this.receiveImage}
                            ></tm-check-capture>
                            <div class="action-icon w-4 ml-1">${chevronForwardIcon}</div>
                        </div>
                    </div>
                    <div class="flex justify-between items-center border-b ml-4 pr-4">
                        <div>Back</div>
                        <div class="flex items-center text-sm">
                            <tm-check-capture
                                checkSide="back"
                                @image-uploaded=${this.receiveImage}
                            ></tm-check-capture>
                            <div class="action-icon w-4 ml-1">${chevronForwardIcon}</div>
                        </div>
                    </div>
                    <div class="justify-between items-center border-b mt-5 ml-4 pr-4">
                        <div class="text-lg text-center">For Mobile Deposit Only</div>
                        <div class="text-gray-400 text-center">
                            must be clearly written below your signature
                        </div>
                    </div>
                </tm-body>
                <tm-footer
                    .buttonConfig=${[
                        {
                            text: 'Review',
                            onClick: () => this.onReviewClick(),
                            disabled: this.disableReviewButton(),
                        },
                    ]}
                >
                </tm-footer>
            </form>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                    height: 100%;
                }
                .select-item {
                    color: var(--color-primary);
                }
                .action-icon svg {
                    width: 100%;
                    height: auto;
                }
                form {
                    background-color: #fff;
                    height: 100%;
                }
                tm-body {
                    background-color: #fff;
                    height: 100%;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: QuickDepositCreateStep;
    }
}
