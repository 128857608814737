import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { TmBaseComponent } from '../..';
import { chevronForwardIcon } from '../../assets/icons';
import '../tm-bottom-sheet';
import '../tm-button';
import '../tm-footer';

export const tagName = 'tm-slider';
@customElement(tagName)
export class TmSlider extends TmBaseComponent {
    @state()
    private open = false;

    @property({ type: Boolean })
    public disabled = false;

    @property({ type: String })
    public header = '';

    @property({ type: String })
    public value?: string;

    @property({ type: String })
    public placeholder? = 'Select';

    @property({ type: Boolean })
    public disableSubmitButton?: boolean = false;

    @property({ type: Boolean })
    public showControls?: boolean = false;

    private closeEvent = new Event('close-tm-slider', { bubbles: true });

    handleClose() {
        this.open = false;
        this.dispatchEvent(this.closeEvent);
    }

    renderChevron() {
        if (this.disabled) return nothing;
        return html`<div class="action-icon w-4 ml-1 -mr-1">${chevronForwardIcon}</div>`;
    }

    renderFooter() {
        if (!this.showControls) return nothing;

        return html`<tm-footer
            class="absolute"
            .buttonConfig=${[
                {
                    text: 'Apply',
                    onClick: () => this.handleClose(),
                    disabled: this.disableSubmitButton,
                },
            ]}
        >
        </tm-footer>`;
    }

    render() {
        return html`<button
                class="flex items-center"
                type="button"
                ?disabled=${this.disabled}
                @click=${() => !this.disabled && (this.open = true)}
            >
                <slot name="left"></slot>
                <div class="flex justify-end items-center">
                    <slot name="right">
                        <div class=${classMap({ 'text-[--primary-action-color]': !this.disabled })}>
                            ${this.value ?? this.placeholder}
                        </div>
                        ${this.renderChevron()}
                    </slot>
                </div>
            </button>
            <tm-bottom-sheet .open=${this.open} horizontal @close=${() => (this.open = false)}>
                <p slot="header-center">${this.header}</p>
                <slot></slot>
                ${this.renderFooter()}
            </tm-bottom-sheet> `;
    }

    static get styles() {
        return [
            css`
                .action-icon svg {
                    width: 100%;
                    height: auto;
                }
                button[disabled] {
                    cursor: not-allowed;
                }
                :host(.form-row-slotted) button {
                    margin: 16px 0 16px auto;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmSlider;
    }
}
