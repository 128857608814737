/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { ResourceConfigurationModelDto } from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class ResourceCenterConfigurationsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags ResourceCenterConfigurations
     * @name ResourceCenterConfigurationsGetAll
     * @summary Returns the Resource center configurations.
     * @request GET:/ResourceCenterConfigurations
     */
    resourceCenterConfigurationsGetAll = (params: RequestParams = {}) =>
        this.http.request<ResourceConfigurationModelDto[], any>({
            path: `/ResourceCenterConfigurations`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ResourceCenterConfigurations
     * @name ResourceCenterConfigurationsPost
     * @summary Creates the Resource center.
     * @request POST:/ResourceCenterConfigurations
     */
    resourceCenterConfigurationsPost = (
        resourceConfigurationModel: ResourceConfigurationModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ResourceConfigurationModelDto, any>({
            path: `/ResourceCenterConfigurations`,
            method: 'POST',
            body: resourceConfigurationModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ResourceCenterConfigurations
     * @name ResourceCenterConfigurationsDelete
     * @summary Deletes the Resourcermation center for the specified Id.
     * @request DELETE:/ResourceCenterConfigurations/{id}
     */
    resourceCenterConfigurationsDelete = (id: number, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/ResourceCenterConfigurations/${id}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ResourceCenterConfigurations
     * @name ResourceCenterConfigurationsGet
     * @summary Returns the Resource center for the specified Resource center id.
     * @request GET:/ResourceCenterConfigurations/{id}
     */
    resourceCenterConfigurationsGet = (id: number, params: RequestParams = {}) =>
        this.http.request<ResourceConfigurationModelDto, any>({
            path: `/ResourceCenterConfigurations/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ResourceCenterConfigurations
     * @name ResourceCenterConfigurationsPut
     * @summary Updates the passed Resource center.
     * @request PUT:/ResourceCenterConfigurations/{id}
     */
    resourceCenterConfigurationsPut = (
        id: number,
        resourceConfigurationModel: ResourceConfigurationModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ResourceConfigurationModelDto, any>({
            path: `/ResourceCenterConfigurations/${id}`,
            method: 'PUT',
            body: resourceConfigurationModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ResourceCenterConfigurations
     * @name ResourceCenterConfigurationsResequence
     * @summary Returns the info center for the specified info center id.
     * @request GET:/ResourceCenterConfigurations/Resequence
     */
    resourceCenterConfigurationsResequence = (
        query: {
            /**
             * Info center id.
             * @format int32
             */
            id: number;
            /** up/down */
            direction: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<ResourceConfigurationModelDto[], any>({
            path: `/ResourceCenterConfigurations/Resequence`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
}
