import { NavigationService } from '@treasury/core/navigation';
import { TmApiError } from '@treasury/domain/shared';

import { PaymentTypeDto } from '@treasury/api/channel';
import BillPayService from '@treasury/domain/bill-pay/bill-pay.service';
import { NotificationService, TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-confirm-dialog';
import '@treasury/presentation/components/tm-footer';
import { InjectProperty } from '@treasury/utils';
import { html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import { BillPayPaymentDetailViewModel } from '../data/bill-pay-payment-detail-view-model';
import '../partials/read-only-bill-pay-payment-detail';

export const tagName = 'business-bill-pay-payment-detail-container';
@customElement(tagName)
export class BusinessBillPayDetailContainer extends TmBaseComponent {
    @InjectProperty()
    private declare billPayService: BillPayService;

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare navigationService: NavigationService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @state()
    loading = false;

    @state()
    payment!: BillPayPaymentDetailViewModel;

    @state()
    showStopPaymentPrompt = false;

    @state()
    showStopPaymentOptions = false;

    @state()
    paymentId = '';

    @state()
    paymentType: PaymentTypeDto = PaymentTypeDto.Scheduled;

    get buttonConfig() {
        if (!this.showStopPaymentOptions) {
            return [
                {
                    text: 'Stop Payment',
                    error: true,
                    onClick: () => {
                        this.showStopPaymentPrompt = true;
                        this.showStopPaymentOptions = true;
                    },
                },
            ];
        }
        return [
            {
                text: 'Stop Series Immediately',
                error: true,
                onClick: () => {
                    this.stopPayment({ immediately: true });
                },
            },
            {
                text: 'Stop After Next Payment',
                error: true,
                onClick: () => {
                    this.stopPayment({ afterNext: true });
                },
            },
            {
                text: 'Skip Next Payment',
                error: true,
                onClick: () => {
                    this.stopPayment({ skipNext: true });
                },
            },
            {
                text: 'Cancel',
                onClick: () => {
                    this.showStopPaymentOptions = false;
                },
            },
        ];
    }

    async firstUpdated() {
        this.headerService.configure({
            title: 'Payment Detail',
            backAction: () => this.navigationService.navigate(`/business-bill-pay`),
        });
        const { params } = await this.navigationService.getRouteData<{
            type: PaymentTypeDto;
            id: string;
        }>();
        this.paymentId = params.id;
        this.paymentType = params.type;
        this.fetchPayment();
    }

    async fetchPayment() {
        this.loading = true;
        try {
            const payment = await this.billPayService.getBillPayment(
                this.paymentType,
                this.paymentId
            );
            this.payment = new BillPayPaymentDetailViewModel(payment);
        } catch (err) {
            if (err instanceof TmApiError) {
                this.notificationService.renderError(err);
            } else {
                this.notificationService.renderError('Failed to get user profile.');
                console.error(err);
            }
        } finally {
            this.loading = false;
        }
    }

    async stopPayment({
        immediately,
        afterNext,
        skipNext,
    }: {
        immediately?: boolean;
        afterNext?: boolean;
        skipNext?: boolean;
    }) {
        this.loading = true;
        try {
            await this.billPayService.updateBillPayment({
                paymentId: this.paymentId,
                deleteRecurrence: afterNext,
                delete: immediately,
                skipFuturePayment: skipNext,
                futurePaymentId: this.payment.futurePayments?.[0]?.id,
            });
            this.notificationService.renderSuccess('Payment stopped successfully');
            if (immediately) {
                this.navigationService.navigate('/business-bill-pay');
            } else {
                this.showStopPaymentOptions = false;
                this.fetchPayment();
            }
        } catch (err) {
            if (err instanceof TmApiError) {
                this.notificationService.renderError(err);
            } else {
                this.notificationService.renderError('Failed to stop payment.');
                console.error(err);
            }
        } finally {
            this.loading = false;
        }
    }

    renderLoader() {
        if (!this.loading) return nothing;
        return html`<tm-blocking-loader card></tm-blocking-loader>`;
    }

    renderFooter() {
        if (!this.payment) return nothing;
        if (!this.payment.recurrence || this.payment.recurrence === 'One Time') return nothing;
        return html`<tm-footer class="fixed bottom-0 w-full" .buttonConfig=${this.buttonConfig}></tm-footer>
        </tm-footer>`;
    }

    renderDetails() {
        if (!this.payment) return nothing;
        if (this.loading) return nothing;
        return html`<bill-pay-read-only-payment-detail
            .payment=${this.payment}
        ></bill-pay-read-only-payment-detail>`;
    }

    render() {
        return html`${this.renderDetails()}${this.renderFooter()}${this.renderLoader()}`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: BusinessBillPayDetailContainer;
    }
}
