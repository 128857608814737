// cspell:words Actvity
/* eslint-disable max-classes-per-file */
/* eslint-disable no-useless-constructor */
import { EntitlementDto, getEntitlementName } from './entitlement.dto';

/**
 * Enumeration of all feature flags the application uses.
 */
export enum Feature {
    BrandTheme = 'Feature.BrandTheme',
    IssuedItemsOdi = 'Feature.Arp.IssuedItemsViaOdi',
    IssuedItemsVoids = 'Feature.AccountRecon.IssuedItemVoids',
    AchNotificationOfChangeNacha = 'Feature.ACH.NotificationOfChange.Nacha',
    AchReturnReviewNacha = 'Feature.ACH.Return.Nacha',
    WireSftpEnabled = 'Feature.Wire.SFTP.DataTransmissions',
    IsPwaBetaBank = 'IsPwaBetaBank',
    ChannelPushNotifications = 'Feature.Notifications.PushNotifications.5652',
    BOPushNotifications = 'Feature.Notifications.PushNotifications.6772',
    LitDomesticAch = 'Feature.ACH.DomesticPayments',
    IATInternationalACHPaymentsRemoveCountryCodes = 'Feature.ACH.IAT.CountryCodeRemoval.TM8184',
    ACHNachaEffectiveDate = 'Feature.ACH.Nacha.EffectiveDate.TM4621',
    PaymentsPendingProcess = 'Feature.ACH.PopulateDuplicatePreventionId.TM4392',
    NewBoWireDetail = 'Feature.Wire.NewWireDetail',
    WirePaymentActivityTotals = 'Feature.Wire.WirePaymentActvityTotals.TM2925',
    Analytics = 'MixPanelAnalytics',
    UisEnabled = 'Feature.Authentication.UIS',
    BoUserSearch = 'Feature.BackOffice.Uis.UserSearch',
    MessageCenterEnhancement = 'Feature.MessageCenterEnhancement',
    CheckExceptionV2 = 'Feature.PositivePay.CheckExceptionV2',
}

/**
 * The shape of the data specified in the local `feature-flags.json` file.
 *
 * The key is the feature name.
 * The value is a binary on/off boolean or a whitelist of FI IDs to enable it for.
 */
export type LocalFeatureJson = Record<Feature, boolean | string[]>;

export interface TmFeatureFlag {
    readonly enabled: boolean;
    readonly name: Feature;
}

/**
 * Represents the state of a Treasury Management BSL entitlement-based feature.
 */
export class EntitlementFeatureFlag implements TmFeatureFlag {
    constructor(
        private dto: EntitlementDto,
        public readonly enabled = true
    ) {}

    public readonly name = getEntitlementName(this.dto) as Feature;
}

/**
 * Represents the state of a Treasury Management local UI-defined feature.
 */
export class LocalFeatureFlag implements TmFeatureFlag {
    constructor(
        public readonly name: Feature,
        public readonly enabled: boolean
    ) {}
}
