import { WireModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';

export class PendingWire extends DomainEntity<WireModelDto> {
    constructor(wire: WireModelDto) {
        super(wire);
    }

    public createDefault(): this {
        throw new Error('Method not implemented.');
    }

    get payment() {
        return this.dto;
    }

    get paymentId() {
        return this.dto.id;
    }

    get beneficiaryName() {
        return this.dto.beneficiary?.name;
    }

    get debitAccount() {
        return this.dto.debitAccount.accountDisplayLabel;
    }

    get amount() {
        return this.dto.amount;
    }
}
