import { camelCaseToTitle } from '@treasury/utils';
import { LabeledListItem } from './labeledListItem';

/**
 * @param {Object} source The object that contains the values for the list
 * @param {Array} keys A list of keys from the source object which should be included in the list
 * @param {(Object|Function|undefined)} labelOverrides
 * If provided, this overrides the default computed labels for certain keys.
 *
 * If passed as an object this should be a map (not `Map`) of source object keys to their desired label.
 * Any keys not found in the map will fallback to their computed label.
 *
 * If passed as a function this should receive a source object key and return the desired label for that key.
 * Any keys which cause this function to return `undefined` will fallback to their computed label.
 */
export class LabeledList<T, K extends (keyof T)[] = (keyof T)[]> {
    constructor(
        private source: T,
        private keys: K,
        private overrideLabels?:
            | ((k: K[number]) => undefined | string)
            | Partial<{ [K in keyof T]: string }>,
        private clickEventNames?:
            | ((k: K[number]) => undefined | string)
            | Partial<{ [K in keyof T]: string }>
    ) {}

    private formatValue(value: any) {
        if (Array.isArray(value)) {
            if (value.every(i => !i)) return '';
            return value.map(i => (i ? i.toString() : ''));
        } else {
            return value?.toString() ?? '';
        }
    }

    public items = this.keys.map(k => {
        const label =
            typeof this.overrideLabels === 'object'
                ? this.overrideLabels[k] ?? camelCaseToTitle(k.toString())
                : this.overrideLabels?.(k) ?? camelCaseToTitle(k.toString());
        const value = this.formatValue(this.source[k]);
        const clickEventName =
            typeof this.clickEventNames === 'object'
                ? this.clickEventNames[k]
                : this.clickEventNames?.(k);
        return new LabeledListItem(label, value, clickEventName);
    });
}
