import { ConfigurationService } from '@treasury/core/config';
import { FdlFieldDefinitions, FieldType, Record } from '@treasury/FDL';
import '@treasury/omega/components/omega-field.js';
import { boolean, string } from '@treasury/policy/primitives';
import { TmBaseComponent } from '@treasury/presentation';
import { InjectProperty, Nullable } from '@treasury/utils';
import { css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';

interface fields {
    companyId: string;
    loginId: string;
    institution: string;
    password?: string;
    useRefreshTokens: boolean;
    code: string;
}

export const tagName = 'user-verification';
@customElement(tagName)
export class UserVerification extends TmBaseComponent {
    @InjectProperty()
    private declare readonly config: ConfigurationService;

    @state()
    private record = this.makeRecord();

    @state()
    private formActionsDisabled = false;

    @state()
    private loading = false;

    private fields: FdlFieldDefinitions<Nullable<fields>> = {
        companyId: string.with
            .label('Company ID')
            .and.placeholder('Enter Company ID')
            .thatHas.maxLength(25)
            .and.minLength(1)
            .thatIs.required() as FieldType<string | null>,
        loginId: string.with
            .label('Login ID')
            .and.placeholder('Enter Login ID')
            .thatHas.maxLength(25)
            .and.minLength(1)
            .thatIs.required() as FieldType<string | null>,
        institution: string as FieldType<string | null>,
        password: string as FieldType<string | null | undefined>,
        useRefreshTokens: boolean as FieldType<boolean | null>,
        code: string as FieldType<string | null>,
    };

    get resetCode() {
        const codeParameter = new URLSearchParams(window.location.search).get('code');
        if (!codeParameter) return '';
        return decodeURIComponent(codeParameter);
    }

    private makeRecord() {
        return new Record(this.fields, {
            companyId: null,
            loginId: null,
            code: this.resetCode,
            useRefreshTokens: false,
            institution: this.config.institutionId,
        });
    }

    connectedCallback() {
        super.connectedCallback();
        this.formActionsDisabled = true;
        this.record = new Record(this.fields, {
            companyId: null,
            loginId: null,
            code: this.resetCode,
            useRefreshTokens: false,
            institution: this.config.institutionId,
        });
        this.listenTo(this.record, 'change', () => {
            this.isFormInvalid();
        });
    }

    submitUser() {
        this.dispatchEvent(
            new CustomEvent('submit', {
                detail: { formValues: this.record.values, record: this.record },
            })
        );
    }

    reset() {
        this.record.reset();
    }

    isFormInvalid() {
        this.formActionsDisabled = !this.record.isValid() || !this.record.hasRequiredValues();
    }

    render() {
        return html`
            ${this.renderAlert()}
            <summary>
                To verify your identity and process with a password change, please enter your
                Company ID and Login ID and provide the answer(s) for your security question(s).
            </summary>
            <form>
                <omega-field field="companyId" .record=${this.record}></omega-field>
                <omega-field field="loginId" .record=${this.record}></omega-field>
            </form>
            <footer>
                <omega-button
                    id="log-in"
                    type="primary"
                    ?loading=${this.loading}
                    ?disabled=${this.formActionsDisabled}
                    @click=${this.submitUser}
                >
                    Submit
                </omega-button>
                <omega-button
                    id="reset"
                    type="link"
                    ?disabled=${this.formActionsDisabled}
                    @click=${this.reset}
                >
                    Reset
                </omega-button>
            </footer>
        `;
    }

    static get styles() {
        return [
            css`
                footer {
                    padding: 4px 15px;
                    border-top: 1px solid #ccc;
                }
                summary,
                form {
                    padding: 15px;
                }
                omega-field {
                    --omega-field-label-width: 100px;
                    --omega-field-control-width: 250px;
                    max-width: 360px;
                    margin-bottom: 15px;
                }
                omega-field:last-of-type {
                    margin-bottom: 0;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserVerification;
    }
}
