/* eslint-disable no-useless-constructor */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
// eslint-disable-next-line import/no-named-as-default
import {
    ApprovalListRequestModelDto,
    UserDto,
    UserTimeAccess2Dto,
    UsersClient,
} from '@treasury/api/channel';
import { RequestParams } from '@treasury/api/channel/generated/http-client';
import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils';
import UserRequests from '../../requests/users/users.js';

type ApprovalListRequestModel = Partial<ApprovalListRequestModelDto>;

@Injectable()
export class UsersService {
    constructor(private usersClient: UsersClient) {}

    public async getApprovers(
        request: ApprovalListRequestModelDto | null,
        params: RequestParams = {}
    ) {
        return this.usersClient.usersGetApprovers(request, params);
    }

    public async searchUsersNew(
        activeUsersOnly: boolean,
        enrolledUsersOnly: boolean,
        includeRoles: boolean
    ) {
        const response = await this.usersClient.usersSearchUsers({
            activeUsersOnly,
            enrolledUsersOnly,
            includeRoles,
        });

        return response.data;
    }

    static async fetchCurrentUser() {
        return UserRequests.fetchCurrentUser();
    }

    static async fetchApprovers(body: ApprovalListRequestModel) {
        return UserRequests.fetchApprovers(body);
    }

    static async fetchAvailableApprovers(body: ApprovalListRequestModel) {
        return UserRequests.fetchAvailableApprovers(body);
    }

    static async fetchAvailableAndCompletedApprovers(body: ApprovalListRequestModel) {
        return UserRequests.fetchAvailableAndCompletedApprovers(body);
    }

    static async getPendingApprovalUsers() {
        const usersList: UserDto[] = await UserRequests.getAllUsers();
        return usersList.filter(user => user.publishState === 'Pending');
    }

    static async approveOrRejectUser(user: UserDto, action: string, comments: string) {
        return (await TmHttpClient.getInstance()).request(
            `users/${user.id}/${action}?comments=${comments}`,
            {
                method: 'PUT',
            }
        );
    }

    static async searchUsers(filterPredicate: (user: UserDto) => boolean) {
        return UserRequests.searchUsers().then(result => {
            if (Array.isArray(result)) {
                return result.filter(user => filterPredicate(user));
            }
            return result;
        });
    }

    static async getCurrentUserTimeAccess(): Promise<UserTimeAccess2Dto> {
        return UserRequests.getCurrentUserTimeAccess();
    }

    static async getUserHeader(id: string) {
        return UserRequests.getUserHeader(id);
    }

    static async getUserInformation(id: string) {
        return UserRequests.getUserInformation(id);
    }

    static async getUserProductFeatures(id: string) {
        return UserRequests.getUserProductFeatures(id);
    }

    static async getUserProductFeatureDetail(id: string, featureName: string) {
        return UserRequests.getUserProductFeatureDetail(id, featureName);
    }
}
