/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    AchFilterReportDownloadRequestApiModelDto,
    AchFiltersSearchResponseModelDto,
    ErrorResponseModelDto,
} from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class AchFilterReportDownloadClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AchFilterReportDownload
     * @name AchFilterReportDownloadAchFilterReportDownload
     * @summary Downloads a report of ACH filters using provided search criteria.
     * @request POST:/achFilterReportDownload
     */
    achFilterReportDownloadAchFilterReportDownload = (
        request: AchFilterReportDownloadRequestApiModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<AchFiltersSearchResponseModelDto, ErrorResponseModelDto>({
            path: `/achFilterReportDownload`,
            method: 'POST',
            body: request,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchFilterReportDownload
     * @name AchFilterReportDownloadAchFilterReportDownload2
     * @summary Downloads a report of ACH filters using provided search criteria.
     * @request POST:/achFilterReportDownload/achFilterReportDownload
     */
    achFilterReportDownloadAchFilterReportDownload2 = (
        request: AchFilterReportDownloadRequestApiModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<AchFiltersSearchResponseModelDto, ErrorResponseModelDto>({
            path: `/achFilterReportDownload/achFilterReportDownload`,
            method: 'POST',
            body: request,
            format: 'json',
            ...params,
        });
}
