import { Injectable } from '@treasury/utils/dependency-injection';
import { dateDistance } from '@treasury/utils/functions';
/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';
import { AccountDto, AccountsGroupDto } from '../../types';
import { Notification, notificationsDto } from '../../types/notifications.dto';
import { PaymentTypes } from '../../types/payment';
import { CutoffTimesDto } from '../../types/processing-time.dto';

const dollarUSLocale = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

@Injectable()
export class DashboardService {
    // eslint-disable-next-line no-useless-constructor
    constructor(private readonly http: TmHttpClient) {}

    async getMyAccounts() {
        const response = await this.http.request<AccountsGroupDto[]>(`dashboard/myaccounts`, {
            method: 'GET',
        });

        response.map((accountGroup: AccountsGroupDto) =>
            accountGroup.accounts.forEach((account: AccountDto) => {
                account.currentBalanceFormatted = dollarUSLocale.format(account.currentBalance);
                account.availableBalanceFormatted = dollarUSLocale.format(account.availableBalance);
                account.collectedBalanceFormatted = dollarUSLocale.format(account.collectedBalance);
            })
        );
        return response;
    }

    getPendingApprovalPaymentsByType(type: PaymentTypes) {
        return this.http.request(`dashboard/paymentPendingApproval?type=${type}`, {
            method: 'GET',
        });
    }

    // action types:
    // Approve Transfer
    // Approve Loan Payment
    // Approve WIRE Payment
    // Approve ACH Payment
    approveOrRejectPayment(items: any[], type: string) {
        let actionType;
        switch (type) {
            case 'transfer':
                actionType = 'Approve Transfer';
                break;
            case 'loanpayment':
                actionType = 'Approve Loan Payment';
                break;
            case 'wire':
                actionType = 'Approve WIRE Payment';
                break;
            case 'ach':
                actionType = 'Approve ACH Payment';
                break;
            default:
                break;
        }
        const body = {
            batch: items.map((item: any) => ({ key: item.id, value: item.selectedState })),
            securityMessage: {
                actionType,
                status: null,
                methodUsed: null,
                hasAlternate: false,
                errorCode: null,
                message: null,
                oneTimePassword: null,
            },
        };
        return this.http.request(`dashboard/batch?type=${type}`, {
            method: 'POST',
            body,
        });
    }

    logNotificationActivity() {
        return this.http.request(`navigations/auditUserActivity/notifications`, {
            method: 'POST',
        });
    }

    async markNotificationsAsRead(notificationIds: { id: number }[]) {
        return this.http.request(`notifications/setRead?value=true`, {
            method: 'PUT',
            body: notificationIds,
        });
    }

    async archiveNotifications(notificationIds: { id: number }[]) {
        return this.http.request(`notifications/archive`, {
            method: 'PUT',
            body: notificationIds,
        });
    }

    async getRecentNotifications() {
        const response = await this.http.request<notificationsDto>(
            `notifications/recent?html=true`,
            {
                method: 'GET',
            }
        );

        return {
            notifications: response.topMessages.map((notification: Notification) => ({
                ...notification,
                // eslint-disable-next-line @treasury/no-date
                createdOn: `${dateDistance(new Date(notification.createdOn), new Date())} ago`,
            })),
            count: response.totalUnreadCount,
        };
    }

    async getCutoffTimes() {
        const timeConversion = (time: any) => {
            let formattedTime = time;
            formattedTime = formattedTime
                .toString()
                .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [formattedTime];
            if (formattedTime.length > 1) {
                formattedTime = formattedTime.slice(1);
                formattedTime[4] = +formattedTime[0] < 12 ? ' AM' : ' PM';
                formattedTime[0] = +formattedTime[0] % 12 || 12;
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const secondsRemoved = formattedTime.splice(3, 1);
            return formattedTime.join('');
        };
        const response = await this.http.request<CutoffTimesDto>(`companyaccounts/cutoffTimes`, {
            method: 'GET',
        });
        const cutoffTimes = response.processingCutoffs.map(
            (cutoff: { productType: string; cutoffTime: string }) => ({
                cutoffTime: timeConversion(cutoff.cutoffTime),
                label: cutoff.productType,
            })
        );

        return {
            cutoffTimes,
            timeZone: response.timeZone,
        };
    }
}
