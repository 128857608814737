/* eslint-disable lit/no-native-attributes */
import { NavigationService } from '@treasury/core/navigation';
import { AuthenticationService } from '@treasury/domain/services/authentication';
import { LogoutManager } from '@treasury/domain/services/logout';
import { openDialog } from '@treasury/omega';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-checkbox';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import TermsAndConditionsClient from './client';
import { TermsAndConditions } from './terms-conditions.types';

export const tagName = 'terms-and-conditions';
@customElement(tagName)
// eslint-disable-next-line @treasury/filename-match-export
export class TermsAndConditionsContainer extends TmBaseComponent {
    @property({ type: Boolean })
    loading = true;

    @property({ type: Object })
    termsAndConditions: TermsAndConditions = {} as TermsAndConditions;

    @property({ type: Boolean })
    showAcceptanceDate = false;

    @state()
    title = `Terms & Conditions`;

    @state()
    client = new TermsAndConditionsClient();

    @state()
    isAccepted = false;

    @InjectProperty()
    private declare readonly authService: AuthenticationService;

    @InjectProperty()
    private declare readonly logoutManager: LogoutManager;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    async connectedCallback() {
        super.connectedCallback();
        const result: TermsAndConditions = await this.client.getTermsAndConditions();
        const needsToAgree =
            !result.lastAcceptedDate ||
            new Date(result.lastAcceptedDate) < new Date(result.updatedDate);
        if (result && result.text && needsToAgree) {
            this.termsAndConditions = result;
            // UX rule:
            // Regardless of the current state,
            // any time the user is sent to this view, they must Agree again to proceed.
            this.isAccepted = false;
            this.loading = false;
        } else {
            // Make sure that the user can get to the dashboard if there are no terms and conditions.
            sessionStorage.setItem('termsAndConditions', 'true');
            this.authService.authenticate();
            this.navService.navigate(`/dashboard`);
        }
    }

    handleDownload(e: CustomEvent) {
        this.client.downloadTermsAndConditions(e.detail.downloadType);
    }

    handlePrint() {
        window.print();
    }

    async handleAcceptClick() {
        await this.client.acceptTermsAndConditions();
        sessionStorage.setItem('termsAndConditions', 'true');

        // TODO: Handle save error from the API?
        this.navService.navigate(`/dashboard`);
    }

    formattedLastAcceptedDate() {
        // TODO: implement this in fi-datetime and use it here.
        const date = new Date(this.termsAndConditions.lastAcceptedDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const dayOfMonth = String(date.getDate()).padStart(2, '0');
        return `${month}/${dayOfMonth}/${year}`;
    }

    async handleCancelClick() {
        const response = await openDialog(document.createElement('omega-dialog'), {
            title: 'Confirm Cancel',
            content: html`<div style="margin: 20px">
                <p>
                    If you do not accept the Terms and Conditions, <br />
                    you will not be able to proceed and <br />
                    your session will be terminated.
                </p>
            </div>`,
            buttons: [
                { type: 'primary', label: `OK`, result: 'ok' },
                { type: 'secondary', label: `Return`, result: 'cancel' },
            ],
        });

        if (response === 'ok') {
            this.client.declineTermsAndConditions();
            await this.logoutManager.logOut('Forced');
            this.navService.navigate(`/login`);
        }
    }

    renderLastAcceptedOn() {
        if (!this.showAcceptanceDate) return ``;
        if (!this.termsAndConditions?.lastAcceptedDate) return ``;

        return html` <div class="body-coda">
            <strong>Accepted On: ${this.formattedLastAcceptedDate()} </strong>
        </div>`;
    }

    renderTermsAndConditions() {
        return html`
            <div>
                <div>
                    <omega-action-bar
                        .title="${this.title}"
                        .downloadOptions=${['PDF']}
                        @download="${this.handleDownload}"
                        @print="${this.handlePrint}"
                    ></omega-action-bar>
                </div>
                <div class="body-text">${unsafeHTML(this.termsAndConditions.text)}</div>
                ${this.renderLastAcceptedOn()}
            </div>
        `;
    }

    render() {
        if (this.loading) {
            return ``;
        }

        return html`<form class="view">
            ${this.renderTermsAndConditions()}
            <div class="agreement-block">
                <div>
                    <tm-checkbox
                        label="I Agree"
                        ?checked=${this.isAccepted}
                        @checked-changed=${(e: CustomEvent) => {
                            this.isAccepted = e.detail.value;
                        }}
                    >
                    </tm-checkbox>
                </div>
                <tm-button
                    importance="primary"
                    @click=${this.handleAcceptClick}
                    ?disabled=${!this.isAccepted}
                >
                    Accept
                </tm-button>
                <tm-button @click=${this.handleCancelClick}> Cancel </tm-button>
            </div>
        </form>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    height: 100%;
                    overflow: auto;
                }
                .view {
                    padding: 15px;
                    color: var(--primary-text-color);
                    background-color: var(--primary-background);
                }
                .agreement-block {
                    padding-left: 24px;
                }
                .agreement-control {
                    margin: 8px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        // eslint-disable-next-line @typescript-eslint/no-empty-interface
        [tagName]: TermsAndConditionsContainer;
    }
}
