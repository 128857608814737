import { DateFormat } from './date-picker.types';

export class DateToTextFormatter {
    constructor(date: Date, format: DateFormat) {
        this.date = date;
        this.format = format;
    }

    date: Date;

    format: DateFormat;

    twoDigits(n: number) {
        if (n < 10) {
            return `0${n}`;
        }
        return n.toString();
    }

    dateToAmerican() {
        return `${this.twoDigits(this.date.getMonth() + 1)}/${this.twoDigits(
            this.date.getDate()
        )}/${this.date.getFullYear()}`;
    }

    dateToIso() {
        return `${this.date.getFullYear()}-${this.twoDigits(
            this.date.getMonth() + 1
        )}-${this.twoDigits(this.date.getDate())}`;
    }

    public toString() {
        if (this.format === DateFormat.ISO) {
            return this.dateToIso();
        }
        return this.dateToAmerican();
    }
}
