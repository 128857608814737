export class Selectable<T> {
    constructor(public readonly value: T) {}

    private _selected = false;

    get selected() {
        return this._selected;
    }

    set selected(value: boolean) {
        this._selected = value;
    }
}
