export const achPaymentStatusItems = [
    {
        label: 'All',
        value: 'All',
    },
    {
        label: 'Pending Approval',
        value: 'Pending Approval',
    },
    {
        label: 'Pending Process',
        value: 'Pending Process',
    },
    {
        label: 'Approval Rejected',
        value: 'Approval Rejected',
    },
    {
        label: 'Initiated',
        value: 'Initiated',
    },
    {
        label: 'Uninitiated',
        value: 'UnInitiated',
    },
    {
        label: 'Cancelled',
        value: 'Cancelled',
    },
    {
        label: 'Failed',
        value: 'Failed',
    },
    {
        label: 'Expired',
        value: 'Expired',
    },
    {
        label: 'Scheduled',
        value: 'Scheduled',
    },
];
