import { AuthenticationService } from '@treasury/domain/services/authentication';
import { TmBaseComponent } from '@treasury/presentation';
import { InjectProperty } from '@treasury/utils';
import { css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import './login-container';
import './login-error';

export const tagName = 'uis-container';
@customElement(tagName)
export class UisContainer extends TmBaseComponent {
    @InjectProperty()
    private declare authService: AuthenticationService;

    @state()
    private isUisEnabled = true;

    @state()
    private uisFailure = false;

    protected async firstUpdated(): Promise<void> {
        try {
            const resp = await this.authService.uisMetadataPromise;
            if (!resp) throw new Error('Error getting UIS metadata');
            const { isUisEnabled, uisLoginUrl } = resp;
            this.isUisEnabled = isUisEnabled;
            if (isUisEnabled && uisLoginUrl) {
                window.location.href = uisLoginUrl;
            }
        } catch (e) {
            console.error(e);
            this.uisFailure = true;
        }
    }

    protected render() {
        if (this.uisFailure) return html`<login-error></login-error>`;
        return this.isUisEnabled ? nothing : html`<login-container></login-container>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                    height: 100%;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UisContainer;
    }
}
