import { CheckException, ExceptionDecisionStatus } from '@treasury/domain/arp';
import { TmBaseComponent } from '@treasury/presentation';
import { PropertyValueMap, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/jhd-approval-toggle';
import '../../components/jhd-icon';
import { JhaApprovalToggleStatus } from '../types';

export const tagName = 'ach-decision-toggle';
@customElement(tagName)
export class AchDecisionToggle extends TmBaseComponent {
    @property({ type: Object })
    public exception!: CheckException;

    @property()
    readonly = false;

    @state()
    private returnColor = '#000';

    @state()
    private payColor = '#000';

    @state()
    private checkColor = '#000';

    @state()
    private decision: JhaApprovalToggleStatus = JhaApprovalToggleStatus.NA;

    firstUpdated() {
        this.determineColor();
    }

    protected updated(
        _changedProperties: PropertyValueMap<AchDecisionToggle> | Map<PropertyKey, unknown>
    ): void {
        if (_changedProperties.has('exception')) {
            if (this.exception.decisionStatus === ExceptionDecisionStatus.Pay) {
                this._payException();
            } else if (this.exception.decisionStatus === ExceptionDecisionStatus.Return) {
                this._returnException();
            } else {
                this._clearDecision();
            }
        }
    }

    determineColor() {
        this.returnColor =
            this.exception.decisionStatus === ExceptionDecisionStatus.Return ? '#e03b2f' : '#000';
        this.payColor =
            this.exception.decisionStatus === ExceptionDecisionStatus.Pay ? '#189100' : '#000';
        this.checkColor = this.exception.acknowledged ? '#041dbf' : '#000';
    }

    payException() {
        this.exception.pay();
        this._payException();
        this.dispatchEvent(new CustomEvent<CheckException>('change', { detail: this.exception }));
    }

    private _payException() {
        this.decision = JhaApprovalToggleStatus.Approved;
        this.determineColor();
    }

    returnException() {
        this.exception.return();
        this._returnException();
        this.dispatchEvent(new CustomEvent<CheckException>('change', { detail: this.exception }));
    }

    private _returnException() {
        this.decision = JhaApprovalToggleStatus.Rejected;
        this.determineColor();
    }

    clearDecision() {
        this.exception.clearDecision();
        this._clearDecision();
        this.dispatchEvent(new CustomEvent<CheckException>('change', { detail: this.exception }));
    }

    private _clearDecision() {
        this.decision = JhaApprovalToggleStatus.NA;
        this.determineColor();
    }

    acknowledgeException() {
        this.exception.acknowledged = !this.exception.acknowledged;
        this.determineColor();
    }

    render() {
        if (this.readonly) {
            return html`<jhd-approval-toggle
                .selectedState=${this.decision}
                readonly
            ></jhd-approval-toggle>`;
        }

        return html`<jhd-approval-toggle
            .selectedState=${this.decision}
            @change=${(changeEvent: CustomEvent<JhaApprovalToggleStatus>) => {
                const newStatus = changeEvent.detail;
                if (newStatus === this.decision) return;
                if (newStatus === JhaApprovalToggleStatus.Approved) {
                    this.payException();
                } else if (newStatus === JhaApprovalToggleStatus.Rejected) {
                    this.returnException();
                } else {
                    this.clearDecision();
                }
                this.dispatchEvent(new Event('change'));
            }}
        ></jhd-approval-toggle>`;
    }

    static get styles() {
        return [
            css`
                .decision-icons {
                    display: flex;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchDecisionToggle;
    }
}
