import { ConfigurationService } from '@treasury/core/config';
import { NavigationService } from '@treasury/core/navigation';
import { Injectable, WindowService } from '@treasury/utils';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Router } from '@vaadin/router';
import { routes } from '../../routes';

@Injectable()
export class PwaNavigationService extends NavigationService {
    constructor(window: WindowService, config: ConfigurationService) {
        super(window);
        this.setRouteBase(`pwa/${config.institutionId}`);
        this.router.setRoutes(routes);
    }

    private readonly router = new Router();

    public async setOutlet(outlet: Node | (() => Promise<Node>)) {
        await super.setOutlet(outlet);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.router.setOutlet(this.outlet!);
    }

    protected async performNavigation(route: string) {
        const success = Router.go(route);

        if (!success) {
            throw new Error(
                'Could not navigate. There was no Vaadin router available to handle the route transition.'
            );
        }

        await this.router.ready;
    }

    protected getActiveRoute() {
        return Promise.resolve(this.router.location.pathname);
    }

    protected getUrlParams() {
        return Promise.resolve(this.router.location.params);
    }
}
