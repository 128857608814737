import { GetUserProductEntitlementsModelDto, ProductAccountDto } from '@treasury/api/channel';
import { UserClaimVm } from './user-claim-view-model';

export interface ProductAccountVm extends Omit<ProductAccountDto, 'userAccountClaims'> {
    userAccountClaims: UserClaimVm[];
}

export class UserEntitlementsVm {
    constructor(readonly value: GetUserProductEntitlementsModelDto) {}

    get permissions() {
        return this.value.userProductClaims?.map(c => new UserClaimVm(c)) ?? [];
    }

    get accounts(): ProductAccountVm[] {
        if (!this.value.accounts) return [];
        return this.value.accounts.map(account => ({
            ...account,
            userAccountClaims: account.userAccountClaims?.map(c => new UserClaimVm(c)) ?? [],
        }));
    }
}
