// eslint-disable-next-line import/no-extraneous-dependencies
import { TmBaseComponent } from '@treasury/presentation';
import ApexCharts from 'apexcharts';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export const tagName = 'total-cash-position-graph';
@customElement(tagName)
export class TotalCashPositionGraph extends TmBaseComponent {
    @property({ type: Object })
    private chartOptions: any = {};

    @property({ type: Object })
    private chart!: ApexCharts;

    get latestCashPosition() {
        if (!Object.keys(this.chartOptions).length) return 0;
        return this.chartOptions?.series[0].data[this.chartOptions.series[0].data.length - 1];
    }

    async firstUpdated() {
        // TODO: get data for chart
        // TODO: data transform for chart API
        this.chartOptions = {
            series: [
                {
                    name: 'Cash Position',
                    data: [31, 40, 28, 51, 47, 93, 101.14],
                },
            ],
            chart: {
                redrawOnParentResize: true,
                height: 350,
                type: 'area',
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            grid: {
                xaxis: {
                    lines: {
                        show: false,
                    },
                },
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
            },
            xaxis: {
                type: 'datetime',
                labels: {},
                categories: [
                    '2022-09-19T00:00:00.000Z',
                    '2022-09-20T01:30:00.000Z',
                    '2022-09-21T02:30:00.000Z',
                    '2022-09-22T03:30:00.000Z',
                    '2022-09-23T04:30:00.000Z',
                    '2022-09-24T05:30:00.000Z',
                    '2022-09-25T06:30:00.000Z',
                ],
            },
            yaxis: {
                labels: {
                    formatter: (value: number) => `$ ${value}`,
                },
            },

            tooltip: {
                theme: 'dark',
            },
            colors: ['#fff'],
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.1,
                    opacityTo: 0.7,
                    stops: [0, 70],
                },
            },
        };
        this.chart = new ApexCharts(this.shadowRoot?.querySelector('.chart'), this.chartOptions);
        this.chart.render();
    }

    render() {
        return html`<div class="chart-canvas">
            <div class="chart-header">
                <p>Total Cash Position</p>
                <h2>${this.latestCashPosition}</h2>
            </div>
            <div class="chart" .chart=${this.chart}></div>
        </div> `;
    }

    static get styles() {
        return css`
            :host {
                flex: 1;
                display: flex;
                padding-top: 10px;
                padding-bottom: 100px;
                background: var(--background-gradient);
                box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.25);
            }
            .chart-canvas {
                width: 100%;
                height: 100%;
                display: block;
                overflow: hidden;
            }
            .chart-header {
                padding: 10px;
                padding-top: 0%;
            }

            .chart-header p {
                font-size: 18px;
                font-weight: 200;
            }
            .chart-header p,
            h2 {
                color: #fff;
                margin: 5px 0;
            }
            text {
                fill: #fff;
            }
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TotalCashPositionGraph;
    }
}
