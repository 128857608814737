import { TmHttpClient } from '@treasury/core/http';
import { OneToOneTransfer } from './one-to-one-transfer.dto';
import { TransferAccountDto } from './transfer-account.dto';
import { TransferPaymentDto } from './transfer-payment.dto';

export const getTransferFromAccounts = async () =>
    (await TmHttpClient.getInstance()).request<TransferAccountDto[]>(
        `companyaccounts/GetForProductFeature?productFeatureType=InternalTransfer&reportType=create&type=From`,
        {
            method: 'GET',
        }
    );

export const getTransferToAccounts = async (oppositeAccountId: number) =>
    (await TmHttpClient.getInstance()).request<TransferAccountDto[]>(
        `companyaccounts/GetForProductFeature?isForLoanPayment=false&oppositeAccountId=${oppositeAccountId}&productFeatureType=InternalTransfer&reportType=create&type=To`,
        {
            method: 'GET',
        }
    );

export const createOneToOneTransfer = async (transfer: any) =>
    (await TmHttpClient.getInstance()).request<OneToOneTransfer>(`internalTransfers/?type=add`, {
        method: 'POST',
        body: transfer,
    });

export const createTransfer = async (transfer: any) =>
    (await TmHttpClient.getInstance()).request(`internalTransfers/?type=add`, {
        method: 'POST',
        body: transfer,
    });

export const getTransferPayment = async (id: string) =>
    (await TmHttpClient.getInstance()).request<TransferPaymentDto>(`internalTransfers/${id}`, {
        method: 'GET',
    });
