import { TmHttpConfig } from '@treasury/core/http';
import { exists } from '@treasury/utils';
import {
    ErrorType,
    hasSecurityMessage,
    isLockedOrSuccessful,
    isSecurityMessage,
    SecurityMessage,
    StatusType,
    WithSecurityMessage,
} from '../../channel/mappings/security';
import { AuthSecurityResponse } from './tm-http-client-mfa.types';

/**
 * Extract the security message contained in either the request options or body.
 */
export function getSecurityMessage(config: TmHttpConfig) {
    if (hasSecurityMessage(config)) {
        return config.securityMessage;
    }

    if (config.body && hasSecurityMessage(config.body)) {
        return config.body.securityMessage;
    }

    return undefined;
}

export function coerceSecurityMessage<T>(obj: SecurityMessage | WithSecurityMessage<T>) {
    return hasSecurityMessage(obj) ? obj.securityMessage : obj;
}

/**
 * Map a response from the API that is or contains a `SecurityMessage`
 * to the client side `AuthSecurityResponse` shape.
 */
export function mapResponse<T extends object>(
    response: (T & SecurityMessage) | WithSecurityMessage<T>,
    previousSecurityMessage?: SecurityMessage
): AuthSecurityResponse<T> {
    let securityResponse: WithSecurityMessage<T>;
    if (isSecurityMessage<T>(response)) {
        const { challengeMethodTypeId, status, errorCode, message, methodUsed, hasAlternate } =
            response;

        // augment response with a security message property
        securityResponse = {
            ...response,
            securityMessage: {
                challengeMethodTypeId,
                status,
                errorCode,
                message,
                methodUsed,
                hasAlternate,
            },
        };
    } else {
        securityResponse = response;
    }

    const { securityMessage } = securityResponse;

    // Hack: Currently failure codes are getting zeroed out by API
    // this line is to fix that issue until it is resolved.
    if (securityMessage.challengeMethodTypeId === 0 && exists(previousSecurityMessage)) {
        securityMessage.challengeMethodTypeId = previousSecurityMessage.challengeMethodTypeId;
    }

    const needsAuthentication = !isLockedOrSuccessful(securityMessage);
    const successful = securityMessage?.status === StatusType.Success;

    return {
        ...securityResponse,
        _auth: {
            isLocked: securityMessage?.errorCode === ErrorType.Locked,
            needsAuthentication,
            successful,
        },
    };
}
