import { AchService } from '@treasury/domain/ach/ach.service';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-currency-range';
import {
    CurrencyRange,
    CurrencyRangeType,
} from '@treasury/presentation/components/tm-currency-range';
import { mapCurrencyRangeToDto } from '@treasury/presentation/components/tm-currency-range.mappings';
import '@treasury/presentation/components/tm-date-range';
import { DateRange, DateRangeType } from '@treasury/presentation/components/tm-date-range';
import { mapDateRangeToDto } from '@treasury/presentation/components/tm-date-range.mappings';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-loader';
import '@treasury/presentation/components/tm-multi-select';
import { MultiSelectItem } from '@treasury/presentation/components/tm-multi-select';
import '@treasury/presentation/components/tm-select';
import { SelectItem } from '@treasury/presentation/components/tm-select';
import '@treasury/presentation/components/tm-text-field';
import { InjectProperty } from '@treasury/utils';
import { DeepReactive } from '@treasury/utils/lit-helpers';
import { add, sub } from 'date-fns';
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { achPaymentStatusItems } from '../data/ach-payment-status-items';
import { achTypeItems } from '../data/ach-type-items';

const defaultAmountRange: CurrencyRange = {
    rangeType: CurrencyRangeType.AllAmounts,
};

const defaultInitiatedDateRange: DateRange = {
    rangeType: DateRangeType.None,
};

const defaultEffectiveDateRange: DateRange = {
    rangeType: DateRangeType.DateRange,
    startDate: sub(new Date(), { weeks: 1 }),
    endDate: add(new Date(), { weeks: 1 }),
};

export const tagName = 'ach-payments-filter-sheet';
@customElement(tagName)
export class AchPaymentsFilterSheet extends TmBaseComponent {
    @InjectProperty()
    private declare achService: AchService;

    @property({ type: Boolean })
    public open = false;

    @state()
    private loading = true;

    @state()
    private achType: string = 'All';

    @state()
    private status: string = 'All';

    @state()
    private achCompanyItems: MultiSelectItem[] = [];

    @state()
    private achCompanyItemsSelected: MultiSelectItem[] = [];

    @state()
    private batchName: string = '';

    @state()
    private transactionId: string = '';

    @state()
    private secCodeItems: SelectItem[] = [];

    @state()
    private secCode: string = 'All';

    @state()
    private debitAmount: CurrencyRange = defaultAmountRange;

    @state()
    private creditAmount: CurrencyRange = defaultAmountRange;

    @state()
    private initiatedDateRange: DateRange = defaultInitiatedDateRange;

    @state()
    private effectiveDateRange: DateRange = defaultEffectiveDateRange;

    @DeepReactive()
    private validState = {
        achCompany: true,
        debitAmount: true,
        creditAmount: true,
        initiatedDate: true,
        effectiveDate: true,
    };

    async firstUpdated() {
        this.onApplyFilters();
        try {
            const achCompanies = await this.achService.getAchCompanies();
            this.achCompanyItems = achCompanies.map(achCompany => ({
                label: achCompany.companyName,
                value: achCompany,
            })) as MultiSelectItem[];
            this.achCompanyItemsSelected = this.achCompanyItems;

            const secCodes = await this.achService.getSecCodes();
            this.secCodeItems = secCodes.map((secCode: string) => ({
                label: secCode,
                value: secCode,
            }));
        } catch (err) {
            console.error(err);
        } finally {
            this.loading = false;
        }
    }

    private onResetForm() {
        this.achType = 'All';
        this.status = 'All';
        this.achCompanyItemsSelected = this.achCompanyItems;
        this.batchName = '';
        this.transactionId = '';
        this.secCode = 'All';
        this.debitAmount = { ...defaultAmountRange };
        this.creditAmount = { ...defaultAmountRange };
        this.initiatedDateRange = { ...defaultInitiatedDateRange };
        this.effectiveDateRange = { ...defaultEffectiveDateRange };
        this.requestUpdate();
    }

    private toDto() {
        const {
            operator: debitAmountType,
            fromAmount: debitAmountMin,
            toAmount: debitAmountMax,
            specificAmount: debitAmount,
        } = mapCurrencyRangeToDto(this.debitAmount, false);

        const {
            operator: creditAmountType,
            fromAmount: creditAmountMin,
            toAmount: creditAmountMax,
            specificAmount: creditAmount,
        } = mapCurrencyRangeToDto(this.creditAmount, false);

        const {
            operator: initiatedDateType,
            dateFrom: initiatedDateStart,
            dateTo: initiatedDateEnd,
            specificDate: initiatedDate,
        } = mapDateRangeToDto(this.initiatedDateRange, false);

        const {
            operator: effectiveDateType,
            dateFrom: effectiveDateStart,
            dateTo: effectiveDateEnd,
            specificDate: effectiveDate,
        } = mapDateRangeToDto(this.effectiveDateRange, false);

        return {
            achType: this.achType,
            status: this.status,
            achCompanyList: this.achCompanyItemsSelected.map((item: MultiSelectItem) =>
                item.value.toDto()
            ),
            batchName: this.batchName,
            transactionId: this.transactionId,
            secCode: this.secCode,
            debitAmountType,
            debitAmount,
            debitAmountMin,
            debitAmountMax,
            creditAmountType,
            creditAmount,
            creditAmountMin,
            creditAmountMax,
            initiatedDate,
            initiatedDateType,
            initiatedDateStart,
            initiatedDateEnd,
            effectiveDate,
            effectiveDateType,
            effectiveDateStart,
            effectiveDateEnd,
            isBasic: true,
        };
    }

    onApplyFilters() {
        this.dispatchEvent(
            new CustomEvent('applyFilters', {
                detail: this.toDto(),
            })
        );
        this.dispatchEvent(new CustomEvent('close'));
    }

    renderFilters() {
        if (this.loading) return html`<tm-loader card></tm-loader>`;
        return html`<form>
            <tm-select
                label="ACH Type"
                .items=${achTypeItems}
                .value=${this.achType}
                @value-changed=${(e: CustomEvent) => {
                    this.achType = e.detail.value;
                }}
            >
            </tm-select>
            <tm-select
                label="Status"
                .items=${achPaymentStatusItems}
                .value=${this.status}
                @value-changed=${(e: CustomEvent) => {
                    this.status = e.detail.value;
                }}
            >
            </tm-select>
            <tm-multi-select
                label="ACH Company"
                .allowSelectAll=${true}
                .allSelectedText=${'All ACH Companies'}
                .items=${this.achCompanyItems}
                .selectedItems=${[...this.achCompanyItemsSelected]}
                @selected-items-changed=${(e: CustomEvent) => {
                    this.achCompanyItemsSelected = e.detail;
                    this.validState.achCompany = !!this.achCompanyItemsSelected.length;
                }}
            >
            </tm-multi-select>
            <tm-text-field
                label="Batch Name"
                .value=${this.batchName}
                @value-changed=${(e: CustomEvent) => {
                    this.batchName = e.detail.value;
                }}
            ></tm-text-field>
            <tm-text-field
                label="Transaction ID"
                .value=${this.transactionId}
                @value-changed=${(e: CustomEvent) => {
                    this.transactionId = e.detail.value;
                }}
            ></tm-text-field>
            <tm-select
                label="SEC Code"
                .items=${this.secCodeItems}
                .value=${this.secCode}
                @value-changed=${(e: CustomEvent) => {
                    this.secCode = e.detail.value;
                }}
            >
            </tm-select>
            <tm-currency-range
                label="Debit Amount"
                .range=${this.debitAmount}
                @selection=${(e: CustomEvent) => {
                    this.validState.debitAmount = !!e.detail;
                    if (!!e.detail) {
                        this.debitAmount = e.detail;
                    }
                }}
            ></tm-currency-range>
            <tm-currency-range
                label="Credit Amount"
                .range=${this.creditAmount}
                @selection=${(e: CustomEvent) => {
                    this.validState.creditAmount = !!e.detail;
                    if (!!e.detail) {
                        this.creditAmount = e.detail;
                    }
                }}
            ></tm-currency-range>
            <tm-date-range
                label="Initiated Date"
                .range=${this.initiatedDateRange}
                @selection=${(e: CustomEvent) => {
                    this.validState.initiatedDate = !!e.detail;
                    if (!!e.detail) {
                        this.initiatedDateRange = e.detail;
                    }
                }}
            ></tm-date-range>
            <tm-date-range
                label="Effective Date"
                .range=${this.effectiveDateRange}
                @selection=${(e: CustomEvent) => {
                    this.validState.effectiveDate = !!e.detail;
                    if (!!e.detail) {
                        this.effectiveDateRange = e.detail;
                    }
                }}
            ></tm-date-range>
        </form>`;
    }

    render() {
        return html`<tm-bottom-sheet
            .open=${this.open}
            @close=${() => {
                this.open = false;
                this.dispatchEvent(new CustomEvent('close'));
            }}
            ><p slot="header-center">Filter ACH Payments</p>
            <p slot="header-right"></p>
            <tm-body>${this.renderFilters()}</tm-body>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: 'Apply Filters',
                        onClick: () => {
                            this.onApplyFilters();
                        },
                        disabled: Object.values(this.validState).some(isValid => !isValid),
                    },
                    {
                        importance: 'secondary',
                        text: 'Reset',
                        onClick: () => {
                            this.onResetForm();
                        },
                    },
                ] as ButtonConfig[]}
            ></tm-footer>
        </tm-bottom-sheet>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchPaymentsFilterSheet;
    }
}
