import { FrequencyModelDto, UsersClient } from '@treasury/api/channel';
import { Wire, WiresService } from '@treasury/domain/wires';
import { LabeledList, TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-button';
import { mapFrequencyModelDtoToFrequency } from '@treasury/presentation/components/tm-frequency.mappings';
import { summarizeFrequency } from '@treasury/presentation/components/tm-frequency.summarizer';
import { FrequencyType } from '@treasury/presentation/components/tm-frequency.types';
import '@treasury/presentation/components/tm-labeled-list';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { format } from 'date-fns';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../../components/jhd-chip';
import '../../../components/jhd-status';

export const tagName = 'wire-read-only';
@customElement(tagName)
export class WireReadOnly extends TmBaseComponent {
    @InjectProperty()
    private declare wireService: WiresService;

    @InjectProperty()
    private declare userClient: UsersClient;

    @property({ type: Object })
    wire!: Wire;

    @state()
    isInternational: boolean | null = null;

    @property({ type: Boolean })
    showStatus = true;

    @property({ type: Boolean })
    showCreationDetails = true;

    @property({ type: Boolean })
    showAudit = true;

    @state()
    auditOpen = false;

    @state()
    createdBy?: string;

    public async firstUpdated() {
        this.isInternational = this.wire.wireTemplate
            ? this.wire.wireTemplate.isInternational
            : this.wire.isInternational;
    }

    private toggleAudit() {
        this.auditOpen = !this.auditOpen;
    }

    renderHeader() {
        const currency = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return html`<div class="wire-header flex px-3">
            <div class="wire-title-amount">
                <h2 class="wire-title text-xl font-normal">
                    ${this.wire.wireTemplate?.name ?? this.wire.purpose}
                </h2>
                <h3 class="wire-amount text-3xl font-light">
                    ${currency.format(this.wire.amount)}
                </h3>
            </div>

            <div class="wire-status-chip">
                <jhd-chip .value=${this.isInternational ? 'International' : 'Domestic'}></jhd-chip>
                ${this.renderStatus()}
            </div>
        </div>`;
    }

    renderStatus() {
        if (!this.showStatus || !this.wire.status) return nothing;
        return html`<div class="mb-1 text-sm">
            <jhd-status .status=${this.wire.status} showLabel></jhd-status>
        </div>`;
    }

    renderInternationalDomesticChip() {
        if (this.isInternational === null) return nothing;
        return html`
            <jhd-chip .value=${this.isInternational ? 'International' : 'Domestic'}></jhd-chip>
        `;
    }

    renderDetails() {
        let wireDetails: any = {
            wireCompany: this.wire.wireCompany?.name,
            debitAccount: this.wire.debitAccount?.accountDisplayLabel,
            beneficiary: this.wire.beneficiary?.name,
            frequency: undefined,
        };
        let wireDetailsLabels: any = {};

        const tmFrequency = mapFrequencyModelDtoToFrequency(
            this.wire.frequency as FrequencyModelDto
        );
        if (tmFrequency.type === FrequencyType.OneTime) {
            wireDetails.frequency = 'One Time';
            wireDetails.effectiveDate = format(tmFrequency.startDate, 'MM/dd/yyyy');
        } else {
            wireDetails.frequency = summarizeFrequency(tmFrequency);
        }

        if (this.wire.transactionId) {
            wireDetails.transactionId = this.wire.transactionId;
            wireDetailsLabels.transactionId = 'Transaction ID';
        }

        const wireDetailsList = new LabeledList(
            wireDetails,
            Object.keys(wireDetails),
            wireDetailsLabels
        );

        let wireAdditionalInfo: any = {
            purpose: this.wire.purpose,
        };

        if (!this.isInternational) {
            wireAdditionalInfo.referenceBeneficiary = this.wire.referenceBeneficiary;
        }

        wireAdditionalInfo.additionalInformation = this.wire.additionalInformationValues;

        const wireAdditionalInfoList = new LabeledList(
            wireAdditionalInfo,
            Object.keys(wireAdditionalInfo)
        );

        return html` <div class="px-3 py-4">
            <tm-labeled-list class="py-4" .list=${wireDetailsList}></tm-labeled-list>
            <hr class="border-t border-dashed border-[--border-color] my-4" />
            <tm-labeled-list class="py-4" .list=${wireAdditionalInfoList}></tm-labeled-list>
            <hr class="border-t border-dashed border-[--border-color] mt-4" />
        </div>`;
    }

    renderCreationDetails() {
        if (!this.showCreationDetails) return nothing;
        const creationDetails: any = {
            creationDate: this.wire.createdDate
                ? format(new Date(this.wire.createdDate), 'MMMM do, yyyy')
                : undefined,
        };

        const creationDetailsList = new LabeledList(creationDetails, Object.keys(creationDetails));
        return html`<tm-labeled-list class="py-4" .list=${creationDetailsList}></tm-labeled-list>`;
    }

    renderAuditData() {
        if (!this.auditOpen) return nothing;
        return html`<div class="mt-6 pb-2">
            <div class="pb-2 font-medium">Audit</div>
            <div class="max-h-36 overflow-y-auto font-normal text-sm">${this.wire.audit}</div>
        </div>`;
    }

    renderAudit() {
        if (!this.showAudit || !this.wire.audit) return nothing;
        const auditButtonText = this.auditOpen ? 'Hide Audit' : 'View Audit';
        return html`${this.renderAuditData()}
            <tm-button class="mt-2" @click=${() => this.toggleAudit()}>
                ${auditButtonText}
            </tm-button> `;
    }

    render() {
        return [
            this.renderHeader(),
            this.renderDetails(),
            this.renderCreationDetails(),
            this.renderAudit(),
        ];
    }

    static get styles() {
        return [
            css`
                .wire-title-amount {
                    flex: 3;
                }

                .wire-status-chip {
                    flex: 1 1 85px;
                    text-align: right;
                }

                .max-h-36 {
                    max-height: 9rem;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: WireReadOnly;
    }
}
