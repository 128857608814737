import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-blocking-loader';
import '@vaadin/grid';
import { columnBodyRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import '../../components/jhd-data-field';
import {
    TransferAccount,
    UserTransferLimitsViewModel,
} from '../data/user-transfer-limits-view-model';

export const tagName = 'user-transfer-limits';
@customElement(tagName)
export class UserTransferLimits extends TmBaseComponent {
    @property({ type: Object })
    public config!: UserTransferLimitsViewModel;

    renderLimitHeader() {
        if (!this.config) return nothing;
        return html`<div class="transfer-limits p-3">
            <h3 class="text-lg pb-2">Transfer Limits</h3>
            <jhd-data-field
                .label=${'Product Daily Limit'}
                .value=${this.config.dailyLimit}
            ></jhd-data-field>
            <jhd-data-field
                .label=${'Daily Initiation Limit'}
                .value=${this.config.transferLimits?.initiationLimit?.value}
                .edited=${this.config.transferLimits?.initiationLimit?.edited}
            ></jhd-data-field>
            <jhd-data-field
                .label=${'Daily Approval Limit'}
                .value=${this.config.transferLimits?.approvalLimit?.value}
                .edited=${this.config.transferLimits?.approvalLimit?.edited}
            ></jhd-data-field>
        </div>`;
    }

    renderAccountNameColumn(account: TransferAccount) {
        return html`<div class="flex flex-col">
            <span class="text-sm font-normal">${account.accountName}</span>
            <span class="text-xs font-normal text-gray-500">${account.accountNumber}</span>
            <span class="text-xs font-normal text-gray-500">${account.accountDailyLimit}</span>
        </div>`;
    }

    renderLimitsColumn(account: TransferAccount) {
        return html`<div class="flex flex-col">
            <span
                class=${classMap({
                    'text-sm': true,
                    'font-normal': true,
                    edited: account.transactionLimit.edited,
                })}
                >transaction limit: ${account.transactionLimit.value}</span
            >
            <span
                class=${classMap({
                    'text-sm': true,
                    'font-normal': true,
                    edited: account.dailyLimit.edited,
                })}
                >daily limit: ${account.dailyLimit.value}</span
            >
        </div>`;
    }

    renderAccountLimitsTable() {
        if (!this.config) return nothing;
        if (!this.config.userTransferAccounts?.length)
            return html`<h3 class="italic text-lg w-full text-center">No Accounts Available.</h3>`;
        return html`<vaadin-grid all-rows-visible .items=${this.config.userTransferAccounts}
            ><vaadin-grid-column
                flex-grow="0"
                ${columnBodyRenderer(this.renderAccountNameColumn)}
            ></vaadin-grid-column
            ><vaadin-grid-column
                flex-grow="1"
                text-align="end"
                ${columnBodyRenderer(this.renderLimitsColumn)}
            ></vaadin-grid-column
        ></vaadin-grid>`;
    }

    render() {
        return html` ${this.renderLimitHeader()}${this.renderAccountLimitsTable()}`;
    }

    static get styles() {
        return [
            css`
                .transfer-limits {
                    background-color: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserTransferLimits;
    }
}
