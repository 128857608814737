// eslint-disable-next-line import/extensions
import { TmHttpClient } from '@treasury/core/http';

export class DashboardRequests {
    static async getMyAccounts() {
        return (await TmHttpClient.getInstance()).request(`dashboard/myaccounts`, {
            method: 'GET',
        });
    }

    static async getProducts() {
        return (await TmHttpClient.getInstance()).request(`dashboard/getProducts`, {
            method: 'GET',
        });
    }
}
