import { TemplateResult } from 'lit';

export interface NavigationOption {
    description: string;
    key: string;
    name: string;
    path: string;
    subMenus: NavigationOption[];
}

export interface NavigationOptions {
    icon: TemplateResult;
    label: string;
    url: string;
    id: string;
}

export interface NavigationMenuItem {
    label?: string;
    options: Array<NavigationOptions>;
}

export enum NavigationArea {
    UserProfile = 'user-profile',
    Accounts = 'accounts',
    Ach = 'ach',
    Transfers = 'transfers',
    BusinessBillPay = 'business-bill-pay',
    Dashboard = 'dashboard',
    PendingApprovals = 'pending-approvals/ach',
    Wires = 'wires',
    PositivePayACHExceptions = 'positive-pay/ach-exceptions',
    PositivePayCheckExceptions = 'positive-pay/check-exceptions',
    Deposit = 'deposit',
    DepositActivity = 'deposit-activity',
    MessageCenter = 'message-center',
    About = 'about',
    LogOut = 'log-out',
    Default = '',
}

type PathDictionary = Record<string, NavigationArea | Record<string, NavigationArea>>;

export const ActiveItemPathsDictionary: PathDictionary = {
    dashboard: NavigationArea.Dashboard,
    accounts: NavigationArea.Accounts,
    ach: NavigationArea.Ach,
    'positive-pay': {
        'check-exceptions': NavigationArea.PositivePayCheckExceptions,
        'ach-exceptions': NavigationArea.PositivePayACHExceptions,
        'ach-filters': NavigationArea.PositivePayACHExceptions,
    },
    'pending-approvals': NavigationArea.PendingApprovals,
    'business-bill-pay': NavigationArea.BusinessBillPay,
    transfers: NavigationArea.Transfers,
    wires: NavigationArea.Wires,
    deposit: NavigationArea.Deposit,
    'batch-deposit': NavigationArea.Deposit,
    'deposit-activity': NavigationArea.DepositActivity,
    'message-center': NavigationArea.MessageCenter,
    about: NavigationArea.About,
    'log-out': NavigationArea.LogOut,
    profile: NavigationArea.UserProfile,
};
