import '@vaadin/text-field';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { TmTextField } from './tm-text-field';

export const tagName = 'tm-currency-field';
@customElement(tagName)
export class TmCurrencyField extends TmTextField {
    constructor() {
        super();
    }

    @property()
    placeholder = '0.00';

    @property({ type: Number })
    fieldId = 0;

    @state()
    private displayValue = '';

    firstUpdated() {
        this.setDisplayValue();
    }

    updated(changedProperties: Map<string, unknown>) {
        if (changedProperties.has('fieldId') || (changedProperties.has('value') && this.value)) {
            this.setDisplayValue();
        }
        if (changedProperties.has('displayValue')) {
            const valueChangedEvent = new CustomEvent('value-changed', {
                detail: this.stripFormatting(this.displayValue),
            });
            this.dispatchEvent(valueChangedEvent);
        }
    }

    setDisplayValue() {
        this.displayValue = Number(this.value).toLocaleString('en-US', {
            minimumFractionDigits: 2,
        });
    }

    stripFormatting(value: string) {
        return Number(value.replace(/\D/g, '')) / 100;
    }

    render() {
        /* Android doesn't respect the allow-char-pattern so this is used to force the displayValue value into the input */
        const input = this.shadowRoot?.querySelector('vaadin-text-field input') as HTMLInputElement;
        return html`
            <vaadin-text-field
                @value-changed=${(e: CustomEvent) => {
                    if (e.detail.value) {
                        this.displayValue = this.stripFormatting(e.detail.value).toLocaleString(
                            'en-US',
                            {
                                minimumFractionDigits: 2,
                            }
                        );
                        input.value = this.displayValue;
                    }
                }}
                @focus=${() => {
                    if (this.displayValue === '0.00') {
                        this.displayValue = '';
                    }
                }}
                allowed-char-pattern="[0-9]"
                class="w-full"
                part="component"
                label=${this.label}
                value=${this.displayValue}
                placeholder=${this.placeholder}
                ?required=${this.required}
                ?readonly=${this.readonly}
                ?disabled=${this.disabled}
                ?invalid=${this.invalid}
            >
            <div slot="prefix">$</div>
            <input slot="input" class="text-left ml-1"></input>
            </vaadin-text-field>
        `;
    }

    static get styles() {
        return [
            ...super.styles,
            css`
                :host {
                    display: block;
                }
                vaadin-text-field > input {
                    -webkit-mask-image: none;
                    mask-image: none;
                }

                :host(.form-row-slotted) input {
                    text-align: right;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmCurrencyField;
    }
}
