import 'reflect-metadata';
import { Constructor } from '../../types';
import { di$ } from '../di-container';
import { InjectPropertyConfig } from '../di.types';

/**
 * Fulfills a class property with an instance of its type or the provided token.
 *
 * **Note:** It is not possible to assign instance properties using decorators.
 * Under the hood, this decorator places a getter with the same
 * name as the field on the class' prototype. Assigning a value to this
 * property name via the instance will make the getter inaccessible due to it
 * shadowing the getter on the [prototype chain](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Inheritance_and_the_prototype_chain#inheriting_properties).
 *
 * See: https://stackoverflow.com/a/34756469
 */
export function InjectProperty<T, R>(config: Partial<InjectPropertyConfig<R>> = {}) {
    let { container } = config;
    if (!container) {
        di$.subscribe(newContainer => {
            container = newContainer;
            return newContainer;
        }, true);
    }

    return function (target: T, name: string) {
        const { token } = config;
        const paramType: Constructor<R> = Reflect.getMetadata(
            'design:type',
            target as object,
            name
        );
        const diToken = token || paramType;
        if (!diToken) {
            throw new Error(`Could not inject property '${name}'. Invalid DI token.`);
        }

        const property = {
            get(): R {
                if (!container) {
                    throw new Error(
                        `Could not inject property: ${name}. No DI container available.`
                    );
                }

                return container.get(diToken);
            },
        };

        Object.defineProperty(target, name, property);
    };
}
