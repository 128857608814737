/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    CompanyModelCollectionDto,
    CompanySearchModel2Dto,
    ErrorHttpContentDto,
    NotFoundResultDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class CompanyClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Company
     * @name CompanyGetCompanyGuidUniqueId
     * @summary Gets company guid unique id in exchange for an ID.
     * @request GET:/Company/GetGuidUniqueId/{companyId}
     */
    companyGetCompanyGuidUniqueId = (companyId: number, params: RequestParams = {}) =>
        this.http.request<string, ErrorHttpContentDto | NotFoundResultDto>({
            path: `/Company/GetGuidUniqueId/${companyId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Company
     * @name CompanyCompanySearch
     * @summary Searches for companies that meet the criteria of the request.
     * @request POST:/Company/Search
     */
    companyCompanySearch = (
        companySearchModel: CompanySearchModel2Dto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyModelCollectionDto, ErrorHttpContentDto>({
            path: `/Company/Search`,
            method: 'POST',
            body: companySearchModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
