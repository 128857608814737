import { AccountService } from '@treasury/domain/channel/services';
import { TmBaseComponent } from '@treasury/presentation';
import { mailIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-loader';
import { InjectProperty } from '@treasury/utils';
import { css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../../components/jhd-tooltip';

export const tagName = 'messages-tooltip';
@customElement(tagName)
export class MessagesTooltip extends TmBaseComponent {
    @InjectProperty()
    private declare accountService: AccountService;

    @state()
    private unreadMessageCount = 0;

    @state()
    private loading = false;

    async firstUpdated() {
        this.loading = true;
        const { unreadMessageCount } = await this.accountService.getUserSettings();
        this.unreadMessageCount = unreadMessageCount;
        this.loading = false;
    }

    renderMessageCount() {
        if (!this.unreadMessageCount) return nothing;
        return html`<jha-badge danger small>${this.unreadMessageCount}</jha-badge>`;
    }

    render() {
        if (this.loading) return html`<tm-loader></tm-loader>`;
        return html`<jhd-tooltip
            ><div class="icon-wrapper">${mailIcon}${this.renderMessageCount()}</div></jhd-tooltip
        >`;
    }

    static get styles() {
        return [
            css`
                .icon-wrapper {
                    position: relative;
                }
                jha-badge {
                    position: absolute;
                    margin-top: -32px;
                }
                path {
                    fill: var(--header-bar-button-color);
                }
                tm-loader {
                    --loader-color: var(--header-bar-button-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: MessagesTooltip;
    }
}
