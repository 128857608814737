import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-blocking-loader';
import '@vaadin/grid';
import { columnBodyRenderer, GridColumnBodyLitRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import '../../components/jhd-data-field';
import { UserAchLimitsCompany, UserAchLimitsViewModel } from '../data/user-ach-limits-view-model';

export const tagName = 'user-ach-limits';
@customElement(tagName)
export class UserAchLimits extends TmBaseComponent {
    @property({ type: Object })
    public config!: UserAchLimitsViewModel;

    renderLimitHeader() {
        if (!this.config) return nothing;
        return html`<div class="ach-limits p-3">
            <h3 class="text-lg pb-2">ACH User Limits</h3>
            <jhd-data-field
                .label=${'Product Daily Limit'}
                .value=${this.config.dailyLimit}
            ></jhd-data-field
            ><jhd-data-field
                .label=${'Daily Initiation Limit'}
                .value=${this.config.achLimits.initiationLimit}
                .edited=${this.config.achLimits.initiationLimitEdited}
            ></jhd-data-field
            ><jhd-data-field
                .label=${'Daily Approval Limit'}
                .value=${this.config.achLimits.approvalLimit}
                .edited=${this.config.achLimits.approvalLimitEdited}
            ></jhd-data-field>
        </div>`;
    }

    renderAccountLimits() {
        return html`<template></template>`;
        // return html`<h3 class="text-lg p-3">ACH Account Limits</h3>`;
    }

    renderCompanyNameColumn(company: UserAchLimitsCompany) {
        return html`<div class="flex flex-col">
            <span class="text-sm font-normal">${company.companyName}</span>
            <span class="text-xs font-normal text-gray-500">${company.companyId}</span>
            <span class="text-xs font-normal text-gray-500">${company.companyDailyLimit}</span>
        </div>`;
    }

    renderAchCompanyLimitColumn(company: UserAchLimitsCompany) {
        return html`<div class="flex flex-col">
            <span
                class=${classMap({
                    'text-sm font-normal': true,
                    edited: company.transactionLimit.edited,
                })}
                >transaction limit: ${company.transactionLimit.value}</span
            >
            <span
                class=${classMap({
                    'text-sm font-normal': true,
                    edited: company.dailyLimit.edited,
                })}
                >daily limit: ${company.dailyLimit.value}</span
            >
        </div>`;
    }

    renderCompanyLimits() {
        if (!this.config) return nothing;
        return html`<div class="ach-company-limits">
            <h3 class="text-lg p-3">ACH Company Limits</h3>
            <vaadin-grid
                all-rows-visible
                class="border-y border-neutral-200"
                .items=${this.config.userAchCompanies}
                ><vaadin-grid-column
                    flex-grow="0"
                    ${columnBodyRenderer(
                        this
                            .renderCompanyNameColumn as GridColumnBodyLitRenderer<UserAchLimitsCompany>
                    )}
                ></vaadin-grid-column
                ><vaadin-grid-column
                    text-align="end"
                    flex-grow="1"
                    ${columnBodyRenderer(
                        this
                            .renderAchCompanyLimitColumn as GridColumnBodyLitRenderer<UserAchLimitsCompany>
                    )}
                ></vaadin-grid-column
            ></vaadin-grid>
        </div>`;
    }

    render() {
        return html` ${this.renderLimitHeader()}${this.renderAccountLimits()}${this.renderCompanyLimits()}`;
    }

    static get styles() {
        return [
            css`
                .ach-limits,
                .ach-company-limits {
                    background-color: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserAchLimits;
    }
}
