import { WireCompanyModelDto } from '@treasury/api/channel';
import { CurrenciesService } from '@treasury/domain/services/currencies.service';
import { WireCompanyDto } from '@treasury/domain/shared';
import { WireDebitAccountDto, WiresService } from '@treasury/domain/wires';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-currency-range';
import {
    CurrencyRange,
    CurrencyRangeType,
} from '@treasury/presentation/components/tm-currency-range';
import { mapCurrencyRangeToDto } from '@treasury/presentation/components/tm-currency-range.mappings';
import '@treasury/presentation/components/tm-date-range';
import { DateRange, DateRangeType } from '@treasury/presentation/components/tm-date-range';
import { mapDateRangeToDto } from '@treasury/presentation/components/tm-date-range.mappings';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-loader';
import '@treasury/presentation/components/tm-multi-select';
import { MultiSelectItem } from '@treasury/presentation/components/tm-multi-select';
import '@treasury/presentation/components/tm-select';
import { SelectItem } from '@treasury/presentation/components/tm-select';
import '@treasury/presentation/components/tm-text-field';
import { InjectProperty } from '@treasury/utils';
import { DeepReactive } from '@treasury/utils/lit-helpers';
import { add } from 'date-fns';
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { wireFrequencyFilterOptions } from '../data/wire-frequency-filter-options';
import { wireStatusItems } from '../data/wire-payment-status-items';

const WireTypes = [
    { label: 'Domestic', value: 'Domestic' },
    { label: 'International', value: 'International' },
];

const defaultNextPaymentDateRange: DateRange = {
    rangeType: DateRangeType.DateRange,
};

const defaultCreatedDateRange: DateRange = {
    rangeType: DateRangeType.None,
};

const defaultAmountRange: CurrencyRange = {
    rangeType: CurrencyRangeType.AllAmounts,
};

export const tagName = 'wire-recurring-payments-filter-sheet';
@customElement(tagName)
export class wireRecurringPaymentsFilterSheet extends TmBaseComponent {
    @InjectProperty()
    private declare wiresService: WiresService;

    @InjectProperty()
    private declare currenciesService: CurrenciesService;

    @property({ type: Boolean })
    public open = false;

    @state()
    private wireTypesSelected: MultiSelectItem[] = WireTypes;

    @state()
    private wireCompanyItems: MultiSelectItem[] = [];

    @state()
    private wireCompanyItemsSelected: MultiSelectItem[] = [];

    @state()
    private frequenciesSelected: { key: string; value: string }[] = wireFrequencyFilterOptions.map(
        f => f.value
    );

    @state()
    private statusItems: MultiSelectItem[] = wireStatusItems.map((status: any) => ({
        label: status.name,
        value: status,
    }));

    @state()
    private statusItemsSelected: MultiSelectItem[] = [];

    @state()
    private debitAccountItems: MultiSelectItem[] = [];

    @state()
    private debitAccountItemsSelected: MultiSelectItem[] = [];

    @state()
    private amountRange: CurrencyRange = defaultAmountRange;

    @state()
    private createdDateRange: DateRange = defaultCreatedDateRange;

    @state()
    private nextPaymentDateRange: DateRange = defaultNextPaymentDateRange;

    @state()
    private beneficiaryName?: string;

    @state()
    private transactionID?: string;

    @state()
    private currency?: string;

    @state()
    private currencyItems: SelectItem[] = [];

    @state()
    private omad?: string;

    @state()
    private loading = true;

    @DeepReactive()
    private validState = {
        wireCompany: true,
        wireType: true,
        status: true,
        debitAccount: true,
        amountRange: true,
        effectiveDate: true,
        createdDate: true,
        frequency: true,
    };

    @property()
    public wireCompanies: Array<WireCompanyModelDto> = [];

    @property()
    public debitAccounts: Array<WireDebitAccountDto> = [];

    async firstUpdated() {
        try {
            // @ts-ignore
            this.wireCompanyItems = this.wireCompanies.map((company: WireCompanyDto) => ({
                label: company.isDliWireCompany ? `${company.name} - International` : company.name,
                value: company,
            }));
            this.wireCompanyItemsSelected = this.wireCompanyItems;
            this.debitAccountItems = this.debitAccounts.map((account: WireDebitAccountDto) => ({
                label: account.accountDisplayLabel,
                value: account,
            }));
            this.debitAccountItemsSelected = this.debitAccountItems;
            const wireCurrencies = (await this.currenciesService.getAll()) as {
                id: number;
                updatedDate: string;
                currencyCode: string;
                name: string;
            }[];
            this.currencyItems = wireCurrencies.map(currency => ({
                label: `${currency.currencyCode}, ${currency.name}`,
                value: currency.currencyCode,
            }));
            this.statusItemsSelected = this.statusItems;
            this.onApplyFilters();
        } catch (e) {
            console.error(e);
            throw new Error('There was an error fetching wire data');
        } finally {
            this.loading = false;
        }
    }

    private toDto() {
        const {
            operator: wireAmountType,
            fromAmount: wireAmountMin,
            toAmount: wireAmountMax,
            specificAmount: wireAmount,
        } = mapCurrencyRangeToDto(this.amountRange);

        const {
            operator: nextPaymentDateType,
            dateFrom: nextPaymentDateStart,
            dateTo: nextPaymentDateEnd,
            specificDate: nextPaymentDate,
        } = mapDateRangeToDto(this.nextPaymentDateRange);

        const {
            operator: createdDateType,
            dateFrom: createdDateStart,
            dateTo: createdDateEnd,
            specificDate: createdDate,
        } = mapDateRangeToDto(this.createdDateRange);

        return {
            statuses: this.statusItemsSelected.map((item: MultiSelectItem) => item.value),
            ...(this.wireCompanyItemsSelected.length && {
                wireCompanies: this.wireCompanyItemsSelected.map(
                    (item: MultiSelectItem) => item.value
                ),
            }),
            ...(this.debitAccountItemsSelected.length && {
                debitAccounts: this.debitAccountItemsSelected.map(
                    (item: MultiSelectItem) => item.value
                ),
            }),
            wireAmountType,
            frequency: this.frequenciesSelected,
            wireAmount,
            wireAmountMin,
            wireAmountMax,
            createdDateType,
            createdDate,
            createdDateStart,
            createdDateEnd,
            nextPaymentDateType,
            nextPaymentDateStart,
            nextPaymentDateEnd,
            nextPaymentDate,
            beneficiaryName: this.beneficiaryName,
            transactionID: this.transactionID,
        };
    }

    private onResetForm() {
        this.wireTypesSelected = WireTypes;
        this.wireCompanyItemsSelected = this.wireCompanyItems;
        this.debitAccountItemsSelected = this.debitAccountItems;
        this.statusItemsSelected = this.statusItems;
        this.amountRange = { ...defaultAmountRange };
        this.nextPaymentDateRange = { ...defaultNextPaymentDateRange };
        this.createdDateRange = { ...defaultCreatedDateRange };
        this.beneficiaryName = undefined;
        this.transactionID = undefined;
        this.currency = undefined;
        this.omad = undefined;
        this.requestUpdate();
    }

    onApplyFilters() {
        this.dispatchEvent(
            new CustomEvent('applyFilters', {
                detail: this.toDto(),
            })
        );
        this.dispatchEvent(new CustomEvent('close'));
    }

    renderForm() {
        if (this.loading) return html`<tm-loader card></tm-loader>`;
        return html` <form>
            <tm-multi-select
                label="Wire Company"
                .allowSelectAll=${true}
                .allSelectedText=${'All Wire Companies'}
                .items=${this.wireCompanyItems}
                .selectedItems=${[...this.wireCompanyItemsSelected]}
                @selected-items-changed=${(e: CustomEvent) => {
                    this.wireCompanyItemsSelected = e.detail;
                    this.validState.wireCompany = !!this.wireCompanyItemsSelected.length;
                }}
            ></tm-multi-select>
            <tm-multi-select
                label="Status"
                .allowSelectAll=${true}
                .allSelectedText=${'All Wire Statuses'}
                .items=${this.statusItems}
                .selectedItems=${[...this.statusItemsSelected]}
                @selected-items-changed=${(e: CustomEvent) => {
                    this.statusItemsSelected = e.detail;
                    this.validState.status = !!this.statusItemsSelected.length;
                }}
            ></tm-multi-select>
            <tm-multi-select
                label="Debit Account"
                .allowSelectAll=${true}
                .allSelectedText=${'All Debit Accounts'}
                .items=${this.debitAccountItems}
                .selectedItems=${[...this.debitAccountItemsSelected]}
                @selected-items-changed=${(e: CustomEvent) => {
                    this.debitAccountItemsSelected = e.detail;
                    this.validState.debitAccount = !!this.debitAccountItemsSelected.length;
                }}
            ></tm-multi-select>
            <tm-text-field
                label="Beneficiary Name"
                .value=${this.beneficiaryName}
                @value-changed=${(e: CustomEvent) => {
                    this.beneficiaryName = e.detail.value;
                }}
            ></tm-text-field
            ><tm-text-field
                label="Transaction ID"
                .value=${this.transactionID}
                @value-changed=${(e: CustomEvent) => {
                    this.transactionID = e.detail.value;
                }}
            ></tm-text-field>
            <tm-text-field
                label="OMAD"
                .value=${this.omad}
                @value-hanged=${(e: CustomEvent) => {
                    this.omad = e.detail.value;
                }}
            ></tm-text-field>
            <tm-currency-range
                .range=${this.amountRange}
                @selection=${(e: CustomEvent) => {
                    this.validState.amountRange = !!e.detail;
                    if (!!e.detail) {
                        this.amountRange = e.detail;
                    }
                }}
            ></tm-currency-range>
            <tm-multi-select
                label="Frequency"
                .allowSelectAll=${true}
                .allSelectedText=${'All Frequencies'}
                .items=${wireFrequencyFilterOptions}
                .selectedItems=${[...this.frequenciesSelected]}
                @selected-items-changed=${(e: CustomEvent) => {
                    this.frequenciesSelected = e.detail;
                    this.validState.frequency = !!this.frequenciesSelected.length;
                }}
            ></tm-multi-select>
            <tm-date-range
                label="Next Payment Date"
                required
                .range=${this.nextPaymentDateRange}
                @selection=${(e: CustomEvent) => {
                    this.validState.effectiveDate = !!e.detail;
                    if (!!e.detail) {
                        this.nextPaymentDateRange = e.detail;
                    }
                }}
            ></tm-date-range>
            <tm-date-range
                label="Created Date"
                .range=${this.createdDateRange}
                @selection=${(e: CustomEvent) => {
                    this.validState.createdDate = !!e.detail;
                    if (!!e.detail) {
                        this.createdDateRange = e.detail;
                    }
                }}
                .maxDate=${new Date()}
            ></tm-date-range>
        </form>`;
    }

    render() {
        return html`<tm-bottom-sheet
            .open=${this.open}
            @close=${() => {
                this.open = false;
                this.dispatchEvent(new CustomEvent('close'));
            }}
            ><p slot="header-center">Filter Wire Payments</p>
            <p slot="header-right"></p>
            <tm-body>${this.renderForm()}</tm-body>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: 'Apply Filters',
                        onClick: () => {
                            this.onApplyFilters();
                        },
                        disabled: Object.values(this.validState).some(isValid => !isValid),
                    },
                    {
                        importance: 'secondary',
                        text: 'Reset',
                        onClick: () => {
                            this.onResetForm();
                        },
                    },
                ] as ButtonConfig[]}
            >
            </tm-footer>
        </tm-bottom-sheet> `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: wireRecurringPaymentsFilterSheet;
    }
}
