import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/layout/tm-section';
import '@treasury/presentation/components/tm-footer';
import { summarizeFrequency } from '@treasury/presentation/components/tm-frequency.summarizer';
import { formatUsdCurrency } from '@treasury/utils';
import { addDays, format } from 'date-fns';
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BillPayWorkflowData } from '../containers/business-bill-pay-workflow-container';

export const tagName = 'business-bill-pay-review-step';
@customElement(tagName)
export class BusinessBillPayReviewStep extends TmBaseComponent {
    @property()
    private payment?: BillPayWorkflowData;

    renderPaymentTypeBadge() {
        if (!this.payment) return nothing;
        return html`<tm-badge>${this.payment.payee.paymentMethod}</tm-badge>`;
    }

    renderMemo() {
        if (!this.payment) return nothing;
        if (!this.payment.comment) return nothing;
        return html` <jhd-data-field
            class="pt-3"
            label="Memo"
            .value=${this.payment.comment}
        ></jhd-data-field>`;
    }

    render() {
        if (!this.payment) return nothing;
        if (!this.payment.frequency) return nothing;
        return html` <tm-section class="p-3">
                <div class="flex justify-between">
                    <span class="text-lg">${this.payment.payee.name}</span
                    ><span>${this.renderPaymentTypeBadge()}</span>
                </div>
                <div class="text-xl">${formatUsdCurrency(this.payment.amount)}</div>
                <div class="payment-details">
                    <jhd-data-field
                        label="From"
                        .value=${this.payment.fromAccount.name}
                    ></jhd-data-field
                    ><jhd-data-field
                        class="pb-3"
                        label="Frequency"
                        .value=${summarizeFrequency(this.payment.frequency)}
                    ></jhd-data-field>
                </div>
                <div class="timeline">
                    <jhd-timeline
                        .events=${[
                            {
                                date: format(this.payment.frequency.startDate, 'P'),
                                eventName: 'Processing',
                                eventCompleted: true,
                            },
                            {
                                date: format(addDays(this.payment.frequency.startDate, 3), 'P'),
                                eventName: 'Estimated arrival',
                                eventCompleted: false,
                            },
                        ]}
                    ></jhd-timeline>
                </div>
                ${this.renderMemo()}
            </tm-section>
            <tm-footer
                class="fixed bottom-0 w-full"
                .buttonConfig=${[
                    {
                        text: 'Submit',
                        onClick: () => {
                            this.dispatchEvent(
                                new CustomEvent('continue', { detail: this.payment })
                            );
                        },
                    },
                ]}
            ></tm-footer>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: BusinessBillPayReviewStep;
    }
}
