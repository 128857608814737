/* eslint-disable no-use-before-define */
import { ApiErrorDto, isApiErrorDto } from '@treasury/api/shared';
import { TmApiError } from '@treasury/domain/shared';
import { exists, Injectable } from '@treasury/utils';
import '@vaadin/button';
import { Notification, ShowOptions } from '@vaadin/notification';
import { format } from 'date-fns';
import { html, nothing, TemplateResult } from 'lit';
import { closeIcon } from '../../assets/icons/icon-close';
import {
    NotificationConfig,
    NotificationTheme,
    NotificationUserConfig,
} from './tm-notification.type';

type TmError = ApiErrorDto | TmApiError;
type ErrorShape = Error | TmError;

@Injectable()
export class NotificationService {
    private renderHeader(config: NotificationUserConfig) {
        if (!config.header) {
            return nothing;
        }

        return html`<div class="font-bold text-lg mb-2">${config.header}</div>`;
    }

    private renderErrorContent(message?: string) {
        if (!message) {
            return nothing;
        }

        return html`<div>${message}</div>`;
    }

    private renderErrorCode(errorCode?: string) {
        if (!errorCode) {
            return nothing;
        }

        return html`<div class="text-sm">Error code: ${errorCode}</div>`;
    }

    private renderErrorTimestamp(timestamp: Date) {
        const dateString = format(timestamp, 'MM/dd/yyyy ppp');
        return html`<div class="text-sm">${dateString}</div>`;
    }

    private renderContentFromError(error: TmError) {
        if (isApiErrorDto(error)) {
            error = new TmApiError(error);
        }

        const { subErrors, timestamp } = error;
        if (subErrors) {
            const errorElements = subErrors.map(
                ({ responseMessage, responseCode }) =>
                    html`${this.renderErrorContent(responseMessage)}${this.renderErrorCode(
                        responseCode.toString()
                    )}`
            );

            return html` ${errorElements}${this.renderErrorTimestamp(timestamp)}`;
        }

        const { message, errorCode } = error;
        return html`${this.renderErrorContent(message)}${this.renderErrorCode(
            errorCode
        )}${this.renderErrorTimestamp(timestamp)}`;
    }

    public renderError(
        errContentConfig: ErrorShape | NotificationUserConfig | string | TemplateResult
    ) {
        let content: string | TemplateResult | undefined;
        if (isTmError(errContentConfig)) {
            content = this.renderContentFromError(errContentConfig);
        } else if (errContentConfig instanceof Error) {
            content = errContentConfig.message;
        } else if (!isNotificationUserConfig(errContentConfig)) {
            content = errContentConfig;
        }

        if (content) {
            this.renderMessage(content, 'error');
        } else if (isNotificationUserConfig(errContentConfig)) {
            this.renderMessage(errContentConfig, 'error');
        } else {
            throw new Error(
                'Could not render an error notification. Provided argument was invalid.'
            );
        }
    }

    public renderSuccess(config: NotificationUserConfig | string | TemplateResult) {
        this.renderMessage(config, 'success');
    }

    public renderWarning(config: NotificationUserConfig | string | TemplateResult) {
        this.renderMessage(config, 'warning');
    }

    private renderMessage(
        config: NotificationUserConfig | string | TemplateResult,
        theme: NotificationTheme
    ) {
        if (isNotificationUserConfig(config)) {
            this.renderNotification(
                {
                    ...config,
                },
                theme
            );
        } else {
            this.renderNotification(
                {
                    content: config,
                },
                theme
            );
        }
    }

    private renderNotification(config: NotificationUserConfig, theme: NotificationTheme) {
        config = normalizeConfig(config);
        let notification!: Notification;
        const { position, duration } = config;
        const options: ShowOptions = {
            theme,
            position,
            duration: theme === 'error' || theme === 'warning' ? 0 : duration,
        };

        const dismissibleTemplate = html`
            <div
                class="notification-content ${theme} flex flex-row justify-between items-start w-full"
            >
                <div>
                    ${this.renderHeader(config)}
                    <div>${config.content}</div>
                </div>

                <vaadin-button
                    class="notification-close"
                    theme="tertiary-inline"
                    @click=${() => notification.close()}
                    aria-label="Close"
                >
                    ${closeIcon}
                </vaadin-button>
            </div>
            <style>
                .notification-content {
                    font-family: 'Roboto', 'sans-serif';
                }
                .notification-close path {
                    fill: #fff;
                }

                .warning .notification-close path {
                    fill: var(--lumo-warning-contrast-color);
                }
            </style>
        `;

        notification = Notification.show(dismissibleTemplate, options);
        return notification;
    }
}

function normalizeConfig(config: NotificationUserConfig): NotificationConfig {
    const defaultConfig = {
        position: 'top-stretch' as const,
        opened: true,
        duration: 5000,
    };

    return {
        ...defaultConfig,
        ...config,
    };
}

function isNotificationUserConfig(obj: unknown): obj is NotificationUserConfig {
    return typeof obj === 'object' && exists(obj) && 'content' in obj;
}

function isTmError(obj: unknown): obj is TmError {
    return isApiErrorDto(obj) || obj instanceof TmApiError;
}
