import { Tristate } from './tristate';

export enum ApprovalOptions {
    Approved = 'Approved',
    Rejected = 'Rejected',
    NA = 'na',
}

export class ApprovalVm<T> extends Tristate<T, ApprovalOptions> {
    constructor(
        public readonly value: T,
        public readonly options: ApprovalOptions
    ) {
        super(value, options);
        this.selected = ApprovalOptions.NA;
    }

    get selectedState() {
        return this.selected;
    }

    set selectedState(value: string) {
        this.selected = value;
    }
}
