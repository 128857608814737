import {
    AddItemRequestModelDto,
    AuthenticateRequestModelDto,
    AuthenticatedResponseModelDto,
    GetCustomersRequestModelDto,
    GetItemImagesRequestModelDto,
    GetItemsRequestModelDto,
    RemoteDepositCaptureClient,
} from '@treasury/api/channel';
import { Injectable } from '@treasury/utils';

@Injectable()
export class RemoteDepositCaptureService {
    // eslint-disable-next-line no-useless-constructor
    constructor(private readonly client: RemoteDepositCaptureClient) {}

    private authenticatedResponse: AuthenticatedResponseModelDto | null = null;

    private isAuthenticated = false;

    private async authenticateRemoteDepositCapture() {
        /*
{
    "Credentials":{
        "__type":"SSOCredentialsSHA256:#JackHenry.Eps.Mobile.RDA",
        "FIIdentifier":"5678",
        "Hash":"A189729c2292d323131a5c14cf351f3fa8507928d3f8904f9c9eee9b2c5e3b291",
        "UserNumber":"1234",
        "SaltValue":"xyz",
        "Timestamp":"/Date(1560817240000-500)/",
        "PhoneKey":"1"
    },
    "DeviceTracking":{
        "AppBundleId":"testbundleid",
        "AppVersion":"1.0",
        "DeviceModel":"android",
        "DeviceSystemName":"testuserandroid",
        "DeviceSystemVersion":"1.0",
        "Vendor":"Your Company Name Here"
    },
    "RequestDate":"/Date(1560817240000)/",
    "RequestId":"123456789"
} 
         */
        const rdcAuthRequest: AuthenticateRequestModelDto = {
            deviceTracking: {
                appBundleId: 'com.jackhenry.treasury.pwa',
                appVersion: '1.0.0',
                deviceModel: 'test',
                deviceSystemName: 'test',
                deviceSystemVersion: 'test',
            },
        };
        const response = await this.client.remoteDepositCaptureAuthenticate(rdcAuthRequest);
        if (response.data) {
            this.authenticatedResponse = response.data;
            this.isAuthenticated = true;
            return true;
        }
        return false;
    }

    public async getLocations() {
        if (!this.isAuthenticated) {
            await this.authenticateRemoteDepositCapture();
        }
        const response = await this.client.remoteDepositCaptureGetLocations(
            this.authenticatedResponse!
        );
        this.authenticatedResponse!.securityToken = response.data.securityToken;
        return response.data.locations;
    }

    public async getCustomers(
        accountNumber: string = '',
        customerName: string = '',
        customerNumber: string = ''
    ) {
        const body: GetCustomersRequestModelDto = {
            accountNumber,
            customerName,
            customerNumber,
            securityToken: this.authenticatedResponse!.securityToken,
        };
        if (!this.isAuthenticated) {
            await this.authenticateRemoteDepositCapture();
        }
        const response = await this.client.remoteDepositCaptureGetCustomers(body);
        this.authenticatedResponse!.securityToken = response.data.securityToken;
        return response.data.customers;
    }

    public async createDeposit(item: AddItemRequestModelDto) {
        if (!this.isAuthenticated) {
            await this.authenticateRemoteDepositCapture();
        }
        // eslint-disable-next-line no-param-reassign
        item.securityToken = this.authenticatedResponse!.securityToken;
        const response = await this.client.remoteDepositCaptureAddItem(item);
        this.authenticatedResponse!.securityToken = response.data.securityToken;
        return response;
    }

    public async getItems(batchReference: string) {
        if (!this.isAuthenticated) {
            await this.authenticateRemoteDepositCapture();
        }

        const request: GetItemsRequestModelDto = {
            batchReference,
            securityToken: this.authenticatedResponse!.securityToken,
        };
        const response = await this.client.remoteDepositCaptureGetItems(request);
        this.authenticatedResponse!.securityToken = response.data.securityToken;
        return response.data.items ?? [];
    }

    public async getItemImages(itemReference: string) {
        if (!this.isAuthenticated) {
            await this.authenticateRemoteDepositCapture();
        }

        const request: GetItemImagesRequestModelDto = {
            itemReference,
            securityToken: this.authenticatedResponse!.securityToken,
        };
        const response = await this.client.remoteDepositCaptureGetItemImages(request);
        this.authenticatedResponse!.securityToken = response.data.securityToken;
        return response.data.images ?? [];
    }

    public async getBatches() {
        if (!this.isAuthenticated) {
            await this.authenticateRemoteDepositCapture();
        }

        const response = await this.client.remoteDepositCaptureGetBatches(
            this.authenticatedResponse!
        );
        this.authenticatedResponse!.securityToken = response.data.securityToken;
        return response.data.batches ?? [];
    }
}
