import { CompanyAccountModelDto } from '@treasury/api/channel';

export const nullAccount: CompanyAccountModelDto = {
    accountDisplayLabel: '',
    accountUniqueId: '',
    allowLoanPaymentPastDue: false,
    availableBalance: 0,
    cifNumber: '0',
    collectedBalance: 0,
    currentBalance: 0,
    dailyLimit: 0,
    id: 0,
    isDeleted: false,
    loanPaymentAmountDue: 0,
    loanPaymentDueDate: '',
    name: '',
    number: '',
    perTransactionLimit: 0,
    showAccountNickName: false,
    status: '',
    transferAmount: 0,
    transferMemo: '',
    type: 'Checking',
    updatedBy: '',
    updatedDate: '',
};
