import { TmFilterForm } from '@treasury/domain-components/forms/tm-filter-form.component';
import { AchCompany } from '@treasury/domain/ach';
import '@treasury/presentation/components/tm-currency-range';
import '@treasury/presentation/components/tm-date-range';
import '@treasury/presentation/components/tm-multi-select';
import { MultiSelectItem } from '@treasury/presentation/components/tm-multi-select';
import '@treasury/presentation/components/tm-select';
import { SelectItem } from '@treasury/presentation/components/tm-select';
import '@treasury/presentation/components/tm-text-field';
import { DeepReactive } from '@treasury/utils/lit-helpers';
import { PropertyValues, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { AchFilterVm } from '../data/ach-filter-vm';
import { achTemplateStatusItems } from '../data/ach-template-status-items';

export const tagName = 'ach-templates-filter-form';
@customElement(tagName)
export class AchTemplatesFilterForm extends TmFilterForm {
    @DeepReactive()
    protected filter = new AchFilterVm();

    @property({ type: Array })
    public secCodes: string[] = ['All'];

    @property({ type: Array })
    public achCompanies: AchCompany[] = [];

    @state()
    private achCompanyItems: MultiSelectItem[] = [];

    @state()
    private achCompanyItemsSelected: MultiSelectItem[] = [];

    @state()
    private secCodeItems: SelectItem[] = [];

    @DeepReactive()
    protected validState = {
        debitAmount: true,
        creditAmount: true,
        lastUpdated: true,
    };

    public get isValid() {
        return Object.values(this.validState).every(v => !!v);
    }

    protected updated(_changedProperties: PropertyValues): void {
        super.updated(_changedProperties);

        if (_changedProperties.has('achCompanies') && this.achCompanies?.length) {
            this.achCompanyItems = this.achCompanies.map(company => ({
                label: company.companyName,
                value: company,
            }));
            this.achCompanyItemsSelected = this.achCompanyItems;
            this.applyFilter();
        }
        if (_changedProperties.has('secCodes') && this.secCodes) {
            this.secCodeItems = this.secCodes.map((secCode: string) => ({
                label: secCode,
                value: secCode,
            }));
        }
    }

    public reset() {
        this.filter.reset();
        this.achCompanyItemsSelected = this.achCompanyItems;
    }

    renderAchCompanySelect() {
        if (!this.achCompanyItems || !this.achCompanyItems.length) return nothing;
        return html`<tm-multi-select
            label="ACH Company"
            .allowSelectAll=${true}
            .allSelectedText=${'All ACH Companies'}
            .items=${this.achCompanyItems}
            .selectedItems=${this.achCompanyItemsSelected}
            @selected-items-changed=${(e: CustomEvent<MultiSelectItem<AchCompany>[]>) => {
                this.filter.achCompanies = e.detail.map(c => c.value);
            }}
        >
        </tm-multi-select>`;
    }

    render() {
        return html`<form>
            <tm-select
                label="Status"
                .items=${achTemplateStatusItems}
                .value=${this.filter.status}
                @value-changed=${(e: CustomEvent) => {
                    this.filter.status = e.detail.value;
                }}
            >
            </tm-select>
            ${this.renderAchCompanySelect()}
            </tm-multi-select>
            <tm-text-field
                label="Template Name"
                .value=${this.filter.templateName}
                @value-changed=${(e: CustomEvent) => {
                    this.filter.templateName = e.detail.value;
                }}
            ></tm-text-field>
            <tm-select
                label="SEC Code"
                .items=${this.secCodeItems}
                .value=${this.filter.secCode}
                @value-changed=${(e: CustomEvent) => {
                    this.filter.secCode = e.detail.value;
                }}
            >
            </tm-select>
            <tm-currency-range
                label="Debit Amount"
                .range=${this.filter.debitRange}
                @selection=${(e: CustomEvent) => {
                    this.validState.debitAmount = !!e.detail;
                    if (e.detail) {
                        this.filter.debitRange = e.detail;
                    }
                }}
            ></tm-currency-range>
            <tm-currency-range
                label="Credit Amount"
                .range=${this.filter.creditRange}
                @selection=${(e: CustomEvent) => {
                    this.validState.creditAmount = !!e.detail;
                    if (e.detail) {
                        this.filter.creditRange = e.detail;
                    }
                }}
            ></tm-currency-range>
            <tm-date-range
                label="Last Updated"
                .range=${this.filter.lastUpdatedRange}
                @selection=${(e: CustomEvent) => {
                    this.validState.lastUpdated = !!e.detail;
                    if (e.detail) {
                        this.filter.lastUpdatedRange = e.detail;
                    }
                }}
                .maxDate=${new Date()}
            ></tm-date-range>
        </form>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchTemplatesFilterForm;
    }
}
