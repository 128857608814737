import { AchExceptionsDecisionsActivityRequestModelDto } from '@treasury/api/channel';
import PositivePayService from '@treasury/domain/channel/services/positive-pay/positive-pay-service';
import { InjectProperty, Injectable } from '@treasury/utils';
import type {
    Grid,
    GridDataProviderCallback,
    GridDataProviderParams,
    GridSorterDefinition,
    GridSorterDirection,
} from '@vaadin/grid';

@Injectable()
export default class AchExceptionsActivityDataProvider {
    @InjectProperty()
    private declare positivePayService: PositivePayService;

    achExceptionsActivityDataProvider = async (
        params: GridDataProviderParams<AchExceptionsDecisionsActivityRequestModelDto>,
        callback: GridDataProviderCallback<any>
    ) => {
        const { page, pageSize, sortOrders } = params;

        const { achExceptionDecisions, count } =
            await this.positivePayService.searchPaginatedAchExceptionsActivity({
                ...params,
                pagingParameters: {
                    pageSize,
                    pageNumber: page,
                    orderBys: sortOrders.map((sorter: GridSorterDefinition) => ({
                        name: sorter.path,
                        descending: sorter.direction === 'desc',
                    })),
                },
                searchText: '',
                searchAccountNickname: true,
                previousBusinessDayCutoff: '',
                currentBusinessDayCutoff: '',
            });

        callback(achExceptionDecisions || [], count);
    };
}
