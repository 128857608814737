/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    CopyCoreAchCompanyDataRequestDto,
    CopyCoreAchCompanyDataResponseDto,
    GetAchCompanyMatchTypeParametersResponseDto,
    RefreshCoreAchCompanyDataRequestDto,
    RefreshCoreAchCompanyDataResponseDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class AchCompanyClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AchCompany
     * @name AchCompanyGetAchCompanyMatchTypeParameters
     * @summary Retrieves ACH company match type parameters.
     * @request GET:/AchCompany/MatchTypeParameters/{achCompanyId}
     */
    achCompanyGetAchCompanyMatchTypeParameters = (
        achCompanyId: string,
        params: RequestParams = {}
    ) =>
        this.http.request<GetAchCompanyMatchTypeParametersResponseDto, any>({
            path: `/AchCompany/MatchTypeParameters/${achCompanyId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchCompany
     * @name AchCompanyCopyCoreAchCompanyData
     * @summary Copies ACH company data from the banking core to Treasury ACH company(s).
     * @request PUT:/AchCompany/CopyCoreData
     */
    achCompanyCopyCoreAchCompanyData = (
        request: CopyCoreAchCompanyDataRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<CopyCoreAchCompanyDataResponseDto, any>({
            path: `/AchCompany/CopyCoreData`,
            method: 'PUT',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchCompany
     * @name AchCompanyRefreshCoreAchCompanyData
     * @summary Refreshes an ACH company's data with up-to-date information from the banking core.
     * @request PUT:/AchCompany/RefreshCoreData
     */
    achCompanyRefreshCoreAchCompanyData = (
        request: RefreshCoreAchCompanyDataRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<RefreshCoreAchCompanyDataResponseDto, any>({
            path: `/AchCompany/RefreshCoreData`,
            method: 'PUT',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
