import { AchFrequencyModelDto } from '@treasury/api/channel';
import { AchTemplate } from '@treasury/domain/ach';
import {
    mapFrequencyModelDtoToFrequency,
    mapFrequencyToFrequencyModelDto,
} from '@treasury/presentation/components/tm-frequency.mappings';
import { Frequency } from '@treasury/presentation/components/tm-frequency.types';

export class AchTemplateVm extends AchTemplate {
    public get tmFrequency() {
        return mapFrequencyModelDtoToFrequency(this.dto.frequency);
    }

    public set tmFrequency(frequency: Frequency) {
        if (!frequency) return;
        this.dto.frequency = mapFrequencyToFrequencyModelDto(
            frequency,
            true
        ) as AchFrequencyModelDto;
    }
}
