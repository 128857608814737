/* eslint-disable no-use-before-define */
import 'reflect-metadata';
import { Constructor } from '../../types';
import { di$, DiContainer } from '../di-container';
import { FACTORY_DEPS, IS_INJECTABLE_KEY } from '../di.constants';
import { InjectConfig } from '../di.types';

/**
 * Decorate a class to register it as a provider with the DI container
 * and make it eligible for injection.
 */
export function Injectable<T = unknown, Deps extends Array<any> = unknown[]>(
    config: Partial<InjectConfig<T, Deps>> = {}
) {
    const { container, provider } = config;

    return function (ctor: Constructor<T>) {
        Reflect.defineMetadata(IS_INJECTABLE_KEY, true, ctor);

        if (provider) {
            Reflect.defineMetadata(FACTORY_DEPS, provider.dependencies, ctor);
        }

        if (container) {
            provideDependency(container, provider || ctor);
            return;
        }

        // register class with any new container instances that are emitted
        di$.subscribe(newContainer => {
            provideDependency(newContainer, provider || ctor);
        }, true);
    };
}

function provideDependency<T, Deps extends Array<any>>(
    container: DiContainer,
    ctorOrProvider: Constructor<T> | InjectConfig<T, Deps>['provider']
) {
    if (typeof ctorOrProvider !== 'function') {
        const { provide, dependencies, factory } = ctorOrProvider;
        container.provideFactory(provide, dependencies, factory);
        return;
    }

    container.provide(ctorOrProvider);
}
