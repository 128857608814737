import { Time } from '@treasury/alarm-clock/time';
import { TimeAccessDto } from '@treasury/api/channel';
import { InjectByToken, Injectable, InjectionToken, exists } from '@treasury/utils';
import { userTimeAccessDuration } from '../../shared/logout/time-access-duration';
import { LogoutCallback } from './logout.types';

const timeAccessLogoutWarningSeconds = 60;

interface TimeAccessDialogResult {
    closed: Promise<void>;
    close: () => void;
}

interface TimeAccessDialogConfig {
    warningSeconds: number;
    logoSource?: string;
}

export interface OpenTimeAccessDialogFn {
    (config: TimeAccessDialogConfig): Promise<TimeAccessDialogResult>;
}

export const OpenTimeAccessDialogToken = new InjectionToken<OpenTimeAccessDialogFn>(
    'OpenTimeAccessDialog'
);

@Injectable()
export class TimeAccessLogout {
    constructor(
        @InjectByToken(OpenTimeAccessDialogToken) private openDialog: OpenTimeAccessDialogFn
    ) {}

    /**
     * ID of handle returned from a `setTimeout()` call.
     */
    private timer?: number;

    private closeDialogFn?: TimeAccessDialogResult['close'];

    private get dialogOpen() {
        return exists(this.closeDialogFn);
    }

    public startLogoutTimer(
        fiCurrentTime: Time,
        timeAccessSettings: TimeAccessDto,
        callback: LogoutCallback
    ) {
        const warningTime = timeAccessLogoutWarningSeconds * 1000;
        const timeAccessDuration = userTimeAccessDuration(fiCurrentTime, timeAccessSettings);
        this.stopTimer();
        if (timeAccessDuration > 0) {
            this.timer = window.setTimeout(
                () => this.startAccessLogout(callback, timeAccessDuration),
                timeAccessDuration - warningTime
            );
        } else {
            this.logOut(callback);
        }
    }

    public stop() {
        this.stopTimer();
        this.dismissLogoutWarningModal();
    }

    private startAccessLogout(callback: LogoutCallback, timeAccessDuration: number) {
        let warningTime = timeAccessLogoutWarningSeconds * 1000;
        if (timeAccessDuration < warningTime) {
            warningTime = timeAccessDuration;
        }
        const warningSeconds = Math.trunc(warningTime / 1000);
        window.setTimeout(() => this.logOut(callback), warningTime);
        this.openLogoutWarningDialog(warningSeconds);
    }

    private async openLogoutWarningDialog(warningSeconds: number) {
        if (this.dialogOpen) {
            return;
        }

        const result = await this.openDialog({
            warningSeconds,
        });

        this.closeDialogFn = result.close;

        await result.closed;
    }

    private stopTimer() {
        if (this.timer) {
            window.clearTimeout(this.timer);
        }
        this.timer = undefined;
    }

    private dismissLogoutWarningModal() {
        if (!this.closeDialogFn) {
            return;
        }

        this.closeDialogFn();
        this.closeDialogFn = undefined;
    }

    private logOut(callback: LogoutCallback) {
        callback('Forced').then(() => {
            this.dismissLogoutWarningModal();
            window.location.href = 'login';
        });
    }
}
