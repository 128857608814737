import { UserAccountReconEntitlements } from '@treasury/domain/users/types/user-account-recon-entitlements.type';

export class UserAccountReconViewModel {
    constructor(readonly value: UserAccountReconEntitlements) {}

    get accounts() {
        return this.value?.accounts?.map((account: any) => ({
            name: account.name,
            number: account.number,
            enabled: account.userAccountClaims[0].hasUserClaim,
        }));
    }
}
