/* eslint-disable import/no-unresolved */

import { NavigationService } from '@treasury/core/navigation';
import { EntitlementsService } from '@treasury/domain/channel/services';
import { TmBaseComponent } from '@treasury/presentation';
import { dollarIcon, informationIcon, templateIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-bottom-sheet';
import { InjectProperty } from '@treasury/utils';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

export const tagName = 'create-wire-type-bottom-sheet';
@customElement(tagName)
export class CreateWireTypeBottomSheet extends TmBaseComponent {
    @property({ type: Boolean })
    bottomSheetOpen = false;

    @state()
    domesticEntitlement = false;

    @state()
    internationalEntitlement = false;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    private entitlementsService = new EntitlementsService();

    public async firstUpdated() {
        this.domesticEntitlement = await this.entitlementsService.hasEntitlement(
            'Wire, Domestic, Create Wire'
        );
        this.internationalEntitlement = await this.entitlementsService.hasEntitlement(
            'Wire, International, Create Wire'
        );
    }

    renderCreateUsdActionItem() {
        if (this.domesticEntitlement || this.internationalEntitlement) {
            return html`<jhd-route-action-item
                .icon=${dollarIcon}
                .action=${'Create USD Wire'}
                .actionDescription=${'Create USD wire payment(s)'}
                @click=${() => {
                    this.navService.navigate(`/wires/payment/create/new/usd`);
                }}
            ></jhd-route-action-item>`;
        }
        return nothing;
    }

    renderCreateUsdFromTemplateActionItem() {
        if (this.domesticEntitlement || this.internationalEntitlement) {
            return html` <jhd-route-action-item
                .icon=${templateIcon}
                .action=${'Create Wire from Template'}
                .actionDescription=${'Create USD wire payment(s) from template'}
                @click=${() => {
                    this.navService.navigate(`/wires/templates/initiate`);
                }}
            ></jhd-route-action-item>`;
        }
        return nothing;
    }

    render() {
        return html`<tm-bottom-sheet
            .open=${this.bottomSheetOpen}
            @close=${() => {
                this.bottomSheetOpen = false;
                this.dispatchEvent(new CustomEvent('close'));
            }}
        >
            <p slot="header-center">Create Wire Payment</p>
            <p slot="header-right">${informationIcon}</p>
            ${this.renderCreateUsdActionItem()} ${this.renderCreateUsdFromTemplateActionItem()}
        </tm-bottom-sheet>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    --navbar-height: 60px;
                }
                path {
                    fill: #ffffff;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: CreateWireTypeBottomSheet;
    }
}
