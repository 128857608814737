/* eslint-disable import/extensions */
import { NavigationService } from '@treasury/core/navigation';
import { BillPayAccountModel, PayeeListModel } from '@treasury/domain/bill-pay';
import BillPayService from '@treasury/domain/bill-pay/bill-pay.service';
import { TmApiError } from '@treasury/domain/shared';
import { NotificationService, TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-footer';
import { Frequency } from '@treasury/presentation/components/tm-frequency.types';
import { InjectProperty } from '@treasury/utils';
import { DeepReactive } from '@treasury/utils/lit-helpers';
import { css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import { BillPayAccountTableViewModel } from '../data/bill-pay-account-table-view-model';
import { BillPayWorkflowPaymentViewModel } from '../data/bill-pay-payment-workflow-view-model';
import { transformBillPayFormData } from '../data/bill-pay-transform-form-data';
import '../partials/business-bill-pay-confirm-step';
import '../partials/business-bill-pay-create-step';
import '../partials/business-bill-pay-review-step';

export type BillPayWorkflowData = {
    payee: PayeeListModel;
    fromAccount: BillPayAccountModel;
    amount: number;
    frequency: Frequency | undefined;
    createdDate?: Date;
    estimatedArrivalDate?: Date;
    comment: string;
    status?: string;
};

export const defaultPayment = {
    payee: {} as PayeeListModel,
    fromAccount: {} as BillPayAccountModel,
    amount: 0,
    frequency: undefined as Frequency | undefined,
    comment: '',
};

export const tagName = 'business-bill-pay-workflow-container';
@customElement(tagName)
export class BusinessBillPayWorkflowContainer extends TmBaseComponent {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare navigationService: NavigationService;

    @InjectProperty()
    private declare billPayService: BillPayService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @DeepReactive()
    private workflowData: BillPayWorkflowData = defaultPayment;

    @state()
    private step = 1;

    @state()
    private loading = false;

    @state()
    billPayAccounts?: BillPayAccountTableViewModel[];

    @state()
    payment?: BillPayWorkflowPaymentViewModel;

    async firstUpdated() {
        this.configureHeader();
        this.billPayAccounts = (await this.billPayService.getBillPayAccounts())?.map(
            (account: BillPayAccountModel) => new BillPayAccountTableViewModel(account)
        );
    }

    async submitPayment() {
        if (!this.workflowData) return;
        this.loading = true;
        try {
            const response = await this.billPayService.createBillPayment(
                transformBillPayFormData(this.workflowData)
            );
            if (response.errorSummary?.summaryMessage) {
                this.notificationService.renderError(response.errorSummary.summaryMessage);
                // display detailed errors in console
                console.error(response.errorSummary.summaryMessageList);
                return;
            }
            if (response.payment) {
                this.payment = new BillPayWorkflowPaymentViewModel(response.payment);
            }

            this.advanceStep();
        } catch (err) {
            if (err instanceof TmApiError) {
                this.notificationService.renderError(err);
            } else {
                this.notificationService.renderError(
                    'An error occurred while submitting bill payment'
                );
                console.error(err);
            }
        } finally {
            this.loading = false;
        }
    }

    advanceStep() {
        this.step++;
        this.configureHeader();
    }

    goBack() {
        this.step--;
        this.configureHeader();
    }

    configureHeader() {
        switch (this.step) {
            case 1:
                this.headerService.configure({
                    title: 'Create Payment',
                    backAction: () => this.navigationService.navigate(`/business-bill-pay`),
                });
                break;
            case 2:
                this.headerService.configure({
                    title: 'Review Payment',
                    backAction: () => this.goBack(),
                });
                break;
            default:
                this.headerService.configure({
                    title: 'Confirm Payment',
                    backAction: () => this.goBack(),
                });
                break;
        }
    }

    renderStep() {
        switch (this.step) {
            case 1:
                return html`<business-bill-pay-create-step
                    .payment=${this.workflowData}
                    .billPayAccounts=${this.billPayAccounts}
                    @payment-changed=${(e: CustomEvent) => {
                        this.workflowData = e.detail;
                    }}
                    @continue=${({ detail }: CustomEvent) => {
                        this.workflowData = detail;
                        this.advanceStep();
                    }}
                ></business-bill-pay-create-step>`;
            case 2:
                return html`<business-bill-pay-review-step
                    .payment=${this.workflowData}
                    @back=${() => {
                        this.goBack();
                    }}
                    @continue=${() => {
                        this.submitPayment();
                    }}
                ></business-bill-pay-review-step>`;
            default:
                return html`<business-bill-pay-confirm-step
                    .payment=${this.payment}
                    @create-another-payment=${() => {
                        this.payment = undefined;
                        this.workflowData = defaultPayment;
                        this.step = 1;
                        this.configureHeader();
                    }}
                ></business-bill-pay-confirm-step>`;
        }
    }

    renderLoader() {
        if (!this.loading) return nothing;
        return html`<tm-blocking-loader></tm-blocking-loader>`;
    }

    render() {
        return html`${this.renderLoader()}
            <div class="workflow-wrapper">${this.renderStep()}</div>`;
    }

    static get styles() {
        return [css``];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: BusinessBillPayWorkflowContainer;
    }
}
