// eslint-disable-next-line @nx/enforce-module-boundaries
import { tagName as forgotPasswordTagName } from '@treasury/domain-components/login/components/forgot-password';
import { tagName as ssoCallbackContainerTagName } from '@treasury/domain-components/login/sso-callback-container';
import { tagName as uisContainerTagName } from '@treasury/domain-components/login/uis-container';
import { Commands, Context } from '@vaadin/router';
import { tagName as achPaymentsContainerTagName } from '../ach/containers/ach-payments-container';
import { tagName as JhdDashboardTagName } from '../dashboard/containers/dashboard';
import { tagName as batchDepositContainerTagName } from '../deposits/containers/batch-deposit-container';
import { tagName as depositActivityContainerTagName } from '../deposits/containers/deposit-activity-container';
import { tagName as depositContainerTagName } from '../deposits/containers/deposit-container';
import { tagName as quickDepositContainerTagName } from '../deposits/containers/quick-deposit-container';
import { tagName as composeMessageContainerTagName } from '../message-center/containers/compose-message-container';
import { tagName as messageCenterContainerTagName } from '../message-center/containers/message-center-container';
import { tagName as achExceptionsContainerTagName } from '../positive-pay/ach-exceptions/containers/ach-exceptions-container';
import { tagName as achFiltersTagName } from '../positive-pay/ach-exceptions/containers/ach-filters-container';
import { tagName as checkExceptionDetailContainerTagName } from '../positive-pay/check-exceptions/containers/check-exception-detail-container';
import { tagName as checkExceptionsContainerTagName } from '../positive-pay/check-exceptions/containers/check-exceptions-container';
import { AuthGuard } from './authGuard';

const authGuard = new AuthGuard();
export const routes = [
    { path: '/:institution/login', component: uisContainerTagName },
    { path: '/:institution/login/sso', component: ssoCallbackContainerTagName },

    { path: '/:institution/forgot-password', component: forgotPasswordTagName },
    {
        path: '/:institution/terms-and-conditions-accept',
        component: 'terms-and-conditions',
    },
    {
        path: '/:institution',
        component: 'jhd-layout',
        action: (context: Context, commands: Commands) =>
            authGuard.pageEnabled(context, commands, '/:institution/login'),
        children: [
            { path: '/dashboard', component: JhdDashboardTagName },
            {
                path: '/accounts',
                component: 'accounts-container',
            },
            { path: '/accounts/:type/:id', component: 'account-container' },
            {
                path: '/accounts/:type/:accountId/transaction/:id',
                component: 'transaction-container',
            },
            { path: '/pending-approvals/:id', component: 'pending-approvals-container' },
            { path: '/ach', component: achPaymentsContainerTagName },
            { path: '/ach/:id', component: 'ach-payment-container' },
            { path: '/ach/templates/:action', component: 'ach-templates-container' },
            { path: '/ach/:type/:action/:id?', component: 'ach-workflow-container' },
            { path: '/business-bill-pay', component: 'business-bill-pay-container' },
            { path: '/business-bill-pay/payees', component: 'business-bill-pay-payees-container' },
            {
                path: '/business-bill-pay/payment/:type/:id',
                component: 'business-bill-pay-payment-detail-container',
            },
            {
                path: '/business-bill-pay/payees/:id',
                component: 'business-bill-pay-payee-detail-container',
            },
            {
                path: '/business-bill-pay/payments/:workflow/:id',
                component: 'business-bill-pay-workflow-container',
            },
            { path: '/loans/:id', component: 'loan-container' },
            { path: '/transfers', component: 'transfers-container' },
            {
                path: '/transfers/templates/:action?',
                component: 'transfer-templates-container',
            },
            { path: '/transfers/:id', component: 'transfer-container' },
            {
                path: '/transfers/:type/:workflow/:action/:id',
                component: 'transfer-workflow-container',
            },
            { path: '/wires', component: 'wires-container' },
            { path: '/wires/:id', component: 'wire-container' },
            { path: '/wires/template/:id', component: 'wire-template-container' },
            {
                path: '/wires/:workflow/:action/:id/:currency?',
                component: 'wire-workflow-container',
            },
            {
                path: '/wires/templates/:action?',
                component: 'wire-templates-container',
            },
            {
                path: '/positive-pay/check-exceptions',
                component: checkExceptionsContainerTagName,
            },
            {
                path: '/positive-pay/check-exceptions/:id',
                component: checkExceptionDetailContainerTagName,
            },
            {
                path: '/positive-pay/ach-exceptions',
                component: achExceptionsContainerTagName,
            },
            {
                path: '/positive-pay/ach-filters',
                component: achFiltersTagName,
            },
            { path: '/deposit', component: depositActivityContainerTagName },
            { path: '/quick-deposit', component: quickDepositContainerTagName },
            { path: '/batch-deposit', component: batchDepositContainerTagName },
            { path: '/create-deposit', component: depositContainerTagName },
            { path: '/message-center', component: messageCenterContainerTagName },
            { path: '/message-center/compose', component: composeMessageContainerTagName },
            { path: '/message-center/:id', component: 'message-center-detail-container' },
            { path: '/notifications', component: 'notifications-container' },
            { path: '/about', component: 'about-container' },
            { path: '/log-out', component: 'logout-container' },
            { path: '/profile', component: 'user-profile-container' },
            { path: '/users/:id', component: 'user-container' },
        ],
    },
    { path: '(.*)', component: 'not-found' },
];
