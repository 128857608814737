/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { WireCompanyModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class WireCompaniesClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags WireCompanies
     * @name WireCompaniesGet
     * @summary Search for Wire Companies for passed criteria.
     * @request GET:/WireCompanies/{id}/search
     */
    wireCompaniesGet = (
        id: number,
        query: {
            /** Contains search on Company Name */
            companyName: string | null;
            isDli: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<WireCompanyModelDto[], any>({
            path: `/WireCompanies/${id}/search`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
}
