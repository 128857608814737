/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { InstitutionModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class JhasupportClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags JhaSupport
     * @name JhaSupportGetInstitutions
     * @summary Gets institutions.
     * @request GET:/jhasupport/institutions
     */
    jhaSupportGetInstitutions = (
        query?: {
            /** The search text. */
            searchText?: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<InstitutionModelDto[], any>({
            path: `/jhasupport/institutions`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags JhaSupport
     * @name JhaSupportImpersonateInstitution
     * @summary Impersonates an institutions.
     * @request POST:/jhasupport/impersonateInstitution/{newInstitutionId}
     */
    jhaSupportImpersonateInstitution = (newInstitutionId: number, params: RequestParams = {}) =>
        this.http.request<string, any>({
            path: `/jhasupport/impersonateInstitution/${newInstitutionId}`,
            method: 'POST',
            format: 'json',
            ...params,
        });
}
