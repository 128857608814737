import { CredentialDto, UserModelDto } from '@treasury/api/channel';
import { ConfigurationService } from '@treasury/core/config';
import { TmHttpClient, TmHttpClientCached } from '@treasury/core/http';
import { NavigationService } from '@treasury/core/navigation';
import { ChannelAuthenticationService } from '@treasury/domain/channel/services/account/authentication-service';
import { StatusCode } from '@treasury/domain/channel/services/login';
import { AuthenticationService } from '@treasury/domain/services/authentication';
import { NotificationService, TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-bottom-sheet';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import './components/change-password';
import './components/login';
import './components/login-locked';
import './components/oob-registration';
import './components/partials/login-layout';
import './components/secure-token-registration';
import './components/security-questions';
import './components/security-questions-enrollment';
import './components/user-selection';
import { UserOptionViewModel } from './components/user-selection';
import './components/user-verification';
import { LoginPhases } from './data/login-phases';
import { LoginClient } from './login-client';

const genericErrorMessage =
    'We were unable to process your request at this time. Please try again in a moment.';

export const tagName = 'login-container';
@customElement(tagName)
export class LoginContainer extends TmBaseComponent {
    constructor() {
        super();
        Object.keys(sessionStorage).forEach(key => {
            sessionStorage.removeItem(key);
        });
        this.verifiedUserResponse = {
            data: '',
            daysUntilPasswordExpires: 0,
            numberOfSecurityQuestions: 0,
        };
    }

    verifiedUserRecord: any;

    verifiedUserResponse: {
        data: any;
        daysUntilPasswordExpires: any;
        numberOfSecurityQuestions: any;
    };

    @property({ reflect: true }) statusCode? = StatusCode.Login;

    @state()
    public loginResult?: CredentialDto;

    @state()
    public loading = false;

    @state()
    private errorMessage?: string = '';

    @state()
    public userOptions: Array<UserOptionViewModel> = [];

    @InjectProperty()
    public declare readonly client: LoginClient;

    @InjectProperty()
    private declare readonly authService: AuthenticationService;

    @InjectProperty()
    private declare readonly channelAuthService: ChannelAuthenticationService;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    @InjectProperty()
    private declare readonly config: ConfigurationService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    private nonSecureCredentials?: { companyId: string; loginId: string };

    private readonly brandedBackgroundPhases: StatusCode[] = [
        StatusCode.Login,
        StatusCode.Locked,
        StatusCode.Loading,
        StatusCode.ChallengeQuestions,
        StatusCode.ResetPassword,
        StatusCode.ResetPasswordEnrollment,
        StatusCode.ChallengeResetPassword,
        StatusCode.ChallengeResetExpiredPassword,
        StatusCode.ResetExpiredPassword,
    ];

    private readonly topAffixedPhases: StatusCode[] = [StatusCode.Locked, StatusCode.Loading];

    get hasLoginCode() {
        return !!new URLSearchParams(window.location.search).get('code');
    }

    set hasLoginCode(value) {
        this.hasLoginCode = value;
    }

    protected async firstUpdated() {
        const http = await TmHttpClient.getInstance();
        if (!(http instanceof TmHttpClientCached)) {
            throw new Error('Cannot clear HTTP cache. The client does not implement caching.');
        }

        http.clearCache();

        /* Close any notifications that may have been open on either session timeout or manual logout */
        document
            .querySelectorAll('vaadin-notification')
            .forEach(notification => notification.close());
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('loadingStatusChange', this.loadingStatusChange as EventListener);
    }

    disconnectedCallback() {
        this.removeEventListener('loadingStatusChange', this.loadingStatusChange as EventListener);
    }

    private loadingStatusChange(e: CustomEvent) {
        this.loading = e.detail;
    }

    async login(e: CustomEvent) {
        this.loading = true;
        try {
            const { companyId, loginId, password } = e.detail;
            const credentials = {
                institution: this.config.institutionId,
                companyId,
                loginId,
                password,
            };
            this.nonSecureCredentials = {
                companyId,
                loginId,
            };
            this.loginResult = await this.client.login(credentials);
            const { statusCode, message } = this.loginResult;
            if (statusCode === StatusCode.Invalid) {
                throw new Error(message);
            }
            this.statusCode = await this.channelAuthService.startAuthWorkflow(this.loginResult);
            this.errorMessage = '';
        } catch (err: any) {
            this.errorMessage = err.message ?? genericErrorMessage;
        } finally {
            this.loading = false;
        }
    }

    async completeOutOfBandConfig() {
        await this.client.completeOutOfBandConfig();
        this.completeLogin();
    }

    async registerSecureToken(e: CustomEvent) {
        const secureTokenDetails = e.detail;
        this.loading = true;
        try {
            const { actionType, credentialId, token, pin } = secureTokenDetails;
            const { success } = await this.client.registerSecureToken(
                actionType,
                credentialId,
                token,
                pin
            );
            if (success) {
                this.errorMessage = '';
                this.statusCode = await this.channelAuthService.goToNextStep();
            } else {
                this.errorMessage = 'There was an error in your entry. Please try again.';
            }
        } catch (err) {
            this.errorMessage = (err as Error)?.message ?? genericErrorMessage;
        } finally {
            this.loading = false;
        }
    }

    async validateOneTimePassword(
        { oneTimePassword }: any,
        dialog: { open: boolean; verifying: boolean; securityMessage: any }
    ) {
        const result = await this.client.validateOneTimePassword(oneTimePassword);
        if (result.status === 'Success') {
            dialog.open = false;
            this.checkTermsAndConditions();
        } else {
            dialog.verifying = false;
            dialog.securityMessage = {
                ...dialog.securityMessage,
                ...result,
            };
        }
    }

    async userSelected(userCompanyUniqueId: string) {
        const { message, alias, companyUniqueId, digitalId } =
            await this.authService.authenticateSelectedCompanyUser(userCompanyUniqueId);

        const loginData = {
            institution: this.config.institutionId,
            companyId: companyUniqueId,
            loginId: alias,
            userName: alias,
            useRefreshTokens: false,
            digitalId,
        };

        window.sessionStorage.setItem('user', JSON.stringify(loginData));

        this.statusCode = await this.channelAuthService.startAuthWorkflow({
            statusCode: StatusCode.Allow,
            message,
            numberOfSecurityQuestions: 0,
            daysUntilPasswordExpires: 0,
        });
    }

    async completeLogin() {
        this.statusCode = await this.channelAuthService.goToNextStep();
    }

    async submitUserVerificationForm({
        detail,
    }: {
        detail: { formValues: UserModelDto; record: unknown };
    }) {
        try {
            this.loading = true;
            const {
                statusCode,
                message,
                data,
                daysUntilPasswordExpires,
                numberOfSecurityQuestions,
            } = await this.client.verifyUser(detail.formValues);
            if (statusCode === StatusCode.Invalid && message) {
                this.notificationService.renderError(message);
                return;
            }
            this.verifiedUserRecord = detail.record;
            this.verifiedUserResponse = {
                data,
                daysUntilPasswordExpires,
                numberOfSecurityQuestions,
            };
            this.statusCode = await this.channelAuthService.goToNextStep();
        } catch (err) {
            this.notificationService.renderError(err as Error);
        } finally {
            this.loading = false;
        }
    }

    getHeaderText() {
        if (!this.statusCode) {
            return '';
        }

        return LoginPhases[this.statusCode]?.title;
    }

    async checkTermsAndConditions() {
        const { isComplete } = await this.client.checkLoginCompletion();
        const result = await this.client.getTermsAndConditions();

        if (!isComplete && result && result.text) {
            this.navService.navigate(`/terms-and-conditions-accept`);
        } else {
            sessionStorage.setItem('termsAndConditions', 'true');
            this.navService.navigate(`/dashboard`);
        }
    }

    async saveSecurityQuestions(e: CustomEvent) {
        this.loading = true;
        try {
            const questions = e.detail;
            const success = await this.client.saveSecurityQuestions(questions);
            if (success) {
                this.statusCode = await this.channelAuthService.goToNextStep();
            }
        } catch (err) {
            this.notificationService.renderError(err as Error);
        } finally {
            this.loading = false;
        }
    }

    async verifySecurityQuestions(e: CustomEvent) {
        this.loading = true;
        try {
            const questions = e.detail;
            const response = await this.client.verifySecurityQuestions(questions);
            if (!response.statusCode || response.statusCode === 'Undefined') {
                throw new Error(response.message);
            } else {
                this.statusCode = await this.channelAuthService.goToNextStep();
                this.errorMessage = '';
            }
        } catch (err) {
            this.notificationService.renderError(err as Error);
        } finally {
            this.loading = false;
        }
    }

    forgotPassword(user: UserModelDto) {
        this.client.forgotPassword(user);
    }

    async updatePassword(e: CustomEvent) {
        this.loading = true;
        try {
            const formData: UserModelDto = {
                companyID: this.nonSecureCredentials!.companyId,
                loginID: this.nonSecureCredentials!.loginId,
                institution: this.config.institutionId,
                password: e.detail,
                useClientBiometricsAuthentication: false,
                id: 0,
            };
            this.loading = true;
            await this.client.updatePassword(formData);

            this.statusCode = (await this.channelAuthService.goToNextStep()) || StatusCode.Unknown;
        } catch (err) {
            this.notificationService.renderError(err as Error);
        } finally {
            this.loading = false;
        }
    }

    renderErrorMessage() {
        if (!this.errorMessage) return nothing;
        return html`
            <div class="error">
                <omega-icon icon="times-circle"></omega-icon>
                <span class="error-message ml-3 text-sm">${this.errorMessage}</span>
            </div>
        `;
    }

    renderPhase() {
        if (!this.statusCode) {
            return nothing;
        }

        return LoginPhases[this.statusCode]?.render(this);
    }

    renderBlockingLoader() {
        if (this.loading) return html`<tm-blocking-loader></tm-blocking-loader>`;
        return nothing;
    }

    render() {
        if (!this.statusCode) {
            return nothing;
        }

        if (LoginPhases[this.statusCode]?.isDialog) {
            return this.renderPhase();
        }
        return html`
            ${this.renderBlockingLoader()}
            <login-layout
                .headerText=${this.getHeaderText() || ''}
                .stickyHeader=${!this.brandedBackgroundPhases.includes(this.statusCode)}
                .bottomAffixed=${!this.topAffixedPhases.includes(this.statusCode)}
            >
                <span slot="phase"> ${this.renderErrorMessage()} ${this.renderPhase()}</span>
            </login-layout>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                    overflow: hidden;
                    height: 100%;
                }
                .error omega-icon {
                    color: var(--body-text-alert-color);
                }
                .error {
                    border: 1px solid var(--body-text-alert-color);
                    border-left: 8px solid var(--body-text-alert-color);
                    border-radius: 4px;
                    padding: 7px 15px;
                    margin: 15px;
                    display: flex;
                    align-items: center;
                    color: var(--primary-text-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: LoginContainer;
    }
}
