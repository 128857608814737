// eslint-disable-next-line import/no-extraneous-dependencies
import { TmHttpClient } from '@treasury/core/http';
import { format } from 'date-fns';
import { Notification } from '../../types/notifications.dto';

export class NotificationsService {
    static async getNotifications() {
        const http = await TmHttpClient.getInstance();
        const response = await http.request<Notification[]>('notifications/?html=true', {
            method: 'GET',
        });
        return response.map((notification: Notification) => ({
            ...notification,
            createdOn: format(new Date(notification.createdOn), 'Pp'),
        }));
    }

    static async updateNotificationReadStatus(notificationIds: { id: number }[], isRead: boolean) {
        return (await TmHttpClient.getInstance()).request(`notifications/setRead?value=${isRead}`, {
            method: 'PUT',
            body: notificationIds,
        });
    }

    static async deleteNotifications(notificationIds: { id: number }[]) {
        return (await TmHttpClient.getInstance()).request(`notifications/`, {
            method: 'DELETE',
            body: notificationIds,
        });
    }
}
