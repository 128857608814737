/* eslint-disable import/no-unresolved */
import { TmBaseComponent } from '@treasury/presentation';
import { attachIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-button';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { Attachment, Message } from '../types/message.types';
import './compose-message-form';

export const tagName = 'message-detail';
@customElement(tagName)
export class MessageDetail extends TmBaseComponent {
    @property({ type: Array })
    messages: Message[] = [] as Message[];

    @property({ type: Boolean })
    loading = false;

    @property({ type: Boolean })
    downloading = false;

    @state()
    private isReplying = false;

    archiveMessage() {
        this.dispatchEvent(new CustomEvent('archive-message'));
    }

    replyToMessage() {
        this.isReplying = true;
    }

    renderMessageForm() {
        if (!this.isReplying) return nothing;
        return html`<compose-message-form
            .message=${this.messages}
            .isReplying=${this.isReplying}
            @send-message=${(e: CustomEvent) => {
                this.dispatchEvent(new CustomEvent('send-message', { detail: e.detail }));
                this.isReplying = false;
            }}
            @cancel-message=${() => {
                this.isReplying = false;
                this.dispatchEvent(new CustomEvent('cancel-message'));
            }}
            @cancel=${() => {
                this.isReplying = false;
            }}
        ></compose-message-form>`;
    }

    renderMessageDetails() {
        if (this.messages.length) {
            const messages = this.messages.map(
                (message: Message) =>
                    html`<div class="message-details text-sm">
                        <div class="message-details-header mb-2">
                            <div class="message-details-header-left">
                                <div class="message-id font-medium">${message.messageId}</div>
                                <div class="text-xs">
                                    From
                                    <span class="from-user-text font-medium"
                                        >${message.fromToUserName}</span
                                    >
                                </div>
                            </div>
                            <div class="message-time text-xs">
                                <div>${message.sentDate}</div>
                                <div>${message.sentTime}</div>
                            </div>
                        </div>
                        <div class="message-details-body">
                            <p>${message.body}</p>
                        </div>
                        ${this.renderAttachmentDownload(message)}
                    </div>`
            );
            return html`<div class="messages-wrapper">${messages}</div>`;
        }
        return nothing;
    }

    renderAttachmentDownload(message: Message) {
        if (message.attachments.length) {
            return message.attachments.map(
                (attachment: Attachment) => html`
                    <tm-button
                        class="mt-2"
                        importance="tertiary"
                        @click=${() =>
                            this.dispatchEvent(
                                new CustomEvent('download-attachment', {
                                    detail: {
                                        attachmentId: message.attachments[0].id,
                                        attachmentName: message.attachments[0].name,
                                    },
                                })
                            )}
                        ><span>${attachIcon} ${attachment.name}</span>
                    </tm-button>
                `
            );
        }
        return nothing;
    }

    renderActionButtons() {
        return html`
            <tm-button
                class="m-1"
                .disabled=${this.messages[0]?.isArchived}
                @click=${() => this.archiveMessage()}
            >
                Archive
            </tm-button>
            <tm-button class="m-1" @click=${() => this.replyToMessage()}> Reply </tm-button>
        `;
    }

    render() {
        return html`<tm-body
            ><div class="button-bar">${this.renderActionButtons()}</div>
            ${this.renderMessageForm()} ${this.renderMessageDetails()}</tm-body
        >`;
    }

    static get styles() {
        return [
            css`
                .button-bar {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 100%;
                }
                .messages-wrapper {
                    padding: 10px 10px;
                    border-radius: 5px;
                    margin: 5px;
                    color: var(--header-text-color);
                }
                .message-details:not(:last-child) {
                    padding-bottom: 10px;
                    border-bottom: 1px dashed var(--divider-default-color);
                }
                .message-details:not(:first-child) {
                    padding-top: 10px;
                    border-top: 1px dashed var(--divider-default-color);
                }
                .message-details-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;
                }
                .from-text {
                    color: var(--secondary-text-color);
                    font-size: 14px;
                    line-height: 16px;
                }
                .from-user-text {
                    color: var(--header-text-color);
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 500;
                }
                svg {
                    display: inline-block;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: MessageDetail;
    }
}
