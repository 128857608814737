import { css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { TmBaseComponent } from '../../tm-base.component';

export const tagName = 'tm-content';
@customElement(tagName)
export class TmContent extends TmBaseComponent {
    render() {
        return html`<slot></slot>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    row-gap: 0.625rem;
                    box-shadow: var(--card-shadow);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmContent;
    }
}
