import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { TmBaseComponent } from '..';
import { arrowBackIcon } from '../assets/icons/icon-arrow-back';

export const tagName = 'tm-bottom-sheet';
@customElement(tagName)
export class TmBottomSheet extends TmBaseComponent {
    @property({ type: Boolean })
    open = false;

    @property({ type: Boolean })
    shadow = true;

    @property({ type: Number })
    top = 0;

    @property({ type: String, reflect: true })
    direction: 'horizontal' | 'vertical' = 'vertical';

    @property({ type: Boolean })
    closeable = true;

    @property({ type: Boolean })
    loading = false;

    render() {
        return html`<div
            class=${classMap({
                overlay: true,
                fixed: true,
                'top-0': true,
                'left-0': true,
                'w-full': true,
                'h-full': this.open,
                'overflow-y-auto': true,
                overlayOpen: this.open,
                overlayHidden: !this.open,
            })}
            style=${`top: ${this.top}px`}
        >
            <header class="header-bar relative flex items-center">
                <div class="header-title fixed left-0 w-full text-center text-lg font-normal">
                    <slot name="header-center"></slot>
                </div>
                <div class="flex flex-row justify-between items-center w-full z-10">
                    <div class="header-back flex items-center w-6 h-12 cursor-pointer">
                        ${this.closeable
                            ? html`<button
                                  id="back-button"
                                  class="cursor-pointer border-none outline-none"
                                  @click=${() => this.dispatchEvent(new CustomEvent('close'))}
                              >
                                  ${arrowBackIcon}
                              </button>`
                            : nothing}
                    </div>
                    <div class="header-actions">
                        <slot name="header-right"></slot>
                    </div>
                </div>
            </header>
            <div
                class=${classMap({
                    content: true,
                    'overflow-y-auto': true,
                    'content-shadow': this.shadow,
                })}
            >
                ${this.loading ? html`<tm-blocking-loader></tm-blocking-loader>` : nothing}
                <slot></slot>
            </div>
        </div>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                }
                path {
                    fill: #fff;
                }
                #back-button {
                    background: transparent;
                }
                .overlay {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    background: var(--content-background);
                }
                .overlayHidden {
                    height: 0;
                    opacity: 0;
                    visibility: hidden;
                    -webkit-transition:
                        opacity 0.5s,
                        visibility 0s 0.5s;
                    transition:
                        all 0.5s,
                        height 0s 0.4s,
                        visibility 0s 0.5s;
                    transform: translate3d(0, 100%, 0);
                }
                :host([horizontal]) .overlayHidden {
                    transform: translate3d(100%, 0, 0);
                }
                :host([vertical]) .overlayHidden {
                    transform: translate3d(0, 100%, 0);
                }
                .overlayOpen {
                    opacity: 1;
                    visibility: visible;
                    z-index: 100;
                    -webkit-transition: opacity 0.5s;
                    transition:
                        all 0.5s,
                        height 0s;
                    transform: translate3d(0, 0, 0);
                }
                header {
                    color: var(--nav-text-color);
                    height: var(--navbar-height, 40px);
                    background: var(--header-gradient, rgba(255, 255, 255, 0.05));
                    padding: 10px;
                }
                .content {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                .content-shadow slot {
                    box-shadow: var(--content-shadow);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmBottomSheet;
    }
}
