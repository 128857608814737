import { PaymentMethodDto, PaymentModelDto, PaymentStatusDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';

export const getStatusString = (status: PaymentStatusDto) => {
    switch (status) {
        case PaymentStatusDto.Unknown:
            return 'Unknown';
        case PaymentStatusDto.Processed:
            return 'Processed';
        case PaymentStatusDto.Paid:
            return 'Paid';
        case PaymentStatusDto.Stopped:
            return 'Stopped';
        case PaymentStatusDto.Cancelled:
            return 'Cancelled';
        case PaymentStatusDto.Resubmitted:
            return 'Resubmitted';
        case PaymentStatusDto.Refunded:
            return 'Refunded';
        case PaymentStatusDto.Skipped:
            return 'Skipped';
        case PaymentStatusDto.Approved:
            return 'Approved';
        case PaymentStatusDto.Scheduled:
            return 'Scheduled';
        case PaymentStatusDto.Pending:
            return 'Pending';
        case PaymentStatusDto.PendingSkip:
            return 'Pending Skip';
        case PaymentStatusDto.ApprovalRequired:
            return 'Approval Required';
        case PaymentStatusDto.Settled:
            return 'Settled';
        case PaymentStatusDto.Returned:
            return 'Returned';
        default:
            return 'Unknown';
    }
};

export const getPaymentMethodString = (paymentMethod: PaymentMethodDto) => {
    switch (paymentMethod) {
        case PaymentMethodDto.Check:
            return 'Check';
        case PaymentMethodDto.Email:
            return 'Email';
        case PaymentMethodDto.Electronic:
            return 'Electronic';
        default:
            return 'Unknown';
    }
};

export class PaymentListModel extends DomainEntity<PaymentModelDto> {
    constructor(payment: PaymentModelDto) {
        super(payment);
    }

    public createDefault() {
        return new PaymentListModel({} as PaymentModelDto) as this;
    }

    public get payee() {
        return this.dto.payee;
    }

    public get fromAccount() {
        return this.dto.fromAccount;
    }

    public get paymentMethod() {
        return getPaymentMethodString(this.dto.paymentMethod || 0);
    }

    public get status() {
        return getStatusString(this.dto.status || 0);
    }

    public get amount() {
        return this.dto.amount;
    }

    public get processDate() {
        return this.dto.processDate;
    }

    public get recurrence() {
        return this.dto.recurrence;
    }

    public get id() {
        return this.dto.id;
    }
}
