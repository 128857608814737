import { PendingAchPayment } from '@treasury/domain/approvals/pending-ach.entity';
import { ApprovalOptions, ApprovalVm } from '@treasury/presentation';
import { formatUsdCurrency } from '@treasury/utils';

export class PendingAchPaymentViewModel extends ApprovalVm<PendingAchPayment> {
    constructor(achPayment: PendingAchPayment) {
        super(achPayment, ApprovalOptions.NA);
    }

    get creditAmount() {
        return formatUsdCurrency(this.value.creditAmount);
    }

    get debitAmount() {
        return formatUsdCurrency(this.value.debitAmount);
    }
}
