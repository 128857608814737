import { Account } from '@treasury/domain/dashboard';
import { formatUsdCurrency } from '@treasury/utils';

export class AccountViewModel {
    constructor(private readonly account: Account) {}

    get currentBalanceFormatted() {
        return formatUsdCurrency(this.currentBalance);
    }

    get currentBalance() {
        return this.account.currentBalance || 0;
    }

    get availableBalanceFormatted() {
        return formatUsdCurrency(this.availableBalance);
    }

    get availableBalance() {
        return this.account.availableBalance || 0;
    }

    get collectedBalanceFormatted() {
        return formatUsdCurrency(this.collectedBalance);
    }

    get collectedBalance() {
        return this.account.collectedBalance || 0;
    }

    get name() {
        return this.account.name;
    }

    get number() {
        return this.account.number;
    }

    get type() {
        return this.account.type;
    }

    get id() {
        return this.account.toDto().id;
    }
}
