import {
    AuditModelDto,
    CheckExceptionCorrectionClient,
    ErrorResponseModelDto,
    ExceptionCorrectionModelDto,
} from '@treasury/api/channel';
import { HttpResponse } from '@treasury/api/channel/generated/http-client';
import { NavigationService } from '@treasury/core/navigation';
import { CheckException } from '@treasury/domain/arp';
import CheckExceptionsServices from '@treasury/domain/channel/services/positive-pay/check-exceptions-services';
import PositivePayService from '@treasury/domain/channel/services/positive-pay/positive-pay-service';
import { CheckExceptionReturnReasons } from '@treasury/domain/channel/types';
import { LabeledList, TmContainer } from '@treasury/presentation';
import { informationIcon, uploadIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/forms/tm-form-row';
import '@treasury/presentation/components/forms/tm-slider';
import '@treasury/presentation/components/layout/tm-content';
import '@treasury/presentation/components/layout/tm-section';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-labeled-list';
import { InjectProperty } from '@treasury/utils';
import { DeepReactive } from '@treasury/utils/lit-helpers';
import { TemplateResult, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { HeaderBarService } from '../../../services/jhd-header-bar.service';
import { MenuItem } from '../../../services/types/jhd-header-bar.type';
import { CheckExceptionAudit, CheckImage } from '../../types/check-exception.types';
import '../partials/detail/check-exception-comment-editor';
import '../partials/detail/check-exception-correction';
import '../partials/detail/check-exception-select-from-list';
import '../partials/detail/correction-request-confirmation';
import { renderHeaderText } from '../partials/header-text';
import { CheckExceptionVm } from '../view-models/checkException';

export const tagName = 'check-exception-detail-container';
@customElement(tagName)
export class CheckExceptionDetailContainer extends TmContainer {
    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare positivePayService: PositivePayService;

    @InjectProperty()
    private declare checkCorrectionClient: CheckExceptionCorrectionClient;

    @property()
    selectedException!: CheckException;

    @property()
    backToTableView!: () => void;

    @property()
    returnReasons!: Array<CheckExceptionReturnReasons>;

    @property()
    afterCutoff?: boolean;

    @DeepReactive()
    private checkException!: CheckException;

    @state()
    private checkImages?: CheckImage;

    @state()
    private menuItems: MenuItem[] = [
        { title: 'Info', icon: informationIcon, action: () => console.log('clicked') },
    ];

    @state()
    private selectedReturnReason?: CheckExceptionReturnReasons;

    @state()
    private requestingCorrection = false;

    @state()
    private reviewingCorrectionRequest = false;

    @state()
    private viewingAudit = false;

    @state()
    private showCorrectionRequestConfirmation = false;

    @state()
    private correctionRequestResponse: boolean | HttpResponse<boolean, ErrorResponseModelDto> =
        false;

    @state()
    footerConfig: Array<ButtonConfig> = [
        {
            error: true,
            text: 'Return',
            onClick: this.returnException.bind(this),
            size: 'large',
        },
        {
            success: true,
            text: 'Pay',
            onClick: this.payException.bind(this),
            size: 'large',
        },
    ];

    @state()
    correction?: ExceptionCorrectionModelDto;

    @state()
    attachment?: File;

    viewModel!: CheckExceptionVm;

    get showFooter() {
        const showingSubView =
            this.requestingCorrection ||
            this.showCorrectionRequestConfirmation ||
            this.reviewingCorrectionRequest;
        return !this.afterCutoff || showingSubView;
    }

    async firstUpdated() {
        this.initHeaderAndFooter();
        const checkException = this.selectedException;
        this.checkException = checkException;
        this.viewModel = new CheckExceptionVm(checkException);
        this.selectedReturnReason = this.returnReasons.find(reason => {
            const exceptionReason = this.checkException.returnReasonDescription?.replace(/\s/g, '');
            const reasonFromList = reason.description.replace(/\s/g, '');
            return exceptionReason === reasonFromList;
        });
        if (this.selectedException.protected) {
            this.footerConfig = [];
        }

        this.tryFetch(this.getCheckImages.bind(this), imageData => {
            this.checkImages = imageData;
        });
    }

    async getCheckImages() {
        return this.checkException.checkImageNumber
            ? CheckExceptionsServices.fetchCheckImages(this.checkException.checkImageNumber ?? '')
            : {
                  error: true,
                  backImage: '',
                  frontImage: '',
                  checkId: '',
              };
    }

    returnException() {
        this.checkException.return();
        this.checkException.decisionModified = true;
        this.backToTableView();
    }

    payException() {
        this.checkException.pay();
        this.checkException.decisionModified = true;
        this.backToTableView();
    }

    async submitComment() {
        await this.tryFetch(
            () =>
                CheckExceptionsServices.saveAttachmentAndComment(
                    this.checkException,
                    this.attachment
                ),
            () => {
                // to do: handle error and success case, no designs provided
                this.returnToDetailView();
            }
        );
    }

    initHeaderAndFooter() {
        this.headerService.configure({
            title: 'Check Exception Detail',
            menuItems: this.menuItems,
            backAction: this.backToTableView,
        });
        const footerConfig: Array<ButtonConfig> = [
            {
                error: true,
                text: 'Return',
                onClick: this.returnException.bind(this),
                classes: 'px-1 w-1/2',
                size: 'large',
            },
            {
                success: true,
                text: 'Pay',
                onClick: this.payException.bind(this),
                classes: 'px-1 w-1/2',
                size: 'large',
            },
        ];
        this.configureFooter(footerConfig);
    }

    configureFooter(details: Array<ButtonConfig>) {
        this.footerConfig = details;
    }

    returnToDetailView() {
        this.requestingCorrection = false;
        this.viewingAudit = false;
        this.initHeaderAndFooter();
    }

    clickSelectReturnReason() {
        this.headerService.configure({
            title: html`<h1 class="font-medium">Return Reason</h1>
                ${this.selectedException.checkNumber
                    ? html`<h2 class="text-xs font-light">
                          Check ${this.selectedException.checkNumber}
                      </h2>`
                    : nothing}`,
            menuItems: [
                { title: 'Upload', icon: uploadIcon, action: () => console.log('clicked') },
            ],
            backAction: this.returnToDetailView.bind(this),
        });
        this.configureFooter([]);
    }

    clickSelectComment() {
        this.headerService.configure({
            title: html`<h1 class="font-medium">Comment</h1>
                ${this.selectedException.checkNumber
                    ? html`<h2 class="text-xs font-light">
                          Check ${this.selectedException.checkNumber}
                      </h2>`
                    : nothing}`,
            menuItems: [
                { title: 'Upload', icon: uploadIcon, action: () => console.log('clicked') },
            ],
            backAction: this.returnToDetailView.bind(this),
        });
        this.configureFooter([
            {
                importance: 'primary',
                text: 'Done',
                onClick: this.submitComment.bind(this),
                size: 'large',
            },
        ]);
    }

    clickViewAudit() {
        this.viewingAudit = true;
        this.headerService.configure({
            title: html`<h1 class="font-medium">Audit</h1>
                ${this.selectedException.checkNumber
                    ? html`<h2 class="text-xs font-light">
                          Check ${this.selectedException.checkNumber}
                      </h2>`
                    : nothing}`,
            menuItems: [
                { title: 'Upload', icon: uploadIcon, action: () => console.log('clicked') },
            ],
            backAction: this.returnToDetailView.bind(this),
        });
        this.configureFooter([]);
    }

    async submitCorrectionRequest() {
        await this.configureFooter([
            {
                ...this.footerConfig[0],
                disabled: true,
            },
        ]);

        if (!this.correction) throw new Error('correction data not found');
        const correctionRequest = async () =>
            this.checkCorrectionClient.checkExceptionCorrectionRequestExceptionCorrection(
                this.correction ?? null
            );

        await this.tryFetch(correctionRequest, result => {
            this.correctionRequestResponse = result;
            this.headerService.configure({
                title: renderHeaderText(
                    'Correction Request',
                    this.selectedException.checkNumber
                        ? `Check ${this.selectedException.checkNumber}`
                        : ''
                ),
                backAction: this.returnToDetailView.bind(this),
            });
            this.configureFooter([
                {
                    importance: 'primary',
                    size: 'large',
                    text: 'Return to Check Exceptions',
                    onClick: () => this.backToTableView(),
                },
            ]);
            this.showCorrectionRequestConfirmation = true;
        });
    }

    reviewCorrectionRequest() {
        this.reviewingCorrectionRequest = true;
        this.headerService.configure({
            title: renderHeaderText(
                'Correction Request',
                this.selectedException.checkNumber
                    ? `Check ${this.selectedException.checkNumber}`
                    : ''
            ),
            menuItems: [
                { title: 'Upload', icon: uploadIcon, action: () => console.log('clicked upload') },
            ],
            backAction: this.navToRequestCorrection.bind(this),
        });
        this.configureFooter([
            {
                importance: 'primary',
                size: 'large',
                text: 'Send Request',
                onClick: () => this.submitCorrectionRequest(),
            },
        ]);
    }

    navToRequestCorrection() {
        this.requestingCorrection = true;
        this.reviewingCorrectionRequest = false;
        this.headerService.configure({
            title: renderHeaderText(
                'Correction Request',
                this.selectedException.checkNumber
                    ? `Check ${this.selectedException.checkNumber}`
                    : ''
            ),
            menuItems: [],
            backAction: this.returnToDetailView.bind(this),
        });
        this.configureFooter([
            {
                text: 'Review',
                size: 'large',
                onClick: () => this.reviewCorrectionRequest(),
            },
        ]);
    }

    setReturnReason(reason: CheckExceptionReturnReasons) {
        this.selectedReturnReason = reason;
        this.checkException.returnReasonDescription = reason.description;
    }

    renderCheckImages() {
        const getSpan = (str: string) =>
            html`<span class="secondary-color font-light text-sm pb-4" slot="extra"
                >${this.loading ? '...finding check images' : str}</span
            >`;

        if (!this.checkImages || this.checkImages.error) {
            return getSpan('No check images found');
        }
        return html`
            <div class="check-image border-solid image-front" slot="extra">
                <img
                    src="data:image/jpeg;base64,${encodeURI(this.checkImages.frontImage)}"
                    alt="Front of check"
                    style="width: 100%"
                />
            </div>
            <br />
            <div class="check-image image-back" slot="extra">
                <img
                    src="data:image/jpeg;base64,${encodeURI(this.checkImages.backImage)}"
                    alt="Back of check"
                    style="width: 100%"
                />
            </div>
        `;
    }

    renderAuditFromList(audit: CheckExceptionAudit | AuditModelDto): TemplateResult {
        return html` <div class="audit-time">
                <span class="secondary-text-color"> ${audit.timestamp} </span>
            </div>
            <div class="audit-data">${audit.username} : ${audit.action}</div>`;
    }

    render() {
        if (!this.checkException) return nothing;
        const returnAction = this.navToRequestCorrection.bind(this);
        const changeReturnReason = this.setReturnReason.bind(this);

        let contentBody = html`
            <tm-section>
                <tm-form-row label="Check">
                    <span class="check-number" slot="sublabel">
                        ${this.checkException.checkNumber}
                    </span>
                    <div class="text-right">
                        <span class="badge">${this.checkException.exceptionReason}</span>
                    </div>
                    <div slot="extra" class="flex pb-4">
                        <div class="amount paid-amount">
                            <div class="text-sm secondary-color">Paid</div>
                            <div class="text-xl">${this.viewModel?.paidAmount}</div>
                        </div>
                        <div class="amount issued-amount">
                            <div class="text-sm secondary-color">Issued</div>
                            <div class="text-xl">${this.viewModel?.issuedAmount}</div>
                        </div>
                    </div>
                    <div slot="summary" class="py-4 summary text-left">
                        <tm-labeled-list
                            .list=${new LabeledList(
                                this.viewModel,
                                [
                                    'accountNumber',
                                    'postedDate',
                                    'issuedDate',
                                    'issuedPayee',
                                    'protected',
                                ],
                                { accountNumber: 'Account' }
                            )}
                        >
                        </tm-labeled-list>
                        <tm-button @click=${this.clickViewAudit}>View Audit</tm-button>
                    </div>
                </tm-form-row>

                <tm-form-row label="Return Reason" class="return-reason">
                    <tm-slider
                        .header=${renderHeaderText(
                            'Return Reason',
                            this.selectedException.checkNumber
                                ? `Check ${this.checkException.checkNumber}`
                                : ''
                        )}
                        .value=${this.checkException.returnReasonDescription ?? 'none'}
                    >
                        <check-exception-select-from-list
                            .list=${this.returnReasons}
                            .changeItem=${changeReturnReason}
                            .selectedItem=${this.selectedReturnReason}
                            .getDisplayNameFromListItem=${(item: CheckExceptionReturnReasons) =>
                                item.description}
                            .getIdFromListItem=${(item: CheckExceptionReturnReasons) =>
                                item.returnReasonUniqueId}
                        ></check-exception-select-from-list>
                    </tm-slider>
                </tm-form-row>

                <tm-form-row label="Comment" class="comment">
                    <tm-slider header="Add Comment" .value=${this.checkException.comment}>
                        <tm-section>
                            <check-exception-comment-editor
                                .commentText=${this.checkException.comment}
                                @value-changed=${(e: CustomEvent<string>) => {
                                    this.checkException.comment = e.detail;
                                }}
                                @attachment=${(e: CustomEvent<File>) =>
                                    (this.attachment = e.detail)}
                            ></check-exception-comment-editor>
                        </tm-section>
                    </tm-slider>
                </tm-form-row>

                <tm-form-row label="Check Images" class="check-images">
                    ${this.renderCheckImages()}
                </tm-form-row>
                <div class="ml-4 my-2 request-correction">
                    <tm-button @click=${() => this.navToRequestCorrection()}
                        >Request Correction</tm-button
                    >
                </div>
            </tm-section>
        `;

        if (this.viewingAudit) {
            contentBody = html`<div
                class="p-7 grid grid-cols-2 gap-3 foreground area-shadow audit-log"
            >
                ${this.checkException.audits?.map(this.renderAuditFromList)}
            </div>`;
        }

        if (this.requestingCorrection) {
            contentBody = html`<check-exception-correction
                .checkException=${this.checkException}
                .reviewingCorrectionRequest=${this.reviewingCorrectionRequest}
                .returnToCorrectionOverviewAction=${returnAction}
                .nextStep=${() => this.reviewCorrectionRequest()}
                .correctionPayloadSetter=${(c: ExceptionCorrectionModelDto) =>
                    (this.correction = c)}
            ></check-exception-correction>`;
        }
        if (this.showCorrectionRequestConfirmation) {
            contentBody = html`<correction-request-confirmation
                .requestSuccessful=${this.correctionRequestResponse}
            ></correction-request-confirmation>`;
        }

        const footer =
            Array.isArray(this.footerConfig) && this.footerConfig.length === 0
                ? nothing
                : html`<tm-footer
                      .buttonConfig=${this.footerConfig as ButtonConfig[]}
                      .direction=${'row'}
                      .visible=${this.showFooter}
                  >
                  </tm-footer>`;

        return html`
            <tm-content
                class="${classMap({
                    'content-height': !this.afterCutoff,
                    'after-cutoff-content-height': !!this.afterCutoff,
                })}"
                >${contentBody}</tm-content
            >
            ${footer}
        `;
    }

    static get styles() {
        return [
            css`
                .content-height {
                    min-height: calc(var(--below-navbar-view-height) - 76px);
                }
                .after-cutoff-content-height {
                    min-height: var(--below-navbar-view-height);
                }
                path {
                    fill: #fff;
                }
                .foreground {
                    background-color: var(--primary-background);
                }
                .secondary-text-color {
                    color: var(--secondary-text-color);
                }
                .border-dashed {
                    border-bottom: 1px dashed var(--secondary-text-color);
                }
                .border-solid {
                    border-bottom: 1px var(--secondary-text-color) solid;
                }
                .section-last {
                    padding: 10px 15px;
                }
                .detail-label {
                    width: 33.33333%;
                    color: var(--secondary-text-color, #786e5c);
                }
                .primary-color {
                    color: var(--primary-text-color, #231907);
                }
                .secondary-color {
                    color: var(--secondary-text-color, #786e5c);
                }
                .amount {
                    width: 100px;
                }
                .area-shadow {
                    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.15);
                }
                .check-image {
                    margin: 5px 10px 5px 0;
                    padding: 5px;
                    border: 2px solid var(--secondary-text-color);
                }
                .badge {
                    color: #777;
                    background-color: rgba(230, 230, 230, 0.3);
                    padding: 3px 5px;
                    text-align: center;
                }
                .flex {
                    display: flex;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: CheckExceptionDetailContainer;
    }
}
