import { NavigationService } from '@treasury/core/navigation';
import { CheckException } from '@treasury/domain/arp';
import { TmBaseComponent } from '@treasury/presentation';
import { uploadIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/layout/tm-section';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-footer';
import { InjectProperty } from '@treasury/utils';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../../components/confirmation-container';
import { ConfirmationType } from '../../../../components/confirmation-container';
import { HeaderBarService } from '../../../../services/jhd-header-bar.service';
import './check-exceptions-review-desktop';
import './check-exceptions-review-table';

export const tagName = 'check-exceptions-decision-confirmation';
@customElement(tagName)
export class CheckExceptionsReviewConfirmation extends TmBaseComponent {
    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @property()
    successfulDecision!: boolean;

    @property()
    loading = false;

    @property()
    checkExceptions!: CheckException[];

    firstUpdated() {
        this.headerService.configure({
            backAction: null,
            title: '',
            menuItems: [
                {
                    title: 'Upload',
                    icon: html`<jhd-icon .icon=${uploadIcon}></jhd-icon>`,
                    action: () => console.log('clicked upload'),
                },
            ],
        });
    }

    render() {
        return html`
            <tm-section>
                <confirmation-container
                    class="pt-10"
                    .headingText=${this.successfulDecision
                        ? 'Decisions Submitted'
                        : 'Unable to Submit Decisions'}
                    .confirmationType=${this.successfulDecision
                        ? ConfirmationType.Success
                        : ConfirmationType.Error}
                >
                    <check-exceptions-review-table
                        .checkExceptions=${this.checkExceptions}
                        .confirmation=${true}
                    >
                    </check-exceptions-review-table>
                </confirmation-container>
            </tm-section>
            <tm-footer
                .buttonConfig=${[
                    {
                        importance: 'secondary',
                        text: 'View ACH Exceptions',
                        onClick: () => {
                            this.dispatchEvent(new CustomEvent('close'));
                            this.navService.navigate(`/positive-pay/ach-exceptions`);
                        },
                    },
                    {
                        importance: 'secondary',
                        text: 'View Check Exceptions',
                        onClick: () => {
                            this.dispatchEvent(new CustomEvent('close'));
                            this.headerService.configure({
                                title: 'Positive Pay - Checks',
                                menuItems: [],
                            });
                            this.dispatchEvent(
                                new CustomEvent('refresh', { bubbles: true, composed: true })
                            );
                        },
                    },
                ]}
            >
            </tm-footer>
        `;
    }

    static get styles() {
        return css`
            :host {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: CheckExceptionsReviewConfirmation;
    }
}
