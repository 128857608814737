/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { AchPaymentFilterModelDto } from '@treasury/api/channel';
import { AchPaymentsServices } from '@treasury/domain/channel/services/ach';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { TmContainer } from '@treasury/presentation';
import { circlePlusIcon } from '@treasury/presentation/assets/icons';
import { InjectProperty } from '@treasury/utils';
import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../../components/jhd-route-action-item';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import '../partials/ach-payments-filter-sheet';
import '../partials/ach-payments-table';

export const tagName = 'ach-payments-container';
@customElement(tagName)
export class AchPaymentsContainer extends TmContainer {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare featureFlagService: FeatureFlagService;

    @state()
    achPayments = [];

    @state()
    institution = '';

    @state()
    private filterSheetOpen = false;

    async firstUpdated() {
        this.headerService.configure({ title: 'ACH Payments' });
    }

    async fetchAchPayments(filters: AchPaymentFilterModelDto) {
        await this.tryFetch(
            () => AchPaymentsServices.fetchAchPayments(filters),
            payments => (this.achPayments = payments.data)
        );
    }

    renderTable() {
        return html`
            <ach-payments-table
                .achPayments=${this.achPayments}
                .loading=${this.loading}
                @filter-ach-payments=${() => {
                    this.filterSheetOpen = true;
                }}
            ></ach-payments-table>
        `;
    }

    renderActionItem() {
        const route = `/ach/templates/initiate`;
        return html`
            <jhd-route-action-item
                .icon=${circlePlusIcon}
                .action=${'Create ACH Payment from Template'}
                .route=${route}
            ></jhd-route-action-item>
        `;
    }

    renderFilterSheet() {
        return html`
            <ach-payments-filter-sheet
                .open=${this.filterSheetOpen}
                @close=${() => {
                    this.filterSheetOpen = false;
                }}
                @applyFilters=${(e: CustomEvent) => {
                    this.fetchAchPayments(e.detail);
                }}
            ></ach-payments-filter-sheet>
        `;
    }

    render() {
        return [this.renderActionItem(), this.renderTable(), this.renderFilterSheet()];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchPaymentsContainer;
    }
}
