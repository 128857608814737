import {
    AchBatchFilterModelDto,
    AchBatchesClient,
    AchCompaniesClient,
    AchPaymentModelDto,
    AchPaymentsClient,
    AchSettingsClient,
    CompanyAccountsClient,
    HolidaysClient,
    SecCodesClient,
} from '@treasury/api/channel';
import { Injectable } from '@treasury/utils';
import { format } from 'date-fns';
import { CutoffTimes } from '../channel/types';
import { getDefaultValueDate, SameDayAchSettings } from '../channel/types/ach';
import { AchCompany } from './company.entity';
import { AchFilter } from './filter.entity';
import { OffsetAccount } from './offset-account.entity';
import { AchPayment } from './payment.entity';
import { AchTemplate } from './template.entity';

@Injectable()
export class AchService {
    constructor(
        private readonly batchClient: AchBatchesClient,
        private readonly companyClient: AchCompaniesClient,
        private readonly companyAccountsClient: CompanyAccountsClient,
        private readonly secCodesClient: SecCodesClient,
        private readonly achPaymentsClient: AchPaymentsClient,
        private readonly achSettingsClient: AchSettingsClient,
        private readonly holidaysClient: HolidaysClient
    ) {}

    public async getAchCutoffTimes() {
        const dto = (await this.companyAccountsClient.companyAccountsGetCutoffTimes2('SameDayAch', {
            maxAgeInSeconds: 600,
        })) as unknown as any;

        return dto.data;
    }

    public async getCutoffTimes() {
        const dto = await this.companyAccountsClient.companyAccountsGetCutoffTimes({
            maxAgeInSeconds: 600,
        });
        return dto.data;
    }

    public async getNextAvailableAchDay() {
        const holidays = (
            await this.holidaysClient.holidaysGet({ maxAgeInSeconds: 60000 })
        ).data.map(h => ({
            date: new Date(h.date),
        }));
        const sameDayAchSettings = (
            await this.companyAccountsClient.companyAccountsGetCutoffTimes2('SameDayAch', {
                maxAgeInSeconds: 600,
            })
        ).data;
        const allowSameDayPayments = sameDayAchSettings.processingCutoff?.allowSameDay ?? false;
        const cutoffTimes = await this.getCutoffTimes();

        const date = getDefaultValueDate(
            allowSameDayPayments,
            cutoffTimes as CutoffTimes,
            holidays,
            sameDayAchSettings as SameDayAchSettings
        );
        // This check for $TODAY can be removed wihen Omega reaches EOL and the 'getDefaultValueDate' function is updated to return an ISO date string
        const jsDate = date === '$TODAY' ? new Date() : new Date(date);
        return format(jsDate, 'yyyy-MM-dd');
    }

    public async getSettings() {
        return (
            await this.achSettingsClient.achSettingsGet({
                maxAgeInSeconds: 6000,
            })
        ).data;
    }

    public async getTemplates(filters: AchFilter) {
        const dtos = (
            await this.batchClient.achBatchesPostAll(filters.toDto() as AchBatchFilterModelDto, {
                maxAgeInSeconds: 600,
            })
        ).data;
        return dtos.map(dto => new AchTemplate(dto));
    }

    public async getTemplateById(id: string) {
        const dto = (
            await this.batchClient.achBatchesGet(Number.parseInt(id), { maxAgeInSeconds: 600 })
        ).data;
        return new AchTemplate(dto.batch);
    }

    public async getOffsetAccountsByACHCompanyId(id: number) {
        const dtos = (
            await this.companyClient.achCompaniesOffsetAccounts(id, undefined, {
                maxAgeInSeconds: 600,
            })
        ).data;
        return dtos.map(d => new OffsetAccount(d));
    }

    public async getAchCompanies() {
        const dtos = (await this.companyClient.achCompaniesGet({ maxAgeInSeconds: 600 })).data;
        return dtos.map(c => new AchCompany(c));
    }

    public async getSecCodes() {
        const response = await this.secCodesClient.secCodesGet({ maxAgeInSeconds: 600 });
        if (!response.data.includes('All')) {
            response.data.unshift('All');
        }
        return response.data;
    }

    public async getPaymentById(id: string) {
        const dto = (await this.achPaymentsClient.achPaymentsGet2(Number.parseInt(id))).data;
        return new AchPayment(dto.payment);
    }

    public async createPayment(template: AchTemplate, applyUpdatesToBatch: boolean) {
        const dto = await this.achPaymentsClient.achPaymentsPost({
            ...template.toPaymentDto(template),
            applyUpdatesToBatch,
        } as unknown as AchPaymentModelDto);
        if (dto.data.errorMessage) {
            return new Error(dto.data.errorMessage);
        }
        return new AchPayment(dto.data.payment as unknown as AchPaymentModelDto);
    }

    public async updatePayment(template: AchTemplate, applyUpdatesToBatch: boolean) {
        const dto = await this.achPaymentsClient.achPaymentsPut(template.toDto().id, {
            ...template.toDto(),
            applyUpdatesToBatch,
        } as unknown as AchPaymentModelDto);
        if (dto.data.errorMessage) {
            return new Error(dto.data.errorMessage);
        }
        return new AchPayment(dto.data as unknown as AchPaymentModelDto);
    }
}
