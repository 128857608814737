export function printNode(title: string, node: HTMLElement, styles?: string) {
    // To debug print code uncomment code and comment out the code below
    // const win = window.open('', 'PrintWindow');
    // win.document.title = title;
    // win.document.body.append(node);
    // setTimeout(() => {
    //     win.document.close();
    //     win.focus();
    //     win.print();
    //     win.close();
    // }, 1000);

    const printFrame = document.createElement('iframe');
    printFrame.name = 'printFrame';
    printFrame.style.position = 'absolute';
    printFrame.style.top = '-1000000px';
    document.body.appendChild(printFrame);
    const frameDoc = printFrame.contentDocument;

    if (!frameDoc) {
        throw new Error('Cannot print node. No iframe content document.');
    }

    frameDoc.title = title;

    if (styles) {
        const docStyle = document.createElement('style');
        docStyle.textContent = styles;
        frameDoc.head.appendChild(docStyle);
    }
    frameDoc.body.append(node);

    setTimeout(() => {
        const { contentWindow } = printFrame;
        if (!contentWindow) {
            throw new Error('Could not get window of <iframe> element');
        }

        contentWindow.focus();
        contentWindow.print();
        document.body.removeChild(printFrame);
    }, 500);
}
