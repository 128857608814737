import { CurrencyRange, CurrencyRangeType } from './tm-currency-range';

export const mapCurrencyRangeToDto = (currencyRange?: CurrencyRange, specificRange = true) => {
    if (!currencyRange) {
        return {
            operator: undefined,
            fromAmount: undefined,
            toAmount: undefined,
            specificAmount: undefined,
        };
    }
    const rangeValue = specificRange ? 'Currency Range' : 'Range';
    let operator;
    const { rangeType, specificAmount, floorAmount, ceilAmount } = currencyRange;
    if (rangeType !== CurrencyRangeType.AllAmounts) {
        operator = rangeType === CurrencyRangeType.SpecificAmount ? 'Specific Amount' : rangeValue;
    }

    let fromAmount;
    switch (rangeType) {
        case CurrencyRangeType.AmountRange:
            fromAmount = floorAmount === 0 && ceilAmount && ceilAmount > 0 ? 0.01 : floorAmount;
            break;
        case CurrencyRangeType.SpecificAmount:
            fromAmount = specificAmount;
            break;
        default:
    }

    return {
        operator,
        fromAmount,
        toAmount: ceilAmount,
        specificAmount,
    };
};
