/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    AchExceptionDetailModelDto,
    AchExceptionDetailSearchModelDto,
    AchExceptionFilterModelDto,
    AchExceptionFiltersSearchModelDto,
    AchExceptionModelDto,
    AchExceptionSearchModel2Dto,
    AchExceptionViewModelDto,
    AchExceptionsDecisionsActivityRequestModelDto,
    ArpExceptionDetailDataModelDto,
    ArpExceptionSearchModel2Dto,
    ArpExceptionViewModelDto,
    ArpIssuedItemModelDto,
    ArpModelDto,
    ArpOutputFileSearchModelDto,
    ArpParseResultsModelDto,
    ArpSavedFormatModelDto,
    ArpSecCodeModelCollectionDto,
    CompanyAccountModelDto,
    ErrorResponseModelDto,
    GetCentrixSsoLoginResponseDto,
    InstitutionPosPayAchExceptionsCutOffTimesModelDto,
    IssuedItemSearchModelDto,
    PagedListModelOfAchExceptionDetailDataModelDto,
    SaveAchExceptionDetailResponseModelDto,
    SecCodeModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class ArpClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Arp
     * @name ArpSaveArpExceptions
     * @summary Save ARP Exceptions
     * @request POST:/arp
     */
    arpSaveArpExceptions = (
        arpExceptionDetails: ArpExceptionDetailDataModelDto[],
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/arp`,
            method: 'POST',
            body: arpExceptionDetails,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpSaveArpExceptions2
     * @summary Save ARP Exceptions
     * @request POST:/arp/saveArpExceptions
     */
    arpSaveArpExceptions2 = (
        arpExceptionDetails: ArpExceptionDetailDataModelDto[],
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/arp/saveArpExceptions`,
            method: 'POST',
            body: arpExceptionDetails,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpSearchArpExceptions
     * @summary Searches for Arp Exceptions.
     * @request POST:/arp/arpExceptions
     */
    arpSearchArpExceptions = (
        arpSearchModel: ArpExceptionSearchModel2Dto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ArpExceptionViewModelDto, any>({
            path: `/arp/arpExceptions`,
            method: 'POST',
            body: arpSearchModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpGetAchExceptionFilters
     * @summary Gets all Ach Exception Filters.
     * @request GET:/arp/achExceptionFilters
     */
    arpGetAchExceptionFilters = (params: RequestParams = {}) =>
        this.http.request<AchExceptionFilterModelDto[], any>({
            path: `/arp/achExceptionFilters`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpSearchAchExceptionFilters
     * @summary Gets all Ach Exception Filters.
     * @request POST:/arp/achExceptionFilters
     */
    arpSearchAchExceptionFilters = (
        achExceptionFiltersSearchModel: AchExceptionFiltersSearchModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchExceptionFilterModelDto[], any>({
            path: `/arp/achExceptionFilters`,
            method: 'POST',
            body: achExceptionFiltersSearchModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpSearchArpOutputFiles
     * @summary Searches ARP Output Files.
     * @request POST:/arp/arpOutputFiles
     */
    arpSearchArpOutputFiles = (
        arpOutputFileSearchModel: ArpOutputFileSearchModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchExceptionFilterModelDto[], any>({
            path: `/arp/arpOutputFiles`,
            method: 'POST',
            body: arpOutputFileSearchModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpGetAchExceptions
     * @summary Gets all ACH Exceptions.
     * @request GET:/arp/achExceptions
     */
    arpGetAchExceptions = (params: RequestParams = {}) =>
        this.http.request<AchExceptionModelDto[], any>({
            path: `/arp/achExceptions`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpUpdateAchExceptions
     * @summary Updates Ach Exceptions
     * @request POST:/arp/achExceptions
     */
    arpUpdateAchExceptions = (
        achExceptions: AchExceptionModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/arp/achExceptions`,
            method: 'POST',
            body: achExceptions,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpSearchAchExceptions
     * @summary Searches ACH Exceptions
     * @request POST:/arp/searchAchExceptions
     */
    arpSearchAchExceptions = (
        achExceptionModelSearchModel: AchExceptionSearchModel2Dto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchExceptionModelDto[], any>({
            path: `/arp/searchAchExceptions`,
            method: 'POST',
            body: achExceptionModelSearchModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpFindAchExceptions
     * @summary Searches for Arp Exceptions
     * @request POST:/arp/findAchExceptions
     */
    arpFindAchExceptions = (
        achSearchModel: AchExceptionDetailSearchModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchExceptionViewModelDto, any>({
            path: `/arp/findAchExceptions`,
            method: 'POST',
            body: achSearchModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpFindAchExceptionsDecisionsActivity
     * @summary Searches for previously "decisioned" Positive Pay ACH Exceptions
     * @request POST:/arp/findAchExceptionsDecisionsActivity
     */
    arpFindAchExceptionsDecisionsActivity = (
        achSearchModel: AchExceptionsDecisionsActivityRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<PagedListModelOfAchExceptionDetailDataModelDto, any>({
            path: `/arp/findAchExceptionsDecisionsActivity`,
            method: 'POST',
            body: achSearchModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpSaveAchExceptions
     * @summary Saves the ach exceptions.
     * @request POST:/arp/saveAchExceptions
     */
    arpSaveAchExceptions = (
        achExceptions: AchExceptionDetailModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<SaveAchExceptionDetailResponseModelDto, any>({
            path: `/arp/saveAchExceptions`,
            method: 'POST',
            body: achExceptions,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpSecCodes
     * @summary Gets company SEC Codes.
     * @request GET:/arp/secCodes
     */
    arpSecCodes = (params: RequestParams = {}) =>
        this.http.request<SecCodeModelDto[], any>({
            path: `/arp/secCodes`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpGetAllSecCodes
     * @summary Retrieves the list of all Arp SEC codes.
     * @request GET:/arp/allSecCodes
     */
    arpGetAllSecCodes = (params: RequestParams = {}) =>
        this.http.request<ArpSecCodeModelCollectionDto, ErrorResponseModelDto>({
            path: `/arp/allSecCodes`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpSavedFormat
     * @summary Gets ARP Format
     * @request GET:/arp/savedFormat
     */
    arpSavedFormat = (
        query: {
            /** @format int32 */
            id: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<ArpSavedFormatModelDto, any>({
            path: `/arp/savedFormat`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpUpdateFormat
     * @summary Create/Edit ARP Upload Format
     * @request POST:/arp/savedFormat
     */
    arpUpdateFormat = (
        arpSavedFormatModel: ArpSavedFormatModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/arp/savedFormat`,
            method: 'POST',
            body: arpSavedFormatModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpSavedFormats
     * @summary Saveds the formats.
     * @request GET:/arp/savedFormats
     */
    arpSavedFormats = (params: RequestParams = {}) =>
        this.http.request<ArpSavedFormatModelDto[], any>({
            path: `/arp/savedFormats`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpGetIssuedItems
     * @summary Gets the issued items.
     * @request POST:/arp/getIssuedItems
     */
    arpGetIssuedItems = (params: RequestParams = {}) =>
        this.http.request<ArpIssuedItemModelDto[], any>({
            path: `/arp/getIssuedItems`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpParseIssuedItems
     * @summary Parses the issued items.
     * @request POST:/arp/parseIssuedItems
     */
    arpParseIssuedItems = (params: RequestParams = {}) =>
        this.http.request<ArpParseResultsModelDto, any>({
            path: `/arp/parseIssuedItems`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpSaveIssuedItems
     * @summary Saves the issued items.
     * @request POST:/arp/saveIssuedItems
     */
    arpSaveIssuedItems = (arpModel: ArpModelDto | null, params: RequestParams = {}) =>
        this.http.request<ArpIssuedItemModelDto[], any>({
            path: `/arp/saveIssuedItems`,
            method: 'POST',
            body: arpModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpSearchIssuedItems
     * @summary Searches the issued items.
     * @request POST:/arp/searchIssuedItems
     */
    arpSearchIssuedItems = (
        searchModel: IssuedItemSearchModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ArpIssuedItemModelDto[], any>({
            path: `/arp/searchIssuedItems`,
            method: 'POST',
            body: searchModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpDeleteSavedFormat
     * @summary Deletes the saved format.
     * @request DELETE:/arp/deleteSavedFormat/{id}
     */
    arpDeleteSavedFormat = (id: number, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/arp/deleteSavedFormat/${id}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpGetCentrixSsoLogin
     * @summary Gets the centrix sso login.
     * @request GET:/arp/getCentrixSsoLogin
     */
    arpGetCentrixSsoLogin = (params: RequestParams = {}) =>
        this.http.request<GetCentrixSsoLoginResponseDto, any>({
            path: `/arp/getCentrixSsoLogin`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpGetArpAccounts
     * @summary Gets the arp accounts.
     * @request GET:/arp/GetArpAccounts/{claimType}
     */
    arpGetArpAccounts = (claimType: string, params: RequestParams = {}) =>
        this.http.request<CompanyAccountModelDto[], any>({
            path: `/arp/GetArpAccounts/${claimType}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpGetInstitutionPosPayAchExceptionsCutOffTimes
     * @summary Gets the institution position pay ach exceptions cut off times.
     * @request GET:/arp/achExceptionsCutOffTimes
     */
    arpGetInstitutionPosPayAchExceptionsCutOffTimes = (params: RequestParams = {}) =>
        this.http.request<InstitutionPosPayAchExceptionsCutOffTimesModelDto, any>({
            path: `/arp/achExceptionsCutOffTimes`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
