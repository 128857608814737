import { TmBaseComponent } from '@treasury/presentation';
import { actionIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-bottom-sheet';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../containers/ach-payment-container';
import { AchPayment } from '../types/ach-payment.type';

export const tagName = 'ach-detail-bottom-sheet';
@customElement(tagName)
export class AchDetailBottomSheet extends TmBaseComponent {
    @property({ type: Boolean })
    public bottomSheetOpen = false;

    @property({ type: String })
    public ach: AchPayment | undefined;

    renderAchDetails() {
        if (!this.ach) return nothing;
        return html`<ach-payment-container
            .paymentId=${this.ach?.id}
            .hideHeader=${true}
        ></ach-payment-container>`;
    }

    render() {
        return html`<tm-bottom-sheet
            .open=${this.bottomSheetOpen}
            @close=${() => {
                this.bottomSheetOpen = false;
                this.ach = undefined;
                this.dispatchEvent(new CustomEvent('close'));
            }}
        >
            <span slot="header-center">ACH Detail</span
            ><span slot="header-right" class="header-icon">${actionIcon}</span>
            ${this.renderAchDetails()}
        </tm-bottom-sheet>`;
    }

    static get styles() {
        return [
            css`
                .header-icon path {
                    fill: var(--nav-text-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchDetailBottomSheet;
    }
}
