/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { TmBaseComponent } from '@treasury/presentation';
import { circleWarningIcon, deleteIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-loader';
import '@vaadin/grid';
import { GridActiveItemChangedEvent } from '@vaadin/grid';
import { columnBodyRenderer, gridRowDetailsRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column';
import '@vaadin/grid/vaadin-grid-selection-column.js';
import { formatDistanceToNowStrict } from 'date-fns';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { Notification } from '../types/notification.type';

export const tagName = 'notifications-table';
@customElement(tagName)
export class NotificationsTable extends TmBaseComponent {
    @property({ type: Array })
    notifications: Notification[] = [];

    @property({ type: Array })
    notificationsOpened: Notification[] = [];

    @property({ type: Array })
    selectedNotifications: Notification[] = [];

    @property({ type: Boolean })
    loading = true;

    updateSelectedNotifications(notifications: Notification[]) {
        this.selectedNotifications = notifications;
        this.dispatchEvent(
            new CustomEvent('selected-items-changed', {
                detail: this.selectedNotifications,
            })
        );
    }

    protected updated(changed: Map<PropertyKey, unknown>): void {
        if (changed.has('notifications')) {
            this.notificationsOpened = [];
        }
    }

    updateOpenedNotifications(notification: Notification) {
        if (!notification) return;
        const selectedNotification = notification as Notification;
        if (selectedNotification.id === this.notificationsOpened[0]?.id) {
            this.notificationsOpened = [];
            this.dispatchEvent(new CustomEvent('opened-item-closed'));
        } else {
            const index = this.notifications.findIndex(
                (n: Notification) => n.id === selectedNotification.id
            );
            this.notifications[index].isRead = true;
            this.notificationsOpened = [selectedNotification] as Notification[];
            this.dispatchEvent(
                new CustomEvent('opened-item-changed', {
                    detail: this.notificationsOpened,
                })
            );
        }
    }

    updateSelectAllItems(selected: boolean) {
        if (selected) {
            this.selectedNotifications = this.notifications;
        } else {
            this.selectedNotifications = [];
        }
    }

    renderUnreadIndicator(notification: Notification) {
        if (!notification.isRead) {
            return html`<div class="unread-indicator"></div>`;
        }
        return nothing;
    }

    renderImportantIndicator(notification: Notification) {
        if (notification.isImportant) {
            return html`<div class="important-indicator mr-1">${circleWarningIcon}</div>`;
        }
        return nothing;
    }

    renderSubjectColumn(notification: Notification) {
        const notificationOpenId = this.notificationsOpened.length
            ? this.notificationsOpened[0].id
            : null;
        const notificationOpen = notification.id === notificationOpenId;
        const truncateLength = 40 - notification.functionality.length;
        const notificationDetail = html`${unsafeHTML(notification.detail)}`;
        const truncatedDetail = `${notification.detail.substring(0, truncateLength)}...`;
        const formattedDetail = notificationOpen
            ? html`${notificationDetail}
                  <div class="pt-1">${notification.createdOn}</div>`
            : truncatedDetail;
        const formattedDate = `${formatDistanceToNowStrict(new Date(notification.createdOn))} ago`;
        return html`
            <div class="flex py-1">
                <div class="shrink w-6 items-center flex flex-col">
                    <div>${this.renderUnreadIndicator(notification)}</div>
                    <div>${this.renderImportantIndicator(notification)}</div>
                </div>
                <div class="flex flex-col w-full">
                    <div class="flex justify-between w-full">
                        <span
                            class="subject subject-read-${notification.isRead} pr-4 font-medium text-sm whitespace-normal"
                            >${notification.subject}</span
                        >
                        <span class="text-xs">${formattedDate}</span>
                    </div>
                    <div class="text-xs whitespace-normal">
                        <span class="font-medium">${notification.functionality}</span> -
                        ${formattedDetail}
                    </div>
                </div>
            </div>
        `;
    }

    renderNotificationDelete(notification: Notification) {
        return html`<div class="detail flex justify-end">
            <button
                class="delete-icon p-3"
                @click=${() => {
                    this.dispatchEvent(
                        new CustomEvent('opened-item-deleted', {
                            detail: notification,
                        })
                    );
                }}
            >
                ${deleteIcon}
            </button>
        </div>`;
    }

    renderLoader() {
        if (!this.loading) return nothing;
        return html`<tm-loader card></tm-loader>`;
    }

    renderTable() {
        if (!this.notifications.length || this.loading) return nothing;
        return html`
            <vaadin-grid
                .items=${this.notifications}
                .detailsOpenedItems=${this.notificationsOpened}
                @active-item-changed=${(e: GridActiveItemChangedEvent<Notification>) => {
                    if (e.detail.value != null) this.updateOpenedNotifications(e.detail.value);
                }}
                @selected-items-changed=${(e: GridActiveItemChangedEvent<Notification[]>) => {
                    if (e.detail.value) this.updateSelectedNotifications(e.detail.value);
                }}
                ${gridRowDetailsRenderer<Notification>((notification: Notification) =>
                    this.renderNotificationDelete(notification)
                )}
            >
                <vaadin-grid-selection-column
                    @select-all-changed=${(e: CustomEvent) => {
                        this.updateSelectAllItems(e.detail.value);
                    }}
                ></vaadin-grid-selection-column
                ><vaadin-grid-column
                    header="Select All"
                    ${columnBodyRenderer(this.renderSubjectColumn, [])}
                ></vaadin-grid-column>
            </vaadin-grid>
        `;
    }

    render() {
        return [this.renderLoader(), this.renderTable()];
    }

    static get styles() {
        return [
            css`
                :host {
                    min-height: 120px;
                }
                vaadin-grid {
                    height: calc(100vh - 257px);
                }
                vaadin-grid-cell-content {
                    box-sizing: border-box;
                    padding: 4px 16px 4px 0;
                }
                vaadin-grid::part(header-cell) {
                    color: var(--header-text-color);
                    font-size: 0.875rem;
                }
                vaadin-grid::part(first-column-cell) {
                    align-items: flex-start;
                    padding: 9px 0 9px 16px;
                }
                vaadin-grid::part(row) {
                    overflow-x: hidden;
                }
                .subject-read-false {
                    color: var(--primary-action-color);
                }
                .subject-read-true {
                    color: var(--header-text-color);
                }
                .important-indicator svg {
                    width: 16px;
                    height: auto;
                }
                .important-indicator path {
                    fill: var(--error-color);
                }
                .delete-icon path {
                    fill: var(--primary-action-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: NotificationsTable;
    }
}
