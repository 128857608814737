import { AchTemplate } from '@treasury/domain/ach';

// Needs entitlement check
export const isPrefunding = (template: AchTemplate) => {
    if (!template) return false;
    const prefundingDays = template.achCompany.prefundingDays;
    if (!prefundingDays) return false;
    // return entitlements.allowUnbalanced && prefundingDays;
};

export const fullOffsetAccountAllDebit = (template: AchTemplate) => {
    const creditAmount = Number(template.creditAmount);
    const debitAmount = Number(template.debitAmount);
    const debitOnly = debitAmount > 0 && creditAmount === 0;
    const { notOnUsTransactionTypes, batchBalanceRequirements } = template.achCompany;
    return (
        notOnUsTransactionTypes === 'Debits and Credits' &&
        batchBalanceRequirements === 'Unbalanced - Full Offset' &&
        debitOnly
    );
};

// Needs entitlement check
export const allowUnbalancedPayments = (template: AchTemplate) => {
    if (!template) return false;
    const allowUnbalancedPayments = template.achCompany.allowUnbalancedPayments;
    if (!allowUnbalancedPayments) return false;
    // return entitlements.allowUnbalanced && allowUnbalancedPayments;
};

export const achCompanyIsBalanced = (template: AchTemplate) => {
    if (!template) return false;
    const batchBalanceRequirements = template.achCompany.batchBalanceRequirements;
    if (!batchBalanceRequirements) return false;
    return batchBalanceRequirements.toLowerCase().trim() === 'balanced';
};

// Needs entitlement check
export const requirePrefundingOffsetAccount = (template: AchTemplate) => {
    const prefundedFullOffsetAllDebit =
        isPrefunding(template) && fullOffsetAccountAllDebit(template);
    return !isPrefunding(template) || prefundedFullOffsetAllDebit;
};

export const isOffsetAccountReadOnly = (template: AchTemplate) => {
    if (!template) return false;
    if (template.offsetAccount) return true;
    if (!template.offsetAccount) {
        return (
            (isPrefunding(template) && !fullOffsetAccountAllDebit(template)) ||
            allowUnbalancedPayments(template) ||
            achCompanyIsBalanced(template)
        );
    }
    return false;
};

export const isOffsetAccountVisible = (template: AchTemplate) => {
    if (!template) return false;
    return !achCompanyIsBalanced(template);
};

export const isOffsetAccountRequired = (template: AchTemplate) => {
    if (!template) return false;
    if (!template.offsetAccount) {
        if (achCompanyIsBalanced(template)) return false;
        if (allowUnbalancedPayments(template)) return false;
        return requirePrefundingOffsetAccount(template);
    }
    return true;
};
