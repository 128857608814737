import { TmBaseComponent } from '@treasury/presentation';
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export const tagName = 'header-text';
@customElement(tagName)
export class HeaderText extends TmBaseComponent {
    @property()
    header!: string;

    @property()
    subheader?: string;

    render() {
        const header = html`<div class="font-medium text-lg">${this.header}</div>`;
        const subheader = this.subheader
            ? html`<div class="font-normal text-sm leading-none">${this.subheader}</div>`
            : nothing;

        return html`${header}${subheader}`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: HeaderText;
    }
}
