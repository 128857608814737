import '@vaadin/checkbox';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { TmBaseComponent } from '../tm-base.component';

export const tagName = 'tm-checkbox';
@customElement(tagName)
export class TmCheckbox extends TmBaseComponent {
    @property({ type: String })
    label?: string;

    @property({ type: String })
    value?: string;

    @property({ type: Boolean, attribute: true, reflect: true })
    checked = false;

    @property({ type: Boolean, attribute: true, reflect: true })
    disabled = false;

    render() {
        return html`
            <vaadin-checkbox
                @checked-changed=${(e: CustomEvent) => {
                    const checkedChangedEvent = new CustomEvent('checked-changed', {
                        detail: e.detail,
                    });
                    this.checked = e.detail.value;
                    this.dispatchEvent(checkedChangedEvent);
                }}
                class="w-full"
                label=${this.label}
                value=${this.value}
                ?checked=${this.checked}
                ?disabled=${this.disabled}
            >
            </vaadin-checkbox>
        `;
    }

    static get styles() {
        return [
            css`
                :host([checked]) *::part(checkbox) {
                    background-color: var(--lumo-primary-color);
                }
                :host([checked]) *::part(checkbox)::after {
                    top: -2px;
                    left: -2px;
                }

                *::part(checkbox) {
                    width: var(--vaadin-checkbox-size, 1em);
                    height: var(--vaadin-checkbox-size, 1em);
                    background: #fff;
                    border: 1px solid rgba(26, 57, 96, 0.1);
                    box-shadow: inset 0 0 0 var(--_input-border-width, 0) var(--_input-border-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmCheckbox;
    }
}
