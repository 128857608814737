import { clone, Nullable } from '@treasury/utils';
import { DomainEntity } from '../shared';
import { LoanDto, nullLoan } from './loan.dto';

export class Loan extends DomainEntity<Nullable<LoanDto>> {
    constructor(dto = clone(nullLoan)) {
        super(dto);
        this.dto = dto;
    }

    public createDefault(): this {
        return new Loan(this.dto) as this;
    }

    public get transactionId() {
        return this.dto.transactionId || '';
    }

    public set transactionId(transactionId) {
        this.dto.transactionId = transactionId;
    }

    public get lastEditedDate() {
        return this.dto.lastEditedDate;
    }

    public set lastEditedDate(lastEditedDate) {
        this.dto.lastEditedDate = lastEditedDate;
    }

    public get transferDate() {
        return this.dto.transferDate;
    }

    public set transferDate(transferDate) {
        this.dto.transferDate = transferDate;
    }

    public get amount() {
        return this.dto.amount || 0;
    }

    public set amount(amount) {
        this.dto.amount = amount;
    }

    public get memo() {
        return this.dto.memo || '';
    }

    public set memo(memo) {
        this.dto.memo = memo;
    }

    public get status() {
        return this.dto.status || '';
    }

    public set status(status) {
        this.dto.status = status;
    }

    public get comments() {
        return this.dto.comments || '';
    }

    public set comments(comments) {
        this.dto.comments = comments;
    }

    public get updatedBy() {
        return this.dto.updatedBy || '';
    }

    public set updatedBy(updatedBy) {
        this.dto.updatedBy = updatedBy;
    }

    public get frequency() {
        return this.dto.frequency || '';
    }

    public get fromAccount() {
        return this.dto.fromAccount;
    }

    public set fromAccount(fromAccount) {
        this.dto.fromAccount = fromAccount;
    }

    public get toAccount() {
        return this.dto.toAccount;
    }

    public set toAccount(toAccount) {
        this.dto.toAccount = toAccount;
    }

    public get paymentOptionType() {
        return this.dto.paymentOptionType || '';
    }

    public set paymentOptionType(paymentOptionType) {
        this.dto.paymentOptionType = paymentOptionType;
    }

    public get batchId() {
        return this.dto.batchId || '';
    }

    public set batchId(batchId) {
        this.dto.batchId = batchId;
    }
}
