import { TmBaseComponent } from '@treasury/presentation';
import { circleCheckmarkIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-button';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

export const tagName = 'treasury-pending-approval-template';
@customElement(tagName)
export class TreasuryPendingApprovalTemplate extends TmBaseComponent {
    @property({ type: Array })
    public approvals = [];

    @property({ type: Object })
    public payment = {} as any;

    @property({ type: Function })
    public eligibleApproversFunction = async () => [];

    @state()
    private eligibleApproversVisible = false;

    @state()
    private loading = false;

    @state()
    private eligibleApprovers = [] as string[];

    async firstUpdated() {
        this.loading = true;
        if (!this.eligibleApprovers.length) {
            this.eligibleApprovers =
                (await this.eligibleApproversFunction()) as unknown as string[];
        }
        this.loading = false;
    }

    viewEligibleApprovers() {
        this.eligibleApproversVisible = !this.eligibleApproversVisible;
    }

    renderApprovers() {
        if (!this.approvals.length) return nothing;
        return this.approvals.map(
            (approval: any) =>
                html`<div class="px-6 pt-3">
                    <div class="relative ml-3 py-1 border-b border-[--border-color] text-sm">
                        <div class="approval-icon">${circleCheckmarkIcon}</div>
                        <div class="approver-name">${approval.name}</div>
                        <div class="approved-hint text-xs">approved</div>
                    </div>
                </div>`
        );
    }

    renderEligibleApproversList() {
        if (!this.eligibleApproversVisible) return nothing;
        const approversList = this.eligibleApprovers.map(
            (approver: any) => html`<div class="py-1 text-sm overflow-hidden">${approver}</div>`
        );
        return html`<div class="mb-2 font-medium text-sm text-center">Eligible Approvers</div>
            <div class="approvers-list mb-2">${approversList}</div>`;
    }

    renderEligibleApprovers() {
        if (!this.eligibleApprovers?.length)
            return html`<div class="font-medium text-sm text-center">No Eligible Approvers</div>`;
        const buttonText = this.eligibleApproversVisible ? 'Hide' : 'Eligible Approvers';
        return html`${this.renderEligibleApproversList()}
            <tm-button importance="primary" @click=${() => this.viewEligibleApprovers()}>
                ${buttonText}
            </tm-button>`;
    }

    renderApprovalCountStatus() {
        if (!this.payment.numberOfApprovalsNeeded) return nothing;
        return html`<div class="border-b border-[--border-color] px-6 py-3">
            <p class="approval-count-status text-base font-medium">Approval Status</p>
            <small id="approval-count"
                >${this.payment.completedApprovalCount} of ${this.payment.numberOfApprovalsNeeded}
                approvals completed</small
            >
        </div>`;
    }

    render() {
        return html`
            ${this.renderApprovalCountStatus()} ${this.renderApprovers()}
            <div class="px-6 py-3">${this.renderEligibleApprovers()}</div>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    background-color: var(--primary-background);
                }
                .approvers-list {
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }
                .approved-hint {
                    color: var(--body-text-completed-color);
                }
                .approver-name {
                    color: var(--header-text-color);
                }
                #approval-count {
                    color: var(--secondary-text-color);
                }
                .approval-count-status,
                #no-approvals {
                    color: var(--header-text-color);
                }
                .approval-icon {
                    position: absolute;
                    left: -28px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .approval-icon svg path {
                    fill: var(--body-text-completed-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TreasuryPendingApprovalTemplate;
    }
}
