/* eslint-disable import/extensions */
import { NavigationService } from '@treasury/core/navigation';
import { TmBaseComponent } from '@treasury/presentation';
import { arrowForwardIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-card';
import { InjectProperty } from '@treasury/utils';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export const tagName = 'jhd-route-action-item';
@customElement(tagName)
export class JhdRouteActionItem extends TmBaseComponent {
    @InjectProperty()
    private declare readonly navService: NavigationService;

    @property({ type: SVGElement })
    icon = null;

    @property({ type: Boolean })
    public disabled = false;

    @property({ type: String })
    action = '';

    @property({ type: String })
    actionDescription = '';

    @property({ type: String })
    route = '';

    get disabledClass() {
        // TODO: will have to check user entitlements here when we enable this feature
        return this.disabled ? 'disabled' : '';
    }

    routeToLocation() {
        if (this.disabled) return;
        if (this.route) {
            this.navService.navigate(this.route);
        }
        this.dispatchEvent(new CustomEvent('click'));
    }

    render() {
        return html`<tm-card
            class=${`my-3 mx-2 flex flex-row justify-between items-center ${this.disabledClass}`}
            @click=${() => this.routeToLocation()}
        >
            <div class="flex items-center">
                <div class="plus-icon ml-1 mr-2">${this.icon}</div>
                <div class="action">
                    <span class="action-title font-medium">${this.action}</span><br />
                    <small class="action-description">${this.actionDescription}</small>
                </div>
            </div>
            <div class="arrow-icon mr-1">${arrowForwardIcon}</div>
        </tm-card>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    --tm-card-min-height: 60px;
                }
                tm-card.disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
                .action-title {
                    color: var(--header-text-color);
                }
                small {
                    color: var(--hint-text-color);
                }
                .plus-icon {
                    background: var(--color-primary);
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    padding: 8px;
                }
                .plus-icon svg {
                    width: 100%;
                    height: auto;
                }
                .plus-icon path {
                    fill: var(--icon-fill-color-light);
                }
                .arrow-icon {
                    width: 24px;
                    height: 24px;
                }
                .arrow-icon svg {
                    width: 100%;
                    height: auto;
                }
                .arrow-icon path {
                    fill: #c4c4c4;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: JhdRouteActionItem;
    }
}
