import { DashboardService } from '@treasury/domain/channel/services/dashboard/dashboard-service';
import { UsersService } from '@treasury/domain/channel/services/users/users-service';
import { AuditModel, LoanDto } from '@treasury/domain/loans';
import { NotificationService, TmBaseComponent } from '@treasury/presentation';
import { actionIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import { InjectProperty } from '@treasury/utils';
import { format } from 'date-fns/format';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/jhd-status';
import '../../pending-approvals/partials/approve-or-reject-prompt';

export const tagName = 'loan-detail-bottom-sheet';
@customElement(tagName)
export class LoanDetailBottomSheet extends TmBaseComponent {
    @state()
    loan: LoanDto = {} as LoanDto;

    @InjectProperty()
    private declare dashboardService: DashboardService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @state()
    auditOpen = false;

    @state()
    approversOpen = false;

    @property({ type: Boolean })
    bottomSheetOpen = false;

    @state()
    selectedAction = '';

    @state()
    showApproveOrRejectPrompt = false;

    @state()
    loading = false;

    private async approveOrReject({
        items,
        paymentType = 'loanPayment',
    }: {
        items: any[];
        paymentType: string;
    }) {
        try {
            this.loading = true;
            await this.dashboardService.approveOrRejectPayment(items, paymentType);
            this.dispatchEvent(new CustomEvent('update-loan'));
            this.dispatchEvent(
                new CustomEvent('approve-or-reject', { composed: true, bubbles: true })
            );
            this.bottomSheetOpen = false;
        } catch (err) {
            this.notificationService.renderError(err as Error);
        } finally {
            this.loading = false;
        }
    }

    private toggleAudit() {
        this.auditOpen = !this.auditOpen;
    }

    renderStatus() {
        if (!this.loan) return nothing;
        return html`<jhd-status .status=${this.loan.status} showLabel></jhd-status>`;
    }

    renderApprovalInformation() {
        if (this.loan?.status !== 'PendingApproval') return nothing;
        return html`<treasury-pending-approval-template
            class="mb-2"
            .payment=${this.loan}
            .approvals=${this.loan.completedApprovals}
            .eligibleApproversFunction=${async () =>
                (await UsersService.fetchAvailableApprovers({
                    approvalEntity: 'internalTransfer',
                    productId: this.loan.id,
                    updatedBy: parseInt(this.loan.updatedBy),
                })) as unknown as string[]}
        ></treasury-pending-approval-template>`;
    }

    renderLoanDetails() {
        if (!this.loan) return nothing;
        const loanStatus =
            this.loan.status === 'PendingApproval' ? 'Pending Approval' : this.loan.status;
        return html`<div class="detail-wrapper px-6 py-3 bg-[--primary-background]">
            <div class="loan-detail-wrapper flex items-center justify-between">
                <div class="detail-amount">
                    <div class="text-lg font-medium mb-1">Loan</div>
                    <div class="text-3xl font-normal">
                        ${new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }).format(this.loan.amount)}
                    </div>
                </div>
                <div class="detail-status flex items-center my-1 text-xs">
                    ${this.renderStatus()}
                </div>
            </div>
            <div class="divider"></div>
            <jhd-data-field
                .label=${'From'}
                .value=${this.loan.fromAccount.accountDisplayLabel}
            ></jhd-data-field>
            <jhd-data-field
                .label=${'To'}
                .value=${this.loan.toAccount.accountDisplayLabel}
            ></jhd-data-field>
            <jhd-data-field
                .label=${'Loan Date'}
                .value=${format(new Date(this.loan.transferDate), 'P')}
            ></jhd-data-field>
            <jhd-data-field
                .label=${'Creation Date'}
                .value=${format(new Date(this.loan.createdDate), 'P')}
            ></jhd-data-field>
            <jhd-data-field
                .label=${'Transaction ID'}
                .value=${this.loan.transactionId}
            ></jhd-data-field>
            <jhd-data-field
                .label=${'Created By'}
                .value=${this.loan.audit[this.loan.audit.length - 1].createdBy}
            ></jhd-data-field>
            <jhd-data-field .label=${'Memo'} .value=${this.loan.memo}></jhd-data-field>
            ${this.renderAudit()}
        </div>`;
    }

    renderButtonBar() {
        if (this.loan?.status === 'PendingApproval') {
            return html`
                <tm-footer
                    direction="row"
                    .buttonConfig=${[
                        {
                            error: true,
                            text: 'Reject',
                            classes: 'w-full m-1',
                            onClick: () => {
                                this.showApproveOrRejectPrompt = true;
                                this.selectedAction = 'reject';
                            },
                        },
                        {
                            success: true,
                            text: 'Approve',
                            classes: 'w-full m-1',
                            onClick: () => {
                                this.showApproveOrRejectPrompt = true;
                                this.selectedAction = 'approve';
                            },
                        },
                    ] as ButtonConfig[]}
                >
                </tm-footer>
            `;
        }
        return nothing;
    }

    renderAuditData() {
        if (!this.auditOpen) return nothing;
        const auditList = this.loan.audit.map(
            (auditItem: AuditModel) =>
                html`<div class="audit-item pb-2">
                    <span class="audit-date mr-1">${format(new Date(auditItem.date), 'Pp')}</span>
                    <span>${auditItem.createdBy} : ${auditItem.comments}</span>
                </div>`
        );
        return html`<div class="max-h-36 mt-6 pb-2">
            <div class="pb-2 font-medium">Audit</div>
            <div class="max-h-36 overflow-y-auto font-normal text-sm">${auditList}</div>
        </div>`;
    }

    renderAudit() {
        if (!this.loan?.audit) return nothing;
        const auditButtonText = this.auditOpen ? 'Hide Audit' : 'View Audit';
        return html`
            ${this.renderAuditData()}
            <tm-button class="mt-2" @click=${() => this.toggleAudit()}>
                ${auditButtonText}
            </tm-button>
        `;
    }

    renderApproveOrRejectPrompt() {
        return html`<approve-or-reject-prompt
            .selectedAction=${this.selectedAction}
            .selectedApprovals=${[this.loan]}
            .showApproveOrRejectPrompt=${this.showApproveOrRejectPrompt}
            .paymentType=${'loan'}
            @approve-or-reject=${() => {
                const selectedState = this.selectedAction === 'approve' ? 'Approved' : 'Rejected';
                this.approveOrReject({
                    items: [{ ...this.loan, selectedState }],
                    paymentType: 'loanpayment',
                });
                this.showApproveOrRejectPrompt = false;
            }}
            @close=${() => {
                this.showApproveOrRejectPrompt = false;
            }}
        ></approve-or-reject-prompt>`;
    }

    render() {
        return html`<tm-bottom-sheet
            .open=${this.bottomSheetOpen}
            @close=${() => {
                this.bottomSheetOpen = false;
                this.dispatchEvent(new CustomEvent('close'));
            }}
        >
            <span slot="header-center">Loan Detail</span
            ><span slot="header-right" class="header-icon">${actionIcon}</span>
            <tm-body
                >${this.renderApprovalInformation()}${this.renderLoanDetails()}${this.renderApproveOrRejectPrompt()}</tm-body
            >
            ${this.renderButtonBar()}
        </tm-bottom-sheet>`;
    }

    static get styles() {
        return [
            css`
                .header-icon path {
                    fill: var(--nav-text-color);
                }
                .status svg {
                    display: inline-block;
                }
                .status {
                    color: var(--header-text-color);
                }
                .divider,
                .audit-item {
                    border-bottom: 1px dashed var(--divider-color);
                    color: var(--header-text-color);
                }
                .loan-data-wrapper {
                    background-color: var(--primary-background);
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    min-height: 300px;
                }
                .detail-amount,
                .status {
                    color: var(--header-text-color);
                }
                .audit-date {
                    color: var(--secondary-text-color, #444444);
                }
                .max-h-36 {
                    max-height: 9rem;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: LoanDetailBottomSheet;
    }
}
