import { InternalTransferAddTransactionModelDto } from '@treasury/api/channel';
import { Nullable } from '@treasury/utils';

export const nullTransferTransaction: Nullable<InternalTransferAddTransactionModelDto> = {
    batchId: null,
    type: null,
    errorSummary: null,
    securityMessage: null,
    templateName: null,
    templateId: 0,
    audit: null,
    transactions: [],
    id: null,
    updatedDate: null,
};
