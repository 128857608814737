/* eslint-disable no-use-before-define */
/* eslint-disable import/extensions */
import { CredentialDto, LoginCompletionStatusModelDto } from '@treasury/api/channel';
import { InjectByToken, Injectable, ObservationSource, clone } from '@treasury/utils';
import { LocalStorageService } from '@treasury/utils/services';
import {
    AuthFlowEvent,
    AuthenticationService as NewAuthService,
} from '../../../services/authentication';
import { IdentityDialogFn, OpenIdentityDialogToken } from '../../../shared';
import { ErrorType } from '../../mappings/security';
import EntitlementsService from '../entitlements/entitlements-service.js';
import { StatusCode } from '../login';
import { AccountService } from './account.service';
import TermsAndConditionsService from './terms-and-conditions-service';

/**
 * Represents auth data that has been persisted to local storage.
 */
interface AuthData {
    daysUntilPasswordExpires: number;
    steps: StatusCode[];
}

const DefaultAuthData: AuthData = Object.freeze({
    daysUntilPasswordExpires: Number.MAX_SAFE_INTEGER,
    steps: [],
});

const {
    Allow,
    Challenge,
    ChallengeQuestions,
    ChallengePromptToResetPassword,
    ChallengeResetExpiredPassword,
    ChallengeResetPassword,
    Enrollment,
    Locked,
    PromptToResetPassword,
    PromptToResetPasswordEnrollment,
    ResetExpiredPassword,
    ResetExpiredPasswordEnrollment,
    ResetPassword,
    ResetPasswordEnrollment,
    TwoFactorChallenge,
} = StatusCode;

const StepsMap = new Map<StatusCode, StatusCode[]>([
    [Allow, [TwoFactorChallenge, Allow]],
    [Challenge, [TwoFactorChallenge, Allow]],
    [ChallengeQuestions, [ChallengeQuestions, TwoFactorChallenge, Allow]],
    [ChallengeResetPassword, [ChallengeQuestions, TwoFactorChallenge, ResetPassword, Allow]],
    [
        ChallengeResetExpiredPassword,
        [ChallengeQuestions, TwoFactorChallenge, ResetExpiredPassword, Allow],
    ],
    [ChallengePromptToResetPassword, [TwoFactorChallenge, PromptToResetPassword, Allow]],
    [Enrollment, [TwoFactorChallenge, Enrollment, Allow]],
    [Locked, [Locked]],
    [ResetPasswordEnrollment, [TwoFactorChallenge, ResetPassword, Enrollment, Allow]],
    [ResetExpiredPasswordEnrollment, [TwoFactorChallenge, ResetExpiredPassword, Enrollment, Allow]],
    [
        PromptToResetPasswordEnrollment,
        [TwoFactorChallenge, PromptToResetPassword, Enrollment, Allow],
    ],
    [ResetPassword, [TwoFactorChallenge, ResetPassword, Allow]],
    [ResetExpiredPassword, [TwoFactorChallenge, ResetExpiredPassword, Allow]],
    [PromptToResetPassword, [TwoFactorChallenge, PromptToResetPassword, Allow]],
]);

/**
 * Channel-specific authentication service.
 *
 * Not to be confused with the service in `@treasury/domain/services/authentication`.
 *
 * TODO: combine with agnostic service.
 */
@Injectable()
export class ChannelAuthenticationService {
    // eslint-disable-next-line no-useless-constructor
    constructor(
        private readonly localStorageService: LocalStorageService,
        private readonly accountService: AccountService,
        @InjectByToken(OpenIdentityDialogToken)
        private readonly openIdentityDialog: IdentityDialogFn
    ) {}

    private authEventSource = new ObservationSource<AuthFlowEvent>();

    public authEvent$ = this.authEventSource.toObservable();

    private auth = clone(DefaultAuthData);

    public setAuth(options: { statusCode: StatusCode; daysUntilPasswordExpires: number }) {
        const { statusCode, daysUntilPasswordExpires } = options;

        this.auth.steps = this.getSteps(statusCode);

        this.auth.daysUntilPasswordExpires = daysUntilPasswordExpires;

        this.persistAuthData();
    }

    public async startAuthWorkflow(response: CredentialDto) {
        let statusCode = response.statusCode as StatusCode;
        const { daysUntilPasswordExpires, numberOfSecurityQuestions } = response;
        /* HACK: API is using Challenge status code for more than one flow
           so we now need to redirect the flow from the normal login flow
           if the numberOfSecurityQuestion prop exists. This now represents the
           ChallengeQuestions flow
        */
        if (statusCode === StatusCode.Challenge && numberOfSecurityQuestions) {
            // eslint-disable-next-line no-const-assign
            statusCode = StatusCode.ChallengeQuestions;
        }

        this.auth = this.hydrateAuthData();
        this.setAuth({
            statusCode,
            daysUntilPasswordExpires,
        });

        return await this.goToNextStep();
    }

    public getSteps(statusCode: StatusCode) {
        const steps = StepsMap.get(statusCode);
        return Array.isArray(steps) ? [...steps] : [];
    }

    public async checkTermsAndConditions() {
        const response = await TermsAndConditionsService.checkTermsAndConditions();

        if (response.text) {
            sessionStorage.removeItem('termsAndConditions');
            this.authEventSource.emit(AuthFlowEvent.TermsAndConditionsAccept);
        } else {
            await EntitlementsService.reset();
            const newAuthService = await NewAuthService.getInstance();
            if (!newAuthService.authenticated) {
                newAuthService.authenticate();
            }
            sessionStorage.setItem('termsAndConditions', 'true');
            this.authEventSource.emit(AuthFlowEvent.Done);
        }
    }

    /**
     * Checks the user's login status and redirects to the next step in the login flow
     */
    public async checkLoginCompletion(callback: () => void) {
        const response = await this.accountService.checkLoginCompletion();

        if (response.isComplete) {
            return response;
        }

        if (response.action === 'Challenge') {
            await this.openIdentityDialog(
                {
                    challengeMethodTypeId: response.challengeMethodType,
                    errorCode: ErrorType.Verify,
                },
                {
                    forLogin: true,
                    disableClose: true,
                }
            );

            if (callback) {
                await callback();
            }
            return response;
        }

        // If 2FA/Challenge Point registration is necessary,
        // add it to the top of the queue
        if (response.action === 'Register') {
            this.auth.steps.unshift(getChallengeTypeRoute(response));
        }
        this.persistAuthData();

        return response;
    }

    public async goToNextStep(): Promise<StatusCode | undefined> {
        let statusCode = this.auth.steps.shift();
        switch (statusCode) {
            case StatusCode.Allow:
                this.checkTermsAndConditions();
                return StatusCode.Loading;
            case StatusCode.TwoFactorChallenge:
                // eslint-disable-next-line no-return-await,no-case-declarations
                const callback = async () => {
                    statusCode = await this.goToNextStep();
                };
                // eslint-disable-next-line no-case-declarations
                const response = await this.checkLoginCompletion(callback);
                if (response.isComplete || response.action !== 'Challenge') {
                    statusCode = await this.goToNextStep();
                }
                break;
            default:
                break;
        }
        return statusCode;
    }

    private hydrateAuthData() {
        return this.localStorageService.get<AuthData>('auth') || clone(DefaultAuthData);
    }

    private persistAuthData() {
        this.localStorageService.set('auth', this.auth);
    }
}

function getChallengeTypeRoute(response: LoginCompletionStatusModelDto) {
    switch (response.challengeMethodType) {
        case 1:
            return StatusCode.OobLegacy;
        case 2:
            return StatusCode.RegisterSecureToken;
        case 3:
            return StatusCode.RegisterOutOfBand;
        default:
            throw new Error(`Unknown challengeMethodType: ${response.challengeMethodType}`);
    }
}
