import { UserSummaryModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';
import { nullPendingUser } from './null-pending-user';

export class PendingUser extends DomainEntity<UserSummaryModelDto> {
    constructor(user: UserSummaryModelDto) {
        super(user);
    }

    public createDefault() {
        return new PendingUser(nullPendingUser) as this;
    }

    get userId() {
        return this.dto.userId;
    }

    get userName() {
        return this.dto.userName;
    }

    get status() {
        return this.dto.status;
    }
}
