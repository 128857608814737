import { NavigationService } from '@treasury/core/navigation';
import {
    AccountDetail,
    AccountsService,
    Transaction,
    TransactionDetails,
} from '@treasury/domain/accounts';
import { TmContainer } from '@treasury/presentation';
import { actionIcon, helpIcon, informationIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-bottom-sheet';
import { DateRange } from '@treasury/presentation/components/tm-date-range';
import '@treasury/presentation/components/tm-loader';
import { InjectProperty } from '@treasury/utils';
import { css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import { defaultTransactionDateRange } from '../data/default-account-transaction-filters';
import { TransactionViewModel } from '../data/transaction-view-model';
import '../partials/account-detail-graph';
import '../partials/account-transaction-details';
import '../partials/account-transactions-filter-sheet';
import '../partials/account-transactions-table';

export const tagName = 'account-container';
@customElement(tagName)
export class AccountsContainer extends TmContainer {
    @InjectProperty()
    private declare readonly headerService: HeaderBarService;

    @InjectProperty()
    private declare readonly client: AccountsService;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    @state()
    private balances: number[] = [];

    @state()
    private dates: string[] = [];

    @state()
    private accountName = '';

    @state()
    private accountDisplayLabel = '';

    @state()
    private transactions: TransactionViewModel[] = [];

    @state()
    private transactionDateRange: DateRange = defaultTransactionDateRange;

    @state()
    private selectedTransaction?: Transaction;

    @state()
    private transactionDetailsOpen = false;

    @state()
    private filterSheetOpen = false;

    @state()
    private balanceTotals = {};

    get accountId() {
        return window.location.pathname.split('/').pop() ?? '';
    }

    get accountType() {
        const pathArray = window.location.pathname.split('/');
        return pathArray[pathArray.length - 2];
    }

    async firstUpdated() {
        await this.tryFetch(
            () => this.client.getAccountBalanceHistory(this.accountId, this.accountType),
            response => {
                this.balances = response.balances;
                this.dates = response.dates;
            }
        );
    }

    public async fetchTransactions(filters: any) {
        await this.tryFetch(
            () =>
                this.client.getAccountDetailsByDate(
                    this.accountId ?? '',
                    this.accountType,
                    filters.transactionDateFrom,
                    filters.transactionDateTo
                ),
            accountDetail => {
                const {
                    transactions,
                    name,
                    accountDisplayLabel,
                    availableBalance,
                    collectedBalance,
                    currentBalance,
                } = accountDetail as AccountDetail;

                this.balanceTotals = { availableBalance, collectedBalance, currentBalance };

                if (!this.accountName) {
                    this.accountName = name;
                    this.accountDisplayLabel = accountDisplayLabel;
                    this.configureHeader();
                }
                this.transactions = transactions.map(t => new TransactionViewModel(t));
            }
        );
    }

    configureHeader() {
        this.headerService.configure({
            title: this.accountName,
            subtitle: this.accountDisplayLabel,
            backAction: () => this.navService.navigate(`/accounts`),
            menuItems: [
                {
                    title: 'Download',
                    icon: actionIcon,
                    action: () => console.log('clicked'),
                },
                {
                    title: 'Help',
                    icon: helpIcon,
                    action: () => console.log('clicked'),
                },
            ],
        });
    }

    renderGraph() {
        return html`<account-detail-graph
            .loading=${this.loading}
            .accountBalanceHistory=${this.balances}
            .dateSeries=${this.dates}
            .accountType=${this.accountType}
            .balanceTotals=${this.balanceTotals}
        ></account-detail-graph>`;
    }

    renderTransactionDetails() {
        return html`<account-transaction-details
            .accountName=${this.accountName}
            .selectedTransaction=${this.selectedTransaction}
        ></account-transaction-details>`;
    }

    renderTransactionDetailsSheet() {
        return html`<tm-bottom-sheet
            .open=${this.transactionDetailsOpen && this.selectedTransaction}
            @close=${() => {
                this.transactionDetailsOpen = false;
            }}
            ><div slot="header-center">Transaction Detail</div>
            <div slot="header-right" class="flex header-icon">
                <button
                    class="header-button cursor-pointer border-none outline-none p-2 last-of-type:mr-0"
                >
                    ${actionIcon}</button
                ><button
                    class="header-button cursor-pointer border-none outline-none p-2 last-of-type:mr-0"
                >
                    ${informationIcon}
                </button>
            </div>
            <tm-body>${this.renderTransactionDetails()}</tm-body></tm-bottom-sheet
        >`;
    }

    renderTransactionsTable() {
        return html`<account-transactions-table
            .loading=${this.loading}
            .transactions=${this.transactions}
            .transactionDateRange=${this.transactionDateRange}
            @transaction-selected=${(e: CustomEvent) => {
                this.transactionDetailsOpen = true;
                this.selectedTransaction = e.detail;
            }}
            @filter-account-transactions=${() => {
                this.filterSheetOpen = true;
            }}
        ></account-transactions-table>`;
    }

    renderTransactionsFilterSheet() {
        return html`<account-transactions-filter-sheet
            .open=${this.filterSheetOpen}
            @close=${() => {
                this.filterSheetOpen = false;
            }}
            @applyFilters=${(e: CustomEvent) => {
                const { filters, transactionDateRange } = e.detail;
                this.fetchTransactions(filters);
                this.transactionDateRange = transactionDateRange;
            }}
        ></account-transactions-filter-sheet>`;
    }

    render() {
        return [
            this.renderGraph(),
            this.renderTransactionsTable(),
            this.renderTransactionDetailsSheet(),
            this.renderTransactionsFilterSheet(),
        ];
    }

    static get styles() {
        return [
            css`
                :host {
                    width: 100vw;
                }
                .header-icon path {
                    fill: var(--nav-text-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AccountsContainer;
    }
}
