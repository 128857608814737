import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-checkbox';
import '@vaadin/grid';
import { columnBodyRenderer, GridColumnBodyLitRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

type AchEntitlement = {
    claimId: number;
    claimName: string;
    hasUserClaim: boolean;
    hasPendingUserClaim: boolean;
    claimDescription: string;
    edited: boolean;
    value: boolean;
};

export const tagName = 'user-ach-entitlements';
@customElement(tagName)
export class UserAchEntitlements extends TmBaseComponent {
    @property({ type: Object })
    public config: any = {};

    renderEntitlementColumn(entitlement: AchEntitlement) {
        return html`<div class="flex justify-start">
            <tm-checkbox .checked=${entitlement.value} disabled></tm-checkbox>
            <span
                class=${classMap({
                    'ml-2': true,
                    'text-sm': true,
                    'font-normal': true,
                    edited: entitlement.edited,
                })}
                >${entitlement.claimDescription}</span
            >
        </div>`;
    }

    renderAchEntitlementGrids() {
        return html` <h3 class="font-normal p-3">ACH Templates</h3>
            <vaadin-grid all-rows-visible .items=${this.config?.achTemplateFeatures}
                ><vaadin-grid-column
                    ${columnBodyRenderer(
                        this.renderEntitlementColumn as GridColumnBodyLitRenderer<AchEntitlement>
                    )}
                ></vaadin-grid-column
            ></vaadin-grid>
            <h3 class="font-normal p-3">ACH Payments</h3>
            <vaadin-grid all-rows-visible .items=${this.config?.achPaymentFeatures}
                ><vaadin-grid-column
                    ${columnBodyRenderer(
                        this.renderEntitlementColumn as GridColumnBodyLitRenderer<AchEntitlement>
                    )}
                ></vaadin-grid-column
            ></vaadin-grid>
            <h3 class="font-normal p-3">International ACH Templates</h3>
            <vaadin-grid all-rows-visible .items=${this.config?.internationalAchTemplateFeatures}
                ><vaadin-grid-column
                    ${columnBodyRenderer(
                        this.renderEntitlementColumn as GridColumnBodyLitRenderer<AchEntitlement>
                    )}
                ></vaadin-grid-column
            ></vaadin-grid>
            <h3 class="font-normal p-3">International ACH Payments</h3>
            <vaadin-grid all-rows-visible .items=${this.config?.internationalAchPaymentFeatures}
                ><vaadin-grid-column
                    ${columnBodyRenderer(
                        this.renderEntitlementColumn as GridColumnBodyLitRenderer<AchEntitlement>
                    )}
                ></vaadin-grid-column
            ></vaadin-grid>
            <h3 class="font-normal p-3">ACH Recipients</h3>
            <vaadin-grid all-rows-visible .items=${this.config?.recipientFeatures}
                ><vaadin-grid-column
                    ${columnBodyRenderer(
                        this.renderEntitlementColumn as GridColumnBodyLitRenderer<AchEntitlement>
                    )}
                ></vaadin-grid-column
            ></vaadin-grid>
            <h3 class="font-normal p-3">ACH Returns</h3>
            <vaadin-grid all-rows-visible class="mb-4" .items=${this.config?.reviewFeatures}
                ><vaadin-grid-column
                    ${columnBodyRenderer(
                        this.renderEntitlementColumn as GridColumnBodyLitRenderer<AchEntitlement>
                    )}
                ></vaadin-grid-column
            ></vaadin-grid>`;
    }

    render() {
        return html`${this.renderAchEntitlementGrids()}`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserAchEntitlements;
    }
}
