export const transferTemplateTypeItems = [
    {
        label: 'One-to-One',
        value: {
            key: 'One-to-One',
            value: 'One-to-One',
        },
    },
    {
        label: 'One-to-Many',
        value: {
            key: 'One-to-Many',
            value: 'One-to-Many',
        },
    },
    {
        label: 'Many-to-One',
        value: {
            key: 'Many-to-One',
            value: 'Many-to-One',
        },
    },
];
