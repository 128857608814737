import { TmBaseComponent } from '@treasury/presentation';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';

export const tagName = 'not-found';
@customElement(tagName)
export class NotFound extends TmBaseComponent {
    render() {
        return html`<div>404 - not found</div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: NotFound;
    }
}
