export const defaultAchExceptionsSearchRequest = {
    amount: 0,
    amountStart: 0,
    amountEnd: 0,
    amountType: undefined,
    decisionTaken: 'both',
    description: '',
    postedDate: '',
    postedDateEnd: '',
    postedDateStart: '',
    postedDateType: undefined,
    searchType: 'OpenItems',
    type: 'OpenItems',
    accounts: [],
    secCodes: [],
    page: 'AchExceptionsList',
};
