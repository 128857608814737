import { CompanyAccountModelDto } from '@treasury/api/channel';
import { AchException } from '@treasury/domain/arp';
import { PayOrReturnChoice } from '@treasury/domain/channel/mappings/ach';
import { TmBaseComponent } from '@treasury/presentation';
import { filterIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/layout/tm-section';
import '@treasury/presentation/components/tm-badge';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-footer';
import '@treasury/presentation/components/tm-table-header';
import { GridActiveItemChangedEvent } from '@vaadin/grid';
import { columnBodyRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-selection-column.js';
import '@vaadin/grid/vaadin-grid-sort-column.js';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { filterTableResults } from '../../../../utilities/table-result-filter';
import '../../components/ach-decision-toggle';
import './ach-exceptions-filter-sheet';

type SelectedStateAchException = AchException & { selectedState?: string };

export const tagName = 'ach-exceptions-table';
@customElement(tagName)
export class AchExceptionsTable extends TmBaseComponent {
    @state()
    loading = true;

    @property({ type: Array })
    achExceptions: AchException[] = [];

    @property({ type: Boolean })
    decisioningDisabled = false;

    @property({ type: String })
    actionName = 'Review';

    @property({ type: Boolean })
    actionLoading = false;

    @property({ type: Array })
    filteredItems: AchException[] = [];

    @property({ type: Array })
    private accounts: CompanyAccountModelDto[] = [];

    @property({ type: Array })
    private secCodes: { secCode?: string; description?: string }[] = [];

    @property({ type: Boolean })
    readonly = false;

    @state()
    filterSheetOpen = false;

    @state()
    showSelectAllOptions = false;

    get tabTitle() {
        return 'ACH Exceptions';
    }

    get fieldsToFilter() {
        return ['name', 'amount', 'accountName', 'accountNumber', 'achCompanyName'];
    }

    get decisionedExceptions() {
        return this.achExceptions.filter(ae => ae.decisionChoice !== PayOrReturnChoice.Unselected);
    }

    get hasItems() {
        return this.achExceptions?.length > 0;
    }

    get totalAchExceptions() {
        return this.achExceptions.length || 0;
    }

    toggleShowSelectAllOptions() {
        this.showSelectAllOptions = !this.showSelectAllOptions;
    }

    protected updated(changedProperties: Map<string | number | symbol, unknown>): void {
        super.firstUpdated(changedProperties);
        if (changedProperties.has('loading') || changedProperties.has('achExceptions')) {
            this.filteredItems = this.achExceptions;
        }
    }

    getAggregateByDecision(decision: PayOrReturnChoice) {
        if (!this.achExceptions.length) return 0;
        return this.achExceptions.filter(e => e.decisionChoice === decision).length;
    }

    decisionAll(decision: PayOrReturnChoice) {
        this.dispatchEvent(new CustomEvent('decisionAll', { detail: decision }));
    }

    renderToggleColumn(item: SelectedStateAchException) {
        return html`<ach-decision-toggle
            .exception=${item}
            @change=${() => {
                this.dispatchEvent(new CustomEvent('decision'));
                this.requestUpdate();
            }}
            .readonly=${this.readonly}
        ></ach-decision-toggle>`;
    }

    renderAchNameColumn(achException: AchException) {
        return html`<div
            class="flex flex-col"
            @click=${(e: Event) => {
                this.dispatchEvent(new CustomEvent('selected', { detail: achException }));
            }}
        >
            <span class="font-medium">${achException.accountNumber}</span>
            <span class="text-sm font-light ">${achException.achCompanyName}</span>
        </div>`;
    }

    renderAchAmountColumn(achException: AchException) {
        const formattedAmount = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(achException.amount ?? 0);

        return html`<div
            class="flex flex-col"
            @click=${(e: Event) => {
                this.dispatchEvent(new CustomEvent('selected', { detail: achException }));
            }}
        >
            <span class="pr-2 mr-2">${formattedAmount}</span>
        </div>`;
    }

    renderColumns() {
        return html`
            <vaadin-grid-column
                width="15%"
                ${columnBodyRenderer(this.renderToggleColumn, [])}
            ></vaadin-grid-column>
            <vaadin-grid-column
                width="40%"
                ${columnBodyRenderer(this.renderAchNameColumn, [])}
            ></vaadin-grid-column>
            <vaadin-grid-column
                text-align="end"
                ${columnBodyRenderer(this.renderAchAmountColumn, [])}
            >
            </vaadin-grid-column>
        `;
    }

    renderTable() {
        if (this.loading)
            return html`<div class="text-center empty-state py-16">
                <tm-loader card></tm-loader>
            </div>`;
        if (!this.hasItems)
            return html`<div class="text-center empty-state py-16">No exceptions found.</div> `;

        return html`
            <vaadin-grid .items=${this.filteredItems}> ${this.renderColumns()} </vaadin-grid>
        `;
    }

    renderTotals() {
        if (!this.hasItems || this.loading) return nothing;
        return html`<div
            class="w-full h-12 border-t flex items-end justify-end border-b border-[--border-color] grid-footer"
        >
            <div class="flex items-center h-full">
                <div class="p-1 flex items-center total-count text-sm">
                    <tm-badge>${this.totalAchExceptions}</tm-badge>
                    <span class="table-footer-label p-1">Total</span>
                </div>
                <div class="p-1 flex items-center total-count text-sm">
                    <tm-badge
                        >${this.getAggregateByDecision(PayOrReturnChoice.Unselected)}
                    </tm-badge>
                    <span class="table-footer-label p-1">Undecisioned</span>
                </div>
                <div class="p-1 flex items-center pay-total text-sm">
                    <tm-badge
                        ><div>${this.getAggregateByDecision(PayOrReturnChoice.Pay)}</div></tm-badge
                    >
                    <span class="table-footer-label p-1">Pay</span>
                </div>
                <div class="p-1 flex items-center return-total text-sm">
                    <tm-badge
                        ><div>
                            ${this.getAggregateByDecision(PayOrReturnChoice.Return)}
                        </div></tm-badge
                    >
                    <span class="table-footer-label p-1">Return</span>
                </div>
            </div>
        </div>`;
    }

    renderActions() {
        if (this.readonly) return nothing;
        if (!this.hasItems) return nothing;
        if (this.loading) return nothing;
        if (!this.showSelectAllOptions) {
            return html`<div class="flex m-4">
                <tm-button importance="primary" @click=${this.toggleShowSelectAllOptions}>
                    Select All
                </tm-button>
            </div>`;
        }
        return html`<div class="actions m-4 flex justify-start">
            <tm-button
                class="mr-2"
                @click=${() => {
                    this.toggleShowSelectAllOptions();
                    this.dispatchEvent(new CustomEvent('clearAll'));
                }}
            >
                Cancel
            </tm-button>
            <tm-button
                class="mx-2"
                success
                importance="primary"
                @click=${() => {
                    this.decisionAll(PayOrReturnChoice.Pay);
                }}
            >
                Pay All
            </tm-button>
            <tm-button
                class="mx-2"
                error
                importance="primary"
                @click=${() => {
                    this.decisionAll(PayOrReturnChoice.Return);
                }}
            >
                Return All
            </tm-button>
        </div>`;
    }

    renderTableHeader() {
        return html`<tm-table-header
            .filteringEnabled=${!this.readonly && !this.decisioningDisabled}
            .filterButton=${true}
            @filterButtonClick=${() => (this.filterSheetOpen = true)}
            @filterStringChange=${(e: CustomEvent) => {
                this.filteredItems = filterTableResults(
                    e,
                    this.achExceptions,
                    this.fieldsToFilter
                ) as any;
            }}
        >
            <h2 class="font-medium">${this.tabTitle}</h2></tm-table-header
        >`;
    }

    renderFilterSheet() {
        if (!this.accounts.length || !this.secCodes.length) return nothing;
        return html`<ach-exceptions-filter-sheet
            .open=${this.filterSheetOpen}
            .accounts=${this.accounts}
            .secCodes=${this.secCodes}
            @close=${() => {
                this.filterSheetOpen = false;
            }}
        ></ach-exceptions-filter-sheet>`;
    }

    render() {
        return html`<tm-section class="border-b border-[--border-color]">
                ${this.renderTableHeader()} ${this.renderTable()} ${this.renderTotals()}
                ${this.renderActions()}
            </tm-section>
            <slot></slot>
            ${this.renderFilterSheet()}`;
    }

    static get styles() {
        return [
            css`
                :host {
                    --lumo-space-xs: 3px;
                    --lumo-space-m: 0;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }
                h3 {
                    margin-top: 10px;
                    color: var(--header-text-color);
                }
                tm-button:not(:last-child) {
                    margin-right: 10px;
                }
                tm-button:not(:last-child) {
                    margin-right: 10px;
                }
                .button-bar {
                    border-top: 1px solid var(--content-background);
                }
                .empty-state {
                    color: var(--header-text-color);
                }
                #no-exceptions {
                    margin: auto;
                }
                .max-w-100 {
                    max-width: 150px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchExceptionsTable;
    }
}
