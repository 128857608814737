// eslint-disable-next-line max-classes-per-file
import { Grid, GridActiveItemChangedEvent, GridColumn, GridItemModel } from '@vaadin/grid';
import { GridColumnBodyLitRenderer, columnBodyRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { PropertyValues, TemplateResult, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';
import at from 'lodash.at';
import { TmBaseComponent } from '../tm-base.component';
import './tm-loader';
import './tm-table-header';

export interface TmListColumnOptions {
    isIconColumn: boolean;
}

class TmListGroupHeader {
    constructor(public text: string) {}
}

export type TmListColumnRenderer<T> = (
    item: T,
    model?: GridItemModel<T>,
    column?: GridColumn
) => TemplateResult;

interface TmListColumnWithOptions<T> {
    renderer: TmListColumnRenderer<T>;
    options?: TmListColumnOptions;
}

export type TmListColumn<T> = TmListColumnRenderer<T> | TmListColumnWithOptions<T>;

export const tagName = 'tm-list';
@customElement(tagName)
export class TmList<T extends object> extends TmBaseComponent {
    protected verifiedPropNames: readonly (keyof this)[] = ['columns'];

    @property({ attribute: false })
    public columns!: TmListColumn<T>[];

    @property({ type: Array })
    public searchFields: (keyof T)[] = [];

    @property({ type: Array })
    public items: T[] = [];

    @property({ type: Boolean })
    public filterButton = false;

    @property({ type: String })
    public groupBy?: keyof T;

    @state()
    private searchTerm = '';

    @state()
    private filteredItems: (T | TmListGroupHeader)[] = [];

    private readonly defaultColumnOptions: TmListColumnOptions = {
        isIconColumn: false,
    };

    private gridRef: Ref<Grid> = createRef();

    private currentHeaderRowIndex = -1;

    private cellPartNameGen(column: GridColumn, model: GridItemModel<T | TmListGroupHeader>) {
        if (model.item instanceof TmListGroupHeader) {
            this.currentHeaderRowIndex = model.index;
            return 'item-header-row';
        }

        if (model.index === this.currentHeaderRowIndex + 1) {
            return 'first-row-in-group';
        }

        return '';
    }

    protected updated(_changedProperties: PropertyValues): void {
        if (_changedProperties.has('items') || _changedProperties.has('searchTerm')) {
            this.filterItems();
        }
    }

    // The consumer-passed renderer function doesn't understand the concept of header rows, so we
    // need to wrap it in a higher-level renderer that will handle rendering the header rows,
    // while deferring to the consumer renderer for the actual item rows.
    private createRenderer(column: TmListColumn<T>, index: number) {
        const consumerRenderer = typeof column === 'function' ? column : column.renderer;
        return (item: T | TmListGroupHeader, model: GridItemModel<T>, column: GridColumn) => {
            if (item instanceof TmListGroupHeader) {
                // Display the header row text in the first column...
                if (index === 0) {
                    return html`<div class="flex flex-col">${item.text}</div>`;
                }
                // ...and empty space in the other columns
                return nothing;
            }
            // Not a header row, so render the column normally
            return consumerRenderer(item, model, column);
        };
    }

    private groupItems() {
        if (this.groupBy) {
            // Group items by the groupBy field, maintaining the order of the items
            const groupedItems = new Map<string, T[]>();
            (this.filteredItems as T[]).forEach(item => {
                const key = at(item, this.groupBy!)[0]?.toString() ?? '';
                if (!groupedItems.has(key)) {
                    groupedItems.set(key, []);
                }
                groupedItems.get(key)!.push(item);
            });
            // Flatten the map back into a single array, inserting a header row before each group
            this.filteredItems = [...groupedItems.entries()].flatMap(([key, items]) => [
                new TmListGroupHeader(key),
                ...items,
            ]);

            if (this.gridRef.value) {
                setTimeout(() => this.gridRef.value?.generateCellPartNames(), 0);
            }
        }
    }

    private filterItems() {
        const matchesTerm = (val?: T[keyof T]) =>
            val?.toString().toLowerCase().includes(this.searchTerm.toLowerCase());

        this.filteredItems = this.items.filter(
            item => !this.searchTerm || at(item, ...this.searchFields).some(matchesTerm)
        );

        this.groupItems();
    }

    renderHeader() {
        return html`
            <tm-table-header
                .filterButton=${this.filterButton}
                @filterButtonClick=${() => this.dispatchEvent(new CustomEvent('open-list-filter'))}
                @filterStringChange=${(e: CustomEvent) => {
                    this.searchTerm = (e.detail.value || '').trim();
                }}
            ></tm-table-header>
        `;
    }

    renderColumns() {
        return this.columns.map((c, index) => {
            const options = {
                ...this.defaultColumnOptions,
                ...(typeof c === 'function' ? {} : c.options),
            };

            return html`<vaadin-grid-column
                .width=${options.isIconColumn ? 'min-content' : '100px'}
                .flexGrow=${options.isIconColumn ? 0 : 1}
                ${columnBodyRenderer(this.createRenderer(c, index), [])}
            ></vaadin-grid-column>`;
        });
    }

    renderList() {
        if (this.items.length === 0) {
            return html`<div class="mx-auto w-full text-center py-5">No Items Found</div>`;
        }
        if (this.filteredItems.length === 0) {
            return html`<div class="mx-auto w-full text-center py-5">
                No Items Match Your Search
            </div>`;
        }
        return html`
            <vaadin-grid
                ${ref(this.gridRef)}
                class="border-y border-[--tabs-border]"
                style="height: calc(100vh - 164px)"
                .items=${this.filteredItems}
                .cellPartNameGenerator=${this.cellPartNameGen}
                @active-item-changed=${(e: GridActiveItemChangedEvent<T>) => {
                    const item = e.detail.value;
                    if (item) {
                        this.dispatchEvent(
                            new CustomEvent('select-list-item', {
                                detail: item,
                                bubbles: true,
                                composed: true,
                            })
                        );
                    }
                }}
            >
                ${this.renderColumns()}
            </vaadin-grid>
        `;
    }

    render() {
        return html`<tm-section> ${this.renderHeader()} ${this.renderList()} </tm-section>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    --lumo-contrast-10pct: var(--tabs-border);
                    --input-border-color: 1px solid var(--text-hint);
                    height: 100%;
                }
                vaadin-grid-cell-content {
                    padding: 6px 16px 6px 0;
                }
                vaadin-grid::part(first-column-cell) {
                    margin-left: 16px;
                }
                vaadin-grid::part(item-header-row) {
                    background-color: var(--lighter-grey, #f5f5f5);
                    margin-left: 0px;
                    padding-left: 16px;
                    pointer-events: none;
                }
                vaadin-grid::part(first-row-in-group) {
                    border: 0;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmList<object>;
    }
}
