import '@vaadin/text-field';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { circleCloseFilledIcon } from '../assets/icons';
import { TmInputFieldBase } from './tm-input-field.base';

export const tagName = 'tm-text-field';
@customElement(tagName)
export class TmTextField extends TmInputFieldBase {
    @property({ type: String })
    pattern?: string;

    @property({ type: String })
    allowedCharacters?: string;

    @property({ type: Number })
    minLength?: number;

    @property({ type: Number })
    maxLength?: number;

    @property({ type: Boolean })
    clearButton = false;

    @property({ type: Boolean, reflect: true })
    large = false;

    @property({ type: Boolean, reflect: true })
    autofocus = false;

    render() {
        return html`
            <style>
                vaadin-text-field::part(clear-button)::before {
                    content: url(${`'data:image/svg+xml,${encodeURIComponent(
                        circleCloseFilledIcon.strings[0]
                    )}'`});
                }
            </style>
            <vaadin-text-field
                @value-changed=${(e: CustomEvent) => {
                    const valueChangedEvent = new CustomEvent('value-changed', {
                        detail: e.detail,
                    });
                    this.dispatchEvent(valueChangedEvent);
                }}
                part="component"
                class="w-full"
                label=${this.label}
                value=${this.value}
                placeholder=${ifDefined(this.placeholder)}
                pattern=${ifDefined(this.pattern)}
                allowed-char-pattern=${ifDefined(this.allowedCharacters)}
                autocapitalize=${ifDefined(this.autocapitalize)}
                helper-text=${ifDefined(this.helperText)}
                .minlength=${this.minLength}
                .maxlength=${this.maxLength}
                ?required=${this.required}
                ?readonly=${this.readonly}
                ?disabled=${this.disabled}
                .invalid=${this.invalid}
                ?autofocus=${this.autofocus}
                ?clear-button-visible=${this.clearButton}
            >
                <slot slot="prefix" name="prefix"></slot>
                <slot slot="suffix" name="suffix"></slot>
            </vaadin-text-field>
        `;
    }

    static get styles() {
        return [
            ...super.styles,
            css`
                :host([large]) {
                    --lumo-size-m: 3rem;
                }

                :host([autofocus]) {
                    --lumo-primary-color-50pct: none;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmTextField;
    }
}
