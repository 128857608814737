/* eslint-disable max-classes-per-file */
/* eslint-disable no-use-before-define */
import { ApiErrorDto, ErrorResponseDto, isErrorResponse } from '@treasury/api/shared';
import { exists } from '@treasury/utils';
/**
 * Client-side class for working with Treasury Management API error responses.
 *
 * Primarily serves to maintain call stack information when thrown by extending
 * the built-in `Error` type. Also serves as a base class for more specific
 * TM API error types.
 */
export class TmApiError extends Error {
    constructor(private dto: ApiErrorDto) {
        super(extractMessage(dto));

        // https://stackoverflow.com/q/41102060/574930
        Object.setPrototypeOf(this, new.target.prototype);
        this.stack = new Error().stack;
    }

    public readonly errorCode =
        (this.dto.code || this.dto.responseCode || this.dto.errorCode)?.toString() || '';

    public readonly timestamp = stringToDate(this.timeString);

    public subErrors = this.dto.responseDetails;

    private get timeString() {
        return this.dto.time || this.dto.timestamp;
    }
}

/**
 * A `TmApiError` generated from an `ErrorResponseDto`.
 */
export class ResponseError extends TmApiError {
    constructor(dto: ErrorResponseDto) {
        super({
            message: extractMessage(dto),
        });
    }
}

function stringToDate(dateString?: string) {
    if (!exists(dateString)) {
        return new Date();
    }
    try {
        const date = new Date(dateString);
        if (Number.isNaN(date.getTime())) {
            return new Date();
        }
        return date;
    } catch {
        return new Date();
    }
}

function extractMessage(dto: ApiErrorDto | ErrorResponseDto) {
    const defaultMessage = 'An unknown error occurred.';

    if (isErrorResponse(dto)) {
        return dto.errorMessage || defaultMessage;
    }

    return (
        dto.message || dto.responseMessage || dto.errorMessage || dto.errorSummary || defaultMessage
    );
}
