import { css, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

export const tagName = 'tm-loader';
@customElement(tagName)
export class TmLoader extends LitElement {
    static get styles() {
        return css`
            @keyframes spin {
                100% {
                    transform: rotate(360deg);
                }
            }
            :host {
                display: inline-block;
                animation: spin 0.75s linear infinite;
                transform-origin: center;
                width: 16px;
                height: 16px;
                border-width: 2px !important;
                border-style: solid;
                border-color: var(--tm-loader-color) !important;
                border-right-color: transparent !important;
                border-radius: 50%;
            }
            :host([inline]) {
                width: 12px;
                height: 12px;
            }
            :host([card]) {
                display: block;
                width: 28px;
                height: 28px;
                border-width: 4px !important;
                margin: 80px auto;
            }
            :host([reversed]) {
                --tm-loader-color: var(--tm-loader-reversed);
            }
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmLoader;
    }
}
