import { FiDate } from '@treasury/domain/dates';
import { formatUsdCurrency } from '@treasury/utils/functions/currency.helpers';
import { format } from 'date-fns';
import { AchPayment } from '../types/ach-payment.type';

export type AchPaymentViewModel = {
    status: string;
    transactionId: string;
    name: string;
    creditAmount: string;
    debitAmount: string;
    paymentDate: string;
    id: number;
};

export class AchPaymentTableViewModel {
    constructor(dto: AchPayment) {
        this.status = dto.status;
        this.transactionId = dto.transactionId;
        this.name = dto.name;
        this.creditAmount = formatUsdCurrency(dto.creditAmount);
        this.debitAmount = formatUsdCurrency(dto.debitAmount);
        this.paymentDate = format(new FiDate(dto.frequency.effectiveDate).date, 'P');
        this.id = dto.id;
    }

    status: string;

    transactionId: string;

    name: string;

    creditAmount: string;

    debitAmount: string;

    paymentDate: string;

    id: number;
}
