import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/layout/tm-section';
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BillPayWorkflowPaymentViewModel } from '../data/bill-pay-payment-workflow-view-model';
import { getPaymentRecurrenceText } from '../data/bill-pay-transform-form-data';

export const tagName = 'business-bill-pay-payment-workflow-read-only-detail';
@customElement(tagName)
export class BusinessBillPayPaymentWorkflowReadOnlyDetail extends TmBaseComponent {
    @property()
    private payment?: BillPayWorkflowPaymentViewModel;

    renderPaymentTypeBadge() {
        if (!this.payment?.payee) return nothing;
        return html`<tm-badge>${this.payment.paymentMethod}</tm-badge>`;
    }

    renderMemo() {
        if (!this.payment) return nothing;
        if (!this.payment.comment) return nothing;
        return html` <jhd-data-field
            class="pt-3"
            label="Memo"
            .value=${this.payment.comment}
        ></jhd-data-field>`;
    }

    renderRecurrence() {
        if (!this.payment) return nothing;
        if (!this.payment.recurrence) return nothing;
        return html`<jhd-data-field
            label="Frequency"
            .value=${getPaymentRecurrenceText(this.payment.recurrence)}
        ></jhd-data-field>`;
    }

    render() {
        if (!this.payment) return nothing;
        return html`
            <div class="flex justify-between">
                <span class="text-lg">${this.payment.payee}</span
                ><span>${this.renderPaymentTypeBadge()}</span>
            </div>
            <div class="text-xl">${this.payment.amount}</div>
            <div class="payment-details">
                <jhd-data-field label="From" .value=${this.payment.fromAccount}></jhd-data-field
                >${this.renderRecurrence()}
                <jhd-data-field label="Status" .value=${this.payment.status}></jhd-data-field>
            </div>
            <div class="timeline">
                <jhd-timeline
                    .events=${[
                        {
                            date: this.payment.processDate,
                            eventName: 'Processing',
                            eventCompleted: true,
                        },
                        {
                            date: this.payment.estimatedArrivalDate,
                            eventName: 'Estimated arrival',
                            eventCompleted: false,
                        },
                    ]}
                ></jhd-timeline>
            </div>
            ${this.renderMemo()}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: BusinessBillPayPaymentWorkflowReadOnlyDetail;
    }
}
