import { HolidayCalendarModelDto, HolidaysClient } from '@treasury/api/channel';
import { Injectable } from '@treasury/utils';
import { InjectProperty } from '@treasury/utils/dependency-injection';

@Injectable()
export class HolidaysService {
    @InjectProperty()
    private declare holidaysClient: HolidaysClient;

    public async getHolidays() {
        const response = await this.holidaysClient.holidaysGet();
        return response.data as HolidayCalendarModelDto[];
    }
}
