/* eslint-disable import/extensions */
import { NavigationService } from '@treasury/core/navigation';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { TmBaseComponent } from '@treasury/presentation';
import { achIcon, billsIcon, transferIcon, wiresIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-action-tile';
import '@treasury/presentation/components/tm-loader';
import { InjectProperty } from '@treasury/utils';
import { formatUsdCurrency } from '@treasury/utils/functions/currency.helpers';
import ApexCharts from 'apexcharts';
import { PropertyValueMap, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

interface BalanceTotals {
    availableBalance: string;
    currentBalance: string;
    collectedBalance: string;
}

export const tagName = 'account-detail-graph';
@customElement(tagName)
export class AccountDetailGraph extends TmBaseComponent {
    @InjectProperty()
    private declare featureFlagService: FeatureFlagService;

    @InjectProperty()
    private declare navService: NavigationService;

    @property({ type: Object })
    private chartOptions: unknown = {};

    @property({ type: String })
    public accountType = '';

    @property({ type: String })
    public institution = '';

    @property({ type: Object })
    private balanceTotals: BalanceTotals = {
        availableBalance: '',
        currentBalance: '',
        collectedBalance: '',
    };

    @property({ type: Object })
    private chart!: ApexCharts;

    @property({ type: Array })
    public accountBalanceHistory = [];

    @property({ type: Array })
    public dateSeries = [];

    @property({ type: Boolean, reflect: true })
    public loading = true;

    @state()
    dollarUSLocale = Intl.NumberFormat();

    @state()
    isPwaBetaBank = false;

    async firstUpdated() {
        this.isPwaBetaBank = await this.featureFlagService.isEnabled(Feature.IsPwaBetaBank);
        this.dollarUSLocale = Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        this.generateChart();
    }

    protected updated(changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        if (
            changedProperties.has('accountBalanceHistory') ||
            changedProperties.has('dateSeries') ||
            changedProperties.has('loading')
        ) {
            this.generateChart();
        }
    }

    generateChart() {
        this.chartOptions = {
            series: [
                {
                    name: 'Cash Position',
                    data: this.accountBalanceHistory,
                },
            ],
            chart: {
                width: '97%',
                height: 215,
                type: 'area',
                toolbar: {
                    tools: {
                        zoom: false,
                        pan: false,
                    },
                },
                export: {
                    csv: {
                        filename: undefined,
                        columnDelimiter: ',',
                        headerCategory: 'category',
                        headerValue: 'value',
                        dateFormatter(timestamp: string) {
                            return new Date(timestamp).toDateString();
                        },
                    },
                    svg: {
                        filename: undefined,
                    },
                    png: {
                        filename: undefined,
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            grid: {
                xaxis: {
                    lines: {
                        show: false,
                    },
                },
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
            },
            xaxis: {
                type: 'datetime',
                labels: {},
                categories: this.dateSeries,
            },
            yaxis: {
                labels: {
                    show: false,
                    formatter: (value: number) => this.dollarUSLocale.format(value),
                },
            },

            tooltip: {
                theme: 'dark',
            },
            colors: ['#fff'],
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 0.5,
                    opacityFrom: 0.6,
                    opacityTo: 0.1,
                    stops: [0, 70],
                },
            },
        };
        const chartContainer = this.shadowRoot?.getElementById('chart');
        if (this.accountBalanceHistory.length > 0 && chartContainer) {
            this.chart = new ApexCharts(chartContainer, this.chartOptions);
            this.chart.render();
        }
    }

    getTotalToDisplay() {
        if (
            this.accountType.toLowerCase() === 'loan' ||
            this.accountType.toLowerCase().includes('time')
        ) {
            return '--';
        }
        return formatUsdCurrency(Number(this.balanceTotals.availableBalance));
    }

    render() {
        if (this.loading) return html`<tm-loader card></tm-loader>`;
        return html`<div id="parent" class="uppercase text-sm">
            <div id="chart-header" class="ml-4 mb-3">
                <p class="mb-0.5 text-stone-50 text-xs font-light">Daily Available Balance</p>
                <div class="text-stone-50 text-2xl font-light">${this.getTotalToDisplay()}</div>
            </div>
            <div id="chart"></div>
            <div class="action-tiles flex mt-0 mb-3 mx-2 items-center justify-around">
                <tm-action-tile
                    .tileTitle=${'ACH Payment'}
                    .icon=${achIcon}
                    .action=${() => this.navService.navigate(`/ach`)}
                ></tm-action-tile
                ><tm-action-tile
                    .tileTitle=${'Transfer'}
                    .icon=${transferIcon}
                    .action=${() => this.navService.navigate(`/transfers`)}
                ></tm-action-tile>
                <tm-action-tile
                    .tileTitle=${'Pay Bill'}
                    .icon=${billsIcon}
                    .disabled=${this.isPwaBetaBank}
                    .action=${() => this.navService.navigate(`/business-bill-pay`)}
                ></tm-action-tile
                ><tm-action-tile
                    .tileTitle=${'Wire'}
                    .icon=${wiresIcon}
                    .action=${() => this.navService.navigate(`/wires`)}
                ></tm-action-tile>
            </div>
            <div id="chart-footer" class="flex w-full justify-around py-3">
                <div class="px-1 flex flex-col items-center">
                    <p class="text-xs text-[--secondary-text-color]">Current Balance</p>
                    <h3 class="text-xl text-[--header-text-color] font-normal">
                        ${formatUsdCurrency(Number(this.balanceTotals.currentBalance))}
                    </h3>
                </div>
                <div class="px-1 flex flex-col items-center">
                    <p class="text-xs text-[--secondary-text-color]">Collected Balance</p>
                    <h3 class="text-xl text-[--header-text-color] font-normal">
                        ${formatUsdCurrency(Number(this.balanceTotals.collectedBalance))}
                    </h3>
                </div>
            </div>
        </div> `;
    }

    static get styles() {
        return [
            css`
                :host {
                    width: 100vw;
                    background: var(--header-gradient);
                    box-shadow: var(--content-shadow);
                    padding-top: 10px;
                }
                :host([loading]) {
                    min-height: 180px;
                }
                #chart-header {
                    color: var(--nav-text-color);
                }
                #chart text {
                    fill: var(--nav-text-color);
                }
                #chart-footer {
                    background: var(--primary-background);
                }
                .apexcharts-menu {
                    background-color: var(--primary-background);
                }
                .apexcharts-toolbar .apexcharts-zoomin-icon svg,
                .apexcharts-toolbar .apexcharts-zoomin-icon:hover svg,
                .apexcharts-toolbar .apexcharts-zoomout-icon svg,
                .apexcharts-toolbar .apexcharts-zoomout-icon:hover svg,
                .apexcharts-toolbar .apexcharts-reset-icon svg,
                .apexcharts-toolbar .apexcharts-reset-icon:hover svg,
                .apexcharts-toolbar .apexcharts-menu-icon svg,
                .apexcharts-toolbar .apexcharts-menu-icon:hover svg {
                    fill: var(--icon-fill-color-light);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AccountDetailGraph;
    }
}
