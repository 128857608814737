/* eslint-disable import/no-duplicates */
import { AccountsService } from '@treasury/domain/accounts';
import { TmBaseComponent } from '@treasury/presentation';
import { checkIcon } from '@treasury/presentation/assets/icons';
import { InjectProperty } from '@treasury/utils';
import '@vaadin/grid';
import { GridActiveItemChangedEvent } from '@vaadin/grid';
import {
    GridColumnBodyLitRenderer,
    columnBodyRenderer,
    gridRowDetailsRenderer,
} from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-sort-column.js';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/jhd-icon';
import { TransactionViewModel } from '../data/transaction-view-model';
import { DepositCheck } from '../types/deposit-check.type';

export const tagName = 'account-transaction-details';
@customElement(tagName)
export class AccountTransactionDetails extends TmBaseComponent {
    @property({ type: Object })
    selectedTransaction?: TransactionViewModel;

    @property({ type: String })
    accountName?: '';

    @property({ type: Boolean })
    open = false;

    @InjectProperty()
    private declare service: AccountsService;

    @state()
    private checkImages: { frontImage: string; backImage: string }[] = [];

    @state()
    private depositImages: { frontImage: string; backImage: string }[] = [];

    @state()
    private depositCheckImage?: { frontImage: string; backImage: string } | null = null;

    @state()
    private loadingCheckImages = false;

    @state()
    private loadingDepositImages = false;

    @state()
    private depositCheckOpened: DepositCheck[] = [];

    async updated(changedProps: Map<string, any>) {
        if (changedProps.has('selectedTransaction') && this.selectedTransaction) {
            if (this.selectedTransaction.source.checkImageNumber) {
                this.loadingCheckImages = true;
                try {
                    await this.fetchCheckImages();
                } catch (e) {
                    console.log(e);
                } finally {
                    this.loadingCheckImages = false;
                }
            } else {
                this.checkImages = [];
            }
        }
    }

    async fetchCheckImages() {
        try {
            this.loadingCheckImages = true;
            const checkImages = (await this.service.getCheckImages(
                this.selectedTransaction?.source?.checkImageNumber ?? '',
                this.selectedTransaction?.source?.tranCodeDescription ?? ''
            )) as unknown as { frontImage: string; backImage: string }[];
            this.checkImages = checkImages;
        } catch (e) {
            console.log(e);
        } finally {
            this.loadingCheckImages = false;
        }
    }

    async updateOpenedDepositCheck(check: DepositCheck) {
        if (!check) return;
        const selectedCheck = check as DepositCheck;
        if (selectedCheck.checkId === this.depositCheckOpened[0]?.checkId) {
            this.depositCheckOpened = [];
        } else {
            this.loadingDepositImages = true;
            this.depositCheckImage = await this.service.getCheckImages(
                selectedCheck.checkId,
                'check'
            );
            this.depositCheckOpened = [selectedCheck] as DepositCheck[];
            this.loadingDepositImages = false;
        }
    }

    renderCheckImages() {
        if (!this.selectedTransaction) return nothing;
        if (!this.selectedTransaction.source.checkImageNumber) return nothing;
        if (this.loadingCheckImages) return html`<tm-loader card></tm-loader>`;
        if (!this.checkImages.length) return nothing;
        return html`<img
                alt="Check Front"
                src=${`data:image/jpg;base64,${this.checkImages[0].frontImage}`}
            />
            <img
                alt="Check Back"
                src=${`data:image/jpg;base64,${this.checkImages[0].backImage}`}
            /> `;
    }

    renderDepositCheckNumberColumn(checkImage: DepositCheck) {
        const index = this.checkImages.indexOf(checkImage) + 1;
        const { length } = this.checkImages;
        return html`<span class="text-sm text-[--header-text-color]"
            >Check Image ${index} of ${length}</span
        >`;
    }

    renderDepositCheckImages() {
        if (this.loadingDepositImages) return html`<tm-loader card></tm-loader>`;
        if (!this.depositCheckImage) return html`<div>No Check Images Available</div>`;
        return html`<div>
            <img
                alt="Check Front"
                src=${`data:image/jpg;base64,${this.depositCheckImage.frontImage}`}
            />
            <img
                alt="Check Back"
                src=${`data:image/jpg;base64,${this.depositCheckImage.backImage}`}
            />
        </div>`;
    }

    renderDepositCheckIcon() {
        return html`<div class="text-right"><jhd-icon .icon=${checkIcon}></jhd-icon></div>`;
    }

    renderCheckIcon() {
        if (this.loadingCheckImages) return nothing;
        if (!this.checkImages.length) return nothing;
        return html`<div><jhd-icon .icon=${checkIcon}></jhd-icon></div>`;
    }

    renderDepositChecksTable() {
        if (this.loadingCheckImages) return nothing;
        if (!this.checkImages.length) return nothing;
        return html`<div class="deposit-checks">
            <div class="check-images pt-3 px-3 border-t border-dashed">
                <vaadin-grid
                    .items=${this.checkImages}
                    .detailsOpenedItems=${this.depositCheckOpened}
                    @active-item-changed=${(e: GridActiveItemChangedEvent<DepositCheck>) => {
                        if (e.detail.value != null) this.updateOpenedDepositCheck(e.detail.value);
                    }}
                    ${gridRowDetailsRenderer<DepositCheck>((check: DepositCheck) =>
                        this.renderDepositCheckImages()
                    )}
                    ><vaadin-grid-sort-column
                        auto-width
                        ${columnBodyRenderer(
                            this
                                .renderDepositCheckNumberColumn as GridColumnBodyLitRenderer<DepositCheck>,
                            []
                        )}
                    ></vaadin-grid-sort-column>
                    <vaadin-grid-sort-column
                        text-align="end"
                        ${columnBodyRenderer(
                            this.renderDepositCheckIcon as GridColumnBodyLitRenderer<DepositCheck>,
                            []
                        )}
                    ></vaadin-grid-sort-column>
                </vaadin-grid>
            </div>
        </div>`;
    }

    render() {
        if (!this.selectedTransaction) return nothing;
        const transactionType = this.selectedTransaction?.source?.checkNumber
            ? html`<div class="flex justify-between pt-3">
                  <div>
                      <span class="text-lg text-[--header-text-color] font-medium">
                          ${this.selectedTransaction?.source?.tranCodeDescription}
                      </span>
                      <small>${this.selectedTransaction?.source?.checkNumber}</small>
                  </div>
                  ${this.renderCheckIcon()}
              </div>`
            : html`<div>${this.selectedTransaction?.source?.tranCodeDescription}</div>`;
        return html`<div>
            <div class="transaction-detail-header ml-6 pr-6 py-3 border-b border-dashed">
                <div class="transaction-description mb-2">${transactionType}</div>
                <div class="transaction-amount text-[--header-text-color]">
                    <small class="text-[--secondary-text-color]"
                        >${this.selectedTransaction?.source?.tranCodeType}</small
                    >
                    <p class="text-xl">${this.selectedTransaction?.amountFormatted}</p>
                </div>
            </div>
            <div class="transaction-account-detail mx-6 py-3">
                <jhd-data-field .label=${'Account'} .value=${this.accountName}></jhd-data-field>
                <jhd-data-field
                    .label=${'Transaction Date'}
                    .value=${this.selectedTransaction?.dateFormatted}
                ></jhd-data-field>

                <jhd-data-field
                    .label=${'Description'}
                    .value=${this.selectedTransaction?.source?.description}
                ></jhd-data-field>
            </div>
            ${this.renderCheckImages()} ${this.renderDepositChecksTable()}
        </div>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    background: var(--primary-background);
                }
                .transaction-detail-header {
                    border-bottom-color: var(--content-background);
                }
                .check-images {
                    border-color: var(--content-background);
                }
                path {
                    fill: var(--color-primary);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AccountTransactionDetails;
    }
}
