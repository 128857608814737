import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-loader';
import '@treasury/presentation/components/tm-table-header';
import '@vaadin/grid';
import { columnBodyRenderer, GridColumnBodyLitRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { filterTableResults } from '../../../utilities/table-result-filter';
import { TransferTemplateTableViewModel } from '../data/transfer-template-table-view-model';
import { TransferTemplateDto } from '../types/transfer-template-dto.type';

export const tagName = 'transfer-templates-table';
@customElement(tagName)
export class TransferTemplatesTable extends TmBaseComponent {
    @property({ type: Array })
    transferTemplates = [];

    @property({ type: String })
    institution = '';

    @property()
    loading = true;

    @state()
    fieldsToFilter = ['toAccount', 'fromAccount', 'transferType', 'amount', 'templateName'];

    @state()
    transferTemplateViewModels: TransferTemplateTableViewModel[] = [];

    @state()
    filteredTransferTemplateViewModels: TransferTemplateTableViewModel[] = [];

    updated(changedProps: Map<string, unknown>) {
        if (changedProps.has('loading') && !this.loading) {
            this.buildTransferTemplateViewModels();
        }
    }

    buildTransferTemplateViewModels() {
        if (!this.transferTemplates.length) return;
        this.transferTemplateViewModels = this.transferTemplates.map(
            (transferTemplate: TransferTemplateDto) =>
                new TransferTemplateTableViewModel(transferTemplate)
        ) as TransferTemplateTableViewModel[];
        this.filteredTransferTemplateViewModels = this.transferTemplateViewModels;
    }

    templateClicked(template: TransferTemplateTableViewModel) {
        this.dispatchEvent(new CustomEvent('template-clicked', { detail: template }));
    }

    filterResults(event: CustomEvent) {
        this.filteredTransferTemplateViewModels = filterTableResults(
            event.detail.value,
            this.transferTemplateViewModels,
            this.fieldsToFilter
        ) as TransferTemplateTableViewModel[];
    }

    onFiltersClick() {
        this.dispatchEvent(new CustomEvent('filter-transfer-templates'));
    }

    renderTableHeader() {
        return html`<tm-table-header
            .filterButton=${true}
            @filterButtonClick=${() =>
                this.dispatchEvent(new CustomEvent('filter-transfer-templates'))}
            @filterStringChange=${(e: CustomEvent) => this.filterResults(e)}
        ></tm-table-header>`;
    }

    renderTemplateNameAndAccountsColumn(transfer: TransferTemplateTableViewModel) {
        return html`<div class="flex">
            <div class="flex flex-col">
                <span class="text-sm font-medium">${transfer.templateName}</span>
                <span class="text-xs text-gray-500"
                    >From ${transfer.fromAccount} to ${transfer.toAccount}</span
                >
            </div>
        </div>`;
    }

    renderAmountColumn(transfer: TransferTemplateTableViewModel) {
        return html`<div class="flex justify-end">
            <span class="text-right text-sm font-medium">${transfer.amount}</span>
        </div>`;
    }

    renderTable() {
        if (this.loading) return html`<tm-loader card></tm-loader>`;
        if (!this.transferTemplates.length)
            return html`<div class="mx-auto w-full text-center mt-5 empty-message">
                No Transfer Templates Found
            </div>`;
        return html`<vaadin-grid
            class="border-y border-[--border-color]"
            .items=${this.filteredTransferTemplateViewModels}
            @active-item-changed=${({ detail }: CustomEvent) => this.templateClicked(detail.value)}
        >
            <vaadin-grid-column
                flex-grow="1"
                ${columnBodyRenderer(
                    this
                        .renderTemplateNameAndAccountsColumn as GridColumnBodyLitRenderer<TransferTemplateTableViewModel>,
                    []
                )}
            ></vaadin-grid-column>
            <vaadin-grid-column
                ${columnBodyRenderer(
                    this
                        .renderAmountColumn as GridColumnBodyLitRenderer<TransferTemplateTableViewModel>,
                    []
                )}
            ></vaadin-grid-column>
        </vaadin-grid>`;
    }

    render() {
        return html`<div class="pwa-table-wrapper">
            ${this.renderTableHeader()} ${this.renderTable()}
        </div>`;
    }

    static get styles() {
        return [
            css`
                .table-header,
                .empty-message {
                    color: var(--header-text-color);
                }
                vaadin-grid-cell-content {
                    padding: 6px 16px 6px 0;
                }
                vaadin-grid::part(first-column-cell) {
                    margin-left: 16px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TransferTemplatesTable;
    }
}
