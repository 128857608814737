import { TmBaseComponent } from '@treasury/presentation';
import {
    circleCheckmarkFilledIcon,
    circleCloseFilledIcon,
} from '@treasury/presentation/assets/icons';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export const tagName = 'correction-request-confirmation';
@customElement(tagName)
export class CorrectionRequestConfirmation extends TmBaseComponent {
    @property()
    requestSuccessful!: boolean;

    render() {
        let iconText = html`<div>Correction Request Received!</div>`;
        let icon = circleCheckmarkFilledIcon;
        if (!this.requestSuccessful) {
            iconText = html`<div>An error occurred submitting this correction request</div>`;
            icon = circleCloseFilledIcon;
        }
        const color = this.requestSuccessful ? 'green' : 'red';

        return html`<div class="w-full flex justify-center items-center flex-col">
            <jhd-icon
                class="m-2"
                height="50px"
                width="50px"
                color="${color}"
                .icon=${icon}
            ></jhd-icon>
            ${iconText}
        </div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: CorrectionRequestConfirmation;
    }
}
