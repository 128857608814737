import { TransactionDetails } from '@treasury/domain/accounts';
import { format } from 'date-fns';

const dollarUSLocale = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export class TransactionViewModel {
    // eslint-disable-next-line no-useless-constructor
    constructor(public source: TransactionDetails) {}

    public get dateFormatted() {
        return format(new Date(this.source.date), 'MM/dd/yyyy');
    }

    public get amountFormatted() {
        return this.source.debit
            ? dollarUSLocale.format(this.source.debit)
            : dollarUSLocale.format(this.source.credit);
    }
}
