import { UserReceivablesSettings } from '@treasury/domain/users/product-features/user-receivables-settings.entity';

export class UserReceivablesEntitlementsViewModel {
    constructor(private readonly userReceivablesSettings: UserReceivablesSettings) {}

    get ssoLoginId() {
        return this.userReceivablesSettings.ssoLoginId;
    }

    get accessKey() {
        return this.userReceivablesSettings.ssoAccessKey;
    }

    get products() {
        return this.userReceivablesSettings.products;
    }
}
