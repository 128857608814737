import { NavigationService } from '@treasury/core/navigation';
import { Wire, WireBeneficiary, WiresService, WireTemplate } from '@treasury/domain/wires';
import { TmBaseComponent } from '@treasury/presentation';
import { actionIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-bottom-sheet';
import { InjectProperty } from '@treasury/utils';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import '../shared/partials/wire-template-read-only';

export const tagName = 'wire-template-container';
@customElement(tagName)
export class WireTemplateContainer extends TmBaseComponent {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare navService: NavigationService;

    @property({ type: Number })
    public wireTemplateId!: number;

    @property({ type: Boolean, reflect: true })
    public hideHeader = false;

    @state()
    wire = new Wire();

    @state()
    wireTemplate = new WireTemplate();

    @state()
    loading = false;

    @InjectProperty()
    private declare wiresService: WiresService;

    @state()
    viewAudit = false;

    @state()
    institution = '';

    @state()
    beneficiarySheetOpen = false;

    @state()
    wireBeneficiary = {} as WireBeneficiary;

    async firstUpdated() {
        const { params } = await this.navService.getRouteData<{ id: string }>();
        this.wireTemplateId = +params.id;
        this.loading = true;

        try {
            this.wireTemplate = await this.wiresService.getTemplate(this.wireTemplateId);
            if (this.wireTemplate.beneficiary) {
                this.wireBeneficiary = new WireBeneficiary(this.wireTemplate.beneficiary);
            }
            this.configureHeader();
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    configureHeader() {
        if (this.hideHeader) return;
        this.headerService.configure({
            backAction: () => this.navService.navigateBack(),
            title: 'Wire Template Details',
            menuItems: [{ title: 'Download', icon: actionIcon, action: () => alert('download') }],
        });
    }

    renderBlockingLoader() {
        if (!this.loading) return nothing;
        return html`<tm-blocking-loader></tm-blocking-loader>`;
    }

    renderBeneficiarySheet() {
        return html`<tm-bottom-sheet
            .open=${this.beneficiarySheetOpen}
            @close=${() => {
                this.beneficiarySheetOpen = false;
            }}
        >
            <p slot="header-center">Beneficiary Detail</p>
            <div class="p-4">
                <wire-beneficiary-read-only
                    .beneficiary=${this.wireBeneficiary}
                ></wire-beneficiary-read-only></div
        ></tm-bottom-sheet>`;
    }

    renderDetails() {
        if (this.loading) return nothing;

        return html`<div class="detail-wrapper px-6 py-3">
            <wire-template-read-only
                .wireTemplate=${this.wireTemplate}
                @beneficiaryClick=${() => {
                    this.beneficiarySheetOpen = true;
                }}
            ></wire-template-read-only>
        </div>`;
    }

    render() {
        return [this.renderBlockingLoader(), this.renderBeneficiarySheet(), this.renderDetails()];
    }

    static get styles() {
        return [
            css`
                .detail-wrapper {
                    background-color: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: WireTemplateContainer;
    }
}
