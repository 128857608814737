import { CompanyAccountModelDto } from '@treasury/api/channel';
import { AchException } from '@treasury/domain/arp';
import { TmBaseComponent } from '@treasury/presentation';
import { filterIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/layout/tm-section';
import '@treasury/presentation/components/tm-badge';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-footer';
import '@treasury/presentation/components/tm-table-header';
import { InjectProperty } from '@treasury/utils';
import { columnBodyRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-selection-column.js';
import '@vaadin/grid/vaadin-grid-sort-column.js';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { filterTableResults } from '../../../../utilities/table-result-filter';
import AchExceptionsActivityDataProvider from '../data/ach-exceptions-activity-data-provider';
import { AchExceptionActivityVm } from '../view-models/ach-exception-activity';
import './ach-exceptions-activity-filter-sheet';

export const tagName = 'ach-exceptions-activity-table';
@customElement(tagName)
export class AchExceptionsActivityTable extends TmBaseComponent {
    @state()
    loading = true;

    @property({ type: Array })
    achExceptions: AchException[] = [];

    @property({ type: Array })
    filteredItems: AchException[] = [];

    @property({ type: Boolean })
    readonly = false;

    @property({ type: Array })
    private accounts: CompanyAccountModelDto[] = [];

    @property({ type: Array })
    private secCodes: { secCode?: string; description?: string }[] = [];

    @state()
    filterSheetOpen = false;

    @state()
    fieldsToFilter: string[] = [
        'decisionBy',
        'decisionDate',
        'decision',
        'amount',
        'achCompanyName',
    ];

    @InjectProperty()
    private declare dataProvider: AchExceptionsActivityDataProvider;

    protected updated(changedProperties: Map<string | number | symbol, unknown>): void {
        super.firstUpdated(changedProperties);
        if (changedProperties.has('loading')) {
            this.filteredItems = this.achExceptions;
        }
    }

    renderDecisionColumn(achException: AchExceptionActivityVm) {
        return html`<div class="flex flex-col">
            <span class="text-sm font-medium ml-2 pr-2">${achException.decisionBy}</span>
            <span class="text-sm ml-2 pr-2">${achException.decisionDate}</span>
        </div>`;
    }

    renderAmountColumn(achException: AchExceptionActivityVm) {
        return html`<div class="flex flex-col">
            <span class="text-sm font-medium mr-2 pr-2">${achException.amount}</span>
            <span class="text-sm mr-2 pr-2">${achException.achCompanyName}</span>
        </div>`;
    }

    renderColumns() {
        return html`<vaadin-grid-column
                ${columnBodyRenderer(this.renderDecisionColumn, [])}
            ></vaadin-grid-column
            ><vaadin-grid-column
                text-align="end"
                ${columnBodyRenderer(this.renderAmountColumn, [])}
            ></vaadin-grid-column>`;
    }

    renderTable() {
        if (this.loading)
            return html`<div class="text-center empty-state py-16">
                <tm-loader card></tm-loader>
            </div>`;

        if (!this.achExceptions.length)
            return html`<div class="text-center empty-state py-16">No decisions found.</div> `;

        return html`<vaadin-grid .items=${this.filteredItems} .dataProvider=${this.dataProvider}
            >${this.renderColumns()}</vaadin-grid
        >`;
    }

    renderTableHeader() {
        return html`<tm-table-header
            .filteringEnabled=${!this.readonly}
            .filterButton=${this.accounts.length && this.secCodes.length}
            @filterButtonClick=${() => (this.filterSheetOpen = true)}
            @filterStringChange=${(e: CustomEvent) => {
                this.filteredItems = filterTableResults(
                    e,
                    this.achExceptions,
                    this.fieldsToFilter
                ) as any;
            }}
            ><h2 class="font-medium">ACH Exception Decisions</h2></tm-table-header
        >`;
    }

    renderFilterSheet() {
        if (!this.accounts.length || !this.secCodes.length) return nothing;
        return html`<ach-exceptions-activity-filter-sheet
            .open=${this.filterSheetOpen}
            .accounts=${this.accounts}
            .secCodes=${this.secCodes}
            @close=${() => {
                this.filterSheetOpen = false;
            }}
        ></ach-exceptions-activity-filter-sheet>`;
    }

    render() {
        return html`<tm-section class="border-b">
                ${this.renderTableHeader()}${this.renderTable()} </tm-section
            >${this.renderFilterSheet()}`;
    }

    static get styles() {
        return [
            css`
                :host {
                    --lumo-space-xs: 3px;
                    --lumo-space-m: 0;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }
                .empty-state {
                    color: var(--header-text-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchExceptionsActivityTable;
    }
}
