import { UserInformationModelDto } from '@treasury/api/channel';
import { ConfigurationService } from '@treasury/core/config';
import { NavigationService } from '@treasury/core/navigation';
import { DashboardRequests } from '@treasury/domain/channel/requests/dashboard/dashboard';
import { LoginService } from '@treasury/domain/channel/services/login';
import { NavigationsService } from '@treasury/domain/channel/services/navigations/navigations-service';
import { EntitlementsService } from '@treasury/domain/entitlements';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { TmBaseComponent } from '@treasury/presentation';
import { chevronBackIcon } from '@treasury/presentation/assets/icons';
import { InjectProperty, isNonEmptyString } from '@treasury/utils';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { UserProfileClient } from '../../profile/clients/user-profile-client';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import { LayoutService } from '../../services/layout.service';

import { DashboardProduct } from '@treasury/domain/channel/types/dashboard/dashboard';
import {
    ActiveItemPathsDictionary,
    NavigationArea,
    NavigationMenuItem,
    NavigationOption,
    NavigationOptions,
} from '../types/navigation.types';

const BetaNavigationItems = [
    'dashboard',
    'accounts',
    'message-center',
    'log-out',
    'pending-approvals/ach',
    'ach',
    'transfers',
    'wires',
    'positive-pay/ach-exceptions',
    'positive-pay/check-exceptions',
];
export const tagName = 'navigation-menu';
@customElement(tagName)
export class NavigationMenu extends TmBaseComponent {
    constructor() {
        super();
        this.navService.routeChange$.subscribe(item => {
            this.navigationItem = this.getActiveNavArea(item.route);
            this.setActiveNavigationItem(this.navigationItem);
        });
    }

    @InjectProperty()
    private declare layoutService: LayoutService;

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare featureFlagService: FeatureFlagService;

    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare config: ConfigurationService;

    @InjectProperty()
    private declare entitlementsService: EntitlementsService;

    @state()
    private entitlements: Map<string, boolean> = new Map();

    @property({ type: Array })
    private navigationOptions: Array<NavigationMenuItem> = [];

    @state()
    private loading = false;

    @InjectProperty()
    private declare readonly service: NavigationsService;

    @InjectProperty()
    private declare readonly loginService: LoginService;

    @state()
    private lastLogin = '';

    @state()
    private visibleMenuItems: Array<NavigationMenuItem> = [];

    @InjectProperty()
    private declare client: UserProfileClient;

    @state()
    private user: UserInformationModelDto | undefined;

    @state()
    private activeNavigationItem?: NavigationOptions;

    @state()
    private isPwaBetaBank = false;

    @state()
    private navigationItem: NavigationArea = NavigationArea.Dashboard;

    @state()
    configuredProducts: Map<string, boolean> = new Map();

    async firstUpdated() {
        this.layoutService.isSidebarOpen$.subscribe(isSidebarOpen => {
            const container = this.shadowRoot?.querySelector('div.container');
            container?.classList.toggle('active', isSidebarOpen);
            window.setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 225);
        });
        this.loading = true;
        const configuredProducts = (await DashboardRequests.getProducts()) as DashboardProduct[];
        this.configuredProducts = new Map(
            configuredProducts.map(product => [
                product.type,
                product.status === 'Active' ? true : false,
            ])
        );
        this.isPwaBetaBank = await this.featureFlagService.isEnabled(Feature.IsPwaBetaBank);
        this.entitlements = await this.entitlementsService.getEntitlements();
        try {
            this.lastLogin = await this.loginService.getLastLogin();
            this.user = await this.client.getCurrentUser();
        } catch (e) {
            console.log(e);
        }

        try {
            const navigations =
                (await this.service.fetchNavigationOptions()) as unknown as Array<NavigationOption>;
            const navigationOptions = navigations
                .map((menu: NavigationOption) => {
                    const navKey = menu.key;
                    const mainKeys = menu.subMenus.map((submenu: NavigationOption) => submenu.key);
                    const subKeys = menu.subMenus.map((submenu: NavigationOption) =>
                        submenu.subMenus.map((sub: NavigationOption) => sub.key)
                    );
                    return [navKey, ...mainKeys, ...subKeys.flat()];
                })
                .flat();
            this.visibleMenuItems = this.navigationOptions.map((option: NavigationMenuItem) => {
                const visibleOptions = option.options
                    .filter((o: NavigationOptions) => !o.id || navigationOptions.includes(o.id))
                    .filter((o: NavigationOptions) => {
                        if (o.url === 'deposit') {
                            return (
                                this.configuredProducts.get(
                                    'RemoteDepositCaptureSsoProductFeature'
                                ) && this.entitlements.has('Remote Deposits')
                            );
                        }
                        return true;
                    });
                return {
                    ...option,
                    options: visibleOptions,
                };
            });
        } catch (e) {
            console.log(e);
        }

        this.loading = false;
    }

    updated() {
        this.setActiveNavigationItem(this.navigationItem);
    }

    handleKeyUp(event: KeyboardEvent) {
        const { key } = event;
        if (key === 'Return') this.layoutService.toggleSidebar();
    }

    setActiveNavigationItem(option: string) {
        const visibleNavigationItems = this.visibleMenuItems.map(o => o.options).flat();
        this.activeNavigationItem = visibleNavigationItems.find(o => o.url === option);
        this.shadowRoot?.querySelectorAll('li').forEach(li => {
            li.classList.remove('active-item');
            if (li.id === option) li.classList.add('active-item');
        });
    }

    /**
     * Determine the highlighted navigation menu item based on the current path.
     */
    private getActiveNavArea(route: string) {
        const pathSegments = route.split('/').filter(isNonEmptyString);
        pathSegments.shift();

        let activeItem: NavigationArea | typeof ActiveItemPathsDictionary =
            ActiveItemPathsDictionary[pathSegments[1]];

        if (typeof activeItem === 'object') {
            activeItem = activeItem[pathSegments[2]];
        }

        return typeof activeItem === 'string' ? activeItem : NavigationArea.Dashboard;
    }

    renderNavigationOptions() {
        if (this.loading) return html`<tm-loader card reversed></tm-loader>`;
        const navigationGroups = this.visibleMenuItems.map((option: NavigationMenuItem) => {
            const navigationItems = option.options.map(o => {
                const { icon, label, url } = o;

                const disabled = !BetaNavigationItems.includes(o.url) && this.isPwaBetaBank;
                return html`<li
                    .id=${o.url}
                    @click=${() => {
                        this.layoutService.toggleSidebar();
                    }}
                    class=${classMap({
                        'feature-disabled': disabled,
                    })}
                    @keyup=${(event: KeyboardEvent) => this.handleKeyUp(event)}
                >
                    <a
                        href="javascript:void(0);"
                        @click=${() => this.navService.navigate(`/${url}`)}
                        class="inline-block relative text-left w-full font-normal box-border text-base leading-6"
                        >${icon} <span class="label ml-1">${label}</span></a
                    >
                </li>`;
            });
            const navigationHeader =
                option.label && option.options.length
                    ? html`<li class="navigation-header label font-normal font-sm text-left">
                          ${option.label}
                      </li>`
                    : nothing;
            const navigationFooter =
                option.label && option.options.length
                    ? html`<div class="divider" aria-hidden="true"></div>`
                    : nothing;
            return html`${navigationHeader}${navigationItems}${navigationFooter}`;
        });
        const userProfileItem = html`<li
            class="user-profile-item"
            id="user-profile"
            @keyup=${(event: KeyboardEvent) => this.handleKeyUp(event)}
            @click=${() => {
                this.layoutService.toggleSidebar();
                this.navService.navigate(`/profile`);
            }}
        >
            <div class="user-badge">${this.user?.name.charAt(0).toUpperCase()}</div>
            <div class="user-data">
                <span class="user-name">${this.user?.name}</span>
                <div class="user-info">
                    <span>Last Login: ${this.lastLogin}</span>
                </div>
            </div>
        </li>`;
        return html`${navigationGroups}${userProfileItem}`;
    }

    render() {
        return html`<div class="container overflow-hidden h-full">
            <div
                class="sidebar-background fixed h-full"
                @click=${() => this.layoutService.toggleSidebar()}
                @keyup=${(e: KeyboardEvent) =>
                    e.key === 'Return' && this.layoutService.toggleSidebar()}
            ></div>
            <div
                class="sidebar relative h-full w-screen block overflow-hidden text-center z-10 box-border"
            >
                <div class="navigation-control flex justify-between py-4 pr-4 pl-3">
                    <div
                        class="toggle-container relative w-6 h-6"
                        @click=${() => this.layoutService.toggleSidebar()}
                        @keyup=${(e: KeyboardEvent) =>
                            e.key === 'Return' && this.layoutService.toggleSidebar()}
                    >
                        <div class="toggle-icon"></div>
                    </div>
                    <div
                        @click=${() => this.layoutService.toggleSidebar()}
                        @keyup=${(e: KeyboardEvent) =>
                            e.key === 'Return' && this.layoutService.toggleSidebar()}
                    >
                        ${chevronBackIcon}
                    </div>
                </div>
                <img
                    src="/pwa/assets/branding/${this.config.institutionId}/logo.webp"
                    alt="logo"
                    class="logo mb-8 ml-auto mr-auto relative px-4"
                />
                <ul class="menu relative text-center w-full">
                    ${this.renderNavigationOptions()}
                </ul>
            </div>
            <slot></slot>
        </div>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    --background-gradient: var(--background-gradient);
                }
                svg {
                    display: inline-block;
                }
                path {
                    fill: var(--header-bar-button-color, #fff);
                }
                .active-item {
                    border-left: var(--active-item-border);
                    background: var(--active-item-overlay);
                }
                .container .sidebar-background {
                    width: 100vw;
                    transition: background-color 0.9s;
                    pointer-events: none;
                }
                .container.active .sidebar-background {
                    background-color: rgba(0, 0, 0, 0.5);
                    pointer-events: auto;
                }
                .container .sidebar {
                    margin-left: -100vw;
                    padding-left: 76px;
                    transition: 0.9s;
                    background: var(--header-gradient);
                    box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.25);
                    padding-bottom: 16px;
                }
                .container .sidebar ul.menu li a {
                    text-decoration: none;
                    padding: 10px 0 10px 10px;
                    color: var(--nav-text-color);
                }
                .container.active .sidebar {
                    margin-left: -76px;
                    overflow: scroll;
                    -webkit-overflow-scrolling: touch;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                }
                .container.active .sidebar::-webkit-scrollbar {
                    display: none;
                }
                .container .sidebar .container {
                    position: fixed;
                    top: 50px;
                    width: inherit;
                    z-index: 3000;
                }
                .divider {
                    height: 1px;
                    margin: 10px 0;
                    background: var(--divider-color);
                    flex: none;
                    order: 2;
                    flex-grow: 0;
                }
                .container .sidebar ul.menu li.navigation-header {
                    color: var(--nav-text-color);
                    line-height: 20px;
                    padding: 10px 0 10px 10px;
                }
                .container .sidebar ul.menu li {
                    list-style: none;
                }
                .container .sidebar ul.menu li:hover a {
                    background: var(--item-hover-overlay);
                }
                .container .sidebar ul.navigation-icon {
                    position: relative;
                    margin-top: 30%;
                    width: 100%;
                    height: 55px;
                }
                .container .sidebar ul.navigation-icon li {
                    list-style: none;
                    width: calc(100% / 5);
                    height: 100%;
                    float: left;
                }
                .container .sidebar ul.navigation-icon li a {
                    text-decoration: none;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    position: relative;
                    color: var(--nav-text-color);
                    border-radius: 50%;
                    font-size: 25px;
                }
                .container .sidebar ul.navigation-icon li:hover a {
                    background: var(--item-hover-overlay);
                }
                .user-profile-item {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 5px 10px;
                    color: var(--nav-text-color);
                    font-size: 12px;
                    line-height: 25px;
                    font-weight: 400;
                }
                .user-profile-item .user-badge {
                    background: var(--body-text-completed-color);
                    border-radius: 50%;
                    width: 36px;
                    height: 36px;
                    margin-right: 10px;
                    font-size: 20px;
                    line-height: 36px;
                }
                .user-profile-item .user-data {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
                .user-profile-item .user-data .user-name {
                    font-size: 16px;
                }
                .user-info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
                .user-info small {
                    font-size: 65%;
                }
                .container .sidebar ul.navigation-icon a .fa {
                    line-height: 55px;
                }
                .toggle-icon,
                .toggle-icon::before,
                .toggle-icon::after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -1px);
                    transform-origin: center center;
                    width: 16px;
                    height: 2px;
                    background-color: var(--header-bar-button-color);
                    border-radius: 10px;
                    transition: 0.9s;
                }
                .toggle-icon::before {
                    transform: translate(-50%, -6px) rotate(0deg);
                }
                .toggle-icon::after {
                    transform: translate(-50%, 4px) rotate(0deg);
                }
                @media screen and (min-width: 768px) {
                    .container .sidebar-background {
                        display: none;
                    }
                    .container .sidebar {
                        width: 50px;
                        padding-left: 0;
                        transition: 0.5s;
                    }
                    .container.active .sidebar {
                        margin-left: 0;
                    }
                    .container .sidebar .label {
                        display: none;
                    }
                    .container .sidebar ul.menu li a {
                        padding: 10px 0 10px 10px;
                    }
                    .container .sidebar img {
                        display: none;
                    }
                    .container .sidebar .content {
                        top: 0;
                    }
                    .navigation-control {
                        display: none;
                    }
                }
                @media screen and (min-width: 1024px) {
                    .container .sidebar {
                        width: 250px;
                        transition: 0.5s;
                    }
                    .container .sidebar .label {
                        display: inline-block;
                    }
                    .container .sidebar img {
                        display: none;
                    }
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: NavigationMenu;
    }
}
