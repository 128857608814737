import { AchFrequencyModelDto, FrequencyModelDto } from '@treasury/api/channel';
import { format } from 'date-fns';
import {
    Frequency,
    FrequencyDay,
    FrequencyType,
    MonthlyFrequency,
    TwiceMonthlyFrequency,
    WeeklyFrequency,
} from './tm-frequency.types';

const startDateFromDto = (frequencyDto: FrequencyModelDto | AchFrequencyModelDto): Date => {
    if (
        frequencyDto.type === FrequencyType.OneTime &&
        ('valueDate' in frequencyDto || 'effectiveDate' in frequencyDto)
    ) {
        if ('valueDate' in frequencyDto) {
            return new Date(frequencyDto.valueDate as string);
        }

        return new Date((frequencyDto as AchFrequencyModelDto).effectiveDate as string);
    }

    return new Date(frequencyDto.startOn as string);
};

export const mapFrequencyModelDtoToFrequency = (
    frequencyDto: FrequencyModelDto | AchFrequencyModelDto
): Frequency => {
    let frequency = {
        type: frequencyDto.type.replace(/\s/g, '') as FrequencyType,
        startDate: startDateFromDto(frequencyDto),
    };

    if (frequencyDto.type !== FrequencyType.OneTime) {
        frequency = {
            ...frequency,
            ...(frequencyDto.endOn && { endDate: new Date(frequencyDto.endOn as string) }),
            ...(frequencyDto.noEndDate && { perpetual: true }),
        };
    }

    switch (frequency.type) {
        case FrequencyType.Weekly:
        case FrequencyType.EveryTwoWeeks:
            frequency = {
                ...frequency,
                repeatOn: frequencyDto.repeatOn
                    ? FrequencyDay[frequencyDto.repeatOn as keyof typeof FrequencyDay]
                    : frequencyDto.repeatOnDay1,
            } as WeeklyFrequency;
            break;
        case FrequencyType.Monthly:
        case FrequencyType.TwiceAMonth:
            frequency = {
                ...frequency,
                ...(frequencyDto.repeatOnDay1 && {
                    repeatOn:
                        frequencyDto.type === FrequencyType.Monthly
                            ? frequencyDto.repeatOnDay1
                            : [frequencyDto.repeatOnDay1, frequencyDto.repeatOnDay2],
                }),
                ...(frequencyDto.repeatOnLastBusinessDay && { repeatOnLastBusinessDay: true }),
            } as MonthlyFrequency | TwiceMonthlyFrequency;
            break;
    }

    return frequency;
};

export const mapFrequencyToFrequencyModelDto = (
    frequency: Frequency,
    ach = false
): FrequencyModelDto | AchFrequencyModelDto => {
    let frequencyDto = {
        type: frequency.type,
    } as FrequencyModelDto | AchFrequencyModelDto;

    if (frequency.type !== FrequencyType.OneTime) {
        frequencyDto = {
            ...frequencyDto,
            startOn: format(frequency.startDate, 'MM/dd/yyyy'),
            ...(frequency.endDate && { endOn: format(frequency.endDate, 'MM/dd/yyyy') }),
            ...(frequency.perpetual && { noEndDate: true }),
        };
    }

    switch (frequency.type) {
        case FrequencyType.OneTime:
            if (ach) {
                frequencyDto = {
                    ...frequencyDto,
                    effectiveDate: format(frequency.startDate, 'MM/dd/yyyy'),
                } as AchFrequencyModelDto;
            } else {
                frequencyDto = {
                    ...frequencyDto,
                    valueDate: format(frequency.startDate, 'MM/dd/yyyy'),
                };
            }
            break;

        case FrequencyType.Weekly:
        case FrequencyType.EveryTwoWeeks:
            frequencyDto = {
                ...frequencyDto,
                repeatOn: FrequencyDay[(frequency as WeeklyFrequency).repeatOn],
            };
            break;

        case FrequencyType.Monthly:
            frequencyDto = {
                ...frequencyDto,
                ...((frequency as MonthlyFrequency).repeatOn &&
                    !(frequency as MonthlyFrequency).repeatOnLastBusinessDay && {
                        repeatOnDay1: (frequency as MonthlyFrequency).repeatOn,
                    }),
                ...((frequency as MonthlyFrequency).repeatOnLastBusinessDay && {
                    repeatOnLastBusinessDay: true,
                }),
            };
            break;

        case FrequencyType.TwiceAMonth:
            frequencyDto = {
                ...frequencyDto,
                repeatOnDay1: (frequency as TwiceMonthlyFrequency).repeatOn[0],
                ...((frequency as TwiceMonthlyFrequency).repeatOn[1] &&
                    !(frequency as TwiceMonthlyFrequency).repeatOnLastBusinessDay && {
                        repeatOnDay2: (frequency as TwiceMonthlyFrequency).repeatOn[1] as number,
                    }),
                ...((frequency as TwiceMonthlyFrequency).repeatOnLastBusinessDay && {
                    repeatOnLastBusinessDay: true,
                }),
            };
            break;
    }

    return frequencyDto;
};
