import '@treasury/presentation/components/tm-checkbox';
import '@treasury/presentation/components/tm-phone-field';
import { html, nothing } from 'lit';

function renderErrorMessage(context: any) {
    if (!context.errorMessage) return nothing;

    return html`<omega-alert type="error" isVisible>${context.errorMessage}</omega-alert>`;
}

function renderUseSameNumberCheckbox(context: any) {
    if (context.verificationMethod.other.isVerified) return nothing;
    return html`<div class="checkbox-container">
        <tm-checkbox
            label=${`Use same number for ${context.verificationMethod.other.plural}`}
            @checked-changed=${(e: CustomEvent) => {
                context.useForBoth = e.detail.value;
            }}
        >
    </div>`;
}

export default {
    title: 'Add Phone Number',

    renderBody(context: any) {
        return html` <style>
                fieldset {
                    border: none;
                    padding: 15px 0;
                }
                /* hide legend without disabling it for screen readers */
                legend {
                    width: 0;
                    height: 0;
                    overflow: hidden;
                }
                p {
                    margin: 0px 0px 18px;
                }

                input {
                    font-size: 16px;
                    font-family: inherit;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                    padding: 8px;
                }
                .checkbox-container {
                    margin-top: 18px;
                    display: flex;
                    align-items: center;
                }
                .visible-label {
                    margin-right: 12px;
                }
            </style>

            ${renderErrorMessage(context)}
            <p>Receive ${context.verificationMethod.singular} and reply to verify your identity.</p>

            <tm-phone-field
                label="Phone number"
                .value=${context.phoneNumber?.value ?? ''}
                @value-changed=${(e: CustomEvent) => {
                    context.phoneNumber = e.detail;
                }}
                @keydown=${(e: KeyboardEvent) => {
                    if (e.code === 'Enter' && context.phoneNumber && !context.isLoading) {
                        context.sendOneTimePassword();
                    }
                }}
            >
            </tm-phone-field>

            ${renderUseSameNumberCheckbox(context)}`;
    },

    renderActions(context: any) {
        const label = context.errorMessage
            ? context.verificationMethod.retryLabel
            : 'Verify Number';
        return html`<div class="w-full flex justify-between">
            <tm-button
                importance="primary"
                @click=${context.sendOneTimePassword}
                ?disabled=${!context.phoneNumber || context.isLoading}
            >
                ${label}
            </tm-button>
            <tm-button importance="tertiary" @click=${context.cancel}>Cancel</tm-button>
        </div>`;
    },
};
