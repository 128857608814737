import { AchEntitlements } from '@treasury/domain/users/product-features';

export class UserAchEntitlementViewModel {
    constructor(readonly value: AchEntitlements) {}

    get achTemplateFeatures() {
        return this.value.achTemplateFeatures;
    }

    get achPaymentFeatures() {
        return this.value.achPaymentFeatures;
    }

    get internationalAchTemplateFeatures() {
        return this.value.internationalAchTemplateFeatures;
    }

    get internationalAchPaymentFeatures() {
        return this.value.internationalAchPaymentFeatures;
    }

    get recipientFeatures() {
        return this.value.recipientFeatures;
    }

    get reviewFeatures() {
        return this.value.reviewFeatures;
    }
}
