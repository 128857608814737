import { isThisQuarter } from 'date-fns';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { TmBaseComponent } from '../tm-base.component';

export const tagName = 'tm-body';
@customElement(tagName)
export class TmBody extends TmBaseComponent {
    @property({ type: Boolean })
    shadow = true;

    @property({ type: Boolean })
    background = true;

    @property({ type: Boolean })
    padding = true;

    render() {
        return html`
            <slot
                class=${classMap({
                    block: true,
                    'p-3': this.padding,
                    'content-shadow': this.shadow,
                    'content-background': this.background,
                })}
            ></slot>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    margin-bottom: auto;
                    background-color: var(--content-background);
                    color: var(--primary-text-color);
                }

                .content-shadow {
                    box-shadow: var(--content-shadow);
                }

                .content-background {
                    background: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmBody;
    }
}
