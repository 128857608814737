/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    CompanyProductConfigurationModelDto,
    ErrorHttpContentDto,
    InstitutionProductConfigurationModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class AccountReconClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconGetInstitutionProductConfiguration
     * @summary Gets the Institution Product Configuration
     * @request GET:/AccountRecon/InstitutionProductConfiguration
     */
    accountReconGetInstitutionProductConfiguration = (params: RequestParams = {}) =>
        this.http.request<InstitutionProductConfigurationModelDto, ErrorHttpContentDto>({
            path: `/AccountRecon/InstitutionProductConfiguration`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconSaveInstitutionProductConfiguration
     * @summary Saves the Institution Product Configuration
     * @request POST:/AccountRecon/InstitutionProductConfiguration
     */
    accountReconSaveInstitutionProductConfiguration = (
        institutionProductConfigurationModel: InstitutionProductConfigurationModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorHttpContentDto>({
            path: `/AccountRecon/InstitutionProductConfiguration`,
            method: 'POST',
            body: institutionProductConfigurationModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconGetCompanyProductConfiguration
     * @summary Gets the Company Product Configuration
     * @request GET:/AccountRecon/CompanyProductConfiguration/{companyId}
     */
    accountReconGetCompanyProductConfiguration = (companyId: number, params: RequestParams = {}) =>
        this.http.request<CompanyProductConfigurationModelDto, ErrorHttpContentDto>({
            path: `/AccountRecon/CompanyProductConfiguration/${companyId}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AccountRecon
     * @name AccountReconSaveCompanyProductConfiguration
     * @summary Saves the Company Product Configuration
     * @request POST:/AccountRecon/CompanyProductConfiguration
     */
    accountReconSaveCompanyProductConfiguration = (
        companyProductConfiguration: CompanyProductConfigurationModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorHttpContentDto>({
            path: `/AccountRecon/CompanyProductConfiguration`,
            method: 'POST',
            body: companyProductConfiguration,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
