import { NavigationModelDto, NavigationsClient } from '@treasury/api/channel';
import { Injectable } from '@treasury/utils';
import { ApplicationArea } from './application-area.type';

@Injectable()
export class ApplicationAreaService {
    constructor(private navigationsClient: NavigationsClient) {}

    private initialized = false;

    public navigationTree: NavigationModelDto[] = [];

    public async init() {
        if (this.initialized) {
            return;
        }
        this.navigationTree = (await this.navigationsClient.navigationsGet()).data;
        this.initialized = true;
    }

    public hasMenuPermission(permitKey: ApplicationArea) {
        if (!this.initialized) {
            throw new Error('Menu service is not initialized.');
        }
        const navigationData: NavigationModelDto[] = this.navigationTree.map((key: any) => key);
        return !!navigationData.find(x => x.key === permitKey);
    }

    public reset() {
        this.navigationTree = [];
        this.initialized = false;
    }
}
