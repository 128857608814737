import { ConfigurationService } from '@treasury/core/config';
import { NavigationService } from '@treasury/core/navigation';
import { AccountService, ChannelAuthenticationService } from '@treasury/domain/channel/services';
import { StatusCode } from '@treasury/domain/channel/services/login';
import { AuthenticationService } from '@treasury/domain/services/authentication';
import { TmBaseComponent } from '@treasury/presentation';
import { InjectProperty, SessionStorageService } from '@treasury/utils';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { UserOptionViewModel } from './components/user-selection';
import './login-container';

export const tagName = 'sso-callback-container';
@customElement(tagName)
export class SsoContainer extends TmBaseComponent {
    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare authService: AuthenticationService;

    @InjectProperty()
    private declare channelAuthService: ChannelAuthenticationService;

    @InjectProperty()
    private declare accountService: AccountService;

    @InjectProperty()
    private declare readonly config: ConfigurationService;

    @InjectProperty()
    private declare readonly sessionStorageService: SessionStorageService;

    @state()
    private errorMessage?: string;

    @state()
    private loading = true;

    @state()
    private statusCode?: string;

    @state()
    private userOptions: Array<UserOptionViewModel> = [];

    protected async firstUpdated(): Promise<void> {
        const { institutionId } = this.config;
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        try {
            this.loading = true;
            const {
                statusCode,
                message,
                alias,
                companyUniqueId,
                digitalId,
                userCompanyInformation,
            } = await this.authService.authenticationSsoViaUis(code ?? '', state ?? '');

            // Go to user selection if more than one TM is linked to the UIS ID
            if (userCompanyInformation && userCompanyInformation.length > 1) {
                this.statusCode = StatusCode.UserSelection;
                this.userOptions = userCompanyInformation;
                return;
            }

            // If only one user is linked go ahead and log them in
            const loginData = {
                institution: institutionId,
                companyId: companyUniqueId,
                loginId: alias,
                userName: alias,
                useRefreshTokens: false,
                digitalId,
            };

            this.sessionStorageService.set('user', JSON.stringify(loginData));

            this.statusCode = await this.channelAuthService.startAuthWorkflow({
                statusCode,
                message,
                numberOfSecurityQuestions: 0,
                daysUntilPasswordExpires: 0,
            });
        } catch (ex: any) {
            this.errorMessage = ex.message;
        } finally {
            this.loading = false;
        }
    }

    renderErrorMessage() {
        if (!this.errorMessage) return nothing;
        return html`
            <div class="error">
                <omega-icon icon="times-circle"></omega-icon>
                ${this.errorMessage}
            </div>
        `;
    }

    protected render() {
        if (this.loading || this.statusCode === StatusCode.Loading) {
            return html`<blocking-loader></blocking-loader>`;
        }

        if (this.errorMessage) {
            this.renderErrorMessage();
        }

        if (this.statusCode && this.statusCode !== StatusCode.Loading && !this.errorMessage) {
            return html`<login-container
                .statusCode=${this.statusCode}
                .userOptions=${this.userOptions}
            ></login-container>`;
        }

        return nothing;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                }

                @media screen and (min-width: 1024px) {
                    :host {
                        max-width: 50vw;
                    }
                }

                .error {
                    border: 1px solid #ee3a3b;
                    border-left: 4px solid #ee3a3b;
                    padding: 7px 15px;
                    margin: 15px;
                    display: flex;
                    align-items: center;
                }
                omega-icon {
                    margin-right: 16px;
                    color: #ee3a3b;
                    font-size: 24px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: SsoContainer;
    }
}
