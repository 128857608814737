import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-select';
import { DeepReactive } from '@treasury/utils/lit-helpers';
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { notificationTypeItems } from '../data/notification-type-items';

export const tagName = 'notifications-filter-sheet';
@customElement(tagName)
export class NotificationsFilterSheet extends TmBaseComponent {
    @property({ type: Boolean })
    public open = false;

    @state()
    private notificationTypes: { label: string; value: string }[] = notificationTypeItems;

    @DeepReactive()
    validState = {
        type: true,
    };
    onApplyFilters() {
        this.dispatchEvent(
            new CustomEvent('applyFilters', {
                detail: {
                    notificationTypes: this.notificationTypes.map(item => item.value),
                },
            })
        );
        this.dispatchEvent(new CustomEvent('close'));
    }

    private onResetForm() {
        this.notificationTypes = notificationTypeItems;
    }

    renderFilters() {
        return html`<form>
            <tm-multi-select
                label="Notification Type"
                .allowSelectAll=${true}
                .items=${notificationTypeItems}
                .selectedItems=${this.notificationTypes}
                @selected-items-changed=${(e: CustomEvent) => {
                    this.notificationTypes = e.detail;
                    this.validState.type = !!this.notificationTypes.length;
                }}
            >
            </tm-multi-select>
        </form>`;
    }

    render() {
        return html`<tm-bottom-sheet
            .open=${this.open}
            @close=${() => {
                this.open = false;
                this.dispatchEvent(new CustomEvent('close'));
            }}
            ><p slot="header-center">Filter Notifications</p>
            <p slot="header-right"></p>
            <tm-body>${this.renderFilters()}</tm-body>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: 'Apply Filters',
                        onClick: () => {
                            this.onApplyFilters();
                        },
                        disabled: Object.values(this.validState).some(isValid => !isValid),
                    },
                    {
                        importance: 'secondary',
                        text: 'Reset',
                        onClick: () => {
                            this.onResetForm();
                        },
                    },
                ] as ButtonConfig[]}
            ></tm-footer>
        </tm-bottom-sheet>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: NotificationsFilterSheet;
    }
}
