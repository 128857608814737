import { AccountType3Dto } from '@treasury/api/channel';
import { BillPayAccountModel } from '@treasury/domain/bill-pay';

const getAccountTypeDisplay = (accountType: AccountType3Dto | undefined) => {
    switch (accountType) {
        case AccountType3Dto.Checking:
            return 'Checking';
        case AccountType3Dto.Savings:
            return 'Savings';
        default:
            return '';
    }
};

export class BillPayAccountTableViewModel {
    constructor(readonly value: BillPayAccountModel) {}

    get accountNumber() {
        return this.value.accountNumber;
    }

    get accountType() {
        return getAccountTypeDisplay(this.value.accountType);
    }

    get name() {
        return this.value.name;
    }

    get routingNumber() {
        return this.value.routingNumber;
    }
}
