import { TmBaseComponent } from '@treasury/presentation';
import { css, html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

export const tagName = 'jhd-action-button';
@customElement(tagName)
export class JhdActionButton extends TmBaseComponent {
    @property({ type: String })
    public label = '';

    @property({ type: Object })
    public icon: TemplateResult = html``;

    @property({ type: Number })
    public approvals = 0;

    @property({ type: Function })
    public action: () => void = () => null;

    @property({ type: Function })
    public keyup: () => void = () => null;

    @property({ type: Boolean })
    public disabled = false;

    render() {
        return html`<div
            class=${classMap({
                'action-button': true,
                'flex flex-col items-center relative p-5': true,
                'feature-disabled': this.disabled,
            })}
            @click=${() => this.action()}
            @keyup=${() => this.keyup()}
        >
            <div class="action-button-icon w-8 mb-1">${this.icon}</div>
            <div class="action-button-approvals absolute">
                <jha-badge ?danger=${this.approvals !== 0}>${this.approvals}</jha-badge>
            </div>
            <div class="text-center">${this.label}</div>
        </div>`;
    }

    static get styles() {
        return [
            css`
                .action-button {
                    width: 7rem;
                }
                .action-button-icon svg {
                    width: 100%;
                    height: auto;
                }
                .action-button-icon path {
                    fill: var(--color-primary);
                }
                .action-button-approvals {
                    top: 10px;
                    right: 15px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: JhdActionButton;
    }
}
