import { PaymentTypes } from '@treasury/domain/channel/types/payment';
import { TmBaseComponent } from '@treasury/presentation';
import { checkmarkIcon, closeIcon } from '@treasury/presentation/assets/icons';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../components/jhd-icon';
import { PendingAchPaymentViewModel } from '../data/pending-ach-view-model';
import { PendingLoanViewModel } from '../data/pending-loan-view-model';
import { PendingTransferViewModel } from '../data/pending-transfer-view-model';
import { PendingUserViewModel } from '../data/pending-user-view-model';
import { PendingWireViewModel } from '../data/pending-wire-view-model';

export const tagName = 'approval-review-table';
@customElement(tagName)
export class ApprovalReviewBottomSheet extends TmBaseComponent {
    @property({ type: Array })
    public activeItems = [];

    @property({ type: String })
    public activeTab = '';

    formatCurrency(amount: number) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(amount);
    }

    renderAchPaymentDetails(item: PendingAchPaymentViewModel) {
        return html`<div class="text-sm font-medium">${item.value.name}</div>
            <div class="text-xs">${item.value.transactionId}</div>`;
    }

    renderTransferPaymentDetails(item: PendingTransferViewModel) {
        return html`<div class="text-sm font-medium">From: ${item.value.fromAccount}</div>
            <div class="text-xs">To: ${item.value.toAccount}</div>`;
    }

    renderLoanPaymentDetails(item: PendingLoanViewModel) {
        return html`<div class="text-sm font-medium">From: ${item.value.fromAccount}</div>
            <div class="text-xs">To: ${item.value.toAccount}</div>`;
    }

    renderWirePaymentDetails(item: PendingWireViewModel) {
        return html`<div class="text-sm font-medium">From: ${item.value.beneficiaryName}</div>
            <div class="text-xs">To: ${item.value.debitAccount}</div>`;
    }

    renderUserDetails(item: PendingUserViewModel) {
        return html`<div class="text-sm font-medium">${item.value.userName}</div>
            <div class="text-xs">${item.value.userId}</div>`;
    }

    renderApprovalDetails(
        item:
            | PendingAchPaymentViewModel
            | PendingTransferViewModel
            | PendingLoanViewModel
            | PendingWireViewModel
            | PendingUserViewModel
    ) {
        switch (this.activeTab) {
            case PaymentTypes.ACH:
                return this.renderAchPaymentDetails(item as PendingAchPaymentViewModel);
            case PaymentTypes.Transfer:
                return this.renderTransferPaymentDetails(item as PendingTransferViewModel);
            case PaymentTypes.Loan:
                return this.renderLoanPaymentDetails(item as PendingLoanViewModel);
            case PaymentTypes.Wire:
                return this.renderWirePaymentDetails(item as PendingWireViewModel);
            case 'user':
                return this.renderUserDetails(item as PendingUserViewModel);
            default:
                return nothing;
        }
    }

    renderAmounts(item: PendingAchPaymentViewModel) {
        const amounts = [];
        if (item.value.creditAmount) {
            const creditAmount = html`
                <div class="font-medium text-sm ">
                    ${item.creditAmount}
                    <span class="text-xs font-normal">CR</span>
                </div>
            `;
            amounts.push(creditAmount);
        }
        if (item.value.debitAmount) {
            const debitAmount = html`
                <div class="font-medium text-sm ">
                    ${item.debitAmount}
                    <span class="text-xs font-normal">DR</span>
                </div>
            `;
            amounts.push(debitAmount);
        }
        return amounts;
    }

    renderUserStatus(item: PendingUserViewModel) {
        return html`<div>${item.value.status}</div>`;
    }

    renderAmount(item: PendingTransferViewModel | PendingLoanViewModel | PendingWireViewModel) {
        return html`${item.amount}`;
    }

    renderPaymentAmount(
        item:
            | PendingAchPaymentViewModel
            | PendingTransferViewModel
            | PendingLoanViewModel
            | PendingWireViewModel
    ) {
        if (this.activeTab === PaymentTypes.ACH) {
            return html`
                <div class="flex flex-col">
                    ${this.renderAmounts(item as PendingAchPaymentViewModel)}
                </div>
            `;
        }
        if (this.activeTab === 'user')
            return this.renderUserStatus(item as unknown as PendingUserViewModel);
        return this.renderAmount(
            item as PendingTransferViewModel | PendingLoanViewModel | PendingWireViewModel
        );
    }

    render() {
        const decisionedPayments = this.activeItems.map(
            (
                item:
                    | PendingAchPaymentViewModel
                    | PendingTransferViewModel
                    | PendingLoanViewModel
                    | PendingWireViewModel
            ) => {
                const paymentState = item.selectedState.toLowerCase();
                const paymentIcon = paymentState === 'approved' ? checkmarkIcon : closeIcon;
                return html`<div class="payment-container flex">
                    <div class="payment-status flex border-b">
                        <div
                            class="icon-container icon-container-${paymentState} flex items-center px-3 border-l-4"
                        >
                            <div
                                class="payment-icon payment-icon-${paymentState} w-6 h-6 rounded-full flex justify-center items-center"
                            >
                                <jhd-icon
                                    .icon="${paymentIcon}"
                                    .color="${'var(--approval-icon-color)'}"
                                    .width=${'18px'}
                                    .height=${'18px'}
                                ></jhd-icon>
                            </div>
                        </div>
                    </div>
                    <div
                        class="payment-info flex justify-between items-center w-full py-2 pr-4 border-b border-[--border-color]"
                    >
                        <div class="flex flex-col">${this.renderApprovalDetails(item)}</div>
                        <div
                            class="payment-amount flex flex-col justify-center items-end text-sm font-medium"
                        >
                            ${this.renderPaymentAmount(item)}
                        </div>
                    </div>
                </div>`;
            }
        );
        return html`${decisionedPayments}`;
    }

    static get styles() {
        return [
            css`
                .payment-status {
                    border-color: transparent;
                }
                .icon-container {
                    border-color: transparent;
                }
                .icon-container-approved {
                    border-left-color: var(--approved-color);
                }
                .icon-container-rejected {
                    border-left-color: var(--rejected-color);
                }
                .payment-icon-approved {
                    background-color: var(--approved-color);
                }
                .payment-icon-rejected {
                    background-color: var(--rejected-color);
                }
                div.text-sm {
                    color: var(--primary-text-color);
                }
                div.text-xs {
                    color: var(--hint-text-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: ApprovalReviewBottomSheet;
    }
}
