import { OmegaDialogHandle, OmegaDialogService } from '@treasury/omega/services/omega-dialog';
import { Injectable, noOp } from '@treasury/utils';
import { html } from 'lit';
import { TmOffline } from './tm-offline.component';

import './tm-offline.component';

@Injectable()
export class OfflineDialogService {
    constructor(private readonly dialogService: OmegaDialogService) {}

    private dialogHandle?: OmegaDialogHandle<TmOffline>;

    public open() {
        if (this.dialogHandle) {
            return this.dialogHandle.closed.then(noOp);
        }

        this.dialogHandle = this.dialogService.open<TmOffline>(
            html`<tm-offline></tm-offline>`,
            'Offline',
            {
                preventClose: true,
                renderButtons: false,
            }
        );
        this.dialogHandle.closed.then(() => (this.dialogHandle = undefined));

        return this.dialogHandle.closed.then(noOp);
    }

    public close() {
        if (!this.dialogHandle) {
            return Promise.resolve();
        }

        const { closed } = this.dialogHandle;
        this.dialogHandle.close();

        return closed.then(noOp);
    }
}
export async function showOfflineDialog() {}
