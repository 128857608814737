import { AchRecipientModelDto } from '@treasury/api/channel';
import { AchTemplate } from '@treasury/domain/ach';
import { LabeledList, TmContainer } from '@treasury/presentation';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

export const tagName = 'ach-template-data';
@customElement(tagName)
export class AchTemplateData extends TmContainer {
    @property()
    template?: AchTemplate;

    @state()
    private recipients: AchTemplate['recipients'] = [];

    @state()
    private openedRecipients: AchRecipientModelDto[] = [];

    @state()
    private auditOpen = false;

    private toggleAudit() {
        this.auditOpen = !this.auditOpen;
    }

    private get renderStatusIcon() {
        return html`<jhd-status .status=${this.template?.status}></jhd-status>`;
    }

    renderAuditData() {
        if (!this.auditOpen) return nothing;
        if (!this.template?.audit) return nothing;
        return html`<div class="pb-2">
            <div class="pb-2 font-medium">Audit</div>
            <div class="max-h-36 overflow-y-auto font-normal text-sm">${this.template.audit}</div>
        </div>`;
    }

    renderAudit() {
        if (!this.template?.audit) return nothing;
        const auditButtonText = this.auditOpen ? 'Hide Audit' : 'View Audit';
        return html`
            ${this.renderAuditData()}
            <tm-button class="mt-2" @click=${() => this.toggleAudit()}>
                ${auditButtonText}
            </tm-button>
        `;
    }

    render() {
        if (!this.template) return nothing;
        return html`<div class="detail-header flex flex-row justify-between items-start w-full">
                <div class="detail-amount flex flex-col">
                    <div class="text-lg font-medium mb-1">${this.template.name}</div>
                </div>
                <div class="flex flex-col items-end">
                    <span class="detail-status flex items-center my-1 text-xs"
                        >${this.renderStatusIcon}&nbsp;${this.template.status}</span
                    ><span class="text-sm font-medium amount"
                        >${this.template.creditAmountFormatted}&nbsp;<span
                            class="credit-debit-notation text-xs font-normal"
                            >CR</span
                        ></span
                    ><span class="text-sm font-medium amount"
                        >${this.template.debitAmountFormatted}&nbsp;<span
                            class="credit-debit-notation text-xs font-normal"
                            >DR</span
                        ></span
                    >
                </div>
            </div>
            <div class="detail-content py-4 border-b border-dashed border-[--border-color]">
                <tm-labeled-list
                    .list=${new LabeledList(
                        this.template,
                        [
                            'companyName',
                            'companyId',
                            'secCode',
                            'frequencyDisplay',
                            'offsetAccount',
                            'entryDescription',
                            'discretionaryData',
                        ],
                        {
                            companyName: 'ACH Company',
                            companyId: 'ACH Company ID',
                            secCode: 'SEC Code',
                            frequencyDisplay: 'Frequency',
                            offsetAccount: 'Offset Account',
                            entryDescription: 'Entry Description',
                            discretionaryData: 'Discretionary Data',
                        }
                    )}
                ></tm-labeled-list>
            </div>
            <div class="detail-wrapper pl-6 pr-4 py-3">${this.renderAudit()}</div>`;
    }

    static get styles() {
        return [
            css`
                jha-data-field {
                    margin: 0;
                }
                vaadin-grid-cell-content {
                    padding: 0;
                }
                vaadin-grid::part(cell) {
                    border-top: none;
                    border-bottom: 1px solid var(--border-color);
                }
                .detail-wrapper {
                    background: var(--primary-background);
                }
                .detail-amount,
                .detail-status,
                .recipients-table-header,
                .amount {
                    color: var(--header-text-color);
                }
                .credit-debit-notation {
                    color: var(--secondary-text-color);
                }
                .recipient-chevron svg {
                    display: inline-block;
                }
                .max-h-36 {
                    max-height: 9rem;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchTemplateData;
    }
}
