export enum FrequencyType {
    OneTime = 'OneTime',
    Weekly = 'Weekly',
    EveryTwoWeeks = 'EveryTwoWeeks',
    TwiceAMonth = 'TwiceAMonth',
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    EverySixMonths = 'EverySixMonths',
    Yearly = 'Yearly',
}

export const FrequencyTypeLabels = {
    [FrequencyType.OneTime]: 'One Time',
    [FrequencyType.Weekly]: 'Weekly',
    [FrequencyType.EveryTwoWeeks]: 'Every Two Weeks',
    [FrequencyType.TwiceAMonth]: 'Twice a Month',
    [FrequencyType.Monthly]: 'Monthly',
    [FrequencyType.Quarterly]: 'Quarterly',
    [FrequencyType.EverySixMonths]: 'Every Six Months',
    [FrequencyType.Yearly]: 'Yearly',
};

export enum FrequencyDay {
    Monday = 1,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
}

export interface FrequencyBase {
    type: FrequencyType;
    startDate: Date;
}

export interface OneTimeFrequency extends FrequencyBase {
    type: FrequencyType.OneTime;
}

export interface FrequencyRange extends FrequencyBase {
    type:
        | FrequencyType.EverySixMonths
        | FrequencyType.EveryTwoWeeks
        | FrequencyType.Monthly
        | FrequencyType.OneTime
        | FrequencyType.Quarterly
        | FrequencyType.TwiceAMonth
        | FrequencyType.Weekly
        | FrequencyType.Yearly;
    endDate?: Date;
    perpetual?: boolean;
}

export interface WeeklyFrequency extends FrequencyRange {
    type: FrequencyType.Weekly | FrequencyType.EveryTwoWeeks;
    repeatOn: FrequencyDay;
}

export interface MonthlyFrequency extends FrequencyRange {
    type: FrequencyType.Monthly;
    repeatOn?: number;
    repeatOnLastBusinessDay: boolean;
}

export interface TwiceMonthlyFrequency extends FrequencyRange {
    type: FrequencyType.TwiceAMonth;
    repeatOn: [number, number | null];
    repeatOnLastBusinessDay: boolean;
}

export type Frequency =
    | OneTimeFrequency
    | FrequencyRange
    | WeeklyFrequency
    | MonthlyFrequency
    | TwiceMonthlyFrequency;

export const OneTimeTodayFrequency: Frequency = {
    type: FrequencyType.OneTime,
    startDate: new Date(),
};
