/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    BadRequestResultDto,
    LoginIdPasswordParametersModelDto,
    MessageCategoryModelDto,
    MessageCenterSearchTermsDto,
    MessageModelDto,
    UpdateMessageModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class MessagingcenterClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags MessagingCenter
     * @name MessagingCenterGetMessageCategories
     * @summary Gets message categories.
     * @request GET:/messagingcenter/getmessagecategories
     */
    messagingCenterGetMessageCategories = (params: RequestParams = {}) =>
        this.http.request<MessageCategoryModelDto[], any>({
            path: `/messagingcenter/getmessagecategories`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags MessagingCenter
     * @name MessagingCenterSaveMessage
     * @summary Save messages
     * @request POST:/messagingcenter/savemessage
     */
    messagingCenterSaveMessage = (params: RequestParams = {}) =>
        this.http.request<MessageModelDto, BadRequestResultDto>({
            path: `/messagingcenter/savemessage`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags MessagingCenter
     * @name MessagingCenterSearchMessages
     * @summary Search messages
     * @request POST:/messagingcenter/search
     */
    messagingCenterSearchMessages = (
        searchTerms: MessageCenterSearchTermsDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<MessageModelDto[], any>({
            path: `/messagingcenter/search`,
            method: 'POST',
            body: searchTerms,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags MessagingCenter
     * @name MessagingCenterUpdateMessage
     * @summary Update messages.
     * @request POST:/messagingcenter/update
     */
    messagingCenterUpdateMessage = (
        update: UpdateMessageModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<LoginIdPasswordParametersModelDto, any>({
            path: `/messagingcenter/update`,
            method: 'POST',
            body: update,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags MessagingCenter
     * @name MessagingCenterGetMessageThread
     * @summary Gets message threads by id.
     * @request GET:/messagingcenter/messagethread/{id}
     */
    messagingCenterGetMessageThread = (id: number, params: RequestParams = {}) =>
        this.http.request<MessageModelDto[], any>({
            path: `/messagingcenter/messagethread/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags MessagingCenter
     * @name MessagingCenterDownloadAttachment
     * @summary Download attachments.
     * @request GET:/messagingcenter/downloadattachment/{id}
     */
    messagingCenterDownloadAttachment = (id: number, params: RequestParams = {}) =>
        this.http.request<File, any>({
            path: `/messagingcenter/downloadattachment/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
