import { AuditModelDto } from '@treasury/api/channel';
import { CheckException } from '@treasury/domain/arp';
import usd from '@treasury/policy/lib/formatters/usd.formatter';

export class CheckExceptionVm {
    constructor(public exception: CheckException) {}

    formatDate(dateStr?: Date | string): string {
        if (dateStr === undefined || dateStr === null) return '';
        const date = new Date(dateStr);
        if (date.toDateString() === 'Invalid Date') return '';
        return Intl.DateTimeFormat(undefined, {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        }).format(date);
    }

    public get accountNumber() {
        return this.exception.accountNumber ?? '';
    }

    public get issuedPayee() {
        return this.exception.issuedPayee ?? '';
    }

    public get paidAmount() {
        return usd(this.exception.paidAmount ?? 0) ?? '';
    }

    public get issuedAmount() {
        return usd(this.exception.issuedAmount ?? 0) ?? '';
    }

    public get postedDate() {
        return this.formatDate(this.exception.postedDate) ?? '';
    }

    public get issuedDate() {
        return this.formatDate(this.exception.issuedDate) ?? '';
    }

    public get protected() {
        return this.exception.protected ? 'Yes' : 'No';
    }

    public get decidedToday() {
        return (
            this.exception.audits?.reduce((prev: boolean, audit: AuditModelDto) => {
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                const sinceYesterday = new Date(audit.timestamp) >= yesterday;
                return prev || sinceYesterday;
            }, false) || this.exception.isPastCutoff
        );
    }
}
