/* eslint-disable import/no-extraneous-dependencies */
import '@banno/jha-wc/src/forms/jha-form-select/jha-form-select.js';
import { NavigationService } from '@treasury/core/navigation';
import { CheckException, ExceptionDecisionStatus } from '@treasury/domain/arp';
import PositivePayService from '@treasury/domain/channel/services/positive-pay/positive-pay-service';
import { ReturnReasonsDescriptions } from '@treasury/domain/channel/types';
import '@treasury/omega/components/omega-button-bar.js';
import { TmBaseComponent } from '@treasury/presentation';
import { InjectProperty } from '@treasury/utils';
import '@vaadin/grid';
import { columnBodyRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-selection-column.js';
import '@vaadin/grid/vaadin-grid-sort-column.js';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import '../../../../components/jhd-approval-toggle';
import '../../../../components/jhd-icon';
import '../../../../components/jhd-list-selection-item';
import { JhaApprovalToggleStatus } from '../../../types';
import { columns as checkExceptionColumns } from '../../data/check-exceptions-columns';
import '../table/check-exception-row-detail';

export const tagName = 'check-exceptions-review-desktop';
@customElement(tagName)
export class CheckExceptionsReviewDesktop extends TmBaseComponent {
    @InjectProperty()
    private declare readonly navService: NavigationService;

    @InjectProperty()
    private declare positivePayService: PositivePayService;

    @property({ type: Array })
    checkExceptions: Array<CheckException> = [];

    @property()
    showTitle = true;

    @property()
    showSubmit = true;

    get tabTitle() {
        return 'Check Exceptions';
    }

    private columns() {
        return checkExceptionColumns.slice(0, checkExceptionColumns.length - 1);
    }

    handleSubmitClick() {
        this.dispatchEvent(new CustomEvent('submit'));
    }

    renderDefaultColumn(text: string) {
        return html`<div class="flex flex-col text-left">
            <span>${text}</span>
        </div>`;
    }

    renderDecisionColumn(exception: CheckException) {
        const getSelectedState = () => {
            if (exception.decisionStatus === ExceptionDecisionStatus.Pay) {
                return JhaApprovalToggleStatus.Approved;
            }
            if (exception.decisionStatus === ExceptionDecisionStatus.Return) {
                return JhaApprovalToggleStatus.Rejected;
            }
            return JhaApprovalToggleStatus.NA;
        };

        return html`
            <jhd-approval-toggle
                .readonly=${true}
                .selectedState=${getSelectedState()}
            ></jhd-approval-toggle>
        `;
    }

    renderDateColumn(object: CheckException, key: 'postedDate' | 'issuedDate') {
        const dateValue = object[key];
        if (dateValue?.length && dateValue.length > 1) {
            const date = new Date(dateValue);
            return html`<div class="text-center">
                ${date.getMonth()}/${date.getDate()}/${date.getFullYear()}
            </div>`;
        }
        return html`<div class="text-center">${dateValue}</div>`;
    }

    renderIssuedDate(object: CheckException) {
        return this.renderDateColumn(object, 'issuedDate');
    }

    renderPostedDate(object: CheckException) {
        return this.renderDateColumn(object, 'postedDate');
    }

    renderReturnReasonColumn(object: CheckException) {
        const options = [
            { name: ReturnReasonsDescriptions.None, value: 1 },
            { name: ReturnReasonsDescriptions.ReIssued, value: 2 },
            { name: ReturnReasonsDescriptions.WrongOrInvalidAmount, value: 3 },
        ];

        const value = options.find(option => option.name === object.exceptionReason);

        return this.renderDefaultColumn(`${value?.name ?? ReturnReasonsDescriptions.None}`);
    }

    renderAmountColumn(checkException: CheckException, key: 'paidAmount' | 'issuedAmount') {
        const amount = checkException[key];

        const formattedAmount = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(amount ?? 0);

        return this.renderDefaultColumn(formattedAmount);
    }

    renderPaidAmountColumn(checkException: CheckException) {
        return this.renderAmountColumn(checkException, 'paidAmount');
    }

    renderIssuedAmountColumn(checkException: CheckException) {
        return this.renderAmountColumn(checkException, 'issuedAmount');
    }

    renderColumns() {
        return html`
            ${this.columns().map(column => {
                switch (column.field) {
                    case 'decisionToPayOrReturn':
                        return html`<vaadin-grid-column
                            path="decisionToPayOrReturn"
                            header="Pay  /  Return"
                            width="140px"
                            text-align="center"
                            flex-grow="0"
                            ${columnBodyRenderer(this.renderDecisionColumn, [])}
                        >
                        </vaadin-grid-column>`;
                    case 'returnReason':
                        return html`<vaadin-grid-column
                            path="returnReason"
                            header="Return Reason"
                            width="241px"
                            ${columnBodyRenderer(this.renderReturnReasonColumn, [])}
                        ></vaadin-grid-column>`;
                    case 'accountNumber':
                        return html`<vaadin-grid-column
                            path="accountNumber"
                            header="Account"
                            width="215px"
                        ></vaadin-grid-column>`;
                    case 'checkNumber':
                        return html`<vaadin-grid-column
                            path="checkNumber"
                            header="Check Number"
                            text-align="center"
                            width="146px"
                        ></vaadin-grid-column>`;
                    case 'paidAmount':
                        return html`<vaadin-grid-column
                            path="paidAmount"
                            header="Paid Amount"
                            width="132px"
                            text-align="right"
                            ${columnBodyRenderer(this.renderPaidAmountColumn, [])}
                        ></vaadin-grid-column>`;
                    case 'issuedAmount':
                        return html`<vaadin-grid-column
                            path="issuedAmount"
                            header="Issued Amount"
                            width="144px"
                            text-align="right"
                            ${columnBodyRenderer(this.renderIssuedAmountColumn, [])}
                        ></vaadin-grid-column>`;
                    case 'postedDate':
                        return html`<vaadin-grid-column
                            path="postedDate"
                            header="Posted Date"
                            text-align="center"
                            width="129px"
                            ${columnBodyRenderer(this.renderPostedDate, [])}
                        ></vaadin-grid-column>`;
                    case 'issuedDate':
                        return html`<vaadin-grid-column
                            path="issuedDate"
                            header="Issued Date"
                            text-align="center"
                            width="129px"
                            flex-grow="0"
                            ${columnBodyRenderer(this.renderIssuedDate, [])}
                        ></vaadin-grid-column>`;
                    case 'issuedPayee':
                        return html`<vaadin-grid-column
                            path="issuedPayee"
                            header="Issued Payee"
                            width="213px"
                            flex-grow="0"
                        ></vaadin-grid-column>`;
                    default:
                        return html`
                            <vaadin-grid-column
                                path=${ifDefined(column.field)}
                                header=${column.label}
                                flex-grow="0"
                            ></vaadin-grid-column>
                        `;
                }
            })}
        `;
    }

    renderTable() {
        if (!this.checkExceptions?.length)
            return html`<p id="no-exceptions">No exceptions found</p>`;

        return html`
            <vaadin-grid .items=${this.checkExceptions}> ${this.renderColumns()} </vaadin-grid>
            <omega-button-bar .alignment=${'center'}>
                <vaadin-grid .items=${this.checkExceptions}> ${this.renderColumns()} </vaadin-grid>
                <omega-button-bar .alignment=${'left'}> ${this.renderActions()} </omega-button-bar>
            </omega-button-bar>
        `;
    }

    renderActions() {
        if (!this.showSubmit) return nothing;

        return html`
            <omega-button type="primary" @click=${this.handleSubmitClick}
                >Decision (${this.checkExceptions.length})</omega-button
            >
        </omega-button-bar>`;
    }

    renderTableHeader() {
        if (!this.showTitle) return nothing;

        return html`<div class="table-header">
            <h3>${this.tabTitle}</h3>
        </div>`;
    }

    render() {
        return html` <tm-card> ${this.renderTableHeader()} ${this.renderTable()} </tm-card> `;
    }

    static get styles() {
        return [
            css`
                :host {
                    --icon-svg-transform: scale(1) !important;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: CheckExceptionsReviewDesktop;
    }
}
