/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { TmBaseComponent } from '@treasury/presentation';
import { attachIcon } from '@treasury/presentation/assets/icons';
import '@vaadin/grid';
import { GridColumnBodyLitRenderer, columnBodyRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { Message } from '../types/message.types';

export const tagName = 'messages-table';
@customElement(tagName)
export class MessagesTable extends TmBaseComponent {
    @property({ type: Array })
    messages: Message[] = [];

    @property({ type: Array })
    selectedMessages: Message[] = [];

    @property({ type: Object })
    selectedMessage = {};

    messageClicked(message: Message) {
        this.selectedMessages = message ? [message] : [];
        const [selectedMessage] = this.selectedMessages;
        if (selectedMessage) {
            this.dispatchEvent(new CustomEvent('message-selected', { detail: selectedMessage }));
        }
    }

    renderNewIndicator(message: Message) {
        if (message.status.toLocaleLowerCase() === 'new') {
            return html`<div class="new-indicator"></div>`;
        }
        return nothing;
    }

    renderAttachmentIndicator(message: Message) {
        if (message.hasAttachment) {
            return html`<span class="attachment-indicator">${attachIcon}</span>`;
        }
        return nothing;
    }

    renderMessageDataColumn(message: Message) {
        const newIndicator = this.renderNewIndicator(message);
        const messageDiv = document.createElement('span');
        const truncatedMessageBody =
            message.body.length > 50 ? `${message.body.substring(0, 50)}...` : `${message.body}`;
        messageDiv.innerHTML = truncatedMessageBody;
        const attachmentIndicator = this.renderAttachmentIndicator(message);
        return html` <div class="flex pt-3">
            <div class="flex flex-col w-5 mr-2">
                <span class="text-center">${newIndicator}</span
                ><span class="text-center">${attachmentIndicator}</span>
            </div>
            <div class="message-info-column flex flex-col w-full border-b pr-4 pb-3">
                <div class="flex justify-between w-full mb-1">
                    <div>
                        <span class="message-id mr-1 text-xs">${message.messageId}</span
                        ><span
                            class="message-subject text-sm font-medium message-status-${message.status.toLowerCase()}"
                            >${message.subject}</span
                        >
                    </div>
                    <div class="message-time text-xs">
                        ${message.sentDate}&nbsp;${message.sentTime}
                    </div>
                </div>
                <div class="message-body text-xs text-gray-600">${messageDiv}</div>
            </div>
        </div>`;
    }

    renderMessageDateTimeColumn(message: Message) {
        return html`<div class="date-time-column flex flex-col">
            <small>${message.sentDate}</small><small>${message.sentTime}</small>
        </div>`;
    }

    render() {
        if (this.messages.length === 0)
            return html`<p class="no-accounts m-auto text-center py-9">
                <em>No Messages found</em>
            </p>`;
        return html`<vaadin-grid
            .items=${this.messages}
            .selectedItems=${this.selectedMessages}
            @active-item-changed=${({ detail }: CustomEvent) => this.messageClicked(detail.value)}
        >
            <vaadin-grid-column
                .hidden=${screen.width > 600}
                flex-grow="1"
                auto-width
                ${columnBodyRenderer(
                    this.renderMessageDataColumn as GridColumnBodyLitRenderer<Message>,
                    []
                )}
            ></vaadin-grid-column>
        </vaadin-grid>`;
    }

    static get styles() {
        return [
            css`
                .message-body {
                    wrap: break-word;
                }
                .new-indicator {
                    margin-right: 0;
                }
                .attachment-indicator > svg {
                    display: inline-block;
                    width: 100%;
                    height: auto;
                }
                vaadin-grid {
                    border: none;
                }
                vaadin-grid-cell-content {
                    box-sizing: border-box;
                    padding: 0 0 0 12px;
                }
                vaadin-grid::part(cell) {
                    border-top: none;
                    padding: 0;
                }
                .message-status-new {
                    color: var(--color-primary);
                }
                .message-status-open {
                    color: var(--header-text-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: MessagesTable;
    }
}
