import { AuditModelDto } from '@treasury/api/channel';
import { CheckException } from '@treasury/domain/arp';

export const auditShowsExceptionDecisionToday = (exception: CheckException) =>
    exception.audits?.reduce((prev: boolean, audit: AuditModelDto) => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const sinceYesterday = new Date(audit.timestamp) >= yesterday;
        return prev || sinceYesterday;
    }, false);

export const exceptionNeedsDecision = (exception: CheckException) =>
    !exception.isPastCutoff && !auditShowsExceptionDecisionToday(exception) && !exception.protected;

export const exceptionsDecidedToday = (exception: CheckException): boolean =>
    exception.isPastCutoff || !!auditShowsExceptionDecisionToday(exception) || exception.protected;
