import '@treasury/omega/components/omega-icon';
import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { StyleMixin } from '../../mix-ins';

export const tagName = 'tm-offline';
@customElement(tagName)
export class TmOffline extends StyleMixin(LitElement) {
    public render() {
        return html` <p class="text-center">
                <omega-icon id="network-icon" icon="wifi"></omega-icon>
            </p>
            <p class="text-sm p-1">You are currently experiencing poor connectivity.</p>
            <p class="text-sm p-1">
                To ensure you don't lose any in-progress work, you will be unable to interact with
                the application until your connection is restored.
            </p>
            <p class="text-sm p-1">
                This message will automatically be dismissed when you are online again. Please check
                back shortly.
            </p>`;
    }

    static styles = css`
        #network-icon {
            font-size: 8em;
            color: var(--color-primary);
            opacity: 50%;
        }
    `;
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmOffline;
    }
}
