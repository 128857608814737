import {
    AchFrequencyModelDto,
    AchPaymentModelDto,
    AchRecipientModelDto,
} from '@treasury/api/channel';
import { DomainEntity } from '../shared';
import { defaultAchPaymentModelDto } from './data/ach.data';
import { OffsetAccount } from './offset-account.entity';

export class AchPayment extends DomainEntity<AchPaymentModelDto> {
    public createDefault(): this {
        return new AchPayment(defaultAchPaymentModelDto) as this;
    }

    private _frequencyDisplay: string = '';

    private _offsetAccountDisplay: string = '';

    public get templateName() {
        return this.dto.templateName;
    }

    public get name() {
        return this.dto.name;
    }

    public get audit() {
        return this.dto.audit;
    }

    public set audit(value) {
        this.dto.audit = value;
    }

    public get creditAmount() {
        return this.dto.creditAmount;
    }

    public set creditAmount(value: number) {
        this.dto.creditAmount = value;
    }

    public get formattedCreditAmount() {
        return this.dto.creditAmount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }

    public get debitAmount() {
        return this.dto.debitAmount;
    }

    public set debitAmount(value: number) {
        this.dto.debitAmount = value;
    }

    public get formattedDebitAmount() {
        return this.dto.debitAmount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }

    public get updatedBy() {
        return this.dto.updatedBy;
    }

    public get id() {
        return this.dto.id;
    }

    public get permissions() {
        return this.dto.permissions ?? [];
    }

    public get recipientTotalDebitAmount() {
        const total = this.recipients
            ?.filter(r => r.transactionType === 'DR')
            .reduce((acc, r) => acc + r.amount, 0);

        this.debitAmount = total ?? 0;

        return total
            ? total.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
              })
            : '$0.00';
    }

    public get recipientTotalCreditAmount() {
        const total = this.recipients
            ?.filter(r => r.transactionType === 'CR')
            .reduce((acc, r) => acc + r.amount, 0);

        this.creditAmount = total ?? 0;

        return total
            ? total.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
              })
            : '$0.00';
    }

    public get status() {
        return this.dto.status;
    }

    public set status(value) {
        this.dto.status = value;
    }

    public get companyName() {
        return this.dto.achCompanyName;
    }

    public get companyId() {
        return this.dto.achCompanyId;
    }

    public get secCode() {
        return this.dto.secCode;
    }

    public get transactionId() {
        return this.dto.transactionId;
    }

    public set transactionId(value) {
        this.dto.transactionId = value;
    }

    public get frequency() {
        return this.dto.frequency;
    }

    public set frequency(frequency: AchFrequencyModelDto) {
        if (!frequency) return;
        this.dto.frequency = frequency;
    }

    public get frequencyDisplay() {
        return this._frequencyDisplay;
    }

    public set frequencyDisplay(frequencyDisplay: string) {
        this._frequencyDisplay = frequencyDisplay;
    }

    public get effectiveDate() {
        return this.dto.frequency.nextPaymentDate ?? '0000-00-00';
    }

    public get offsetAccount() {
        return this.dto.offsetAccount as unknown as OffsetAccount;
    }

    public get offsetAccountPaymentName() {
        if (!this.dto.offsetAccount) return '';
        return this.dto.offsetAccount.value;
    }

    public get offsetAccountDisplay() {
        return this._offsetAccountDisplay;
    }

    public set offsetAccountDisplay(offsetAccountDisplay: string) {
        this._offsetAccountDisplay = offsetAccountDisplay;
    }

    public get entryDescription() {
        return this.dto.entryDescription;
    }

    public get discretionaryData() {
        return this.dto.discretionaryData;
    }

    public get restrictedPayment() {
        return this.dto.restricted;
    }

    public get formattedRestricted() {
        return this.dto.restricted ? 'Yes' : 'No';
    }

    public get recipients() {
        if (!this.dto.recipients) return [];
        return this.dto.recipients.map(r => ({
            ...r,
            addenda: r.addenda && r.addenda[0] ? r.addenda[0].value : null,
        })) as AchRecipientModelDto[];
    }
}
