/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    ChallengeGroupCompanyModelDto,
    ChallengeGroupDetailModelDto,
    ChallengeGroupModelDto,
} from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class ChallengeGroupsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags ChallengeGroups
     * @name ChallengeGroupsGetAll
     * @summary Gets all the challenge Groups.
     * @request GET:/challenge-groups
     */
    challengeGroupsGetAll = (params: RequestParams = {}) =>
        this.http.request<ChallengeGroupModelDto[], any>({
            path: `/challenge-groups`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ChallengeGroups
     * @name ChallengeGroupsPost
     * @summary Saves the challenge Group Detail to the Group List.
     * @request POST:/challenge-groups
     */
    challengeGroupsPost = (
        challengeGroupDetail: ChallengeGroupDetailModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<number, any>({
            path: `/challenge-groups`,
            method: 'POST',
            body: challengeGroupDetail,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ChallengeGroups
     * @name ChallengeGroupsPut
     * @request PUT:/challenge-groups
     */
    challengeGroupsPut = (
        query: {
            /** @format int32 */
            id: number;
            name: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<ChallengeGroupModelDto, any>({
            path: `/challenge-groups`,
            method: 'PUT',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ChallengeGroups
     * @name ChallengeGroupsGet
     * @summary Gets the challenge Group Detail.
     * @request GET:/challenge-groups/{id}
     */
    challengeGroupsGet = (id: number, params: RequestParams = {}) =>
        this.http.request<ChallengeGroupModelDto, any>({
            path: `/challenge-groups/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ChallengeGroups
     * @name ChallengeGroupsPut2
     * @summary Updates the Challenge Group Detail Object.
     * @request PUT:/challenge-groups/{id}
     */
    challengeGroupsPut2 = (
        id: number,
        challengeGroupDetail: ChallengeGroupDetailModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<number, any>({
            path: `/challenge-groups/${id}`,
            method: 'PUT',
            body: challengeGroupDetail,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ChallengeGroups
     * @name ChallengeGroupsDelete
     * @summary Deletes the challenge group detail object.
     * @request DELETE:/challenge-groups/{id}
     */
    challengeGroupsDelete = (id: number, params: RequestParams = {}) =>
        this.http.request<boolean, any>({
            path: `/challenge-groups/${id}`,
            method: 'DELETE',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags ChallengeGroups
     * @name ChallengeGroupsGetGroupCOmpanies
     * @summary Gets the Challenge Group Companies.
     * @request GET:/challenge-groups/{id}/companies
     */
    challengeGroupsGetGroupCOmpanies = (id: number, params: RequestParams = {}) =>
        this.http.request<ChallengeGroupCompanyModelDto[], any>({
            path: `/challenge-groups/${id}/companies`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
