import { AchFilter } from '@treasury/domain/ach';
import {
    CurrencyRange,
    CurrencyRangeType,
} from '@treasury/presentation/components/tm-currency-range';
import { mapCurrencyRangeToDto } from '@treasury/presentation/components/tm-currency-range.mappings';
import { DateRange, DateRangeType } from '@treasury/presentation/components/tm-date-range';
import { mapDateRangeToDto } from '@treasury/presentation/components/tm-date-range.mappings';

const defaultAmountRange: CurrencyRange = {
    rangeType: CurrencyRangeType.AllAmounts,
};

const defaultLastUpdatedRange: DateRange = {
    rangeType: DateRangeType.None,
};

export class AchFilterVm extends AchFilter {
    constructor() {
        super();
        this.reset();
    }

    private _debitRange = defaultAmountRange;

    private _creditRange = defaultAmountRange;

    private _lastUpdatedRange = defaultLastUpdatedRange;

    public get debitRange(): CurrencyRange {
        return this._debitRange;
    }

    public set debitRange(r: CurrencyRange) {
        this._debitRange = r;
        const { operator, fromAmount, toAmount, specificAmount } = mapCurrencyRangeToDto(r, false);
        this.debitAmountType = operator!;
        this.debitAmountMin = fromAmount;
        this.debitAmountMax = toAmount;
        this.debitAmount = specificAmount;
    }

    public get creditRange(): CurrencyRange {
        return this._creditRange;
    }

    public set creditRange(r: CurrencyRange) {
        this._creditRange = r;
        const { operator, fromAmount, toAmount, specificAmount } = mapCurrencyRangeToDto(r, false);
        this.creditAmountType = operator!;
        this.creditAmountMin = fromAmount;
        this.creditAmountMax = toAmount;
        this.creditAmount = specificAmount;
    }

    public get lastUpdatedRange(): DateRange {
        return this._lastUpdatedRange;
    }

    public set lastUpdatedRange(r: DateRange) {
        this._lastUpdatedRange = r;
        const { operator, dateFrom, dateTo, specificDate } = mapDateRangeToDto(r, false);
        this.updatedDateType = operator!;
        this.lastUpdatedStart = dateFrom;
        this.lastUpdatedEnd = dateTo;
        this.updatedDate = specificDate;
    }

    public reset() {
        super.reset();
        this.debitRange = { ...defaultAmountRange };
        this.creditRange = { ...defaultAmountRange };
        this.lastUpdatedRange = { ...defaultLastUpdatedRange };
    }
}
