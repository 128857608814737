import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { TmBaseComponent } from '..';
import { LabeledList } from '../view-models';

export const tagName = 'tm-labeled-list';
@customElement(tagName)
export class TmLabeledList<T> extends TmBaseComponent {
    @property()
    protected readonly verifiedPropNames: (keyof this)[] = ['list'];

    @property({ reflect: false })
    public list!: LabeledList<T>;

    render() {
        if (!this.list) return nothing;
        return html`<div class="grid grid-cols-[auto_1fr] gap-x-3 text-sm">
            ${this.list.items.map(
                d => html`
                    <div class="text-left mr-3 py-1 text-[--secondary-text-color]">${d.label}</div>
                    <div
                        @click=${() => {
                            if (d.clickEventName) {
                                this.dispatchEvent(
                                    new CustomEvent(d.clickEventName, {
                                        bubbles: true,
                                        composed: true,
                                    })
                                );
                            }
                        }}
                        class="${d.value
                            ? d.clickEventName
                                ? 'text-[--primary-action-color]'
                                : 'text-[--primary-text-color]'
                            : 'text-[--null-text-color]'} text-left py-1"
                    >
                        ${d.value
                            ? Array.isArray(d.value)
                                ? d.value.map(e => html`${e}<br />`)
                                : d.value
                            : 'none'}
                    </div>
                `
            )}
        </div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmLabeledList<unknown>;
    }
}
