import { TmBaseComponent } from '@treasury/presentation';
import { arrowRightIcon } from '@treasury/presentation/assets/icons';
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export const tagName = 'jhd-list-selection-item';
@customElement(tagName)
export class JhdListSelectionItem extends TmBaseComponent {
    @property({ type: String })
    label = '';

    @property({ type: String })
    count = '';

    render() {
        return html`<div class="flex items-center justify-between w-full p-0" id="selection-item">
            <span>${this.label}</span><span>${this.count}</span>${arrowRightIcon}
        </div>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    width: 100%;
                }
                #selection-item {
                    height: 50px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: JhdListSelectionItem;
    }
}
