import { Injectable, ObservationSource } from '@treasury/utils';

@Injectable()
export class LayoutService {
    private isSidebarOpen = false;

    private readonly sidebarOpenSource = new ObservationSource<boolean>();

    public readonly isSidebarOpen$ = this.sidebarOpenSource.toObservable();

    public toggleSidebar() {
        this.isSidebarOpen = !this.isSidebarOpen;
        this.sidebarOpenSource.emit(this.isSidebarOpen);
    }
}
