import { UserTransferLimits } from '@treasury/domain/users/product-features';
import { formatUsdCurrency } from '@treasury/utils';

export type TransferAccount = {
    accountName: string;
    accountNumber: string;
    id: number;
    transactionLimit: {
        value: string;
        edited: boolean;
    };
    dailyLimit: {
        value: string;
        edited: boolean;
    };
    accountDailyLimit: number;
};

export class UserTransferLimitsViewModel {
    constructor(private readonly value: UserTransferLimits) {}

    get dailyLimit() {
        return formatUsdCurrency(this.value.dailyLimit);
    }

    get transferLimits() {
        return {
            initiationLimit: {
                value: formatUsdCurrency(
                    Number(this.value.transferLimits.initiationLimit.value) || 0
                ),
                edited: this.value.transferLimits.initiationLimit.edited,
            },
            approvalLimit: {
                value: formatUsdCurrency(
                    Number(this.value.transferLimits.approvalLimit.value) || 0
                ),
                edited: this.value.transferLimits.approvalLimit.edited,
            },
        };
    }

    get userTransferAccounts() {
        return this.value.userTransferAccounts?.map(account => ({
            ...account,
            transactionLimit: {
                value: formatUsdCurrency(Number(account.transactionLimit.value) || 0),
                edited: account.transactionLimit.edited,
            },
            dailyLimit: {
                value: formatUsdCurrency(Number(account.dailyLimit.value) || 0),
                edited: account.dailyLimit.edited,
            },
            accountDailyLimit: formatUsdCurrency(account.accountDailyLimit),
        }));
    }
}
