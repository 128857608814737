/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable no-console */
/* eslint-disable @treasury/no-date */
/* eslint-disable import/no-extraneous-dependencies */
import '@treasury/presentation/components/tm-checkbox';
import '@treasury/presentation/components/tm-date-picker';
import { summarizeFrequency } from '@treasury/presentation/components/tm-frequency.summarizer';
import {
    Frequency,
    FrequencyDay,
    FrequencyType,
    MonthlyFrequency,
    TwiceMonthlyFrequency,
    WeeklyFrequency,
} from '@treasury/presentation/components/tm-frequency.types';
import '@treasury/presentation/components/tm-select';
import '@treasury/presentation/components/tm-toggle';
import { TmBaseComponent } from '@treasury/presentation/tm-base.component';
import { deepEquals } from '@treasury/utils';
import { toOrdinalSuffix } from '@treasury/utils/functions/strings.helpers';
import { SelectItem } from '@vaadin/select/src/vaadin-select';
import { format, isValid } from 'date-fns';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { BillPayFrequencyType, BillPayFrequencyTypeLabels } from './bill-pay-frequency.types';

const tagName = 'bill-pay-frequency';
@customElement(tagName)
export class BillPayFrequency extends TmBaseComponent {
    @property({ type: Object })
    public frequency?: Frequency;

    @property()
    public dateLabel = 'Payment Date';

    @property({ type: Boolean, reflect: true })
    public readOnly = false;

    @property({ type: Boolean, reflect: true })
    public disabled = false;

    @property({ type: String })
    public minDate = format(new Date(), 'yyyy-MM-dd');

    @state()
    private isRecurring = false;

    @state()
    private type?: FrequencyType | BillPayFrequencyType = FrequencyType.OneTime;

    @state()
    private selectedFrequency?: FrequencyType | BillPayFrequencyType;

    @state()
    private startDate: string = '';

    @state()
    private endDate?: string;

    @state()
    private perpetual?: boolean;

    @state()
    private repeatOnWeekday?: FrequencyDay;

    @state()
    private repeatOnDay?: number;

    @state()
    private repeatOnDay2?: number | null;

    @state()
    private repeatOnLastBusinessDay?: boolean;

    @state()
    private frequencyParsed = false;

    invalidInputs: string[] = [];

    get frequenciesForSelect() {
        const frequencies = [];
        for (const frequency of Object.keys(BillPayFrequencyType)) {
            if (frequency !== 'OneTime') {
                frequencies.push({
                    label: BillPayFrequencyTypeLabels[frequency as BillPayFrequencyType],
                    value: frequency,
                });
            }
        }
        return frequencies;
    }

    get weekdayItems() {
        const days: SelectItem[] = [];
        const dayKeys = Object.keys(FrequencyDay).filter(label => Number.isNaN(Number(label)));
        const dayValues = Object.values(FrequencyDay).filter(value => !Number.isNaN(Number(value)));
        dayKeys.forEach((label: string, i: number) => {
            days.push({ label, value: dayValues[i].toString() });
        });
        return days;
    }

    get dayNumberItems() {
        const days = [];
        for (let index = 1; index <= 31; index++) {
            days.push({
                label: `${toOrdinalSuffix(index)}`,
                value: `${index}`,
                ...(this.repeatOnDay2 && this.repeatOnDay2 <= index && { disabled: true }),
            });
        }
        return days;
    }

    get day2NumberItems() {
        const days = [];
        for (let index = 1; index <= 31; index++) {
            days.push({
                label: `${toOrdinalSuffix(index)}`,
                value: `${index}`,
                ...(this.repeatOnDay && this.repeatOnDay >= index && { disabled: true }),
            });
        }
        return days;
    }

    get frequencyDefinition() {
        switch (this.type) {
            case BillPayFrequencyType.EveryThreeMonths:
                return 'Every Three Months is defined as processing on the same day every 3 months.';
            case FrequencyType.EverySixMonths:
                return 'Every Six Months is defined as processing on the same day every 6 months.';
            case FrequencyType.Quarterly:
                return 'Quarterly is defined as processing on the same day every 3 months';
            case FrequencyType.Yearly:
                return 'Yearly is defined as processing on the same day annually.';
            default:
                return '';
        }
    }

    get summary() {
        if (this.frequency) {
            return summarizeFrequency(this.frequency);
        }
        console.error('No frequency to summarize');
        return nothing;
    }

    protected firstUpdated(): void {
        if (this.frequency) {
            this.parseFrequency();
        }
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    private parseFrequency() {
        if (!this.frequency) return;

        if (this.frequency.type) {
            this.type = this.frequency.type;
        }

        if (this.frequency.startDate) {
            this.startDate = format(this.frequency.startDate, 'yyyy-MM-dd');
        }

        if (this.frequency.type !== FrequencyType.OneTime) {
            this.isRecurring = true;
            this.selectedFrequency = this.type;

            if (this.frequency.endDate) this.endDate = format(this.frequency.endDate, 'yyyy-MM-dd');

            if (this.frequency.perpetual) this.perpetual = this.frequency.perpetual;
            switch (this.type) {
                case FrequencyType.Weekly || BillPayFrequencyType.EveryFourWeeks:
                    if ('repeatOn' in this.frequency) {
                        this.repeatOnWeekday = this.frequency.repeatOn as FrequencyDay;
                    }
                    break;
                case FrequencyType.Monthly:
                case FrequencyType.TwiceAMonth:
                    if ('repeatOnLastBusinessDay' in this.frequency) {
                        this.repeatOnLastBusinessDay = this.frequency.repeatOnLastBusinessDay;
                    }
                    if ('repeatOn' in this.frequency) {
                        if (Array.isArray(this.frequency.repeatOn)) {
                            [this.repeatOnDay, this.repeatOnDay2] = this.frequency.repeatOn;
                        } else {
                            this.repeatOnDay = this.frequency.repeatOn as number;
                        }
                    }
                    break;
                default:
                    break;
            }
        }
        this.frequencyParsed = true;
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    private validateInputs() {
        this.invalidInputs = [];

        if (this.isRecurring && this.type === BillPayFrequencyType.OneTime) {
            this.invalidInputs.push('selectedFrequency');
        }

        if (!this.startDate || !isValid(new Date(this.startDate.replace(/-/g, '/'))))
            this.invalidInputs.push('startDate');

        if (this.type !== BillPayFrequencyType.OneTime) {
            if (
                // eslint-disable-next-line @treasury/no-mixed-boolean-operators
                !this.perpetual &&
                (!this.endDate || !isValid(new Date(this.endDate.replace(/-/g, '/'))))
            ) {
                this.invalidInputs.push('endDate');
            }

            if (this.startDate && this.endDate) {
                const startDate = new Date(this.startDate.replace(/-/g, '/'));
                const endDate = new Date(this.endDate.replace(/-/g, '/'));
                if (startDate.getTime() >= endDate.getTime()) {
                    this.invalidInputs.push('startDate', 'endDate');
                }
                const minEndDate = this.getMinEndDate();
                if (
                    minEndDate &&
                    endDate.getTime() < new Date(minEndDate.replace(/-/g, '/')).getTime()
                ) {
                    this.invalidInputs.push('endDate');
                }
            }
            switch (this.type) {
                case FrequencyType.Weekly:
                case FrequencyType.EveryTwoWeeks:
                    if (!this.repeatOnWeekday) {
                        this.invalidInputs.push('repeatOnWeekday');
                    }
                    break;
                case BillPayFrequencyType.EveryFourWeeks:
                    if (!this.repeatOnWeekday) {
                        this.invalidInputs.push('repeatOnWeekday');
                    }
                    break;
                case FrequencyType.Monthly:
                    if (!this.repeatOnLastBusinessDay && !this.repeatOnDay)
                        this.invalidInputs.push('repeatOnDay');
                    break;
                case BillPayFrequencyType.EveryOtherMonth:
                    if (!this.repeatOnLastBusinessDay && !this.repeatOnDay)
                        this.invalidInputs.push('repeatOnDay');
                    break;
                case FrequencyType.TwiceAMonth:
                    if (!this.repeatOnDay) this.invalidInputs.push('repeatOnDay');
                    if (!this.repeatOnDay2 && !this.repeatOnLastBusinessDay)
                        this.invalidInputs.push('repeatOnDay2');
                    if (
                        !this.repeatOnLastBusinessDay &&
                        this.repeatOnDay &&
                        this.repeatOnDay2 &&
                        this.repeatOnDay >= this.repeatOnDay2
                    )
                        this.invalidInputs.push('repeatOnDay', 'repeatOnDay2');
                    break;
                default:
                    break;
            }
        }
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    protected updated(changedProperties: Map<PropertyKey, unknown>): void {
        if (!this.readOnly) {
            if (!changedProperties.has('frequency')) {
                this.validateInputs();
                if (this.invalidInputs.length === 0) {
                    this.dispatchEvent(
                        new CustomEvent('selection', {
                            detail: this.generateFrequency(),
                            bubbles: true,
                        })
                    );
                } else {
                    this.dispatchEvent(
                        new CustomEvent('selection', { detail: false, bubbles: true })
                    );
                }
            }
            if (
                changedProperties.has('frequency') &&
                !!this.frequency &&
                deepEquals(changedProperties.get('frequency'), this.frequency)
            ) {
                this.parseFrequency();
            }
        }
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    private generateFrequency(): Frequency {
        let frequency = {
            type: this.type as FrequencyType,
            startDate: new Date(this.startDate?.replace(/-/g, '/') as string),
        };

        if (this.type !== FrequencyType.OneTime) {
            frequency = {
                ...frequency,
                ...(this.endDate && {
                    endDate: new Date(this.endDate.replace(/-/g, '/') as string),
                }),
                ...(this.perpetual && { perpetual: this.perpetual }),
            };
        }

        switch (this.type) {
            case FrequencyType.Weekly:
            case FrequencyType.EveryTwoWeeks:
                return {
                    ...frequency,
                    repeatOn: this.repeatOnWeekday,
                } as WeeklyFrequency;
            // eslint-disable-next-line sonarjs/no-duplicated-branches
            case BillPayFrequencyType.EveryFourWeeks:
                return {
                    ...frequency,
                    repeatOn: this.repeatOnWeekday,
                } as WeeklyFrequency;
            case FrequencyType.Monthly:
            case BillPayFrequencyType.EveryOtherMonth:
            case FrequencyType.TwiceAMonth:
                return {
                    ...frequency,
                    repeatOn:
                        this.type === FrequencyType.Monthly ||
                        this.type === BillPayFrequencyType.EveryOtherMonth
                            ? this.repeatOnDay
                            : [this.repeatOnDay, this.repeatOnDay2],
                    ...(this.repeatOnLastBusinessDay && {
                        repeatOnLastBusinessDay: this.repeatOnLastBusinessDay,
                    }),
                } as MonthlyFrequency | TwiceMonthlyFrequency;
            default:
                return frequency;
        }
    }

    private getMinEndDate() {
        if (!this.startDate || !isValid(new Date(this.startDate.replace(/-/g, '/'))))
            return undefined;

        const startDate = new Date(this.startDate.replace(/-/g, '/'));
        const minEndDate = new Date(startDate);
        switch (this.type) {
            case FrequencyType.Weekly:
                minEndDate.setDate(startDate.getDate() + 7);
                break;
            case FrequencyType.EveryTwoWeeks:
                minEndDate.setDate(startDate.getDate() + 14);
                break;
            case BillPayFrequencyType.EveryFourWeeks:
                minEndDate.setDate(startDate.getDate() + 28);
                break;
            case FrequencyType.Monthly:
            case BillPayFrequencyType.EveryOtherMonth:
                minEndDate.setMonth(startDate.getMonth() + 1);
                break;
            case FrequencyType.TwiceAMonth:
                minEndDate.setMonth(startDate.getMonth() + 1);
                break;
            case FrequencyType.Quarterly:
                minEndDate.setMonth(startDate.getMonth() + 3);
                break;
            case BillPayFrequencyType.EveryThreeMonths:
                minEndDate.setMonth(startDate.getMonth() + 3);
                break;
            case FrequencyType.EverySixMonths:
                minEndDate.setMonth(startDate.getMonth() + 6);
                break;
            case FrequencyType.Yearly:
                minEndDate.setFullYear(startDate.getFullYear() + 1);
                break;
            default:
                break;
        }
        return format(minEndDate, 'yyyy-MM-dd');
    }

    renderDatePicker() {
        if (this.frequency && !this.frequencyParsed) return nothing;
        return html`
            <tm-date-picker
                .label=${this.dateLabel}
                required
                .min=${this.minDate}
                .value=${this.startDate}
                @value-changed=${(e: CustomEvent) => {
                    this.startDate = e.detail.value;
                }}
                .disabled=${this.disabled}
                class="w-full"
            ></tm-date-picker>
        `;
    }

    renderFrequencySelect() {
        return html`
            <tm-select
                label="Frequency"
                required
                .items=${this.frequenciesForSelect}
                .value=${this.selectedFrequency ?? ''}
                @value-changed=${(e: CustomEvent) => {
                    this.selectedFrequency = e.detail.value as FrequencyType;
                    this.type = this.selectedFrequency;
                }}
                .disabled=${this.disabled}
                class="w-full"
            ></tm-select>
            ${this.renderDefinition()}
        `;
    }

    renderDateOrFrequency() {
        if (this.isRecurring) {
            return [this.renderFrequencySelect(), this.renderFrequencyForm()];
        }
        return this.renderDatePicker();
    }

    renderFrequencyForm() {
        switch (this.type) {
            case FrequencyType.Weekly:
            case FrequencyType.EveryTwoWeeks:
                return this.renderWeekly();
            case BillPayFrequencyType.EveryFourWeeks:
                return this.renderWeekly();
            case FrequencyType.Monthly:
                return this.renderMonthly();
            case BillPayFrequencyType.EveryOtherMonth:
                return this.renderMonthly();
            case FrequencyType.TwiceAMonth:
                return this.renderTwiceAMonth();
            case BillPayFrequencyType.EveryThreeMonths:
            case FrequencyType.EverySixMonths:
            case FrequencyType.Quarterly:
            case FrequencyType.Yearly:
                return this.renderStartEnd();
            default:
                return nothing;
        }
    }

    renderStartOnDate() {
        return html`
            <tm-date-picker
                label="Start on"
                required
                .min=${this.minDate}
                .value=${this.startDate}
                @value-changed=${(e: CustomEvent) => {
                    this.startDate = e.detail.value;
                }}
                .disabled=${this.disabled}
                class="w-full"
            ></tm-date-picker>
        `;
    }

    renderEndOnDate() {
        this.getMinEndDate();
        return html` <tm-date-picker
                label="End on"
                .required=${!this.perpetual}
                .disabled=${this.perpetual || this.disabled}
                .min=${this.getMinEndDate()}
                .value=${this.endDate}
                @value-changed=${(e: CustomEvent) => {
                    this.endDate = e.detail.value;
                }}
                class="w-full"
            ></tm-date-picker>
            <tm-checkbox
                label="No end"
                .checked=${this.perpetual}
                @checked-changed=${(e: CustomEvent) => {
                    this.perpetual = e.detail.value;
                    if (this.perpetual) {
                        this.endDate = undefined;
                    }
                }}
                .disabled=${this.disabled}
                class="w-full"
            ></tm-checkbox>`;
    }

    renderRepeatOnWeekday() {
        return html` <tm-select
            label="Repeat on"
            required
            .items=${this.weekdayItems}
            .value=${this.repeatOnWeekday ? this.repeatOnWeekday.toString() : ''}
            @value-changed=${(e: CustomEvent) => {
                this.repeatOnWeekday = Number(e.detail.value) as FrequencyDay;
            }}
            .disabled=${this.disabled}
            class="w-full"
        ></tm-select>`;
    }

    renderRepeatOnDay() {
        const isMonthlyDisabled =
            this.type === FrequencyType.Monthly && this.repeatOnLastBusinessDay;
        const isEveryOtherMonthDisabled =
            this.type === BillPayFrequencyType.EveryOtherMonth && this.repeatOnLastBusinessDay;
        const isDisabled = isMonthlyDisabled || isEveryOtherMonthDisabled;

        return html` <tm-select
                label="Repeat on"
                .required=${!(
                    (this.type === FrequencyType.Monthly && this.repeatOnLastBusinessDay) ||
                    (this.type === BillPayFrequencyType.EveryOtherMonth &&
                        !this.repeatOnLastBusinessDay)
                )}
                .disabled=${isDisabled || this.disabled}
                .items=${this.dayNumberItems}
                .value=${this.repeatOnDay?.toString()}
                @value-changed=${(e: CustomEvent) => {
                    this.repeatOnDay = Number(e.detail.value);
                }}
                class="w-full"
            ></tm-select>
            ${this.type === FrequencyType.Monthly ||
            this.type === BillPayFrequencyType.EveryOtherMonth
                ? this.renderRepeatOnLastBusinessDay()
                : nothing}`;
    }

    renderRepeatOnDay2() {
        return html` <tm-select
                label="and"
                .disabled=${this.repeatOnLastBusinessDay || this.disabled}
                .items=${this.day2NumberItems}
                .value=${this.repeatOnDay2?.toString()}
                @value-changed=${(e: CustomEvent) => {
                    this.repeatOnDay2 = Number(e.detail.value);
                }}
                class="w-full"
            ></tm-select>
            ${this.renderRepeatOnLastBusinessDay()}`;
    }

    renderRepeatOnLastBusinessDay() {
        return html` <tm-checkbox
            label="Last business day"
            .checked=${this.repeatOnLastBusinessDay}
            @checked-changed=${(e: CustomEvent) => {
                this.repeatOnLastBusinessDay = e.detail.value;
                if (this.repeatOnLastBusinessDay) {
                    switch (this.type) {
                        case FrequencyType.TwiceAMonth:
                            this.repeatOnDay2 = null;
                            break;
                        case FrequencyType.Monthly:
                            this.repeatOnDay = undefined;
                            break;
                        case BillPayFrequencyType.EveryOtherMonth:
                            this.repeatOnDay = undefined;
                            break;
                        default:
                            break;
                    }
                }
            }}
            .disabled=${this.disabled}
            class="w-full"
        ></tm-checkbox>`;
    }

    renderWeekly() {
        return html`
            ${this.renderRepeatOnWeekday()} ${this.renderStartOnDate()} ${this.renderEndOnDate()}
        `;
    }

    renderTwiceAMonth() {
        return html`
            ${this.renderRepeatOnDay()} ${this.renderRepeatOnDay2()} ${this.renderStartOnDate()}
            ${this.renderEndOnDate()}
        `;
    }

    renderMonthly() {
        return html`
            ${this.renderRepeatOnDay()} ${this.renderStartOnDate()} ${this.renderEndOnDate()}
        `;
    }

    renderDefinition() {
        if (!this.frequencyDefinition) return nothing;
        return html` <div class="text-sm">${this.frequencyDefinition}</div> `;
    }

    renderStartEnd() {
        return html` ${this.renderStartOnDate()} ${this.renderEndOnDate()} `;
    }

    /* We can set this up to a standard layout format once we migrate all of the jhd-data-field to a tm presentation version */
    renderSummary() {
        return html`<label id="readonly-label" for="frequencySummary">Frequency</label>${this
                .summary}`;
    }

    render() {
        if (this.readOnly) {
            return this.renderSummary();
        }
        const type = this.isRecurring ? this.selectedFrequency : FrequencyType.OneTime;
        return html`
            <tm-toggle
                label="Recurring"
                .checked=${this.isRecurring}
                @change=${() => {
                    this.isRecurring = !this.isRecurring;
                    this.type = type;
                }}
                .disabled=${this.disabled}
                class="w-full"
            ></tm-toggle>
            ${this.renderDateOrFrequency()}
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                }

                :host([readonly]) {
                    padding-top: var(--lumo-space-m);
                }

                #readonly-label {
                    color: var(--lumo-secondary-text-color);
                    font-weight: 400;
                    font-size: var(--lumo-font-size-s);
                    line-height: 1;
                    padding-right: 1em;
                    padding-bottom: 0.5em;
                    padding-top: 0.25em;
                    margin-top: -0.25em;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 100%;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: BillPayFrequency;
    }
}
