/* eslint-disable max-classes-per-file */
import { CompanyAccountModelDto, WireCompanyModelDto } from '@treasury/api/channel';

export class WireCompanyViewModel {
    // eslint-disable-next-line no-useless-constructor
    constructor(private source: WireCompanyModelDto) {}

    public get name() {
        return this.source.name;
    }

    public get value() {
        return String(this.source.id);
    }
}

export class DebitAccountViewModel {
    // eslint-disable-next-line no-useless-constructor
    constructor(private source: CompanyAccountModelDto) {}

    public get name() {
        return this.source.name;
    }

    public get value() {
        return String(this.source.id);
    }
}
