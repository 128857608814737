import { AchPaymentModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';

export class PendingAchPayment extends DomainEntity<AchPaymentModelDto> {
    constructor(dto: AchPaymentModelDto) {
        super(dto);
    }

    public createDefault(): this {
        throw new Error('Method not implemented.');
    }

    get payment() {
        return this.dto;
    }

    get paymentId() {
        return this.dto.id;
    }

    get name() {
        return this.dto.name;
    }

    get transactionId() {
        return this.dto.transactionId;
    }

    get creditAmount() {
        return this.dto.creditAmount;
    }

    get debitAmount() {
        return this.dto.debitAmount;
    }
}
