import { TmBaseComponent } from '@treasury/presentation';
import {
    attachIcon,
    closeIcon,
    documentIcon,
    uploadIcon,
} from '@treasury/presentation/assets/icons';
import '@vaadin/form-layout';
import type { FormLayoutResponsiveStep } from '@vaadin/form-layout';
import '@vaadin/select';
import '@vaadin/text-field';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../../components/jhd-icon';
import { CheckException } from '@treasury/domain/arp';

export const tagName = 'check-exception-row-detail';
@customElement(tagName)
export class CheckExceptionsRowDetail extends TmBaseComponent {
    @property()
    checkException!: CheckException;

    @property()
    closeAction!: () => void;

    private responsiveSteps: FormLayoutResponsiveStep[] = [
        { minWidth: 0, columns: 1 },
        { minWidth: '320px', columns: 2 },
        { minWidth: '500px', columns: 3 },
    ];

    renderHeaderBar() {
        return html`
            <div class="row-detail-header">
                <h1 class="row-detail-header-text">Check Exception Detail</h1>
                <div>
                    <button class="icon-button">
                        <jhd-icon color="grey" .icon=${documentIcon}></jhd-icon>
                    </button>
                    <button class="icon-button">
                        <jhd-icon color="grey" .icon=${uploadIcon}></jhd-icon>
                    </button>
                    <button class="icon-button" @click=${this.closeAction}>
                        <jhd-icon color="grey" .icon=${closeIcon}></jhd-icon>
                    </button>
                </div>
            </div>
        `;
    }

    renderExceptionDecisionSelector() {
        return html``;
    }

    renderCheckImage() {
        return html`<div>check image here</div>`;
    }

    render() {
        if (!this.checkException) return nothing;
        return html`<div>
            ${this.renderHeaderBar()}
            <div class="row-detail-form">
                <div class="col-1">
                    <vaadin-form-item>
                        <label>Return Reason</label>
                        <vaadin-select></vaadin-select>
                    </vaadin-form-item>
                    <vaadin-form-item>
                        <label>Comment</label>
                        <vaadin-text-field></vaadin-text-field>
                    </vaadin-form-item>
                    <vaadin-form-item>
                        <label>Attachment</label>
                        <button class="attachment-icon-button">
                            <jhd-icon .icon=${attachIcon} color="grey"></jhd-icon>
                        </button>
                    </vaadin-form-item>
                    ${this.renderCheckImage()}
                </div>
                <div class="col-2">
                    <vaadin-form-item>
                        <label slot="label">Account</label>
                        <vaadin-text-field
                            .value=${this.checkException.accountNumber}
                            readonly
                        ></vaadin-text-field>
                    </vaadin-form-item>
                    <vaadin-form-item>
                        <label slot="label"> Check Number </label>
                        <vaadin-text-field
                            .value=${this.checkException.checkNumber}
                            readonly
                        ></vaadin-text-field>
                    </vaadin-form-item>
                    <vaadin-form-item>
                        <label slot="label"> Paid Amount </label>
                        <vaadin-text-field
                            .value=${this.checkException.paidAmount}
                            readonly
                        ></vaadin-text-field>
                    </vaadin-form-item>
                    <vaadin-form-item>
                        <label slot="label"> Issued Amount </label>
                        <vaadin-text-field
                            .value=${this.checkException.issuedAmount}
                            readonly
                        ></vaadin-text-field>
                    </vaadin-form-item>
                    <vaadin-form-item>
                        <label slot="label"> Posted Date </label>
                        <vaadin-text-field
                            .value=${this.checkException.postedDate}
                            readonly
                        ></vaadin-text-field>
                    </vaadin-form-item>
                    <vaadin-form-item>
                        <label slot="label"> Issued Date </label>
                        <vaadin-text-field
                            .value=${this.checkException.issuedDate}
                            readonly
                        ></vaadin-text-field>
                    </vaadin-form-item>
                    <vaadin-form-item>
                        <label slot="label"> Issued Payee </label>
                        <vaadin-text-field
                            .value=${this.checkException.issuedPayee}
                            readonly
                        ></vaadin-text-field>
                    </vaadin-form-item>
                </div>
                <div class="col-3">
                    <vaadin-form-item>
                        <label slot="label">Exception Reason</label>
                        <vaadin-text-field
                            .value=${this.checkException.exceptionReason}
                            readonly
                        ></vaadin-text-field>
                    </vaadin-form-item>
                    <vaadin-form-item>
                        <label slot="label"> Protected (Y/N) </label>
                        <vaadin-text-field
                            .value=${this.checkException.protected}
                            readonly
                        ></vaadin-text-field>
                    </vaadin-form-item>
                    <vaadin-form-item>
                        <label slot="label"> Updated By </label>
                        <vaadin-text-field
                            .value=${this.checkException.updatedBy}
                            readonly
                        ></vaadin-text-field>
                    </vaadin-form-item>
                    <vaadin-form-item>
                        <label slot="label"> From Workstation </label>
                        <vaadin-text-field
                            .value=${this.checkException.fromWorkStation}
                            readonly
                        ></vaadin-text-field>
                    </vaadin-form-item>
                    <vaadin-form-item>
                        <label slot="label"> Source of Entry </label>
                        <vaadin-text-field
                            .value=${this.checkException.sourceOfEntry}
                            readonly
                        ></vaadin-text-field>
                    </vaadin-form-item>
                    <vaadin-form-item>
                        <label slot="label"> DDA Batch Number </label>
                        <vaadin-text-field
                            .value=${this.checkException.ddaBatchNumber}
                            readonly
                        ></vaadin-text-field>
                    </vaadin-form-item>
                    <vaadin-form-item>
                        <label slot="label"> DDA Sequence Number </label>
                        <vaadin-text-field
                            .value=${this.checkException.ddaSequenceNumber}
                            readonly
                        ></vaadin-text-field>
                    </vaadin-form-item>
                </div>
            </div>
        </div> `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                }
                .row-detail-header {
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid var(--gray-light, #e6e6e6);
                }
                .row-detail-header-text {
                    font-size: 14px;
                }
                .row-detail-form {
                    display: flex;
                    justify-content: space-around;
                }
                .col-1,
                .col-2,
                .col-3 {
                    display: flex;
                    flex-direction: column;
                }
                .icon-button,
                .attachment-icon-button {
                    height: 30px;
                    width: 30px;
                }
                .label {
                    font-size: 14px;
                    font-weight: 400;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: CheckExceptionsRowDetail;
    }
}
