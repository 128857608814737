/* eslint-disable @treasury/no-date */
import { clone } from '@treasury/utils/functions';
import { nullTransferTemplate } from './transfer-template.dto';

/**
 * Represents the client-side contract for Transfer templates.
 */
export class TransferTemplate {
    // eslint-disable-next-line no-useless-constructor
    constructor(protected dto = clone(nullTransferTemplate)) {}

    public get amount() {
        return this.dto.amount || 0;
    }

    public set amount(amount) {
        this.dto.amount = amount;
    }

    public get templateName() {
        return this.dto.templateName || '';
    }

    public set templateName(templateName) {
        this.dto.templateName = templateName;
    }

    public get transferFromAccount() {
        return this.dto.transferFromAccount || '';
    }

    public set transferFromAccount(transferFromAccount) {
        this.dto.transferFromAccount = transferFromAccount;
    }

    public get transferToAccount() {
        return this.dto.transferToAccount || '';
    }

    public set transferToAccount(transferToAccount) {
        this.dto.transferToAccount = transferToAccount;
    }

    public get fromAccount() {
        return this.dto.fromAccount;
    }

    public set fromAccount(fromAccount) {
        this.dto.fromAccount = fromAccount;
    }

    public get toAccount() {
        return this.dto.toAccount;
    }

    public set toAccount(toAccount) {
        this.dto.toAccount = toAccount;
    }

    public get memo() {
        return this.dto.memo || '';
    }

    public set memo(memo) {
        this.dto.memo = memo;
    }

    public get transferType() {
        return this.dto.transferType || '';
    }

    public set transferType(transferType) {
        this.dto.transferType = transferType;
    }

    public get totalTransferAmount() {
        return this.dto.totalTransferAmount || 0;
    }

    public set totalTransferAmount(totalTransferAmount) {
        this.dto.totalTransferAmount = totalTransferAmount;
    }

    public get id() {
        return this.dto.id || 0;
    }

    public set id(id) {
        this.dto.id = id;
    }

    public toDto() {
        return this.dto;
    }

    public clone() {
        return new TransferTemplate(clone(this.dto));
    }
}
