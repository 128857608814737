import { AccountClient } from '@treasury/api/bo';
import { Injectable, noOp } from '@treasury/utils';
import { UserSettings } from '../../../accounts';
import { AuthenticationService } from '../../../services/authentication';

@Injectable()
export class BoAccountService {
    constructor(
        private readonly client: AccountClient,
        private readonly authService: AuthenticationService
    ) {}

    public async getUserSettings() {
        const { data } = await this.client.accountSettings();

        // normalize data to fulfill client-side shape
        const settings: UserSettings = {
            ...data,
            areNotificationsEnabled: false,
        };

        return settings;
    }

    public logout(reason: string) {
        this.authService.invalidate();
        return this.client.accountLogout({ reason }).then(noOp);
    }
}
