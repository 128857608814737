import { InternalTransferTransactionModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';

// NOTE: Per the API, a loan is technically a transfer and uses the same shape
export class PendingLoan extends DomainEntity<InternalTransferTransactionModelDto> {
    constructor(loan: InternalTransferTransactionModelDto) {
        super(loan);
    }

    public createDefault(): this {
        throw new Error('Method not implemented.');
    }

    get payment() {
        return this.dto;
    }

    get paymentId() {
        return this.dto.id;
    }

    get toAccount() {
        return this.dto.toAccount.accountDisplayLabel;
    }

    get fromAccount() {
        return this.dto.fromAccount.accountDisplayLabel;
    }

    get amount() {
        return this.dto.amount;
    }
}
