/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { TransactionCodeGroupControllerDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class TransactionCodeGroupClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags TransactionCodeGroup
     * @name TransactionCodeGroupGetAll
     * @summary Gets all.
     * @request GET:/transactionCodeGroup
     */
    transactionCodeGroupGetAll = (params: RequestParams = {}) =>
        this.http.request<TransactionCodeGroupControllerDto[], any>({
            path: `/transactionCodeGroup`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionCodeGroup
     * @name TransactionCodeGroupGetAll2
     * @summary Gets all.
     * @request GET:/transactionCodeGroup/getAll
     */
    transactionCodeGroupGetAll2 = (params: RequestParams = {}) =>
        this.http.request<TransactionCodeGroupControllerDto[], any>({
            path: `/transactionCodeGroup/getAll`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
