import { TransferAccount } from '@treasury/domain/transfers';

export class TransferAccountVm extends TransferAccount {
    public get formattedAvailableBalance() {
        return this.availableBalance.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }
}
