import { TmBaseComponent } from '@treasury/presentation';
import { checkmarkIcon, closeIcon } from '@treasury/presentation/assets/icons';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import './jhd-icon';

type ApprovalState = 'Approved' | 'Rejected' | 'na';

export const tagName = 'jhd-approval-toggle';
@customElement(tagName)
export class JhdApprovalToggle extends TmBaseComponent {
    @property({ type: String })
    public selectedState: ApprovalState = 'na';

    @property({ type: Boolean, reflect: true })
    public readonly = false;

    @state()
    private approveColor = 'var(--approval-default-color)';

    @state()
    private rejectColor = 'var(--approval-default-color)';

    determineIconColor() {
        if (this.selectedState === 'Approved') {
            this.approveColor = 'var(--approval-icon-color)';
            this.rejectColor = 'var(--approval-deselected-color)';
        }
        if (this.selectedState === 'Rejected') {
            this.approveColor = 'var(--approval-deselected-color)';
            this.rejectColor = 'var(--approval-icon-color)';
        }
        if (this.selectedState === 'na') {
            this.approveColor = 'var(--approval-default-color)';
            this.rejectColor = 'var(--approval-default-color)';
        }
    }

    updated(changedProps: Map<string, unknown>) {
        super.updated(changedProps);
        if (changedProps.has('selectedState')) {
            this.determineIconColor();
        }
    }

    renderButton(state: 'Approved' | 'Rejected') {
        return html`<button
            class="flex justify-center items-center w-10 h-10"
            @click=${() => {
                if (this.selectedState === state) {
                    this.selectedState = 'na';
                } else {
                    this.selectedState = state;
                }
                this.determineIconColor();
                this.dispatchEvent(new CustomEvent('change', { detail: this.selectedState }));
            }}
        >
            ${this.renderIcon(state)}
        </button>`;
    }

    renderIcon(state: 'Approved' | 'Rejected') {
        const isStateApproved = state === 'Approved';
        const approved = this.selectedState === 'Approved';
        const rejected = this.selectedState === 'Rejected';
        const iconWrapperClass = `icon-wrapper-${state.toLowerCase()}`;
        const icon = html` <div
            class="${classMap({
                'icon-wrapper': true,
                'w-6 h-6 border rounded-full': true,
                [iconWrapperClass]: isStateApproved ? approved : rejected,
                'icon-wrapper-deselected': isStateApproved ? rejected : approved,
            })} flex justify-center items-center"
        >
            <jhd-icon
                .icon=${isStateApproved ? checkmarkIcon : closeIcon}
                .color=${isStateApproved ? this.approveColor : this.rejectColor}
                .width=${'18px'}
                .height=${'18px'}
            ></jhd-icon>
        </div>`;
        return this.readonly
            ? html`<div class="flex justify-center items-center w-10 h-10">${icon}</div>`
            : icon;
    }

    renderActions() {
        if (this.readonly) {
            if (this.selectedState === 'na') return nothing;
            return html`${this.renderIcon(this.selectedState)}`;
        }
        return html`${this.renderButton('Approved')} ${this.renderButton('Rejected')}`;
    }

    render() {
        const status = this.selectedState ? this.selectedState.toLowerCase() : 'na';
        return html`<div class="justify-around border-l-4 py-2 pr-3 flex-0 status-${status}">
            ${this.renderActions()}
        </div>`;
    }

    static get styles() {
        return [
            css`
                div {
                    display: flex;
                }
                button {
                    border: none;
                }
                .icon-wrapper {
                    padding: 2px;
                    border-color: var(--approval-default-color);
                }
                .icon-wrapper-approved {
                    background-color: var(--approved-color);
                    border-color: var(--approved-color);
                }
                .icon-wrapper-rejected {
                    background-color: var(--rejected-color);
                    border-color: var(--rejected-color);
                }
                .icon-wrapper-deselected {
                    border-color: var(--approval-deselected-color);
                }
                jhd-icon {
                    position: relative;
                }
                .status-na {
                    border-color: transparent;
                }
                .status-approved {
                    border-color: var(--approved-color);
                }
                .status-rejected {
                    border-color: var(--rejected-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: JhdApprovalToggle;
    }
}
