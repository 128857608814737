/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { AccountTypeModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class TaxPaymentClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags TaxPayment
     * @name TaxPaymentGet
     * @summary Gets federal tax codes.
     * @request GET:/taxPayment
     */
    taxPaymentGet = (params: RequestParams = {}) =>
        this.http.request<File | null, any>({
            path: `/taxPayment`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags TaxPayment
     * @name TaxPaymentGetStateTaxAmountTypes
     * @summary Gets the state tax amount types.
     * @request GET:/taxPayment/stateTaxAmountTypes
     */
    taxPaymentGetStateTaxAmountTypes = (params: RequestParams = {}) =>
        this.http.request<File | null, any>({
            path: `/taxPayment/stateTaxAmountTypes`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags TaxPayment
     * @name TaxPaymentGetFederalTaxAmountTypes
     * @summary Gets the federal tax amount types.
     * @request GET:/taxPayment/federalTaxAmountTypes
     */
    taxPaymentGetFederalTaxAmountTypes = (
        query: {
            /**
             * The identifier.
             * @format int32
             */
            id: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<File | null, any>({
            path: `/taxPayment/federalTaxAmountTypes`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags TaxPayment
     * @name TaxPaymentGeFederalTaxRecipient
     * @summary Gets the federal tax recipient.
     * @request GET:/taxPayment/federalTaxRecipient
     */
    taxPaymentGeFederalTaxRecipient = (params: RequestParams = {}) =>
        this.http.request<File | null, any>({
            path: `/taxPayment/federalTaxRecipient`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags TaxPayment
     * @name TaxPaymentGetStateTaxCodes
     * @summary Gets the state tax codes.
     * @request GET:/taxPayment/{id}/stateTaxCodes
     */
    taxPaymentGetStateTaxCodes = (id: number, params: RequestParams = {}) =>
        this.http.request<File | null, any>({
            path: `/taxPayment/${id}/stateTaxCodes`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags TaxPayment
     * @name TaxPaymentGetTaxRecipientAccountTypes
     * @summary Gets the tax recipient account types.
     * @request GET:/taxPayment/recipientAccountTypes
     */
    taxPaymentGetTaxRecipientAccountTypes = (params: RequestParams = {}) =>
        this.http.request<AccountTypeModelDto[], any>({
            path: `/taxPayment/recipientAccountTypes`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
