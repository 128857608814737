import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import '../css/icons/font-awesome.js';

function closeOnEscape({ key }) {
    if (key === 'Escape' && !this.disableClose) {
        this.close();
    }
}

export class OmegaDialog extends LitElement {
    static get properties() {
        return {
            disableClose: {
                type: Boolean,
            },
            hideCloseButton: {
                type: Boolean,
            },
            disableDownload: {
                type: Boolean,
            },
            disablePrint: {
                type: Boolean,
            },
            downloadOptions: {
                type: Array,
            },
            dialogTitle: {
                type: String,
            },
            /**
             * The open property is set to reflect so it acts as an attribute
             * so the easiest way to open and close the dialog externally is to get
             * the dialog from the dom and call dialog.open = false
             */
            open: {
                type: Boolean,
                reflect: true,
            },
            content: {
                type: String,
            },
            /**
             * Buttons to display at the bottom of the modal.
             * @deprecated Use the actions slot over this array.
             * @example
             * [{
             *    type: 'primary', //button type
             *    label: 'My Button',
             *    result: myResult // bubbles back up via close event
             * }]
             */
            buttons: {
                type: Array,
            },
            headerActions: Array,
            headerIcon: {
                type: String,
            },
        };
    }

    constructor() {
        super();
        this.headerActions = [];
        this.open = false;
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-dialog--declarative-api',
        };
    }

    connectedCallback() {
        super.connectedCallback();
        this.closeOnEscapeListener = closeOnEscape.bind(this);
        document.addEventListener('keyup', this.closeOnEscapeListener);
    }

    disconnectedCallback() {
        document.removeEventListener('keyup', this.closeOnEscapeListener);
        if (typeof window.focusManager !== 'undefined') {
            /* Untrap focus */
            window.focusManager.clearProxies();
        }
        super.disconnectedCallback();
    }

    hasHeaderAction(action) {
        return this.headerActions.includes(action);
    }

    showModal() {
        this.open = true;
    }

    close(result) {
        this.dispatchEvent(new CustomEvent('close', { detail: result }));
        this.open = false;
    }

    updated(changedProperties) {
        if (changedProperties?.has('open') && typeof window.focusManager !== 'undefined') {
            if (this.open) {
                this.dispatchEvent(new CustomEvent('open'));
                this._trapFocus();
            } else {
                /* Untrap focus */
                window.focusManager.clearProxies();
            }
        }
    }

    _trapFocus() {
        const { focusManager } = window;
        const maxSlotContentWaitTime = 100;
        const intervalTime = 5;

        let waitedTime = 0;
        let assignedFocus = false;
        const header = this.shadowRoot.querySelector('.omega-dialog-header');
        const slotContentLoading = setInterval(() => {
            const firstHeaderFocusable = focusManager.list(header)[0];
            const firstContentFocusable = focusManager.first(this);
            const lastFocusable = focusManager.list(this).pop() || focusManager.list(header).pop();
            const prevOutsideFocusable = focusManager.previous(this);
            const nextOutsideFocusable = focusManager.next(lastFocusable);

            if (firstContentFocusable != null) {
                focusManager.focus(firstContentFocusable);
                assignedFocus = true;
            } else if (waitedTime >= maxSlotContentWaitTime) {
                focusManager.focus(firstHeaderFocusable);
                assignedFocus = true;
            }

            if (assignedFocus) {
                focusManager.proxy(nextOutsideFocusable, firstHeaderFocusable);
                focusManager.proxy(prevOutsideFocusable, firstHeaderFocusable);
                clearInterval(slotContentLoading);
            }

            waitedTime += intervalTime;
        }, intervalTime);
    }

    toggle() {
        this.open = !this.open;
        if (!this.open) this.dispatchEvent(new CustomEvent('close'));
    }

    toggleDropdownDownloadOptions() {
        this.isDownloadDropdownOpen = !this.isDownloadDropdownOpen;
        this.requestUpdate();
    }

    _download(e) {
        this.dispatchEvent(
            new CustomEvent('download', {
                composed: true,
                bubbles: true,
                detail: { downloadType: e.target.innerText },
            })
        );
        this.toggleDropdownDownloadOptions();
    }

    _print() {
        this.dispatchEvent(
            new CustomEvent('print', {
                composed: true,
                bubbles: true,
            })
        );
    }

    renderDownloadOption(option) {
        // FIXME: fix the accessibility problem and re-enable this ESLint rule
        // eslint-disable-next-line lit-a11y/click-events-have-key-events
        return html`<li
            role="menuitem"
            class="omega-download-dropdown-item"
            @click=${this._download}
        >
            ${option}
        </li>`;
    }

    renderDownloadDropdown() {
        if (!this.isDownloadDropdownOpen) return nothing;

        return html`<ul
            class="omega-download-dropdown"
            role="menu"
            aria-hidden=${this.isDownloadDropdownOpen}
        >
            ${this.downloadOptions.map(option => this.renderDownloadOption(option))}
        </ul>`;
    }

    renderDownloadButton() {
        if (!this.hasHeaderAction('download')) return nothing;
        return html`<div class="omega-dialog-menu omega-dialog-menu-item">
            <omega-button
                class="omega-dialog-menu-button"
                type="icon"
                icon="download"
                hideLabel="always"
                ?disabled="${this.disableDownload}"
                @click=${this.toggleDropdownDownloadOptions}
            >
                Download
            </omega-button>
            ${this.renderDownloadDropdown()}
        </div>`;
    }

    renderPrintButton() {
        if (!this.hasHeaderAction('print')) return nothing;
        return html`<omega-button
            class="omega-dialog-menu-button"
            type="icon"
            hideLabel="always"
            icon="print"
            ?disabled="${this.disablePrint}"
            @click=${this._print}
        >
            Print
        </omega-button>`;
    }

    renderCloseButton() {
        if (this.hideCloseButton) return nothing;
        return html`
            <omega-button
                class="omega-dialog-menu-button close-button"
                .disabled=${this.disableClose}
                type="icon"
                icon="times"
                hideLabel="always"
                @click=${this.toggle}
                @blur=${e => {
                    e.stopPropagation();
                }}
            >
                Close
            </omega-button>
        `;
    }

    renderHeaderTitle() {
        if (this.headerIcon) {
            return html`<div class="omega-dialog-header-title">
                <i class="info-icon"><omega-icon icon="${this.headerIcon}"></omega-icon></i> ${this
                    .dialogTitle}
            </div>`;
        }
        return html`<div class="omega-dialog-header-title">${this.dialogTitle}</div>`;
    }

    renderHeader() {
        return html` <div class="omega-dialog-header">
            ${this.renderHeaderTitle()}
            <span class="omega-dialog-header-supplement">
                ${this.renderDownloadButton()} ${this.renderPrintButton()}
                ${this.renderCloseButton()}
            </span>
        </div>`;
    }

    renderSubHeader() {
        return html`<slot name="sub-header"></slot>`;
    }

    renderBody() {
        if (this.content) {
            return this.content;
        }
        return html` <slot name="content"></slot> `;
    }

    renderActions() {
        if (this.buttons) {
            return this.buttons.map(
                button =>
                    html`<omega-button type=${button.type} @click=${() => this.close(button.result)}
                        >${button.label}</omega-button
                    >`
            );
        }
        return html`<slot name="actions"></slot>`;
    }

    render() {
        return html`<div
                class=${classMap({ 'omega-dialog-background': true, show: this.open })}
            ></div>
            <div class=${classMap({ 'omega-dialog': true, show: this.open })}>
                ${this.renderHeader()} ${this.renderSubHeader()}
                <div class="omega-dialog-content">${this.renderBody()}</div>
                <div class="omega-dialog-actions">${this.renderActions()}</div>
                <slot></slot>
            </div>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                    position: absolute;
                }
                .omega-dialog {
                    position: fixed;
                    box-shadow: var(--omega-dialog-shadow);
                    text-align: left;
                    background-color: var(--primary-background, var(--omega-white, white));
                    padding: 0px 2px 5px 2px;
                    z-index: -1;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) scale(0.8);
                    transition: var(--omega-zoom-in);
                    min-width: var(--omega-dialog-min-width, min(400px, 80vw));
                    max-width: var(--omega-dialog-max-width, 90vw);
                    border-radius: 10px;
                    opacity: 0;
                    width: var(--omega-dialog-width, auto);
                    min-height: var(--omega-dialog-height, 200px);
                    max-height: calc(100vh - 256px);
                    display: flex;
                    flex-direction: column;
                    padding-left: var(--omega-dialog-panel-padding, 0px);
                    padding-right: var(--omega-dialog-panel-padding, 0px);
                    color: var(--primary-text-color, black);
                }

                .omega-dialog-background {
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: var(--omega-dialog-overlay);
                    opacity: 0;
                    z-index: -1;
                    height: 100%;
                    width: 100%;
                    transition: var(--omega-zoom-in);
                }

                .omega-dialog-background.show {
                    display: block;
                    opacity: 1;
                    visibility: visible;
                    z-index: 9999;
                    transition: var(--omega-zoom-out);
                }

                .omega-dialog-header {
                    margin-bottom: 12px;
                    display: flex;
                    justify-content: space-between;
                    /** Maintain height when no close button is present. */
                    min-height: 45px;
                }

                .omega-dialog-header-title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: var(--omega-dialog-header-font-size, 16px);
                    color: var(--omega-text-header);
                    font-weight: 500;
                    padding: 0 0 0 15px;
                }

                .omega-dialog-header-title .info-icon {
                    color: #4384b5;
                    font-size: 21px;
                    margin-right: 5px;
                }

                .omega-dialog.show {
                    visibility: visible;
                    opacity: 1;
                    z-index: 10000;
                    transform: translate(-50%, -50%) scale(1);
                    transition: var(--omega-zoom-out);
                }

                .omega-dialog-content {
                    flex: 1;
                    display: flex;
                    flex-direction: var(--omega-dialog-content-flex-direction, column);
                    max-height: calc(100vh - 64px);
                    overflow: var(--omega-dialog-content-overflow, auto);
                }

                .omega-dialog-actions {
                    display: flex;
                    justify-content: right;
                    padding: 10px 10px 0 0;
                }

                .omega-dialog-actions > omega-button:first-of-type {
                    margin-left: 0px;
                }

                .omega-dialog-actions > omega-button:last-of-type {
                    margin-right: 0px;
                }

                .omega-dialog-header-supplement {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    grid-column: 3;
                    grid-row: 1;
                }

                .omega-dialog-menu-item {
                    display: inline-block;
                    font-size: 16px;
                }

                .close-button {
                    --omega-button-icon-color: var(--omega-info);
                    --omega-button-icon-hover: var(--omega-info-lighten-300);
                }

                .omega-dialog-menu {
                    position: relative;
                }

                .omega-download-dropdown {
                    position: absolute;
                    right: 0;
                    top: 30px;
                    z-index: 5;
                    min-width: 50px;
                    padding: 0;
                    border: 1px solid #e9e9e9;
                    background: #fff;
                    border-radius: 3px;
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
                    list-style: none;
                }

                .omega-download-dropdown li {
                    min-width: 50px;
                    padding: 10px 25px;
                    text-align: center;
                    color: var(--omega-primary);
                    cursor: pointer;
                }

                .omega-download-dropdown li:hover {
                    background-color: #f5f5f5;
                }

                .omega-download-dropdown a {
                    text-decoration: none;
                }
            `,
        ];
    }
}

customElements.define('omega-dialog', OmegaDialog);
export default OmegaDialog;
