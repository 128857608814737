/* eslint-disable no-use-before-define */
import '@banno/jha-wc';
import { AlarmClock, parseTime } from '@treasury/alarm-clock';
import { ConfigurationService } from '@treasury/core/config';
import { TmHttpClient } from '@treasury/core/http';
import { LoggingService } from '@treasury/core/logging';
import { NavigationService } from '@treasury/core/navigation';
import { AchService } from '@treasury/domain/ach';
import { AccountService, ChannelAuthenticationService } from '@treasury/domain/channel/services';
import { EntitlementService } from '@treasury/domain/channel/services/entitlements/entitlement.service';
import { AuthenticationService, AuthFlowEvent } from '@treasury/domain/services/authentication';
import { FeatureFlagService } from '@treasury/domain/services/feature-flags';
import {
    LogoutManager,
    OpenIdleDialogToken,
    OpenTimeAccessDialogToken,
} from '@treasury/domain/services/logout';
import { StatusService } from '@treasury/domain/services/status';
import { OpenIdentityDialogToken, TmHttpClientMfa } from '@treasury/domain/shared';
import '@treasury/omega/css/index.css';
import { openIdentityDialog } from '@treasury/omega/domain-components/identity-verification-dialog';
import { openIdleDialog } from '@treasury/omega/domain-components/idle-logout-dialog';
import { openTimeAccessDialog } from '@treasury/omega/domain-components/time-access-logout-dialog';
import { OmegaDialogService } from '@treasury/omega/services/omega-dialog';
import { OfflineDialogService } from '@treasury/presentation/components/offline';
import baseStyles from '@treasury/presentation/styles/styles.css';
import { DiContainer } from '@treasury/utils/dependency-injection';
import camelCase from 'camelcase';
import { AppComponent } from './components/app-component';
import { importTemplates, registerWorker } from './helpers';
import { ApplicationAreaService } from './services/application-area';
import { PwaNavigationService } from './services/navigation';

const institutionId = window.location.pathname.split('/')[1];
const preferredColorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
if (!localStorage.getItem('color-scheme')) {
    window.localStorage.setItem('color-scheme', preferredColorScheme);
}
document.documentElement.setAttribute(
    'theme',
    localStorage.getItem('color-scheme') ?? preferredColorScheme
);

document.adoptedStyleSheets = [...document.adoptedStyleSheets, baseStyles];

const themeColorMetaTag = document.createElement('meta');
themeColorMetaTag.name = 'theme-color';
themeColorMetaTag.content = getComputedStyle(document.documentElement).getPropertyValue(
    '--color-primary'
);
document.getElementsByTagName('head')[0].appendChild(themeColorMetaTag);

(async function main(institutionId: string, version: string) {
    const worker = await registerWorker(institutionId, version);
    await bootstrap(worker);
    await importTemplates();
})(institutionId, window.treasury.version);

function configureBranding(institutionId: string) {
    const head = document.getElementsByTagName('HEAD')[0];
    const appleIconLink = document.createElement('link');
    appleIconLink.rel = 'apple-touch-icon';
    appleIconLink.href = `/pwa/assets/branding/${institutionId}/apple-touch-icon.png`;
    head.appendChild(appleIconLink);
    const faviconLink = document.createElement('link');
    faviconLink.rel = 'icon';
    faviconLink.href = `/pwa/assets/branding/${institutionId}/favicon.ico`;
    head.appendChild(faviconLink);
}

async function startAlarmClock(achService: AchService) {
    const fiClock = AlarmClock.getInstance();
    achService.getCutoffTimes().then(cutoffs => {
        fiClock.time = cutoffs.currentFiTime;
        fiClock.timeZone =
            /(All (\w+))/.exec(cutoffs.timeZone || '')?.[2] || cutoffs.timeZone || '';
        cutoffs.processingCutoffs?.forEach(cutoff => {
            const { startTime, cutoffTime, productType } = cutoff;
            if (startTime) {
                const startKeyName = camelCase(`${productType} start`);
                fiClock.setAlarm(startKeyName, startTime);
            }
            if (cutoffTime) {
                const cutoffKeyName = camelCase(`${productType} cutoff`);
                fiClock.setAlarm(cutoffKeyName, cutoffTime);
                const cutoffTimeObject = parseTime(cutoffTime);
                cutoffTimeObject.subtractMinutes(30);
                fiClock.setAlarm(`${cutoffKeyName}-30Minutes`, cutoffTimeObject);
            }
        });
    });
}

async function bootstrap(worker?: ServiceWorkerRegistration) {
    const di = new DiContainer();
    di.provide(OpenIdentityDialogToken, openIdentityDialog);
    di.provide(OpenIdleDialogToken, openIdleDialog);
    di.provide(OpenTimeAccessDialogToken, openTimeAccessDialog);
    di.provide(TmHttpClient, TmHttpClientMfa);
    di.provide(NavigationService, PwaNavigationService);

    await DiContainer.init(di);

    // TODO: consolidate auth service usage
    const channelAuthService = di.get(ChannelAuthenticationService);
    const authService = di.get(AuthenticationService);
    const logoutManager = di.get(LogoutManager);
    const http = di.get(TmHttpClient);
    const navService = di.get(NavigationService);
    const configService = di.get(ConfigurationService);
    const statusService = di.get(StatusService);
    const offlineDialog = di.get(OfflineDialogService);
    const loggingService = di.get(LoggingService);
    const applicationAreaService = di.get(ApplicationAreaService);
    const entitlementService = di.get(EntitlementService);
    const accountService = di.get(AccountService);
    const achService = di.get(AchService);

    configureBranding(configService.institutionId);

    channelAuthService.authEvent$.subscribe(event => {
        switch (event) {
            case AuthFlowEvent.TermsAndConditionsAccept:
                navService.navigate('/terms-and-conditions-accept');
                break;
            case AuthFlowEvent.Done:
                navService.navigate('/dashboard');
                break;
        }
    });

    statusService.online$.subscribe(async isOnline => {
        if (isOnline) {
            offlineDialog.close();
        } else {
            offlineDialog.open();
        }
    });

    authService.onAuthenticated(async () => {
        if (worker) {
            const { apiRoot } = di.get(ConfigurationService);
            const { authToken } = http;
            worker.active?.postMessage({
                authToken,
                apiRoot,
            });

            const user = await accountService.getCurrentUser();
            loggingService.setUserSessionContext(user);
        }
        logoutManager.startLogoutTimers();
        FeatureFlagService.init('/api/features');
        applicationAreaService.init();
        entitlementService.init();
        startAlarmClock(achService);
    });

    loggingService.init();

    logoutManager.logout$.subscribe(() => onSessionEnd(di));
    http.sessionExpired$.subscribe(() => onSessionEnd(di));
    http.error$.subscribe(err => {
        loggingService.logError('Fetch error', err);
    });
    navService.setOutlet(AppComponent.getOutlet);
}

function onSessionEnd(di: DiContainer) {
    const dialogService = di.get(OmegaDialogService);
    dialogService.closeAll();

    const applicationAreaService = di.get(ApplicationAreaService);
    applicationAreaService.reset();

    const entitlementService = di.get(EntitlementService);
    entitlementService.reset();

    const ffService = di.get(FeatureFlagService);
    ffService.reset();

    const navService = di.get(NavigationService);
    navService.navigate('login');
}
