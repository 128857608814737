import '@vaadin/number-field';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { TmInputFieldBase } from './tm-input-field.base';

export const tagName = 'tm-number-field';
@customElement(tagName)
export class TmNumberField extends TmInputFieldBase {
    @property({ type: Number })
    min?: number;

    @property({ type: Number })
    max?: number;

    render() {
        return html`
            <vaadin-number-field
                @value-changed=${(e: CustomEvent) => {
                    const valueChangedEvent = new CustomEvent('value-changed', {
                        detail: e.detail,
                    });
                    this.dispatchEvent(valueChangedEvent);
                }}
                class="w-full"
                label=${this.label}
                value=${this.value}
                placeholder=${ifDefined(this.placeholder)}
                .min=${this.min}
                .max=${this.max}
                ?required=${this.required}
                ?readonly=${this.readonly}
                ?disabled=${this.disabled}
            >
            </vaadin-number-field>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmNumberField;
    }
}
