import { CompanyAccountModelDto } from '@treasury/api/channel';
import TransferPaymentsService from '@treasury/domain/channel/services/transfers/transfer-payments-service';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-currency-range';
import {
    CurrencyRange,
    CurrencyRangeType,
} from '@treasury/presentation/components/tm-currency-range';
import { mapCurrencyRangeToDto } from '@treasury/presentation/components/tm-currency-range.mappings';
import '@treasury/presentation/components/tm-date-range';
import { DateRange, DateRangeType } from '@treasury/presentation/components/tm-date-range';
import { mapDateRangeToDto } from '@treasury/presentation/components/tm-date-range.mappings';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-loader';
import '@treasury/presentation/components/tm-multi-select';
import { MultiSelectItem } from '@treasury/presentation/components/tm-multi-select';
import '@treasury/presentation/components/tm-text-field';
import { DeepReactive } from '@treasury/utils/lit-helpers';
import { add } from 'date-fns';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { transferPaymentStatusItems } from '../data/transfer-payment-status-items';

const defaultTransferDateRange: DateRange = {
    rangeType: DateRangeType.DateRange,
    startDate: new Date(),
    endDate: add(new Date(), { weeks: 1 }),
};

const defaultCreatedDateRange: DateRange = {
    rangeType: DateRangeType.None,
};

const defaultAmountRange: CurrencyRange = {
    rangeType: CurrencyRangeType.AllAmounts,
};

export const tagName = 'transfer-payments-filter-sheet';
@customElement(tagName)
export class TransferPaymentsFilterSheet extends TmBaseComponent {
    @property({ type: Boolean })
    public open = false;

    @state()
    private transferDateRange: DateRange = defaultTransferDateRange;

    @state()
    private statusItems = transferPaymentStatusItems;

    @state()
    private statusItemsSelected = this.statusItems;

    @state()
    private accountItems: MultiSelectItem[] = [];

    @state()
    private fromAccountItemsSelected: MultiSelectItem[] = [];

    @state()
    private toAccountItemsSelected: MultiSelectItem[] = [];

    @state()
    private amountRange: CurrencyRange = defaultAmountRange;

    @state()
    private createdDateRange: DateRange = defaultCreatedDateRange;

    @state()
    private transactionId: string | null = null;

    @state()
    private loading = true;

    @DeepReactive()
    private validState = {
        transferDate: true,
        status: true,
        fromAccount: true,
        toAccount: true,
        amountRange: true,
        createdDate: true,
    };

    async firstUpdated() {
        try {
            const transferAccounts =
                (await TransferPaymentsService.fetchTransferAccounts()) as unknown as CompanyAccountModelDto[];
            this.accountItems = transferAccounts.map((account: CompanyAccountModelDto) => ({
                label: account.accountDisplayLabel ?? '',
                value: account,
            }));
            this.onResetForm();
            this.onApplyFilters();
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    }

    onApplyFilters() {
        this.open = false;
        this.dispatchEvent(new CustomEvent('close'));
        this.dispatchEvent(
            new CustomEvent('applyFilters', {
                detail: this.toDto(),
            })
        );
    }

    private onResetForm() {
        this.transferDateRange = { ...defaultTransferDateRange };
        this.statusItemsSelected = this.statusItems;
        this.fromAccountItemsSelected = this.accountItems;
        this.toAccountItemsSelected = this.accountItems;
        this.amountRange = { ...defaultAmountRange };
        this.createdDateRange = { ...defaultCreatedDateRange };
        this.transactionId = null;
    }

    private toDto() {
        const {
            operator: transferDateOperator,
            dateFrom: transferDateFrom,
            dateTo: transferDateTo,
        } = mapDateRangeToDto(this.transferDateRange);

        const {
            operator: createdDateOperator,
            dateFrom: createdDateFrom,
            dateTo: createdDateTo,
        } = mapDateRangeToDto(this.createdDateRange);

        const {
            operator: amountOperator,
            fromAmount,
            toAmount,
        } = mapCurrencyRangeToDto(this.amountRange);

        return {
            transferDateOperator,
            transferDateFrom,
            transferDateTo,
            status: this.statusItemsSelected.map((item: MultiSelectItem) => item.value),
            accountOperator: 'FromTo',
            fromAccounts: this.fromAccountItemsSelected.map((item: MultiSelectItem) => item.value),
            toAccounts: this.toAccountItemsSelected.map((item: MultiSelectItem) => item.value),
            amountOperator,
            fromAmount,
            toAmount,
            createdDateOperator,
            createdDateFrom,
            createdDateTo,
            transactionId: this.transactionId,
        };
    }

    renderForm() {
        if (this.loading) return html`<tm-loader card></tm-loader>`;
        return html`
            <form>
                <tm-date-range
                    label="Transfer Date"
                    required
                    .range=${this.transferDateRange}
                    @selection=${(e: CustomEvent) => {
                        this.validState.transferDate = !!e.detail;
                        if (!!e.detail) {
                            this.transferDateRange = e.detail;
                        }
                    }}
                ></tm-date-range>
                <tm-multi-select
                    label="Status"
                    .allowSelectAll=${true}
                    .allSelectedText=${'All Statuses'}
                    .items=${this.statusItems}
                    .selectedItems=${[...this.statusItemsSelected]}
                    @selected-items-changed=${(e: CustomEvent) => {
                        this.statusItemsSelected = e.detail;
                        this.validState.status = !!this.statusItemsSelected.length;
                    }}
                ></tm-multi-select>
                <tm-multi-select
                    label="From Account"
                    .allowSelectAll=${true}
                    .allSelectedText=${'All Accounts'}
                    .items=${this.accountItems}
                    .selectedItems=${[...this.fromAccountItemsSelected]}
                    @selected-items-changed=${(e: CustomEvent) => {
                        this.fromAccountItemsSelected = e.detail;
                        this.validState.fromAccount = !!this.fromAccountItemsSelected.length;
                    }}
                ></tm-multi-select>
                <tm-multi-select
                    label="To Account"
                    .allowSelectAll=${true}
                    .allSelectedText=${'All Accounts'}
                    .items=${this.accountItems}
                    .selectedItems=${[...this.toAccountItemsSelected]}
                    @selected-items-changed=${(e: CustomEvent) => {
                        this.toAccountItemsSelected = e.detail;
                        this.validState.toAccount = !!this.toAccountItemsSelected.length;
                    }}
                ></tm-multi-select>
                <tm-currency-range
                    .range=${this.amountRange}
                    @selection=${(e: CustomEvent) => {
                        this.validState.amountRange = !!e.detail;
                        if (!!e.detail) {
                            this.amountRange = e.detail;
                        }
                    }}
                ></tm-currency-range>
                <tm-text-field
                    label="Transaction ID"
                    .value=${this.transactionId}
                    @value-changed=${(e: CustomEvent) => {
                        this.transactionId = e.detail.value;
                    }}
                ></tm-text-field>
                <tm-date-range
                    label="Created Date"
                    .range=${this.createdDateRange}
                    @selection=${(e: CustomEvent) => {
                        this.validState.createdDate = !!e.detail;
                        if (!!e.detail) {
                            this.createdDateRange = e.detail;
                        }
                    }}
                    .maxDate=${new Date()}
                ></tm-date-range>
            </form>
        `;
    }

    render() {
        return html`
            <tm-bottom-sheet
                .open=${this.open}
                @close=${() => {
                    this.open = false;
                    this.dispatchEvent(new CustomEvent('close'));
                }}
                ><p slot="header-center">Filter Transfer Payments</p>
                <p slot="header-right"></p>
                <tm-body>${this.renderForm()}</tm-body>
                <tm-footer
                    .buttonConfig=${[
                        {
                            text: 'Apply Filters',
                            onClick: () => {
                                this.onApplyFilters();
                            },
                            disabled: Object.values(this.validState).some(isValid => !isValid),
                        },
                        {
                            importance: 'secondary',
                            text: 'Reset',
                            onClick: () => {
                                this.onResetForm();
                            },
                        },
                    ] as ButtonConfig[]}
                >
                </tm-footer>
            </tm-bottom-sheet>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TransferPaymentsFilterSheet;
    }
}
