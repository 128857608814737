export class Tristate<T, Options> {
    constructor(
        public readonly value: T,
        public readonly options: Options
    ) {}

    private _selected = '';

    get selected() {
        return this._selected;
    }

    set selected(value: string) {
        this._selected = value;
    }
}
