import { TmContainer } from '@treasury/presentation';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-footer';
import '@treasury/presentation/components/tm-list';
import { TmList } from '@treasury/presentation/components/tm-list';
import { PropertyValueMap, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { TmFilterForm } from '../forms/tm-filter-form.component';

export const tagName = 'tm-list-layout';
@customElement(tagName)
export class TmListLayout<T extends object> extends TmContainer {
    protected verifiedPropNames: readonly (keyof this)[] = ['columns'];

    @property({ type: Array })
    public columns!: TmList<T>['columns'];

    @property({ attribute: false })
    public fetchItems?: () => Promise<TmList<T>['items']>;

    @property({ type: Array })
    public items?: TmList<T>['items'];

    @property({ type: Array })
    public searchFields: TmList<T>['searchFields'] = [];

    @property({ type: String })
    public filterSheetHeading = 'Advanced Filter';

    @property({ type: String })
    public groupBy?: TmList<T>['groupBy'];

    @state()
    private filterEl?: TmFilterForm;

    @state()
    private open = false;

    protected async firstUpdated() {
        if (this.fetchItems) {
            await this.tryFetch(this.fetchItems, items => (this.items = items));
        }
    }

    protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        if (_changedProperties.has('items') && this.items) {
            this.loading = false;
        }
    }

    protected onSlotChange(e: Event) {
        // Detect and store the slotted component, making sure we only use a TmFilterForm component
        if (e.target instanceof HTMLSlotElement && e.target.assignedNodes().length > 0) {
            const filterForm = e.target
                .assignedNodes()
                // whitespace in html counts as separate slotted nodes so filter it out
                .filter(n => n instanceof TmFilterForm) as TmFilterForm[];
            if (filterForm.length) this.filterEl = filterForm[0];
        }
    }

    renderFilterSheet() {
        return html`<tm-bottom-sheet .open=${this.open} @close=${() => (this.open = false)}
            ><p slot="header-center">${this.filterSheetHeading}</p>
            <!-- A slot is required for the FilterForm because they can vary drastically. Because
            the footer lives outside the FilterForm but relies on the validity of the FilterForm, we
            need to call requestUpdate() each time the validity changes so that the footer can
            request the current value and update its buttons accordingly. -->
            <div class="p-4" @change-validity=${() => this.requestUpdate()}>
                <slot @slotchange=${this.onSlotChange}></slot>
            </div>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: 'Apply Filters',
                        onClick: (e: Event) => {
                            this.filterEl?.applyFilter();
                            e.currentTarget?.dispatchEvent(
                                new CustomEvent('close', { bubbles: true, composed: true })
                            );
                        },
                        disabled: !this.filterEl || !this.filterEl.isValid,
                    },
                    {
                        importance: 'secondary',
                        text: 'Reset',
                        onClick: () => {
                            this.filterEl?.reset();
                        },
                    },
                ]}
            ></tm-footer>
        </tm-bottom-sheet>`;
    }

    render() {
        if (this.loading || !this.items) {
            return html`${this.renderLoader()} ${this.renderFilterSheet()}`;
        }

        return html`<tm-list
                .items=${this.items}
                .columns=${this.columns}
                .searchFields=${this.searchFields}
                .filterButton=${!!this.filterEl}
                .groupBy=${this.groupBy}
                @open-list-filter=${() => {
                    this.open = true;
                }}
            ></tm-list
            >${this.renderFilterSheet()}`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmListLayout<object>;
    }
}
