import { BatchModelDto } from '@treasury/api/channel';
import { ConfigurationService } from '@treasury/core/config';
import { NavigationService } from '@treasury/core/navigation';
import { RemoteDepositCaptureService } from '@treasury/domain/channel/services';
import { TmBaseComponent } from '@treasury/presentation';
import {
    arrowRightIcon,
    checkmarkIcon,
    circleCloseIcon,
    circlePlusIcon,
    clockIcon,
    helpIcon,
} from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-bottom-sheet';
import { InjectProperty } from '@treasury/utils';
import { format } from 'date-fns';
import { TemplateResult, css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import '../partials/deposit-detail';
import './quick-deposit-container';

export const tagName = 'deposit-activity-container';
@customElement(tagName)
export class DepositActivityContainer extends TmBaseComponent {
    @InjectProperty()
    private declare readonly headerService: HeaderBarService;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    @InjectProperty()
    private declare readonly config: ConfigurationService;

    @InjectProperty()
    private declare readonly remoteDepositCaptureService: RemoteDepositCaptureService;

    @state()
    private batches: BatchModelDto[] = [];

    @state()
    private selectedBatch?: BatchModelDto;

    @state()
    private batchDetailOpen = false;

    async firstUpdated() {
        this.headerService.configure({
            title: 'Deposits',
            menuItems: [{ title: 'Help', icon: helpIcon, action: () => alert('TODO') }],
        });
        this.batches = (await this.remoteDepositCaptureService.getBatches()).map(batch => ({
            ...(batch as BatchModelDto),
            // add date to the item so we can group by it in the list
            date: format(
                new Date(batch.events?.[batch.events.length - 1].date ?? ''),
                'iiii MMMM d, yyyy'
            ),
        }));
    }

    private getStatusColor(status: string) {
        switch (status) {
            case 'Processing':
                return 'orange';
            case 'Accepted':
            case 'Deposited':
                return 'green';
            case 'Rejected':
                return 'red';
            default:
                return '';
        }
    }

    private renderRouteActionItem() {
        return html`
            <jhd-route-action-item
                .icon=${circlePlusIcon}
                .action=${'Create a Deposit'}
                @click=${() => {
                    this.navService.navigate(`/create-deposit`);
                }}
            ></jhd-route-action-item>
        `;
    }

    private renderStatusIconColumn(batch: BatchModelDto) {
        let statusIcon: TemplateResult;
        const color = this.getStatusColor(batch.status ?? '');
        switch (batch.status) {
            case 'Processing':
                statusIcon = clockIcon;
                break;
            case 'Accepted':
            case 'Deposited':
                statusIcon = checkmarkIcon;
                break;
            case 'Rejected':
                statusIcon = circleCloseIcon;
                break;
            default:
                statusIcon = html``;
        }

        return html`<div class="flex flex-col w-6 mr-1">
            <span class="status-icon"><tm-icon .icon=${statusIcon} .color=${color}></tm-icon></span>
        </div>`;
    }

    private renderActivityColumn(batch: BatchModelDto) {
        const color = this.getStatusColor(batch.status ?? '');
        return html`<div class="flex flex-col">
            <span class="font-medium text-sm overflow-hidden text-ellipsis"
                >${batch.locationName}</span
            >
            <small class="account-number text-xs" style="color: ${color};">${batch.status}</small>
        </div>`;
    }

    private renderAmountColumn(batch: BatchModelDto) {
        const dollarUSLocale = Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return html`<div class="amount-column flex flex-col">
            <div class="available-balance text-lg text-right font-medium">
                ${dollarUSLocale.format(batch.totalAmount)}
                <tm-icon .icon=${arrowRightIcon} .color=${'var(--tm-color-primary)'}></tm-icon>
            </div>
        </div>`;
    }

    public render() {
        return html`
            ${this.renderRouteActionItem()}
            <tm-list-layout
                .columns=${[
                    {
                        renderer: this.renderStatusIconColumn.bind(this),
                        options: { isIconColumn: true },
                    },
                    this.renderActivityColumn.bind(this),
                    this.renderAmountColumn.bind(this),
                ]}
                .items=${this.batches}
                groupBy="date"
                .searchFields=${['locationName', 'status', 'totalAmount']}
                @select-list-item=${(e: CustomEvent<BatchModelDto>) => {
                    this.selectedBatch = e.detail;
                    this.batchDetailOpen = true;
                }}
            ></tm-list-layout>
            <tm-bottom-sheet
                .open=${this.batchDetailOpen}
                @close=${() => (this.batchDetailOpen = false)}
            >
                <span slot="header-center">Deposit Detail</span>
                <deposit-detail .deposit=${this.selectedBatch as BatchModelDto}></deposit-detail
            ></tm-bottom-sheet>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    height: 100%;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: DepositActivityContainer;
    }
}
