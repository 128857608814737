/* eslint-disable no-use-before-define */
import { HttpVerb } from '@treasury/utils';
import hash from 'object-hash';

export interface TmHttpCacheEntry {
    cacheDate: Date;
    responsePromise: Promise<Response>;
}

export interface CacheParams {
    url: string;
    method: HttpVerb;
    body?: object;
}

export class TmHttpCache {
    // eslint-disable-next-line no-useless-constructor
    constructor(private hashFn: (obj: hash.NotUndefined) => string = hash) {}

    private readonly data = new Map<string, TmHttpCacheEntry>();

    /**
     * Generating a hash is time-intensive.
     * Memoize them for later lookup.
     */
    private readonly hashedKeys = new WeakMap<CacheParams, string>();

    public get size() {
        return this.data.size;
    }

    public getEntry(params: CacheParams, maxAgeInSeconds = 0) {
        const key = this.generateCacheKey(params);
        if (!key) {
            return undefined;
        }

        const cachedEntry = this.data.get(key) as TmHttpCacheEntry | undefined;

        if (!cachedEntry) {
            return undefined;
        }

        const now = new Date();
        const expiration = cachedEntry.cacheDate;
        expiration.setSeconds(expiration.getSeconds() + maxAgeInSeconds);

        if (expiration <= now) {
            this.deleteEntry(params);
            return undefined;
        }

        return cachedEntry;
    }

    public addEntry(params: CacheParams, responsePromise: Promise<Response>) {
        const key = this.generateCacheKey(params);
        if (!key) {
            return;
        }

        const cacheEntry: TmHttpCacheEntry = {
            responsePromise,
            cacheDate: new Date(),
        };

        this.data.set(key, cacheEntry);
    }

    public deleteEntry(params: CacheParams) {
        const key = this.generateCacheKey(params);
        if (!key) {
            return;
        }

        this.data.delete(key);
        this.hashedKeys.delete(params);
    }

    public clear() {
        this.data.clear();
    }

    private generateCacheKey(params: CacheParams) {
        const previousKey = this.hashedKeys.get(params);
        if (previousKey) {
            return previousKey;
        }

        try {
            const key = this.hashFn(params as unknown as Record<string, unknown>);
            this.hashedKeys.set(params, key);
            return key;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}

export const httpCache = new TmHttpCache();
