import { TmHttpClient, TmHttpResponseType } from '@treasury/core/http';
import { Injectable } from '@treasury/utils';
import { transformMessage } from './transform-message';
import { Message as MessageRequest } from './types/message.type';

export enum MESSAGE_TYPE {
    Received = 2,
    Sent = 3,
    Archived = 4,
}

export type MessageDto = {
    CompanyId: number | undefined;
    UserId: number | undefined;
    CategoryId: number;
    Body: string;
    ParentMessageId: number | undefined;
    AssignedToUserId: number | undefined;
    file: File | undefined;
};

export type Message = {
    id: number;
    messageId: string;
    companyName: string | null;
    companyId: number | null;
    fromToUserName: string;
    fromUserId: number;
    toUserId: number | null;
    categoryId: number;
    subject: string;
    body: string;
    status: string;
    assignedTo: string;
    sentDate: string;
    sentTime: string;
    sentDateTime: string;
    sentMessage: boolean;
    hasAttachment: boolean;
    attachmentNames: Array<string>;
    attachments: Array<string>;
    parentMessage: string | null;
    isArchived: boolean;
    parentMessageId: number | null;
    assignedToUserId: number | null;
    isReply: boolean;
    isSent: boolean;
    messageIdSortValue: string;
};

@Injectable()
export class MessagesService {
    // eslint-disable-next-line no-useless-constructor
    constructor(private readonly http: TmHttpClient) {}

    fetchMessages(messageTypeId: MESSAGE_TYPE) {
        return this.http.request('messagingcenter/search', {
            method: 'POST',
            body: {
                messageTypeId,
            },
        });
    }

    getMessageCatagories() {
        return this.http.request('messagingcenter/getMessageCategories', {
            method: 'GET',
        });
    }

    async sendMessage(messageRequestBody: MessageRequest) {
        const formData = transformMessage(messageRequestBody);
        return this.http.request('messagingcenter/savemessage', {
            method: 'POST',
            body: formData,
        });
    }

    getMessage(messageId: number) {
        return this.http.request(`messagingcenter/messagethread/${messageId}`, {
            method: 'GET',
        });
    }

    archiveMessage(message: Message) {
        return this.http.request(`messagingcenter/update`, {
            method: 'POST',
            body: {
                messageId: message.id,
                actionTypeId: 2,
            },
        });
    }

    async downloadAttachment(attachmentId: number, attachmentName: string) {
        const response = await this.http.request(
            `messagingcenter/downloadattachment/${attachmentId}`,
            {
                method: 'GET',
                responseType: TmHttpResponseType.ArrayBuffer,
            }
        );
        const isPdf = attachmentName.toLowerCase().includes('pdf');
        const type = isPdf ? 'application/pdf' : 'application/octet-stream';
        const blob = new Blob([response], { type });
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = attachmentName;
        document.body.appendChild(link);
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        );
        document.body.removeChild(link);
    }
}
