export const achTypeItems = [
    {
        label: 'All',
        value: 'All',
    },
    {
        label: 'Reversal',
        value: 'Reversal',
    },
    {
        label: 'Tax Payment',
        value: 'TaxPayment',
    },
    {
        label: 'Child Support',
        value: 'ChildSupportPayment',
    },
    {
        label: 'International ACH Payment',
        value: 'InternationalPayment',
    },
];
