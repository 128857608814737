import { phoneFormatter } from '@treasury/utils';
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { TmTextField } from './tm-text-field';

export const tagName = 'tm-phone-field';
@customElement(tagName)
export class TmPhoneField extends TmTextField {
    constructor() {
        super();
    }

    @property({ type: String })
    allowedCharacters: string = '[0-9]';

    @state()
    formattedValue?: string;

    protected firstUpdated(): void {
        this.formattedValue = phoneFormatter(this.value);
    }

    private onValueChanged(e: CustomEvent) {
        // Android doesn't always respect the allow-char-pattern
        // so this is used to force the formattedValue value and prevent non-digits characters
        const input = this.shadowRoot?.querySelector('vaadin-text-field input') as HTMLInputElement;
        const digits = e.detail.value.replace(/[^\d]/g, '');
        this.formattedValue = phoneFormatter(e.detail.value);
        if (input) {
            input.value = this.formattedValue;
        }

        const detail =
            digits.length === 10
                ? {
                      value: digits,
                      formatted: this.formattedValue,
                  }
                : undefined;

        if (!detail) {
            return;
        }

        const valueChangedEvent = new CustomEvent('value-changed', {
            detail,
        });
        this.dispatchEvent(valueChangedEvent);
    }

    render() {
        return html`
            <vaadin-text-field
                @value-changed=${(e: CustomEvent) => this.onValueChanged(e)}
                class="w-full"
                part="component"
                label=${this.label}
                .value=${this.formattedValue ?? ''}
                placeholder=${ifDefined(this.placeholder)}
                pattern=${ifDefined(this.pattern)}
                allowed-char-pattern=${this.allowedCharacters}
                helper-text=${ifDefined(this.helperText)}
                .minlength=${this.minLength}
                .maxlength=${this.maxLength || 14}
                ?required=${this.required}
                ?readonly=${this.readonly}
                ?disabled=${this.disabled}
            >
            </vaadin-text-field>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmPhoneField;
    }
}
