import { PropertyValues } from '@lit/reactive-element';
import { DomainEntity } from '@treasury/domain/shared';
import { TmBaseComponent } from '@treasury/presentation/tm-base.component';

export abstract class TmFilterForm extends TmBaseComponent {
    protected abstract filter: DomainEntity<unknown>;

    public abstract get isValid(): boolean;

    public abstract reset(): void;

    protected updated(_changedProperties: PropertyValues): void {
        if (_changedProperties.has('filter')) {
            this.changeValidity();
        }
    }

    protected changeValidity() {
        this.dispatchEvent(new CustomEvent('change-validity', { bubbles: true, composed: true }));
    }

    public applyFilter() {
        this.dispatchEvent(
            new CustomEvent('apply-filter', {
                detail: this.filter,
                bubbles: true,
                composed: true,
            })
        );
    }
}
