import { exists } from './object.helpers';

export function toTitleCase(phrase: string) {
    if (!phrase) return phrase;
    if (phrase.trim().length > 2) {
        // Title case after spaces. Doesn't handle apostrophes (O'Malley), dashes, etc.
        phrase = phrase
            .toLowerCase()
            .split(' ')
            .map(function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');
    }
    return phrase;
}

export function isNonEmptyString(str: string | undefined | null): str is string {
    return typeof str === 'string' && str.length > 0;
}

/**
 * Returns `true` if the provided value is a string containing
 * only an integer number (e.g., `'10203'`).
 */
export function isIntegerString(str: string | undefined | null) {
    if (!exists(str)) {
        return false;
    }

    if (str.length < 1) {
        return false;
    }

    // do not consider decimal numbers
    if (str.includes('.')) {
        return false;
    }

    // avoiding parseInt() here, as it parses strings with postfix measurement units
    return !Number.isNaN(+str);
}

/**
 *  Converts a "camelCase" or "PascalCase" string to "Title Case".
 */
export function camelCaseToTitle(str: string) {
    const isUppercase = (c: string) => /[A-Z]/.test(c);
    const isNumber = (c: string) => /[0-9]/.test(c);
    let output = '';
    let cursor = 0;
    while (cursor < str.length) {
        const c = str[cursor];
        if (isUppercase(c)) {
            let upperCursor = cursor;
            while (upperCursor < str.length && isUppercase(str[upperCursor])) {
                upperCursor++;
            }
            const consecutiveUppers = upperCursor - cursor > 1;
            if (consecutiveUppers && upperCursor !== str.length) {
                upperCursor--;
            }
            output += ` ${str.slice(cursor, upperCursor)}`;
            cursor = upperCursor;
        } else if (isNumber(c)) {
            let numberCursor = cursor;
            while (numberCursor < str.length && isNumber(str[numberCursor])) {
                numberCursor++;
            }
            output += ` ${str.slice(cursor, numberCursor)}`;
            cursor = numberCursor;
        } else {
            output += c;
            cursor++;
        }
    }

    const title = (output[0].toUpperCase() + output.slice(1)).trim();
    return title.replace(/((?<= |^))Id((?= |$))/g, '$1ID$2');
}

export function toOrdinalSuffix(num: string | number) {
    const int = typeof num === 'string' ? parseInt(num) : Math.round(num),
        digits = [int % 10, int % 100],
        ordinals = ['st', 'nd', 'rd', 'th'],
        oPattern = [1, 2, 3, 4],
        tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
        ? int + ordinals[digits[0] - 1]
        : int + ordinals[3];
}

/**
 * Removes all non-alphanumeric characters in a string.
 */
export function removeSpecialChars(str: string) {
    // https://regex101.com/r/DJTaqH/1
    return str.replace(/[^a-zA-Z0-9]/g, '');
}
