/* eslint-disable import/extensions */
import { NavigationService } from '@treasury/core/navigation';
import { DashboardService } from '@treasury/domain/channel/services/dashboard/dashboard-service';
import { Notification } from '@treasury/domain/channel/types/notifications.dto';
import { TmBaseComponent } from '@treasury/presentation';
import { notificationsIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-loader';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/jhd-tooltip';

export const tagName = 'notifications-tooltip';
@customElement(tagName)
export class NotificationsTooltip extends TmBaseComponent {
    @InjectProperty()
    private declare readonly navService: NavigationService;

    @property({ type: Boolean })
    showNotificationsTooltip = false;

    @state()
    private loading = true;

    @state()
    private notifications: Notification[] = [];

    @state()
    private totalNotifications = 0;

    @InjectProperty()
    private declare readonly client: DashboardService;

    async firstUpdated() {
        this.fetchNotifications();
    }

    async fetchNotifications() {
        try {
            const { notifications, count } = await this.client.getRecentNotifications();
            this.notifications = notifications;
            this.totalNotifications = count;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    handleKeyUp(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.client.logNotificationActivity();
        }
    }

    async markNotificationsAsRead(notifications: Notification[]) {
        const notificationIds = notifications.map((notification: Notification) => ({
            id: notification.id,
        }));
        try {
            await this.client.markNotificationsAsRead(notificationIds);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        } finally {
            notifications.forEach((notification: Notification) => {
                const n = { ...notification, detailsVisible: !notification.detailsVisible };
                this.renderNotificationDetail(n);
            });
        }
    }

    async archiveNotifications(notifications: Notification[]) {
        const notificationIds = notifications
            .filter((notification: Notification) => !notification.isRead)
            .map((notification: Notification) => ({ id: notification.id }));
        try {
            await this.client.archiveNotifications(notificationIds);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        } finally {
            this.fetchNotifications();
        }
    }

    routeToNotificationsCenter() {
        this.navService.navigate(`/notifications`);
    }

    renderNotificationDetail(notification: Notification) {
        if (notification.detailsVisible) {
            return html`<div class="notification-detail">${notification.detail}</div>`;
        }
        return nothing;
    }

    renderNotificationsList() {
        const notificationItems = this.notifications.map(
            (notification: Notification) =>
                html`<div
                    class="flex flex-row justify-between notification-item"
                    @click=${() => {
                        this.markNotificationsAsRead([notification]);
                    }}
                    @keyup=${(event: KeyboardEvent) => this.handleKeyUp(event)}
                >
                    <div>
                        <span class="notification-title">${notification.subject}</span><br />
                        <span class="notification-subtitle">${notification.createdOn}</span>
                    </div>
                    <omega-button
                        type="link"
                        @click=${() => {
                            this.archiveNotifications([notification]);
                        }}
                        >Archive</omega-button
                    >
                    ${this.renderNotificationDetail(notification)}
                </div>`
        );

        return html`<div class="flex flex-row notifications-header">
                <span>${notificationsIcon}</span>
                <span>&nbsp;Notifications</span>
                <omega-button
                    type="link"
                    posture="small"
                    @click=${() => this.routeToNotificationsCenter()}
                    >View All</omega-button
                >
                <omega-button
                    type="link"
                    posture="small"
                    @click=${(event: Event) => {
                        event.stopPropagation();
                        this.archiveNotifications(this.notifications);
                    }}
                    >Archive All</omega-button
                >
            </div>
            <div id="notifications-list">${notificationItems}</div>`;
    }

    renderNotificationsCount() {
        if (!this.totalNotifications) return nothing;
        return html`<jha-badge danger small>${this.totalNotifications}</jha-badge>`;
    }

    render() {
        if (this.loading) return html`<tm-loader></tm-loader>`;
        return this.showNotificationsTooltip
            ? html`<jhd-tooltip direction="bottom-left" is-clickable light
                  ><div class="icon-wrapper">
                      ${notificationsIcon}${this.renderNotificationsCount()}
                  </div>
                  <div slot="content" style="width:290px;">${this.renderNotificationsList()}</div>
              </jhd-tooltip>`
            : html`<div class="icon-wrapper">
                  ${notificationsIcon}${this.renderNotificationsCount()}
              </div>`;
    }

    static get styles() {
        return [
            css`
                path {
                    fill: var(--header-bar-button-color);
                }

                tm-loader {
                    --loader-color: var(--header-bar-button-color);
                }
                span path {
                    fill: #8e8d8d;
                }
                small {
                    position: absolute;
                    background-color: rgb(215, 61, 9);
                    color: rgb(255, 255, 255);
                    padding: 1px;
                    margin-top: -32px;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    text-align: center;
                }
                jha-badge {
                    position: absolute;
                    margin-top: -32px;
                }
                .notifications-header {
                    font-size: 12px;
                    border-bottom: 1px solid #e2e8f0;
                    padding-bottom: 5px;
                    align-items: center;
                    margin-bottom: 5px;
                    color: var(--notification-title);
                }
                .notification-title {
                    font-weight: 600;
                    font-size: 13px;
                    color: var(--notification-title);
                }
                .notification-subtitle {
                    font-weight: normal;
                    color: var(--notification-subtitle);
                }
                .icon-wrapper {
                    position: relative;
                }
                .notification-item {
                    margin-top: 5px;
                    align-items: center;
                }
                #notifications-list {
                    max-height: 400px;
                    overflow-y: auto;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: NotificationsTooltip;
    }
}
