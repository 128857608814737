import { NavigationService } from '@treasury/core/navigation';
import { TmBaseComponent } from '@treasury/presentation';
import { depositIcon } from '@treasury/presentation/assets/icons';
import { InjectProperty } from '@treasury/utils';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import '../../components/jhd-icon';

export const tagName = 'deposits-widget';
@customElement(tagName)
export class DepositsWidget extends TmBaseComponent {
    @property({ type: Boolean })
    public disabled = false;

    @InjectProperty()
    private declare navService: NavigationService;

    newQuickDeposit() {
        this.navService.navigate('/quick-deposit');
    }

    newBatchDeposit() {
        this.navService.navigate('/batch-deposit');
    }

    render() {
        return html` <tm-card>
            <h4>Deposits</h4>
            <a
                href="javascript:void(0)"
                @click=${this.newQuickDeposit}
                class=${classMap({
                    'feature-disabled': this.disabled,
                })}
            >
                <span> <jhd-icon .icon=${depositIcon}></jhd-icon> &nbsp; &nbsp;Quick Deposit </span>
            </a>
            <a
                href="javascript:void(0)"
                @click=${this.newBatchDeposit}
                class=${classMap({
                    'feature-disabled': this.disabled,
                })}
            >
                <span> <jhd-icon .icon=${depositIcon}></jhd-icon> &nbsp; &nbsp;Batch Deposit </span>
            </a>
        </tm-card>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    --tm-card-min-height: 135px;
                    --icon-transform-scale: scale(1.2);
                }
                h4 {
                    margin-left: 12px;
                    margin-top: 8px;
                    font-weight: 400;
                    color: var(--header-text-color);
                    font-size: 16px;
                    line-height: 28px;
                }
                a {
                    outline: none;
                    border: none;
                    background: none;
                    cursor: pointer;
                    font-size: 16px;
                    margin: 10px 0 0 12px;
                    text-decoration: none;
                    color: var(--primary-text-color);
                }
                a:hover {
                    background-color: var(--link-background-hover);
                }
                span {
                    display: flex;
                    align-items: center;
                    color: var(--header-text-color);
                    padding: 2px 7px 0 5px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: DepositsWidget;
    }
}
