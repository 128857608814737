import { NavigationService } from '@treasury/core/navigation';
import { LogoutManager } from '@treasury/domain/services/logout';
import '@treasury/omega/components/omega-button.js';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import { InjectProperty } from '@treasury/utils';
import { css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import '../../components/jhd-header-bar';
import { HeaderBarService } from '../../services/jhd-header-bar.service';

export const tagName = 'logout-container';
@customElement(tagName)
export class LogoutContainer extends TmBaseComponent {
    constructor() {
        super();
        this.headerService.configure({ title: 'Confirm Log Out', menuItems: [] });
    }

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare logoutManager: LogoutManager;

    @InjectProperty()
    private declare navService: NavigationService;

    routeToDashboard() {
        this.navService.navigate(`/dashboard`);
    }

    async logOut() {
        await this.logoutManager.logOut('Forced');
        this.navService.navigate(`/login`);
    }

    render() {
        return html`
            <tm-body>
                <p class="text-center py-4">Are you sure you want to log out of the application?</p>
            </tm-body>
            <tm-footer
                .buttonConfig=${[
                    {
                        importance: 'secondary',
                        text: 'Cancel',
                        onClick: () => {
                            this.routeToDashboard();
                        },
                    },
                    {
                        text: 'Log Out',
                        onClick: () => {
                            this.logOut();
                        },
                    },
                ] as ButtonConfig[]}
            >
            </tm-footer>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: LogoutContainer;
    }
}
