import { InternalTransferTransactionFilterModelDto } from '@treasury/api/channel';
import { NavigationService } from '@treasury/core/navigation';
import TransferPaymentsService from '@treasury/domain/channel/services/transfers/transfer-payments-service';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { TmBaseComponent } from '@treasury/presentation';
import { circlePlusIcon, informationIcon } from '@treasury/presentation/assets/icons';
import { InjectProperty } from '@treasury/utils';
import { html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../../components/jhd-route-action-item';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import '../partials/transfer-detail-bottom-sheet';
import '../partials/transfer-payments-filter-sheet';
import '../partials/transfer-payments-table';
import { TransferPaymentDto } from '../types/transfer-payment-dto.type';

export const tagName = 'transfers-container';
@customElement(tagName)
export class TransfersContainer extends TmBaseComponent {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare featureFlagService: FeatureFlagService;

    @InjectProperty()
    private declare navService: NavigationService;

    @state()
    transferPayments: TransferPaymentDto[] = [];

    @state()
    loading = true;

    @state()
    bottomSheetOpen = false;

    @state()
    detailBottomSheetOpen = false;

    @state()
    transfer: TransferPaymentDto | undefined = undefined;

    @state()
    private isPwaBetaBank = false;

    @state()
    private filterSheetOpen = false;

    async firstUpdated() {
        this.isPwaBetaBank = await this.featureFlagService.isEnabled(Feature.IsPwaBetaBank);
        try {
            this.headerService.configure({
                title: 'Transfer Payments',
                menuItems: [
                    {
                        title: 'Info',
                        icon: informationIcon,
                        action: () => alert('TODO'),
                    },
                ],
            });
        } catch (e) {
            console.error(e);
        }
    }

    async filterTransfers(filters: InternalTransferTransactionFilterModelDto) {
        this.loading = true;
        try {
            const transfersResponse = (await TransferPaymentsService.getTransfers(
                filters
            )) as unknown as { data: TransferPaymentDto[]; totalCount: number };
            this.transferPayments = transfersResponse.data;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    renderTransferDetailBottomSheet() {
        if (!this.transfer) return nothing;
        return html`<transfer-detail-bottom-sheet
            .bottomSheetOpen=${this.detailBottomSheetOpen}
            .transfer=${this.transfer}
            @close=${() => {
                this.detailBottomSheetOpen = false;
            }}
            @update-transfer=${() => {
                this.firstUpdated();
                this.detailBottomSheetOpen = false;
            }}
        >
        </transfer-detail-bottom-sheet>`;
    }

    renderTable() {
        return html`<transfer-payments-table
            .transferPayments=${this.transferPayments}
            .loading=${this.loading}
            @transfer-clicked=${(e: CustomEvent) => {
                this.transfer = e.detail;
                this.detailBottomSheetOpen = true;
            }}
            @filter-transfer-payments=${() => {
                this.filterSheetOpen = true;
            }}
        ></transfer-payments-table>`;
    }

    renderFilterSheet() {
        return html`<transfer-payments-filter-sheet
            .open=${this.filterSheetOpen}
            @close=${() => {
                this.filterSheetOpen = false;
            }}
            @applyFilters=${(e: CustomEvent) => {
                this.filterTransfers(e.detail);
            }}
        ></transfer-payments-filter-sheet>`;
    }

    renderActionItem() {
        const route = `transfers/payment/one-to-one/create/new`;
        return html`<jhd-route-action-item
            .icon=${circlePlusIcon}
            .action=${'Create Transfer Payment'}
            @click=${() => {
                this.bottomSheetOpen = true;
                this.navService.navigate(route);
            }}
        ></jhd-route-action-item>`;
    }

    render() {
        return [
            this.renderActionItem(),
            this.renderTable(),
            this.renderTransferDetailBottomSheet(),
            this.renderFilterSheet(),
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TransfersContainer;
    }
}
