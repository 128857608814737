import { CheckException, ExceptionDecisionStatus } from '@treasury/domain/arp';
import { TmBaseComponent } from '@treasury/presentation';
import { formatUsdCurrency } from '@treasury/utils';
import { columnBodyRenderer } from '@vaadin/grid/lit.js';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { JhaApprovalToggleStatus } from '../../../types';
import { CheckExceptionVm } from '../../view-models/checkException';

export const tagName = 'check-exceptions-review-table';
@customElement(tagName)
export class CheckExceptionsReviewTable extends TmBaseComponent {
    @property()
    protected readonly verifiedPropNames: (keyof this)[] = ['checkExceptions'];

    @property()
    checkExceptions!: CheckException[];

    @property()
    confirmation?: boolean;

    renderDecisionColumn(exception: CheckException) {
        const getSelectedState = () => {
            if (exception.decisionStatus === ExceptionDecisionStatus.Pay) {
                return JhaApprovalToggleStatus.Approved;
            }
            if (exception.decisionStatus === ExceptionDecisionStatus.Return) {
                return JhaApprovalToggleStatus.Rejected;
            }
            return JhaApprovalToggleStatus.NA;
        };

        return html`
            <jhd-approval-toggle
                .readonly=${true}
                .selectedState=${getSelectedState()}
            ></jhd-approval-toggle>
        `;
    }

    renderCheckExceptionDetailColumn(exception: CheckException) {
        const vm = new CheckExceptionVm(exception);

        return html`
            <div class="font-bold">${vm.accountNumber}</div>
            <div class="font-light text-sm text-left">
                ${exception.checkNumber} <br />
                Paid: ${formatUsdCurrency(exception.paidAmount)} | Issued:
                ${formatUsdCurrency(exception.issuedAmount)}
            </div>
        `;
    }

    renderCheckExceptionReasonColumn(exception: CheckException) {
        return html`<div class="badge text-sm">${exception.exceptionReason}</div>`;
    }

    render() {
        return html`<div class="w-full max-h-[]">
            <vaadin-grid
                .items=${this.checkExceptions}
                all-rows-visible
                class=${classMap({
                    confirmation: !!this.confirmation,
                })}
            >
                <vaadin-grid-column
                    width="10px"
                    ${columnBodyRenderer(this.renderDecisionColumn)}
                ></vaadin-grid-column>
                <vaadin-grid-column
                    width="30%"
                    ${columnBodyRenderer(this.renderCheckExceptionDetailColumn)}
                >
                </vaadin-grid-column>
                <vaadin-grid-column
                    ${columnBodyRenderer(this.renderCheckExceptionReasonColumn)}
                ></vaadin-grid-column>
            </vaadin-grid>
        </div>`;
    }

    static get styles() {
        return [
            css`
                vaadin-grid-cell-content {
                    padding: 0;
                }

                vaadin-grid {
                    height: calc(var(--below-navbar-view-height) - 132px);
                }

                vaadin-grid.confirmation {
                    height: calc(var(--below-navbar-view-height) - 276px);
                }

                .badge {
                    color: #777;
                    background-color: rgba(230, 230, 230, 0.3);
                    padding: 3px 5px;
                    text-align: center;
                    width: 90%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: CheckExceptionsReviewTable;
    }
}
