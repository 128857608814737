import { Action, FlexDirection, ObservationSource, ScreenUnit } from '@treasury/utils';
import { RootPart } from 'lit';

export enum OmegaDialogExitReason {
    Confirm = 'confirm',
    Cancel = 'cancel',
    ForceClose = 'force',
}

type OmegaButtonType = 'primary' | 'secondary' | 'approve' | 'reject' | 'link' | 'icon';

export enum DialogButtonPosture {
    Primary,
    Secondary,
    Constructive,
    Destructive,
}

export const postureToTypeMap: Record<DialogButtonPosture, OmegaButtonType> = {
    [DialogButtonPosture.Primary]: 'primary',
    [DialogButtonPosture.Secondary]: 'secondary',
    [DialogButtonPosture.Constructive]: 'approve',
    [DialogButtonPosture.Destructive]: 'reject',
};

export interface DialogButton {
    label: string;
    posture: DialogButtonPosture;
    onClick?: Action;
}

export interface OmegaDialogOptionsAll {
    flexDirection: FlexDirection;
    headerIcon?: string;
    height: number;
    heightUnits: ScreenUnit;
    width: number;
    widthUnits: ScreenUnit;
    buttons: { [key in OmegaDialogExitReason]: DialogButton | null };
    host: HTMLElement | DocumentFragment;
    preventClose: boolean;
    renderButtons: boolean;
    logoSource?: string;
}
type OmegaDialogButtonsOptional = { [key in OmegaDialogExitReason]?: Partial<DialogButton> | null };
export type OmegaDialogOptions = Partial<Omit<OmegaDialogOptionsAll, 'buttons'>> & {
    buttons?: OmegaDialogButtonsOptional;
};

export interface OmegaDialogResult<T = unknown> {
    reason: OmegaDialogExitReason;
    data?: T;
}

export interface OmegaDialogHandle<Elem extends HTMLElement, T = unknown> {
    close: (r?: OmegaDialogResult<T>) => void;

    /**
     * Provides a hook for events fired from the components contained within the template
     */
    listenFor: <T>(eventName: string, listener: (e: CustomEvent<T>) => void) => void;
    /**
     * Promise that completes when the dialog has been closed.
     */
    closed: Promise<OmegaDialogResult<T>>;
    element: Elem;
}

/**
 * Data required for bookkeeping open dialogs.
 */
export interface OmegaDialogMetadata<Result> {
    part: RootPart;
    options: OmegaDialogOptionsAll;
    /**
     * Collection of listener removers to call on close.
     */
    listeners: Action[];
    closed: ObservationSource<Result>;
}
