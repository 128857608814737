/* eslint-disable import/no-duplicates */
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-checkbox';
import '@treasury/presentation/components/tm-table-header';
import '@vaadin/grid';
import { GridActiveItemChangedEvent } from '@vaadin/grid';
import { columnBodyRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import '@vaadin/grid/vaadin-grid-selection-column';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { filterTableResults } from '../../../utilities/table-result-filter';
import { Account } from '../types/userAccountAccess.type';

export const tagName = 'user-account-access';
@customElement(tagName)
export class UserAccountAccess extends TmBaseComponent {
    @property({ type: Array })
    public config: any = [];

    @state()
    public filteredAccounts: any = [];

    @property({ type: Array })
    public selectedAccounts: any = [];

    @state()
    private fieldsToFilter = ['name', 'number', 'status', 'type'];

    @state()
    private filterString = '';

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        if (changedProperties.has('config')) {
            this.filteredAccounts = this.config;
        }
    }

    getAccountStatusColor(status: string) {
        switch (status) {
            case 'Active':
                return 'text-gray-700';
            case 'Added':
                return 'text-green-500';
            case 'Inactive':
                return 'text-gray-500';
            case 'Pending':
                return 'text-yellow-500';
            default:
                return '';
        }
    }

    filterResults(e: CustomEvent) {
        this.filterString = e.detail.value;
        this.filteredAccounts = filterTableResults(e, this.config, this.fieldsToFilter);
    }

    updateSelectAllItems(selected: boolean) {
        if (selected) {
            this.selectedAccounts = this.config;
        } else {
            this.selectedAccounts = [];
        }
    }

    updateSelectedAccounts(accounts: any[]) {
        this.selectedAccounts = accounts;
        this.dispatchEvent(
            new CustomEvent('selected-items-changed', {
                detail: this.selectedAccounts,
            })
        );
    }

    renderAccountNameAndNumber(account: Account) {
        return html`<div class="flex flex-col">
            <span class=${`text-sm font-medium ${account.edited ? 'edited' : ''}`}
                >${account.name}</span
            >
            <small class="text-xs">${account.number}</small>
        </div>`;
    }

    renderStatus(account: Account) {
        const statusColor = this.getAccountStatusColor(account.status);
        return html`<div class="flex flex-col">
            <span class=${`text-sm font-medium ${statusColor}`}>${account.status}</span>
            <small class="text-xs">${account.type}</small>
        </div>`;
    }

    renderCheckboxColumn(account: Account) {
        return html`<div class="w-8">
            <tm-checkbox disabled .checked=${account.value}></tm-checkbox>
        </div>`;
    }

    renderTableHeader() {
        return html`<tm-table-header
            .filterButton=${false}
            .filterString=${this.filterString}
            @filterButtonClick=${() => this.dispatchEvent(new CustomEvent('filter-ach-payments'))}
            @filterStringChange=${(e: CustomEvent) => this.filterResults(e)}
        ></tm-table-header>`;
    }

    render() {
        return html` ${this.renderTableHeader()}
            <vaadin-grid
                all-rows-visible
                .items=${this.filteredAccounts}
                @selected-items-changed=${(e: GridActiveItemChangedEvent<Notification[]>) => {
                    if (e.detail.value) this.updateSelectedAccounts(e.detail.value);
                }}
            >
                <vaadin-grid-column
                    auto-width
                    flex-grow="0"
                    ${columnBodyRenderer(this.renderCheckboxColumn)}
                ></vaadin-grid-column>
                <vaadin-grid-column
                    ${columnBodyRenderer(this.renderAccountNameAndNumber)}
                ></vaadin-grid-column
                ><vaadin-grid-column
                    text-align="end"
                    ${columnBodyRenderer(this.renderStatus)}
                ></vaadin-grid-column>
            </vaadin-grid>`;
    }

    static get styles() {
        return [
            css`
                tm-table-header {
                    background-color: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserAccountAccess;
    }
}
