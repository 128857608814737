import { IpRestrictionAddressModelDto } from '@treasury/api/channel';
import { TmBaseComponent } from '@treasury/presentation';
import '@vaadin/grid';
import { columnBodyRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export const tagName = 'user-ip-restrictions';
@customElement(tagName)
export class UserIpRestrictions extends TmBaseComponent {
    @property({ type: Object })
    public config: any = {};

    renderIpAddress(ipRestriction: IpRestrictionAddressModelDto) {
        return html`<div>
            <tm-text-field disabled .value=${ipRestriction.octet1}></tm-text-field> .
            <tm-text-field disabled .value=${ipRestriction.octet2}></tm-text-field> .
            <tm-text-field disabled .value=${ipRestriction.octet3}></tm-text-field> .
            <tm-text-field disabled .value=${ipRestriction.octet4}></tm-text-field>
        </div>`;
    }

    renderIpAddressName(ipRestriction: any) {
        return html`<div class="text-md font-normal">${ipRestriction.name}</div>`;
    }

    renderTable() {
        if (!this.config.ipRestrictions?.length) return nothing;
        return html`<vaadin-grid all-rows-visible .items=${this.config.ipRestrictions}
            ><vaadin-grid-column ${columnBodyRenderer(this.renderIpAddress)}></vaadin-grid-column
            ><vaadin-grid-column
                ${columnBodyRenderer(this.renderIpAddressName)}
                text-align="end"
            ></vaadin-grid-column
        ></vaadin-grid>`;
    }

    renderEmptyState() {
        if (this.config.ipRestrictions?.length) return nothing;
        return html`<div class="empty-state w-full text-center px-3 py-3 italic">
            User has no IP restrictions configured.
        </div>`;
    }

    render() {
        if (!this.config) return nothing;
        return html` <div>
            <h3 class="text-lg pl-2 py-2">Available IP Addresses</h3>
            ${this.renderTable()} ${this.renderEmptyState()}
        </div>`;
    }

    static get styles() {
        return [
            css`
                h3,
                .empty-state {
                    background-color: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserIpRestrictions;
    }
}
