/* eslint-disable lit/binding-positions */
import { NavigationService } from '@treasury/core/navigation';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { TransferService, TransferTemplate } from '@treasury/domain/transfers';
import { TmBaseComponent } from '@treasury/presentation';
import {
    arrowMergeIcon,
    arrowSplitIcon,
    cardsIcon,
    circlePlusIcon,
} from '@treasury/presentation/assets/icons';
import { InjectProperty } from '@treasury/utils';
import { css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';

export const tagName = 'transfer-create-type-step';
@customElement(tagName)
export class TransferCreateTypeStep extends TmBaseComponent {
    constructor() {
        super();
        this.headerService.configure({
            title: 'Create Transfer',
            backAction: () => this.navService.navigate(`/transfers`),
        });
    }

    @state()
    private isPwaBetaBank = false;

    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare featureFlagService: FeatureFlagService;

    @InjectProperty()
    private declare transferService: TransferService;

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @state()
    templates?: TransferTemplate[];

    @state()
    templatesExist = false;

    async firstUpdated() {
        this.isPwaBetaBank = await this.featureFlagService.isEnabled(Feature.IsPwaBetaBank);
    }

    render() {
        return html` <div class="px-4 py-6 font-medium text-sm text-center">
                How would you prefer to make this transfer?
            </div>
            <div class="actions-wrapper py-px">
                <jhd-route-action-item
                    .icon=${circlePlusIcon}
                    .action=${'Create One-to-One Transfer'}
                    .actionDescription=${'Create transfer(s) from one account to another'}
                    @click=${() => {
                        this.dispatchEvent(new CustomEvent('create-one-to-one'));
                    }}
                ></jhd-route-action-item>
                <!-- TODO: Future enhancement for Desktop Parity -->
                <!-- <jhd-route-action-item
                    .icon=${arrowSplitIcon}
                    .disabled=${this.isPwaBetaBank}
                    .action=${'Create One-to-Many Transfer'}
                    .actionDescription=${'Create transfer(s) from one account to many'}
                    .route=${`/transfers/payment/one-to-many/create/new`}
                ></jhd-route-action-item>
                <jhd-route-action-item
                    .icon=${arrowMergeIcon}
                    ?disabled=${this.isPwaBetaBank}
                    .action=${'Create Many-to-One Transfer'}
                    .actionDescription=${'Create transfer(s) from many accounts to one'}
                    .route=${`/transfers/payment/many-to-one/create/new`}
                ></jhd-route-action-item> -->
                <jhd-route-action-item
                    .icon=${cardsIcon}
                    .action=${'Create Transfer from Template'}
                    .actionDescription=${'Create transfer(s) from a template'}
                    .route=${`/transfers/templates/initiate`}
                ></jhd-route-action-item>
            </div>`;
    }

    static get styles() {
        return [
            css`
                path {
                    fill: #ffffff;
                }
                .actions-wrapper {
                    background-color: var(--content-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TransferCreateTypeStep;
    }
}
