/* eslint-disable no-use-before-define */
// cspell:ignore paramtypes
import 'reflect-metadata';
import { isPrimitiveCtor } from '../../functions';
import { Constructor, VariadicFunction } from '../../types';
import { di$ } from '../di-container';
import { InjectConfig } from '../di.types';

/**
 * Fulfills and injects instances of non-primitive parameters
 * of a method's arguments list when invoked. Primitive parameters
 * are passed through as-is.
 */
export function InjectMethod<T, R>(config: Partial<InjectConfig> = {}) {
    let { container } = config;
    if (!container) {
        di$.subscribe(newContainer => {
            container = newContainer;
        }, true);
    }

    return function (
        target: T,
        memberName: string | symbol,
        descriptor: TypedPropertyDescriptor<VariadicFunction<R>>
    ) {
        const method = descriptor.value!;
        const argTypes: Constructor<unknown>[] = Reflect.getMetadata(
            'design:paramtypes',
            target as object,
            memberName
        );

        // explicitly not using an arrow function so `this` context is not permanently bound
        descriptor.value = function (...args: unknown[]) {
            if (!container) {
                throw new Error(
                    `Could not inject method: ${memberName.toString()}. No DI container available.`
                );
            }

            const deps: (unknown | null)[] = argTypes.map(type =>
                isPrimitiveCtor(type) ? null : container!.get(type)
            );
            const merged = mergeDepsWithArgs(deps, args);
            return method.apply(this, merged);
        };
    };
}

/**
 * Merges an array of dependencies and arguments into a single array.
 *
 * @param deps List of possibly resolved dependencies.
 * @param args List of arguments used to call the function.
 * @returns A merged array of arguments, preferring resolved dependencies over passed arguments.
 */
function mergeDepsWithArgs<T>(deps: (T | null)[], args: T[]) {
    return deps.map((dep, i) => (dep === null ? args[i] : dep));
}
