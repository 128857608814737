import { deepEquals, exists } from '@treasury/utils';
import { SelectItem } from '@vaadin/select/src/vaadin-select';
import { html, nothing, PropertyValueMap } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { TmBaseComponent } from '../tm-base.component';
import './tm-currency-field';
import './tm-select';

export enum CurrencyRangeType {
    AllAmounts = 'All Amounts',
    SpecificAmount = 'Specific Amount',
    AmountRange = 'Amount Range',
}

export interface CurrencyRange {
    rangeType: CurrencyRangeType;
    specificAmount?: number;
    floorAmount?: number;
    ceilAmount?: number;
}

export const tagName = 'tm-currency-range';
@customElement(tagName)
export class TmCurrencyRange extends TmBaseComponent {
    @property({ type: String })
    label = 'Amount';

    @property({ type: Array })
    currencyRangeOptions: CurrencyRangeType[] = [
        CurrencyRangeType.AllAmounts,
        CurrencyRangeType.SpecificAmount,
        CurrencyRangeType.AmountRange,
    ];

    @property({ type: Object })
    range: CurrencyRange = {
        rangeType: CurrencyRangeType.AllAmounts,
    };

    @state()
    rangeType?: CurrencyRangeType;

    @state()
    specificAmount?: number;

    @state()
    floorAmount?: number;

    @state()
    ceilAmount?: number;

    get rangeOptionsForSelect() {
        const rangeOptions: SelectItem[] = [];
        for (const rangeOption of this.currencyRangeOptions) {
            rangeOptions.push({
                label: rangeOption,
                value: rangeOption,
            });
        }
        return rangeOptions;
    }

    get isValidCurrencyRange() {
        if (!this.rangeType) return false;
        switch (this.rangeType) {
            case CurrencyRangeType.SpecificAmount:
                return this.specificAmount! > 0;
            case CurrencyRangeType.AmountRange:
                return this.floorAmount! <= this.ceilAmount! && this.ceilAmount! > 0;
            default:
                return true;
        }
    }

    protected updated(changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        // If the range is updated, but has the same values as before then don't parse out the individual properties again. Dispatches redundant @selection events
        if (
            changedProperties.has('range') &&
            this.range &&
            (!deepEquals(changedProperties.get('range'), this.range) || !this.isValidCurrencyRange)
        ) {
            this.parseRange();
        }
    }

    parseRange() {
        const { rangeType, specificAmount, floorAmount, ceilAmount } = this.range;
        this.rangeType = rangeType;
        this.specificAmount = specificAmount;
        this.floorAmount = floorAmount;
        this.ceilAmount = ceilAmount;
    }

    dispatchSelectionEvent() {
        let detail: CurrencyRange | false = false;
        if (this.isValidCurrencyRange) {
            const { rangeType, specificAmount, floorAmount, ceilAmount } = this;
            detail = {
                rangeType,
                ...(specificAmount && { specificAmount }),
                ...(exists(floorAmount) && { floorAmount }),
                ...(ceilAmount && { ceilAmount }),
            } as CurrencyRange;
        }

        this.dispatchEvent(new CustomEvent('selection', { detail }));
    }

    updateAmounts() {
        if (!this.rangeType) return;

        this.specificAmount = undefined;
        this.floorAmount = undefined;
        this.ceilAmount = undefined;

        switch (this.rangeType as CurrencyRangeType) {
            case CurrencyRangeType.SpecificAmount:
                this.specificAmount = 0;
                break;
            case CurrencyRangeType.AmountRange:
                this.floorAmount = 0;
                this.ceilAmount = 0;
                break;
        }
    }

    renderRangeTypeSelect() {
        return html` <tm-select
            .label=${this.label}
            .items=${this.rangeOptionsForSelect}
            .value=${this.rangeType}
            @value-changed=${(e: CustomEvent) => {
                if (e.detail.value) {
                    this.rangeType = e.detail.value as CurrencyRangeType;
                    this.updateAmounts();
                    this.dispatchSelectionEvent();
                }
            }}
        ></tm-select>`;
    }

    renderSpecificAmountField() {
        if (this.rangeType !== CurrencyRangeType.SpecificAmount) return nothing;
        return html` <tm-currency-field
            .value=${this.specificAmount ? this.specificAmount?.toString() : '0.00'}
            @value-changed=${(e: CustomEvent) => {
                this.specificAmount = e.detail;
                this.dispatchSelectionEvent();
            }}
        ></tm-currency-field>`;
    }

    renderFloorAmountField() {
        return html` <tm-currency-field
            .value=${this.floorAmount ? this.floorAmount?.toString() : '0.00'}
            @value-changed=${(e: CustomEvent) => {
                this.floorAmount = e.detail;
                this.dispatchSelectionEvent();
            }}
            .invalid=${!this.isValidCurrencyRange && this.floorAmount! > 0}
            class="min-w-0 w-full"
        ></tm-currency-field>`;
    }

    renderCeilAmountField() {
        return html` <tm-currency-field
            .value=${this.ceilAmount ? this.ceilAmount?.toString() : '0.00'}
            @value-changed=${(e: CustomEvent) => {
                this.ceilAmount = e.detail;
                this.dispatchSelectionEvent();
            }}
            .invalid=${!this.isValidCurrencyRange && this.floorAmount! > 0}
            class="min-w-0 w-full"
        ></tm-currency-field>`;
    }

    renderRangedAmountFields() {
        if (this.rangeType !== CurrencyRangeType.AmountRange) return nothing;
        return html` <div class="flex items-center">
            ${this.renderFloorAmountField()}
            <div class="px-2">-</div>
            ${this.renderCeilAmountField()}
        </div>`;
    }

    render() {
        return [
            this.renderRangeTypeSelect(),
            this.renderSpecificAmountField(),
            this.renderRangedAmountFields(),
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmCurrencyRange;
    }
}
