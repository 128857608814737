/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { RdcSsoLoginResponseDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class ReceivablesClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Receivables
     * @name ReceivablesGetRemoteDepositCaptureSsoLogin
     * @summary Gets the remote deposit capture sso login.
     * @request GET:/receivables/getRemoteDepositCaptureSsoLogin
     */
    receivablesGetRemoteDepositCaptureSsoLogin = (params: RequestParams = {}) =>
        this.http.request<RdcSsoLoginResponseDto, any>({
            path: `/receivables/getRemoteDepositCaptureSsoLogin`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
