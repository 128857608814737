import { NavigationService } from '@treasury/core/navigation';
import { MessagesService } from '@treasury/domain/channel/services';
import '@treasury/omega/components/omega-button.js';
import { NotificationService, TmBaseComponent } from '@treasury/presentation';
import { informationIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-blocking-loader';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import '../partials/compose-message-form';
import type { Message } from '../types/message.types';

export const tagName = 'compose-message-container';
@customElement(tagName)
export class ComposeMessageContainer extends TmBaseComponent {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @InjectProperty()
    private declare readonly service: MessagesService;

    @state()
    private loading = false;

    @state()
    private messageDetails: Message[] = [];

    firstUpdated() {
        this.headerService.configure({
            backAction: () => this.navService.navigate(`/message-center`),
            title: 'New Message',
            menuItems: [
                {
                    title: 'Information',
                    icon: informationIcon,
                    action: () => console.log('Clicked'),
                },
            ],
        });
    }

    async sendMessage(e: CustomEvent) {
        const message = e.detail;
        this.loading = true;
        try {
            await this.service.sendMessage(message);
        } catch (err) {
            this.notificationService.renderError(err as Error);
        } finally {
            this.loading = false;
            this.navService.navigate(`/message-center`);
        }
    }

    renderBlockingLoader() {
        if (!this.loading) return nothing;
        return html`<tm-blocking-loader></tm-blocking-loader>`;
    }

    renderComposeMessageForm() {
        if (this.loading) return nothing;
        return html` <compose-message-form
            .message=${this.messageDetails}
            @send-message=${(e: CustomEvent) => this.sendMessage(e)}
            @cancel-message=${() => this.navService.navigate(`/message-center`)}
        ></compose-message-form>`;
    }

    render() {
        return [this.renderBlockingLoader(), this.renderComposeMessageForm()];
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: ComposeMessageContainer;
    }
}
