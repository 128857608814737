/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-useless-constructor */
import 'reflect-metadata';
import { exists } from '../functions';
import { getDependencies, getTokenName, isSymbolToken } from './di.helpers';
import { DiToken } from './di.types';

export class DependencyNode<T = unknown> {
    constructor(public readonly token: DiToken<T>) {}

    public children: DependencyNode<unknown>[] = [];

    public readonly name = getTokenName(this.token);
}

export type GraphTraversalCallback = (node: DependencyNode<unknown>) => void;

/**
 * Maximum number of times the dependency algorithm can loop to create
 * a valid graph. Places a hard cap to prevent stack overflow scenarios that
 * result in browser unresponsiveness and instead results in an error.
 */
const RECURSION_LIMIT = 100;

export class DependencyGraph<T> {
    constructor(tokenOrRoot: DiToken<T> | DependencyNode<T>) {
        this.root =
            tokenOrRoot instanceof DependencyNode
                ? tokenOrRoot
                : createDependencyGraph(tokenOrRoot);
        this.cycle = detectCircularDependencies(this.root);
    }

    public readonly root: DependencyNode<T>;

    public readonly cycle?: string[];

    public get hasCycle() {
        return !!this.cycle;
    }

    /**
     * Visit each node in the graph in a breadth-first manner starting with the root.
     *
     * @param fn Function to invoke against each node.
     * @param visitMulti If `false`, nodes are only ever visited once even if they appear in the graph at multiple points.
     */
    public traverse(fn: GraphTraversalCallback, visitMulti = false) {
        if (this.cycle) {
            throw new Error(`Cannot traverse the graph; there is a cycle: ${this.cycle}`);
        }

        const queue: DependencyNode<unknown>[] = [this.root];

        /**
         * Tracks which nodes have already been touched.
         */
        const explored = new Set<DependencyNode<unknown>>();

        do {
            const node = queue.shift();
            if (!node) {
                return;
            }
            fn(node);

            node.children.forEach(child => {
                if (explored.has(child) && !visitMulti) {
                    return;
                }

                explored.add(child);
                queue.push(child);
            });
        } while (queue.length > 0);
    }

    /**
     * Produces a list of dependencies needed to instantiate the `root` token (inclusive)
     * as if the graph was [topologically sorted](https://en.wikipedia.org/wiki/Topological_sorting).
     */
    public createDependencyStack() {
        if (this.cycle) {
            throw new Error(
                `Cannot create a dependency stack; there is a cycle in the graph: ${this.cycle}`
            );
        }

        const depStack: DependencyNode[] = [];
        /**
         * Tracks the number of times a `DependencyNode` occurs in `depStack` for later pruning.
         */
        const occurrences = new Map<DependencyNode, number>();

        this.traverse(node => {
            const count = occurrences.get(node) || 0;
            occurrences.set(node, count + 1);
            depStack.push(node);
        }, true);

        // prune duplicates
        return (
            depStack
                .filter(node => {
                    const count = occurrences.get(node) || 1;
                    if (count === 1) {
                        return true;
                    }

                    occurrences.set(node, count - 1);
                    return false;
                })
                // ensure array is ordered from nodes with least dependencies to most
                .reverse()
        );
    }
}

function createDependencyGraph<T>(rootToken: DiToken<T>) {
    const rootNode = new DependencyNode(rootToken);
    const nodesList: DependencyNode<unknown>[] = [];
    /**
     * Nodes that have been created but possibly
     * not had their child dependencies determined.
     */
    const seenNodes = new Map<DiToken<any>, DependencyNode>([[rootNode.token, rootNode]]);

    /**
     * Nodes which have been both instantiated and had their child
     * dependencies linked.
     */
    const resolvedNodes = new Set<DependencyNode>();

    let count = 0;
    let currentNode: DependencyNode | undefined = rootNode;

    while (exists(currentNode) && count < RECURSION_LIMIT) {
        const childNodes = getChildNodes(currentNode);
        nodesList.push(...childNodes);
        currentNode = nodesList.shift();
        count++;

        if (count >= RECURSION_LIMIT) {
            throw new Error(
                `Reached recursion limit of ${RECURSION_LIMIT} while trying to resolve ${rootNode.name}. Aborting.`
            );
        }
    }

    return rootNode;

    function getChildNodes<T>(depNode: DependencyNode<T>) {
        if (resolvedNodes.has(depNode)) {
            // if this node has already been resolved,return an empty array
            // to prevent infinitely adding to the processing queue
            return [];
        }

        const { token } = depNode;
        const dependencies = isSymbolToken(token) ? [] : getDependencies(token);
        const childNodes = dependencies.map(dep => {
            if (seenNodes.has(dep)) {
                return seenNodes.get(dep) as DependencyNode;
            }

            const childNode = new DependencyNode(dep);
            seenNodes.set(dep, childNode);

            return childNode;
        });

        depNode.children = childNodes;
        resolvedNodes.add(depNode);

        return childNodes;
    }
}

/**
 * Checks the graph of dependencies for cycles starting with the provided node.
 *
 * @returns An array of strings describing the cycle if one is found.
 */
function detectCircularDependencies(node: DependencyNode) {
    /**
     * Tracks which nodes have already been seen.
     */
    const explored = new Set<DependencyNode>();
    /**
     * Tracks which nodes are currently being evaluated as part of a dependency chain.
     */
    const evaluating = new Set<DependencyNode>();

    return visit(node);

    function visit(node: DependencyNode): string[] | undefined {
        const { children } = node;
        explored.add(node);
        evaluating.add(node);

        for (let i = 0; i < children.length; i++) {
            const child = children[i];

            if (!explored.has(child)) {
                const cycle = visit(child);
                if (cycle) {
                    return cycle;
                }
            } else if (evaluating.has(child)) {
                return [...evaluating, child].map(n => n.name);
            }
        }

        evaluating.delete(node);

        return undefined;
    }
}
