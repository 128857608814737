import { css, html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { TmBaseComponent } from '../tm-base.component';

export const tagName = 'tm-action-button';
@customElement(tagName)
export class TmActionButton extends TmBaseComponent {
    @property({ type: String })
    public label = '';

    @property({ type: Object })
    public icon: TemplateResult<2> | undefined;

    @property({ type: Number })
    public approvals = 0;

    @property({ type: Function })
    public action: () => void = () => null;

    @property({ type: Function })
    public keyup: () => void = () => null;

    @property({ type: Boolean })
    public disabled = false;

    render() {
        return html`<div
            class=${classMap({
                'flex flex-col items-center relative w-24 p-5': true,
                'feature-disabled': this.disabled,
            })}
            @click=${() => this.action()}
            @keyup=${() => this.keyup()}
        >
            <div class="action-button-icon w-8 mb-1">${this.icon}</div>
            <div class="absolute top-2.5 right-3.5">
                <div
                    class=${classMap({
                        'h-5 px-2 text-white text-xs leading-5 text-center whitespace-nowrap rounded-xl align-middle':
                            true,
                        'bg-[--tm-action-button-badge-color]': this.approvals === 0,
                        'bg-[--tm-action-button-badge-active-color]': this.approvals !== 0,
                    })}
                >
                    ${this.approvals}
                </div>
            </div>
            <div class="text-center">${this.label}</div>
        </div>`;
    }

    static get styles() {
        return [
            css`
                .action-button-icon svg {
                    width: 100%;
                    height: auto;
                }
                .action-button-icon path {
                    fill: var(--primary-action-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmActionButton;
    }
}
