import { AchBatchFilterModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';
import { AchCompany } from './company.entity';
import { defaultAchFilterDto } from './data/ach.data';

export class AchFilter extends DomainEntity<AchBatchFilterModelDto> {
    constructor(protected dto = defaultAchFilterDto) {
        super(dto);
    }

    private achCompanyList = this.dto.achCompanyList.map(c => new AchCompany(c));

    public createDefault(): this {
        return new AchFilter() as this;
    }

    public get status() {
        return this.dto.status;
    }

    public set status(status) {
        this.dto.status = status;
    }

    public get achCompanies() {
        return this.achCompanyList;
    }

    public set achCompanies(achCompanies) {
        this.achCompanyList = achCompanies;
        this.dto.achCompanyList = this.achCompanies.map(c => c.toDto());
    }

    public get templateName() {
        return this.dto.batchName;
    }

    public set templateName(batchName) {
        this.dto.batchName = batchName;
    }

    public get secCode() {
        return this.dto.secCode;
    }

    public set secCode(secCode) {
        this.dto.secCode = secCode;
    }

    public get debitAmountType() {
        return this.dto.debitAmountType;
    }

    public set debitAmountType(debitAmountType) {
        this.dto.debitAmountType = debitAmountType;
    }

    public get debitAmount() {
        return this.dto.debitAmount;
    }

    public set debitAmount(debitAmount) {
        this.dto.debitAmount = debitAmount;
    }

    public get debitAmountMin() {
        return this.dto.debitAmountMin;
    }

    public set debitAmountMin(debitAmountMin) {
        this.dto.debitAmountMin = debitAmountMin;
    }

    public get debitAmountMax() {
        return this.dto.debitAmountMax;
    }

    public set debitAmountMax(debitAmountMax) {
        this.dto.debitAmountMax = debitAmountMax;
    }

    public get creditAmountType() {
        return this.dto.creditAmountType;
    }

    public set creditAmountType(creditAmountType) {
        this.dto.creditAmountType = creditAmountType;
    }

    public get creditAmount() {
        return this.dto.creditAmount;
    }

    public set creditAmount(creditAmount) {
        this.dto.creditAmount = creditAmount;
    }

    public get creditAmountMin() {
        return this.dto.creditAmountMin;
    }

    public set creditAmountMin(creditAmountMin) {
        this.dto.creditAmountMin = creditAmountMin;
    }

    public get creditAmountMax() {
        return this.dto.creditAmountMax;
    }

    public set creditAmountMax(creditAmountMax) {
        this.dto.creditAmountMax = creditAmountMax;
    }

    public get updatedDateType() {
        return this.dto.updatedDateType;
    }

    public set updatedDateType(updatedDateType) {
        this.dto.updatedDateType = updatedDateType;
    }

    public get updatedDate() {
        return this.dto.updatedDate;
    }

    public set updatedDate(updatedDate) {
        this.dto.updatedDate = updatedDate;
    }

    public get lastUpdatedStart() {
        return this.dto.lastUpdatedStart;
    }

    public set lastUpdatedStart(lastUpdatedStart) {
        this.dto.lastUpdatedStart = lastUpdatedStart;
    }

    public get lastUpdatedEnd() {
        return this.dto.lastUpdatedEnd;
    }

    public set lastUpdatedEnd(lastUpdatedEnd) {
        this.dto.lastUpdatedEnd = lastUpdatedEnd;
    }
}
