import { html, nothing } from 'lit';

function renderRadioButtons(context: any) {
    if (!context.verificationMethod.other.isVerified) return nothing;
    return html`<fieldset>
        <legend>What is your preferred method of verification?</legend>
        <label
            ><input
                type="radio"
                name="method"
                .value=${context.verificationMethod.id}
                checked
                @input=${() => context.preferredMethod == context.verificationMethod.id}
            />${context.verificationMethod.label}</label
        >
        <label
            ><input
                type="radio"
                name="method"
                .value=${context.verificationMethod.other.id}
                @input=${() => context.preferredMethod == context.verificationMethod.other.id}
            />${context.verificationMethod.other.label}</label
        >
    </fieldset>`;
}

export default {
    title: 'Verify Phone Number',

    renderBody(context: any) {
        return html` <style>
                .info-box {
                    border: 1px solid #34aa44;
                    border-left: 4px solid #34aa44;
                    padding: 8px 15px;
                    margin-bottom: 15px;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                }
                omega-icon {
                    margin-right: 16px;
                    color: #34aa44;
                    font-size: 24px;
                }
                fieldset {
                    border: none;
                    padding: 8px 0;
                    margin: 0;
                }
                legend {
                    padding: 0;
                    margin: 0;
                }
                .phone {
                    margin: 24px 0 18px;
                }
            </style>
            <div class="info-box"><omega-icon icon="check-circle"></omega-icon>Success</div>
            <div class="phone">Phone Number: ${context.phoneNumber?.formatted}</div>
            ${renderRadioButtons(context)}`;
    },

    renderActions(context: any) {
        if (!context.verificationMethod.other.phoneNumber) {
            return html`<tm-button importance="primary" @click=${context.finish}>Done</tm-button>`;
        }

        return html`<tm-button importance="primary" @click=${context.requestOtherContactMethod}
                >Add ${context.verificationMethod.other.label} Number</tm-button
            >

            <tm-button @click=${context.finish}>Done</tm-button> `;
    },
};
