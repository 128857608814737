/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-use-before-define */

import { ReactiveController, ReactiveElement } from 'lit';
import { exists, isSubclassOf } from '../../../functions';
import { Constructor } from '../../../types';
import { createArrayProxy, createObjectProxy, unwrapReactive } from './deep-reactive.helpers';

type ProxyRevoker = ReturnType<ProxyConstructor['revocable']>['revoke'];

/**
 * Provides Lit reactivity on an object when one of its properties is mutated
 * without the need to re-assign the entire reference.
 */
export function DeepReactive<
    Element extends ReactiveElement = ReactiveElement,
    T extends object = object,
>() {
    return function (target: Element, propName?: string) {
        if (!propName) {
            throw new Error('Directive DeepReactive must be used on a class property');
        }

        const isReactive = isSubclassOf(ReactiveElement, target.constructor as Constructor);
        const className = target.constructor.name;

        if (!isReactive) {
            throw new Error(
                `Cannot decorate property "${propName}" with DeepReactive. The class ${className} is not a ReactiveElement.`
            );
        }

        const ctor = target.constructor as typeof ReactiveElement;
        // eslint-disable-next-line arrow-body-style
        ctor.addInitializer(instance => {
            return new ReactiveObjectController<Element, T>(
                instance as Element,
                propName as keyof Element
            );
        });
    };
}

class ReactiveObjectController<Element extends ReactiveElement, T extends object>
    implements ReactiveController
{
    constructor(
        private readonly host: Element,
        private readonly hostPropName: keyof Element
    ) {
        host.addController(this);
    }

    /**
     * Not used to track changes on the object itself, but to
     * determine by reference equality if a new object has been set.
     *
     * Emulates `@state()` decorator behavior.
     */
    private lastValue?: T;

    private isHostConnected = false;

    /**
     * Track created proxies to prevent re-wrapping.
     */
    private proxies = new WeakSet<ProxyConstructor>();

    public hostUpdated(): void {
        const hostTarget = this.host[this.hostPropName] as T;

        // don't re-wrap an already proxy'd value
        if (this.proxies.has(hostTarget as typeof Proxy)) {
            return;
        }

        // create a proxy only for new values
        if (exists(hostTarget) && hostTarget !== this.lastValue) {
            const { proxy } = this.createProxy();
            this.proxies.add(proxy as typeof Proxy);
            this.host[this.hostPropName] = proxy as Element[keyof Element];
            this.updateElement(hostTarget);
        }

        // store a reference to compare against new values
        this.lastValue = hostTarget as T;
    }

    public hostConnected() {
        this.isHostConnected = true;
    }

    public hostDisconnected() {
        this.isHostConnected = false;
    }

    private updateElement(oldValue: T | T[] | null, postFix = '') {
        if (!this.isHostConnected) {
            return;
        }

        this.host.requestUpdate(this.hostPropName.toString() + postFix, oldValue);
    }

    private createProxy() {
        const target = this.host[this.hostPropName] as T;
        if (Array.isArray(target)) {
            return createArrayProxy(
                target as T[],
                (arrTarget, oldTarget, propNameOrIndex) => {
                    this.updateElement(oldTarget);
                },
                element => {
                    const index = target.findIndex(
                        proxyElem => unwrapReactive(proxyElem) === element
                    );
                    const postFix = index > -1 ? `[${index}]` : undefined;
                    this.updateElement(element, postFix);
                }
            );
        }

        return createObjectProxy(target, (target, oldTarget) => {
            this.updateElement(oldTarget);
        });
    }
}
