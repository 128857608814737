export const wireTemplateStatusItems = [
    {
        label: 'Pending Approval',
        value: {
            name: 'Pending Approval',
            isChecked: true,
        },
    },
    {
        label: 'Approval Rejected',
        value: {
            name: 'Approval Rejected',
            isChecked: true,
        },
    },
    {
        label: 'Ready',
        value: {
            name: 'Ready',
            isChecked: true,
        },
    },
];
