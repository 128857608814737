import {
    CompleteSecureTokenConfigRequestModelDto,
    SecurityQuestionModelDto,
    UserModelDto,
    UserPasswordModelDto,
} from '@treasury/api/channel';
import { AccountService } from '@treasury/domain/channel/services';
import TermsAndConditionsService from '@treasury/domain/channel/services/account/terms-and-conditions-service';
import { AuthenticationService } from '@treasury/domain/services/authentication';
import { Injectable } from '@treasury/utils/dependency-injection/';

@Injectable()
export class LoginClient {
    // eslint-disable-next-line no-useless-constructor
    constructor(
        private readonly authService: AuthenticationService,
        private readonly accountService: AccountService
    ) {}

    login(credentials: {
        institution: string;
        companyId: string;
        loginId: string;
        password: string;
    }) {
        return this.accountService.login(credentials);
    }

    async checkLoginCompletion() {
        const response = await this.accountService.checkLoginCompletion();
        const { isComplete } = response;

        if (isComplete) {
            this.authService.authenticate();
        }

        return response;
    }

    saveSecurityQuestions(securityQuestions: SecurityQuestionModelDto[]) {
        return this.accountService.saveSecurityQuestions(securityQuestions);
    }

    verifySecurityQuestions(securityQuestionsPayload: SecurityQuestionModelDto[]) {
        return this.accountService.verifySecurityQuestions(securityQuestionsPayload);
    }

    registerSecureToken(actionType: number, credentialId?: string, token?: string, pin?: string) {
        return this.accountService.registerSecureToken(credentialId, token, pin);
    }

    forgotPassword(user: UserModelDto) {
        return this.accountService.forgotPassword(user);
    }

    async sendOneTimePassword(phone: string, method: string, useForBoth: boolean) {
        return this.accountService.sendOneTimePassword(phone, method, useForBoth);
    }

    async validateOneTimePassword(oneTimePassword: string) {
        return this.accountService.validateOneTimePassword(oneTimePassword);
    }

    async setOutOfBandPreferredMethod(method: string) {
        return this.accountService.sendOutOfBandPreferredMethod(method);
    }

    async oobRemindMeLater() {
        return this.accountService.oobRemindMeLater();
    }

    verifyUser(user: UserModelDto) {
        return this.accountService.verifyUser(user);
    }

    passwordRequirements(institution: string) {
        return this.accountService.getPasswordRequirements(institution);
    }

    updatePassword(user: UserModelDto) {
        return this.accountService.updatePassword(user);
    }

    fetchOutOfBandConfig() {
        return this.accountService.getSecurityConfig();
    }

    async removeVerificationMethod(methodType: string) {
        return this.accountService.removeVerificationMethod(methodType);
    }

    completeOutOfBandConfig() {
        return this.accountService.completeOutOfBandConfiguration();
    }

    getTermsAndConditions() {
        return TermsAndConditionsService.getTermsAndConditions();
    }

    checkTermsAndConditions() {
        return TermsAndConditionsService.checkTermsAndConditions();
    }
}
