import { UserAccessDay } from '@treasury/domain/users/product-features';
import { TmBaseComponent } from '@treasury/presentation';
import { formatTwelveHourTime } from '@treasury/utils';
import '@vaadin/grid';
import { columnBodyRenderer, GridColumnBodyLitRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

export const tagName = 'user-time-access';
@customElement(tagName)
export class UserTimeAccess extends TmBaseComponent {
    @property({ type: Object })
    public config: any = {};

    renderDayAndAccess(day: UserAccessDay) {
        return html`<div class="flex flex-col">
            <span>${day.label}</span>
        </div>`;
    }

    renderStartAndEndTime(day: UserAccessDay) {
        if (!day.startTime.value && !day.endTime.value) return html`<span>Allowed All Day</span>`;
        return html`<div class="flex flex-col">
            <span
                class=${classMap({
                    'text-sm': true,
                    'font-normal': true,
                    edited: day.startTime.edited,
                })}
                >Start: ${formatTwelveHourTime(day.startTime.value)}</span
            ><span
                class=${classMap({
                    'text-sm': true,
                    'font-normal': true,
                    edited: day.startTime.edited,
                })}
                >End: ${formatTwelveHourTime(day.endTime.value)}</span
            >
        </div>`;
    }

    render() {
        return html` <vaadin-grid all-rows-visible .items=${this.config?.accessDays}
            ><vaadin-grid-column ${columnBodyRenderer(this.renderDayAndAccess)}></vaadin-grid-column
            ><vaadin-grid-column
                text-align="end"
                ${columnBodyRenderer(this.renderStartAndEndTime)}
            ></vaadin-grid-column
        ></vaadin-grid>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserTimeAccess;
    }
}
