/* eslint-disable no-restricted-globals */
import { PaymentTypes } from '@treasury/domain/channel/types/payment';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-table-header';
import '@vaadin/grid';
import { columnBodyRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-selection-column.js';
import '@vaadin/grid/vaadin-grid-sort-column.js';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { filterTableResults } from '../../../utilities/table-result-filter';
import '../../ach/partials/ach-detail-bottom-sheet';
import '../../components/jhd-approval-toggle';
import '../../loans/partials/loan-detail-bottom-sheet';
import '../../transfers/partials/transfer-detail-bottom-sheet';
import '../../users/partials/user-detail-bottom-sheet';
import '../../wires/wires/partials/wire-detail-bottom-sheet';
import { PendingAchPaymentViewModel } from '../data/pending-ach-view-model';
import { PendingLoanViewModel } from '../data/pending-loan-view-model';
import { PendingTransferViewModel } from '../data/pending-transfer-view-model';
import { PendingUserViewModel } from '../data/pending-user-view-model';
import { PendingWireViewModel } from '../data/pending-wire-view-model';

export const tagName = 'pending-approvals-table';
@customElement(tagName)
export class PendingApprovalsTable extends TmBaseComponent {
    @state()
    loading = true;

    @property({ type: Boolean })
    public isReviewing = false;

    @property({ type: Boolean })
    public isConfirming = false;

    @property({ type: Array })
    transfers = [];

    @property({ type: Array })
    loans = [];

    @property({ type: Array })
    wires = [];

    @property({ type: Array })
    ach = [];

    @property({ type: Array })
    users = [];

    @property({ type: String })
    activeTab = '';

    @property()
    institution = '';

    @state()
    selectedItems = [] as any[];

    @state()
    activeItems = [] as any[];

    @state()
    private filteredItems = [] as any[];

    @state()
    wireDetailsOpen = false;

    @state()
    transferDetailsOpen = false;

    @state()
    loanDetailsOpen = false;

    @state()
    userDetailsOpen = false;

    @state()
    achDetailsOpen = false;

    @state()
    selectedPayment: any;

    @state()
    disableReview = true;

    @state()
    selectAll = false;

    get fieldsToFilter() {
        switch (this.activeTab) {
            case PaymentTypes.ACH:
                return ['value.name', 'value.transactionId', 'creditAmount', 'debitAmount'];
            case PaymentTypes.Wire:
                return ['value.beneficiaryName', 'value.debitAccount', 'amount'];
            case PaymentTypes.Loan:
                return ['value.toAccount', 'value.fromAccount', 'amount'];
            case PaymentTypes.Transfer:
                return ['value.fromAccount', 'value.toAccount', 'amount'];
            default:
                return ['value.userName', 'value.userId', 'value.status'];
        }
    }

    updated(changedProps: Map<string | number | symbol, unknown>) {
        if (changedProps.has('loading')) {
            this.getActiveItems();
        }
    }

    get tabTitle() {
        switch (this.activeTab) {
            case PaymentTypes.ACH:
                return 'ACH Payments';
            case `${PaymentTypes.Wire}`:
                return 'Wires';
            case `${PaymentTypes.Transfer}`:
                return 'Transfers';
            case `${PaymentTypes.Loan}`:
                return 'Loans';
            default:
                return 'Users';
        }
    }

    get emptyStateText() {
        switch (this.activeTab) {
            case PaymentTypes.Loan:
                return 'loan';
            case PaymentTypes.ACH:
                return 'ACH';
            case PaymentTypes.Transfer:
                return 'transfer';
            case PaymentTypes.User:
                return 'user';
            case PaymentTypes.Wire:
                return 'wire';
            default:
                return '';
        }
    }

    getActiveItems() {
        switch (this.activeTab) {
            case PaymentTypes.ACH:
                this.activeItems = this.ach;
                break;
            case `${PaymentTypes.Wire}`:
                this.activeItems = this.wires;
                break;
            case `${PaymentTypes.Transfer}`:
                this.activeItems = this.transfers;
                break;
            case `${PaymentTypes.Loan}`:
                this.activeItems = this.loans;
                break;
            default:
                this.activeItems = this.users;
                break;
        }
        this.filteredItems = this.activeItems;
    }

    get footerButtonConfig(): ButtonConfig[] {
        const buttonConfig: ButtonConfig[] = [
            {
                text: `Review (${this.selectedItems.length})`,
                disabled: this.disableReview,
                onClick: () => {
                    this.dispatchEvent(
                        new CustomEvent('review-approvals', { detail: this.selectedItems })
                    );
                },
                classes: 'w-full basis-full',
            },
        ];

        if (
            this.activeTab !== PaymentTypes.User &&
            !(this.activeItems.length === 0 || this.isReviewing || this.isConfirming)
        ) {
            const actionButtons: ButtonConfig[] = this.selectAll
                ? [
                      {
                          text: 'Cancel',
                          importance: 'secondary',
                          onClick: () => {
                              this.approveOrRejectAllItems('na');
                              this.selectAll = false;
                          },
                          classes: 'mb-2 pr-2',
                      },
                      {
                          text: 'Approve All',
                          success: true,
                          onClick: () => this.approveOrRejectAllItems('Approved'),
                          classes: 'mb-2 pr-2',
                      },
                      {
                          text: 'Reject All',
                          error: true,
                          onClick: () => this.approveOrRejectAllItems('Rejected'),
                          classes: 'mb-2',
                      },
                  ]
                : [
                      {
                          text: 'Select All',
                          importance: 'secondary',
                          onClick: () => (this.selectAll = true),
                          classes: 'mb-2',
                      },
                  ];

            buttonConfig.unshift(...actionButtons);
        }

        return buttonConfig;
    }

    updateSelectedItems(selectedState: string, item: any) {
        if (!item) {
            this.disableReview = true;
            return;
        }

        if (selectedState !== 'na') {
            item.selectedState = selectedState;
            this.selectedItems = [...this.selectedItems, item].filter(
                (i: any) => i.value.entityId !== item.value.entityId
            );
            this.selectedItems = [...this.selectedItems, item];
            // this.selectedItems = [...new Map(this.selectedItems.map((i: any) => [i.value.id, i]))];
        }
        if (selectedState === 'na') {
            this.selectedItems = this.selectedItems.filter(
                (i: any) => i.value.entityId !== item.value.entityId
            );
        }
        if (this.selectedItems.length > 0) {
            this.disableReview = false;
        } else {
            this.disableReview = true;
        }
        this.activeItems.find(i => i.value.entityId === item.value.entityId).selectedState =
            selectedState;
        this.activeItems = [...this.activeItems];
    }

    approveOrRejectAllItems(action: string) {
        this.filteredItems = this.activeItems.map((item: any) => {
            item.selectedState = action;
            return item;
        });
        if (action === 'na') {
            this.selectedItems = [];
            this.disableReview = true;
        } else {
            this.selectedItems = this.filteredItems;
            this.disableReview = false;
        }
    }

    showPendingPaymentDetails(payment: any) {
        if (this.isConfirming) return;
        this.selectedPayment = payment;
        if (!payment) return;
        switch (this.activeTab) {
            case PaymentTypes.ACH:
                this.achDetailsOpen = true;
                break;
            case `${PaymentTypes.Wire}`:
                this.wireDetailsOpen = true;
                break;
            case `${PaymentTypes.Transfer}`:
                this.transferDetailsOpen = true;
                break;
            case `${PaymentTypes.Loan}`:
                this.loanDetailsOpen = true;
                break;
            default:
                this.userDetailsOpen = true;
        }
    }

    renderToggleColumn(
        item:
            | PendingAchPaymentViewModel
            | PendingWireViewModel
            | PendingLoanViewModel
            | PendingTransferViewModel
            | PendingUserViewModel
    ) {
        if (this.isReviewing || this.isConfirming)
            return html`<span class="text-sm font-medium ">${item.selectedState}</span>`;
        return html`<jhd-approval-toggle
            .selectedState=${item.selectedState}
            @change=${(e: CustomEvent<string>) => {
                this.updateSelectedItems(e.detail, item);
            }}
        ></jhd-approval-toggle>`;
    }

    renderAchNameColumn(ach: PendingAchPaymentViewModel) {
        return html`<div
            class="name-col flex flex-col"
            @click=${() => {
                this.showPendingPaymentDetails(ach);
            }}
            @keyup=${(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    this.showPendingPaymentDetails(ach);
                }
            }}
        >
            <span class="font-medium text-sm overflow-hidden text-ellipsis">${ach.value.name}</span>
            <span class="text-xs">${ach.value.transactionId}</span>
        </div>`;
    }

    renderAchAmountColumn(ach: PendingAchPaymentViewModel) {
        function renderAmounts() {
            const amounts = [];
            if (ach.value.creditAmount) {
                const creditAmount = html`
                    <div class="font-medium text-sm ">
                        ${ach.creditAmount}
                        <span class="text-xs font-normal">CR</span>
                    </div>
                `;
                amounts.push(creditAmount);
            }
            if (ach.value.debitAmount) {
                const debitAmount = html`
                    <div class="font-medium text-sm ">
                        ${ach.debitAmount}
                        <span class="text-xs font-normal">DR</span>
                    </div>
                `;
                amounts.push(debitAmount);
            }
            return amounts;
        }

        return html`
            <div
                class="flex flex-col"
                @click=${() => {
                    this.showPendingPaymentDetails(ach);
                }}
                @keyup=${(e: KeyboardEvent) => {
                    if (e.key === 'Enter') {
                        this.showPendingPaymentDetails(ach);
                    }
                }}
            >
                ${renderAmounts()}
            </div>
        `;
    }

    renderWireNameColumn(wire: PendingWireViewModel) {
        return html`<div
            class="flex flex-col"
            @click=${() => {
                this.showPendingPaymentDetails(wire);
            }}
            @keyup=${(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    this.showPendingPaymentDetails(wire);
                }
            }}
        >
            <span class="font-medium text-sm overflow-hidden text-ellipsis"
                >${wire.value.beneficiaryName}</span
            >
            <span class="text-xs ">from ${wire.value.debitAccount}</span>
        </div>`;
    }

    renderWireAmountColumn(wire: PendingWireViewModel) {
        return html`<div
            class="flex flex-col"
            @click=${() => {
                this.showPendingPaymentDetails(wire);
            }}
            @keyup=${(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    this.showPendingPaymentDetails(wire);
                }
            }}
        >
            <span class="text-sm font-medium text-gray-900">${wire.amount}</span>
        </div>`;
    }

    renderTransferAccountsColumn(transfer: PendingTransferViewModel) {
        return html`<div
            class="flex flex-col"
            @click=${() => {
                this.showPendingPaymentDetails(transfer);
            }}
            @keyup=${(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    this.showPendingPaymentDetails(transfer);
                }
            }}
        >
            <span class="text-sm font-medium">${transfer.value.toAccount}</span>
            <span class="text-xs">from ${transfer.value.fromAccount}</span>
        </div>`;
    }

    renderTransferAmountColumn(transfer: PendingTransferViewModel) {
        return html`<div
            class="flex flex-col"
            @click=${() => {
                this.showPendingPaymentDetails(transfer);
            }}
            @keyup=${(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    this.showPendingPaymentDetails(transfer);
                }
            }}
        >
            <span class="text-base font-medium">${transfer.amount}</span>
        </div>`;
    }

    renderLoanNameColumn(loan: PendingLoanViewModel) {
        return html`<div
            class="flex flex-col"
            @click=${() => {
                this.showPendingPaymentDetails(loan);
            }}
            @keyup=${(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    this.showPendingPaymentDetails(loan);
                }
            }}
        >
            <span class="text-sm font-medium overflow-hidden text-ellipsis"
                >${loan.value.toAccount}</span
            >
            <span class="text-sm ">from ${loan.value.fromAccount}</span>
        </div>`;
    }

    renderLoanAmountColumn(loan: PendingLoanViewModel) {
        return html`<div
            class="flex flex-col"
            @click=${() => {
                this.showPendingPaymentDetails(loan);
            }}
            @keyup=${(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    this.showPendingPaymentDetails(loan);
                }
            }}
        >
            <span class="text-sm font-medium text-gray-900">${loan.amount}</span>
        </div>`;
    }

    renderUserNameColumn(user: PendingUserViewModel) {
        return html`<div
            class="flex flex-col pl-3"
            @click=${() => {
                this.showPendingPaymentDetails(user);
            }}
            @keyup=${(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    this.showPendingPaymentDetails(user);
                }
            }}
        >
            <span class="font-medium text-sm ">${user.value.userName}</span>
            <span class="text-xs ">${user.value.userId}</span>
        </div>`;
    }

    renderUserStatusColumn(user: PendingUserViewModel) {
        return html`<span
            class="font-medium text-sm"
            @click=${() => {
                this.showPendingPaymentDetails(user);
            }}
            @keyup=${(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    this.showPendingPaymentDetails(user);
                }
            }}
            >${user.value.status}</span
        >`;
    }

    renderColumns() {
        switch (this.activeTab) {
            case PaymentTypes.ACH:
                return html` <vaadin-grid-column
                        width="5em"
                        ${columnBodyRenderer(this.renderToggleColumn, [])}
                    ></vaadin-grid-column>
                    <vaadin-grid-column
                        ${columnBodyRenderer(this.renderAchNameColumn, [])}
                    ></vaadin-grid-column>
                    <vaadin-grid-column
                        text-align="end"
                        ${columnBodyRenderer(this.renderAchAmountColumn, [])}
                    ></vaadin-grid-column>
                    <vaadin-grid-sort-column
                        path="name"
                        width="175px"
                        .hidden=${screen.width < 550}
                        flex-grow="1"
                    ></vaadin-grid-sort-column>
                    <vaadin-grid-sort-column
                        path="transactionId"
                        width="175px"
                        .hidden=${screen.width < 550}
                        flex-grow="1"
                    ></vaadin-grid-sort-column>
                    <vaadin-grid-sort-column
                        path="debitAmount"
                        width="175px"
                        .hidden=${screen.width < 550}
                        flex-grow="1"
                    ></vaadin-grid-sort-column>
                    <vaadin-grid-sort-column
                        path="creditAmount"
                        width="175px"
                        .hidden=${screen.width < 550}
                        flex-grow="1"
                    ></vaadin-grid-sort-column>`;
            case `${PaymentTypes.Wire}`:
                return html`<vaadin-grid-column
                        width="5em"
                        ${columnBodyRenderer(this.renderToggleColumn, [])}
                    ></vaadin-grid-column
                    ><vaadin-grid-column
                        ${columnBodyRenderer(this.renderWireNameColumn, [])}
                    ></vaadin-grid-column
                    ><vaadin-grid-column
                        text-align="end"
                        ${columnBodyRenderer(this.renderWireAmountColumn, [])}
                    ></vaadin-grid-column
                    ><vaadin-grid-sort-column
                        path="purpose"
                        width="175px"
                        .hidden=${screen.width < 550}
                        flex-grow="1"
                    ></vaadin-grid-sort-column>
                    <vaadin-grid-sort-column
                        path="transactionId"
                        width="175px"
                        .hidden=${screen.width < 550}
                        flex-grow="1"
                    ></vaadin-grid-sort-column>
                    <vaadin-grid-sort-column
                        path="amount"
                        width="175px"
                        .hidden=${screen.width < 550}
                        flex-grow="1"
                    ></vaadin-grid-sort-column>`;
            case `${PaymentTypes.Transfer}`:
                return html`<vaadin-grid-column
                        width="5em"
                        ${columnBodyRenderer(this.renderToggleColumn, [])}
                    ></vaadin-grid-column
                    ><vaadin-grid-column
                        ${columnBodyRenderer(this.renderTransferAccountsColumn, [])}
                    ></vaadin-grid-column>
                    <vaadin-grid-column
                        text-align="end"
                        ${columnBodyRenderer(this.renderTransferAmountColumn, [])}
                    ></vaadin-grid-column
                    ><vaadin-grid-sort-column
                        path="fromAccount.accountDisplayLabel"
                        header="From"
                        width="175px"
                        .hidden=${screen.width < 550}
                        flex-grow="1"
                    ></vaadin-grid-sort-column>
                    <vaadin-grid-sort-column
                        header="To"
                        path="toAccount.accountDisplayLabel"
                        width="175px"
                        .hidden=${screen.width < 550}
                        flex-grow="1"
                    ></vaadin-grid-sort-column>
                    <vaadin-grid-sort-column
                        path="transactionId"
                        header="Transaction ID"
                        width="175px"
                        .hidden=${screen.width < 550}
                        flex-grow="1"
                    ></vaadin-grid-sort-column>
                    <vaadin-grid-sort-column
                        path="amount"
                        width="175px"
                        .hidden=${screen.width < 550}
                        flex-grow="1"
                    ></vaadin-grid-sort-column> `;
            case `${PaymentTypes.Loan}`:
                return html`<vaadin-grid-column
                        width="5em"
                        ${columnBodyRenderer(this.renderToggleColumn, [])}
                    ></vaadin-grid-column
                    ><vaadin-grid-column
                        ${columnBodyRenderer(this.renderLoanNameColumn, [])}
                    ></vaadin-grid-column
                    ><vaadin-grid-column
                        text-align="end"
                        ${columnBodyRenderer(this.renderLoanAmountColumn, [])}
                    ></vaadin-grid-column
                    ><vaadin-grid-sort-column
                        path="name"
                        width="175px"
                        .hidden=${screen.width < 550}
                        flex-grow="1"
                    ></vaadin-grid-sort-column>
                    <vaadin-grid-sort-column
                        path="transactionId"
                        width="175px"
                        .hidden=${screen.width < 550}
                        flex-grow="1"
                    ></vaadin-grid-sort-column>
                    <vaadin-grid-sort-column
                        path="debitAmount"
                        .hidden=${screen.width < 550}
                        flex-grow="1"
                    ></vaadin-grid-sort-column> `;
            default:
                return html`<vaadin-grid-column
                        flex-grow="1"
                        ${columnBodyRenderer(this.renderUserNameColumn, [])}
                    ></vaadin-grid-column>
                    <vaadin-grid-column
                        flex-grow="0"
                        text-align="end"
                        ${columnBodyRenderer(this.renderUserStatusColumn, [])}
                    ></vaadin-grid-column>`;
        }
    }

    renderActions() {
        if (this.activeTab === PaymentTypes.User) return nothing;
        if (this.activeItems.length === 0 || this.isReviewing || this.isConfirming) return nothing;
        const actionButtons = this.selectAll
            ? html`
                  <tm-button
                      class="mb-2 pr-2"
                      @click=${() => {
                          this.approveOrRejectAllItems('na');
                          this.selectAll = false;
                      }}
                      >Cancel
                  </tm-button>
                  <tm-button
                      class="mb-2 pr-2"
                      success
                      importance="primary"
                      @click=${() => this.approveOrRejectAllItems('Approved')}
                  >
                      Approve All
                  </tm-button>
                  <tm-button
                      class="mb-2"
                      error
                      importance="primary"
                      @click=${() => this.approveOrRejectAllItems('Rejected')}
                  >
                      Reject All
                  </tm-button>
              `
            : html`
                  <tm-button
                      class="mb-2"
                      @click=${() => {
                          this.selectAll = true;
                      }}
                  >
                      Select All
                  </tm-button>
              `;
        return html` <div class="flex justify-between w-full">${actionButtons}</div> `;
    }

    renderTableHeader() {
        if (this.activeItems.length === 0) return nothing;
        return html` <tm-table-header
            .placeholder=${`Search ${this.tabTitle}`}
            @filterStringChange=${(e: CustomEvent) => {
                this.filteredItems = filterTableResults(
                    e,
                    this.activeItems,
                    this.fieldsToFilter
                ) as
                    | PendingAchPaymentViewModel[]
                    | PendingWireViewModel[]
                    | PendingLoanViewModel[]
                    | PendingTransferViewModel[];
            }}
        ></tm-table-header>`;
    }

    renderTable() {
        if (this.loading) return html`<div class="p-4 pt-6"><tm-loader card></tm-loader></div>`;
        if (this.activeItems.length === 0)
            return html`
                <p class="text-center empty-state pt-7 pb-4">
                    <em>No pending ${this.emptyStateText} approvals</em>
                </p>
            `;
        return html`
            <vaadin-grid class="-ml-3 -m5-3" all-rows-visible .items=${this.filteredItems}>
                ${this.renderColumns()}
            </vaadin-grid>
        `;
    }

    renderUserBottomSheet() {
        if (this.activeTab === PaymentTypes.User) {
            return html`<user-detail-bottom-sheet
                .bottomSheetOpen=${this.userDetailsOpen}
                .user=${this.selectedPayment}
                @close=${() => {
                    this.selectedPayment = null;
                    this.userDetailsOpen = false;
                }}
            ></user-detail-bottom-sheet>`;
        }
        return nothing;
    }

    renderAchBottomSheet() {
        if (this.activeTab === PaymentTypes.ACH) {
            return html`<ach-detail-bottom-sheet
                .bottomSheetOpen=${this.achDetailsOpen}
                .ach=${this.selectedPayment?.value?.dto}
                @close=${() => {
                    this.achDetailsOpen = false;
                    this.selectedPayment = null;
                }}
            ></ach-detail-bottom-sheet>`;
        }
        return nothing;
    }

    renderTransferBottomSheet() {
        if (this.activeTab === PaymentTypes.Transfer) {
            return html`<transfer-detail-bottom-sheet
                .bottomSheetOpen=${this.transferDetailsOpen}
                .transfer=${this.selectedPayment?.value?.dto}
                @close=${() => {
                    this.transferDetailsOpen = false;
                }}
            ></transfer-detail-bottom-sheet>`;
        }
        return nothing;
    }

    renderWireDetailBottomSheet() {
        if (this.activeTab === PaymentTypes.Wire) {
            return html`<wire-detail-bottom-sheet
                .bottomSheetOpen=${this.wireDetailsOpen}
                .wire=${this.selectedPayment?.value?.dto}
                @close=${() => {
                    this.wireDetailsOpen = false;
                    this.selectedPayment = null;
                }}
            ></wire-detail-bottom-sheet>`;
        }
        return nothing;
    }

    renderLoanDetailsBottomSheet() {
        if (this.activeTab === PaymentTypes.Loan) {
            return html`<loan-detail-bottom-sheet
                .bottomSheetOpen=${this.loanDetailsOpen}
                .loan=${this.selectedPayment?.value?.dto}
                @close=${() => {
                    this.loanDetailsOpen = false;
                }}
            ></loan-detail-bottom-sheet>`;
        }
        return nothing;
    }

    renderFooter() {
        if (this.isReviewing) return nothing;
        if (this.activeTab === PaymentTypes.User) return nothing;
        /**
         * Maybe can't do approve and reject aggregate selections
         * without a new API
         */

        return html`
            <tm-footer
                direction="row"
                wrap
                .alignCenter=${false}
                .buttonConfig=${this.footerButtonConfig}
            >
            </tm-footer>
        `;
    }

    render() {
        return html`
            <tm-body>
                ${this.renderTableHeader()} ${this.renderTable()} ${this.renderUserBottomSheet()}
                ${this.renderTransferBottomSheet()} ${this.renderAchBottomSheet()}
                ${this.renderLoanDetailsBottomSheet()} ${this.renderWireDetailBottomSheet()}
            </tm-body>
            ${this.renderFooter()}
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                }
                vaadin-grid::part(last-column-cell) {
                    padding-right: 16px;
                }
                vaadin-grid-cell-content {
                    margin-bottom: 1px;
                    padding: 0.5rem 0;
                }
                .text-ellipsis {
                    text-overflow: ellipsis;
                }
                #grid-wrapper {
                    max-height: calc(100vh - 344px);
                    overflow-y: auto;
                }
                .title {
                    align-items: center;
                }
                .title p {
                    color: var(--header-text-color);
                }
                .title small {
                    color: var(--hint-text-color);
                }
                h3 {
                    margin-top: 10px;
                    color: var(--header-text-color);
                }
                span.text-sm {
                    color: var(--primary-text-color);
                }
                span.text-xs {
                    color: var(--hint-text-color);
                }
                .button-bar {
                    border-top: 1px solid var(--content-background);
                }
                .empty-state {
                    color: var(--header-text-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: PendingApprovalsTable;
    }
}
