/* eslint-disable @treasury/no-date */
import { clone } from '@treasury/utils/functions';
import { TransferTemplate } from './transfer-template.entity';
import { nullTransferTransaction } from './transfer-transaction.dto';

export class TransferTransaction {
    // eslint-disable-next-line no-useless-constructor
    constructor(protected dto = clone(nullTransferTransaction)) {}

    public get type() {
        return this.dto.type || '';
    }

    public set type(type) {
        this.dto.type = type;
    }

    public get templateName() {
        return this.dto.templateName || '';
    }

    public set templateName(templateName) {
        this.dto.templateName = templateName;
    }

    public get transactions() {
        return this.dto.transactions || [];
    }

    public set transactions(transactions) {
        this.dto.transactions = transactions;
    }

    public toDto() {
        return this.dto;
    }

    public clone() {
        return new TransferTransaction(clone(this.dto));
    }
}
