import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-blocking-loader';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/jhd-data-field';

export const tagName = 'user-receivables-entitlements';
@customElement(tagName)
export class UserReceivablesEntitlements extends TmBaseComponent {
    @property({ type: Object })
    public config: any = {};

    renderRemoteDepositEntitlements() {
        const remoteDeposit = this.config?.products?.find(
            (p: { name: string; hasProduct: boolean; description: string }) =>
                p.name === 'Remote Deposits'
        );
        const hasRemoteDeposit = remoteDeposit?.hasProduct ? 'Yes' : 'No';
        const remoteDepositEdited = remoteDeposit?.edited;
        return html`<div class="p-3 remote-deposit">
            <h3 class="text-lg font-normal">Remote Deposit Capture Entitlements</h3>
            <jhd-data-field
                .label=${'User Has Access'}
                .value=${hasRemoteDeposit}
                .edited=${remoteDepositEdited}
            ></jhd-data-field>
            <jhd-data-field
                .label=${'User ID'}
                .edited=${this.config?.ssoLoginId?.edited}
                .value=${this.config?.ssoLoginId?.value}
            ></jhd-data-field>
            <jhd-data-field
                .label=${'Access Key'}
                .edited=${this.config?.ssoAccessKey?.edited}
                .value=${this.config?.ssoAccessKey?.value}
            ></jhd-data-field>
        </div>`;
    }

    render() {
        return html`${this.renderRemoteDepositEntitlements()}`;
    }

    static get styles() {
        return [
            css`
                .remote-deposit {
                    background-color: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserReceivablesEntitlements;
    }
}
