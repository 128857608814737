import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-icon';
import '@vaadin/grid';
import { columnBodyRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { BillPayPaymentTableViewModel } from '../data/bill-pay-payment-table-view-model';

export const tagName = 'payment-history-table';
@customElement(tagName)
export class PaymentHistoryTable extends TmBaseComponent {
    @property({ type: Array })
    historicPayments!: BillPayPaymentTableViewModel[];

    @state()
    selectedPayments: BillPayPaymentTableViewModel[] = [];

    paymentClicked(payment: BillPayPaymentTableViewModel) {
        this.selectedPayments = payment ? [payment] : [];
        if (payment) {
            this.dispatchEvent(new CustomEvent('payment-selected', { detail: payment }));
        }
    }

    renderStatusIconColumn(item: BillPayPaymentTableViewModel) {
        return html`<jhd-icon .icon=${item.statusIcon} .color=${item.statusColor}></jhd-icon>`;
    }

    renderPayeeAndStatusColumn(item: BillPayPaymentTableViewModel) {
        return html`<div class="flex flex-col">
            <span class="text-sm font-normal">${item.payeeName}</span>
            <span class="text-xs font-light" style="color:${item.statusColor}">${item.status}</span>
        </div>`;
    }

    renderAmountAndDateColumn(item: BillPayPaymentTableViewModel) {
        return html`<div class="flex flex-col">
            <span class="text-sm font-normal">${item.amount}</span>
            <span class="text-xs">${item.processDate}</span>
        </div>`;
    }

    render() {
        return html`<vaadin-grid
            .items=${this.historicPayments}
            @active-item-changed=${({ detail }: CustomEvent) => {
                this.paymentClicked(detail.value);
            }}
        >
            <vaadin-grid-column
                flex-grow="0"
                width="40px"
                ${columnBodyRenderer(this.renderStatusIconColumn, [])}
            ></vaadin-grid-column>
            <vaadin-grid-column
                flex-grow="1"
                ${columnBodyRenderer(this.renderPayeeAndStatusColumn, [])}
            ></vaadin-grid-column
            ><vaadin-grid-column
                flex-grow="0"
                text-align="end"
                ${columnBodyRenderer(this.renderAmountAndDateColumn, [])}
            ></vaadin-grid-column
        ></vaadin-grid>`;
    }

    static get styles() {
        return [
            css`
                span.text-sm {
                    color: var(--primary-text-color);
                }
                span.text-xs {
                    color: var(--hint-text-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: PaymentHistoryTable;
    }
}
