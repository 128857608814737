import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-footer';
import { mapFrequencyToFrequencyModelDto } from '@treasury/presentation/components/tm-frequency.mappings';
import { summarizeFrequency } from '@treasury/presentation/components/tm-frequency.summarizer';
import { Frequency, FrequencyType } from '@treasury/presentation/components/tm-frequency.types';
import { format } from 'date-fns';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { TransferViewModel } from '../types/transfer-view-model.type';

export const tagName = 'review-transfer-payment-step';
@customElement(tagName)
export class ReviewTransferPaymentStep extends TmBaseComponent {
    @property({ type: Object })
    private transfer = {} as TransferViewModel;

    onConfirmClick = () => {
        if (this.transfer.transferType === 'one') {
            this.transfer.frequency = mapFrequencyToFrequencyModelDto(
                this.transfer.frequency as unknown as Frequency
            );
        }
        this.dispatchEvent(new CustomEvent('confirm', { detail: this.transfer }));
    };

    renderTemplateAccounts() {
        const direction = this.transfer.transferType === 'manyToOne' ? 'from' : 'to';
        if (this.transfer.transferType === 'one')
            return html`<div class="pt-7 pr-4 pb-2 text-base font-medium">
                    Transfer ${direction}
                </div>
                <div class="transfer-transaction flex justify-between py-2">
                    <div class="text-sm">
                        <div class="account-name">
                            ${this.transfer.toAccount.accountDisplayLabel}
                        </div>
                        <div class="pt-7 pr-4 pb-2 text-base font-medium">Memo</div>
                        <div class="transaction-memo text-xs">${this.transfer.memo}</div>
                    </div>
                    <div class="font-medium">
                        ${new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }).format(this.transfer.amount)}
                    </div>
                </div>`;
        return html`<div class="pt-7 pr-4 pb-2 text-base font-medium">Transfer ${direction}</div>
            ${this.transfer.transactions.map(item => {
                const account =
                    this.transfer.transferType === 'manyToOne' ? item.fromAccount : item.toAccount;
                return html`<div class="transfer-transaction flex justify-between py-2">
                    <div class="text-sm">
                        <div class="account-name">${account.accountDisplayLabel}</div>
                        ${item.memo
                            ? html`<div class="text-xs text-[--primary-text-color]">
                                  <span class="text-[--secondary-text-color]">Memo - </span
                                  >${item.memo}
                              </div>`
                            : nothing}
                    </div>
                    <div class="font-medium">
                        ${new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }).format(item.amount)}
                    </div>
                </div>`;
            })}`;
    }

    renderFrequencyDetails() {
        if (this.transfer.transferType === 'one') {
            const frequency = this.transfer.frequency as unknown as Frequency;
            return html` <jhd-data-field
                .label=${frequency.type === FrequencyType.OneTime ? 'Transfer Date' : 'Frequency'}
                .value=${frequency.type === FrequencyType.OneTime
                    ? format(frequency.startDate, 'MM/dd/yyyy')
                    : summarizeFrequency(frequency)}
            ></jhd-data-field>`;
        }

        const transferDate = this.transfer.transactions[0].transferDate as unknown as string;
        return html` <jhd-data-field
            .label=${'Transfer Date'}
            .value=${format(new Date(transferDate.replace(/-/g, '/')), 'MM/dd/yyyy')}
        ></jhd-data-field>`;
    }

    renderSingleTransferDetails() {
        if (!this.transfer) return nothing;
        const transferMemo = this.transfer.memo
            ? html`<jhd-data-field .label=${'Memo'} .value=${this.transfer.memo}></jhd-data-field>`
            : nothing;

        return html`<div class="transfer-data-wrapper p-3">
            <div class="amount-label text-xl">Transfer</div>
            <div class="transfer-amount text-2xl">
                ${new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(this.transfer.amount)}
            </div>
            <jhd-data-field
                .label=${'From'}
                .value=${this.transfer.fromAccount.accountDisplayLabel}
            ></jhd-data-field>
            <jhd-data-field
                .label=${'To'}
                .value=${this.transfer.toAccount.accountDisplayLabel}
            ></jhd-data-field>
            <div class="border-t border-dashed border-[--border-color]">
                ${this.renderFrequencyDetails()} ${transferMemo}
            </div>
        </div>`;
    }

    renderTemplateTransferDetails() {
        const account =
            this.transfer.transferType === 'manyToOne'
                ? this.transfer.toAccount
                : this.transfer.fromAccount;
        const direction = this.transfer.transferType === 'manyToOne' ? 'To' : 'From';
        return html`<div class="transfer-data-wrapper p-3">
            <div class="flex justify-between">
                <div class="template-label">${this.transfer.templateName}</div>
                <div class="template-badge text-xs uppercase">Template</div>
            </div>
            <div class="transfer-amount text-2xl">
                ${new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(this.transfer.transferTotal)}
            </div>
            <jhd-data-field
                .label=${direction}
                .value=${account.accountDisplayLabel}
            ></jhd-data-field>
            ${this.renderFrequencyDetails()}
            <div class="border-t border-dashed border-[--border-color]">
                ${this.renderTemplateAccounts()}
            </div>
        </div>`;
    }

    renderTransferDetails() {
        if (!this.transfer) return nothing;
        return this.transfer.isTemplate
            ? this.renderTemplateTransferDetails()
            : this.renderSingleTransferDetails();
    }

    render() {
        return html`
            <tm-body> ${this.renderTransferDetails()} </tm-body>
            <tm-footer
                visible
                .buttonConfig=${[
                    {
                        text: 'Transfer',
                        onClick: this.onConfirmClick,
                        disabled: false,
                    },
                ]}
            >
            </tm-footer>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
                .transfer-data-wrapper {
                    background-color: var(--primary-background);
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    min-height: 300px;
                }
                .amount-label {
                    color: var(--header-text-color);
                }
                .template-badge {
                    color: var(--icon-fill-color-secondary);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: ReviewTransferPaymentStep;
    }
}
