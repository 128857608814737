import { PayeeModelDto, PaymentMethodDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';
import { getPaymentMethodString } from './payment-list.entity';

export class PayeeListModel extends DomainEntity<PayeeModelDto> {
    constructor(payee: PayeeModelDto) {
        super(payee);
    }

    public createDefault() {
        return new PayeeListModel({} as PayeeModelDto) as this;
    }

    public get nickName() {
        return this.dto.nickName;
    }

    public get name() {
        return this.dto.name;
    }

    public get paymentMethod() {
        return getPaymentMethodString(this.dto.paymentMethod || 0);
    }

    public get id() {
        return this.dto.id;
    }
}
