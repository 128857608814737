/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { CutoffConfigurationModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class CutoffconfigurationsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags CutOffConfigurations
     * @name CutOffConfigurationsPut
     * @summary Updates the transfer time.
     * @request PUT:/cutoffconfigurations/transfer
     */
    cutOffConfigurationsPut = (
        query: {
            /**
             * Transfer time to be updated.
             * @format date-time
             */
            transferTime: string;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<CutoffConfigurationModelDto, any>({
            path: `/cutoffconfigurations/transfer`,
            method: 'PUT',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags CutOffConfigurations
     * @name CutOffConfigurationsGet
     * @summary Gets the transfer time.
     * @request GET:/cutoffconfigurations/transfer
     */
    cutOffConfigurationsGet = (params: RequestParams = {}) =>
        this.http.request<string, any>({
            path: `/cutoffconfigurations/transfer`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
