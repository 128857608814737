import { AchException } from '@treasury/domain/arp';
import usd from '@treasury/policy/lib/formatters/usd.formatter';

export class AchExceptionVm {
    constructor(public exception: AchException) {}

    formatDate(dateStr?: Date | string) {
        if (dateStr === undefined) return 'none';
        const date = new Date(dateStr);
        if (date.toDateString() === 'Invalid Date') return 'none';
        return Intl.DateTimeFormat(undefined, {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        }).format(date);
    }

    public get accountName() {
        return this.exception.accountName;
    }

    public get amount() {
        return this.exception.amount ? usd(this.exception.amount) : '';
    }

    public get secCode() {
        return this.exception.secCode;
    }

    public get achCompanyName() {
        return this.exception.achCompanyName;
    }

    public get description() {
        return this.exception.entryDescription;
    }
}
