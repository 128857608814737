/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    ChangePasswordViewModelDto,
    CredentialDto,
    ErrorHttpContentDto,
    InitialLoginCredentialModelDto,
    OkResultDto,
    RecentLoginsModelDto,
    RegisterSecureTokenMfaRequestModelDto,
    RegisterSecureTokenMfaResponseModelDto,
    SecureTokenMfaCredentialsResponseModelDto,
    SecureTokenMfaLoginRequestModelDto,
    SecureTokenMfaLoginResponseModelDto,
    SecurityQuestionModelDto,
    TextKeyCollectionDto,
    UserModelDto,
    UserSettingsModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class AccountClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
 * No description
 *
 * @tags Account
 * @name AccountLogin
 * @summary Initiates login for an FI user, checking initial login credentials and possibly MFA.
Returns login credentials model.
 * @request POST:/account/login
 */
    accountLogin = (user: UserModelDto, params: RequestParams = {}) =>
        this.http.request<InitialLoginCredentialModelDto, ErrorHttpContentDto>({
            path: `/account/login`,
            method: 'POST',
            body: user,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Account
     * @name AccountAuthenticateSecureToken
     * @summary Authenticates a secure token.
     * @request POST:/account/authenticatesecuretoken
     */
    accountAuthenticateSecureToken = (
        request: SecureTokenMfaLoginRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<SecureTokenMfaLoginResponseModelDto, ErrorHttpContentDto>({
            path: `/account/authenticatesecuretoken`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Account
     * @name AccountRegisterSecureToken
     * @summary Registers a secure token.
     * @request POST:/account/registersecuretoken
     */
    accountRegisterSecureToken = (
        request: RegisterSecureTokenMfaRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<RegisterSecureTokenMfaResponseModelDto, ErrorHttpContentDto>({
            path: `/account/registersecuretoken`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Account
     * @name AccountGetSecureTokenCredentials
     * @summary Gets the users secure token credentials.
     * @request GET:/account/securetokencredentials
     */
    accountGetSecureTokenCredentials = (params: RequestParams = {}) =>
        this.http.request<SecureTokenMfaCredentialsResponseModelDto, ErrorHttpContentDto>({
            path: `/account/securetokencredentials`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Account
     * @name AccountLogout
     * @summary Ends the user's session.
     * @request GET:/account/logout
     */
    accountLogout = (
        query?: {
            /** @default "" */
            reason?: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<OkResultDto, ErrorHttpContentDto>({
            path: `/account/logout`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Account
     * @name AccountSecurityQuestions
     * @summary Save Security Question and Answers
     * @request POST:/account/securityquestions
     */
    accountSecurityQuestions = (
        securityQuestions: SecurityQuestionModelDto[],
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorHttpContentDto>({
            path: `/account/securityquestions`,
            method: 'POST',
            body: securityQuestions,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Account
     * @name AccountVerifySecurityQuestions
     * @summary Validate MFA Challenge Questions Answers
     * @request PUT:/account/verifysecurityquestions
     */
    accountVerifySecurityQuestions = (
        securityQuestions: SecurityQuestionModelDto[],
        params: RequestParams = {}
    ) =>
        this.http.request<CredentialDto, ErrorHttpContentDto>({
            path: `/account/verifysecurityquestions`,
            method: 'PUT',
            body: securityQuestions,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Account
     * @name AccountVerifyUser
     * @summary Initiates login in context of new user credentials.
     * @request PUT:/account/verifyuser
     */
    accountVerifyUser = (user: UserModelDto | null, params: RequestParams = {}) =>
        this.http.request<InitialLoginCredentialModelDto, ErrorHttpContentDto>({
            path: `/account/verifyuser`,
            method: 'PUT',
            body: user,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Account
     * @name AccountVerifyUserForPasswordReset
     * @summary Initiates login in context of resetting an existing user.
     * @request PUT:/account/VerifyUserForPasswordReset
     */
    accountVerifyUserForPasswordReset = (user: UserModelDto | null, params: RequestParams = {}) =>
        this.http.request<InitialLoginCredentialModelDto, ErrorHttpContentDto>({
            path: `/account/VerifyUserForPasswordReset`,
            method: 'PUT',
            body: user,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Account
     * @name AccountUpdatePassword
     * @summary Update User Password
     * @request PUT:/account/updatepassword
     */
    accountUpdatePassword = (user: UserModelDto, params: RequestParams = {}) =>
        this.http.request<ChangePasswordViewModelDto, ErrorHttpContentDto>({
            path: `/account/updatepassword`,
            method: 'PUT',
            body: user,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Account
     * @name AccountPasswordRequirements
     * @summary Get Password Strength Requirements
     * @request GET:/account/passwordrequirements
     */
    accountPasswordRequirements = (
        query: {
            fiId: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<TextKeyCollectionDto, ErrorHttpContentDto>({
            path: `/account/passwordrequirements`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Account
     * @name AccountGetOnDemandMfaChallengeSecurityQuestions
     * @summary Validate MFA Challenge Questions Answers
     * @request PUT:/account/getOnDemandMfaChallengeSecurityQuestions
     */
    accountGetOnDemandMfaChallengeSecurityQuestions = (
        user: UserModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<CredentialDto, ErrorHttpContentDto>({
            path: `/account/getOnDemandMfaChallengeSecurityQuestions`,
            method: 'PUT',
            body: user,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Account
     * @name AccountSettings
     * @request GET:/account/settings
     */
    accountSettings = (params: RequestParams = {}) =>
        this.http.request<UserSettingsModelDto, ErrorHttpContentDto>({
            path: `/account/settings`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Account
     * @name AccountCurrentFiDateTime
     * @summary Retrieves the current date time for the financial institution
     * @request GET:/account/currentFiDateTime
     */
    accountCurrentFiDateTime = (params: RequestParams = {}) =>
        this.http.request<string | null, ErrorHttpContentDto>({
            path: `/account/currentFiDateTime`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Account
     * @name AccountRecentLogins
     * @request GET:/account/recentLogins
     */
    accountRecentLogins = (params: RequestParams = {}) =>
        this.http.request<RecentLoginsModelDto, ErrorHttpContentDto>({
            path: `/account/recentLogins`,
            method: 'GET',
            format: 'json',
            ...params,
        });
}
