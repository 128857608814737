import { NavigationService } from '@treasury/core/navigation';
import { TransferService } from '@treasury/domain/transfers';
import { TmContainer } from '@treasury/presentation';
import { InjectProperty } from '@treasury/utils';
import '@vaadin/grid';
import '@vaadin/grid/vaadin-grid-column.js';
import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import '../partials/transfer-templates-filter-sheet';
import '../partials/transfer-templates-table';
import { TransferTemplateDto } from '../types/transfer-template-dto.type';
import {
    TransferAction,
    TransferTemplatesParameters,
} from '../types/transfer-template-parameters.type';

interface RouteParams extends TransferTemplatesParameters {
    id: string;
}

export const tagName = 'transfer-templates-container';
@customElement(tagName)
export class TransferTemplatesContainer extends TmContainer {
    constructor() {
        super();
        this.headerService.configure({ title: 'Transfer Templates' });
    }

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare transferService: TransferService;

    @InjectProperty()
    private declare navService: NavigationService;

    @state()
    templates?: TransferTemplateDto[];

    @state()
    filterSheetOpen = false;

    @state()
    action = '';

    @state()
    templateSelection = false;

    async firstUpdated() {
        const { params } = await this.navService.getRouteData<RouteParams>();
        const { action } = params;
        this.action = action?.toLowerCase() as TransferAction;

        if (this.action === 'initiate') {
            this.templateSelection = true;
        }
        this.configureHeader();
    }

    async filterTemplates(filters: any) {
        await this.tryFetch(
            () => this.transferService.getTransferTemplates(filters),
            t => (this.templates = t as TransferTemplateDto[])
        );
    }

    configureHeader() {
        this.headerService.configure({
            title: this.templateSelection ? 'Select Transfer Template' : 'Transfer Templates',
            backAction: this.templateSelection
                ? () => this.navService.navigate('transfers/payment/one-to-one/create/new')
                : null,
        });
    }

    transferTemplateClicked(template: TransferTemplateDto) {
        if (!template) return;
        this.navService.navigate(
            `/transfers/payment/${template.transferType.toLowerCase()}/create/${template.id}`
        );
    }

    onFiltersClick() {
        this.filterSheetOpen = true;
    }

    renderTable() {
        return html`<transfer-templates-table
            .loading=${this.loading}
            .transferTemplates=${this.templates || []}
            @template-clicked=${({ detail }: CustomEvent) => this.transferTemplateClicked(detail)}
            @filter-transfer-templates=${() => this.onFiltersClick()}
        ></transfer-templates-table>`;
    }

    renderFilterSheet() {
        return html`<transfer-templates-filter-sheet
            .open=${this.filterSheetOpen}
            @close=${() => {
                this.filterSheetOpen = false;
            }}
            .action=${this.action}
            @applyFilters=${({ detail }: CustomEvent) => {
                this.filterTemplates(detail);
            }}
        ></transfer-templates-filter-sheet>`;
    }

    render() {
        return [this.renderTable(), this.renderFilterSheet()];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TransferTemplatesContainer;
    }
}
