export const wireFrequencyFilterOptions = [
    {
        label: 'Weekly',
        value: {
            key: 'Weekly',
            value: 'Weekly',
        },
    },
    {
        label: 'Every Two Weeks',
        value: {
            key: 'EveryTwoWeeks',
            value: 'Every Two Weeks',
        },
    },
    {
        label: 'Twice a Month',
        value: {
            key: 'TwiceAMonth',
            value: 'Twice a Month',
        },
    },
    {
        label: 'Monthly',
        value: {
            key: 'Monthly',
            value: 'Monthly',
        },
    },
    {
        label: 'Quarterly',
        value: {
            key: 'Quarterly',
            value: 'Quarterly',
        },
    },
    {
        label: 'Every Six Months',
        value: {
            key: 'EverySixMonths',
            value: 'Every Six Months',
        },
    },
    {
        label: 'Yearly',
        value: {
            key: 'Yearly',
            value: 'Yearly',
        },
    },
];
