/* eslint-disable no-use-before-define */
import { isNonEmptyString, WindowService } from '@treasury/utils';
import { DiContainer, Injectable, InjectByToken } from '@treasury/utils/dependency-injection';
import {
    AppType,
    AppTypeToken,
    BACKOFFICE_API,
    BuildType,
    BuildTypeToken,
    CHANNEL_API,
    Environment,
    InjectedWindow,
} from './configuration.types';

/**
 * Service exposing information used for bootstrapping or
 * otherwise configuring channel and BO applications.
 */
@Injectable()
export class ConfigurationService {
    // eslint-disable-next-line no-useless-constructor
    public constructor(
        @InjectByToken(AppTypeToken) public readonly app: AppType,
        @InjectByToken(BuildTypeToken) public readonly buildType: BuildType,
        private readonly window: WindowService
    ) {
        if (app === AppType.Unknown) {
            throw new Error('Could not determine application type.');
        }
    }

    public readonly uisRedirectUrl = this.generateSsoCallbackUrl;

    /**
     * The API path used when the client is deployed together with the API on the same domain.
     */
    private readonly apiPath = this.app === AppType.Channel ? CHANNEL_API : BACKOFFICE_API;

    public get institutionId() {
        const path = this.window.location.pathname.split('/').filter(isNonEmptyString);
        if (!path) throw new Error('Incomplete path. Unable to determine institutionId.');
        if (this.app === AppType.Channel) return path[0];
        if (this.app === AppType.Pwa) return path[1];
        return '';
    }

    /**
     * The root URL prepended to all calls made to API endpoints.
     */
    public get apiRoot() {
        const { apiRoot } = this.injectedConfig;

        return isNonEmptyString(apiRoot) ? apiRoot : `${this.baseUrl}/${this.apiPath}`;
    }

    public get analyticsToken() {
        return this.injectedConfig.analyticsToken;
    }

    public get environment() {
        if (this.injectedMetadata.isLocal) {
            return Environment.Local;
        }

        const { hostname } = this.window.location;
        if (hostname)
            switch (hostname) {
                case '127.0.0.1':
                case 'localhost':
                    return Environment.Local;
                case 'dev.jha.treasury.jackhenry.com':
                case 'dev.jha.treasurybackoffice.jackhenry.com':
                    return Environment.Dev;
                case 'qa.jha.treasury.jackhenry.com':
                case 'qa.jha.treasurybackoffice.jackhenry.com':
                case 'www.qa.treasurymanagement.jackhenry.com':
                    return Environment.QA;
                case 'lt.jha.treasury.jackhenry.com':
                case 'lt.jha.treasurybackoffice.jackhenry.com':
                    return Environment.LT;
                case 'treasuryuat.jackhenry.com':
                case 'treasurybackofficeuat.jackhenry.com':
                case 'www.uat.treasurymanagement.jackhenry.com':
                    return Environment.Uat;
                default:
                    return Environment.Production;
            }
    }

    public get loggingToken() {
        return this.injectedConfig.loggingToken;
    }

    public get version() {
        return this.injectedMetadata.version;
    }

    private get baseUrl() {
        const { protocol, hostname, port } = window.location;
        let url = `${protocol}//${hostname}`;

        if (port) {
            url += `:${port}`;
        }

        return url;
    }

    private get generateSsoCallbackUrl() {
        let { host } = window.location;
        if (this.app === AppType.Pwa) host = `${host}/pwa`;
        return `${window.location.protocol}//${host}/${this.institutionId}/login/sso`;
    }

    private get injectedConfig() {
        return (window as unknown as InjectedWindow).config ?? {};
    }

    private get injectedMetadata() {
        return (window as unknown as InjectedWindow).treasury ?? {};
    }

    /**
     * Convenience method for locations where DI isn't available.
     */
    public static async getInstance() {
        return (await DiContainer.getInstance()).get(ConfigurationService);
    }
}

const buildType = BuildTypeToken.getValue() || BuildType.Unknown;

/**
 * @deprecated Prefer referencing `apiRoot` through the configuration service.
 */
export const channelApiRoot = new ConfigurationService(
    AppType.Channel,
    buildType,
    new WindowService()
).apiRoot;

/**
 * @deprecated Prefer referencing `apiRoot` through the configuration service.
 */
export const boApiRoot = new ConfigurationService(
    AppType.BackOffice,
    buildType,
    new WindowService()
).apiRoot;
