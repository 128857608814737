/* eslint-disable import/no-unresolved */
import { NavigationService } from '@treasury/core/navigation';
import { PositivePayExceptionRequests } from '@treasury/domain/channel/requests/positive-pay/positive-pay-exception-requests.js';
import CheckExceptionsServices from '@treasury/domain/channel/services/positive-pay/check-exceptions-services';
import { AccountDto } from '@treasury/domain/channel/types/arp';
import { EntitlementsService } from '@treasury/domain/entitlements';
import { TmBaseComponent } from '@treasury/presentation';
import { achIcon, checkRangeIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-action-button';
import '@treasury/presentation/components/tm-card';
import { InjectProperty } from '@treasury/utils';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/jhd-icon';

export const tagName = 'positive-pay-decision-widget';
@customElement(tagName)
export class PositivePayDecisionWidget extends TmBaseComponent {
    @property({ type: Boolean })
    public disabled = false;

    @InjectProperty()
    private declare entitlementsService: EntitlementsService;

    @state()
    private entitlements: Map<string, boolean> = new Map();

    @state()
    loadingCheckExceptions = false;

    @state()
    loadingAchExceptions = false;

    @state()
    private arpExceptionAccounts = [];

    @state()
    private checkExceptionCount = 0;

    @state()
    private achExceptionCount = 0;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    @state()
    private hasAchExceptionEntitlement = false;

    @state()
    private hasCheckExceptionEntitlement = false;

    get loading() {
        return this.loadingCheckExceptions || this.loadingAchExceptions;
    }

    protected firstUpdated(): void {
        this.loadEntitlements();
        this.loadAchExceptions();
        this.loadCheckExceptions();
    }

    async loadEntitlements() {
        this.entitlements = await this.entitlementsService.getEntitlements();
        this.hasAchExceptionEntitlement = this.entitlements.get('Work ACH Exceptions') || false;
        this.hasCheckExceptionEntitlement = this.entitlements.get('Work Check Exceptions') || false;
    }

    async loadCheckExceptions() {
        try {
            this.loadingCheckExceptions = true;
            const params = {
                parameters: {
                    account: this.arpExceptionAccounts.map(
                        (account: AccountDto) => account.accountUniqueId
                    ),
                },
            };
            const checkExceptions = await CheckExceptionsServices.searchCheckExceptions(params);
            this.checkExceptionCount = checkExceptions?.totalCount || 0;
        } catch (e) {
            throw new Error('Error fetching check exceptions');
        } finally {
            this.loadingCheckExceptions = false;
        }
    }

    async loadAchExceptions() {
        try {
            this.loadingAchExceptions = true;
            const achExceptions = await PositivePayExceptionRequests.getAchExceptionsForWidget();
            this.achExceptionCount = achExceptions?.length ? achExceptions.length : 0;
        } catch (e) {
            throw new Error('Error fetching ACH exceptions');
        } finally {
            this.loadingAchExceptions = false;
        }
    }

    routeToDecisions(route: string) {
        this.navService.navigate(`/positive-pay/${route}`);
    }

    handleKeyUp(event: KeyboardEvent, route: string) {
        const { key } = event;
        if (key === 'Return') this.routeToDecisions(route);
    }

    renderAchExceptions() {
        if (!this.hasAchExceptionEntitlement) return nothing;
        return html`<tm-action-button
            .label=${'ACH'}
            .icon=${achIcon}
            .action=${() => this.routeToDecisions('ach-exceptions')}
            .keyup=${(event: KeyboardEvent) => this.handleKeyUp(event, 'ach-exceptions')}
            .approvals=${this.achExceptionCount}
            .disabled=${this.disabled}
        ></tm-action-button>`;
    }

    renderCheckExceptions() {
        if (!this.hasCheckExceptionEntitlement) return nothing;
        return html`<tm-action-button
            .label=${'Check'}
            .icon=${checkRangeIcon}
            .action=${() => this.routeToDecisions('check-exceptions')}
            .keyup=${(event: KeyboardEvent) => this.handleKeyUp(event, 'check-exceptions')}
            .approvals=${this.checkExceptionCount}
            .disabled=${this.disabled}
        ></tm-action-button>`;
    }

    renderPendingDecisionCounts() {
        if (this.loadingAchExceptions || this.loadingCheckExceptions)
            return html`<tm-loader card></tm-loader>`;
        if (!this.hasAchExceptionEntitlement && !this.hasCheckExceptionEntitlement) return nothing;
        return html`<div class="flex flex-wrap p-2.5">
            ${this.renderAchExceptions()} ${this.renderCheckExceptions()}
        </div>`;
    }

    render() {
        if (this.hasAchExceptionEntitlement || this.hasCheckExceptionEntitlement) {
            return html`
                <tm-card>
                    <h4>Positive Pay Decisions</h4>
                    ${this.renderPendingDecisionCounts()}
                </tm-card>
            `;
        }
        return nothing;
    }

    static get styles() {
        return [
            css`
                :host {
                    --tm-card-min-height: 150px;
                    --icon-transform-scale: scale(1.6);
                }
                h4 {
                    margin-left: 12px;
                    margin-top: 8px;
                    font-weight: 400;
                    color: var(--header-text-color);
                    font-size: 16px;
                    line-height: 28px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: PositivePayDecisionWidget;
    }
}
