import { TmBaseComponent } from '@treasury/presentation';
import { checkmarkIcon, circleCloseIcon, clockIcon } from '@treasury/presentation/assets/icons';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import './jhd-icon';

export const getFormattedStatus = (status: string) => {
    switch (status) {
        case 'Pending':
            return 'Pending Approval';
        case 'PendingApproval':
            return 'Pending Approval';

        case 'ApprovalRejected':
            return 'Approval Rejected';
        default:
            return status;
    }
};

export const getStatusColorToken = (status: string) => {
    switch (status) {
        case 'Scheduled':
        case 'Uninitiated':
            return '--secondary-text-color';

        case 'Pending':
        case 'Pending Approval':
        case 'PendingApproval':
            return '--warning-color';

        case 'Rejected':
        case 'Approval Rejected':
        case 'ApprovalRejected':
        case 'Cancelled':
        case 'Failed':
        case 'Expired':
            return '--error-color';

        case 'Initiated':
        case 'Transmitted':
        case 'Ready':
        case 'Posted':
            return '--success-color';

        default:
            return '--success-color';
    }
};

export const getStatusIcon = (status: string) => {
    switch (status) {
        case 'Scheduled':
        case 'Pending Approval':
        case 'PendingApproval':
        case 'Pending':
        case 'Uninitiated':
            return clockIcon;
        case 'Rejected':
        case 'Approval Rejected':
        case 'ApprovalRejected':
        case 'Cancelled':
        case 'Failed':
        case 'Expired':
            return circleCloseIcon;
        case 'Initiated':
        case 'Posted':
            return checkmarkIcon;
        default:
            return checkmarkIcon;
    }
};

export const tagName = 'jhd-status';
@customElement(tagName)
export class JhdStatus extends TmBaseComponent {
    @property({ type: String })
    status = '';

    @property({ type: Boolean, reflect: true })
    showLabel = false;

    renderLabel(formattedStatus: string) {
        if (!this.showLabel) return nothing;
        return html`&nbsp;<span class="text-sm status-label">${formattedStatus}</span>`;
    }

    render() {
        const formattedStatus = getFormattedStatus(this.status);

        return html`<jhd-icon
                class=${classMap({
                    'w-6': true,
                    label: this.showLabel,
                })}
                .icon=${getStatusIcon(formattedStatus)}
                style="--icon-fill-color: var(${getStatusColorToken(formattedStatus)})"
            ></jhd-icon
            >${this.renderLabel(formattedStatus)}`;
    }

    static get styles() {
        return [
            css`
                jhd-icon.label {
                    position: relative;
                    top: 7px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: JhdStatus;
    }
}
