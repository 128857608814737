import { TmBaseComponent } from '@treasury/presentation';
import { css, html, LitElement, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';

// eslint-disable-next-line no-shadow
enum ColorSchemes {
    Light = 'light',
    Dark = 'dark',
}

export const tagname = 'dark-mode-toggle';
@customElement(tagname)
export class DarkModeToggle extends TmBaseComponent {
    @state()
    _colorScheme = localStorage.getItem('color-scheme') || ColorSchemes.Light;

    get colorScheme() {
        return this._colorScheme;
    }

    _toggleColorScheme(color: ColorSchemes) {
        window.localStorage.setItem('color-scheme', color);
        document.documentElement.setAttribute(
            'theme',
            color === ColorSchemes.Dark ? 'dark' : 'light'
        );
    }

    renderButton(color: ColorSchemes) {
        const checked = this._colorScheme === color ? 'checked' : nothing;
        return html` <input
                class="option-input hidden appearance-none"
                id=${color}
                name="appearance"
                type="radio"
                checked=${checked}
                @click=${() => {
                    this._toggleColorScheme(color);
                }}
            /><label
                class="option-label inline-block w-24 py-1 rounded-lg text-sm text-center capitalize"
                for=${color}
                >${color}</label
            >`;
    }

    render() {
        return html`
            <div class="flex justify-between items-center">
                <div class="text-sm">Appearance</div>
                <div class="button-container flex border rounded-lg">
                    ${this.renderButton(ColorSchemes.Light)} ${this.renderButton(ColorSchemes.Dark)}
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            css`
                .button-container,
                .option-label {
                    border-color: var(--color-primary);
                    color: var(--color-primary);
                }
                .option-input:checked + .option-label {
                    margin: -1px;
                    background-color: var(--color-primary);
                    color: var(--nav-text-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagname]: DarkModeToggle;
    }
}
