import { css, html, PropertyValueMap } from 'lit';
import { property } from 'lit/decorators.js';
import { TmBaseComponent } from '../tm-base.component';

export class TmInputFieldBase extends TmBaseComponent {
    @property({ type: String })
    label!: string;

    @property({ type: String })
    value = '';

    @property({ type: String })
    placeholder?: string;

    @property({ type: Boolean })
    required = false;

    @property({ type: Boolean })
    readonly = false;

    @property({ type: Boolean })
    disabled = false;

    @property({ type: Boolean })
    invalid = false;

    @property({ type: String })
    helperText?: string;

    protected update(changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        !!this.value ? this.setAttribute('has-value', '') : this.removeAttribute('has-value');
        !!this.placeholder
            ? this.setAttribute('has-placeholder', '')
            : this.removeAttribute('has-placeholder');
        super.update(changedProperties);
    }

    static get styles() {
        return [
            css`
                :host {
                    position: relative;
                    margin: 4px 0 8px 0;
                }

                *::part(input-field) {
                    background: var(--input-background-color);
                    border: var(--input-border-color);
                    font-size: 1rem;
                    padding-left: 10px;
                }

                *::part(input-field)::after {
                    background: none;
                }

                * > input:placeholder-shown {
                    opacity: 0.5;
                }

                *::part(label) {
                    position: absolute;
                    z-index: 1;
                    top: 22px;
                    left: 8px;
                    background: none;
                    transition: all 0.2s cubic-bezier(0.1, 0.5, 0.1, 1) 0s;
                    pointer-events: none;
                    font-size: 14px;
                }

                :host::part(component) {
                    padding-top: 10px;
                }

                :host::part(component)::before {
                    display: none;
                }

                *[focused]::part(label),
                *[has-value]::part(label),
                *[has-placeholder]::part(label) {
                    padding-left: 4px;
                    padding-top: 4px;
                    padding-bottom: 4px;
                    background: var(--input-background-color);
                    border-radius: 3px;
                    font-size: 11px;
                    left: 4px;
                    top: 2px;
                }

                *[focused]:not([required])::part(label),
                *[has-value]:not([required])::part(label),
                *[has-placeholder]:not([required])::part(label) {
                    padding-right: 3px;
                }

                *[invalid][focused]::part(label)::after,
                *[invalid][has-value]::part(label)::after,
                *[invalid][has-placeholder]::part(label)::after {
                    content: '';
                    position: absolute;
                    inset: 0;
                    border-radius: inherit;
                    pointer-events: none;
                }

                *[focused]::part(input-field) {
                    border-color: var(--color-primary);
                }

                *[invalid]::part(input-field) {
                    color: var(--error-color);
                    border-color: var(--error-color);
                    background: var(--input-background-color);
                }

                *[invalid]::part(label) {
                    color: var(--error-color);
                }

                vaadin-text-field > input:placeholder-shown {
                    color: var(--hint-text-color);
                }

                :host(.helper-align-right) *::part(helper-text) {
                    text-align: right;
                    padding-right: 1em;
                }

                :host(.form-row-slotted) {
                    margin: 8px 0 8px;
                }

                :host(.form-row-slotted)::part(component) {
                    padding: 0px;
                }

                :host(.form-row-slotted)::part(input-field) {
                    border: none;
                    border-bottom: 1px solid #b0b0b0;
                    background-color: #f5f5f5;
                    border-radius: 4px 4px 0 0;
                }

                :host(.form-row-slotted) *[invalid]::part(input-field) {
                    background: rgba(201, 29, 7, 0.1);
                    border-color: var(--error-color);
                    color: var(--error-color);
                }
            `,
        ];
    }
}
