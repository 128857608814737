import { AccountModelDto } from '@treasury/api/bo';
import {
    AchBatchModelDto,
    AchFrequencyModelDto,
    CompanyAccountModelDto,
    LookupModelDto,
} from '@treasury/api/channel';
import { DomainEntity } from '../shared';
import { defaultAchBatchModelDto } from './data/ach.data';

export class AchTemplate extends DomainEntity<AchBatchModelDto> {
    public createDefault(): this {
        return new AchTemplate(defaultAchBatchModelDto) as this;
    }

    private _frequencyDisplay: string = '';

    private _offsetAccountDisplay: string = '';

    public get templateId() {
        return this.dto.id;
    }

    public get name() {
        return this.dto.name;
    }

    public get status() {
        return this.dto.status;
    }

    public get creditAmount() {
        return this.dto.creditAmount;
    }

    public get creditAmountFormatted() {
        return this.dto.creditAmount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }

    public set creditAmount(value: number) {
        this.dto.creditAmount = value;
    }

    public get debitAmount() {
        return this.dto.debitAmount;
    }

    public get debitAmountFormatted() {
        return this.dto.debitAmount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }

    public set debitAmount(value: number) {
        this.dto.debitAmount = value;
    }

    public get recipientTotalDebitAmount() {
        const total = this.recipients
            ?.filter(r => r.transactionType === 'DR')
            .reduce((acc, r) => acc + r.amount, 0);

        this.debitAmount = total ?? 0;

        return total
            ? total.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
              })
            : '$0.00';
    }

    public get recipientTotalCreditAmount() {
        const total = this.recipients
            ?.filter(r => r.transactionType === 'CR')
            .reduce((acc, r) => acc + r.amount, 0);

        this.creditAmount = total ?? 0;

        return total
            ? total.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
              })
            : '$0.00';
    }

    public get transactionId() {
        return this.dto.transactionId ?? '';
    }

    public get achCompany() {
        return this.dto.achCompany;
    }

    public get companyId() {
        return this.dto.achCompanyId;
    }

    public get achCompanyId() {
        return this.dto.achCompanyId;
    }

    public get companyName() {
        return this.dto.achCompanyName;
    }

    public get secCode() {
        return this.dto.secCode;
    }

    public get entryDescription() {
        return this.dto.entryDescription;
    }

    public set entryDescription(entryDescription: string) {
        this.dto.entryDescription = entryDescription;
    }

    public get offsetAccount() {
        return this.dto.offsetAccount as unknown as string | undefined | AccountModelDto;
    }

    public set offsetAccount(offsetAccount: string | undefined | AccountModelDto) {
        if (!offsetAccount) return;
        this.dto.offsetAccount = offsetAccount as unknown as LookupModelDto;
    }

    public get offsetAccountDisplay() {
        return this._offsetAccountDisplay;
    }

    public set offsetAccountDisplay(offsetAccountDisplay: string) {
        this._offsetAccountDisplay = offsetAccountDisplay;
    }

    public get recipients() {
        return this.dto.recipients;
    }

    public set recipients(recipients) {
        this.dto.recipients = recipients;
    }

    public get discretionaryData() {
        return this.dto.discretionaryData;
    }

    public set discretionaryData(discretionaryData: string) {
        this.dto.discretionaryData = discretionaryData;
    }

    public get restrictedPayment() {
        return this.dto.restricted;
    }

    public set restrictedPayment(restrictedPayment: boolean) {
        this.dto.restricted = restrictedPayment;
    }

    public get frequency() {
        return this.dto.frequency;
    }

    public set frequency(frequency: AchFrequencyModelDto) {
        if (!frequency) return;
        this.dto.frequency = frequency;
    }

    public get frequencyDisplay() {
        return this._frequencyDisplay;
    }

    public set frequencyDisplay(frequencyDisplay: string) {
        this._frequencyDisplay = frequencyDisplay;
    }

    public get audit() {
        return this.dto.audit;
    }

    public get permissions() {
        return this.dto.permissions ?? [];
    }

    public toPaymentDto(template: this) {
        return {
            ...template.toDto(),
            audit: null,
            batchId: template.toDto().id,
            offsetAccount: template.offsetAccount,
            securityMessage: {
                actionType: 'Initiate Payment From Batch',
            },
            status: null,
            templateName: null,
            permissions: [],
            recipients: template.recipients?.map(r => ({
                ...r,
                addenda: r.addendaBody ? [{ value: r.addendaBody }] : [],
            })),
        };
    }
}
