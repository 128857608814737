import { exists, isObject } from '@treasury/utils';
import { ChallengeType } from './challengeType';
import { ErrorType } from './errorType';
import { StatusType } from './statusType';

export interface SecurityMessage {
    actionType?: string | null;
    status?: StatusType | null;
    errorCode?: ErrorType | null;
    challengeMethodTypeId: ChallengeType | 0;
    oneTimePassword?: string | null;
    message?: string | null;
    methodUsed?: string;
    hasAlternate?: boolean;
    sendMessage?: string;
}

export type WithSecurityMessage<T = object> = T & {
    securityMessage: SecurityMessage;
};

export function isSecurityMessage<T>(obj: unknown): obj is T & SecurityMessage {
    const maybeSecurityMessage = obj as SecurityMessage;
    return isObject(obj) && 'challengeMethodTypeId' in maybeSecurityMessage;
}

export function hasSecurityMessage<T>(obj: T): obj is WithSecurityMessage<T> {
    const maybeWithSecurityMessage = obj as WithSecurityMessage<T>;
    return isObject(obj) && exists(maybeWithSecurityMessage.securityMessage);
}

export async function extractSecurityPayload<T>(resp: Response) {
    try {
        const json = await resp.clone().json();
        if (hasSecurityMessage<T>(json) || isSecurityMessage<T>(json)) {
            return json;
        }

        return undefined;
    } catch {
        return undefined;
    }
}

export function isLockedOrSuccessful<T>(obj: SecurityMessage | WithSecurityMessage<T>) {
    const securityMessage = hasSecurityMessage(obj) ? obj.securityMessage : obj;
    return (
        securityMessage.status === StatusType.Success ||
        securityMessage.errorCode === ErrorType.Locked
    );
}
