/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    CompleteSecureTokenConfigRequestModelDto,
    CompleteSecureTokenConfigResponseDto,
    ErrorHttpContentDto,
    GetSecureTokenConfigResponseModelDto,
    RecentLoginsModelDto,
    SecurityMessageModelDto,
    SecurityVerificationMethodModelDto,
    UserSecurityConfigModelDto,
    ValidateOneTimePasswordResponseModelDto,
    ValidateSecureTokenRequestModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class SecurityClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
 * No description
 *
 * @tags Security
 * @name SecurityCheckSecurity
 * @summary Checks to see if a user needs to verify their identity or tells the server to wait for an identity verification.
Is not used for security checks that involve amount thresholds.
 * @request POST:/security/checkSecurity
 */
    securityCheckSecurity = (
        securityMessage: SecurityMessageModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<SecurityMessageModelDto, ErrorHttpContentDto>({
            path: `/security/checkSecurity`,
            method: 'POST',
            body: securityMessage,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Security
     * @name SecurityVerifyMethod
     * @summary Verifies a security method and returns a status.
     * @request POST:/security/verifyMethod
     */
    securityVerifyMethod = (
        query: {
            /** If true, use given phone number for both SMS and automated phone calls. */
            useForBoth: string | null;
        },
        securityVerificationMethod: SecurityVerificationMethodModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<SecurityMessageModelDto, ErrorHttpContentDto>({
            path: `/security/verifyMethod`,
            method: 'POST',
            query: query,
            body: securityVerificationMethod,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
 * No description
 *
 * @tags Security
 * @name SecurityConfig
 * @summary Gets the user's current security configuration. This includes the security methods used and, optionally, a
preferred method.
 * @request GET:/security/config
 */
    securityConfig = (params: RequestParams = {}) =>
        this.http.request<UserSecurityConfigModelDto, ErrorHttpContentDto>({
            path: `/security/config`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Security
     * @name SecuritySetPreferredMethod
     * @summary Sets the user's preferred verification method (SMS or automated phone call) for Out-of-Band (OOB) verification.
     * @request GET:/security/setPreferredMethod
     */
    securitySetPreferredMethod = (
        query: {
            /** Options: Text / Call */
            methodType: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorHttpContentDto>({
            path: `/security/setPreferredMethod`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Security
     * @name SecurityRemoveMethod
     * @summary Removes an Out-Of-Band (OOB) verification contact method (SMS or automated phone call) for a user.
     * @request DELETE:/security/removeMethod
     */
    securityRemoveMethod = (
        query: {
            /** Options: Text / Call */
            methodType: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorHttpContentDto>({
            path: `/security/removeMethod`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Security
     * @name SecurityCompleteConfig
     * @summary Completes the security configuration with one of three options.
     * @request GET:/security/complete-oob-config
     */
    securityCompleteConfig = (
        query: {
            /** Options: Done / Skip / Remind */
            action: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorHttpContentDto>({
            path: `/security/complete-oob-config`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Security
     * @name SecurityRecentLogins
     * @summary Gets the recent logins.
     * @request GET:/security/recentLogins
     */
    securityRecentLogins = (params: RequestParams = {}) =>
        this.http.request<RecentLoginsModelDto, ErrorHttpContentDto>({
            path: `/security/recentLogins`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Security
     * @name SecurityCompleteSecureTokenConfig
     * @summary Completes the secure token config.
     * @request POST:/security/complete-secure-token-config
     */
    securityCompleteSecureTokenConfig = (
        query: {
            /**
             * The CompleteSecureTokenConfigActionType enumeration.
             * @format int32
             */
            actionType: number;
            /** The credential id. */
            credentialId: string | null;
            /** The token. */
            token: string | null;
            /** The PIN. */
            pin: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<CompleteSecureTokenConfigResponseDto, ErrorHttpContentDto>({
            path: `/security/complete-secure-token-config`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Security
     * @name SecurityCompleteSecureTokenConfig2
     * @summary Completes the secure token config.
     * @request POST:/security/completeTokenConfig
     */
    securityCompleteSecureTokenConfig2 = (
        request: CompleteSecureTokenConfigRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<CompleteSecureTokenConfigRequestModelDto, ErrorHttpContentDto>({
            path: `/security/completeTokenConfig`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Security
     * @name SecurityGetSecureTokenConfig
     * @summary Gets the secure token config.
     * @request GET:/security/get-secure-token-config
     */
    securityGetSecureTokenConfig = (params: RequestParams = {}) =>
        this.http.request<GetSecureTokenConfigResponseModelDto, ErrorHttpContentDto>({
            path: `/security/get-secure-token-config`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Security
     * @name SecurityValidateOneTimePassword
     * @summary Verifies the one-time password.
     * @request POST:/security/validateOneTimePassword
     */
    securityValidateOneTimePassword = (
        model: SecurityMessageModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ValidateOneTimePasswordResponseModelDto, ErrorHttpContentDto>({
            path: `/security/validateOneTimePassword`,
            method: 'POST',
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Security
     * @name SecurityValidateSecureToken
     * @summary Verifies a secure token.
     * @request POST:/security/validateSecureToken
     */
    securityValidateSecureToken = (
        model: ValidateSecureTokenRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ValidateSecureTokenRequestModelDto, ErrorHttpContentDto>({
            path: `/security/validateSecureToken`,
            method: 'POST',
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
