import { deepEquals } from '@treasury/utils';
import { SelectItem } from '@vaadin/select/src/vaadin-select';
import { add, format, isValid, startOfMonth, startOfWeek, startOfYear, sub } from 'date-fns';
import { PropertyValueMap, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { TmBaseComponent } from '../tm-base.component';
import './tm-date-picker';
import './tm-select';

export enum DateRangeType {
    None = '',
    Today = 'Today',
    SpecificDate = 'Specific Date',
    DateRange = 'Date Range',
    WeekToDate = 'Week to Date',
    MonthToDate = 'Month to Date',
    YearToDate = 'Year to Date',
}

export interface DateRange {
    rangeType: DateRangeType;
    startDate?: Date;
    endDate?: Date;
}

export const tagName = 'tm-date-range';
@customElement(tagName)
export class TmDateRange extends TmBaseComponent {
    @property({ type: String })
    label = 'Date Range';

    @property({ type: Boolean, reflect: true })
    required = false;

    @property({ type: Object })
    private range?: DateRange;

    @property({ type: Object })
    private _maxDate: Date | undefined;

    @property({ type: Object })
    private _minDate: Date | undefined;

    @state()
    private rangeType: DateRangeType = DateRangeType.None;

    @property({ type: Array })
    dateRangeOptions: DateRangeType[] = [
        DateRangeType.Today,
        DateRangeType.SpecificDate,
        DateRangeType.DateRange,
        DateRangeType.WeekToDate,
        DateRangeType.MonthToDate,
        DateRangeType.YearToDate,
    ];

    @state()
    private _startDate: Date | undefined;

    get startDate() {
        return this._startDate;
    }

    set startDate(value: Date | undefined) {
        this._startDate = this.zeroDateHours(value);
    }

    @state()
    private _endDate: Date | undefined;

    get endDate() {
        return this._endDate;
    }

    set endDate(value: Date | undefined) {
        this._endDate = this.zeroDateHours(value);
    }

    get maxDate() {
        return this._maxDate;
    }

    set maxDate(value: Date | undefined) {
        this._maxDate = this.zeroDateHours(value);
    }

    get minDate() {
        return this._minDate;
    }

    set minDate(value: Date | undefined) {
        this._minDate = this.zeroDateHours(value);
    }

    get rangeOptionsForSelect() {
        const rangeOptions: SelectItem[] = [];
        if (!this.required) {
            rangeOptions.push({ label: undefined, value: DateRangeType.None });
        }
        for (const rangeOption of this.dateRangeOptions) {
            rangeOptions.push({ label: rangeOption, value: rangeOption });
        }
        return rangeOptions;
    }

    get isValidDateRange() {
        if (this.rangeType !== DateRangeType.None && !this.startDate) return false;

        switch (this.rangeType) {
            case DateRangeType.SpecificDate:
                return this.startDate && isValid(this.startDate);
            case DateRangeType.DateRange:
                return (
                    this.startDate &&
                    isValid(this.startDate) &&
                    this.endDate &&
                    isValid(this.endDate)
                );
        }
        return true;
    }

    get startDateReadOnly() {
        return (
            this.rangeType !== DateRangeType.SpecificDate &&
            this.rangeType !== DateRangeType.DateRange
        );
    }

    get endDateReadOnly() {
        return this.rangeType !== DateRangeType.DateRange;
    }

    get maxStartDate() {
        if (this.endDate && this.rangeType == DateRangeType.DateRange) {
            return sub(this.endDate, { days: 1 });
        }
        return this.maxDate;
    }

    get minEndDate() {
        if (this.rangeType !== DateRangeType.DateRange) return undefined;
        if (!!this.startDate) {
            return add(this.startDate, { days: 1 });
        }
        return this.minDate;
    }

    protected update(changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        // If the range is updated, but has the same values as before then don't parse out the individual properties again. Dispatches redundant @selection events
        if (
            changedProperties.has('range') &&
            this.range &&
            (!deepEquals(changedProperties.get('range'), this.range) || !this.isValidDateRange)
        ) {
            this.parseRange();
        }

        if (
            changedProperties.has('rangeType') &&
            !changedProperties.has('_startDate') &&
            !changedProperties.has('_endDate')
        ) {
            this.updateDateSelections();
        }
        if (
            changedProperties.has('rangeType') ||
            changedProperties.has('_startDate') ||
            changedProperties.has('_endDate')
        ) {
            this.dispatchSelectionEvent();
        }
        super.update(changedProperties);
    }

    parseRange() {
        if (!this.range) {
            this.rangeType = DateRangeType.None;
            this.startDate = undefined;
            this.endDate = undefined;
        } else {
            const { rangeType, startDate, endDate } = this.range;
            this.rangeType = rangeType;
            this.startDate = startDate;
            this.endDate = endDate;
        }
    }

    dispatchSelectionEvent() {
        const { isValidDateRange, rangeType, startDate, endDate } = this;
        const detail = isValidDateRange
            ? ({
                  rangeType,
                  ...(startDate && { startDate }),
                  ...(endDate && { endDate }),
              } as DateRange)
            : false;

        this.dispatchEvent(new CustomEvent('selection', { detail }));
    }

    zeroDateHours(date: Date | undefined) {
        if (!date) return undefined;
        const fns = ['getHours', 'getMinutes', 'getSeconds', 'getMilliseconds'] as const;
        const zeroedDate = fns.every(fn => date[fn]() === 0);
        return zeroedDate ? date : new Date(date.setHours(0, 0, 0, 0));
    }

    isDateToday(date?: Date): Boolean {
        if (!date) return false;
        return date.getTime() === new Date().setHours(0, 0, 0, 0);
    }

    async updateDateSelections() {
        if (!this.rangeType) return;
        switch (this.rangeType as DateRangeType) {
            case DateRangeType.Today:
                this.startDate = new Date();
                this.endDate = undefined;
                break;
            case DateRangeType.None:
            case DateRangeType.SpecificDate:
            case DateRangeType.DateRange:
                this.startDate = undefined;
                this.endDate = undefined;
                break;
            case DateRangeType.WeekToDate:
                this.startDate = new Date(startOfWeek(new Date()));
                this.endDate = new Date();
                break;
            case DateRangeType.MonthToDate:
                this.startDate = new Date(startOfMonth(new Date()));
                this.endDate = new Date();
                break;
            case DateRangeType.YearToDate:
                this.startDate = new Date(startOfYear(new Date()));
                this.endDate = new Date();
                break;
        }
        return;
    }

    updateRangeTypeSelection() {
        if (!this.startDate && !this.endDate && !this.required) {
            this.rangeType = DateRangeType.None;
            return;
        }

        if (!!this.startDate && this.rangeType !== DateRangeType.DateRange) {
            if (!!this.endDate) {
                this.rangeType = DateRangeType.DateRange;
            } else {
                this.rangeType = DateRangeType.SpecificDate;
            }
        }

        if (this.rangeType === DateRangeType.SpecificDate && this.isDateToday(this.startDate)) {
            this.rangeType = DateRangeType.Today;
            return;
        }

        if (this.rangeType === DateRangeType.DateRange && this.isDateToday(this.endDate)) {
            switch (this.startDate?.getTime()) {
                case new Date(startOfWeek(new Date())).setHours(0, 0, 0, 0):
                    this.rangeType = DateRangeType.WeekToDate;
                    break;
                case new Date(startOfMonth(new Date())).setHours(0, 0, 0, 0):
                    this.rangeType = DateRangeType.MonthToDate;
                    break;
                case new Date(startOfYear(new Date())).setHours(0, 0, 0, 0):
                    this.rangeType = DateRangeType.YearToDate;
                    break;
            }
        }
    }

    renderRangeTypeSelect() {
        return html` <tm-select
            .label=${this.label}
            .items=${this.rangeOptionsForSelect}
            .value=${this.rangeType ?? DateRangeType.None}
            @value-changed=${(e: CustomEvent) => {
                this.rangeType = e.detail.value;
            }}
        ></tm-select>`;
    }

    renderStartDatePicker() {
        return html` <tm-date-picker
            placeholder="mm/dd/yyyy"
            .min=${this.minDate ? format(this.minDate, 'yyyy-MM-dd') : undefined}
            .max=${this.maxStartDate ? format(this.maxStartDate, 'yyyy-MM-dd') : undefined}
            .value=${this.startDate ? format(this.startDate, 'yyyy-MM-dd') : ''}
            @value-changed=${(e: CustomEvent) => {
                this.startDate = !!e.detail.value
                    ? new Date(e.detail.value.toString().replace(/-/g, '/'))
                    : undefined;
            }}
            @change=${() => this.updateRangeTypeSelection()}
            class="min-w-0 w-full"
            .readonly=${this.startDateReadOnly}
        ></tm-date-picker>`;
    }

    renderEndDatePicker() {
        if (this.rangeType == DateRangeType.SpecificDate || this.rangeType == DateRangeType.Today)
            return nothing;

        return html` <div class="px-2">-</div>
            <tm-date-picker
                placeholder="mm/dd/yyyy"
                .min=${this.minEndDate ? format(this.minEndDate, 'yyyy-MM-dd') : undefined}
                .max=${this.maxDate ? format(this.maxDate, 'yyyy-MM-dd') : undefined}
                .value=${this.endDate ? format(this.endDate, 'yyyy-MM-dd') : ''}
                @value-changed=${(e: CustomEvent) => {
                    this.endDate = !!e.detail.value
                        ? new Date(e.detail.value.toString().replace(/-/g, '/'))
                        : undefined;
                    this.dispatchSelectionEvent();
                }}
                @change=${() => this.updateRangeTypeSelection()}
                .readonly=${this.endDateReadOnly}
                class="min-w-0 w-full"
            ></tm-date-picker>`;
    }

    renderDatePickers() {
        if (this.rangeType === DateRangeType.None) return nothing;
        return html` <div class="flex items-center">
            ${this.renderStartDatePicker()} ${this.renderEndDatePicker()}
        </div>`;
    }

    render() {
        return [this.renderRangeTypeSelect(), this.renderDatePickers()];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmDateRange;
    }
}
