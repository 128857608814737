import { InternalTransferTemplateDetailModelDto } from '@treasury/api/channel';
import { Nullable } from '@treasury/utils';

export interface TransferTemplateDto extends InternalTransferTemplateDetailModelDto {
    amount: number;
}

export const nullTransferTemplate: Nullable<TransferTemplateDto> = {
    amount: 0,
    templateName: null,
    transferFromAccount: null,
    transferToAccount: null,
    fromAccount: null,
    toAccount: null,
    memo: null,
    audit: null,
    transferType: null,
    totalTransferAmount: 0,
    status: null,
    templateTransferInfo: null,
    completedApprovals: null,
    availableApprovers: null,
    completedApprovalCount: 0,
    numberOfApprovalsNeeded: 0,
    permissions: [
        {
            permission: '',
            id: 0,
            updatedBy: '',
            updatedDate: '',
        },
    ],
    id: 0,
    updatedBy: null,
    updatedDate: null,
};
