/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    GetArpAccountsResponseModelDto,
    ProductFeatureModelDto,
    UpdateCompanyArpProductModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class ArpClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Arp
     * @name ArpGetTreasuryAccounts
     * @summary Returns Treasury ARP Accounts
     * @request GET:/arp/getTreasuryaccounts
     */
    arpGetTreasuryAccounts = (
        query: {
            /**
             * Company specified by Treasury Id
             * @format int32
             */
            companyId: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<GetArpAccountsResponseModelDto, any>({
            path: `/arp/getTreasuryaccounts`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Arp
     * @name ArpSaveCompanyArpProduct
     * @summary Save ARP company products.
     * @request POST:/arp/SaveCompanyArpProduct
     */
    arpSaveCompanyArpProduct = (
        companyArpProduct: UpdateCompanyArpProductModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ProductFeatureModelDto, any>({
            path: `/arp/SaveCompanyArpProduct`,
            method: 'POST',
            body: companyArpProduct,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
