import { CompanyAccountModelDto, WireCompanyModelDto } from '@treasury/api/channel';
import { WiresService, WireTemplateQueryDto } from '@treasury/domain/wires';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-date-range';
import { DateRange, DateRangeType } from '@treasury/presentation/components/tm-date-range';
import { mapDateRangeToDto } from '@treasury/presentation/components/tm-date-range.mappings';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-multi-select';
import { MultiSelectItem } from '@treasury/presentation/components/tm-multi-select';
import '@treasury/presentation/components/tm-text-field';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { DeepReactive } from '@treasury/utils/lit-helpers';
import { css, html, nothing, PropertyValueMap } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { wireTemplateStatusItems } from '../data/wire-template-status-items';

const defaultCreatedDateRange: DateRange = {
    rangeType: DateRangeType.None,
};

export const tagName = 'wire-templates-filters-sheet';
@customElement(tagName)
export class WireTemplatesFiltersSheet extends TmBaseComponent {
    @InjectProperty()
    private declare wiresService: WiresService;

    @property({ type: Boolean })
    open = false;

    private _templateSelection = false;

    @property({ type: Boolean })
    get templateSelection() {
        return this._templateSelection;
    }

    set templateSelection(value) {
        this._templateSelection = value;
        this.statusItems = this._templateSelection
            ? wireTemplateStatusItems.filter(status => status.label === 'Ready')
            : wireTemplateStatusItems;
        this.statusItemsSelected = this.statusItems;
    }

    @state()
    private templateName = '';

    @state()
    private beneficiaryName = '';

    @state()
    private wireCompanyItems: MultiSelectItem[] = [];

    @state()
    private wireCompanyItemsSelected: MultiSelectItem[] = [];

    @state()
    private statusItems: MultiSelectItem[] = wireTemplateStatusItems;

    @state()
    private statusItemsSelected: MultiSelectItem[] = [];

    @state()
    private debitAccountItems: MultiSelectItem[] = [];

    @state()
    private debitAccountItemsSelected: MultiSelectItem[] = [];

    @state()
    private createdDateRange: DateRange = defaultCreatedDateRange;

    @DeepReactive()
    private validState = {
        wireCompany: true,
        status: true,
        debitAccount: true,
        createdDate: true,
    };

    protected async firstUpdated(
        _changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
    ): Promise<void> {
        try {
            const wireCompanies = await this.wiresService.getWireCompanies(true);
            this.wireCompanyItems = wireCompanies.map((wireCompany: WireCompanyModelDto) => ({
                label: wireCompany.name,
                value: wireCompany,
            }));
            const debitAccounts = await this.wiresService.fetchTransferAccounts();
            this.debitAccountItems = debitAccounts.map((debitAccount: CompanyAccountModelDto) => ({
                label: debitAccount.accountDisplayLabel ?? '',
                value: debitAccount,
            }));
            this.onResetForm();
            this.onApplyFilters();
        } catch (e) {
            console.error(e);
        }
    }

    private onResetForm() {
        this.templateName = '';
        this.beneficiaryName = '';
        this.wireCompanyItemsSelected = this.wireCompanyItems;
        this.debitAccountItemsSelected = this.debitAccountItems;
        this.statusItemsSelected = this.statusItems;
        this.createdDateRange = { ...defaultCreatedDateRange };
    }

    private toDto(): WireTemplateQueryDto {
        const {
            operator: createdDateType,
            dateFrom: createdDateStart,
            dateTo: createdDateEnd,
            specificDate: createdDate,
        } = mapDateRangeToDto(this.createdDateRange);

        return {
            ...(this.templateName && { templateName: this.templateName }),
            ...(this.beneficiaryName && { beneficiaryName: this.beneficiaryName }),
            wireCompanies: this.wireCompanyItemsSelected.map(
                item => item.value as WireCompanyModelDto
            ),
            statuses: this.statusItemsSelected.map(status => status.value),
            debitAccounts: this.debitAccountItemsSelected.map(
                item => item.value as CompanyAccountModelDto
            ),
            createdDateType: createdDateType!,
            ...(createdDateStart && { createdDateStart }),
            ...(createdDateEnd && { createdDateEnd }),
            ...(createdDate && { createdDate }),
            wireType: 'Both',
        };
    }

    onApplyFilters() {
        this.dispatchEvent(new CustomEvent('close'));
        this.dispatchEvent(
            new CustomEvent('applyFilters', {
                detail: this.toDto(),
            })
        );
    }

    renderStatusSelect() {
        if (this.statusItems.length <= 1) return nothing;
        return html`
            <tm-multi-select
                label="Status"
                .allowSelectAll=${true}
                .allSelectedText=${'All Wire Statuses'}
                .items=${this.statusItems}
                .selectedItems=${[...this.statusItemsSelected]}
                @selected-items-changed=${(e: CustomEvent) => {
                    this.statusItemsSelected = e.detail;
                    this.validState.status = !!this.statusItemsSelected.length;
                }}
            ></tm-multi-select>
        `;
    }

    render() {
        return html`<tm-bottom-sheet
            .open=${this.open}
            @close=${() => {
                this.dispatchEvent(new CustomEvent('close'));
            }}
        >
            <p slot="header-center">Filter Wire Templates</p>
            <p slot="header-right"></p>
            <tm-body>
                <form>
                    <tm-text-field
                        label="Template Name"
                        .value=${this.templateName}
                        @value-changed=${(e: CustomEvent) => {
                            this.templateName = e.detail.value;
                        }}
                    ></tm-text-field>
                    <tm-text-field
                        label="Beneficiary Name"
                        .value=${this.beneficiaryName}
                        @value-changed=${(e: CustomEvent) => {
                            this.beneficiaryName = e.detail.value;
                        }}
                    ></tm-text-field>
                    <tm-multi-select
                        label="Wire Company"
                        .allowSelectAll=${true}
                        .allSelectedText=${'All Wire Companies'}
                        .items=${this.wireCompanyItems}
                        .selectedItems=${[...this.wireCompanyItemsSelected]}
                        @selected-items-changed=${(e: CustomEvent) => {
                            this.wireCompanyItemsSelected = e.detail;
                            this.validState.wireCompany = !!this.wireCompanyItemsSelected.length;
                        }}
                    ></tm-multi-select>
                    ${this.renderStatusSelect()}
                    <tm-multi-select
                        label="Debit Account"
                        .allowSelectAll=${true}
                        .allSelectedText=${'All Debit Accounts'}
                        .items=${this.debitAccountItems}
                        .selectedItems=${[...this.debitAccountItemsSelected]}
                        @selected-items-changed=${(e: CustomEvent) => {
                            this.debitAccountItemsSelected = e.detail;
                            this.validState.debitAccount = !!this.debitAccountItemsSelected.length;
                        }}
                    ></tm-multi-select>
                    <tm-date-range
                        label="Created Date"
                        .range=${this.createdDateRange}
                        @selection=${(e: CustomEvent) => {
                            this.validState.createdDate = !!e.detail;
                            if (!!e.detail) {
                                this.createdDateRange = e.detail;
                            }
                        }}
                        .maxDate=${new Date()}
                    ></tm-date-range>
                </form>
            </tm-body>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: 'Apply Filters',
                        onClick: () => {
                            this.onApplyFilters();
                        },
                        disabled: Object.values(this.validState).some(isValid => !isValid),
                    },
                    {
                        importance: 'secondary',
                        text: 'Reset',
                        onClick: () => {
                            this.onResetForm();
                        },
                    },
                ] as ButtonConfig[]}
            >
            </tm-footer>
        </tm-bottom-sheet>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    --navbar-height: 60px;
                    height: 100%;
                }
                path {
                    fill: #ffffff;
                }

                jha-date-picker {
                    min-height: 400px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: WireTemplatesFiltersSheet;
    }
}
