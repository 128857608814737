import { Bank, WireBeneficiary } from '@treasury/domain/wires';
import { TmBaseComponent } from '@treasury/presentation';
import { PropertyValueMap, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

export const tagName = 'wire-beneficiary-read-only';
@customElement(tagName)
export class WireBeneficiaryReadOnly extends TmBaseComponent {
    @property({ type: Object })
    beneficiary: WireBeneficiary = new WireBeneficiary();

    @property({ type: Boolean })
    showIntermediaryBanks = false;

    @state()
    bank?: Bank;

    @state()
    intermediaryBanks?: Bank[];

    public async firstUpdated() {
        this.hydrateBanks();
    }

    protected updated(changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        if (changedProperties.has('beneficiary')) {
            this.hydrateBanks();
        }
    }

    private hydrateBanks() {
        if (this.beneficiary.bank) {
            this.bank = new Bank(this.beneficiary.bank);
        }

        if (this.beneficiary.intermediaryBanks) {
            this.intermediaryBanks = this.beneficiary.intermediaryBanks.map(bank => new Bank(bank));
        }
    }

    renderBankFields() {
        if (!this.bank) return nothing;
        return html` <jhd-data-field
                label="Routing Number"
                .value=${this.bank.bankId}
            ></jhd-data-field>
            <jhd-data-field label="Bank Name" .value=${this.bank.name}></jhd-data-field>
            <jhd-data-field label="Bank Address" .value=${this.bank.address}></jhd-data-field>`;
    }

    renderIntermediaryBanksFields() {
        if (this.intermediaryBanks && this.showIntermediaryBanks) {
            return html`<hr class="border-t-2 border-dashed border-[--border-color] mt-4" />
                <h4 class="mt-4">Intermediary Bank Information</h4>
                ${this.intermediaryBanks.map(
                    (bank, i) =>
                        html`${i > 0 ? html`<hr class="my-2" />` : ''}<jhd-data-field
                                label="Bank ID"
                                .value=${bank.bankId}
                            ></jhd-data-field>
                            <jhd-data-field label="Bank Name" .value=${bank.name}></jhd-data-field>
                            <jhd-data-field
                                label="Bank Country"
                                .value=${bank.country}
                            ></jhd-data-field>`
                )}`;
        }
        return nothing;
    }

    render() {
        return html`
            <h3 class="pb-3">${this.beneficiary.name}</h3>
            <jhd-data-field label="Address" .value=${this.beneficiary.address}></jhd-data-field>
            <jhd-data-field
                label="Account Number"
                .value=${this.beneficiary.accountNumber}
            ></jhd-data-field>
            <jhd-data-field
                .defaultValue=${!!this.beneficiary.notes}
                label="Notes"
                .value=${this.beneficiary.notes === '' ? 'none' : this.beneficiary.notes}
                .unemphasize=${this.beneficiary.notes === ''}
            ></jhd-data-field>
            ${this.renderBankFields()} ${this.renderIntermediaryBanksFields()}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: WireBeneficiaryReadOnly;
    }
}
