import { PendingUser } from '@treasury/domain/approvals/pending-user.entity';
import { ApprovalOptions, ApprovalVm } from '@treasury/presentation';

export class PendingUserViewModel extends ApprovalVm<PendingUser> {
    constructor(user: PendingUser) {
        super(user, ApprovalOptions.NA);
    }

    get id() {
        return this.value.toDto().id;
    }
}
