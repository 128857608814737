import { css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { TmBaseComponent } from '../tm-base.component';

export const tagName = 'tm-blocking-loader';
@customElement(tagName)
export class TmBlockingLoader extends TmBaseComponent {
    protected render(): unknown {
        return html`
            <div class="background">
                <span class="loader"></span>
            </div>
        `;
    }

    static get styles() {
        return css`
            :host {
                width: 100%;
                height: 100%;
            }
            .loader {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 48px;
                height: 48px;
                margin-top: -24px;
                margin-left: -24px;
                border: 5px solid #989898;
                border-bottom-color: transparent;
                border-radius: 50%;
                display: inline-block;
                box-sizing: border-box;
                animation: rotation 1s linear infinite;
            }

            .background {
                z-index: 9999;
                position: fixed;
                background: rgba(9, 17, 9, 0.75);
                opacity: 0;
                height: 100%;
                width: 100%;
                opacity: 1;
            }

            @keyframes rotation {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmBlockingLoader;
    }
}
