/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-classes-per-file */
import { NavigationService } from '@treasury/core/navigation';
import { AccountsService, TransactionDetails } from '@treasury/domain/accounts';
import { TmBaseComponent } from '@treasury/presentation';
import { checkIcon, depositIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-button';
import { DateRange } from '@treasury/presentation/components/tm-date-range';
import '@treasury/presentation/components/tm-footer';
import '@treasury/presentation/components/tm-loader';
import '@treasury/presentation/components/tm-table-header';
import { InjectProperty } from '@treasury/utils';
import '@vaadin/grid';
import { GridColumnBodyLitRenderer, columnBodyRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-sort-column.js';
import { TextFieldValueChangedEvent } from '@vaadin/text-field';
import { format } from 'date-fns';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { defaultTransactionDateRange } from '../data/default-account-transaction-filters';

const dollarUSLocale = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export class TransactionViewModel {
    // eslint-disable-next-line no-useless-constructor
    constructor(public source: TransactionDetails) {}

    public get dateFormatted() {
        return format(new Date(this.source.date), 'MM/dd/yyyy');
    }

    public get amountFormatted() {
        return this.source.debit
            ? dollarUSLocale.format(this.source.debit)
            : dollarUSLocale.format(this.source.credit);
    }
}

export const tagName = 'account-transactions-table';
@customElement(tagName)
export class AccountTransactionsTable extends TmBaseComponent {
    @InjectProperty()
    private declare client: AccountsService;

    @InjectProperty()
    private declare navService: NavigationService;

    @property({ type: Boolean })
    loading = true;

    @property({ type: Object })
    _transactions: TransactionViewModel[] = [];

    @property({ type: Object })
    transactionDateRange: DateRange = defaultTransactionDateRange;

    @state()
    private selectedTransactions: TransactionViewModel[] = [];

    @state()
    private filteredTransactions: TransactionViewModel[] = [];

    get transactions() {
        return this._transactions;
    }

    set transactions(value: TransactionViewModel[]) {
        this._transactions = value;
        this.filteredTransactions = this._transactions;
    }

    transactionClicked(account: TransactionViewModel) {
        this.selectedTransactions = account ? [account] : [];
        const [selectedTransaction] = this.selectedTransactions;
        this.dispatchEvent(
            new CustomEvent('transaction-selected', { detail: selectedTransaction })
        );
        /**
         * No endpoint to get transaction details by ID. Will use a bottom sheet for the time being.
         */
        // if (selectedTransaction) {
        //     this.navService.navigate(
        //         `${/${this.accountId}/transaction/${selectedTransaction.id}`
        //     );
        // }
    }

    filterResults(e: TextFieldValueChangedEvent) {
        const searchTerm = ((e.detail.value as string) || '').trim();
        const matchesTerm = (value: string) =>
            value.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
        this.filteredTransactions = this.transactions.filter(
            ({ source, amountFormatted }: TransactionViewModel) =>
                !searchTerm ||
                matchesTerm(source.description) ||
                matchesTerm(amountFormatted.toString())
        );
    }

    renderTransactionTypeColumn(transaction: TransactionViewModel) {
        const checkComponent = transaction.source.checkImageNumber
            ? html`<jhd-icon .icon=${checkIcon}></jhd-icon>`
            : nothing;
        const depositComponent =
            transaction.source.tranCodeDescription === 'Deposit' ? html`${depositIcon}` : nothing;
        return html`
            <div class="transaction-column flex items-center">
                <div class="flex flex-col pr-2 text-sm font-medium">
                    <span class="transaction-code"
                        >${transaction.source.tranCodeDescription ??
                        transaction.source.description}</span
                    ><span class="check-number text-xs text-[--secondary-text-color]"
                        >${transaction.source.checkNumber}</span
                    >
                </div>
                <div class="column-icon">${checkComponent}${depositComponent}</div>
            </div>
        `;
    }

    renderAmountColumn(transaction: TransactionViewModel) {
        return html`
            <div class="amount-column flex flex-col text-sm font-medium">
                <span class="amount">${transaction.amountFormatted}</span
                ><span class="date text-xs font-normal text-[--secondary-text-color]"
                    >${transaction.dateFormatted}</span
                >
            </div>
        `;
    }

    renderTableHeader() {
        return html`
            <tm-table-header
                .filterButton=${true}
                @filterButtonClick=${() => {
                    this.dispatchEvent(new CustomEvent('filter-account-transactions'));
                }}
                @filterStringChange=${(e: CustomEvent) => this.filterResults(e)}
            >
                <h2 class="font-medium">Transactions</h2>
                <h3 class="text-xs text-[--secondary-text-color]">
                    ${format(this.transactionDateRange.startDate as Date, 'MMM dd')} -
                    ${format(this.transactionDateRange.endDate as Date, 'MMM dd')}
                </h3></tm-table-header
            >
        `;
    }

    renderTable() {
        if (this.loading)
            return html`<div class="w-full pb-8"><tm-loader card class="mb-3"></tm-loader></div>`;
        if (this.filteredTransactions.length === 0)
            return html`<div
                class="no-results flex justify-center items-center h-64 text-[--header-text-color]"
            >
                No transactions found
            </div>`;
        return html` <vaadin-grid
            .items=${this.filteredTransactions}
            .selectedItems=${this.selectedTransactions}
            @active-item-changed=${({ detail }: CustomEvent) =>
                this.transactionClicked(detail.value)}
        >
            <vaadin-grid-sort-column
                auto-width
                flex-grow="1"
                .hidden=${screen.width > 500}
                ${columnBodyRenderer(
                    this
                        .renderTransactionTypeColumn as GridColumnBodyLitRenderer<TransactionViewModel>,
                    []
                )}
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
                text-align="end"
                auto-width
                flex-grow="0"
                .hidden=${screen.width > 500}
                ${columnBodyRenderer(
                    this.renderAmountColumn as GridColumnBodyLitRenderer<TransactionViewModel>,
                    []
                )}
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
                path="description"
                header=""
                width="175px"
                flex-grow="1"
                .hidden=${screen.width < 500}
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
                path="amount"
                header=""
                width="175px"
                flex-grow="1"
                text-align="end"
                .hidden=${screen.width < 500}
            ></vaadin-grid-sort-column>
        </vaadin-grid>`;
    }

    render() {
        return html` ${this.renderTableHeader()}${this.renderTable()} `;
    }

    static get styles() {
        return [
            css`
                :host {
                    width: 100vw;
                    margin-bottom: 8px;
                    box-shadow: var(--content-shadow);
                    background-color: var(--primary-background);
                }
                vaadin-grid {
                    border: none;
                }
                vaadin-grid-cell-content {
                    padding: 6px 16px 6px 0;
                }
                vaadin-grid::part(first-column-cell) {
                    margin-left: 16px;
                }
                vaadin-grid::part(selected-row-cell) {
                    background-image: none;
                    box-shadow: none;
                }
                .header-actions svg {
                    display: inline-block;
                }
                .column-icon > svg > path {
                    fill: var(--color-primary);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AccountTransactionsTable;
    }
}
