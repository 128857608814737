/* eslint-disable @treasury/no-date */

const RE_AMERICAN = /^\d\d\/\d\d\/\d\d\d\d$/;
const RE_ISO_DATE = /^\d\d\d\d-\d\d-\d\d$/;

function twoDigits(n) {
    if (n < 10) {
        return `0${n}`;
    }
    return n.toString();
}

function createDateFromIso(isoString) {
    const yearMonthDate = isoString.split('-').map(part => parseInt(part));
    return new Date(yearMonthDate[0], yearMonthDate[1] - 1, yearMonthDate[2]);
}

function createDateFromAmericanFormat(sourceDate) {
    const monthDateYear = sourceDate.split('/').map(part => parseInt(part));
    return new Date(monthDateYear[2], monthDateYear[0] - 1, monthDateYear[1]);
}

function createDateFromSource(sourceDate) {
    let date = sourceDate;

    if (typeof sourceDate === 'string') {
        [date] = date.split('T');

        if (RE_AMERICAN.test(date)) {
            return createDateFromAmericanFormat(date);
        }

        if (RE_ISO_DATE.test(date)) {
            return createDateFromIso(date);
        }
    }

    if (sourceDate && typeof sourceDate.valueOf === 'function') {
        const value = sourceDate.valueOf();
        if (typeof value === 'number') {
            return new Date(value);
        }
    }

    throw new Error(
        `Invalid argument (${typeof sourceDate} ${JSON.stringify(
            sourceDate
        )}) passed to FiDate() constructor. Valid arguments are a native Date object, another FiDate object, or a string in the form YYYY-MM-DD or MM/DD/YYYY.`
    );
}

export class FiDate {
    /**
     * Create an `FiDate` instance.
     * Defaults to today.
     *
     * @param {(string|Date|FiDate|number)} sourceDate A string in the form `YYYY-MM-DD`, `MM/DD/YYYY`,
     * a native `Date` object, an `FiDate` object, or a number representing a date in milliseconds.
     */
    constructor(sourceDate = new Date()) {
        this._date = createDateFromSource(sourceDate);
    }

    /**
     * Returns Date object for noon of given date
     */
    get date() {
        const date = new Date(this._date.getTime());
        return new Date(date.setHours(12, 0, 0, 0));
    }

    /**
     * Get the ISO 8601 representation of the date
     * @returns {string} - a string in the form YYYY-MM-DD
     */
    toIsoDate() {
        return `${this._date.getFullYear()}-${twoDigits(this._date.getMonth() + 1)}-${twoDigits(
            this._date.getDate()
        )}`;
    }

    /**
     * A number used for comparing two dates
     * @returns {number} - the number of milliseconds since the epoch (same as `Date#valueOf()`)
     */
    valueOf() {
        return new Date(
            this._date.getFullYear(),
            this._date.getMonth(),
            this._date.getDate()
        ).valueOf();
    }

    /**
     * Get the user-friendly representation of the date
     * @returns {string} - a string in the form MM/DD/YYYY
     */
    toString() {
        return `${twoDigits(this._date.getMonth() + 1)}/${twoDigits(
            this._date.getDate()
        )}/${this._date.getFullYear()}`;
    }

    /**
     * Adds year, month, and days and returns a new FiDate
     * @private
     * @param {number} years - the number of years to add
     * @param {number} months - the number of months to add
     * @param {number} days - the number of days to add
     * @returns {FiDate} - a new FiDate object
     */
    plus(years, months, days) {
        const sumDate = new Date(
            this._date.getFullYear() + years,
            this._date.getMonth() + months,
            this._date.getDate() + days
        );
        return new FiDate(sumDate);
    }

    /**
     * Adds days and returns a new FiDate
     * @param {number} days - the number of days to add
     * @returns {FiDate} - a new FiDate object
     */
    plusDays(days) {
        return this.plus(0, 0, days);
    }

    /**
     * Adds weeks and returns a new FiDate
     * @param {number} weeks - the number of weeks to add
     * @returns {FiDate} - a new FiDate object
     */
    plusWeeks(weeks) {
        return this.plus(0, 0, weeks * 7);
    }

    /**
     * Adds months and returns a new FiDate
     * @param {number} months - the number of months to add
     * @returns {FiDate} - a new FiDate object
     */
    plusMonths(months) {
        return this.plus(0, months, 0);
    }

    /**
     * Adds quarters and returns a new FiDate
     * @param {number} quarters - the number of quarters to add
     * @returns {FiDate} - a new FiDate object
     */
    plusQuarters(quarters) {
        return this.plus(0, quarters * 3, 0);
    }

    /**
     * Adds years and returns a new FiDate
     * @param {number} years - the number of years to add
     * @returns {FiDate} - a new FiDate object
     */
    plusYears(years) {
        return this.plus(years, 0, 0);
    }

    /**
     * Compares two FiDate objects
     * @param {FiDate} otherDate - the other FiDate object to compare to
     * @returns {boolean} - true if the two dates are equal, false otherwise
     */
    equals(other) {
        return this.toIsoDate() === new FiDate(other).toIsoDate();
    }

    /**
     * @returns {FiDate} - the first day of the week
     */
    firstDayOfWeek() {
        return this.plusDays(0 - this._date.getDay());
    }

    /**
     * @returns {FiDate} - the first day of the month
     */
    firstDayOfMonth() {
        return new FiDate(new Date(this._date.getFullYear(), this._date.getMonth(), 1));
    }

    /**
     * @returns {FiDate} - the first day of the quarter
     */
    firstDayOfQuarter() {
        const quarter = Math.floor(this._date.getMonth() / 3);
        return new FiDate(new Date(this._date.getFullYear(), quarter * 3, 1));
    }

    /**
     * @returns {FiDate} - the first day of the year
     */
    firstDayOfYear() {
        return new FiDate(new Date(this._date.getFullYear(), 0, 1));
    }
}
