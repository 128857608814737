import '@banno/jha-wc/src/forms/jha-form-switch/jha-form-switch.js';
import { css, html, nothing, PropertyValueMap } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { TmBaseComponent } from '../tm-base.component';

export const tagName = 'tm-toggle';
@customElement(tagName)
export class TmToggle extends TmBaseComponent {
    constructor() {
        super();
        this.addEventListener('click', event => {
            if (this.disabled) {
                event.preventDefault();
                event.stopImmediatePropagation();
                event.stopPropagation();
            }
        });
    }

    @property({ type: Boolean, attribute: true, reflect: true })
    public disabled = false;

    @property({ type: Boolean, attribute: true, reflect: true })
    public checked = false;

    @property({ type: String })
    public label?: string;

    protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        if (_changedProperties.has('label')) {
            !!this.label ? this.setAttribute('has-label', '') : this.removeAttribute('has-label');
        }
    }

    renderLabel() {
        if (!this.label) return nothing;
        return html`<label for="tmToggle">${this.label}</label>`;
    }

    render() {
        return html`
            ${this.renderLabel()}
            <jha-form-switch
                id="tmToggle"
                ?checked=${this.checked}
                ?disabled=${this.disabled}
            ></jha-form-switch>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: inline-block;
                    --jha-form-switch-active-color: var(--tm-toggle-active);
                }

                :host([disabled]) label {
                    color: var(--lumo-disabled-text-color);
                    -webkit-text-fill-color: var(--lumo-disabled-text-color);
                }

                :host([has-label]) {
                    padding-top: var(--lumo-space-m);
                }

                label {
                    color: var(--lumo-secondary-text-color);
                    font-weight: 500;
                    font-size: var(--lumo-font-size-s);
                    line-height: 1;
                    white-space: nowrap;
                }

                jha-form-switch {
                    display: block;
                }

                :host(.form-row-slotted) {
                    text-align: right;
                }

                :host(.form-row-slotted) jha-form-switch {
                    display: inline-block;
                    margin: 8px 0 10px;
                }

                :host(.form-row-slotted) jha-form-switch::part(toggle)::after {
                    box-shadow: var(--jha-switch-shadow-focus, 0 1px 1px 0px rgba(0, 0, 0, 1));
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmToggle;
    }
}
