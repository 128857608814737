import {
    AchBatchFilterModelDto,
    AchBatchModelDto,
    AchBatchSummaryModelDto,
    AchCompanyModelDto,
    AchEditableBatchModelDto,
    AchFrequencyModelDto,
    AchPaymentModelDto,
    AchSettingsModelDto,
    CompanyAccountModelDto,
    LookupModelDto,
    ModelBaseDto,
} from '@treasury/api/channel';

export const defaultModelBaseDto: ModelBaseDto = {
    id: 0,
    updatedDate: '',
};

export const defaultAchCompanyModelDto: AchCompanyModelDto = {
    ...defaultModelBaseDto,
    companyName: '',
    companyId: '',
    entryDescription: '',
    discretionaryData: '',
    totalDailyLimit: 0,
    notOnUsTransactionTypes: '',
    batchBalanceRequirements: '',
    onUsTransactionsRequired: 0,
    onUsAmountRequiredPerBatch: 0,
    isActive: false,
    allowUnbalancedPayments: false,
    companyGuidUniqueId: '',
};

export const defaultAchFrequencyModelDto: AchFrequencyModelDto = {
    ...defaultModelBaseDto,
    noEndDate: false,
    repeatOnDay1: 0,
    repeatOnDay2: 0,
    type: '',
};

export const defaultLookupModelDto: LookupModelDto = {
    ...defaultModelBaseDto,
};

export const defaultAchSettingsModelDto: AchSettingsModelDto = {
    achCompanyMatchType: 0,
    allowStateTaxPayments: false,
    allowFederalTaxPayments: false,
    allowTaxPayments: false,
    allowOnUsAccessManagement: false,
    allowUnbalancedPayments: false,
    allowChildSupportPayments: false,
    allowInternationalPayments: false,
};

export const defaultAchBatchModelDto: AchBatchModelDto = {
    ...defaultModelBaseDto,
    name: '',
    achCompanyName: '',
    achCompanyId: '',
    secCode: '',
    discretionaryData: '',
    entryDescription: '',
    restricted: false,
    lastUpdated: '',
    debitAmount: 0,
    creditAmount: 0,
    numberOfApprovalsNeeded: 0,
    status: '',
    successMessage: '',
    achCompany: defaultAchCompanyModelDto,
    frequency: defaultAchFrequencyModelDto,
    offsetAccount: defaultLookupModelDto,
    achPaymentTypeId: 0,
};

export const defaultAchBatchSummaryModelDto: AchBatchSummaryModelDto = {
    ...defaultAchBatchModelDto,
    isEdited: false,
    numberOfRecipients: 0,
    batchUniqueId: 0,
    hasAccess: false,
    achPaymentDraftId: 0,
    isValid: false,
    createdBy: 0,
    updatedByUserId: 0,
};

export const defaultAchEditableBatchModelDto: AchEditableBatchModelDto = {
    ...defaultModelBaseDto,
    batch: defaultAchBatchModelDto,
    settings: defaultAchSettingsModelDto,
};

export const defaultCompanyAccountModelDto: CompanyAccountModelDto = {
    ...defaultModelBaseDto,
    name: '',
    number: '',
    status: '',
    isDeleted: false,
    showAccountNickName: false,
    transferAmount: 0,
    allowLoanPaymentPastDue: false,
    accountUniqueId: '',
};

export const defaultAchFilterDto: AchBatchFilterModelDto = {
    ...defaultModelBaseDto,
    achCompanyList: [],
    batchName: '',
    creditAmountType: 'Specific Amount',
    debitAmountType: 'Specific Amount',
    secCode: 'All',
    status: 'All',
    taxTemplateType: 3,
    updatedDateType: 'Specific Date',
};

export const defaultAchPaymentModelDto: AchPaymentModelDto = {
    ...defaultModelBaseDto,
    type: '',
    recipients: [],
    frequency: defaultAchFrequencyModelDto,
    parentFrequency: defaultAchFrequencyModelDto,
    applyUpdatesToBatch: false,
    parentId: 0,
    batchId: 0,
    offsetAccount: defaultLookupModelDto,
    name: '',
    templateName: '',
    parentFrequencyType: '',
    achCompanyName: '',
    achCompanyId: '',
    secCode: '',
    isEdited: false,
    discretionaryData: '',
    entryDescription: '',
    restricted: false,
    debitAmount: 0,
    creditAmount: 0,
    errorMessage: '',
    transactionId: '',
    status: '',
    successMessage: '',
    numberOfApprovalsNeeded: 0,
    achCompany: defaultAchCompanyModelDto,
    canReverseFull: false,
    canReverseTransactions: false,
    achPaymentDraftId: 0,
    achPaymentTypeId: 0,
    achPaymentTypeName: '',
    achPaymentTypeDescription: '',
    createdDate: '',
};
