/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { NavigationService } from '@treasury/core/navigation';
import {
    ManyToManyTransfer,
    ManyToOneTransfer,
    OneToManyTransfer,
    OneToOneTransfer,
    TransferService,
    TransferTemplateDetails,
    TransferTransaction,
} from '@treasury/domain/transfers';
import { NotificationService, TmContainer } from '@treasury/presentation';
import { Frequency } from '@treasury/presentation/components/tm-frequency.types';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { PropertyValueMap, css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import { TransferAccountVm } from '../data/transfer-account-view-model';
import '../partials/confirm-transfer-payment-step';
import '../partials/create-transfer-payment-step';
import '../partials/review-transfer-payment-step';
import '../partials/transfer-create-type-step';
import { TransferViewModel } from '../types/transfer-view-model.type';
import { TransferWorkflowParameters } from '../types/transfer-workflow-parameters.type';

enum Step {
    Init,
    Select,
    Create,
    Review,
    Confirm,
}

export const tagName = 'transfer-workflow-container';
@customElement(tagName)
export class TransferWorkflowContainer extends TmContainer {
    constructor() {
        super();
        this.headerService.configure({
            title: 'Create Transfer',
            backAction: () => this.navService.navigate(`/transfers`),
        });
    }

    @InjectProperty()
    private declare transferService: TransferService;

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    private workflow = '';

    @state()
    private action = '';

    @state()
    private type = '';

    @state()
    private step = Step.Init;

    @state()
    private transfer = [] as any;

    @state()
    private template?: TransferTemplateDetails;

    @state()
    private formData?:
        | {
              fromAccount: TransferAccountVm;
              toAccount: TransferAccountVm;
              frequency?: Frequency;
              amount: string;
              memo: string;
              isTemplate: boolean;
              transactions: [];
              transferType: string;
              transferTotal: string;
              templateName?: string;
          }
        | undefined;

    @state()
    private accountsMap = new Map<string, TransferAccountVm>();

    @state()
    private isTemplate = false;

    async firstUpdated() {
        await this.setParams();
        if (this.id === 'new') {
            this.loading = false;
            this.step = Step.Select;
        } else {
            await this.tryFetch(
                () =>
                    Promise.all([
                        this.transferService.getTransferTemplate(this.id),
                        this.transferService.getTransferTemplateDetails(this.id),
                    ]),
                resp => {
                    [this.transfer, this.template] = resp;
                    this.step = Step.Create;
                },
                err => {
                    this.notificationService.renderError(err as Error);
                    this.loading = false;
                }
            );
        }
    }

    protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        if (_changedProperties.has('step')) {
            switch (this.step) {
                case Step.Select:
                    this.headerService.configure({
                        title: 'Create Transfer',
                        backAction: () => this.navService.navigate(`/transfers`),
                    });
                    break;

                case Step.Create:
                    this.headerService.configure({
                        title: 'Create Transfer',
                        backAction: () => {
                            this.formData = this.formData = undefined;
                            this.isTemplate
                                ? this.navService.navigate(
                                      `/transfers/payment/one-to-one/create/new`
                                  )
                                : (this.step = Step.Select);
                        },
                    });
                    break;
                case Step.Review:
                    this.headerService.configure({
                        title: 'Review Transfer',
                        backAction: () => (this.step = Step.Create),
                    });
                    break;
                default:
                    break;
            }
        }
    }

    private async setParams() {
        const { params } = await this.navService.getRouteData<TransferWorkflowParameters>();
        const { action, type, workflow, id } = params;
        this.workflow = workflow;
        this.action = action;
        this.type = type;
        this.id = id;
        this.isTemplate = this.id !== 'new';
    }

    submitTransfer(detail: TransferViewModel) {
        switch (detail.transferType) {
            case 'one':
                this.submitOneToOneTransfer(detail);
                break;
            case 'oneToMany':
                this.submitOneToManyTransfer(detail);
                break;
            case 'manyToOne':
                this.submitManyToOneTransfer(detail);
                break;
            default:
                break;
        }
    }

    async submitOneToOneTransfer(detail: TransferViewModel) {
        const { fromAccount, toAccount, amount, frequency, memo } = detail;
        try {
            this.loading = true;
            const response = (await this.transferService.createOneToOneTransfer({
                fromAccount,
                toAccount,
                amount,
                frequency,
                memo,
            })) as OneToOneTransfer;
            if (response.errorSummary?.summaryMessage) {
                throw new Error(response.errorSummary.summaryMessage);
            }
            this.transfer = response;
            this.step = Step.Confirm;
        } catch (err) {
            this.notificationService.renderError(err as Error);
        } finally {
            this.loading = false;
        }
    }

    async submitOneToManyTransfer(detail: TransferViewModel) {
        const { transactions } = detail;
        try {
            this.loading = true;
            const response = (await this.transferService.createOneToManyTransfer({
                transactions,
            })) as OneToManyTransfer;
            if (response.errorSummary?.summaryMessage) {
                throw new Error(response.errorSummary.summaryMessage);
            }
            this.transfer = response;
            this.step = Step.Confirm;
        } catch (err) {
            this.notificationService.renderError(err as Error);
        } finally {
            this.loading = false;
        }
    }

    async submitManyToOneTransfer(detail: TransferViewModel) {
        const { transactions } = detail;
        try {
            this.loading = true;
            const response = (await this.transferService.createManyToOneTransfer({
                transactions,
            })) as ManyToOneTransfer;
            if (response.errorSummary?.summaryMessage) {
                throw new Error(response.errorSummary.summaryMessage);
            }
            this.transfer = response;
            this.step = Step.Confirm;
        } catch (err) {
            this.notificationService.renderError(err as Error);
        } finally {
            this.loading = false;
        }
    }

    async submitManyToManyTransfer(detail: TransferViewModel) {
        const { fromAccount, toAccount, amount, frequency, memo } = detail;
        try {
            this.loading = true;
            const response = (await this.transferService.createManyToManyTransfer({
                fromAccount,
                toAccount,
                amount,
                frequency,
                memo,
            })) as ManyToManyTransfer;
            if (response.errorSummary?.summaryMessage) {
                throw new Error(response.errorSummary.summaryMessage);
            }
            this.transfer = response;
            this.step = Step.Confirm;
        } catch (err) {
            this.notificationService.renderError(err as Error);
        } finally {
            this.loading = false;
        }
    }

    renderBlockingLoader() {
        if (!this.loading) return nothing;
        return html`<tm-blocking-loader></tm-blocking-loader>`;
    }

    renderStep() {
        switch (this.step) {
            case Step.Select:
                return html`<transfer-create-type-step
                    @create-one-to-one=${() => {
                        this.step = Step.Create;
                    }}
                ></transfer-create-type-step>`;
            case Step.Create:
                return html`<create-transfer-payment-step
                    @submit=${(e: CustomEvent) => {
                        this.step = Step.Review;
                        this.transfer = e.detail.transfer;
                        this.formData = e.detail.formData;
                    }}
                    @setAccounts=${(e: CustomEvent) => {
                        this.accountsMap = e.detail;
                    }}
                    .accountsMap=${this.accountsMap}
                    .template=${this.template}
                    .isTemplate=${this.isTemplate}
                    .transfer=${this.transfer}
                    .formData=${this.formData}
                ></create-transfer-payment-step>`;
            case Step.Review:
                return html`<review-transfer-payment-step
                    .transfer=${this.formData}
                    @confirm=${({ detail }: CustomEvent) => this.submitTransfer(detail)}
                ></review-transfer-payment-step>`;
            case Step.Confirm:
                return html`<confirm-transfer-payment-step
                    .transfers=${this.transfer.transactions}
                    .isTemplate=${this.transfer.isTemplate}
                    @create-new-transfer=${() => {
                        this.step = Step.Select;
                        if (this.isTemplate) {
                            this.navService.navigate(`/transfers/payment/one-to-one/create/new`);
                        }
                        this.transfer = {};
                        this.formData = undefined;
                    }}
                ></confirm-transfer-payment-step>`;
            default:
                return nothing;
        }
    }

    render() {
        return [this.renderBlockingLoader(), this.renderStep()];
    }

    static get styles() {
        return [
            css`
                :host {
                    height: 100%;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TransferWorkflowContainer;
    }
}
