import corePagingParameters from '../../../shared/utilities/core-paging-parameters.js';
import { downloadBlob } from '../../../utilities/file-handling.js';
import {
    mapAccountsBasedOnUserConfiguration,
    mapAchExceptionToFilterLookup,
    mapSaveAchFilterRequest,
    mapSearchAchFilterRequest,
    mapSearchAchFilterResponse,
} from '../../mappings/ach-filters/ach-filter-search.js';
import AccountRequests from '../../requests/account/account.js';
import AchFilterRequests from '../../requests/ach/ach-filter-requests.js';
import { getAllAchBanks } from '../../requests/ach/bank-service.js';
import { UserRequests } from '../../requests/users/users.js';

let exceptionCache = {};
export default class AchFilterServices {
    static async search({ sort, startIndex, pageSize, parameters }) {
        const body = mapSearchAchFilterRequest(parameters);
        const pagingParameters = corePagingParameters('accountNumber', sort, startIndex, pageSize);

        const results = await AchFilterRequests.search({
            ...body,
            pagingParameters,
        });
        const data = mapSearchAchFilterResponse(results.achFilters);
        return { data, totalCount: results.paging.totalCount };
    }

    static async download(parameters) {
        const body = mapSearchAchFilterRequest(parameters.filter);
        const formattedParameters = { ...body, downloadType: parameters.type };
        const result = await AchFilterRequests.download(formattedParameters);

        const type = parameters.type.toLowerCase();
        const blob = new Blob([result], {
            type: type === 'pdf' ? `application/pdf` : `application/octet-stream`,
        });
        const filename = `ACH Exceptions Filter Rules.${type}`;
        return downloadBlob(blob, filename);
    }

    static getAccountSettings() {
        return AccountRequests.getCurrentUserAccountSettings();
    }

    static async getAccountsAll(accountSettings) {
        let accounts;
        try {
            accounts = await AchFilterRequests.getAccountsAll();
        } catch (ex) {
            console.log(ex);
        }
        return mapAccountsBasedOnUserConfiguration(accountSettings, accounts);
    }

    static async getAccountsCreate() {
        const accountSettings = await AccountRequests.getCurrentUserAccountSettings();
        let accounts;
        try {
            accounts = await AchFilterRequests.getAccountsCreate();
        } catch (ex) {
            console.log(ex);
        }
        return mapAccountsBasedOnUserConfiguration(accountSettings, accounts);
    }

    static async getAccountInfo(accountNumber, accountUniqueId) {
        const accounts = await AchFilterRequests.getAccounts();
        if (accountUniqueId) {
            return accounts.find(a => a.accountUniqueId === accountUniqueId);
        }
        return accounts.find(
            a => a.accountNumber.replace(/\D/g, '') === accountNumber.replace(/\D/g, '')
        );
    }

    static async getAccountUniqueIdById(id) {
        return UserRequests.getAccountUniqueIdById(id);
    }

    static async getAbaNumber() {
        return getAllAchBanks();
    }

    static async getAccountMasterSettings(accountUniqueId) {
        return AchFilterRequests.getAccountMasterSettings(accountUniqueId);
    }

    static async getAuditLog(AchFilterUniqueId) {
        return AchFilterRequests.getAuditLog(AchFilterUniqueId);
    }

    static async saveFilter(filter) {
        const body = mapSaveAchFilterRequest(filter);
        return AchFilterRequests.saveFilter(body);
    }

    static async findAchExceptions(filter) {
        const exceptions = await AchFilterRequests.findAchExceptions(filter);
        exceptionCache = exceptions;
        return exceptions;
    }

    static async lookupException(filterException) {
        if (exceptionCache?.achExceptionDetails) {
            const accountUniqueId = await AchFilterServices.getAccountUniqueIdById(
                filterException.accountId
            );
            const mappedException = mapAchExceptionToFilterLookup(filterException, accountUniqueId);
            return Promise.resolve(mappedException);
        }
        return Promise.resolve([]);
    }

    static async mapException(exception) {
        const accountUniqueId = await AchFilterServices.getAccountUniqueIdById(exception.accountId);
        const mappedException = mapAchExceptionToFilterLookup(exception, accountUniqueId);
        return Promise.resolve(mappedException);
    }

    static approveFilter(AchFilterUniqueId) {
        return AchFilterRequests.approveFilter(AchFilterUniqueId);
    }

    static deleteFilter(AchFilterUniqueId) {
        return AchFilterRequests.deleteFilter(AchFilterUniqueId);
    }

    static rejectFilter(AchFilterUniqueId) {
        return AchFilterRequests.rejectFilter(AchFilterUniqueId);
    }

    static async findEligibleApprovers(AchFilterUniqueId) {
        return AchFilterRequests.findEligibleApprovers(AchFilterUniqueId);
    }
}
