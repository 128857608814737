import { BillPaymentQuery } from '@treasury/domain/bill-pay/bill-pay.service';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-footer';
import { DeepReactive } from '@treasury/utils/lit-helpers';
import { isBefore, isEqual } from 'date-fns';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

export const tagName = 'payment-filters-sheet';
@customElement(tagName)
export class PaymentFiltersSheet extends TmBaseComponent {
    @property({ type: Boolean })
    open = false;

    @DeepReactive()
    private filterData: BillPaymentQuery = {
        startDate: '',
        endDate: '',
        type: 1,
    };

    get isValid() {
        if (!this.filterData.startDate || !this.filterData.endDate) return true;
        if (
            isEqual(
                new Date(this.filterData.startDate || ''),
                new Date(this.filterData.endDate || '')
            )
        )
            return true;
        return isBefore(
            new Date(this.filterData.startDate || ''),
            new Date(this.filterData.endDate || '')
        );
    }

    render() {
        return html`<tm-bottom-sheet
            .open=${this.open}
            .closeable=${true}
            @close=${() => {
                this.open = false;
                this.dispatchEvent(new CustomEvent('close'));
            }}
        >
            <div slot="header-center">Filter Bill Payments</div>
            <div class="p-3 filter-wrapper">
                <tm-date-picker
                    .label=${'Start Date'}
                    @value-changed=${(e: CustomEvent) => {
                        this.filterData.startDate = e.detail.value;
                    }}
                ></tm-date-picker>
                <tm-date-picker
                    .label=${'End Date'}
                    .min=${this.filterData.startDate}
                    @value-changed=${(e: CustomEvent) => {
                        this.filterData.endDate = e.detail.value;
                    }}
                ></tm-date-picker>
                <!-- Leaving this out for now to match mobile designs -->
                <!-- <tm-select
                    .label=${'Payment Type'}
                    .items=${[
                    { label: 'Scheduled', value: 2 },
                    { label: 'Historic', value: 1 },
                    { label: 'All', value: 0 },
                ]}
                    @value-changed=${(e: CustomEvent) => {
                    this.filterData.type = e.detail.value;
                }}
                ></tm-select> -->
            </div>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: 'Cancel',
                        importance: 'secondary',
                        onClick: () => {
                            this.open = false;
                            this.dispatchEvent(new CustomEvent('close'));
                        },
                    },
                    {
                        text: 'Apply',
                        importance: 'primary',
                        onClick: () => {
                            this.dispatchEvent(
                                new CustomEvent('apply-filters', {
                                    detail: { filterData: this.filterData },
                                })
                            );
                        },
                        disabled: !this.isValid,
                    },
                ]}
            ></tm-footer>
        </tm-bottom-sheet>`;
    }

    static get styles() {
        return [
            css`
                .filter-wrapper {
                    background-color: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: PaymentFiltersSheet;
    }
}
