import { css } from 'lit';

export const checkExceptionStyleClasses = css`
    .line {
        width: calc(100vw - 3.5rem);
        position: relative;
        left: 3.5rem;
        border-top: 1px solid var(--secondary-text-color);
    }
    .line-wide {
        border-top: var(--secondary-text-color) solid 0.5px;
        position: relative;
        width: calc(100vw - 16px);
        left: 16px;
    }

    .dashed-line-wide {
        border-top: var(--secondary-text-color) dashed 0.5px;
        position: relative;
        width: calc(100vw - 16px);
        left: 16px;
    }
`;
