export const defaultAchExceptionsActivitySearchRequest = {
    accounts: [],
    secCodes: [],
    amountRange: {
        min: 0,
        max: 0,
    },
    postedDate: {
        startDate: new Date(),
        endDate: new Date(),
    },
    description: '',
    achExceptionDecisionStatus: 'all',
};
