import { clone, Nullable } from '@treasury/utils';
import { DomainEntity } from '../shared';
import { nullTransferAccount, TransferAccountDto } from './transfer-account.dto';

export class TransferAccount extends DomainEntity<Nullable<TransferAccountDto>> {
    constructor(dto = clone(nullTransferAccount)) {
        super(dto);
        this.dto = dto;
    }

    public createDefault(): this {
        return new TransferAccount(this.dto) as this;
    }

    public get name() {
        return this.dto.name || '';
    }

    public set name(name) {
        this.dto.name = name;
    }

    public get number() {
        return this.dto.number || '';
    }

    public set number(number) {
        this.dto.number = number;
    }

    public get status() {
        return this.dto.status || 0;
    }

    public set status(status) {
        this.dto.status = status;
    }

    public get account() {
        return this.dto.account || null;
    }

    public set account(account) {
        this.dto.account = account ?? null;
    }

    public get currentBalance() {
        return this.dto.currentBalance || 0;
    }

    public set currentBalance(balance) {
        this.dto.currentBalance = balance;
    }

    public get collectedBalance() {
        return this.dto.collectedBalance || 0;
    }

    public set collectedBalance(balance) {
        this.dto.collectedBalance = balance;
    }

    public get availableBalance() {
        return this.dto.availableBalance || 0;
    }

    public set availableBalance(balance) {
        this.dto.availableBalance = balance;
    }

    public get type() {
        return this.dto.type || '';
    }

    public set type(type) {
        this.dto.type = type;
    }

    public get perTransactionLimit() {
        return this.dto.perTransactionLimit || 0;
    }

    public set perTransactionLimit(limit) {
        this.dto.perTransactionLimit = limit;
    }

    public get dailyLimit() {
        return this.dto.dailyLimit || 0;
    }

    public set dailyLimit(limit) {
        this.dto.dailyLimit = limit;
    }

    public get cifNumber() {
        return this.dto.cifNumber || 0;
    }

    public set cifNumber(number) {
        this.dto.cifNumber = number;
    }

    public get isDeleted() {
        return this.dto.isDeleted || false;
    }

    public set isDeleted(isDeleted) {
        this.dto.isDeleted = isDeleted;
    }

    public get showAccountNickName() {
        return this.dto.showAccountNickName || false;
    }

    public set showAccountNickName(showAccountNickName) {
        this.dto.showAccountNickName = showAccountNickName;
    }

    public get accountDisplayLabel() {
        return this.dto.accountDisplayLabel || '';
    }

    public set accountDisplayLabel(accountDisplayLabel) {
        this.dto.accountDisplayLabel = accountDisplayLabel;
    }

    public get transferAmount() {
        return this.dto.transferAmount || 0;
    }

    public set transferAmount(transferAmount) {
        this.dto.transferAmount = transferAmount;
    }

    public get transferMemo() {
        return this.dto.transferMemo || null;
    }

    public set transferMemo(transferMemo) {
        this.dto.transferMemo = transferMemo ?? null;
    }

    public get loanPaymentAmountDue() {
        return this.dto.loanPaymentAmountDue || 0;
    }

    public set loanPaymentAmountDue(loanPaymentAmountDue) {
        this.dto.loanPaymentAmountDue = loanPaymentAmountDue;
    }

    public get loanPaymentDueDate() {
        return this.dto.loanPaymentDueDate || null;
    }

    public set loanPaymentDueDate(loanPaymentDueDate) {
        this.dto.loanPaymentDueDate = loanPaymentDueDate ?? null;
    }

    public get allowLoanPaymentPastDue() {
        return this.dto.allowLoanPaymentPastDue || false;
    }

    public set allowLoanPaymentPastDue(allowLoanPaymentPastDue) {
        this.dto.allowLoanPaymentPastDue = allowLoanPaymentPastDue;
    }

    public get accountUniqueId() {
        return this.dto.accountUniqueId || '';
    }

    public set accountUniqueId(accountUniqueId) {
        this.dto.accountUniqueId = accountUniqueId;
    }

    public get transferAccountId() {
        return this.dto.id || 0;
    }

    public set transferAccountId(id) {
        this.dto.id = id;
    }

    public get updatedBy() {
        return this.dto.updatedBy || '';
    }

    public set updatedBy(user) {
        this.dto.updatedBy = user;
    }

    public get updatedDate() {
        return this.dto.updatedDate || '';
    }

    public set updatedDate(updatedDate) {
        this.dto.updatedDate = updatedDate;
    }
}
