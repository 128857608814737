/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { AchBatchImportModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class AchBatchImportsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AchBatchImports
     * @name AchBatchImportsPost
     * @summary Imports a AchBatchImportModel.
     * @request POST:/achBatchImports
     */
    achBatchImportsPost = (params: RequestParams = {}) =>
        this.http.request<AchBatchImportModelDto, any>({
            path: `/achBatchImports`,
            method: 'POST',
            format: 'json',
            ...params,
        });
}
