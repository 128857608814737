import { ConfigurationService } from '@treasury/core/config';
import { ChangePasswordDto } from '@treasury/domain/channel/types/user-account';
import { TmApiError } from '@treasury/domain/shared';
import '@treasury/omega/components/omega-tooltip';
import { NotificationService, TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-password-field';
import { InjectProperty } from '@treasury/utils';
import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { UserProfileClient } from '../clients/user-profile-client';

const defaultPasswordFormData = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
};

export const tagName = 'user-change-password-form';
@customElement(tagName)
export class UserChangePasswordForm extends TmBaseComponent {
    @InjectProperty()
    private declare client: UserProfileClient;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @state()
    public passwordFormValid = false;

    @state()
    private formData = defaultPasswordFormData;

    @InjectProperty()
    private declare readonly config: ConfigurationService;

    private passwordRequirements: Array<string> = [];

    public async firstUpdated() {
        try {
            this.passwordRequirements = await this.client.getPasswordRequirements(
                this.config.institutionId
            );
        } catch (err) {
            if (err instanceof TmApiError) {
                this.notificationService.renderError(err);
            } else {
                this.notificationService.renderError(
                    'User change password dialog first update failed'
                );
                console.error(err);
            }
        }
    }

    public updated(changedProperties: Map<string | number | symbol, unknown>) {
        if (changedProperties.has('formData')) {
            this.isPasswordFormValid();
        }
    }

    handleCurrentPasswordChange(e: CustomEvent) {
        this.formData = { ...this.formData, currentPassword: e.detail.value };
    }

    handleNewPasswordChange(e: CustomEvent) {
        this.formData = { ...this.formData, newPassword: e.detail.value };
    }

    handleConfirmPasswordChange(e: CustomEvent) {
        this.formData = { ...this.formData, confirmPassword: e.detail.value };
    }

    private isPasswordFormValid() {
        const hasRequiredValues =
            this.formData.currentPassword &&
            this.formData.newPassword &&
            this.formData.confirmPassword;
        const hasMatchingPasswords = this.formData.newPassword === this.formData.confirmPassword;
        this.passwordFormValid = !!(hasRequiredValues && hasMatchingPasswords);
    }

    public async submitPasswordChange() {
        try {
            const response = await this.client.changePassword(this.formData as ChangePasswordDto);
            if (response) {
                this.notificationService.renderSuccess('Password changed successfully!');
                this.closeDialog();
            } else {
                this.notificationService.renderError(
                    'An error occurred while changing your password. If the error recurs please contact your representative.'
                );
            }
        } catch (err) {
            if (err instanceof TmApiError) {
                this.notificationService.renderError(err);
            } else {
                this.notificationService.renderError('User change password submission failed');
                console.error(err);
            }
        }
    }

    private closeDialog() {
        this.dispatchEvent(new CustomEvent('close'));
    }

    private renderPasswordRequirements() {
        const requirements = this.passwordRequirements.map(req => html`<li>${req}</li>`);
        return html`<ul>
            ${requirements}
        </ul>`;
    }

    public render() {
        return html`
            <div>
                <tm-password-field
                    label="Current Password"
                    .value=${this.formData.currentPassword}
                    @value-changed=${this.handleCurrentPasswordChange}
                    required
                ></tm-password-field>
                <tm-password-field
                    label="New Password"
                    .value=${this.formData.newPassword}
                    @value-changed=${this.handleNewPasswordChange}
                    required
                ></tm-password-field>
                <tm-password-field
                    label="Confirm Password"
                    .value=${this.formData.confirmPassword}
                    @value-changed=${this.handleConfirmPasswordChange}
                    required
                ></tm-password-field>
            </div>
            <div>
                <tm-button
                    importance="primary"
                    ?disabled=${!this.passwordFormValid}
                    @click=${() => this.submitPasswordChange()}
                >
                    Submit
                </tm-button>
                <tm-button importance="secondary" @click=${() => this.closeDialog()}>
                    Cancel
                </tm-button>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserChangePasswordForm;
    }
}
