/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable no-restricted-globals */
/* eslint-disable import/extensions */
import { NavigationService } from '@treasury/core/navigation';
import { Account, DashboardService } from '@treasury/domain/dashboard';
import { TmBaseComponent } from '@treasury/presentation';
import { chevronDownIcon, chevronUpIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-card';
import { InjectProperty } from '@treasury/utils';
import '@vaadin/grid';
import {
    GridColumnBodyLitRenderer,
    columnBodyRenderer,
    gridRowDetailsRenderer,
} from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import '@vaadin/grid/vaadin-grid-sort-column.js';
import '@vaadin/item';
import { css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../../components/jhd-tooltip';
import { AccountViewModel } from '../data/account-view-model';

export const tagName = 'accounts-widget';
@customElement(tagName)
export default class AccountsWidget extends TmBaseComponent {
    @InjectProperty()
    private declare navService: NavigationService;

    @state()
    accountGroups: { name: string; accounts: AccountViewModel[] }[] = [];

    @InjectProperty()
    private declare dashboardService: DashboardService;

    @state()
    activeAccounts: AccountViewModel[] = [];

    @state()
    selectedAccounts: AccountViewModel[] = [];

    @state()
    selectedAccountsGroup = '';

    @state()
    selectedAccountGroup: { name: string; accounts: AccountViewModel[] }[] = [];

    @state()
    loading = true;

    @state()
    filteredAccounts: AccountViewModel[] = [];

    formatCurrency(amount: number) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(amount);
    }

    get userHasSetUpAccounts() {
        return this.accountGroups.some(
            (accountGroup: { name: string; accounts: AccountViewModel[] }) =>
                accountGroup.accounts.length > 0
        );
    }

    async firstUpdated() {
        const groups = (await this.dashboardService.getMyAccounts()) as {
            name: string;
            accounts: Account[];
        }[];

        this.accountGroups = groups.map(group => ({
            name: group.name,
            accounts: group.accounts.map(account => new AccountViewModel(account)),
        }));
        this.selectedAccountsGroup = this.accountGroups[0].name;
        this.activeAccounts = this.filteredAccounts;
        this.loading = false;
    }

    accountClicked(account: AccountViewModel) {
        this.selectedAccounts = account ? [account] : [];
        const [selectedAccount] = this.selectedAccounts;
        if (selectedAccount) {
            this.navService.navigate(`/accounts/${selectedAccount.type}/${selectedAccount.id}`);
        }
    }

    accountGroupClicked(accountGroup: { name: string; accounts: AccountViewModel[] }) {
        this.selectedAccountGroup = accountGroup ? [accountGroup] : [];
    }

    renderNameAndNumber(account: AccountViewModel) {
        return html`<div class="flex flex-col">
            <span class="text-sm font-medium">${account.name}</span>
            <small class="text-xs">${account.number}</small>
        </div>`;
    }

    renderAmountAndStatus(account: AccountViewModel) {
        return html`<div class="flex flex-col amount-and-status-col">
            <span class="text-sm font-medium">${account.availableBalanceFormatted}</span>
            <small class="text-xs">Available</small>
        </div>`;
    }

    renderGroupAccounts(accountGroup: { name: string; accounts: Account[] }) {
        return html`<vaadin-grid
            id="accounts-grid"
            .items=${accountGroup.accounts}
            .selectedItems=${this.selectedAccounts}
            @active-item-changed=${({ detail }: CustomEvent) => this.accountClicked(detail.value)}
            ><vaadin-grid-column
                ${columnBodyRenderer(
                    this.renderNameAndNumber as GridColumnBodyLitRenderer<AccountViewModel>
                )}
            ></vaadin-grid-column>
            <vaadin-grid-column
                ${columnBodyRenderer(
                    this.renderAmountAndStatus as GridColumnBodyLitRenderer<AccountViewModel>
                )}
                .hidden=${screen.width >= 550}
            ></vaadin-grid-column>
            <vaadin-grid-sort-column
                path="currentBalanceFormatted"
                header="Current Balance"
                flex-grow="1"
                width="160px"
                .hidden=${screen.width < 550}
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
                path="availableBalanceFormatted"
                header="Available Balance"
                flex-grow="1"
                width="160px"
                .hidden=${screen.width < 550}
            ></vaadin-grid-sort-column>
            <vaadin-grid-sort-column
                path="collectedBalanceFormatted"
                header="Collected Balance"
                flex-grow="1"
                width="160px"
                .hidden=${screen.width < 550}
            ></vaadin-grid-sort-column
        ></vaadin-grid>`;
    }

    renderGroupNameAndCount(accountGroup: { name: string; accounts: AccountViewModel[] }) {
        return html`<div class="text-sm">
            <span class="font-medium group-name">${accountGroup.name}&nbsp;</span
            ><span class="account-count">(${accountGroup.accounts.length})</span>
        </div>`;
    }

    renderGroupAmount(accountGroup: { name: string; accounts: AccountViewModel[] }) {
        if (!accountGroup.accounts.length) return html``;
        const availableBalances = accountGroup.accounts.map(
            (acc: AccountViewModel) => acc.availableBalance
        );
        const totalAvailableBalance = availableBalances.reduce((a, b) => a + b, 0);
        const totalAvailableBalanceFormatted = this.formatCurrency(totalAvailableBalance);
        const chevron =
            accountGroup.name === this.selectedAccountGroup[0]?.name
                ? chevronUpIcon
                : chevronDownIcon;

        return html`<div class="flex flex-shrink-0 justify-end items-center">
            <div class="flex flex-col">
                <span class="text-sm font-medium total-amount"
                    >${totalAvailableBalanceFormatted}</span
                ><span class="balance-type text-xs">Group Available</span>
            </div>
            <span class="group-toggle">${chevron}</span>
        </div>`;
    }

    renderTable() {
        if (this.loading) return html`<tm-loader card white></tm-loader>`;
        return html`
            <vaadin-grid
                id="account-groups-grid"
                all-rows-visible
                .items=${this.accountGroups}
                .detailsOpenedItems=${this.selectedAccountGroup}
                @active-item-changed=${({ detail }: CustomEvent) =>
                    this.accountGroupClicked(detail.value)}
                ${gridRowDetailsRenderer<{ name: string; accounts: Account[] }>(
                    this.renderGroupAccounts
                )}
            >
                <vaadin-grid-column
                    ${columnBodyRenderer(
                        this.renderGroupNameAndCount as GridColumnBodyLitRenderer<{
                            name: string;
                            accounts: AccountViewModel[];
                        }>
                    )}
                ></vaadin-grid-column>
                <vaadin-grid-column
                    text-align="end"
                    ${columnBodyRenderer(
                        this.renderGroupAmount as GridColumnBodyLitRenderer<{
                            name: string;
                            accounts: AccountViewModel[];
                        }>
                    )}
                ></vaadin-grid-column>
            </vaadin-grid>
        `;
    }

    renderGroupSelectionItems() {
        return this.accountGroups.map(
            (accountGroup: { name: string; accounts: AccountViewModel[] }) =>
                html`<vaadin-item>${accountGroup.name || 'All'}</vaadin-item>`
        );
    }

    renderHeader() {
        return html`<div class="flex justify-between items-center mb-2 px-3 pt-2 pb-1 w-full">
            <div>
                <h4 class="text-base whitespace-nowrap">Accounts</h4>
                <small class="ml-0">All Account Groups</small>
            </div>
        </div>`;
    }

    render() {
        if (!this.userHasSetUpAccounts && !this.loading)
            return html`
                <div class="rounded empty-state">
                    <h4 class="text-base pb-3">Accounts</h4>
                    <p class="text-center text-sm">No account groups available</p>
                </div>
            `;
        return html`<tm-card> ${this.renderHeader()} ${this.renderTable()} </tm-card>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    flex: 1;
                    --tm-card-min-height: auto;
                }
                svg {
                    display: inline;
                }
                .empty-state {
                    background: var(--primary-background);
                    box-shadow: rgb(0 0 0 / 15%) 0px 3px 12px;
                    border-radius: 10px;
                    padding: 20px;
                }
                .empty-state h4 {
                    color: var(--header-text-color);
                }
                .empty-state p {
                    color: var(--secondary-text-color);
                }
                small {
                    color: var(--secondary-text-color);
                    margin-left: 0;
                }
                span {
                    color: var(--primary-text-color);
                }
                h4 {
                    font-weight: 400;
                    color: var(--header-text-color);
                }
                vaadin-grid {
                    border-top: 1px solid var(--border-color);
                }
                vaadin-grid-cell-content {
                    padding: 6px 16px 6px 0;
                }
                vaadin-grid::part(first-column-cell) {
                    padding-left: 16px;
                }
                vaadin-grid::part(last-column-cell) {
                    padding-right: 6px;
                }
                #account-groups-grid::part(cell) {
                    background-color: var(--grouped-table-row-color);
                }
                #account-groups-grid vaadin-grid-cell-content {
                    padding: 6px 0;
                }
                #accounts-grid::part(last-column-cell) {
                    padding-right: 25px;
                }
                #show-group {
                    color: var(--primary-text-color);
                }
                .amount-and-status-col {
                    text-align: right;
                }
                jhd-tooltip {
                    margin-top: 5px;
                }
                path {
                    fill: var(--secondary-text-color);
                }
                .group-toggle svg {
                    display: inline-block;
                }
                .group-name,
                .total-amount {
                    color: var(--primary-text-color);
                }
                .account-count,
                .balance-type {
                    color: var(--secondary-text-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AccountsWidget;
    }
}
