import { NavigationService } from '@treasury/core/navigation';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import { InjectProperty } from '@treasury/utils';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/jhd-animated-result-icon';
import './approval-review-table';

export const tagName = 'approval-confirmation-bottom-sheet';
@customElement(tagName)
export class ApprovalConfirmationBottomSheet extends TmBaseComponent {
    @property({ type: Boolean })
    private open = false;

    @property({ type: Array })
    public decisionedPayments = [];

    @property({ type: String })
    public activeTab = '';

    @state()
    public approvalSuccess = true;

    @state()
    isConfirming = true;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    renderConfirmation() {
        return html`<tm-bottom-sheet
            .open=${this.open}
            @close=${() => {
                this.open = false;
                this.dispatchEvent(new CustomEvent('close'));
            }}
            ><div id="confirmation-container" class="fixed inset-0 z-20">
                <div id="confirmation">
                    <div
                        id="success-icon"
                        class="forty-center flex items-center justify-center py-5-top"
                    >
                        <jhd-animated-result-icon
                            class="mx-auto"
                            .success=${true}
                        ></jhd-animated-result-icon>
                    </div>
                    <p class="font-normal text-center w-full text-lg py-3-bottom success-message">
                        Submitted successfully
                    </p>
                    <approval-review-table
                        .activeTab=${this.activeTab}
                        .activeItems=${this.decisionedPayments}
                    ></approval-review-table>
                </div>
                <tm-footer
                    .buttonConfig=${[
                        {
                            importance: 'secondary',
                            text: 'View Approvals',
                            onClick: () => {
                                this.dispatchEvent(new CustomEvent('view-approvals'));
                            },
                        },
                        {
                            text: 'Return to Dashboard',
                            onClick: () => {
                                this.navService.navigate(`/dashboard`);
                            },
                        },
                    ] as ButtonConfig[]}
                >
                </tm-footer>
            </div>
        </tm-bottom-sheet>`;
    }

    render() {
        return [this.renderConfirmation()];
    }

    static get styles() {
        return [
            css`
                #confirmation-container {
                    background-color: var(--primary-background);
                }
                .success-message {
                    color: var(--header-text-color);
                }
                .forty-center {
                    width: 40px;
                    margin: 0 auto;
                }
                .py-5-top {
                    padding-top: 1.25rem;
                }
                .py-3-bottom {
                    padding-bottom: 0.75rem;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: ApprovalConfirmationBottomSheet;
    }
}
