/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';

type LastLoginDto = {
    lastFailedLogin: string;
    lastFailedLoginIPAddress: string;
    lastSuccessfulLogin: string;
    lastSuccessfulLoginIPAddress: string;
};

@Injectable()
export class LoginService {
    // eslint-disable-next-line no-useless-constructor
    constructor(private readonly http: TmHttpClient) {}

    async getLastLogin() {
        const response = await this.http.request<LastLoginDto>('security/recentLogins', {
            method: 'GET',
        });

        return response.lastSuccessfulLogin;
    }
}
