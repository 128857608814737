import { UserAccount } from '@treasury/domain/channel/services/account';
import { clone, removeSpecialChars } from '@treasury/utils';

export class UserAccountVm extends UserAccount {
    constructor(userAccount = new UserAccount()) {
        super(userAccount.toDto());
    }

    public get phone() {
        return super.phone || '';
    }

    public set phone(val) {
        super.phone = removeSpecialChars(val);
    }

    public get fax() {
        return super.fax || '';
    }

    public set fax(val) {
        super.fax = removeSpecialChars(val);
    }

    public get mobile() {
        return super.mobile || '';
    }

    public set mobile(val) {
        super.mobile = removeSpecialChars(val);
    }

    public clone() {
        const dto = clone(this.dto);
        // intentionally override default behavior or else
        // the `UserAccountVm` ctor (which expects a BO) will be used
        // instead of the `UserAccount` ctor (which expects a DTO)
        return new UserAccountVm(new UserAccount(dto)) as this;
    }
}
