import { TmBaseComponent } from '@treasury/presentation';
import { checkmarkIcon, closeIcon, lockIcon } from '@treasury/presentation/assets/icons';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import './jhd-icon';

export type DecisionState = 'Approved' | 'Rejected' | 'na';
export type JhdDecisionToggleChangeEvent = CustomEvent<{
    selection: DecisionState;
    modified: boolean;
}>;

/**
 * A switch that toggles between red and green circular icons.
 * User can toggle whether the component state has been 'modified' by clicking the original decision button.
 * User cannot set the decision to 'na' after render. This component's decision will always be 'Approved' or 'Rejected' unless none is provided.
 * @param {DecisionState} decision - the selected decision status
 * @param {DecisionState} originalState - Optional. For tracking orignal state outside of component scope.
 * @param {boolean} readonly - Optional.
 * @param {boolean} modified - Optional.
 * @param {boolean} protected - Optional.
 * If the 'protected' property is true, the toggle switch will render in a locked state.
 */
export const tagName = 'jhd-decision-toggle';
@customElement(tagName)
export class JhdDecisionToggle extends TmBaseComponent {
    @property()
    public decision: DecisionState = 'na';

    @property()
    originalState?: DecisionState;

    @property({ type: Boolean, reflect: true })
    public readonly = false;

    @property({ type: Boolean })
    modified?: boolean;

    @property({ type: Boolean })
    protected?: boolean;

    @property({ type: Boolean })
    filledBackground?: boolean;

    private readonly defaultColor = 'var(--approval-default-color)';

    private readonly whiteColor = 'var(--approval-icon-color)';

    private readonly greyColor = 'var(--approval-deselected-color)';

    private readonly greenColor = 'var(--approved-color)';

    private readonly redColor = 'var(--rejected-color)';

    @state()
    private approveColor = this.defaultColor;

    @state()
    private rejectColor = this.defaultColor;

    determineIconColor() {
        if (this.modified || this.filledBackground) {
            if (this.decision === 'Approved') {
                this.approveColor = this.whiteColor;
                this.rejectColor = this.greyColor;
            }
            if (this.decision === 'Rejected') {
                this.approveColor = this.greyColor;
                this.rejectColor = this.whiteColor;
            }
        } else {
            if (this.decision === 'Approved') {
                this.approveColor = this.greenColor;
                this.rejectColor = this.greyColor;
            }
            if (this.decision === 'Rejected') {
                this.approveColor = this.greyColor;
                this.rejectColor = this.redColor;
            }
        }
        if (this.decision === 'na') {
            this.approveColor = this.defaultColor;
            this.rejectColor = this.defaultColor;
        }
    }

    updated(changedProps: Map<string, unknown>) {
        super.updated(changedProps);
        if (changedProps.has('decision')) {
            this.determineIconColor();
        }
    }

    renderIcon(decision: DecisionState) {
        this.determineIconColor();

        const isApproveIcon = decision === 'Approved';
        const approved = this.decision === 'Approved';
        const rejected = this.decision === 'Rejected';
        const backgroundFilledIn = !!this.modified || !!this.filledBackground;
        const approvedBg = isApproveIcon && backgroundFilledIn && approved;
        const rejectedBg = !isApproveIcon && backgroundFilledIn && rejected;
        const iconWrapperClass = `icon-wrapper-${decision.toLowerCase()}`;
        const iconColor = isApproveIcon ? this.approveColor : this.rejectColor;

        const icon = html`
            <div
                class="${classMap({
                    'icon-wrapper': true,
                    'w-6 h-6': true,
                    'border-2 rounded-full': true,
                    'flex justify-center items-center': true,
                    [iconWrapperClass]: true,
                    'icon-wrapper-deselected': isApproveIcon ? rejected : approved,
                    'dirty-approved': !!approvedBg,
                    'dirty-rejected': !!rejectedBg,
                })}"
            >
                <jhd-icon
                    .icon=${isApproveIcon ? checkmarkIcon : closeIcon}
                    .color=${iconColor}
                    .width=${'18px'}
                    .height=${'18px'}
                ></jhd-icon>
            </div>
        `;

        if (this.protected) {
            if (decision !== this.decision) {
                return html`
                    <div class="flex justify-center items-center w-6 h-6 icon-wrapper">
                        <jhd-icon
                            .icon=${lockIcon}
                            .color=${iconColor}
                            .width=${'18px'}
                            .height=${'18px'}
                        ></jhd-icon>
                    </div>
                `;
            }

            return html`
                <div class="flex justify-center items-center w-10 h-10">
                    <div
                        class="${classMap({
                            'icon-wrapper': true,
                            'w-6 h-6': true,
                            'border-2 rounded-full': true,
                            'flex justify-center items-center': true,
                            [iconWrapperClass]: true,
                            'icon-wrapper-deselected': isApproveIcon ? rejected : approved,
                            'dirty-approved': isApproveIcon,
                            'dirty-rejected': !isApproveIcon,
                            'opacity-50': true,
                        })}"
                    >
                        <jhd-icon
                            .icon=${isApproveIcon ? checkmarkIcon : closeIcon}
                            .color=${this.whiteColor}
                            .width=${'18px'}
                            .height=${'18px'}
                        ></jhd-icon>
                    </div>
                </div>
            `;
        }

        return this.readonly
            ? html`<div class="flex justify-center items-center w-10 h-10">${icon}</div>`
            : icon;
    }

    renderButton(decision: 'Approved' | 'Rejected') {
        const buttonClass = `${decision.toLocaleLowerCase()}-button`;

        if (this.protected) {
            return html`
                <div class="flex justify-center items-center w-10 h-10 ${buttonClass}">
                    ${this.renderIcon(decision)}
                </div>
            `;
        }

        return html`<button
            class="flex justify-center items-center w-10 h-10 ${buttonClass}"
            @click=${() => {
                if (decision === this.originalState && decision === this.decision) {
                    this.modified = !this.modified;
                } else {
                    this.modified = true;
                }
                this.decision = decision;
                this.determineIconColor();
                this.dispatchEvent(
                    new CustomEvent('toggle-change', {
                        detail: { selection: this.decision, modified: this.modified },
                    })
                );
            }}
        >
            ${this.renderIcon(decision)}
        </button>`;
    }

    renderActions() {
        if (this.readonly) {
            if (this.decision === 'na') return nothing;
            return html`${this.renderIcon(this.decision)}`;
        }
        return html`${this.renderButton('Approved')} ${this.renderButton('Rejected')}`;
    }

    render() {
        const status = this.decision && this.modified ? this.decision.toLowerCase() : 'na';
        return html`<div class="justify-around border-l-4 py-2 pr-3 flex-0 status-${status}">
            ${this.renderActions()}
        </div>`;
    }

    static get styles() {
        return [
            css`
                div {
                    display: flex;
                }
                .hide {
                    display: none;
                }
                button {
                    border: none;
                }
                .icon-wrapper {
                    padding: 2px;
                    border-color: var(--approval-default-color);
                }
                .icon-wrapper-approved {
                    border-color: var(--approved-color);
                }
                .icon-wrapper-rejected {
                    border-color: var(--rejected-color);
                }
                .icon-wrapper-deselected {
                    border-color: var(--approval-deselected-color);
                }
                jhd-icon {
                    position: relative;
                }
                .status-na {
                    border-color: transparent;
                }
                .status-approved {
                    border-color: var(--approved-color);
                }
                .status-rejected {
                    border-color: var(--rejected-color);
                }

                .dirty-approved {
                    background-color: var(--approved-color);
                }

                .dirty-rejected {
                    background-color: var(--rejected-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: JhdDecisionToggle;
    }
}
