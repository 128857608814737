import { TmBaseComponent } from '@treasury/presentation';
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export const tagName = 'batch-deposit-container';
@customElement(tagName)
export class BatchDepositContainer extends TmBaseComponent {
    render() {
        return html`Batch Deposit Detail Container`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: BatchDepositContainer;
    }
}
