/* eslint-disable no-restricted-globals */
import {
    AuthenticateRequestModelDto,
    CustomerModelDto,
    LocationModelDto,
} from '@treasury/api/channel';
import { NavigationService } from '@treasury/core/navigation';
import { RemoteDepositCaptureService } from '@treasury/domain/channel/services';
import { NotificationService, TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-bottom-sheet';
import { InjectProperty } from '@treasury/utils';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../partials/quick-deposit-create-step';

type QuickDepositFormData = {
    amount: number;
    location: string;
    customer: string;
    invoiceNumber: string;
    transactionId: string;
    frontImage: string;
    rearImage: string;
};

export const tagName = 'quick-deposit-container';
@customElement(tagName)
export class QuickDepositContainer extends TmBaseComponent {
    @InjectProperty()
    private declare readonly remoteDepositCaptureService: RemoteDepositCaptureService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    @property({ type: Boolean })
    isOpen = false;

    @state()
    loading = false;

    @state()
    private locations?: LocationModelDto[] = [];

    @state()
    private customers?: CustomerModelDto[] = [];

    @state()
    private visibleStep = 1;

    @state()
    private formData = {
        amount: 0,
        location: '',
        customer: '',
        invoiceNumber: '',
        transactionId: '',
        frontImage: '',
        rearImage: '',
    } as QuickDepositFormData;

    async firstUpdated() {
        const lastPartUrl = window.location.href.substring(
            window.location.href.lastIndexOf('/') + 1
        );
        if (lastPartUrl === 'quick-deposit') {
            this.isOpen = true;
        }
        this.loading = true;
        try {
            this.locations = await this.remoteDepositCaptureService.getLocations();
            this.customers = await this.remoteDepositCaptureService.getCustomers();
        } catch (err) {
            this.notificationService.renderError('Error fetching remote deposit data');
            console.error(err);
        } finally {
            this.loading = false;
        }
    }

    async submitQuickDeposit(e: CustomEvent) {
        this.loading = true;
        try {
            await this.remoteDepositCaptureService.createDeposit(e.detail);
            this.visibleStep++;
        } catch (err) {
            this.notificationService.renderError(err as Error);
        } finally {
            this.loading = false;
        }
    }

    advanceStep(formData: QuickDepositFormData) {
        this.visibleStep++;
        this.formData = formData;
    }

    renderStep() {
        switch (this.visibleStep) {
            case 1:
                return html`
                    <quick-deposit-create-step
                        .locations=${this.locations}
                        .customers=${this.customers}
                        @proceed-to-review=${(e: CustomEvent) => this.advanceStep(e.detail)}
                    ></quick-deposit-create-step>
                `;
            case 2:
                return html`
                    <quick-deposit-review-step
                        .formData=${this.formData}
                        @submit=${(e: CustomEvent) => this.submitQuickDeposit(e)}
                    ></quick-deposit-review-step>
                `;
            case 3:
                return html`
                    <quick-deposit-confirmation-step
                        .formData=${this.formData}
                    ></quick-deposit-confirmation-step>
                `;
            default:
                return nothing;
        }
    }

    render() {
        return html`
            <tm-bottom-sheet
                .open=${this.isOpen}
                @close=${() => {
                    this.isOpen = false;
                    this.navService.navigate(`/deposit`);
                }}
            >
                <span slot="header-center">Quick Deposit</span>
                ${this.renderStep()}
            </tm-bottom-sheet>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: QuickDepositContainer;
    }
}
