import { WireConfigurationDto, WireModelDto, WireTemplateModelDto } from '@treasury/api/channel';
import { NavigationService } from '@treasury/core/navigation';
import { EntitlementsService } from '@treasury/domain/channel/services';
import { TmApiError } from '@treasury/domain/shared';
import {
    Wire,
    WireBeneficiary,
    WiresService,
    WireTemplate,
    WireType,
} from '@treasury/domain/wires';
import { NotificationService, TmBaseComponent } from '@treasury/presentation';
import { informationIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-footer';
import { clone, deepEquals } from '@treasury/utils';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { css, html, nothing, PropertyValueMap } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ConfirmationType } from '../../components/confirmation-container';
import '../../components/jhd-animated-result-icon';
import '../../components/jhd-header-bar';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import '../shared/partials/wire-beneficiary-read-only';
import '../shared/partials/wire-editable';
import '../shared/partials/wire-read-only';
import {
    WireAction,
    WireCurrency,
    WireWorkflow,
    WireWorkflowParameters,
} from './types/wire-workflow-parameters.type';

export const tagName = 'wire-workflow-container';
@customElement(tagName)
export class WireWorkflowContainer extends TmBaseComponent {
    @InjectProperty()
    private declare readonly headerService: HeaderBarService;

    @InjectProperty()
    private declare readonly wireService: WiresService;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    @InjectProperty()
    private declare readonly notificationService: NotificationService;

    @state()
    private workflow = '' as WireWorkflow;

    @state()
    private action = '' as WireAction;

    @state()
    private currency = 'usd' as WireCurrency;

    @state()
    private step = 1;

    @property({ type: Object })
    wire = new Wire();

    @state()
    private wireTemplate?: WireTemplate;

    @state()
    private wireBeneficiary = new WireBeneficiary();

    @state()
    private wireType = WireType.Domestic;

    @state()
    wireResponse?: Wire;

    @state()
    private loading = true;

    @state()
    private isFormDirty = false;

    @state()
    private isWireValid = false;

    @state()
    private beneficiarySheetOpen = false;

    @state()
    private wireConfiguration?: WireConfigurationDto;

    private entitlementsService = new EntitlementsService();

    private domesticEntitlement = false;

    private internationalEntitlement = false;

    private initialWire = clone(this.wire);

    private onReviewClick = () => {
        this.step = 2;
    };

    private onConfirmClick = async () => {
        try {
            this.loading = true;
            const response = await this.wireService.createWirePayment(
                this.wire.toDto() as WireModelDto
            );
            this.wireResponse = new Wire(response.data);
            this.step = 3;
        } catch (err) {
            if (err instanceof TmApiError) {
                this.notificationService.renderError(err);
            } else {
                this.notificationService.renderError('Error processing your wire');
                console.error(err);
            }
        } finally {
            this.loading = false;
        }
    };

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('loadingStatusChange', this.loadingStatusChange as EventListener);
    }

    disconnectedCallback() {
        this.removeEventListener('loadingStatusChange', this.loadingStatusChange as EventListener);
    }

    private loadingStatusChange(e: CustomEvent) {
        this.loading = e.detail;
    }

    async firstUpdated() {
        await this.setParams();

        try {
            this.loading = true;
            this.configureHeader();
            if (!this.wireConfiguration) {
                this.wireConfiguration = await this.wireService.getWireConfiguration();
            }

            if (this.workflow === 'template' && this.id) {
                this.wireTemplate = await this.wireService.getTemplate(Number(this.id));

                const {
                    wireCompany,
                    debitAccount,
                    beneficiary,
                    purpose,
                    additionalInformation,
                    referenceBeneficiary,
                    isInternational,
                } = this.wireTemplate;

                this.wire = new Wire({
                    wireCompany,
                    debitAccount,
                    purpose,
                    beneficiary,
                    additionalInformation,
                    referenceBeneficiary,
                    isInternational,
                } as WireModelDto);

                this.wire.wireTemplate = this.wireTemplate.toDto() as WireTemplateModelDto;

                if (this.wire.beneficiary) {
                    this.wireBeneficiary = new WireBeneficiary(this.wireTemplate.beneficiary);
                }
                this.requestUpdate();
            }

            this.initialWire = clone(this.wire);

            await this.checkEntitlements();
        } catch (err) {
            this.notificationService.renderError(err as Error);
        } finally {
            this.loading = false;
        }
    }

    private async setParams() {
        const { params } = await this.navService.getRouteData<WireWorkflowParameters>();
        const { workflow, action, currency, id } = params;
        this.workflow = workflow;
        this.action = action;
        this.id = id;
        this.currency = currency;
    }

    protected updated(changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        if (
            changedProperties.has('action') ||
            changedProperties.has('workflow') ||
            changedProperties.has('step')
        ) {
            this.configureHeader();
        }

        if (changedProperties.has('step')) {
            this.dispatchEvent(
                new CustomEvent('scrollTopContent', {
                    bubbles: true,
                    composed: true,
                })
            );
        }
    }

    private async checkEntitlements() {
        Promise.all([
            this.entitlementsService.hasEntitlement('Wire, Domestic, Create Wire'),
            this.entitlementsService.hasEntitlement('Wire, International, Create Wire'),
        ])
            .then((values: any) => {
                [this.domesticEntitlement, this.internationalEntitlement] = values;
            })
            .catch(err => {
                this.notificationService.renderError(err);
            })
            .finally(() => {
                if (!this.domesticEntitlement && !this.internationalEntitlement) {
                    this.routeToDashboard();
                }
            });
    }

    private configureHeader() {
        this.headerService.configure({
            title: this.pageTitle(),
            backAction: this.backAction(),
        });
    }

    private backAction() {
        switch (this.step) {
            case 1:
                return () => this.routeToWireTemplateInitiate();
            case 3:
                return null; // No back option on confirm
            default:
                this.initialWire = this.wire;
                return () => this.step--;
        }
    }

    getCreateWireTitle() {
        if (this.workflow === 'template') {
            switch (this.action) {
                case 'create':
                    return 'Create a Wire Template';
                case 'edit':
                    return `Edit Wire Template`;
                case 'initiate':
                    return 'Create Wire from Template';
                default:
                    return '';
            }
        }
        if (this.action === 'create') {
            if (this.currency === 'fx') {
                return 'Create FX Wire';
            }
            return 'Create USD Wire';
        }
        return '';
    }

    private pageTitle() {
        switch (this.step) {
            case 1:
                return this.getCreateWireTitle();
            case 2:
                return 'Review Wire';
            case 3:
                return 'Wire Submitted';
            default:
                return `Wire Detail: ${this.wire.entityId}`;
        }
    }

    routeToDashboard() {
        this.navService.navigate(`/dashboard`);
    }

    routeToWires() {
        this.navService.navigate(`/wires`);
    }

    routeToWireTemplateInitiate() {
        this.navService.navigate(`/wires/templates/initiate`);
    }

    routeToWireTemplate() {
        this.navService.navigate(`/wires/template/${this.id}`);
    }

    renderBlockingLoader() {
        if (!this.loading) return nothing;
        return html`<tm-blocking-loader></tm-blocking-loader>`;
    }

    renderWireTypeRadioButtons() {
        if (this.action === 'create' && this.currency === 'usd') {
            return html`
                <fieldset
                    class="flex bg-white border-b border-[--border-color]"
                    @value-changed=${(e: Event) => {
                        this.wireType = (e.target as HTMLInputElement).value as WireType;
                    }}
                >
                    <jha-form-radio-button
                        value=${WireType.Domestic}
                        label="Domestic"
                        .checked=${this.wireType === WireType.Domestic}
                        @click=${async (e: Event) => {
                            if (this.isFormDirty) {
                                e.preventDefault();
                            }
                        }}
                    ></jha-form-radio-button>
                    <jha-form-radio-button
                        value=${WireType.International}
                        label="International"
                        @click=${async (e: Event) => {
                            if (this.isFormDirty) {
                                e.preventDefault();
                            }
                        }}
                        .checked=${this.wireType === WireType.International}
                    ></jha-form-radio-button>
                </fieldset>
            `;
        }
        return nothing;
    }

    renderBeneficiarySheet() {
        return html`<tm-bottom-sheet
            class="z-[101]"
            .open=${this.beneficiarySheetOpen}
            @close=${() => {
                this.beneficiarySheetOpen = false;
            }}
        >
            <p slot="header-center">Beneficiary Detail</p>
            <tm-body>
                <wire-beneficiary-read-only
                    .beneficiary=${this.wireBeneficiary}
                ></wire-beneficiary-read-only></tm-body
        ></tm-bottom-sheet>`;
    }

    renderErrorSummary() {
        if (this.wireResponse && this.wireResponse.errorSummary) {
            return html` <div class="text-left mt-2 mx-4">
                ${this.wireResponse.errorSummary.summaryMessage}
                ${this.wireResponse.errorSummary.details?.length
                    ? html`<ul class="p-0 ml-4 list-disc text-sm font-light">
                          ${this.wireResponse.errorSummary.details.map(
                              detail => html`<li>${detail.message}</li>`
                          )}
                      </ul>`
                    : null}
            </div>`;
        }
        return nothing;
    }

    renderStep() {
        switch (this.step) {
            case 1:
                return html`${this.renderWireTypeRadioButtons()} ${this.renderBeneficiarySheet()}
                    <tm-body .padding=${false}
                        ><wire-editable
                            .wireConfiguration=${this.wireConfiguration}
                            .wireType=${this.wireType}
                            .wire=${this.initialWire}
                            .action=${this.action}
                            .workflow=${this.workflow}
                            @beneficiaryClick=${() => {
                                this.beneficiarySheetOpen = true;
                            }}
                            @wireChange=${(e: CustomEvent) => {
                                this.isWireValid = !!e.detail.value;
                                if (this.isWireValid) {
                                    this.wire = new Wire(e.detail.value.toDto());
                                    this.isFormDirty = !deepEquals(this.wire, this.initialWire);
                                }
                            }}
                            @templateViewClick=${() => {
                                this.routeToWireTemplate();
                            }}
                        ></wire-editable
                    ></tm-body>
                    <tm-footer
                        visible
                        .buttonConfig=${[
                            {
                                size: 'normal',
                                text: 'Review',
                                disabled: !this.isWireValid,
                                onClick: this.onReviewClick,
                            },
                        ]}
                    ></tm-footer>`;
            case 2:
                return html`${this.renderBeneficiarySheet()}
                    <tm-body
                        ><wire-read-only
                            .wire=${this.wire}
                            .wireConfiguration=${this.wireConfiguration}
                            @beneficiaryClick=${() => {
                                this.beneficiarySheetOpen = true;
                            }}
                            .showAudit=${false}
                            .showCreationDetails=${false}
                        ></wire-read-only>
                        <div class="review-info relative text-xs mx-auto text-slate-400">
                            <jhd-icon
                                class="information-icon absolute top-0"
                                .icon=${informationIcon}
                            ></jhd-icon>
                            <p class="my-2">
                                Fees may be assessed for sending a wire payment. Please check your
                                fee schedule for current fees.
                            </p>

                            <p class="my-2">
                                Additions or edits to purpose, additional information or reference
                                beneficiary fields will not be saved to the wire template.
                            </p>
                        </div></tm-body
                    >
                    <tm-footer
                        visible
                        .buttonConfig=${[
                            {
                                text: 'Initiate Wire',
                                disabled: !this.wire || this.loading,
                                onClick: this.onConfirmClick,
                            },
                        ]}
                    ></tm-footer> `;
            case 3:
                return html`
                    <tm-body>
                        <confirmation-container
                            .headingText=${this.wireResponse?.errorSummary
                                ? 'Wire Submission Failed'
                                : 'Wire Submitted'}
                            .confirmationType=${this.wireResponse?.errorSummary
                                ? ConfirmationType.Error
                                : ConfirmationType.Success}
                        >
                            ${this.renderErrorSummary()}
                            <hr class="border-t-2 border-dashed border-[--border-color] mt-4" />
                            <wire-read-only
                                class="p-4"
                                .wire=${this.wireResponse}
                                .wireConfiguration=${this.wireConfiguration}
                                .showAudit=${false}
                                .showCreationDetails=${false}
                            ></wire-read-only>
                        </confirmation-container>
                    </tm-body>
                    <tm-footer
                        visible
                        .buttonConfig=${[
                            {
                                importance: 'secondary',
                                text: 'Create Another Wire',
                                disabled: false,
                                onClick: () =>
                                    this.navService.navigate(`/wires/templates/initiate`),
                            },
                            {
                                importance: 'secondary',
                                text: 'View Wire Activity',
                                disabled: false,
                                onClick: () => this.navService.navigate(`/wires`),
                            },
                        ]}
                    ></tm-footer>
                `;
            default:
                return nothing;
        }
    }

    render() {
        return [this.renderBlockingLoader(), this.renderStep()];
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                .international-bar {
                    background-color: #fff;
                    border-bottom: 1px solid #aeadad;
                }

                .information-icon {
                    --icon-fill-color: var(--secondary-text-color);
                }

                .action-bar {
                    display: flex;
                    box-shadow: rgba(0, 0, 0, 0.15) 0px -3px 12px;
                }

                .review-info {
                    width: 75%;
                    color: var(--secondary-text-color);
                }

                .review-info jhd-icon {
                    top: -4px;
                    left: -28px;
                    --icon-transform-scale: scale(0.8);
                }

                .wire-submission-result jhd-animated-result-icon {
                    width: 100px;
                }

                .confirm-action-bar {
                    flex-direction: column;
                }

                .confirm-action-bar .main-confirm-controls {
                    display: flex;
                }

                @media screen and (max-width: 768px) {
                    .confirm-action-bar .main-confirm-controls {
                        display: block;
                    }
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: WireWorkflowContainer;
    }
}
