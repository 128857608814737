export const wireStatusItems = [
    {
        name: 'Pending Approval',
        id: 1,
        canCancel: true,
        canSearch: 1,
    },
    {
        name: 'Approval Rejected',
        id: 2,
        canSearch: 1,
    },
    {
        name: 'Transmitted',
        id: 4,
        canSearch: 1,
    },
    {
        name: 'Cancelled',
        id: 5,
        canSearch: 1,
    },
    {
        name: 'Posted',
        id: 6,
        canSearch: 1,
    },
    {
        name: 'Scheduled',
        id: 7,
        canCancel: true,
        canSearch: 1,
    },
    {
        name: 'Failed',
        id: 8,
        canSearch: 1,
    },
    {
        name: 'Expired',
        id: 9,
        canSearch: 1,
    },
    {
        name: 'Deleted',
        id: 10,
        canSearch: 1,
    },
];
