import { PaymentRecipientDto } from '@treasury/domain/channel/types/ach';
import { TmBaseComponent } from '@treasury/presentation';
import { chevronForwardIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-table-header';
import { columnBodyRenderer, GridColumnBodyLitRenderer } from '@vaadin/grid/lit.js';
import { html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { filterTableResults } from '../../../utilities/table-result-filter';
import './ach-recipient-detail';

type RecipientRenderer = GridColumnBodyLitRenderer<PaymentRecipientDto>;

export const tagName = 'ach-recipients-table';
@customElement(tagName)
export class AchRecipientsTable extends TmBaseComponent {
    protected verifiedPropNames: readonly (keyof this)[] = ['recipients'];

    @property()
    recipients: PaymentRecipientDto[] = [];

    @state()
    filteredRecipients: PaymentRecipientDto[] = [];

    @state()
    fieldsToFilter = ['name', 'routingNumber', 'accountType', 'transactionType', 'amount'];

    @state()
    selectedRecipient?: PaymentRecipientDto;

    @state()
    recipientDetailOpen = false;

    firstUpdated() {
        this.filteredRecipients = this.recipients as PaymentRecipientDto[];
    }

    filterRecipients(event: CustomEvent) {
        this.filteredRecipients = filterTableResults(
            event.detail.value,
            this.recipients as Array<PaymentRecipientDto>,
            this.fieldsToFilter
        ) as PaymentRecipientDto[];
    }

    renderLeftColumn(recipient: PaymentRecipientDto) {
        return html`<button
            class="w-full"
            @click=${() => {
                this.selectedRecipient = recipient;
                this.recipientDetailOpen = true;
            }}
        >
            <div class="flex flex-col items-start">
                <span class="text-sm font-medium">${recipient.name}</span>
                <span class="recipient-data text-xs"
                    >${recipient.routingNumber}
                    <span class="mx-1">|</span> ${recipient.accountType}</span
                >
            </div>
        </button>`;
    }

    renderRightColumn(recipient: PaymentRecipientDto) {
        const formattedAmount = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(recipient.amount);
        const transactionType = recipient.transactionType === 'CR' ? 'Credit' : 'Debit';
        return html`<button
            class="w-full"
            @click=${() => {
                this.selectedRecipient = recipient;
                this.recipientDetailOpen = true;
            }}
        >
            <div class="flex justify-end items-center">
                <div class="flex flex-col items-end">
                    <span class="text-sm font-medium">${formattedAmount}</span>
                    <span class="recipient-data text-xs">${transactionType}</span>
                </div>
                <div class="action-icon w-4 ml-1">${chevronForwardIcon}</div>
            </div>
        </button>`;
    }

    renderSelectedRecipient() {
        if (!this.recipientDetailOpen) return nothing;
        return html`<p slot="header-center">ACH recipient</p>
            <ach-recipient-detail .recipient=${this.selectedRecipient}></ach-recipient-detail>`;
    }

    render() {
        return html` <tm-table-header
                .filterButton=${false}
                @filterStringChange=${(e: CustomEvent) => this.filterRecipients(e)}
                ><h3 class="font-medium">Payment Recipients</h3></tm-table-header
            >

            <vaadin-grid all-rows-visible .items=${[...this.filteredRecipients]}>
                <vaadin-grid-column
                    ${columnBodyRenderer(this.renderLeftColumn as RecipientRenderer, [])}
                ></vaadin-grid-column>
                <vaadin-grid-column
                    text-align="end"
                    ${columnBodyRenderer(this.renderRightColumn as RecipientRenderer, [])}
                ></vaadin-grid-column>
            </vaadin-grid>

            <tm-bottom-sheet
                .open=${this.recipientDetailOpen}
                horizontal
                @close=${() => {
                    this.recipientDetailOpen = false;
                    this.dispatchEvent(
                        new CustomEvent('close-recipient', { detail: this.selectedRecipient })
                    );
                }}
            >
                ${this.renderSelectedRecipient()}
            </tm-bottom-sheet>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchRecipientsTable;
    }
}
