/* eslint-disable import/extensions */
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { TmBaseComponent } from '../tm-base.component';

export const tagName = 'tm-card';
@customElement(tagName)
export class TmCard extends TmBaseComponent {
    render() {
        return html`<slot></slot>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 4px;
                    width: var(--jhd-card-width, auto);
                    min-width: var(--tm-card-min-width, 350px);
                    min-height: var(--tm-card-min-height, 300px);
                    background: var(--primary-background);
                    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
                    border-radius: 10px;
                    flex: none;
                    order: 0;
                    align-self: stretch;
                    flex-grow: 0;
                    color: var(--primary-text-color);
                }
                @media (max-width: 375px) {
                    :host {
                        overflow-x: scroll;
                    }
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmCard;
    }
}
