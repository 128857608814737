import { ProductFeatureType as Feature } from '@treasury/domain/users/product-features/productFeature.type';

const superUserFeatures = [
    Feature.AccountAccess,
    Feature.AccountRecon,
    Feature.AchEntitlements,
    Feature.AchExceptions,
    Feature.AchFilters,
    Feature.ArpExceptions,
    Feature.InformationReporting,
    Feature.StopPayment,
    Feature.TransferEntitlements,
    Feature.WireDomesticEntitlements,
    Feature.WireInternationalEntitlements,
    Feature.FxWireEntitlements,
    Feature.ReceivablesEntitlements,
    Feature.IntegratedServicesEntitlements,
];

const superUserFeaturesMap = new Map();

superUserFeatures.forEach((feature: string) => {
    superUserFeaturesMap.set(feature, true);
});

const isSuperUserFeature = (feature: string) =>
    superUserFeaturesMap.has(feature) && superUserFeaturesMap.get(feature);

export { isSuperUserFeature, superUserFeaturesMap };
