import { AchTemplate } from '@treasury/domain/ach';
import { OffsetAccount } from '@treasury/domain/ach/offset-account.entity';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/forms/tm-form-frequency';
import { FrequencyEvent } from '@treasury/presentation/components/forms/tm-form-frequency';
import '@treasury/presentation/components/forms/tm-form-row';
import '@treasury/presentation/components/forms/tm-slider';
import '@treasury/presentation/components/layout/tm-content';
import '@treasury/presentation/components/layout/tm-section';
import '@treasury/presentation/components/tm-footer';
import { summarizeFrequency } from '@treasury/presentation/components/tm-frequency.summarizer';
import { Frequency, FrequencyType } from '@treasury/presentation/components/tm-frequency.types';
import '@treasury/presentation/components/tm-labeled-list';
import { LabeledList } from '@treasury/presentation/view-models';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../containers/ach-template-container';
import { AchTemplateVm } from '../data/ach-template-view-model';
import {
    isOffsetAccountReadOnly,
    isOffsetAccountRequired,
    isOffsetAccountVisible,
} from '../helpers/ach-offset-account.helpers';
import './ach-offset-accounts-table';
import { OffsetAccountEvent } from './ach-offset-accounts-table';
import './ach-recipients-table';

export const tagName = 'ach-payment-from-template-form';
@customElement(tagName)
export class achPaymentFromTemplateForm extends TmBaseComponent {
    protected readonly verifiedPropNames: (keyof this)[] = ['template'];

    @property({ type: Array })
    public templates!: AchTemplate[];

    @property({ type: Object })
    public template!: AchTemplate;

    @property({ type: Object })
    public formData!: { offsetAccount: OffsetAccount | null } & AchTemplateVm;

    @property({ type: Array })
    public offsetAccounts!: OffsetAccount[];

    @property({ type: String })
    public firstAvailableProcessingDay!: string;

    @state()
    frequency: Frequency | null = null;

    @state()
    offsetAccount: OffsetAccount | null = null;

    @state()
    entryDescription = '';

    @state()
    discretionaryData = '';

    @state()
    isRestrictedPayment = false;

    @state()
    canRestrictPayments = false;

    private entryDescriptionMaxLength = 10;

    private discretionaryDataMaxLength = 20;

    get hasOffsetAccount() {
        return (
            this.template.offsetAccount ||
            this.template.achCompany.offsetAccountNumber ||
            this.formData.offsetAccount
        );
    }

    get isFormValid() {
        if (isOffsetAccountRequired(this.template)) {
            return !!this.hasOffsetAccount && !!this.frequency && !!this.entryDescription;
        }
        return !!this.frequency && !!this.entryDescription;
    }

    protected firstUpdated(): void {
        this.frequency = this.formData.tmFrequency;
    }

    onReviewPayment() {
        this.formData.frequencyDisplay = summarizeFrequency(this.frequency as Frequency);
        this.formData.offsetAccountDisplay =
            this.formData.offsetAccount?.accountDisplayLabel ??
            (this.template.offsetAccount as string);

        this.dispatchEvent(
            new CustomEvent('reviewPayment', {
                detail: this.formData,
                bubbles: true,
                composed: true,
            })
        );
    }

    renderOffsetAccount() {
        if (!isOffsetAccountVisible(this.template)) return nothing;
        return html`<tm-form-row label="Offset Account">
            <tm-slider
                header="Select Offset Account"
                placeholder=${this.hasOffsetAccount ?? 'Select Account'}
                .value=${this.offsetAccount ?? this.formData?.offsetAccount?.accountDisplayLabel}
                .disabled=${isOffsetAccountReadOnly(this.template)}
                @select-offset-account=${(e: OffsetAccountEvent) => {
                    this.offsetAccount = e.detail.toDto().accountDisplayLabel as any;
                    this.formData.offsetAccount = e.detail.toDto() as any;
                }}
                .showControls=${false}
            >
                <ach-offset-accounts-table
                    .accounts=${this.offsetAccounts}
                ></ach-offset-accounts-table>
            </tm-slider>
        </tm-form-row>`;
    }

    renderRestrictPaymentOption() {
        if (this.canRestrictPayments) {
            return html`<tm-toggle
                @change=${(e: CustomEvent) => {
                    this.formData.restrictedPayment = e.detail.checked;
                    this.isRestrictedPayment = e.detail.checked;
                }}
            ></tm-toggle>`;
        }
        return nothing;
    }

    render() {
        const templateSummary = new LabeledList(
            this.template,
            ['companyName', 'companyId', 'secCode'],
            { companyName: 'ACH Company', companyId: 'ACH Company ID', secCode: 'SEC Code' }
        );
        const { recipients } = this.formData;
        return html`<tm-body .padding=${false}>
                <tm-section>
                    <tm-form-row label="Template">
                        <tm-slider header="Template Detail" .value=${this.template.name}>
                            <ach-template-container
                                .template=${this.template}
                            ></ach-template-container>
                        </tm-slider>
                        <tm-labeled-list
                            slot="summary"
                            class="py-4"
                            .list=${templateSummary}
                        ></tm-labeled-list>
                    </tm-form-row>

                    <tm-form-frequency
                        dateLabel="Effective Date"
                        .minDate=${this.firstAvailableProcessingDay}
                        .frequency=${this.formData.tmFrequency}
                        @select-frequency=${(e: FrequencyEvent) => {
                            this.frequency = e.detail;
                            this.formData.tmFrequency = e.detail as Frequency;
                        }}
                    ></tm-form-frequency>

                    ${this.renderOffsetAccount()}

                    <tm-form-row label="Entry Description" required noWrap>
                        <tm-text-field
                            .maxLength=${this.entryDescriptionMaxLength}
                            .value=${this.formData.entryDescription}
                            @value-changed=${(e: CustomEvent) => {
                                this.formData.entryDescription = e.detail.value;
                                this.entryDescription = e.detail.value;
                            }}
                            .helperText="${!!this.formData.entryDescription.length
                                ? `${this.formData.entryDescription.length}/${this.entryDescriptionMaxLength}`
                                : ''}"
                            class="helper-align-right"
                        ></tm-text-field>
                    </tm-form-row>

                    <tm-form-row label="Discretionary Data" noWrap>
                        <tm-text-field
                            .maxLength=${this.discretionaryDataMaxLength}
                            .value=${this.formData.discretionaryData}
                            @value-changed=${(e: CustomEvent) => {
                                this.formData.discretionaryData = e.detail.value;
                                this.discretionaryData = e.detail.value;
                            }}
                            .helperText="${!!this.formData.discretionaryData.length
                                ? `${this.formData.discretionaryData.length}/${this.discretionaryDataMaxLength}`
                                : ''}"
                            class="helper-align-right"
                        ></tm-text-field>
                    </tm-form-row>

                    <tm-form-row label="Restricted Payment" noWrap>
                        ${this.renderRestrictPaymentOption()}
                    </tm-form-row>
                </tm-section>

                <tm-section class="mt-2.5">
                    <ach-recipients-table
                        .recipients=${recipients}
                        @close-recipient=${() => {
                            this.formData.recipients = recipients;
                            this.requestUpdate();
                        }}
                    ></ach-recipients-table>
                </tm-section>
            </tm-body>

            <tm-footer
                .buttonConfig=${[
                    {
                        text: 'Review',
                        disabled: !this.isFormValid,
                        onClick: () => {
                            this.onReviewPayment();
                        },
                    },
                ]}
            >
                <div class="w-full flex justify-end items-center text-sm pb-2">
                    <div class="text-[--secondary-text-color]">Debit</div>
                    <div class="ml-2 font-medium">${this.formData.recipientTotalDebitAmount}</div>

                    <div class="ml-3 text-[--secondary-text-color]">Credit</div>
                    <div class="ml-2 font-medium">${this.formData.recipientTotalCreditAmount}</div>
                </div>
            </tm-footer>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: achPaymentFromTemplateForm;
    }
}
