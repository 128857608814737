import { NavigationService } from '@treasury/core/navigation';
import {
    AchCompany,
    AchPayment,
    AchService,
    AchTemplate,
    OffsetAccount,
} from '@treasury/domain/ach';
import { defaultAchPaymentModelDto } from '@treasury/domain/ach/data/ach.data';
import { EntitlementsService } from '@treasury/domain/entitlements';
import { NotificationService, TmContainer } from '@treasury/presentation';
import { FrequencyType } from '@treasury/presentation/components/tm-frequency.types';
import { InjectProperty } from '@treasury/utils';
import { DeepReactive } from '@treasury/utils/lit-helpers';
import { format } from 'date-fns';
import { html, nothing, PropertyValues } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import { AchTemplateVm } from '../data/ach-template-view-model';
import '../partials/ach-payment-confirm';
import '../partials/ach-payment-from-template-form';
import '../partials/ach-payment-review';
import { AchWorkflowParameters } from '../types/ach-workflow-parameters.type';

enum Step {
    Initiate,
    Review,
    Confirm,
}

export const tagName = 'ach-workflow-container';
@customElement(tagName)
export class AchWorkflowContainer extends TmContainer {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare service: AchService;

    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @InjectProperty()
    private declare entitlementsService: EntitlementsService;

    @state()
    template?: AchTemplate;

    @DeepReactive()
    formData?: AchTemplateVm;

    @state()
    payment: AchPayment = new AchPayment(defaultAchPaymentModelDto).createDefault();

    @state()
    offsetAccounts?: OffsetAccount[];

    @state()
    achCompanies: AchCompany[] = [];

    @state()
    achPaymentId = '';

    @state()
    private step = Step.Initiate;

    @state()
    private updateTemplate = false;

    @state()
    private firstAvailableProcessingDay = format(new Date(), 'yyyy-MM-dd');

    @state()
    private entitlements: Map<string, boolean> = new Map();

    @state()
    private canRestrictPayments = false;

    async firstUpdated() {
        const { params } = await this.navService.getRouteData<AchWorkflowParameters>();
        this.achPaymentId = params.id;

        await this.tryFetch(
            () =>
                Promise.all([
                    this.service.getNextAvailableAchDay(),
                    this.entitlementsService.getEntitlements(),
                ]),
            resp => {
                [this.firstAvailableProcessingDay, this.entitlements] = resp;
                this.canRestrictPayments = this.entitlements.get('Restricted Batch') ?? false;
            },
            (err: any) => {
                this.notificationService.renderError(err);
            }
        );

        if (this.achPaymentId) {
            await this.tryFetch(
                () => this.service.getTemplateById(this.achPaymentId),
                t => {
                    this.template = t;
                    this.formData = new AchTemplateVm(this.template.toDto());
                    this.formData.tmFrequency = {
                        type: FrequencyType.OneTime,
                        startDate: new Date(this.firstAvailableProcessingDay.replace(/-/g, '/')),
                    };
                },
                (err: any) => {
                    this.notificationService.renderError(err);
                }
            );
            if (this.template) {
                const { template } = this;
                await this.tryFetch(
                    () =>
                        Promise.all([
                            this.service.getOffsetAccountsByACHCompanyId(template.achCompany.id),
                            this.service.getAchCompanies(),
                        ]),
                    resp => {
                        [this.offsetAccounts, this.achCompanies] = resp;
                        if (this.template) this.setFormDataOffsetAccount();
                    },
                    (err: any) => {
                        this.notificationService.renderError(err);
                    }
                );
            }
            this.step = Step.Initiate;
        }
    }

    protected setFormDataOffsetAccount() {
        const achCompany = this.achCompanies.find(c => c.id === this.template?.achCompany.id);
        if (achCompany && this.template && this.formData) {
            this.formData.offsetAccount = this.template.offsetAccount =
                achCompany.offsetAccountNumber;
            this.formData.achCompany.offsetAccountNumber = achCompany.offsetAccountNumber;
        }
    }

    protected updated(_changedProperties: PropertyValues): void {
        if (_changedProperties.has('step')) {
            switch (this.step) {
                case Step.Initiate:
                    this.headerService.configure({
                        title: 'Create ACH Payment',
                        backAction: () => this.navService.navigate('/ach/templates/initiate'),
                    });
                    break;

                case Step.Review:
                    this.headerService.configure({
                        title: 'Review ACH Payment',
                        backAction: () => (this.step = Step.Initiate),
                    });
                    break;

                default:
                    break;
            }
        }
    }

    async submitTemplateAsPayment() {
        this.loading = true;
        await this.tryFetch(
            () =>
                this.service.createPayment(
                    this.formData as unknown as AchTemplate,
                    this.updateTemplate
                ),
            (data: AchPayment | Error) => {
                if (data instanceof Error) {
                    this.notificationService.renderError(data.message);
                } else {
                    this.payment = data;
                    if (!this.formData) return;
                    this.payment.frequencyDisplay = this.formData?.frequencyDisplay;
                    this.payment.offsetAccountDisplay = this.formData?.offsetAccountDisplay;
                    this.step = Step.Confirm;
                }
                this.loading = false;
            },
            (err: any) => {
                this.notificationService.renderError(err);
                this.loading = false;
            }
        );
    }

    renderCreatePaymentForm() {
        if (this.loading || !this.template || !this.offsetAccounts || this.step !== Step.Initiate) {
            return nothing;
        }
        return html`<ach-payment-from-template-form
            .template=${this.template}
            .formData=${this.formData}
            .offsetAccounts=${this.offsetAccounts}
            .canRestrictPayments=${this.canRestrictPayments}
            .firstAvailableProcessingDay=${this.firstAvailableProcessingDay}
            @reviewPayment=${(e: CustomEvent) => {
                this.payment = e.detail;
                this.step = Step.Review;
            }}
        ></ach-payment-from-template-form>`;
    }

    renderReviewPayment() {
        if (this.loading || this.step !== Step.Review) {
            return nothing;
        }
        return html`<ach-payment-review
            .payment=${this.payment}
            @updateTemplate=${(e: CustomEvent) => {
                this.updateTemplate = e.detail;
            }}
            @submitPayment=${(e: CustomEvent) => {
                this.payment = e.detail;
                this.submitTemplateAsPayment();
            }}
        ></ach-payment-review>`;
    }

    renderConfirmPayment() {
        if (this.loading || this.step !== Step.Confirm) {
            return nothing;
        }
        return html`<ach-payment-confirm
            .payment=${this.payment}
            @createAchTransfer=${() => {
                this.step = Step.Initiate;
                this.navService.navigate(`/ach/templates/initiate`);
            }}
        ></ach-payment-confirm>`;
    }

    render() {
        return [
            this.renderLoader(),
            this.renderCreatePaymentForm(),
            this.renderReviewPayment(),
            this.renderConfirmPayment(),
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchWorkflowContainer;
    }
}
