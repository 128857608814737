/* eslint-disable no-useless-constructor */
// eslint-disable-next-line import/no-extraneous-dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import { StatusType } from '@treasury/domain/channel/mappings/security';
import { AccountService } from '@treasury/domain/channel/services/account';
import { Injectable, InjectProperty } from '@treasury/utils/dependency-injection';
import { html, nothing } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { IdentityVerificationDialog } from '../identity-verification-dialog';
import {
    AccountValidationResult,
    IdentityDialogStrategy,
} from './identity-verification-dialog.types';

@Injectable()
export class SecureToken implements IdentityDialogStrategy {
    constructor(private readonly context: IdentityVerificationDialog) {}

    private inputValue = '';

    private isValid = false;

    public readonly passwordRef = createRef<HTMLInputElement>();

    @InjectProperty()
    private declare accountService: AccountService;

    private validateInput(event: KeyboardEvent) {
        if (!event.target || !this.passwordRef.value) {
            return;
        }

        const { value } = this.passwordRef.value;
        const { key } = event;
        this.inputValue = value.trim();

        const isValid = this.inputValue.length === 10;
        this.isValid = isValid;
        this.context.requestUpdate();

        if (key === 'Enter' && isValid) {
            this.context.verify();
        }
    }

    public async validateAccountPassword(password: string) {
        const result = await this.accountService.validateSecureToken(password);

        return {
            result,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            success: (result as any).status === StatusType.Allow,
        } as AccountValidationResult;
    }

    public renderContent() {
        const { verifying, securityMessage } = this.context;
        if (securityMessage?.status === StatusType.SecureTokenLocked) {
            return nothing;
        }

        return html`<div class="caption">
                We need to verify your identity for the security of the account. Please enter your
                security code followed by your 4-digit PIN (no-space)
            </div>
            <div class="input-wrapper">
                <input
                    ${ref(this.passwordRef)}
                    class="password"
                    type="password"
                    maxlength="10"
                    .disabled=${verifying}
                    @change=${(e: KeyboardEvent) => this.validateInput(e)}
                    @input=${(e: KeyboardEvent) => this.validateInput(e)}
                    autocomplete="off"
                    data-1p-ignore="true"
                />
            </div> `;
    }

    public renderActions() {
        const { disableClose, verifying, verify, cancel, securityMessage } = this.context;
        if (securityMessage?.status === 'SecureTokenLocked') {
            return html`<omega-button type="secondary" @click=${cancel}>Close</omega-button>`;
        }
        const cancelButton = disableClose
            ? nothing
            : html`<omega-button type="link" @click=${cancel}>Cancel</omega-button>`;
        return html`<div name="actions">
            <omega-button
                type="primary"
                .loading=${verifying}
                .disabled=${verifying || !this.isValid}
                @click=${verify}
                >Verify</omega-button
            >
            ${cancelButton}
        </div>`;
    }
}
