import '@vaadin/email-field';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { TmInputFieldBase } from './tm-input-field.base';

export const tagName = 'tm-email-field';
@customElement(tagName)
export class TmEmailField extends TmInputFieldBase {
    @property({ type: String })
    allowedCharacters?: string;

    @property({ type: Number })
    minLength?: number;

    @property({ type: Number })
    maxLength?: number;

    render() {
        return html`
            <vaadin-email-field
                @value-changed=${(e: CustomEvent) => {
                    const valueChangedEvent = new CustomEvent('value-changed', {
                        detail: e.detail,
                    });
                    this.dispatchEvent(valueChangedEvent);
                }}
                class="w-full"
                part="component"
                label=${this.label}
                value=${this.value}
                placeholder=${ifDefined(this.placeholder)}
                allowed-char-pattern=${ifDefined(this.allowedCharacters)}
                min-length=${ifDefined(this.minLength)}
                max-length=${ifDefined(this.maxLength)}
                ?required=${this.required}
            >
            </vaadin-email-field>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmEmailField;
    }
}
