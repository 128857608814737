/* eslint-disable no-empty-function */
import { PaymentDetail } from '@treasury/domain/bill-pay/payment-detail.entity';
import { formatUsdCurrency } from '@treasury/utils/functions/currency.helpers';
import { format } from 'date-fns';
import { getPaymentRecurrenceText } from './bill-pay-transform-form-data';

export class BillPayPaymentDetailViewModel {
    constructor(public readonly value: PaymentDetail) {}

    public get futurePayments() {
        return this.value.futurePayments;
    }

    public get amount() {
        return formatUsdCurrency(this.value.amount || 0);
    }

    public get comment() {
        return this.value.comment;
    }

    public get estimatedArrivalDate() {
        if (!this.value.estimatedArrivalDate) return '';
        return format(new Date(this.value.estimatedArrivalDate), 'P');
    }

    public get fromAccount() {
        return this.value.fromAccount?.name;
    }

    public get payee() {
        return this.value.payee?.name;
    }

    public get paymentMethod() {
        return this.value.paymentMethod;
    }

    public get paymentType() {
        return this.value.paymentType;
    }

    public get processDate() {
        if (!this.value.processDate) return '';
        return format(new Date(this.value.processDate), 'P');
    }

    public get recurrence() {
        if (!this.value.recurrence) return '';
        if (this.value.recurrence === 'One Time') return 'One Time';
        return getPaymentRecurrenceText(this.value.recurrence);
    }

    public get status() {
        return this.value.status;
    }
}
