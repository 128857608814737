import { AccountModel3Dto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';

export class BillPayAccountModel extends DomainEntity<AccountModel3Dto> {
    constructor(account: AccountModel3Dto) {
        super(account);
    }

    public createDefault() {
        return new BillPayAccountModel({} as AccountModel3Dto) as this;
    }

    get accountNumber() {
        return this.dto.accountNumber;
    }

    get accountType() {
        return this.dto.accountType;
    }

    get name() {
        return this.dto.name;
    }

    get routingNumber() {
        return this.dto.routingNumber;
    }

    get id() {
        return this.dto.id;
    }
}
