import { AchPayment } from '@treasury/domain/ach';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-labeled-list';
import { LabeledList } from '@treasury/presentation/view-models';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/jhd-status';

export const tagName = 'ach-payment-data';
@customElement(tagName)
export class AchPaymentData extends TmBaseComponent {
    @property()
    public payment!: AchPayment;

    @state()
    private auditOpen = false;

    get offsetAccountDisplayValue() {
        if (!this.payment.offsetAccount) return '';
        if (typeof this.payment.offsetAccount === 'string') {
            return this.payment.offsetAccount;
        }
        return this.payment.offsetAccount?.accountDisplayLabel;
    }

    private toggleAudit() {
        this.auditOpen = !this.auditOpen;
    }

    renderSummary() {
        return html`
            <div class="payment-summary py-4">
                <div class="flex justify-between mb-1">
                    <div class="col">
                        <div class="text-lg font-medium">${this.payment.templateName}</div>
                    </div>
                    <div class="col">
                        <div class="status flex align-center text-sm">
                            <jhd-status .status=${this.payment.status}></jhd-status>
                            <div class="ml-1">${this.payment.status}</div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-items-start">
                    <div class="flex flex-col">
                        <span class="text-xs font-normal text-[--secondary-text-color]"
                            >Credit</span
                        >
                        <span class="text-xl">${this.payment.recipientTotalCreditAmount}</span>
                    </div>
                    <div class="flex flex-col pl-4">
                        <span class="text-xs font-normal text-[--secondary-text-color]">Debit</span>
                        <span class="text-xl">${this.payment.recipientTotalDebitAmount}</span>
                    </div>
                </div>
            </div>
        `;
    }

    renderData() {
        const paymentData = new LabeledList(
            {
                companyName: this.payment.companyName,
                companyId: this.payment.companyId,
                secCode: this.payment.secCode,
                frequency: this.payment.frequencyDisplay,
                transactionId: this.payment.transactionId,
                offsetAccount: this.payment.offsetAccountDisplay,
                entryDescription: this.payment.entryDescription,
                discretionaryData: this.payment.discretionaryData,
                restrictedPayment: this.payment.restrictedPayment,
            },
            [
                'companyName',
                'companyId',
                'secCode',
                'frequency',
                'transactionId',
                'offsetAccount',
                'entryDescription',
                'discretionaryData',
                'restrictedPayment',
            ],
            {
                companyName: 'ACH Company',
                companyId: 'ACH Company ID',
                secCode: 'SEC Code',
                restrictedPayment: 'Restrict Payment',
            }
        );
        return html`<tm-labeled-list class="pb-4" .list=${paymentData}></tm-labeled-list>`;
    }

    renderAuditData() {
        if (!this.auditOpen) return nothing;
        if (!this.payment?.audit) return nothing;
        return html`<div class="pb-2">
            <div class="pb-2 font-medium">Audit</div>
            <div class="max-h-36 overflow-y-auto font-normal text-sm">${this.payment.audit}</div>
        </div>`;
    }

    renderAudit() {
        if (!this.payment?.audit) return nothing;
        const auditButtonText = this.auditOpen ? 'Hide Audit' : 'View Audit';
        return html`
            ${this.renderAuditData()}
            <div class="py-4 border-t border-dashed border-[--border-color]">
                <tm-button class="mt-2" @click=${() => this.toggleAudit()}>
                    ${auditButtonText}
                </tm-button>
            </div>
        `;
    }

    render() {
        return [this.renderSummary(), this.renderData(), this.renderAudit()];
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                    --icon-svg-transform: scale(1);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchPaymentData;
    }
}
