import '@treasury/presentation/components/forms/tm-form-row';
import '@treasury/presentation/components/forms/tm-slider';
import '@treasury/presentation/components/layout/tm-section';
import { summarizeFrequency } from '@treasury/presentation/components/tm-frequency.summarizer';
import { Frequency } from '@treasury/presentation/components/tm-frequency.types';
import { TmBaseComponent } from '@treasury/presentation/tm-base.component';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import './bill-pay-frequency';

export type FrequencyEvent = CustomEvent<Frequency>;

export const tagName = 'bill-pay-form-frequency';
@customElement(tagName)
export class BillPayFormFrequency extends TmBaseComponent {
    @property({ type: String })
    public dateLabel?: string;

    @property({ type: String })
    public minDate?: string;

    @property({ type: Boolean })
    public disabled?: boolean;

    @state()
    private frequency?: Frequency;

    @state()
    private disableSubmitButton = false;

    connectedCallback() {
        // eslint-disable-next-line wc/guard-super-call
        super.connectedCallback();
        this.addEventListener('close-tm-slider', this.handleSliderClose);
    }

    disconnectedCallback() {
        this.removeEventListener('close-tm-slider', this.handleSliderClose);
    }

    // Has to be in light dom so global custom border styles can affect it
    protected createRenderRoot() {
        return this;
    }

    get sliderValue() {
        return this.frequency ? 'Change Frequency' : 'Select Frequency';
    }

    handleSelection(e: CustomEvent<Frequency | false>) {
        if (!e.detail) {
            this.disableSubmitButton = true;
            return;
        }
        this.disableSubmitButton = false;
        this.frequency = e.detail;
    }

    private handleSliderClose() {
        this.dispatchEvent(new CustomEvent('close', { bubbles: true }));
        this.dispatchEvent(
            new CustomEvent('select-frequency', {
                detail: this.frequency,
                bubbles: true,
            }) as FrequencyEvent
        );
    }

    renderFrequencySummary() {
        if (!this.frequency) return nothing;
        return html`<div slot="summary" class="text-sm py-4">
            ${summarizeFrequency(this.frequency)}
        </div>`;
    }

    render() {
        return html`
            <tm-form-row label="Frequency">
                <tm-slider
                    header="Select Frequency"
                    .value=${this.sliderValue}
                    @selection=${this.handleSelection}
                    .disabled=${this.disabled}
                    .showControls=${true}
                    .disableSubmitButton=${this.disableSubmitButton}
                >
                    <tm-section class="p-4">
                        <bill-pay-frequency
                            .frequency=${ifDefined(this.frequency)}
                            dateLabel=${ifDefined(this.dateLabel)}
                            .minDate=${ifDefined(this.minDate)}
                        ></bill-pay-frequency>
                    </tm-section>
                </tm-slider>
                ${this.renderFrequencySummary()}
            </tm-form-row>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    box-sizing: border-box;
                    border-width: 0;
                    border-style: solid;
                    border-color: #e5e7eb;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: BillPayFormFrequency;
    }
}
