/* eslint-disable sort-class-members/sort-class-members */
/* eslint-disable no-use-before-define */
import { ErrorType, SecurityMessage, StatusType } from '@treasury/domain/channel/mappings/security';
import { AccountService } from '@treasury/domain/channel/services/account';
import { Injectable, InjectProperty } from '@treasury/utils/dependency-injection';
import { html, nothing } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { IdentityVerificationDialog } from '../identity-verification-dialog';
import {
    AccountValidationResult,
    IdentityDialogStrategy
} from './identity-verification-dialog.types';

@Injectable()
export class OobOtp implements IdentityDialogStrategy {
    // eslint-disable-next-line no-useless-constructor
    constructor(private context: IdentityVerificationDialog) {}

    private isValid = false;

    public readonly passwordRef = createRef<HTMLInputElement>();

    @InjectProperty()
    private declare accountService: AccountService;

    public async validateAccountPassword(password: string) {
        const result = await this.accountService.validateOneTimePassword(password);

        return {
            result,
            success: result.status === StatusType.Success,
        } as AccountValidationResult;
    }

    public renderContent() {
        const { verifying } = this.context;
        return html`<div class="caption">
                We need to verify your identity. For the security of the account, please enter your
                One-Time Passcode below.
            </div>
            <div class="input-wrapper">
                <input
                    ${ref(this.passwordRef)}
                    class="password"
                    type="password"
                    .disabled=${verifying}
                    @change=${(e: Event) => this.validateInput()}
                    autocomplete="off"
                    data-lpignore="true"
                    data-1p-ignore="true"
                />
            </div> `;
    }

    public renderActions() {
        const { securityMessage, verifying, verify, cancel } = this.context;
        if (!securityMessage.errorCode) return nothing;
        const canRetry = securityMessage.errorCode !== ErrorType.Locked;
        return html`<div name="actions">
            <omega-button
                type="primary"
                .loading=${verifying}
                .disabled=${verifying || !this.isValid}
                @click=${verify}
            >
                Verify
            </omega-button>
            ${canRetry && !!securityMessage.methodUsed
                ? html`
                      <omega-button
                          type="secondary"
                          .loading=${verifying}
                          .disabled=${verifying}
                          @click=${() => this.resend(securityMessage)}
                      >
                          ${!securityMessage.methodUsed || securityMessage.methodUsed === 'Text'
                              ? 'Resend text message'
                              : 'Call again'}
                      </omega-button>
                      ${securityMessage.hasAlternate
                          ? html` <omega-button
                                type="secondary"
                                .loading=${verifying}
                                .disabled=${verifying}
                                @click=${() => this.resendAlternate(securityMessage)}
                            >
                                ${!securityMessage.methodUsed ||
                                securityMessage.methodUsed === 'Text'
                                    ? 'Call me instead'
                                    : 'Text me instead'}
                            </omega-button>`
                          : nothing}
                  `
                : nothing}
            ${!this.context.disableClose
                ? html`<omega-button type="link" @click=${cancel}>
                      ${canRetry ? 'Cancel' : 'Close'}
                  </omega-button>`
                : nothing}
        </div>`;
    }

    private validateInput() {
        const inputValue = this.passwordRef.value?.value || '';
        this.isValid = inputValue.length >= 6 && inputValue.length <= 8;
        this.context.requestUpdate();
    }

    private resendAlternate(securityMessage: SecurityMessage) {
        this.context.reverify({
            ...securityMessage,
            status: StatusType.VerifyUsingAlternate,
            oneTimePassword: null,
            message: null,
        });
    }

    private resend(securityMessage: SecurityMessage) {
        this.context.reverify({
            ...securityMessage,
            oneTimePassword: null,
            status: null,
            errorCode: null,
            message: null,
        });
    }
}
