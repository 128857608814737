import { ConfigurationService } from '@treasury/core/config';
import { NavigationService } from '@treasury/core/navigation';
import '@treasury/omega/components/progress/omega-progress';
import { TmBaseComponent } from '@treasury/presentation';
import { hideIcon, showIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-icon';
import '@treasury/presentation/components/tm-password-field';
import '@treasury/presentation/components/tm-text-field';
import { InjectProperty } from '@treasury/utils';
import { css, html, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

export const tagName = 'treasury-login';
@customElement(tagName)
export default class Login extends TmBaseComponent {
    constructor() {
        super();
        this.addEventListener('keydown', (e: KeyboardEvent) => {
            this.onKeyDown(e);
        });
    }

    @property({ type: Boolean })
    loading = false;

    @state()
    private companyId = '';

    @state()
    private loginId = '';

    @state()
    private password = '';

    @state()
    private passwordInputType: 'password' | 'text' = 'password';

    @state()
    private showPasswordLabel: 'Show Password' | 'Hide Password' = 'Show Password';

    @state()
    private passwordIcon: TemplateResult = showIcon;

    @InjectProperty()
    private declare readonly config: ConfigurationService;

    @InjectProperty()
    private declare navigationService: NavigationService;

    get isValid() {
        return this.companyId && this.loginId && this.password;
    }

    login() {
        const { companyId, loginId, password } = this;
        const formData = {
            companyId,
            loginId,
            password,
        };
        this.dispatchEvent(new CustomEvent('login', { detail: formData }));
    }

    onKeyDown(e: KeyboardEvent) {
        if (e.code === 'Enter' && this.isValid) {
            this.login();
        }
    }

    togglePasswordType() {
        if (this.passwordInputType === 'password') {
            this.passwordInputType = 'text';
            this.passwordIcon = hideIcon;
            this.showPasswordLabel = 'Hide Password';
        } else {
            this.passwordInputType = 'password';
            this.passwordIcon = showIcon;
            this.showPasswordLabel = 'Show Password';
        }
    }

    render() {
        return html`
            <div id="content" class="flex flex-col justify-end h-full">
                <div class="m-4">
                    <tm-text-field
                        label="Company ID"
                        aria-label="Company ID"
                        .value=${this.companyId}
                        @value-changed=${(e: CustomEvent) => {
                            this.companyId = e.detail.value;
                        }}
                        required
                        autocapitalize="off"
                    >
                    </tm-text-field>
                    <tm-text-field
                        label="Login ID"
                        aria-label="Login ID"
                        .value=${this.loginId}
                        @value-changed=${(e: CustomEvent) => {
                            this.loginId = e.detail.value;
                        }}
                        required
                        class="pb-1"
                        autocapitalize="off"
                    >
                    </tm-text-field>
                    <tm-password-field
                        label="Password"
                        aria-label="Password"
                        .value=${this.password}
                        @value-changed=${(e: CustomEvent) => {
                            this.password = e.detail.value;
                        }}
                        required
                        class="pb-1"
                        autocapitalize="off"
                    >
                        <button
                            slot="input-right"
                            aria-label="${this.showPasswordLabel}"
                            @click=${() => this.togglePasswordType()}
                        >
                            <jhd-icon .icon=${this.passwordIcon}></jhd-icon>
                        </button>
                    </tm-password-field>
                </div>
                <tm-footer
                    .buttonConfig=${[
                        {
                            text: 'Login',
                            onClick: () => this.login(),
                            disabled: !this.companyId || !this.loginId || !this.password,
                        },
                        {
                            importance: 'tertiary',
                            text: 'Forgot Password?',
                            onClick: () => {
                                this.navigationService.navigate(`/forgot-password`);
                            },
                        },
                    ] as ButtonConfig[]}
                >
                </tm-footer>
            </div>
        `;
    }

    static get styles() {
        return [
            css`
                :host() {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                :host([stickyheader]) {
                    display: block !important;
                }

                :host([stickyheader]) #content {
                    padding-bottom: var(--sticky-header-height, 0);
                }

                tm-footer {
                    padding-top: 1em;
                }

                @media only screen and (min-width: 640px) {
                    :host([stickyheader]) #content {
                        padding-bottom: 0px;
                    }
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: Login;
    }
}
