/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { AchPaymentFilterModelDto, AchPaymentModelDto, ErrorHttpContentDto } from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class AchRecurringPaymentsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsPostAll2
     * @summary Provides the endpoint to search for ACH payments.
     * @request POST:/achRecurringPayments/search
     */
    achPaymentsPostAll2 = (
        achPaymentFilter: AchPaymentFilterModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<AchPaymentModelDto[], ErrorHttpContentDto>({
            path: `/achRecurringPayments/search`,
            method: 'POST',
            body: achPaymentFilter,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchRecurringPayments
     * @name AchRecurringPaymentsGet
     * @summary Provides the end point to get all the ach payments.
     * @request GET:/achRecurringPayments
     */
    achRecurringPaymentsGet = (params: RequestParams = {}) =>
        this.http.request<AchPaymentModelDto, any>({
            path: `/achRecurringPayments`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchRecurringPayments
     * @name AchRecurringPaymentsPost
     * @summary Returns an empty List`1 of AchPaymentModel.
     * @request POST:/achRecurringPayments
     */
    achRecurringPaymentsPost = (
        achPaymentFilter: AchPaymentFilterModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchPaymentModelDto[], any>({
            path: `/achRecurringPayments`,
            method: 'POST',
            body: achPaymentFilter,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
