import { ConfigurationService } from '@treasury/core/config';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-bottom-sheet';
import { InjectProperty } from '@treasury/utils';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

export const tagName = 'login-layout';
@customElement(tagName)
export class LoginLayout extends TmBaseComponent {
    @property({ type: String })
    headerText!: string;

    @property({ reflect: true, type: Boolean })
    stickyHeader = true;

    @property({ reflect: true, type: Boolean })
    bottomAffixed = true;

    @InjectProperty()
    private declare readonly config: ConfigurationService;

    protected firstUpdated() {
        const phaseSlot = this.querySelector('span[slot="phase"]');
        const ro = new ResizeObserver(i => {
            if (this.stickyHeader && this.bottomAffixed && phaseSlot) {
                this.scrollUp();
            }
        });
        if (phaseSlot) {
            ro.observe(phaseSlot);
        }
    }

    private scrollUp() {
        const cardContainer = this.shadowRoot?.getElementById('card');
        if (!cardContainer) return;
        cardContainer.scrollTop = cardContainer.offsetHeight - cardContainer.scrollHeight;
    }

    renderDynamicStyles() {
        const url = `/pwa/assets/branding/${this.config.institutionId}/background.webp`;
        return html`
            <style>
                #brand-container {
                    background-image: url(${url});
                }
            </style>
        `;
    }

    renderBrandedBackground() {
        const imgSrc = `/pwa/assets/branding/${this.config.institutionId}/logo.webp`;
        return html` <div
            id="brand-container"
            class="fixed bg-top bg-no-repeat bg-cover inset-0 h-2/3 sm:h-full place-content-center sm:place-content-start"
        >
            <img
                src="${imgSrc}"
                alt=""
                id="brand-logo"
                class="absolute top-[12vh] right-1/2 translate-x-2/4 max-w-[75%] p-0"
            />
        </div>`;
    }

    renderBrandedStickyHeader() {
        const imgSrc = `/pwa/assets/branding/${this.config.institutionId}/logo.webp`;
        return html`
            <div
                id="sticky-header-bar"
                class="sticky flex top-0 left-0 z-20 w-full m-0 p-[--navbar-padding] text-white justify-center"
            >
                <img class="object-contain" src="${imgSrc}" alt="" />
            </div>
        `;
    }

    renderCardContents() {
        return html`
            <div class="card-header flex items-center justify-between text-[--primary-text-color]">
                <h1 class="text-lg font-medium m-0 pl-4 pt-4">${this.headerText}</h1>
            </div>
            <slot name="phase"></slot>
        `;
    }

    render() {
        const contentClasses = {
            'h-full': this.stickyHeader,
            'overflow-scroll': this.stickyHeader,
            'w-full': true,
            'items-center': true,
            'sm:pb-0': this.stickyHeader,
        };
        const cardClasses = {
            'sm:py-0': this.stickyHeader,
            'h-full': this.stickyHeader,
            'sm:h-auto': this.stickyHeader,
            'mx-auto': true,
            'sm:my-[30px]': this.stickyHeader,
            'min-h-[300px]': true,
            'sm:relative': !this.stickyHeader,
            fixed: this.bottomAffixed && !this.stickyHeader,
            relative: !this.bottomAffixed && this.stickyHeader,
            absolute: !this.bottomAffixed && !this.stickyHeader,
            'left-0': true,
            'bottom-0': true,
            'w-full': true,
            'bg-[--primary-background]': true,
            'sm:rounded-[10px]': true,
            'rounded-none': true,
        };

        return html`
            ${this.renderDynamicStyles()}
            ${this.stickyHeader ? this.renderBrandedStickyHeader() : this.renderBrandedBackground()}
            <slot name="alert"></slot>
            <div id="content-pane" class=${classMap(contentClasses)}>
                <div id="card" class=${classMap(cardClasses)}>${this.renderCardContents()}</div>
            </div>
        `;
    }

    static styles = [
        css`
            :host {
                overflow: hidden;
                position: relative;
                background: var(--background-gradient);
                width: 100%;
                height: 100%;
                --sticky-header-height: 60px;
            }

            :host([stickyheader]) {
                position: absolute;
                background: #fff;
            }

            ::slotted([slot='alert']) {
                display: block;
                position: absolute;
                z-index: 10;
                width: 100%;
                padding: 0 0.5rem;
                left: 50%;
                transform: translateX(-50%);
            }

            :host(:not([stickyheader]))::slotted([slot='phase']) {
                display: block;
                height: 100%;
                --tm-footer-position: static;
            }

            #sticky-header-bar {
                background: var(--header-gradient, rgba(255, 255, 255, 0.05));
                height: var(--sticky-header-height);
            }

            #content-pane {
                padding-bottom: var(--sticky-header-height);
                background: var(--primary-background);
            }

            @media (min-width: 640px) {
                :host {
                    position: absolute;
                    inset: 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background: var(--primary-background);
                }

                #card {
                    box-shadow: rgb(0 0 0 / 40%) 0px 0px 8px;
                    width: clamp(300px, 95%, var(--login-card-max-width, 600px));
                }

                :host([stickyheader])::slotted([slot='phase']) {
                    --tm-footer-position: static;
                }
            }
        `,
    ];
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: LoginLayout;
    }
}
