import { Injectable } from '@treasury/utils';
import { AccountService } from './account.service';
import { UserAccount } from './types';

@Injectable()
export class UserProfileService {
    constructor(private readonly service: AccountService) {}

    public async getCurrentUserProfile() {
        const dto = await this.service.getCurrentUserAccountSettings();

        return new UserAccount(dto);
    }

    public async updateCurrentUserProfile(userProfile: UserAccount) {
        this.service.resetCurrentUserAccountCache();

        const dto = userProfile.toDto();
        const response = await this.service.updateCurrentUserAccountSettings(dto);
        const { status, message, errorCode } = response;

        // TODO: create a bespoke type we own; for now, return just what is needed by the view
        return {
            status,
            message,
            errorCode,
        };
    }
}
