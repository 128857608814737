/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { PrintAuditModelDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class PrintClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Print
     * @name PrintUserActivityAudit
     * @summary Audits the user activity.
     * @request POST:/print
     */
    printUserActivityAudit = (printAuditModel: PrintAuditModelDto, params: RequestParams = {}) =>
        this.http.request<void, any>({
            path: `/print`,
            method: 'POST',
            body: printAuditModel,
            ...params,
        });
    /**
     * No description
     *
     * @tags Print
     * @name PrintUserActivityAudit2
     * @summary Audits the user activity.
     * @request POST:/print/userActivityAudit
     */
    printUserActivityAudit2 = (printAuditModel: PrintAuditModelDto, params: RequestParams = {}) =>
        this.http.request<void, any>({
            path: `/print/userActivityAudit`,
            method: 'POST',
            body: printAuditModel,
            ...params,
        });
}
