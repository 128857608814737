import { HolidayCalendarModelDto } from '@treasury/api/channel';
import { createAccountFilterMessageFromAllowProperties } from '@treasury/domain/channel/mappings/ach-filters/ach-filter-search';
import { FiDate } from '@treasury/domain/dates';
import { ActiveDateFilterFunction } from './date-picker.types';

export const inactiveMinMaxDates: ActiveDateFilterFunction = (
    date: Date,
    minDate: Date,
    maxDate: Date
) => date.getTime() >= minDate.getTime() && date.getTime() <= maxDate.getTime();

export const inactiveWeekends: ActiveDateFilterFunction = (date: Date) =>
    !(date.getDay() === 0 || date.getDay() === 6);

export const inactiveHolidays: ActiveDateFilterFunction = (
    date: Date,
    holidays: HolidayCalendarModelDto[]
) => {
    if (!holidays) return true;

    const holidayDates = holidays.map(holiday => new FiDate(holiday.date).date);
    return !holidayDates.some(holiday => !(holiday > date || holiday < date));
};
