import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-date-range';
import { DateRange, DateRangeType } from '@treasury/presentation/components/tm-date-range';
import { mapDateRangeToDto } from '@treasury/presentation/components/tm-date-range.mappings';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import { DeepReactive } from '@treasury/utils/lit-helpers';
import { sub } from 'date-fns';
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { defaultTransactionDateRange } from '../data/default-account-transaction-filters';

export const tagName = 'account-transactions-filter-sheet';
@customElement(tagName)
export class AccountTransactionsFilterSheet extends TmBaseComponent {
    @property({ type: Boolean })
    public open = false;

    @state()
    private transactionDateRange: DateRange = defaultTransactionDateRange;

    @DeepReactive()
    private validState = {
        transactionDate: true,
        amount: true,
    };

    async firstUpdated() {
        this.onApplyFilters();
    }

    private onResetForm() {
        this.transactionDateRange = { ...defaultTransactionDateRange };
        this.requestUpdate();
    }

    private toDto() {
        const { dateFrom: transactionDateFrom, dateTo: transactionDateTo } = mapDateRangeToDto(
            this.transactionDateRange
        );

        return {
            transactionDateFrom,
            transactionDateTo,
        };
    }

    onApplyFilters() {
        this.dispatchEvent(
            new CustomEvent('applyFilters', {
                detail: {
                    filters: this.toDto(),
                    transactionDateRange: this.transactionDateRange,
                },
            })
        );
        this.dispatchEvent(new CustomEvent('close'));
    }

    renderFilters() {
        return html`<form>
            <tm-date-range
                label="Transaction Date"
                .range=${this.transactionDateRange}
                .dateRangeOptions=${[
                    DateRangeType.DateRange,
                    DateRangeType.WeekToDate,
                    DateRangeType.MonthToDate,
                    DateRangeType.YearToDate,
                ]}
                .minDate=${sub(new Date(), { years: 2 })}
                .maxDate=${new Date()}
                @selection=${(e: CustomEvent) => {
                    this.validState.transactionDate = !!e.detail;
                    if (!!e.detail) {
                        this.transactionDateRange = e.detail;
                    }
                }}
            ></tm-date-range>
        </form>`;
    }

    render() {
        return html`<tm-bottom-sheet
            .open=${this.open}
            @close=${() => {
                this.open = false;
                this.dispatchEvent(new CustomEvent('close'));
            }}
            ><p slot="header-center">Filter Account Transactions</p>
            <p slot="header-right"></p>
            <tm-body>${this.renderFilters()}</tm-body>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: 'Apply Filters',
                        onClick: () => {
                            this.onApplyFilters();
                        },
                        disabled: Object.values(this.validState).some(isValid => !isValid),
                    },
                    {
                        importance: 'secondary',
                        text: 'Reset',
                        onClick: () => {
                            this.onResetForm();
                        },
                    },
                ] as ButtonConfig[]}
            ></tm-footer>
        </tm-bottom-sheet>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AccountTransactionsFilterSheet;
    }
}
