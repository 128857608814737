export const transferPaymentStatusItems = [
    {
        label: 'Pending Approval',
        value: {
            key: 'PendingApproval',
            value: 'Pending Approval',
        },
    },
    {
        label: 'Scheduled',
        value: {
            key: 'Scheduled',
            value: 'Scheduled',
        },
    },
    {
        label: 'Submitted',
        value: {
            key: 'Submitted',
            value: 'Submitted',
        },
    },
    {
        label: 'Approval Rejected',
        value: {
            key: 'ApprovalRejected',
            value: 'Approval Rejected',
        },
    },
    {
        label: 'Cancelled',
        value: {
            key: 'Cancelled',
            value: 'Cancelled',
        },
    },
    {
        label: 'Failed',
        value: {
            key: 'Failed',
            value: 'Failed',
        },
    },
    {
        label: 'Expired',
        value: {
            key: 'Expired',
            value: 'Expired',
        },
    },
];
