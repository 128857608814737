import { User } from '@treasury/domain/users/user.entity';

export class UserViewModel {
    constructor(readonly value: User) {}

    get id() {
        return this.value.toDto().id;
    }

    get userName() {
        return this.value.userName;
    }

    get status() {
        return this.value.status;
    }

    get features() {
        return this.value.userProductFeatures;
    }

    get isSuperUser() {
        return this.value.isSuperUser;
    }

    get isAdmin() {
        return this.value.isAdmin;
    }

    get isEditor() {
        return this.value.isEditor;
    }
}
