import { Injectable, ObservationSource } from '@treasury/utils';
import { HeaderConfig } from './types/jhd-header-bar.type';

const defaultConfig: HeaderConfig = {
    backAction: null,
    title: '',
    subtitle: '',
    menuItems: [],
    empty: false,
};

Object.freeze(defaultConfig);

@Injectable()
export class HeaderBarService {
    private config: HeaderConfig = defaultConfig;

    private configSource = new ObservationSource<HeaderConfig>();

    public readonly config$ = this.configSource.toObservable();

    public configure(c: Partial<HeaderConfig>) {
        this.config = { ...defaultConfig, ...c };
        this.configSource.emit(this.config);
    }
}
