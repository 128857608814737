import { FrequencyModelDto } from '@treasury/api/channel';
import { NavigationService } from '@treasury/core/navigation';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import { mapFrequencyModelDtoToFrequency } from '@treasury/presentation/components/tm-frequency.mappings';
import { summarizeFrequency } from '@treasury/presentation/components/tm-frequency.summarizer';
import { FrequencyType } from '@treasury/presentation/components/tm-frequency.types';
import { InjectProperty } from '@treasury/utils';
import { format } from 'date-fns';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../components/confirmation-container';
import { TransferViewModel } from '../types/transfer-view-model.type';
import './transfer-template-detail.template';

export const tagName = 'confirm-transfer-payment-step';
@customElement(tagName)
export class ConfirmTransferPaymentStep extends TmBaseComponent {
    @property({ type: Array })
    transfers = [] as TransferViewModel[];

    @property({ type: Boolean })
    isTemplate = false;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    createNewTransfer = () => {
        this.dispatchEvent(new CustomEvent('create-new-transfer'));
    };

    get isPending() {
        return this.transfers[0].status.toLowerCase() === 'pendingapproval';
    }

    get confirmationType() {
        return this.isPending ? 'pending' : 'success';
    }

    get subheadingText() {
        return this.isPending
            ? 'Your transfer is pending approval'
            : 'Your transfer has been submitted';
    }

    renderTransferDetails() {
        if (!this.transfers.length) return nothing;
        if (this.isTemplate) {
            return html`<transfer-template-detail
                .transfer=${this.transfers}
            ></transfer-template-detail>`;
        }
        return this.transfers.map((transfer: TransferViewModel) => {
            const frequency = mapFrequencyModelDtoToFrequency(
                transfer.frequency as FrequencyModelDto
            );
            return html`
                <div class="transfer-data-wrapper p-3">
                    <div class="amount-label text-lg font-medium text-[--header-text-color]">
                        Transfer
                    </div>
                    <div class="transfer-amount text-2xl text-[--header-text-color]">
                        ${new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }).format(transfer.amount)}
                    </div>
                    <jhd-data-field
                        .label=${'From'}
                        .value=${transfer.fromAccount.accountDisplayLabel}
                    ></jhd-data-field>
                    <div class="border-t border-dashed border-[--border-color]">
                        <jhd-data-field
                            .label=${'To'}
                            .value=${transfer.toAccount.accountDisplayLabel}
                        ></jhd-data-field>
                        <jhd-data-field
                            .label=${frequency.type === FrequencyType.OneTime
                                ? 'Transfer Date'
                                : 'Frequency'}
                            .value=${frequency.type === FrequencyType.OneTime
                                ? format(new Date(transfer.transferDate), 'MM/dd/yyyy')
                                : summarizeFrequency(frequency)}
                        ></jhd-data-field>
                        <jhd-data-field
                            .label=${'Transaction ID'}
                            .value=${transfer.transactionId}
                        ></jhd-data-field>
                        <jhd-data-field .label=${'Memo'} .value=${transfer.memo}></jhd-data-field>
                    </div>
                </div>
            `;
        });
    }

    render() {
        return html`
            <tm-body>
                <confirmation-container
                    .headingText=${'Transfer Submitted'}
                    .subheadingText=${this.subheadingText}
                    .confirmationType=${this.confirmationType}
                >
                    <div class="p-3 border-t border-dashed border-[--border-color]">
                        ${this.renderTransferDetails()}
                    </div>
                </confirmation-container></tm-body
            >
            <tm-footer
                visible
                .buttonConfig=${[
                    {
                        text: 'Make another transfer',
                        importance: 'secondary',
                        disabled: false,
                        onClick: this.createNewTransfer,
                    },
                    {
                        text: 'View transfer activity',
                        importance: 'secondary',
                        disabled: false,
                        onClick: () => {
                            this.navService.navigate(`/transfers`);
                        },
                    },
                ] as ButtonConfig[]}
            >
            </tm-footer>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: ConfirmTransferPaymentStep;
    }
}
