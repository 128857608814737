/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import {
    GetInstitutionResponseModelDto,
    GetUserResponseModelDto,
    PaymentTypeDto,
} from '@treasury/api/channel';
import { NavigationService } from '@treasury/core/navigation';
import BillPayService, {
    BillPaymentQuery,
    PaymentTypes,
} from '@treasury/domain/bill-pay/bill-pay.service';
import { PaymentListModel } from '@treasury/domain/bill-pay/payment-list.entity';
import { TmBaseComponent } from '@treasury/presentation';
import { circlePlusIcon, informationIcon, userIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-loader';
import '@treasury/presentation/components/tm-table-header';
import '@treasury/presentation/components/tm-tabs';
import { InjectProperty } from '@treasury/utils';
import { css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { filterTableResults } from '../../../utilities/table-result-filter';
import '../../components/jhd-route-action-item';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import { BillPayPaymentTableViewModel } from '../data/bill-pay-payment-table-view-model';
import '../partials/payment-filters-sheet';
import '../partials/payment-history-table';
import '../partials/scheduled-payments-table';

export const tagName = 'business-bill-pay-container';
@customElement(tagName)
export class BusinessBillPayContainer extends TmBaseComponent {
    @InjectProperty()
    private declare billPayService: BillPayService;

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare navService: NavigationService;

    @state()
    menuItems = [
        {
            title: 'Info',
            icon: informationIcon,
            action: () => console.log('Info clicked'),
        },
    ];

    @state()
    activeTab = 'scheduled';

    @state()
    tabs = [
        {
            label: 'Scheduled',
            id: 'scheduled',
        },
        {
            label: 'History',
            id: 'history',
        },
    ];

    @state()
    loading = false;

    @state()
    scheduledPayments: BillPayPaymentTableViewModel[] = [];

    @state()
    paymentHistory: BillPayPaymentTableViewModel[] = [];

    @state()
    filteredPayments: BillPayPaymentTableViewModel[] = [];

    @state()
    payees = [];

    @state()
    fieldsToFilter = ['payeeName', 'amount', 'processDate', 'status'];

    @state()
    institution = {};

    @state()
    user = {};

    @state()
    paymentFiltersOpen = false;

    @state()
    filterData: BillPaymentQuery = {
        startDate: '',
        endDate: '',
        type: 1,
    };

    async firstUpdated() {
        this.headerService.configure({ title: 'Business Bill Pay', menuItems: this.menuItems });
        this.loading = true;
        try {
            await this.getActiveTableTab();
            this.institution =
                (await this.billPayService.getBillPayInstitution()) as GetInstitutionResponseModelDto;
            this.user = (await this.billPayService.getBillPayUser()) as GetUserResponseModelDto;
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    async getActiveTableTab() {
        this.loading = true;
        switch (this.activeTab) {
            case 'scheduled':
                this.scheduledPayments = (
                    await this.billPayService.getBillPayments({
                        ...this.filterData,
                        type: PaymentTypes.Scheduled,
                    })
                )?.map((payment: PaymentListModel) => new BillPayPaymentTableViewModel(payment));
                this.filteredPayments = this.scheduledPayments;
                this.loading = false;
                break;
            case 'history':
                this.paymentHistory = (
                    await this.billPayService.getBillPayments({
                        ...this.filterData,
                        type: PaymentTypes.Past,
                    })
                )?.map((payment: PaymentListModel) => new BillPayPaymentTableViewModel(payment));
                this.filteredPayments = this.paymentHistory;
                this.loading = false;
                break;
            default:
                this.loading = false;
                break;
        }
    }

    filterPayments(filterString: string) {
        switch (this.activeTab) {
            case 'scheduled':
                this.filteredPayments = filterTableResults(
                    filterString,
                    this.scheduledPayments,
                    this.fieldsToFilter
                );
                break;
            case 'history':
                this.filteredPayments = filterTableResults(
                    filterString,
                    this.paymentHistory,
                    this.fieldsToFilter
                );
                break;
            default:
                break;
        }
    }

    switchTab(e: CustomEvent) {
        if (e.detail.activeTab === this.activeTab) return;
        this.activeTab = e.detail.activeTab;
        this.getActiveTableTab();
    }

    renderActiveTable() {
        if (this.loading) return nothing;
        if (!this.filteredPayments.length)
            return html`<div class="flex justify-center items-center h-64 italic">
                No payments found
            </div>`;
        switch (this.activeTab) {
            case 'scheduled':
                return html`<scheduled-payments-table
                    .scheduledPayments=${this.filteredPayments}
                    @payment-selected=${(e: CustomEvent) => {
                        this.navService.navigate(
                            `/business-bill-pay/payment/${PaymentTypeDto.Scheduled}/${e.detail.id}`
                        );
                    }}
                ></scheduled-payments-table>`;
            case 'history':
                return html`<payment-history-table
                    .historicPayments=${this.filteredPayments}
                    @payment-selected=${(e: CustomEvent) => {
                        this.navService.navigate(
                            `/business-bill-pay/payment/${PaymentTypeDto.Past}/${e.detail.id}`
                        );
                    }}
                ></payment-history-table>`;
            default:
                return nothing;
        }
    }

    renderPaymentFilters() {
        return html`<payment-filters-sheet
            .open=${this.paymentFiltersOpen}
            @close=${() => {
                this.paymentFiltersOpen = false;
            }}
            @apply-filters=${(e: CustomEvent) => {
                this.filterData = e.detail.filterData;
                this.paymentFiltersOpen = false;
                this.getActiveTableTab();
            }}
        ></payment-filters-sheet>`;
    }

    renderLoader() {
        if (!this.loading) return nothing;
        return html`<tm-loader card></tm-loader>`;
    }

    render() {
        const createRoute = `/business-bill-pay/payments/create/new`;
        const payeeListRoute = `/business-bill-pay/payees`;
        return html`<jhd-route-action-item
                .icon=${circlePlusIcon}
                .action=${'Create payment'}
                .route=${createRoute}
            ></jhd-route-action-item>
            <jhd-route-action-item
                .icon=${userIcon}
                .action=${'Payees'}
                .route=${payeeListRoute}
            ></jhd-route-action-item>
            <div id="payment-table-wrapper">
                <tm-table-header
                    .filterButton=${true}
                    @filterStringChange=${(e: CustomEvent) => {
                        this.filterPayments(e.detail.value);
                    }}
                    @filterButtonClick=${() => {
                        this.paymentFiltersOpen = true;
                    }}
                    ><h2 class="font-medium">Payments</h2></tm-table-header
                >

                <tm-tabs
                    .activeTab=${this.activeTab}
                    .tabs=${this.tabs}
                    @switchTab=${(e: CustomEvent) => this.switchTab(e)}
                ></tm-tabs>
                ${this.renderActiveTable()}${this.renderLoader()}
            </div>
            ${this.renderPaymentFilters()}`;
    }

    static get styles() {
        return [
            css`
                #payment-table-wrapper {
                    background-color: var(--primary-background);
                    min-height: 300px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: BusinessBillPayContainer;
    }
}
