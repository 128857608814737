import { TmBaseComponent } from '@treasury/presentation';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

export const tagName = 'user-fx-wire-limits';
@customElement(tagName)
export class UserFxWireLimits extends TmBaseComponent {
    @property({ type: Object })
    public config: any = {};

    render() {
        return html``;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserFxWireLimits;
    }
}
