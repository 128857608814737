/**
 * This is only used in Business bill pay and only ever has 2 events.
 * That being said I'm not sure a timeline is the best component to use here as
 * it adds no real value to the user. Might want to rethink
 * the design here.
 */

import { TmBaseComponent } from '@treasury/presentation';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

interface TimelineEvent {
    date: string;
    eventName: string;
    eventDescription: string;
    eventCompleted: boolean;
}

export const tagName = 'jhd-timeline';
@customElement(tagName)
export class JhdTimeline extends TmBaseComponent {
    @property({ type: Array })
    events!: [TimelineEvent];

    render() {
        if (!this.events) return nothing;
        const events = this.events.map(
            event =>
                html`<div class="timeline-event">
                    <div
                        class=${classMap({
                            'timeline-knob': true,
                            'timeline-knob-incomplete': !event.eventCompleted,
                        })}
                    ></div>
                    <div class="timeline-event-description">
                        <span class="timeline-event-name">${event.eventName}</span>
                        <p>${event.eventDescription}</p>
                    </div>
                    <div class="timeline-event-date"><h5>${event.date}</h5></div>
                </div>`
        );
        return html`<div class="timeline">
            <div class="events">
                ${events}
                <div class="line"></div>
            </div>
        </div>`;
    }

    static get styles() {
        return css`
            :host {
                display: flex;
            }
            .timeline {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 32px;
            }
            .events {
                position: relative;
                display: grid;
                grid-template-columns: 15px 1fr;
                row-gap: 16px;
                padding-top: 16px;
                width: fit-content;
            }

            .timeline-event {
                display: grid;
                grid-template-columns: 16px 1fr;
                column-gap: 16px;
                grid-column: 1 / 3;
            }

            .timeline-event-date > h5,
            .timeline-event-description > p {
                margin: 0;
            }
            .timeline-knob {
                grid-column: 1 / 2;
                align-self: center;
                width: 100%;
                aspect-ratio: 1 / 1;
                z-index: 1;
                background: #4c4c4c;
                border-radius: 50%;
            }
            .timeline-knob-incomplete {
                opacity: 0.5;
            }
            .timeline-event-date,
            .timeline-event-description {
                grid-column: 2 / 3;
                align-self: center;
            }
            .timeline-event-name {
                opacity: 0.7;
                font-size: 14px;
            }
            .line {
                position: absolute;
                grid-column: 1 / 2;
                justify-self: center;
                height: 100%;
                width: 4px;
                z-index: 0;
                background-color: rgba(0, 0, 0, 0.1);
            }
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: JhdTimeline;
    }
}
