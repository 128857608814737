import { Injectable } from '@treasury/utils';
import { getLoanPaymentDetails } from './loan-requests';
import { LoanDto } from './loan.dto';
import { Loan } from './loan.entity';

@Injectable()
export class LoanService {
    public async getLoanPaymentDetails(id: string) {
        const details = (await getLoanPaymentDetails(id)) as LoanDto;
        return new Loan(details);
    }
}
