import { Nullable } from '@treasury/utils';

export interface TransferAccountDto {
    name: string;
    number: string;
    status: number;
    account: string | null;
    currentBalance: number;
    collectedBalance: number | null;
    availableBalance: number;
    type: string;
    perTransactionLimit: number;
    dailyLimit: number;
    cifNumber: number | null;
    isDeleted: boolean;
    showAccountNickName: boolean;
    accountDisplayLabel: string;
    transferAmount: number;
    transferMemo: string | null;
    loanPaymentAmountDue: number;
    loanPaymentDueDate: string | null;
    allowLoanPaymentPastDue: boolean;
    accountUniqueId: string;
    id: number;
    updatedBy: string;
    updatedDate: string;
}

export const nullTransferAccount: Nullable<TransferAccountDto> = {
    name: null,
    number: null,
    status: 0,
    account: null,
    currentBalance: 0,
    collectedBalance: null,
    availableBalance: 0,
    type: null,
    perTransactionLimit: 0,
    dailyLimit: 0,
    cifNumber: 0,
    isDeleted: false,
    showAccountNickName: false,
    accountDisplayLabel: null,
    transferAmount: 0,
    transferMemo: null,
    loanPaymentAmountDue: 0,
    loanPaymentDueDate: null,
    allowLoanPaymentPastDue: false,
    accountUniqueId: null,
    id: null,
    updatedBy: null,
    updatedDate: null,
};
