import '@vaadin/dialog';
import { DialogOpenedChangedEvent } from '@vaadin/dialog';
import { dialogFooterRenderer, dialogRenderer } from '@vaadin/dialog/lit.js';
import { TemplateResult, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { helpFilledIcon } from '../../assets/icons';
import { TmBaseComponent } from '../../tm-base.component';

export const tagName = 'tm-form-row';
@customElement(tagName)
export class TmFormRow extends TmBaseComponent {
    protected readonly verifiedPropNames: (keyof this)[] = ['label'];

    @property()
    label!: string | TemplateResult;

    @property()
    helperText?: string | TemplateResult;

    @property({ type: Boolean, reflect: true })
    required = false;

    @property({ type: Boolean, reflect: true })
    noWrap = false;

    @state()
    hasSummary = false;

    @state()
    helperDialogOpened = false;

    handleSlotChange(e: Event) {
        if (e.target instanceof HTMLSlotElement) {
            this.hasSummary = e.target?.assignedNodes({ flatten: true }).length > 0;
        }
    }

    inputElementChange(e: Event) {
        if (e.target instanceof HTMLSlotElement) {
            e.target.assignedElements().forEach(element => {
                element.classList.add('form-row-slotted', 'ml-auto');
                element.setAttribute('formRowSlotted', '');
                this.required
                    ? element.setAttribute('required', '')
                    : element.removeAttribute('required');
            });
        }
    }

    renderHelperDialog() {
        if (!this.helperText) return nothing;
        return html`<vaadin-dialog
            id="helper-text-dialog"
            .opened=${this.helperDialogOpened}
            @opened-changed=${(event: DialogOpenedChangedEvent) => {
                this.helperDialogOpened = event.detail.value;
            }}
            ${dialogRenderer(
                () =>
                    html`<h2 class="text-lg font-bold">${this.label}</h2>
                        ${this.helperText}`
            )}
            ${dialogFooterRenderer(() => {
                return html`<vaadin-button
                    @click="${() => (this.helperDialogOpened = false)}"
                    style="align-self: flex-end;"
                >
                    Close
                </vaadin-button>`;
            })}
        ></vaadin-dialog>`;
    }

    renderHelperDialogIconBtn() {
        if (!this.helperText) return nothing;
        return html`<button class="ml-1" }>${helpFilledIcon}</button>`;
    }

    render() {
        return html`
            ${this.renderHelperDialog()}
            <div class="flex text-sm items-center mr-4">
                <span
                    class=${classMap({
                        'my-2': true,
                        'mr-2': true,
                        'whitespace-nowrap': this.noWrap,
                    })}
                >
                    <span
                        class="font-medium form-row-label"
                        @click=${() =>
                            !!this.helperText ? (this.helperDialogOpened = true) : nothing}
                        >${this.label}${this.renderHelperDialogIconBtn()}</span
                    ></span
                >
                <span class="text-[--secondary-text-color] font-light">
                    <slot name="sublabel"></slot>
                </span>
                <slot class="flex-grow" @slotchange=${this.inputElementChange}></slot>
            </div>
            <slot name="extra"></slot>
            <div
                class=${classMap({
                    'border-dashed': this.hasSummary,
                    'border-t': this.hasSummary,
                    'border-[--border-color]': this.hasSummary,
                    'mr-4': true,
                })}
            >
                <slot name="summary" class="text-right" @slotchange=${this.handleSlotChange}></slot>
            </div>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    margin-left: 1rem;
                }

                :host([dashed]) {
                    border-bottom-style: dashed !important;
                }

                :not([name])::slotted(*) {
                    width: 100%;
                }

                button svg {
                    height: 14px !important;
                    width: 14px !important;
                }

                :host([required]) .form-row-label::after {
                    content: '.';
                    font-size: 1.5rem;
                    line-height: 1rem;
                    color: var(
                        --lumo-required-field-indicator-color,
                        var(--lumo-primary-text-color)
                    );
                    position: relative;
                    bottom: 6px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmFormRow;
    }
}
