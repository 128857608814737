import { TmBaseComponent } from '@treasury/presentation';
import { chevronDownIcon, chevronUpIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-checkbox';
import '@vaadin/grid';
import {
    columnBodyRenderer,
    GridColumnBodyLitRenderer,
    gridRowDetailsRenderer,
} from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { UserClaimVm } from '../data/user-claim-view-model';
import { ProductAccountVm } from '../data/user-entitlements-view-model';

export const tagName = 'user-entitlements';
@customElement(tagName)
export class UserEntitlements extends TmBaseComponent {
    @property({ type: String })
    public headerLabel = '';

    @property({ type: String })
    public tableLabel = '';

    @property({ type: Array })
    public accounts: ProductAccountVm[] = [];

    @property({ type: Array })
    public permissions?: UserClaimVm[];

    @property({ type: Boolean })
    public open = false;

    @state()
    private selectedAccount: ProductAccountVm[] = [];

    accountClicked(account: ProductAccountVm) {
        this.selectedAccount = account ? [account] : [];
    }

    renderGlobalPermissionsTable() {
        if (!this.permissions) return nothing;
        return html`<h3 class="w-full text-center text-lg font-normal p-3">
                ${this.tableLabel} Product Entitlements
            </h3>
            <vaadin-grid all-rows-visible .items=${this.permissions}>
                <vaadin-grid-column
                    ${columnBodyRenderer(
                        this.renderGlobalPermission as GridColumnBodyLitRenderer<UserClaimVm>
                    )}
                ></vaadin-grid-column
            ></vaadin-grid>`;
    }

    renderGlobalPermission({ name, edited, displayHasClaim }: UserClaimVm) {
        return html`<div class="flex justify-between">
            <span class="text-sm font-normal">${name}</span>
            <span class=${classMap({ 'text-sm': true, edited })}>${displayHasClaim}</span>
        </div>`;
    }

    renderPermissionColumn({ name, edited, hasClaim }: UserClaimVm) {
        return html`<div class="flex justify-between">
            <div class="flex flex-col">
                <div class=${classMap({ 'text-sm font-medium': true, edited })}>${name}</div>
            </div>
            <tm-checkbox .checked=${hasClaim ?? false} disabled></tm-checkbox>
        </div>`;
    }

    renderPermissionsTable(account: ProductAccountVm) {
        return html`<vaadin-grid all-rows-visible .items=${account.userAccountClaims}>
            <vaadin-grid-column
                ${columnBodyRenderer(
                    this.renderPermissionColumn as GridColumnBodyLitRenderer<UserClaimVm>
                )}
            ></vaadin-grid-column>
        </vaadin-grid>`;
    }

    renderAccountColumn(account: ProductAccountVm) {
        const editedBadge = account.userAccountClaims?.some(c => c.edited)
            ? html`<span class="mx-2"><jha-badge>Edited</jha-badge></span>`
            : nothing;
        const chevron =
            account.id === this.selectedAccount[0]?.id ? chevronUpIcon : chevronDownIcon;
        return html`<div class="flex justify-between items-center">
            <div class="flex flex-col">
                <div class="text-sm font-medium">${account.name}</div>
                <div class="text-xs text-gray-500">${account.number}</div>
            </div>
            <div class="flex">
                ${editedBadge}
                <span class="group-toggle">${chevron}</span>
            </div>
        </div>`;
    }

    renderAccountsTable() {
        if (!this.accounts?.length) {
            return html`<div class="italic w-full text-center">No Accounts available.</div>`;
        }
        return html`<h3
                ?hidden=${!this.permissions}
                class="w-full text-center text-lg font-normal p-3"
            >
                ${this.tableLabel} Account Entitlements
            </h3>
            <vaadin-grid
                all-rows-visible
                .detailsOpenedItems=${this.selectedAccount}
                .items=${this.accounts}
                @active-item-changed=${({ detail }: CustomEvent) =>
                    this.accountClicked(detail.value)}
                ${gridRowDetailsRenderer<ProductAccountVm>(this.renderPermissionsTable)}
            >
                <vaadin-grid-column
                    ${columnBodyRenderer(
                        this.renderAccountColumn as GridColumnBodyLitRenderer<ProductAccountVm>
                    )}
                ></vaadin-grid-column>
            </vaadin-grid>`;
    }

    render() {
        return html` <div slot="header-center" class="text-lg font-normal">${this.headerLabel}</div>
            ${this.renderGlobalPermissionsTable()} ${this.renderAccountsTable()}`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserEntitlements;
    }
}
