import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export interface UserOptionViewModel {
    companyName?: string;
    companyUniqueId?: string;
    digitalId?: string;
    userAlias?: string;
    userCompanyUniqueId: string;
}

export const tagName = 'user-selection';
@customElement(tagName)
export class UserSelection extends LitElement {
    @property({ type: Array })
    public userOptions: Array<UserOptionViewModel> = [];

    userSelected(userOption: UserOptionViewModel) {
        this.dispatchEvent(new CustomEvent('selected', { detail: userOption }));
    }

    render() {
        return html`
            <p class="caption">
                Please select which linked TM User ID you would like to authenticate with:
            </p>
            <table class="user-selector">
                <thead>
                    <tr>
                        <th>TM User ID</th>
                        <th>Company Name</th>
                    </tr>
                </thead>
                <tbody>
                    ${this.userOptions.map(
                        uo =>
                            html`<tr @click="${() => this.userSelected(uo)}">
                                <td>${uo.userAlias}</td>
                                <td>${uo.companyName}</td>
                            </tr>`
                    )}
                </tbody>
            </table>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                }
                .caption {
                    padding: 5px 10px;
                    font-weight: 100;
                }
                table.user-selector {
                    border: none;
                    width: 100%;
                    font-size: 14px;
                    border-collapse: collapse;
                    padding: 10px;
                }
                table.user-selector > thead > tr > th {
                    border-top: 1px solid #ccc;
                    border-bottom: 3px solid #ccc;
                    text-align: left;
                    padding: 10px;
                    background-color: #dedede;
                    font-weight: 100;
                }
                table.user-selector > tbody > tr {
                    border-bottom: 1px solid #ccc;
                    cursor: pointer;
                }
                table.user-selector > tbody > tr > td {
                    text-align: left;
                    padding: 10px;
                    font-weight: 300;
                }
                table.user-selector > tbody > tr:hover {
                    background-color: #efefefef;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserSelection;
    }
}
