/* eslint-disable import/no-extraneous-dependencies */
import { NavigationService } from '@treasury/core/navigation';
import BillPayService from '@treasury/domain/bill-pay/bill-pay.service';
import { PayeeListModel } from '@treasury/domain/bill-pay/payee-list.entity';
import { TmBaseComponent } from '@treasury/presentation';
import { chevronForwardIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-table-header';
import { InjectProperty } from '@treasury/utils';
import '@vaadin/grid';
import { columnBodyRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { filterTableResults } from '../../../utilities/table-result-filter';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import { BillPayPayeeTableViewModel } from '../data/bill-pay-payee-table-view-model';
import '../partials/payee-filters-sheet';

export const tagName = 'business-bill-pay-payees-container';
@customElement(tagName)
export class BusinessBillPayPayeesContainer extends TmBaseComponent {
    @InjectProperty()
    private declare billPayService: BillPayService;

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare navigationService: NavigationService;

    @property({ type: Boolean, reflect: true })
    isWorkflow = false;

    @state()
    loading = false;

    @state()
    payees: BillPayPayeeTableViewModel[] = [];

    @state()
    filteredPayees: BillPayPayeeTableViewModel[] = [];

    @state()
    payeeFiltersOpen = false;

    @state()
    fieldsToFilter = ['nickName', 'name', 'paymentMethod'];

    @state()
    private filterData: { onlyIncludePayablePayees: boolean } = {
        onlyIncludePayablePayees: false,
    };

    firstUpdated() {
        this.configureHeader();
        this.fetchPayees();
    }

    async configureHeader() {
        const { params } = (await this.navigationService.getRouteData()) as {
            params: { id: string; workflow: string; type: string };
        };
        this.isWorkflow = params.workflow === 'create';
        if (!this.isWorkflow) {
            this.headerService.configure({
                title: 'Payees',
                backAction: () => this.navigationService.navigate(`/business-bill-pay`),
            });
        }
    }

    async fetchPayees() {
        this.loading = true;
        try {
            const payees = await this.billPayService.getPaymentPayees(
                this.filterData.onlyIncludePayablePayees
            );
            this.payees = payees.map(
                (payee: PayeeListModel) => new BillPayPayeeTableViewModel(payee)
            );
            this.filteredPayees = this.payees;
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    selectPayee(payee: BillPayPayeeTableViewModel) {
        if (!payee) return;
        if (!this.isWorkflow) {
            this.navigationService.navigate(`/business-bill-pay/payees/${payee.id}`);
        }
        this.dispatchEvent(new CustomEvent('payee-selected', { detail: payee, bubbles: true }));
        this.dispatchEvent(new CustomEvent('close', { bubbles: true }));
    }

    filterPayees(filterString: string) {
        this.filteredPayees = filterTableResults(filterString, this.payees, this.fieldsToFilter);
    }

    renderLoader() {
        if (this.loading) {
            return html`<tm-blocking-loader></tm-blocking-loader>`;
        }
        return nothing;
    }

    renderPayeeFiltersSheet() {
        return html`<payee-filters-sheet
            .open=${this.payeeFiltersOpen}
            @close=${() => {
                this.payeeFiltersOpen = false;
            }}
            @apply-filters=${(e: CustomEvent) => {
                this.filterData = e.detail.filterData;
                this.payeeFiltersOpen = false;
                this.fetchPayees();
            }}
        ></payee-filters-sheet>`;
    }

    renderNameColumn(payee: BillPayPayeeTableViewModel) {
        return html`<div class="flex flex-col">
            <span class="text-sm font-normal">${payee.nickName}</span>
            <span class="text-xs">${payee.name}</span>
        </div>`;
    }

    renderPaymentTypeColumn(payee: BillPayPayeeTableViewModel) {
        return html`<div class="flex w-full items-center justify-end">
            <span class="text-sm">${payee.paymentMethod}</span><span>${chevronForwardIcon}</span>
        </div>`;
    }

    renderTable() {
        if (this.loading) return nothing;
        return html`<div id="payee-table-wrapper">
            <tm-table-header
                .filterButton=${true}
                @filterStringChange=${(e: CustomEvent) => {
                    this.filterPayees(e.detail.value);
                }}
                @filterButtonClick=${() => {
                    this.payeeFiltersOpen = true;
                }}
            ></tm-table-header
            ><vaadin-grid
                .items=${this.filteredPayees}
                @active-item-changed=${(e: CustomEvent) => {
                    this.selectPayee(e.detail.value);
                }}
                ><vaadin-grid-column
                    ${columnBodyRenderer(this.renderNameColumn, [])}
                ></vaadin-grid-column
                ><vaadin-grid-column
                    text-align="end"
                    ${columnBodyRenderer(this.renderPaymentTypeColumn, [])}
                ></vaadin-grid-column
            ></vaadin-grid>
        </div>`;
    }

    render() {
        return html` ${this.renderLoader()}${this.renderTable()}${this.renderPayeeFiltersSheet()}`;
    }

    static get styles() {
        return [
            css`
                #payee-table-wrapper {
                    background-color: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: BusinessBillPayPayeesContainer;
    }
}
