import { TmBaseComponent } from '@treasury/presentation';
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

export const tagName = 'ach-filters-container';
@customElement(tagName)
export class AchFiltersContainer extends TmBaseComponent {
    render() {
        return html`<div>ACH Filters</div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchFiltersContainer;
    }
}
