import { AchCompanyModelDto, LookupModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';
import { defaultAchCompanyModelDto } from './data/ach.data';

export class AchCompany extends DomainEntity<AchCompanyModelDto> {
    public createDefault(): this {
        return new AchCompany(defaultAchCompanyModelDto) as this;
    }

    public get companyName() {
        return this.dto.companyName;
    }

    public get companyId() {
        return this.dto.companyId;
    }

    public get batchBalanceRequirements() {
        return this.dto.batchBalanceRequirements;
    }

    public get prefundingDays() {
        return this.dto.prefundingDays;
    }

    public get id() {
        return this.dto.id;
    }

    public get offsetAccountNumber() {
        return this.dto.offsetAccountNumber as string | undefined;
    }
}
