import JhaFormTextInput from '@banno/jha-wc/src/forms/jha-form-text-input/JhaFormTextInput';
import '@banno/jha-wc/src/icons/jha-icon-date';
import { jhaStyles } from '@banno/jha-wc/src/styles/jha-styles';
import { HolidayCalendarModelDto } from '@treasury/api/channel';
import { FiDate, HolidaysService } from '@treasury/domain/dates';
import { dateIcon } from '@treasury/presentation/assets/icons';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { add, sub } from 'date-fns';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../jhd-icon';
import { inactiveHolidays, inactiveMinMaxDates, inactiveWeekends } from './active-date.functions';
import { ActiveDateFilterFunction, DateFormat } from './date-picker.types';
import { DateToTextFormatter } from './date-to-text.formatter';
import './jhd-date-picker-calendar';
import { TextInputDateFormatter } from './text-input-date.formatter';

export const tagName = 'jhd-date-picker';
@customElement(tagName)
export class JhdDatePicker extends JhaFormTextInput {
    @InjectProperty()
    private declare holidaysService: HolidaysService;

    private _allowRangeSelection = false;

    private _selectedDate: Date | null = null;

    private _secondaryDate: Date | null = null;

    private _currentDate: Date = new FiDate().date;

    private _minDate: Date = sub(this._currentDate, { years: 1 });

    private _maxDate: Date = add(this._currentDate, { years: 1 });

    @property({ type: Boolean, reflect: true })
    readonly = false;

    @property({ type: Boolean, reflect: true })
    required = false;

    @property({ type: Boolean, reflect: true })
    disabled = false;

    @property({ type: Number })
    inputDateFormat: DateFormat = DateFormat.American;

    @property({ type: Number })
    outputDateFormat: DateFormat = DateFormat.ISO;

    @property({ type: Object })
    activeDateFilter: ActiveDateFilterFunction = (date: Date) => true;

    compositeActiveDateFilter!: ActiveDateFilterFunction;

    @property({ type: Boolean, reflect: true })
    showToday = false;

    @property({ type: Boolean, reflect: true })
    disableHolidays = false;

    @property({ type: Boolean, reflect: true })
    disableWeekends = false;

    @property({ type: Boolean })
    infiniteScroll = true;

    @property({ type: Boolean, reflect: true })
    calendarOpen = false;

    @state()
    textInputValue = '';

    @state()
    validStartDate = true;

    @state()
    validEndDate = true;

    @state()
    incompleteValues = false;

    @state()
    errorMessage = '';

    @state()
    holidays: HolidayCalendarModelDto[] = [];

    @state()
    maxTextLength = this.allowRangeSelection ? 23 : 10;

    allowedCharacters = '[0-9]+';

    dialogClickoutBound = this.dialogClickout.bind(this);

    @property({ type: Boolean })
    get allowRangeSelection() {
        return this._allowRangeSelection;
    }

    set allowRangeSelection(value: boolean) {
        const oldValue = this._allowRangeSelection;
        this._allowRangeSelection = value;
        if (oldValue !== value) {
            this.setTextInputFromCalendarDates();
            this.requestUpdate();
        }
    }

    @property({ type: Object })
    get selectedDate() {
        return this._selectedDate;
    }

    set selectedDate(value: Date | null) {
        const oldValue = this._selectedDate;
        this._selectedDate = value ? new FiDate(value).date : null;
        if (!this.equivalentDateCheck(oldValue, this._selectedDate)) {
            this.setTextInputFromCalendarDates();
            this.requestUpdate();
            this.dispatchSelectionEvent();
        }
    }

    @property({ type: Object })
    get secondaryDate() {
        return this._secondaryDate;
    }

    set secondaryDate(value: Date | null) {
        const oldValue = this._secondaryDate;
        this._secondaryDate = value ? new FiDate(value).date : null;
        const validSecondaryDateUpdate =
            !this.equivalentDateCheck(oldValue, this._secondaryDate) &&
            (!this.allowRangeSelection ||
                (this.allowRangeSelection && this._secondaryDate !== null));
        if (validSecondaryDateUpdate || !this._secondaryDate) {
            this.setTextInputFromCalendarDates();
            this.requestUpdate();
            this.dispatchSelectionEvent();
        }
    }

    @property({ type: Object })
    get currentDate() {
        return this._currentDate;
    }

    set currentDate(value: Date) {
        this._currentDate = new FiDate(value).date;
        this.requestUpdate();
    }

    @property({ type: Object })
    get minDate() {
        return this._minDate;
    }

    set minDate(value: Date) {
        this._minDate = new FiDate(value).date;
        this.requestUpdate();
    }

    @property({ type: Object })
    get maxDate() {
        return this._maxDate;
    }

    set maxDate(value: Date) {
        this._maxDate = new FiDate(value).date;
        this.requestUpdate();
    }

    async firstUpdated() {
        this.maxTextLength = this.allowRangeSelection ? 23 : 10;
        if (this.disableHolidays) {
            this.holidays = await this.holidaysService.getHolidays();
        }
        this.combineActiveDateFilters();
        this.clearError();

        if (this.selectedDate) {
            if (this.secondaryDate) {
                this.allowRangeSelection = true;
            }
            this.setTextInputFromCalendarDates();
        }
        if (this.minDate.getTime() > this.maxDate.getTime()) {
            console.error('jhd-date-picker max date must come after min date');
        }
    }

    equivalentDateCheck(date1: Date | null, date2: Date | null) {
        if (date1 === date2) return true;
        return !!(date1 && date2 && date1.getTime() === date2.getTime());
    }

    combineActiveDateFilters() {
        this.compositeActiveDateFilter = (date: Date, minDate: Date, maxDate: Date) => {
            date.setHours(12, 0, 0, 0);
            let activeDate =
                inactiveMinMaxDates(date, minDate, maxDate) &&
                this.activeDateFilter(date, minDate, maxDate);

            if (activeDate && this.disableWeekends) {
                activeDate = inactiveWeekends(date);
            }

            if (activeDate && this.disableHolidays) {
                activeDate = inactiveHolidays(date, this.holidays);
            }

            return activeDate;
        };
    }

    toggleCalendarOverlay() {
        if (this.calendarOpen) {
            this.closeCalendarOverlay();
            return;
        }
        this.openCalendarOverlay();
    }

    calculateCalendarScroll() {
        const calendarOverlay = this.shadowRoot?.querySelector('jhd-date-picker-calendar');
        const calendarDiv = calendarOverlay?.shadowRoot?.querySelector('#calendar');
        const monthHeader = calendarDiv?.querySelector('.month-header') as HTMLElement;

        let targetMonth: any = null;

        if (this.allowRangeSelection && this.secondaryDate !== null) {
            targetMonth = calendarDiv
                ?.querySelector('button[secondary]')
                ?.closest('.month-container') as HTMLElement;
        } else if (this.selectedDate !== null) {
            targetMonth = calendarDiv
                ?.querySelector('button[selected]')
                ?.closest('.month-container') as HTMLElement;
        } else if (this.showToday) {
            targetMonth = calendarDiv
                ?.querySelector('button[istoday]')
                ?.closest('.month-container') as HTMLElement;
        } else {
            const todayString = new Date().toDateString();
            targetMonth = calendarDiv
                ?.querySelector(`button[data-day="${todayString}"]`)
                ?.closest('.month-container') as HTMLElement;
        }

        if (targetMonth && calendarDiv) {
            const ro = new ResizeObserver(i => {
                calendarDiv.scrollTo(0, targetMonth.offsetTop - monthHeader.clientHeight);
            });
            ro.observe(calendarDiv);
        }
    }

    async openCalendarOverlay() {
        this.calendarOpen = true;
        await this.updateComplete;
        this.calculateCalendarScroll();
        window.addEventListener('click', this.dialogClickoutBound);
    }

    closeCalendarOverlay() {
        window.removeEventListener('click', this.dialogClickoutBound);
        this.calendarOpen = false;
    }

    dialogClickout(e: Event) {
        const datePickerContent = this.shadowRoot?.querySelector(
            '#calendar-overlay-content'
        ) as EventTarget;
        const dateIconBtn = this.shadowRoot?.querySelector('#date-icon-btn') as EventTarget;
        if (
            !e.composedPath().includes(datePickerContent) &&
            !e.composedPath().includes(dateIconBtn)
        ) {
            this.closeCalendarOverlay();
        }
    }

    inputPlaceholder() {
        if (this.inputDateFormat === DateFormat.ISO) {
            if (this.allowRangeSelection) {
                return 'YYYY-MM-DD - YYYY-MM-DD';
            }
            return 'YYYY-MM-DD';
        }

        if (this.inputDateFormat === DateFormat.American && this.allowRangeSelection) {
            return 'mm/dd/yyyy - mm/dd/yyyy';
        }
        return 'mm/dd/yyyy';
    }

    showError() {
        if (this.showWarning_()) {
            this.errorMessage = 'Invalid date';
            return true;
        }
        if (this.hasValidEntry()) return false;

        if (!this.validStartDate) {
            this.errorMessage = 'Invalid date';
        }

        if (this.allowRangeSelection) {
            if (!this.validStartDate) this.errorMessage = 'Invalid start date';

            if (!this.validEndDate) this.errorMessage = 'Invalid end date';
            if (!this.validStartDate && !this.validEndDate)
                this.errorMessage = 'Invalid start and end dates';
        }

        return !this.validStartDate || !this.validEndDate;
    }

    clearError() {
        this.validStartDate = true;
        this.validEndDate = true;
        this.incompleteValues = false;
        this.errorMessage = '';
    }

    onFocus() {
        this.clearError();
    }

    onBlur() {
        super.onBlur_();
        this.clearError();
        if (this.hasValidEntry()) return true;
        if (this.value.length) {
            if (!this.selectedDate) {
                this.validStartDate = false;
            }
            if (!this.secondaryDate) {
                this.validEndDate = false;
            }
        }

        return true;
    }

    validateInputDate(inputDate: string) {
        const date = this.textInputToDate(inputDate);
        if (Number.isNaN(date.getTime())) {
            return false;
        }
        if (this.compositeActiveDateFilter) {
            return this.compositeActiveDateFilter(date, this.minDate, this.maxDate);
        }
        return true;
    }

    textInputToDate(inputDate: string) {
        return new FiDate(inputDate).date;
    }

    onInput(e: Event) {
        super.onInput_(e);
        this.textInputValue = new TextInputDateFormatter(
            this.value,
            this.inputDateFormat,
            this.allowRangeSelection
        ).toString();
        const inputDates = this.textInputValue.split(' - ');

        this.clearError();
        if (inputDates[0].length === 10) {
            if (this.validateInputDate(inputDates[0])) {
                this.selectedDate = this.textInputToDate(inputDates[0]);
            } else {
                this.validStartDate = false;
            }
        } else {
            this.selectedDate = null;
        }

        if (inputDates.length === 2 && inputDates[1].length === 10) {
            if (this.validateInputDate(inputDates[1])) {
                this.secondaryDate = this.textInputToDate(inputDates[1]);
            } else {
                this.validEndDate = false;
            }
        } else {
            this.secondaryDate = null;
        }
        this.requestUpdate();
    }

    setTextInputFromCalendarDates() {
        if (!this.selectedDate && !this.secondaryDate) {
            this.textInputValue = '';
        }
        const selectedDateText = this.selectedDate
            ? new DateToTextFormatter(this.selectedDate, this.inputDateFormat).toString()
            : '';
        const singleSelected = !this.allowRangeSelection && this.selectedDate;
        const rangeSelected = this.allowRangeSelection && this.secondaryDate;
        if (rangeSelected) {
            const secondaryDateText = this.secondaryDate
                ? new DateToTextFormatter(this.secondaryDate as Date, this.inputDateFormat)
                : '';
            this.textInputValue = `${selectedDateText} - ${secondaryDateText}`;
        }
        if (singleSelected) {
            this.textInputValue = selectedDateText;
        }
    }

    onSelectedDates(e: CustomEvent) {
        this.selectedDate = e.detail.selectedDate;
        this.secondaryDate = e.detail.secondaryDate;

        this.setTextInputFromCalendarDates();

        if (this.hasValidEntry()) {
            setTimeout(() => {
                this.calendarOpen = false;
                const dateInput = this.querySelector(`#dateInput`) as HTMLInputElement;
                if (dateInput) dateInput.focus();
            }, 200);
        }
        this.requestUpdate();
    }

    hasValidEntry() {
        const singleSelected = !this.allowRangeSelection && this.selectedDate;
        const rangeSelected = this.allowRangeSelection && this.secondaryDate;
        return singleSelected || rangeSelected;
    }

    formatOutputDate(date: Date | null) {
        if (!date) return date;
        return new DateToTextFormatter(date, this.outputDateFormat).toString();
    }

    dispatchSelectionEvent() {
        this.dispatchEvent(
            new CustomEvent('selection', {
                detail: {
                    value: {
                        startDate: this.formatOutputDate(this.selectedDate),
                        endDate: this.formatOutputDate(this.secondaryDate),
                    },
                },
            })
        );
    }

    renderCalendarOverlay() {
        if (!this.calendarOpen) return nothing;
        return html`<div
            id="calendar-overlay-container"
            @blur=${() => {
                this.calendarOpen = false;
            }}
        >
            <div id="calendar-overlay-content">
                <jhd-date-picker-calendar
                    ?.disabled=${this.readonly}
                    .selectedDate=${this.selectedDate}
                    .secondaryDate=${this.secondaryDate}
                    .currentDate=${this.currentDate}
                    .minDate=${this.minDate}
                    .maxDate=${this.maxDate}
                    .showToday=${this.showToday}
                    .allowRangeSelection=${this.allowRangeSelection}
                    .infiniteScroll=${this.infiniteScroll}
                    .activeDateFilter=${this.compositeActiveDateFilter}
                    @selected-dates=${(e: CustomEvent) => {
                        this.onSelectedDates(e);
                    }}
                ></jhd-date-picker-calendar>
            </div>
        </div>`;
    }

    renderCalendarIconBtn() {
        if (this.readonly) return nothing;
        const dateIconColor = this.disabled ? '#ccc' : '#786E5C';
        return html` <button
            id="date-icon-btn"
            @click=${() => {
                if (!this.disabled) this.toggleCalendarOverlay();
            }}
        >
            <jhd-icon .icon=${dateIcon} .color=${dateIconColor}></jhd-icon>
        </button>`;
    }

    render() {
        return html`
            <div class="jhd-date-picker">
                <jha-form-floating-group
                    ?outline=${this.outline}
                    ?small=${this.small}
                    ?filled=${this.filled}
                    ?no-label=${this.noLabel}
                    ?always-float=${true}
                    ?has-value=${Boolean(this.value)}
                    .error=${this.showError() ? this.errorMessage : null}
                    .assistiveText=${this.assistiveText}
                >
                    <input
                        id="dateInput"
                        name="dateInput"
                        ?required=${this.required}
                        ?readonly=${this.readonly}
                        ?disabled=${this.disabled}
                        type="text"
                        .value=${this.textInputValue}
                        value=${this.textInputValue}
                        minlength=${this.minlength}
                        maxlength=${this.maxTextLength}
                        aria-label=${this.label}
                        .title=${this.caption}
                        @input=${this.onInput}
                        @blur=${this.onBlur}
                        @focus=${this.onFocus}
                        @keydown=${this.onKeydown_}
                        @keypress=${this.onKeypress_}
                        autocomplete=${this.autocomplete}
                        placeholder=${this.inputPlaceholder()}
                    />
                    ${this.renderCalendarIconBtn()}
                    <label for="dateInput" class="label"
                        >${this.required ? `${this.label} *` : this.label}</label
                    >
                </jha-form-floating-group>
                ${this.renderCalendarOverlay()}
            </div>
        `;
    }

    static get styles() {
        return [
            jhaStyles,
            css`
                :host([outline]) {
                    --jha-input-padding: 17px 12px;
                }
                :host([outline][small]) {
                    --jha-input-padding: 9px 10px;
                }

                input[disabled] {
                    color: var(
                        --jha-form-floating-group-input-disabled-text-color,
                        var(--jha-text-light)
                    );
                    opacity: var(--jhd-disabled-input-opacity, 0.7);
                }

                .jhd-date-picker {
                    position: relative;
                    width: 100%;
                    margin: 0;
                }

                jha-form-floating-group {
                    --jha-input-margin-bottom: var(--jha-text-input-margin-bottom, 4px);
                }

                #date-icon-btn {
                    position: absolute;
                    top: 12px;
                    height: 50px;
                    width: 50px;
                    border: none;
                    right: 0;
                    fill: var(--body-text-secondary-color, var(--jha-text-light));
                    background: none;
                    cursor: pointer;
                }

                #calendar-overlay-container {
                    display: flex;
                    min-height: 360px;
                    width: 100%;
                    z-index: 5;
                    min-width: 340px;
                }

                #calendar-overlay-content {
                    width: 340px;
                    padding: 16px 16px 20px;
                    background-color: var(--jha-component-background-color, #fff);
                    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 10%);
                    margin-left: auto;
                    margin-top: 8px;
                    position: relative;
                }

                jhd-date-picker-calendar {
                    min-height: 360px;
                }

                @media (max-width: 420px) {
                    jhd-date-picker-calendar {
                        --jha-date-picker-data-day-min-height: 46px;
                        --jha-date-picker-data-day-min-width: 46px;
                        min-height: 400px;
                    }
                    #calendar-overlay-content {
                        width: 100%;
                    }
                }

                @media (max-width: 380px) {
                    jhd-date-picker-calendar {
                        --jha-date-picker-data-day-min-height: 40px;
                        --jha-date-picker-data-day-min-width: 40px;
                    }
                }

                @media (max-width: 340px) {
                    jhd-date-picker-calendar {
                        --jha-date-picker-data-day-min-height: 34px;
                        --jha-date-picker-data-day-min-width: 34px;
                    }
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: JhdDatePicker;
    }
}
