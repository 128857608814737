import { MessagesService } from '@treasury/domain/channel/services';
import { TmContainer } from '@treasury/presentation';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-select';
import { SelectItem } from '@treasury/presentation/components/tm-select';
import '@treasury/presentation/components/tm-text-area';
import '@treasury/presentation/components/tm-upload';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { DeepReactive } from '@treasury/utils/lit-helpers';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { Message } from '../types/message.types';

export type MessageSubjectItem = {
    categoryDescription: string;
    fiMessageCategoryId: number;
    messageCategory: string;
};

const MessageSubjectItems = (items: MessageSubjectItem[]) =>
    items.map(item => ({
        value: item.fiMessageCategoryId.toString(),
        label: item.categoryDescription,
    }));

const defaultFormData = {
    userId: 0,
    categoryId: 0,
    parentMessageId: 0,
    assignedToUserId: 0,
    files: undefined,
    body: '',
};

export const tagName = 'compose-message-form';
@customElement(tagName)
export class ComposeMessageForm extends TmContainer {
    @property({ type: Array })
    public message = [] as Message[];

    @property({ type: Boolean })
    public isReplying = false;

    @InjectProperty()
    private declare readonly messageService: MessagesService;

    @state()
    private messageSubjectItems: SelectItem[] = [];

    @DeepReactive()
    private formData = { ...defaultFormData };

    @DeepReactive()
    private validState = {
        subject: true,
        message: true,
    };

    private messageCharLimit = 1000;

    async firstUpdated() {
        if (!this.isReplying) {
            await this.tryFetch(
                () => this.messageService.getMessageCatagories(),
                messageSubjects => {
                    this.messageSubjectItems = MessageSubjectItems(
                        messageSubjects as MessageSubjectItem[]
                    );
                }
            );
        } else {
            this.loading = false;
        }
    }

    updated(changedProps: Map<string, unknown>) {
        if (changedProps.has('message')) {
            if (!this.message.length) return;
            this.formData = {
                ...this.formData,
                userId: this.message[0].assignedToUserId ?? 0,
                categoryId: this.message[0].categoryId,
                parentMessageId:
                    Number(this.message[0].parentMessageId) ??
                    Number(this.message[0].messageId) ??
                    0,
            };
        }
    }

    sendMessage() {
        this.dispatchEvent(new CustomEvent('send-message', { detail: this.formData }));
        this.formData = { ...defaultFormData };
    }

    cancelMessage() {
        this.dispatchEvent(new CustomEvent('cancel-message'));
        this.formData = { ...defaultFormData };
    }

    renderSubjectField() {
        if (this.isReplying) return nothing;

        return html` <tm-select
            label="Subject"
            required
            .value=${this.formData.categoryId !== 0
                ? this.formData.categoryId.toString()
                : undefined}
            .items=${this.messageSubjectItems}
            @value-changed=${(e: CustomEvent) => {
                this.formData.categoryId = Number(e.detail.value);
                this.validState.subject = !!this.formData.categoryId;
            }}
        ></tm-select>`;
    }

    render() {
        return html`
            ${this.renderLoader()}
            <tm-body>
                <form>
                    ${this.renderSubjectField()}
                    <tm-upload
                        accept=".csv,.doc,.docx,.gif,.jfif,.jif,.jpg,.jpeg,.jpe,.pdf,.png,.txt,.xls,.xlsx"
                        @files-changed=${(e: CustomEvent) => {
                            this.formData.files = e.detail.value;
                        }}
                    ></tm-upload>
                    <tm-text-area
                        label="Message"
                        placeholder="Enter Message"
                        required
                        .value=${this.formData.body}
                        .maxLength=${this.messageCharLimit}
                        @value-changed=${(e: CustomEvent) => {
                            this.formData.body = e.detail.value;
                            this.validState.message = !!this.formData.body.length;
                        }}
                        .helperText="${`${this.formData.body.length}/${this.messageCharLimit}`}"
                    ></tm-text-area>
                </form>
            </tm-body>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: 'Send',
                        onClick: () => this.sendMessage(),
                        disabled: Object.values(this.validState).some(isValid => !isValid),
                    },
                    {
                        text: 'Cancel',
                        onClick: () => this.cancelMessage(),
                        importance: 'secondary',
                    },
                ] as ButtonConfig[]}
            >
            </tm-footer>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                tm-text-area {
                    min-height: 200px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: ComposeMessageForm;
    }
}
