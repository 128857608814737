import { css, html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { TmBaseComponent } from '../tm-base.component';

export const tagName = 'tm-action-tile';
@customElement(tagName)
export class TmActionTile extends TmBaseComponent {
    @property({ type: String })
    public tileTitle = '';

    @property({ type: Object })
    public icon: TemplateResult = html``;

    @property({ type: Function })
    public action: () => void = () => null;

    @property({ type: Boolean })
    public disabled = false;

    render() {
        return html`
            <button @click=${() => this.action()} ?disabled=${this.disabled}>
                <div class="flex flex-col items-center justify-center">
                    <div>${this.icon}</div>
                    <div id="tile-title" class="mt-2">${this.tileTitle}</div>
                </div>
            </button>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    background: var(--action-gradient, --color-primary);
                    border-radius: 10px;
                    border: 1px solid rgba(255, 255, 255, 0.1);
                }
                svg {
                    width: 37px;
                    height: 37px;
                }
                button {
                    width: 80px;
                    height: 80px;
                    box-sizing: border-box;
                    left: 0%;
                    right: 0%;
                    top: 0%;
                    bottom: 0%;
                    background: var(--action-gradient, --color-primary));
                    border: 1px solid rgba(255, 255, 255, 0.1);
                    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
                    border-radius: 10px;
                    color: inherit;
                    padding: 0;
                    font: inherit;
                    cursor: pointer;
                    outline: inherit;
                    color: var(--white);
                }
                button path {
                    fill: var(--icon-fill-color-light);
                }
                button #tile-title {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                }
                button[disabled] {
                    opacity: 0.4;
                    pointer-events: none;
                    cursor: not-allowed;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmActionTile;
    }
}
