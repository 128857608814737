/* eslint-disable no-useless-constructor */
import { AbstractConstructor, Constructor, CtorsOf } from '../types';
import { DiContainer } from './di-container';

interface InjectionTokenConfig<T> {
    /**
     * Provide a static value for the dependency provided by the `InjectionToken`.
     */
    value?: T;
    /**
     * Provide a computed value for the dependency provided by the `InjectionToken`.
     */
    factory?: () => T;
}

/**
 * A non-instantiable token that can be used with a DI Container to
 * register/retrieve already-created instances or constant dependencies in a type-safe way.
 */
export class InjectionToken<T> {
    constructor(
        public readonly name: string,
        private readonly config: InjectionTokenConfig<T> = {}
    ) {}

    public toString() {
        return this.name;
    }

    public getValue() {
        const { value: defaultValue, factory } = this.config;

        return defaultValue ?? factory?.() ?? undefined;
    }
}

/**
 * Non-instantiable token used to retrieve a dependency from the DI container.
 */
export type SymbolToken<T> = InjectionToken<T> | string;

export type DiToken<T> = Constructor<T> | AbstractConstructor<T> | SymbolToken<T>;

export type DependencyFactory<T, Deps extends Array<any>> = (...dependencies: Deps) => T;

export interface DependencyDefinition<T> {
    /**
     * The token used to fulfill a dependency.
     *
     * This may or may not be the same as the key used to retrieve it.
     * There are scenarios where a token used for lookup may be associated with some other
     * token (an injection token, a sub-class constructor, etc.) for instantiation.
     *
     * When in doubt, this value should be the considered the source of truth
     * for creating an instance of any dependency.
     */
    token: DiToken<T>;
    instance?: T;
    /**
     * Flag used to indicate if a token is known internally
     *  to the registry but is otherwise non-instantiable
     * to external consumers (e.g., it is a subclass fulfilling the implementation of a parent class).
     */
    instantiable: boolean;
}

export interface FactoryDefinition<T> {
    factory: DependencyFactory<T, any>;
    dependencies: DiToken<any>[];
}

interface DependencyProvider<T, Deps extends Array<any>> {
    provide: Constructor<T> | SymbolToken<T>;
    dependencies: CtorsOf<Deps> | Readonly<CtorsOf<Deps>>;
    factory: DependencyFactory<T, Deps>;
}

export interface InjectConfig<T = unknown, Deps extends Array<any> = unknown[]> {
    /**
     * The DI container to register the dependency with.
     * Useful for test scenarios or segregating dependencies into packages.
     */
    container: DiContainer;
    /**
     * Provide this dependency explicitly using a factory function.
     * Useful for when a class constructor has mixed injectable and
     * non-injectable parameters or has a run-time requirement.
     */
    provider: DependencyProvider<T, Deps>;
}

export interface InjectPropertyConfig<T> extends InjectConfig {
    token: SymbolToken<T>;
}

/**
 * Dependency definition for a parameter of an injectable constructor
 * that is annotated with a non-constructable injection token.
 */
export interface TokenDependency {
    /**
     * Ordinal position in the constructor argument list.
     */
    index: number;
    token: SymbolToken<unknown>;
}
