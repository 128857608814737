import { NavigationService } from '@treasury/core/navigation';
import '@treasury/domain-components/layout/tm-list-layout';
import { AchCompany, AchService, AchTemplate } from '@treasury/domain/ach';
import { NotificationService, TmContainer } from '@treasury/presentation';
import { helpIcon } from '@treasury/presentation/assets/icons';
import { InjectProperty } from '@treasury/utils';
import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import '../partials/ach-templates-filter-form';

export const tagName = 'ach-templates-container';
@customElement(tagName)
export class AchTemplatesContainer extends TmContainer {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare service: AchService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @state()
    private templates: AchTemplate[] = [];

    @state()
    private achCompanies?: AchCompany[];

    @state()
    private secCodes?: string[];

    @state()
    action?: string;

    @state()
    params?: { institution: string; action: string };

    async configureHeader() {
        const { params } = await this.navService.getRouteData<{
            institution: string;
            action: string;
        }>();
        this.action = params.action;
        this.headerService.configure({
            title: this.params?.action === 'initiate' ? 'Select Template' : 'ACH Templates',
            menuItems: [
                {
                    title: 'Info',
                    icon: helpIcon,
                    action: () => alert('information on ACH Templates'),
                },
            ],
            backAction: () => this.navService.navigate(`/ach`),
        });
    }

    async firstUpdated() {
        const { params } = await this.navService.getRouteData<{
            institution: string;
            action: string;
        }>();
        this.params = params;
        this.configureHeader();
        await this.tryFetch(
            () => Promise.all([this.service.getAchCompanies(), this.service.getSecCodes()]),
            resp => ([this.achCompanies, this.secCodes] = resp)
        );
    }

    // TODO: add conditionally rendered columns for larger screens
    renderNameAndStatusColumn(achTemplate: AchTemplate) {
        return html`<div class="flex flex-col">
            <span class="font-medium text-sm overflow-hidden text-ellipsis"
                >${achTemplate.name}</span
            >
            <span class="text-xs">${achTemplate.companyName}</span>
        </div>`;
    }

    renderAmountAndAccountColumn(achTemplate: AchTemplate) {
        const amounts = [
            [achTemplate.creditAmountFormatted, 'CR'],
            [achTemplate.debitAmountFormatted, 'DR'],
        ].filter(([value]) => !!value);

        const toHtml = ([value, label]: string[]) =>
            html`<div class="font-medium text-sm ">
                ${value} <span class="text-xs font-normal">${label}</span>
            </div>`;

        return html`<div class="flex flex-col">
            <div class="text-right">${amounts.map(toHtml)}</div>
            <small class="template-transaction-id text-right">${achTemplate.transactionId}</small>
        </div>`;
    }

    render() {
        return html`${this.renderLoader()}<tm-list-layout
                .columns=${[this.renderNameAndStatusColumn, this.renderAmountAndAccountColumn]}
                .items=${this.templates}
                .searchFields=${['name', 'companyName', 'creditAmount', 'debitAmount', 'status']}
                @select-list-item=${(e: CustomEvent<AchTemplate>) =>
                    this.action === 'initiate' &&
                    this.navService.navigate(`/ach/templates/initiate/${e.detail.templateId}`)}
                @apply-filter=${(e: CustomEvent) => {
                    this.tryFetch(
                        () => this.service.getTemplates(e.detail),
                        t => {
                            this.templates =
                                this.action === 'initiate'
                                    ? t.filter(template => template.status !== 'Pending Approval')
                                    : t;
                        },
                        (err: any) => {
                            this.notificationService.renderError(err);
                        }
                    );
                }}
            >
                <ach-templates-filter-form
                    .achCompanies=${this.achCompanies}
                    .secCodes=${this.secCodes}
                >
                </ach-templates-filter-form>
            </tm-list-layout> `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchTemplatesContainer;
    }
}
