import { Task } from '@lit/task';
import { NavigationService } from '@treasury/core/navigation';
import { UserProfileService } from '@treasury/domain/channel/services';
import { TmApiError } from '@treasury/domain/shared';
import { NotificationService, TmContainer } from '@treasury/presentation';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-email-field';
import '@treasury/presentation/components/tm-phone-field';
import '@treasury/presentation/components/tm-text-field';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { DeepReactive } from '@treasury/utils/lit-helpers';
import { PropertyValueMap, css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import '../partials/dark-mode-toggle';
import '../partials/user-change-password-form';
import { UserAccountVm } from '../view-models';

export const tagName = 'user-profile-container';
@customElement(tagName)
export default class UserProfileContainer extends TmContainer {
    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @InjectProperty()
    private declare service: UserProfileService;

    @DeepReactive()
    public formData = new UserAccountVm();

    private startingFormData = this.formData.clone();

    @state()
    private isDirty = false;

    @state()
    public showChangePasswordForm = false;

    @state()
    public showUserOptions = false;

    private getProfileTask = new Task(this, {
        task: async () => {
            const bo = await this.service.getCurrentUserProfile();
            this.formData = new UserAccountVm(bo);
            this.startingFormData = this.formData.clone();
        },
    });

    private updateProfileTask = new Task(this, {
        task: async () => {
            const response = await this.service.updateCurrentUserProfile(this.formData);

            if (response.status === 'Success') {
                this.notificationService.renderSuccess('Profile Changes Saved!');
                this.startingFormData = this.formData.clone();
                this.isDirty = false;
            } else {
                throw response;
            }
        },
    });

    public async firstUpdated() {
        this.headerService.configure({ title: 'My Profile' });
        this.queueTasks();
        this.getProfileTask.run();
    }

    public updated(changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        if (changedProperties.has('formData')) {
            this.checkDirty();
        }
    }

    public async saveProfileUpdates() {
        await this.updateProfileTask.run();
        window.scrollTo(0, 0);
    }

    private checkDirty() {
        this.isDirty = !this.formData.compare(this.startingFormData);
    }

    private queueTasks() {
        this.enqueueWork(this.getProfileTask, {
            error: err => {
                if (err instanceof TmApiError) {
                    this.notificationService.renderError(err);
                } else {
                    this.notificationService.renderError('Failed to get user profile.');
                }

                return nothing;
            },
        });
        this.enqueueWork(this.updateProfileTask, {
            error: err => {
                if (err instanceof TmApiError) {
                    this.notificationService.renderError(err);
                } else {
                    this.notificationService.renderError('User profile changes save failed');
                }

                return nothing;
            },
        });
    }

    public goToDashboard() {
        return this.navService.navigate(`/dashboard`);
    }

    renderUserOptions() {
        if (!this.showUserOptions) return nothing;
        return html`
            <tm-text-field
                label="Extension"
                maxLength="10"
                .value=${this.formData.extension}
                @value-changed=${(e: CustomEvent) => {
                    this.formData.extension = e.detail.value;
                }}
            ></tm-text-field>
            <tm-phone-field
                label="Mobile"
                .value=${this.formData.mobile}
                @value-changed=${(e: CustomEvent) => {
                    this.formData.mobile = e.detail.value;
                }}
            ></tm-phone-field>
            <tm-phone-field
                label="Fax"
                .value=${this.formData.fax}
                @value-changed=${(e: CustomEvent) => {
                    this.formData.fax = e.detail.value;
                }}
            ></tm-phone-field>
        `;
    }

    renderUserInformation() {
        if (this.loading) return nothing;
        const userOptionsLabel = this.showUserOptions ? 'Hide' : 'Show';
        return html`
            <tm-text-field disabled label="Name" .value=${this.formData.name}></tm-text-field>
            <tm-text-field
                disabled
                label="Login ID"
                .value=${this.formData.loginId}
            ></tm-text-field>
            <tm-email-field
                required
                label="Email"
                .value=${this.formData.email}
                @value-changed=${(e: CustomEvent) => {
                    this.formData.email = e.detail.value;
                }}
            ></tm-email-field>
            <tm-phone-field
                required
                label="Phone"
                .value=${this.formData.phone}
                @value-changed=${(e: CustomEvent) => {
                    if (!!e.detail) {
                        this.formData.phone = e.detail.value;
                    }
                }}
            ></tm-phone-field>
            <div class="options-container">
                <button
                    class=" user-options-switch text-sm"
                    @click=${() => {
                        this.showUserOptions = !this.showUserOptions;
                    }}
                >
                    ${userOptionsLabel} options
                </button>
                <div class="options-content">${this.renderUserOptions()}</div>
            </div>
            <div class="pt-6">
                <tm-button
                    importance="primary"
                    .disabled=${!this.isDirty}
                    @click="${() => this.saveProfileUpdates()}"
                >
                    Save User Information
                </tm-button>
            </div>
        `;
    }

    renderSecurityPreferences() {
        if (this.loading) return nothing;
        return html`
            <div class="switch-container py-3 border-b">
                <jha-form-switch
                    outline
                    class="block"
                    label="Account Nicknames"
                    left-label="Off"
                    right-label="On"
                    .checked=${this.formData.accountNicknames}
                    @change=${(e: CustomEvent) => {
                        this.formData.accountNicknames = e.detail.checked;
                        this.saveProfileUpdates();
                    }}
                >
                    <span slot="left-label">Show Account Nicknames</span>
                </jha-form-switch>
            </div>
            <div class="switch-container py-3 border-b">
                <jha-form-switch
                    outline
                    class="block"
                    left-label="Off"
                    right-label="On"
                    .checked=${this.formData.accountMasking}
                    @change=${(e: CustomEvent) => {
                        this.formData.accountMasking = e.detail.checked;
                        this.saveProfileUpdates();
                    }}
                    ><span slot="left-label">Account Number Masking</span></jha-form-switch
                >
            </div>
            ${this.renderPasswordForm()}
        `;
    }

    private renderPasswordForm() {
        if (!this.showChangePasswordForm) {
            return html`<div class="pt-6">
                <tm-button
                    importance="secondary"
                    @click=${() => {
                        this.showChangePasswordForm = true;
                    }}
                >
                    Change Password
                </tm-button>
            </div>`;
        }

        return html`<user-change-password-form
            .open=${this.showChangePasswordForm}
            @close=${() => {
                this.showChangePasswordForm = false;
            }}
        ></user-change-password-form>`;
    }

    public render() {
        return html`${this.renderAlert()}${this.renderLoader()}
            <div class="user-profile-container">
                <div class="user-information p-4">
                    <h2 class="section-title mb-2 font-medium">User Information</h2>
                    ${this.renderUserInformation()}
                </div>
                <div class="security-references mt-2 p-4">
                    <h2 class="section-title mb-2 font-medium">Security Preferences</h2>
                    ${this.renderSecurityPreferences()}
                </div>

                <div class="user-preferences mt-2 p-4">
                    <h2 class="section-title mb-2 font-medium">User Preferences</h2>
                    <dark-mode-toggle></dark-mode-toggle>
                </div>
            </div>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    width: 100vw;
                }
                .user-profile-container {
                    background-color: var(--primary-background);
                    color: var(--primary-text-color);
                }
                jha-form-input,
                jha-form-email-input,
                jha-form-phone-number {
                    margin: 16px 0;
                }
                .user-options-switch {
                    color: var(--color-primary);
                }
                .user-information,
                .security-references,
                .user-preferences {
                    background-color: var(--tm-card-background);
                    border: var(--tm-card-border);
                    box-shadow: var(--card-shadow);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserProfileContainer;
    }
}
