import { ConfigurationService } from '@treasury/core/config';
import { ChannelAuthenticationService } from '@treasury/domain/channel/services/account/authentication-service';
import { StatusCode } from '@treasury/domain/channel/services/login';
import '@treasury/omega/components/progress/omega-progress.js';
import { NotificationService, TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-password-field';
import { debounce } from '@treasury/utils';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import '@vaadin/dialog';
import { DialogOpenedChangedEvent } from '@vaadin/dialog';
import { dialogFooterRenderer, dialogRenderer } from '@vaadin/dialog/lit.js';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { LoginClient } from '../login-client';

export const tagName = 'change-password';
@customElement(tagName)
export default class ChangePassword extends TmBaseComponent {
    @InjectProperty()
    private declare readonly config: ConfigurationService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @property({ type: Boolean })
    loading = false;

    @state()
    private verifiedUser = {};

    @state()
    private requirements = [];

    @state()
    private statusCode = StatusCode.Unknown;

    @state()
    private password = '';

    @state()
    private confirmPassword = '';

    @state()
    private passwordRequirementsDialogOpened = false;

    @state()
    private debouncedPasswordsMatch = true;

    @InjectProperty()
    private declare readonly client: LoginClient;

    @InjectProperty()
    private declare authService: ChannelAuthenticationService;

    passwordsMatch() {
        return this.password === this.confirmPassword;
    }

    get daysUntilPasswordExpires() {
        return (this.verifiedUser as any).daysUntilPasswordExpires;
    }

    get passwordIsExpired() {
        return (
            this.statusCode === StatusCode.ChallengeResetExpiredPassword ||
            this.statusCode === StatusCode.ResetExpiredPassword
        );
    }

    get hasPasswordRequirements() {
        return this.requirements && this.requirements.length > 0;
    }

    get passwordNotExpiring() {
        return !this.passwordIsExpired && this.daysUntilPasswordExpires === null;
    }

    async firstUpdated() {
        try {
            this.loading = true;
            this.requirements = (await this.client.passwordRequirements(
                this.config.institutionId
            )) as any;
        } catch (err) {
            this.notificationService.renderError(err as Error);
        } finally {
            this.loading = false;
        }
    }

    async submitPassword() {
        this.dispatchEvent(new CustomEvent('submit', { detail: this.password }));
    }

    private debouncePasswordMismatchWarning = debounce(
        () => (this.debouncedPasswordsMatch = this.passwordsMatch()),
        500
    );

    onKeyDown(e: KeyboardEvent) {
        if (e.code === 'Enter') {
            this.submitPassword();
        }
    }

    renderPasswordRequirementsDialog() {
        if (!this.hasPasswordRequirements) return nothing;
        return html`<vaadin-dialog
            id="password-requirements-dialog"
            header-title="Password Requirements"
            .opened=${this.passwordRequirementsDialogOpened}
            @opened-changed=${(event: DialogOpenedChangedEvent) => {
                this.passwordRequirementsDialogOpened = event.detail.value;
            }}
            ${dialogRenderer(this.renderPasswordRequirements)}
            ${dialogFooterRenderer(this.renderPasswordRequirementsDialogFooter)}
        ></vaadin-dialog>`;
    }

    renderPasswordRequirementsDialogFooter() {
        return html`<div class="w-full flex justify-center">
            <tm-button
                importance="primary"
                @click=${() => {
                    this.passwordRequirementsDialogOpened = false;
                }}
            >
                Close
            </tm-button>
        </div>`;
    }

    renderPasswordExpiredMessage() {
        if (!this.passwordIsExpired) return nothing;
        return html` <span> Your password has expired. </span>`;
    }

    renderPasswordWillExpireSoonMessage() {
        if (this.passwordNotExpiring || !this.daysUntilPasswordExpires) return nothing;
        return html`
            <span> Your password will expire in ${this.daysUntilPasswordExpires} days. </span>
        `;
    }

    renderPasswordRequirements() {
        const requirements = this.requirements.map(
            (req: any) => html`<li class="text-sm pb-1">${req}</li>`
        );
        return html`
            <ul>
                ${requirements}
            </ul>
        `;
    }

    renderPasswordMismatchWarning() {
        if (this.confirmPassword.length === 0 || this.debouncedPasswordsMatch) return nothing;
        return html` <span class="text-sm" style="color:red"> Passwords Do Not Match </span>`;
    }

    render() {
        if (this.loading) return html`<omega-progress card></omega-progress>`;
        return html`
            ${this.renderPasswordRequirementsDialog()}
            <div class="m-4">
                <section class="pb-2">
                    <p>
                        Please enter a new
                        password${this.hasPasswordRequirements
                            ? html` following the
                                  <span
                                      class="underline text-[color:var(--brand-color)]"
                                      @click=${(e: Event) => {
                                          e.preventDefault();
                                          this.passwordRequirementsDialogOpened = true;
                                      }}
                                  >
                                      password requirements </span
                                  >.`
                            : '.'}
                    </p>
                    ${this.renderPasswordExpiredMessage()}
                    ${this.renderPasswordWillExpireSoonMessage()}
                </section>
                <tm-password-field
                    label="Password"
                    .value=${this.password}
                    @value-changed=${(e: CustomEvent) => {
                        this.password = e.detail.value;
                        this.debouncedPasswordsMatch = true;
                        this.debouncePasswordMismatchWarning();
                    }}
                    @keydown=${this.onKeyDown}
                    required
                    class="pb-1"
                >
                </tm-password-field>
                <tm-password-field
                    label="Confirm Password"
                    .value=${this.confirmPassword}
                    @value-changed=${(e: CustomEvent) => {
                        this.confirmPassword = e.detail.value;
                        this.debouncedPasswordsMatch = true;
                        this.debouncePasswordMismatchWarning();
                    }}
                    @keydown=${this.onKeyDown}
                    required
                    class="pb-1"
                >
                </tm-password-field>
                ${this.renderPasswordMismatchWarning()}
            </div>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: 'Submit',
                        onClick: () => this.submitPassword(),
                        disabled: !this.confirmPassword || !this.passwordsMatch(),
                    },
                ] as ButtonConfig[]}
            ></tm-footer>
        `;
    }

    static get styles() {
        return [
            css`
                :host() {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                :host([stickyheader]) {
                    display: block !important;
                }

                :host([stickyheader]) #content {
                    padding-bottom: var(--sticky-header-height, 0);
                }

                tm-footer {
                    padding-top: 1em;
                }

                @media only screen and (min-width: 640px) {
                    :host([stickyheader]) #content {
                        padding-bottom: 0px;
                    }
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: ChangePassword;
    }
}
