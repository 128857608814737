/* eslint-disable import/no-extraneous-dependencies */
import { TextFieldValueChangedEvent } from '@vaadin/text-field';
import at from 'lodash.at';

/**
 *
 * @param e TextFieldValueChangedEvent or raw search string
 * @param itemsToFilter Array of any values to filter
 * @param keysToSearch Array<string> of keys to compare the value with the search term. Note that a key should be passed with dot notation if you are attempting to search a value in an object EX: 'FromAccount.Number'
 * @returns
 */
export function filterTableResults(
    e: TextFieldValueChangedEvent | CustomEvent<{ value: string }> | string,
    itemsToFilter: Array<any>,
    keysToSearch: Array<string>
): Array<any> {
    let searchTerm: string;
    if (typeof e === 'string') searchTerm = e.trim();
    else searchTerm = (e.detail.value || '').trim();
    const items = itemsToFilter ?? [];
    const matchesTerm = (value: string | number): boolean => {
        if (value == null) return false;
        return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
    };

    return items.filter(
        (item: any, index: number) =>
            !searchTerm ||
            at(itemsToFilter[index], ...keysToSearch).some(value => matchesTerm(value))
    );
}
