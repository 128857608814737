import { InternalTransferTransactionModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';

export class PendingTransfer extends DomainEntity<InternalTransferTransactionModelDto> {
    constructor(dto: InternalTransferTransactionModelDto) {
        super(dto);
    }

    public createDefault(): this {
        throw new Error('Method not implemented.');
    }

    get payment() {
        return this.dto;
    }

    get paymentId() {
        return this.dto.id;
    }

    get fromAccount() {
        return this.dto.fromAccount.accountDisplayLabel;
    }

    get toAccount() {
        return this.dto.toAccount.accountDisplayLabel;
    }

    get amount() {
        return this.dto.amount;
    }
}
