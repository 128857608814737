import { AlarmClock } from '@treasury/alarm-clock';
import { CompanyAccountModelDto } from '@treasury/api/channel';
import { NavigationService } from '@treasury/core/navigation';
import { AchException } from '@treasury/domain/arp';
import { PayOrReturnChoice } from '@treasury/domain/channel/mappings/ach';
import PositivePayService from '@treasury/domain/channel/services/positive-pay/positive-pay-service';
import '@treasury/omega/components/progress/omega-progress.js';
import { NotificationService, TmContainer } from '@treasury/presentation';
import { informationIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/forms/tm-form-row';
import '@treasury/presentation/components/layout/tm-section';
import '@treasury/presentation/components/tm-labeled-list';
import '@treasury/presentation/components/tm-tabs';
import { InjectProperty } from '@treasury/utils';
import { MediaService } from '@treasury/utils/services/media-service';
import { css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../../../components/confirmation-container';
import { HeaderBarService } from '../../../services/jhd-header-bar.service';
import { MenuItem } from '../../../services/types/jhd-header-bar.type';
import { defaultAchExceptionsActivitySearchRequest } from '../data/default-ach-exceptions-activity-search-request';
import { defaultAchExceptionsSearchRequest } from '../data/default-ach-exceptions-search-request';
import {
    ACH_EXCEPTION_CUTOFF,
    ACH_EXCEPTION_CUTOFF_MINUS_30,
    ACH_EXCEPTION_START,
} from '../partials/ach-exception-cutoff';
import '../partials/ach-exception-detail';
import '../partials/ach-exceptions-table';
import { AchExceptionActivityVm } from '../view-models/ach-exception-activity';
import './ach-exception-workflow-container';

enum AchExceptionsConfig {
    List = 'list',
    Review = 'review',
    Confirm = 'confirm',
}

export const tagName = 'ach-exceptions-container';
@customElement(tagName)
export class AchExceptionsContainer extends TmContainer {
    @InjectProperty()
    private declare mediaService: MediaService;

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare positivePayService: PositivePayService;

    @state()
    private achExceptions!: AchException[];

    @state()
    private achExceptionActivity: AchExceptionActivityVm[] = [];

    @state()
    private exceptionDetailVisible = false;

    @state()
    step: AchExceptionsConfig = AchExceptionsConfig.List;

    @state()
    tabs = [
        { label: 'To Decision', id: 'ach-exceptions' },
        // Keep hidden until valid requests are available
        // { label: 'Decision Activity', id: 'ach-exceptions-activity' },
    ];

    @state()
    activeTab: 'ach-exceptions' | 'ach-exceptions-activity' = 'ach-exceptions';

    @state()
    selectedException!: AchException;

    @state()
    private menuItems: MenuItem[] = [
        { title: 'Info', icon: informationIcon, action: () => console.log('clicked') },
    ];

    @state()
    private accounts: CompanyAccountModelDto[] = [];

    @state()
    private secCodes: { secCode?: string; description?: string }[] = [];

    @state()
    private cutoffMessage?: string;

    @state()
    private decisioningDisabled = false;

    @state()
    private defaultRequest = defaultAchExceptionsSearchRequest;

    @state()
    private defaultActivityRequest = defaultAchExceptionsActivitySearchRequest;

    get exceptionType() {
        return window.location.pathname.split('/').pop();
    }

    private get fiClock() {
        return AlarmClock.getInstance();
    }

    async firstUpdated() {
        this.initHeader();
        await this.setCutoffMessage();
        await this.fetchAccounts();
        await this.fetchSecCodes();
    }

    async fetchAccounts() {
        await this.tryFetch(
            async () => this.positivePayService.getArpAchExceptionAccounts(),
            result => {
                if (result) {
                    this.accounts = result;
                    this.defaultRequest.accounts = this.accounts as [];
                }
            }
        );
    }

    async fetchSecCodes() {
        await this.tryFetch(
            async () => this.positivePayService.getArpSecCodes(),
            result => {
                if (result) {
                    this.secCodes = result;
                    this.defaultRequest.secCodes = this.secCodes as [];
                }
            }
        );
    }

    async fetchAchExceptions(filters: any = this.defaultRequest) {
        await this.tryFetch(
            async () => this.positivePayService.searchAchExceptions(filters),
            result => {
                if (result) {
                    this.achExceptions = result;
                }
            }
        );
    }

    async fetchAchExceptionsActivity(filters: any = this.defaultActivityRequest) {
        await this.tryFetch(
            async () => this.positivePayService.searchAchExceptionsActivity(filters),
            result => {
                if (result) {
                    this.achExceptionActivity = result.map(ex => new AchExceptionActivityVm(ex));
                }
            }
        );
    }

    async goToCheckExceptions() {
        await this.navService.navigate('/positive-pay/check-exceptions');
    }

    private async setCutoffMessage() {
        await this.fiClock.checkReadiness();
        if (
            this.fiClock.isAfter(ACH_EXCEPTION_CUTOFF_MINUS_30) &&
            this.fiClock.isBefore(ACH_EXCEPTION_CUTOFF)
        ) {
            this.cutoffMessage = `ACH exception items will be disabled for decisioning at ${this.fiClock.getAlarm(
                ACH_EXCEPTION_CUTOFF
            )} Cut-Off time (${this.fiClock.timeZone}).`;
            this.notificationService.renderWarning(this.cutoffMessage);
        } else if (this.fiClock.isAfter(ACH_EXCEPTION_CUTOFF)) {
            this.decisioningDisabled = true;
            this.cutoffMessage = `ACH exception items are disabled because the current time is past the ${this.fiClock.getAlarm(
                ACH_EXCEPTION_CUTOFF
            )} Cut-Off time (${this.fiClock.timeZone})`;
            this.notificationService.renderWarning(this.cutoffMessage);
        } else if (this.fiClock.isBefore(ACH_EXCEPTION_START)) {
            this.decisioningDisabled = true;
            this.cutoffMessage = `ACH exception items are not available at this time. Please try again after ${this.fiClock.getAlarm(
                ACH_EXCEPTION_START
            )} (${this.fiClock.timeZone}).`;
            this.notificationService.renderWarning(this.cutoffMessage);
        } else {
            this.cutoffMessage = undefined;
        }
    }

    initHeader() {
        this.headerService.configure({ title: 'Positive Pay - ACH', menuItems: this.menuItems });
    }

    goToReviewStep() {
        this.step = AchExceptionsConfig.Review;
        this.headerService.configure({
            backAction: () => {
                this.step = AchExceptionsConfig.List;
                this.initHeader();
            },
            title: 'Review Decisions',
        });
    }

    goToConfirmStep() {
        this.step = AchExceptionsConfig.Confirm;
        this.headerService.configure({});
    }

    goToLandingTable() {
        this.step = AchExceptionsConfig.List;
        this.initHeader();
    }

    displayDetailSheet(exception: AchException) {
        this.selectedException = exception;
        this.exceptionDetailVisible = true;
    }

    switchExceptionTab(tabId: 'ach-exceptions' | 'ach-exceptions-activity') {
        this.activeTab = tabId;
    }

    async confirm(achExceptions: AchException[]) {
        await this.tryFetch(
            async () => this.positivePayService.updateExceptionStatuses(achExceptions),
            result => {
                if (!result) {
                    this.loading = false;
                    this.notificationService.renderError('Failed to update ACH exceptions.');
                    return;
                }
                if (this.step === AchExceptionsConfig.Review && result) {
                    this.goToConfirmStep();
                }
            }
        );
    }

    decisionAll(decision: PayOrReturnChoice) {
        this.achExceptions?.forEach((e: AchException) => {
            this.updateExceptionDecision(decision, e);
        });
        this.achExceptions = this.achExceptions.map(e => e.clone());
    }

    updateExceptionDecision(decision: PayOrReturnChoice, item: AchException) {
        switch (decision) {
            case PayOrReturnChoice.Pay:
                item.pay();
                return;
            case PayOrReturnChoice.Return:
                item.return();
                return;
            case PayOrReturnChoice.Unselected:
                item.clearDecision();
                return;
            default:
                console.warn('Invalid decision');
                item.clearDecision();
        }
    }

    renderWorkflow() {
        const tabs =
            this.step === AchExceptionsConfig.List
                ? html`
                      <tm-tabs
                          .tabs=${this.tabs}
                          .activeTab=${this.exceptionType}
                          @switchTab=${(event: CustomEvent) =>
                              this.switchExceptionTab(event.detail.activeTab)}
                      >
                      </tm-tabs>
                  `
                : nothing;
        return html`${tabs}<ach-exception-workflow-container
                .loading=${this.loading}
                .step=${this.step}
                .activeTab=${this.activeTab}
                .decisioningDisabled=${this.decisioningDisabled}
                .achExceptions=${this.achExceptions}
                .achExceptionActivity=${this.achExceptionActivity}
                .accounts=${this.accounts}
                .secCodes=${this.secCodes}
                @selected=${(event: CustomEvent<AchException>) => {
                    this.displayDetailSheet(event.detail);
                }}
                @decisionAll=${(event: CustomEvent<PayOrReturnChoice>) => {
                    this.decisionAll(event.detail);
                }}
                @filter=${(e: CustomEvent) => {
                    this.fetchAchExceptions(e.detail);
                }}
                @filter-activity=${(e: CustomEvent) => {
                    this.fetchAchExceptionsActivity(e.detail);
                }}
                @review=${() => {
                    this.goToReviewStep();
                }}
                @confirm=${(e: CustomEvent<AchException[]>) => {
                    this.confirm(e.detail);
                }}
                @list=${() => {
                    this.goToLandingTable();
                }}
                @check-exceptions=${() => {
                    this.goToCheckExceptions();
                }}
            ></ach-exception-workflow-container>`;
    }

    renderExceptionDetail() {
        return html`<ach-exception-detail
            .open=${this.exceptionDetailVisible}
            .loading=${this.loading}
            .hideActions=${this.decisioningDisabled}
            @close=${() => {
                this.exceptionDetailVisible = false;
            }}
            @pay=${async () => {
                this.updateExceptionDecision(PayOrReturnChoice.Pay, this.selectedException);
                await this.confirm([this.selectedException]);
                this.exceptionDetailVisible = false;
                this.fetchAchExceptions();
            }}
            @return=${async () => {
                this.updateExceptionDecision(PayOrReturnChoice.Return, this.selectedException);
                await this.confirm([this.selectedException]);
                this.exceptionDetailVisible = false;
                this.fetchAchExceptions();
            }}
            .selectedException=${this.selectedException}
        ></ach-exception-detail>`;
    }

    render() {
        return html` ${this.renderWorkflow()} ${this.renderExceptionDetail()}`;
    }

    static get styles() {
        return [
            css`
                :host {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }
                ach-exceptions-table {
                    width: 100vw;
                }
                tm-tabs {
                    background-color: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchExceptionsContainer;
    }
}
