import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-footer';
import '@treasury/presentation/components/tm-toggle';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

export const tagName = 'payee-filters-sheet';
@customElement(tagName)
export class PayeeFiltersSheet extends TmBaseComponent {
    @property({ type: Boolean })
    open = false;

    @state()
    private filterData: { onlyIncludePayablePayees: boolean } = {
        onlyIncludePayablePayees: false,
    };

    render() {
        return html`<tm-bottom-sheet
            .open=${this.open}
            .closeable=${true}
            @close=${() => {
                this.open = false;
                this.dispatchEvent(new CustomEvent('close'));
            }}
            ><div slot="header-center">Filter Payees</div>
            <div class="p-3 filter-wrapper">
                <tm-toggle
                    .label=${'Only include payable payees'}
                    .checked=${this.filterData.onlyIncludePayablePayees}
                    @change=${(e: CustomEvent) => {
                        this.filterData.onlyIncludePayablePayees = e.detail.checked;
                    }}
                ></tm-toggle>
            </div>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: 'Cancel',
                        importance: 'secondary',
                        onClick: () => {
                            this.open = false;
                            this.dispatchEvent(new CustomEvent('close'));
                        },
                    },
                    {
                        text: 'Apply',
                        importance: 'primary',
                        onClick: () => {
                            this.dispatchEvent(
                                new CustomEvent('apply-filters', {
                                    detail: { filterData: this.filterData },
                                })
                            );
                        },
                    },
                ]}
            ></tm-footer>
        </tm-bottom-sheet>`;
    }

    static get styles() {
        return [
            css`
                .filter-wrapper {
                    background-color: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: PayeeFiltersSheet;
    }
}
