import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { TmBaseComponent } from '../tm-base.component';

export const tagName = 'tm-badge';
@customElement(tagName)
export class TmBadge extends TmBaseComponent {
    render() {
        return html`<slot></slot>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: inline-block;
                    height: 20px;
                    background-color: var(--tm-badge-background, var(--tm-text-muted, #b3bfc9));
                    color: var(--tm-badge-text, #fff);
                    font-size: var(--tm-badge-text-size, 12px);
                    line-height: 20px;
                    text-align: center;
                    vertical-align: middle;
                    white-space: nowrap;
                    padding: 0 8px;
                    border-radius: 10px;
                }
                :host([primary]) {
                    --tm-badge-background: var(--tm-color-primary, #3aaeda);
                    --tm-badge-text: var(--tm-text-white, #ffffff);
                }
                :host([success]) {
                    --tm-badge-background: var(--tm-color-success, #4caf50);
                    --tm-badge-text: #ffffff;
                }
                :host([danger]) {
                    --tm-badge-background: var(--tm-color-danger, #f44336);
                    --tm-badge-text: #ffffff;
                }
                :host([warning]) {
                    --tm-badge-background: var(--tm-color-warning, #fca902);
                    --tm-badge-text: #ffffff;
                }
                :host([light]) {
                    --tm-badge-background: var(--tm-color-light, #8c989f);
                }
                :host([medium]) {
                    --tm-badge-text-size: 12px;
                    height: 16px;
                    line-height: 16px;
                    padding: 0 5px;
                }
                :host([small]) {
                    --tm-badge-text-size: 9px;
                    height: 15px;
                    line-height: 15px;
                    padding: 0 5px;
                }
                :host([dot]) {
                    width: 7px;
                    height: 7px;
                    line-height: 7px;
                    padding: 0;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmBadge;
    }
}
