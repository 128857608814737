import { CompanyAccountModelDto } from '@treasury/api/channel';
import TransferPaymentsService from '@treasury/domain/channel/services/transfers/transfer-payments-service';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-currency-range';
import {
    CurrencyRange,
    CurrencyRangeType,
} from '@treasury/presentation/components/tm-currency-range';
import { mapCurrencyRangeToDto } from '@treasury/presentation/components/tm-currency-range.mappings';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-loader';
import '@treasury/presentation/components/tm-multi-select';
import { MultiSelectItem } from '@treasury/presentation/components/tm-multi-select';
import '@treasury/presentation/components/tm-text-field';
import { DeepReactive } from '@treasury/utils/lit-helpers';
import { html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { transferTemplateStatusItems } from '../data/transfer-template-status-items';
import { transferTemplateTypeItems } from '../data/transfer-template-type-items';

const defaultAmountRange: CurrencyRange = {
    rangeType: CurrencyRangeType.AllAmounts,
};

export const tagName = 'transfer-templates-filter-sheet';
@customElement(tagName)
export class TransferTemplatesFilterSheet extends TmBaseComponent {
    @property({ type: Boolean })
    open = false;

    private _action = '';

    @property({ type: String })
    get action() {
        return this._action;
    }

    set action(value) {
        this._action = value;
        this.statusItems =
            this._action === 'initiate'
                ? transferTemplateStatusItems.filter(status => status.label === 'Ready')
                : transferTemplateStatusItems;
        this.statusItemsSelected = this.statusItems;
    }

    @state()
    private templateName = '';

    @state()
    private transferTypeItemsSelected: MultiSelectItem[] = transferTemplateTypeItems;

    @state()
    private accountItems: MultiSelectItem[] = [];

    @state()
    private toAccountItemsSelected: MultiSelectItem[] = [];

    @state()
    private fromAccountItemsSelected: MultiSelectItem[] = [];

    @state()
    private amountRange: CurrencyRange = defaultAmountRange;

    @state()
    statusItems: MultiSelectItem[] = transferTemplateStatusItems;

    @state()
    statusItemsSelected: MultiSelectItem[] = transferTemplateStatusItems;

    @DeepReactive()
    private validState = {
        status: true,
        transferType: true,
        fromAccount: true,
        toAccount: true,
        amountRange: true,
    };

    @state()
    private loading = true;

    async firstUpdated() {
        try {
            const transferAccounts =
                (await TransferPaymentsService.fetchTransferAccounts()) as unknown as CompanyAccountModelDto[];
            this.accountItems = transferAccounts.map((account: CompanyAccountModelDto) => ({
                label: account.accountDisplayLabel ?? '',
                value: account,
            }));
            this.onResetForm();
            this.onApplyFilters();
        } catch (err) {
            console.error(err);
        } finally {
            this.loading = false;
        }
    }

    onApplyFilters() {
        this.open = false;
        this.dispatchEvent(new CustomEvent('close'));
        this.dispatchEvent(
            new CustomEvent('applyFilters', {
                detail: this.toDto(),
            })
        );
    }

    private onResetForm() {
        this.templateName = '';
        this.transferTypeItemsSelected = transferTemplateTypeItems;
        this.fromAccountItemsSelected = this.accountItems;
        this.toAccountItemsSelected = this.accountItems;
        this.amountRange = { ...defaultAmountRange };
        this.statusItemsSelected = this.statusItems;
    }

    private toDto() {
        const {
            operator: amountOperator,
            fromAmount: amountStart,
            toAmount: amountEnd,
            specificAmount: amountSpecific,
        } = mapCurrencyRangeToDto(this.amountRange);

        return {
            templateName: this.templateName,
            transferType: this.transferTypeItemsSelected.map((item: MultiSelectItem) => item.value),
            fromAccounts: this.fromAccountItemsSelected.map((item: MultiSelectItem) => item.value),
            toAccounts: this.toAccountItemsSelected.map((item: MultiSelectItem) => item.value),
            bothAccounts: this.accountItems.map((item: MultiSelectItem) => item.value),
            accountOperator: 'Both',
            amountOperator,
            amountSpecific,
            amountStart,
            amountEnd,
            status: this.statusItemsSelected.map((item: MultiSelectItem) => item.value),
        };
    }

    renderStatusSelect() {
        if (this.statusItems.length <= 1) return nothing;
        return html`
            <tm-multi-select
                label="Status"
                .allowSelectAll=${true}
                .allSelectedText=${'All Transfer Template Statuses'}
                .items=${this.statusItems}
                .selectedItems=${[...this.statusItemsSelected]}
                @selected-items-changed=${(e: CustomEvent) => {
                    this.statusItemsSelected = e.detail;
                    this.validState.status = !!this.statusItemsSelected.length;
                }}
            ></tm-multi-select>
        `;
    }

    renderForm() {
        if (this.loading) return html`<tm-loader card></tm-loader>`;
        return html`<form>
            <tm-text-field
                label="Template Name"
                .value=${this.templateName}
                @value-changed=${(e: CustomEvent) => {
                    this.templateName = e.detail.value;
                }}
            ></tm-text-field>
            <tm-multi-select
                label="Transfer Type"
                .allowSelectAll=${true}
                .allSelectedText=${'All Transfer Types'}
                .items=${transferTemplateTypeItems}
                .selectedItems=${[...this.transferTypeItemsSelected]}
                @selected-items-changed=${(e: CustomEvent) => {
                    this.transferTypeItemsSelected = e.detail;
                    this.validState.transferType = !!this.transferTypeItemsSelected.length;
                }}
            ></tm-multi-select>
            <tm-multi-select
                label="From Account"
                .allowSelectAll=${true}
                .allSelectedText=${'All Accounts'}
                .items=${this.accountItems}
                .selectedItems=${[...this.fromAccountItemsSelected]}
                @selected-items-changed=${(e: CustomEvent) => {
                    this.fromAccountItemsSelected = e.detail;
                    this.validState.fromAccount = !!this.fromAccountItemsSelected.length;
                }}
            ></tm-multi-select>
            <tm-multi-select
                label="To Account"
                .allowSelectAll=${true}
                .allSelectedText=${'All Accounts'}
                .items=${this.accountItems}
                .selectedItems=${[...this.toAccountItemsSelected]}
                @selected-items-changed=${(e: CustomEvent) => {
                    this.toAccountItemsSelected = e.detail;
                    this.validState.toAccount = !!this.toAccountItemsSelected.length;
                }}
            ></tm-multi-select>
            <tm-currency-range
                .range=${this.amountRange}
                @selection=${(e: CustomEvent) => {
                    this.validState.amountRange = !!e.detail;
                    if (!!e.detail) {
                        this.amountRange = e.detail;
                    }
                }}
            ></tm-currency-range>
            ${this.renderStatusSelect()}
        </form>`;
    }

    render() {
        return html`<tm-bottom-sheet
            .open=${this.open}
            @close=${() => {
                this.open = false;
                this.dispatchEvent(new CustomEvent('close'));
            }}
        >
            <p slot="header-center">Filter Transfer Templates</p>
            <p slot="header-right"></p>
            <tm-body>${this.renderForm()}</tm-body>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: 'Apply Filters',
                        onClick: () => {
                            this.onApplyFilters();
                        },
                        disabled: Object.values(this.validState).some(isValid => !isValid),
                    },
                    {
                        importance: 'secondary',
                        text: 'Reset',
                        onClick: () => {
                            this.onResetForm();
                        },
                    },
                ] as ButtonConfig[]}
            >
            </tm-footer>
        </tm-bottom-sheet>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TransferTemplatesFilterSheet;
    }
}
