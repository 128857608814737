/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { CompanyAuthenticationSettingsModelDto, OkResultDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class SecureChallengeClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags SecureChallenge
     * @name SecureChallengeUpdateStatus
     * @summary Updates secure challenge.
     * @request POST:/secure-challenge/update-status
     */
    secureChallengeUpdateStatus = (
        query: {
            /**
             * The company id
             * @format int32
             */
            companyId: number;
            /** True of False. */
            isSecureChallengeEnabled: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyAuthenticationSettingsModelDto, any>({
            path: `/secure-challenge/update-status`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags SecureChallenge
     * @name SecureChallengeUpdateChallengeMethodType
     * @summary Updates secure challenge method.
     * @request POST:/secure-challenge/update-challenge-method
     */
    secureChallengeUpdateChallengeMethodType = (
        query: {
            /**
             * The company id.
             * @format int32
             */
            companyId: number;
            /**
             * The challenge method type.
             * @format int32
             */
            challengeMethodType: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<OkResultDto, any>({
            path: `/secure-challenge/update-challenge-method`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags SecureChallenge
     * @name SecureChallengeUpdateChallengeGroup
     * @summary Updates the challenge group.
     * @request POST:/secure-challenge/update-challenge-group
     */
    secureChallengeUpdateChallengeGroup = (
        query: {
            /**
             * The company id.
             * @format int32
             */
            companyId: number;
            /**
             * THe challenge group id.
             * @format int32
             */
            challengeGroupId: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyAuthenticationSettingsModelDto, any>({
            path: `/secure-challenge/update-challenge-group`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        });
}
