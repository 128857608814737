import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-blocking-loader';
import '@vaadin/grid';
import { columnBodyRenderer, GridColumnBodyLitRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { UserIntegratedServicesEntitlementsViewModel } from '../data/user-integrated-services-entitlements-view-model';

export const tagName = 'user-integrated-services';
@customElement(tagName)
export class UserIntegratedServices extends TmBaseComponent {
    @property({ type: Object })
    public config!: UserIntegratedServicesEntitlementsViewModel;

    renderProductNameColumn(product: { name: string; hasClaim: boolean; edited: boolean }) {
        return html`<div>
            <span class="text-sm font-normal">${product.name}</span>
        </div>`;
    }

    renderProductSettingsColumn(product: { name: string; hasClaim: boolean; edited: boolean }) {
        const hasClaim = product.hasClaim ? 'Yes' : 'No';
        return html`<div>
            <span
                class=${classMap({
                    'text-sm': true,
                    'font-normal': true,
                    edited: product.edited,
                })}
                >${hasClaim}</span
            >
        </div>`;
    }

    render() {
        return html`<div class="integrated-products">
            <h3 class="p-3 text-lg font-normal">Integrated Services</h3>
            <vaadin-grid all-rows-visible .items=${this.config?.permissions}
                ><vaadin-grid-column
                    ${columnBodyRenderer(
                        this.renderProductNameColumn as GridColumnBodyLitRenderer<{
                            name: string;
                            hasClaim: boolean;
                            edited: boolean;
                        }>
                    )}
                ></vaadin-grid-column
                ><vaadin-grid-column
                    text-align="end"
                    ${columnBodyRenderer(
                        this.renderProductSettingsColumn as GridColumnBodyLitRenderer<{
                            name: string;
                            hasClaim: boolean;
                            edited: boolean;
                        }>
                    )}
                ></vaadin-grid-column
            ></vaadin-grid>
        </div>`;
    }

    static get styles() {
        return [
            css`
                .integrated-products {
                    background: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserIntegratedServices;
    }
}
