/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { UserDto, UserInformationModelDto } from '@treasury/api/channel';
import { NavigationService } from '@treasury/core/navigation';
import { PendingApprovalsService } from '@treasury/domain/approvals/approvals.service';
import { PendingUser } from '@treasury/domain/approvals/pending-user.entity';
import { DashboardService } from '@treasury/domain/channel/services/dashboard/dashboard-service';
import { DashboardProduct } from '@treasury/domain/channel/types/dashboard/dashboard';
import { PaymentTypes, paymentTypes } from '@treasury/domain/channel/types/payment';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { TmBaseComponent } from '@treasury/presentation';
import {
    achIcon,
    billIcon,
    transferIcon,
    userIcon,
    wiresIcon,
} from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-action-button';
import '@treasury/presentation/components/tm-card';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { UserProfileClient } from '../../profile/clients/user-profile-client';

export const tagName = 'approval-widget';
@customElement(tagName)
export class ApprovalWidget extends TmBaseComponent {
    @property({ type: Boolean })
    private loading = false;

    @property({ type: Boolean })
    public disabled = false;

    @property({ type: Array })
    public products: Map<string, boolean> = new Map();

    @InjectProperty()
    private declare dashboardService: DashboardService;

    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare userClient: UserProfileClient;

    @InjectProperty()
    private declare approvalsService: PendingApprovalsService;

    @property({ type: Array })
    private transferApprovals = [];

    @property({ type: Array })
    private loadApprovals = [];

    @property({ type: Array })
    private wireApprovals = [];

    @property({ type: Array })
    private achApprovals = [];

    @property({ type: Array })
    private userApprovals: PendingUser[] = [];

    @state()
    private user: UserInformationModelDto | undefined;

    async firstUpdated() {
        const featureFlagService = await FeatureFlagService.getInstance();
        this.loading = true;
        try {
            const approvalPromises = paymentTypes.map((type: PaymentTypes) =>
                this.dashboardService.getPendingApprovalPaymentsByType(type)
            );
            const approvals = (await Promise.all(approvalPromises)) as any;
            [this.transferApprovals, this.loadApprovals, this.wireApprovals, this.achApprovals] =
                approvals;
            this.user = await this.userClient.getCurrentUser();
            if (this.user.isAdmin) {
                this.userApprovals = await this.approvalsService.getPendingApprovalUsers();
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    }

    routeToApprovalType(type: string) {
        this.navService.navigate(`/pending-approvals/${type}`);
    }

    handleKeyUp(event: KeyboardEvent, route: string) {
        const { key } = event;
        if (key === 'Return') this.routeToApprovalType(route);
    }

    renderAchActionButton() {
        const hasAchProduct = this.products.get('ACH');
        if (hasAchProduct) {
            return html`<tm-action-button
                .label=${'ACH'}
                .icon=${achIcon}
                .action=${() => this.routeToApprovalType('ach')}
                .keyup=${(event: KeyboardEvent) => this.handleKeyUp(event, 'ach')}
                .approvals=${this.achApprovals.length}
                .disabled=${this.disabled}
            ></tm-action-button>`;
        }
        return nothing;
    }

    renderTransfersActionButton() {
        const hasTransferProduct = this.products.get('InternalTransferProduct');
        if (hasTransferProduct) {
            return html`<tm-action-button
                .label=${'Transfers'}
                .icon=${transferIcon}
                .action=${() => this.routeToApprovalType('transfer')}
                .keyup=${(event: KeyboardEvent) => this.handleKeyUp(event, 'transfer')}
                .approvals=${this.transferApprovals.length}
                .disabled=${this.disabled}
            ></tm-action-button>`;
        }
        return nothing;
    }

    renderWiresActionButton() {
        const hasWireProduct = this.products.get('WireTransferProductFeature');
        if (hasWireProduct) {
            return html`<tm-action-button
                .label=${'Wires'}
                .icon=${wiresIcon}
                .action=${() => this.routeToApprovalType('wire')}
                .keyup=${(event: KeyboardEvent) => this.handleKeyUp(event, 'wire')}
                .approvals=${this.wireApprovals.length}
                .disabled=${this.disabled}
            ></tm-action-button>`;
        }
        return nothing;
    }

    renderLoansActionButton() {
        const hasLoanProduct = this.products.get('WireTransferDliProductFeature');
        if (hasLoanProduct) {
            return html`<tm-action-button
                .label=${'Loans'}
                .icon=${billIcon}
                .action=${() => this.routeToApprovalType('loanpayment')}
                .keyup=${(event: KeyboardEvent) => this.handleKeyUp(event, 'loanpayment')}
                .approvals=${this.loadApprovals.length}
                .disabled=${this.disabled}
            ></tm-action-button>`;
        }
        return nothing;
    }

    renderUsersActionButton() {
        if (!this.user) return nothing;
        if (!this.user.isAdmin) return nothing;
        return html`<tm-action-button
            .label=${'Users'}
            .icon=${userIcon}
            .action=${() => this.routeToApprovalType('user')}
            .approvals=${this.userApprovals.length}
            .disabled=${this.disabled}
        ></tm-action-button>`;
    }

    renderApprovalIcons() {
        if (this.loading) return html`<tm-loader card class="test"></tm-loader>`;
        return html` <div class="flex flex-wrap p-2.5">
            ${this.renderAchActionButton()} ${this.renderTransfersActionButton()}
            ${this.renderWiresActionButton()} ${this.renderLoansActionButton()}
            ${this.renderUsersActionButton()}
        </div>`;
    }

    render() {
        return html`
            <tm-card class="bg-elev-1">
                <h4>Approvals</h4>
                ${this.renderApprovalIcons()}
            </tm-card>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    --tm-card-min-height: 150px;
                }
                p {
                    color: var(--header-text-color);
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                }
                h4 {
                    margin-left: 12px;
                    margin-top: 8px;
                    font-weight: 400;
                    color: var(--header-text-color);
                    font-size: 16px;
                    line-height: 28px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: ApprovalWidget;
    }
}
