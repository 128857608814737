import { TmHttpClient } from '@treasury/core/http';
import { FiDate } from '../../../dates';
import { TransferActionType } from '../../../transfers/transfer-action.type.js';
import {
    mapTransferActivityParameters,
    mapUpdateTransferParamsToRequest,
} from '../../mappings/transfers/map-transfer-request-params.js';
import { OutputType } from '../../types/download/output-type.type.js';
import AchConfigurationServices from '../ach/ach-configuration-services.js';
import DownloadService from '../download/download-service.js';

// TODO: there's some type cleanup that needs to be done here. Should be able to replace the old 'requests' file with this service + mappings
export default class TransferPaymentsService {
    static async fetchTransferActivity({ parameters }: any) {
        const body = mapTransferActivityParameters(parameters);
        const http = await TmHttpClient.getInstance();
        const data = await http.request<any[]>(`internalTransfers/FilterTransfers`, {
            method: 'POST',
            body,
        });

        return {
            data,
            totalCount: data.length,
        };
    }

    static async getTransfers(body: any) {
        const http = await TmHttpClient.getInstance();
        const data = await http.request<any[]>(`internalTransfers/FilterTransfers`, {
            method: 'POST',
            body,
        });

        return {
            data,
            totalCount: data.length,
        };
    }

    static async fetchTransferAccounts() {
        return (await TmHttpClient.getInstance()).request(
            `companyaccounts/GetWithProductFeature?type=To&productfeaturetype=InternalTransfer`,
            {
                method: 'GET',
                // maxAgeInSeconds: 600000,
            }
        );
    }

    static async fetchAchConfig() {
        const configuration = (await AchConfigurationServices.getConfiguration()) as {
            cutoffTimes: any[];
            holidays: any[];
            isSameDayAchEnabled: boolean;
        };
        return {
            cutoffTimes: configuration.cutoffTimes,
            holidays: configuration.holidays,
            allowSameDayPayments: configuration.isSameDayAchEnabled,
        };
    }

    /**
     * @template T The shape of the fetched data.
     * @param {'cancel' | 'approve' | 'reject' | 'resubmit'} action: Action named for the request.
     * The strings `'cancel'`, `'approve'`, `'reject'`, and `'resubmit'` are valid values.
     * @param {{comments:string, internalTransferId:string}} config
     * @returns {Promise<FetchWithAuthentication<T>>}
     */
    static async updateTransferStatus(action: TransferActionType, parameters: any) {
        const { securityActionType, endpoint, data } = mapUpdateTransferParamsToRequest(
            action,
            parameters
        );

        const method = action === 'resubmit' ? 'PUT' : 'POST';

        return (await TmHttpClient.getInstance()).request(`internalTransfers/${endpoint}`, {
            method,
            body: {
                ...data,
                securityMessage: {
                    actionType: `${securityActionType}Transfer_ByName`,
                    errorCode: null,
                    hasAlternate: false,
                    message: null,
                    methodUsed: null,
                    oneTimePassword: null,
                    status: null,
                },
            },
        });
    }

    static async downloadTransfers(outputType: OutputType, page: string, filterModel: any) {
        const fileSuffix = new FiDate(new Date()).toIsoDate();
        const mappedDownloadRequestFilters = mapTransferActivityParameters(filterModel);
        const downloadFormats = [outputType];
        return DownloadService.download(
            fileSuffix,
            outputType,
            page,
            { ...mappedDownloadRequestFilters, page },
            downloadFormats
        );
    }
}
