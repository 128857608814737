import CheckExceptionsServices from '@treasury/domain/channel/services/positive-pay/check-exceptions-services';
import { OmegaColumnDefinition, OmegaColumnType } from '@treasury/omega/components/table';
import CheckImageColumn from '@treasury/omega/components/table/check-image-column.js';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const columns: readonly OmegaColumnDefinition<any>[] = Object.freeze([
    {
        field: 'decisionToPayOrReturn',
        label: 'Pay / Return',
    },
    {
        field: 'returnReason',
        label: 'Return Reason',
    },
    {
        field: 'accountNumber',
        label: 'Account',
    },
    {
        field: 'checkNumber',
        label: 'Check Number',
        type: CheckImageColumn,
        fetchCheckImages: CheckExceptionsServices.fetchCheckImages,
    },
    {
        field: 'paidAmount',
        label: 'Paid Amount',
    },
    {
        field: 'issuedAmount',
        label: 'Issued Amount',
    },
    {
        field: 'postedDate',
        label: 'Posted Date',
    },
    {
        field: 'issuedDate',
        label: 'Issued Date',
    },
    {
        field: 'issuedPayee',
        label: 'Issued Payee',
    },
    {
        field: 'action',
        type: OmegaColumnType.Icon,
    },
]);
