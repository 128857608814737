/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { NavigationService } from '@treasury/core/navigation';
import { DashboardService } from '@treasury/domain/channel/services/dashboard/dashboard-service';
import { UsersService } from '@treasury/domain/channel/services/users/users-service';
import { Wire, WireBeneficiary, WiresService } from '@treasury/domain/wires';
import { TmBaseComponent } from '@treasury/presentation';
import { actionIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-footer';
import { InjectProperty } from '@treasury/utils';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import '../shared/partials/wire-read-only';

export const tagName = 'wire-container';
@customElement(tagName)
export class WireContainer extends TmBaseComponent {
    @InjectProperty()
    private declare readonly headerService: HeaderBarService;

    @InjectProperty()
    private declare readonly dashboardService: DashboardService;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    @property({ type: Number })
    public wireId = 0;

    @property({ type: Boolean, reflect: true })
    public hideHeader = false;

    @state()
    wire: Wire = new Wire();

    @state()
    loading = false;

    @InjectProperty()
    private declare readonly wiresService: WiresService;

    @state()
    viewAudit = false;

    @state()
    selectedAction = '';

    @state()
    showApproveOrRejectPrompt = false;

    @state()
    beneficiarySheetOpen = false;

    @state()
    wireBeneficiary = {} as WireBeneficiary;

    async firstUpdated() {
        if (!this.wireId) {
            const { params } = await this.navService.getRouteData<{ id: string }>();
            this.wireId = +params.id;
        }
        this.loading = true;

        try {
            this.wire = (await this.wiresService.getWirePaymentDetails(this.wireId)) as Wire;
            if (this.wire.beneficiary) {
                this.wireBeneficiary = new WireBeneficiary(this.wire.beneficiary);
            }
            this.configureHeader();
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    configureHeader() {
        if (this.hideHeader) return;
        this.headerService.configure({
            backAction: () => this.navService.navigate(`/wires`),
            title: 'Wire Details',
            menuItems: [{ title: 'Download', icon: actionIcon, action: () => alert('download') }],
        });
    }

    private async approveOrReject({
        items,
        paymentType = 'wire',
    }: {
        items: any[];
        paymentType: string;
    }) {
        try {
            this.loading = true;
            await this.dashboardService.approveOrRejectPayment(items, paymentType);
            this.dispatchEvent(
                new CustomEvent('approve-or-reject', { bubbles: true, composed: true })
            );
            await this.firstUpdated();
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    renderBlockingLoader() {
        if (!this.loading) return nothing;
        return html`<tm-blocking-loader></tm-blocking-loader>`;
    }

    renderBeneficiarySheet() {
        return html`<tm-bottom-sheet
            .open=${this.beneficiarySheetOpen}
            @close=${() => {
                this.beneficiarySheetOpen = false;
            }}
        >
            <p slot="header-center">Beneficiary Detail</p>
            <tm-body>
                <wire-beneficiary-read-only
                    .beneficiary=${this.wireBeneficiary}
                ></wire-beneficiary-read-only></tm-body
        ></tm-bottom-sheet>`;
    }

    renderDetails() {
        if (this.loading) return nothing;

        return html` <wire-read-only
            class="p-3 bg-[--primary-background]"
            .wire=${this.wire}
            @beneficiaryClick=${() => {
                this.beneficiarySheetOpen = true;
            }}
        ></wire-read-only>`;
    }

    renderApprovalInformation() {
        if (this.wire?.status !== 'Pending Approval') return nothing;
        return html`<treasury-pending-approval-template
            class="mb-2"
            .payment=${this.wire}
            .approvals=${this.wire.completedApprovals}
            .eligibleApproversFunction=${async () =>
                (await UsersService.fetchAvailableApprovers({
                    approvalEntity: 'wire',
                    productId: this.wire.wireId ?? undefined,
                    updatedBy: parseInt(this.wire.createdBy ?? ''),
                })) as unknown as string[]}
        ></treasury-pending-approval-template>`;
    }

    renderApproveOrRejectPrompt() {
        return html`<approve-or-reject-prompt
            .selectedAction=${this.selectedAction}
            .paymentType=${'wire'}
            .selectedApprovals=${[
                {
                    ...this.wire.toDto(),
                    selectedState: this.selectedAction === 'approve' ? 'Approved' : 'Rejected',
                },
            ]}
            .showApproveOrRejectPrompt=${this.showApproveOrRejectPrompt}
            @approve-or-reject=${(e: CustomEvent) => {
                const selectedState = this.selectedAction === 'approve' ? 'Approved' : 'Rejected';
                this.approveOrReject({
                    items: [{ ...this.wire.toDto(), selectedState }],
                    paymentType: 'wire',
                });
                this.showApproveOrRejectPrompt = false;
            }}
            @close=${() => {
                this.showApproveOrRejectPrompt = false;
            }}
        ></approve-or-reject-prompt>`;
    }

    renderButtonBar() {
        if (this.wire?.status !== 'Pending Approval') return nothing;
        const canApprove = this.wire?.permissions?.some(
            (p: { permission: string }) => p.permission === 'Approve'
        );
        const canReject = this.wire?.permissions?.some(
            (p: { permission: string }) => p.permission === 'Reject'
        );

        const approveButton = canApprove
            ? html`
                  <tm-button
                      success
                      size="large"
                      importance="primary"
                      class="w-full m-1"
                      @click=${() => {
                          this.selectedAction = 'approve';
                          this.showApproveOrRejectPrompt = true;
                      }}
                  >
                      Approve
                  </tm-button>
              `
            : nothing;
        const rejectButton = canReject
            ? html`
                  <tm-button
                      error
                      size="large"
                      importance="primary"
                      large
                      class="w-full m-1"
                      @click=${() => {
                          this.selectedAction = 'reject';
                          this.showApproveOrRejectPrompt = true;
                      }}
                  >
                      Reject
                  </tm-button>
              `
            : nothing;

        if (!canApprove && !canReject) return nothing;
        return html`<tm-footer direction="row"> ${rejectButton}${approveButton} </tm-footer>`;
    }

    render() {
        return [
            this.renderBlockingLoader(),
            this.renderBeneficiarySheet(),
            this.renderApproveOrRejectPrompt(),
            html`<tm-body .background=${false} .padding=${false}
                >${this.renderApprovalInformation()} ${this.renderDetails()}</tm-body
            >`,
            this.renderButtonBar(),
        ];
    }

    static get styles() {
        return [
            css`
                .detail-wrapper {
                    background-color: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: WireContainer;
    }
}
