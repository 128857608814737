import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { TmBaseComponent } from '../tm-base.component';
import './tm-button';
import { ButtonConfig } from './tm-footer.types';

export const tagName = 'tm-footer';
@customElement(tagName)
export class TmFooter extends TmBaseComponent {
    @property({ type: String, reflect: true })
    direction: 'row' | 'column' = 'column';

    @property({ type: Boolean, reflect: true })
    wrap = false;

    @property({ type: Boolean })
    alignCenter = true;

    @property({ type: Array })
    buttonConfig: ButtonConfig[] = [];

    @property({ type: Boolean })
    visible = true;

    renderButton(cfg: ButtonConfig) {
        const classStr = Array.isArray(cfg.classes) ? cfg.classes.join(' ') : cfg.classes;
        return html`
            <tm-button
                .importance=${cfg.importance ?? 'primary'}
                .size=${cfg.size ?? 'large'}
                ?success=${cfg.success}
                ?error=${cfg.error}
                ?disabled=${cfg.disabled}
                @click=${cfg.onClick}
                class=${classStr || 'w-full'}
            >
                ${cfg.text}
            </tm-button>
        `;
    }

    renderButtons() {
        if (this.buttonConfig.length === 0) {
            return nothing;
        }
        return this.buttonConfig.map(cfg => this.renderButton(cfg));
    }

    render() {
        if (!this.visible) return nothing;
        return html`
            <div class="card-footer w-full">
                <slot></slot>
                <div
                    class=${classMap({
                        'button-wrapper flex items-center p-3': true,
                        'justify-center': this.alignCenter,
                        'flex-wrap': this.wrap,
                        'flex-col': this.direction === 'column',
                    })}
                >
                    ${this.renderButtons()}
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    position: var(--tm-footer-position, sticky);
                    width: 100%;
                    bottom: 0;
                    margin-top: auto;
                    z-index: 10;
                }

                .card-footer {
                    box-shadow: rgba(0, 0, 0, 0.05) 0px -1px 7px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmFooter;
    }
}
