import {
    ExceptionCorrectionItemModelDto,
    ExceptionCorrectionModelDto,
} from '@treasury/api/channel';
import { CheckException } from '@treasury/domain/arp';
import dateFormatter from '@treasury/policy/lib/formatters/date.formatter';
import usd from '@treasury/policy/lib/formatters/usd.formatter';
import { TmBaseComponent } from '@treasury/presentation';
import { attachIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/forms/tm-form-row';
import '@treasury/presentation/components/forms/tm-slider';
import '@treasury/presentation/components/layout/tm-section';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-currency-field';
import '@treasury/presentation/components/tm-number-field';
import '@treasury/presentation/components/tm-text-field';
import { InjectProperty } from '@treasury/utils';
import { PropertyValueMap, TemplateResult, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../../../components/jhd-date-picker/jhd-date-picker';
import { HeaderBarService } from '../../../../services/jhd-header-bar.service';
import { checkExceptionStyleClasses } from '../../../styles/check-exception-styles';
import {
    CheckCorrection,
    CheckCorrectionLabel,
    CheckCorrectionType,
    CheckCorrectionTypeList,
} from '../../../types';
import { renderHeaderText } from '../header-text';
import './check-exception-comment-editor';
import './check-exception-select-from-list';

export const tagName = 'check-exception-correction';
@customElement(tagName)
export class CheckExceptionCorrection extends TmBaseComponent {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @property()
    checkException!: CheckException;

    @state()
    availableCorrections = CheckCorrectionTypeList;

    @state()
    corrections: Array<CheckCorrection> = [
        {
            type: null,
            previousValue: null,
            correctedValue: '',
            label: CheckCorrectionLabel.None,
        },
    ];

    @property()
    correctionPayloadSetter!: (correction: ExceptionCorrectionModelDto) => void;

    @property()
    reviewingCorrectionRequest!: boolean;

    @state()
    comment = '';

    attachment?: File;

    get formattedCorrection() {
        const exceptionCorrectionItems: Array<ExceptionCorrectionItemModelDto> = this.corrections
            .map(correction => ({
                exceptionCorrectionItem: this.getExceptionNumberFromType(correction.type),
                correctionValue: correction.correctedValue,
            }))
            .filter(item => item.exceptionCorrectionItem > 0);

        const formattedCorrection: ExceptionCorrectionModelDto = {
            arpExceptionDetailUniqueId: this.checkException.guid,
            comment: this.comment,
            exceptionCorrectionItems,
        };

        if (this.attachment) {
            formattedCorrection.attachment = this.attachment?.name ?? '';
            formattedCorrection.attachmentFileName = this.attachment?.name ?? '';
        }

        return formattedCorrection;
    }

    protected updated(
        _changedProperties: PropertyValueMap<CheckExceptionCorrection> | Map<PropertyKey, unknown>
    ): void {
        // whenever the component is advanced to the review step, it sends the formatted correction
        // back up to the parent component for the correction submit api request
        if (
            _changedProperties.has('reviewingCorrectionRequest') &&
            this.reviewingCorrectionRequest
        ) {
            this.correctionPayloadSetter(this.formattedCorrection);
        }
    }

    printValue(type: CheckCorrectionType | null, val: string) {
        switch (type) {
            case CheckCorrectionType.DatePosted:
                return dateFormatter(val);
            case CheckCorrectionType.Amount:
                return usd(Number.parseFloat(val));
            case CheckCorrectionType.CheckNumber:
            case CheckCorrectionType.Duplicate:
            case CheckCorrectionType.Payee:
            case CheckCorrectionType.AccountNumber:
            default:
                return val;
        }
    }

    private getExceptionNumberFromType(type: CheckCorrectionType | null): number {
        switch (type) {
            case CheckCorrectionType.AccountNumber:
                return 1;
            case CheckCorrectionType.DatePosted:
                return 2;
            case CheckCorrectionType.CheckNumber:
                return 3;
            case CheckCorrectionType.Amount:
                return 4;
            case CheckCorrectionType.Payee:
                return 5;
            case CheckCorrectionType.Duplicate:
                return 6;
            default:
                return 0;
        }
    }

    setCorrectedValue(type: CheckCorrectionType, val: string) {
        const correction = this.corrections.find(item => item.type === type);
        if (correction) {
            correction.correctedValue = val;
        }
    }

    addNewCorrection() {
        const corrections = [...this.corrections];
        if (corrections.length < CheckCorrectionTypeList.length) {
            corrections.push({
                type: null,
                previousValue: null,
                correctedValue: '',
                label: CheckCorrectionLabel.None,
            });
        }
        this.corrections = corrections;
    }

    private addCorrectionText(label: CheckCorrectionLabel) {
        if (label === CheckCorrectionLabel.None) return label;
        return `Correct ${label}`;
    }

    private capitalizeCorrectionLabel(label: CheckCorrectionLabel) {
        const firstLetter = label.slice(0, 1);
        return `${firstLetter.toUpperCase()}${label.slice(1)}`;
    }

    private getCorrectionLabelFromType(type: CheckCorrectionType) {
        switch (type) {
            case CheckCorrectionType.AccountNumber:
                return CheckCorrectionLabel.AccountNumber;
            case CheckCorrectionType.DatePosted:
                return CheckCorrectionLabel.DatePosted;
            case CheckCorrectionType.CheckNumber:
                return CheckCorrectionLabel.CheckNumber;
            case CheckCorrectionType.Amount:
                return CheckCorrectionLabel.Amount;
            case CheckCorrectionType.Payee:
                return CheckCorrectionLabel.Payee;
            case CheckCorrectionType.Duplicate:
                return CheckCorrectionLabel.None;
            default:
                return CheckCorrectionLabel.AccountNumber;
        }
    }

    private getExceptionValueFromCorrectionType(type: CheckCorrectionType) {
        switch (type) {
            case CheckCorrectionType.AccountNumber:
                return this.checkException.accountNumber;
            case CheckCorrectionType.DatePosted:
                return this.checkException.postedDate;
            case CheckCorrectionType.CheckNumber:
                return this.checkException.checkNumber;
            case CheckCorrectionType.Amount:
                return this.checkException.issuedAmount;
            case CheckCorrectionType.Payee:
                return this.checkException.issuedPayee;
            case CheckCorrectionType.Duplicate:
                return false;
            default:
                return null;
        }
    }

    renderCorrectionInputComponentFromType(correction: CheckCorrection): TemplateResult {
        const setCorrectedValue = this.setCorrectedValue.bind(this);
        const { type } = correction;
        switch (type) {
            case CheckCorrectionType.DatePosted:
                return html`<jhd-date-picker
                    .selectedDate=${correction.correctedValue}
                    @selection=${(e: CustomEvent<{ value: { startDate: string } }>) => {
                        setCorrectedValue(
                            CheckCorrectionType.DatePosted,
                            e.detail?.value?.startDate
                        );
                    }}
                ></jhd-date-picker>`;
            case CheckCorrectionType.Amount:
                return html`<tm-currency-field
                    .value=${correction.correctedValue}
                    @value-changed=${(e: CustomEvent<string>) =>
                        setCorrectedValue(CheckCorrectionType.Amount, e.detail)}
                ></tm-currency-field>`;
            case CheckCorrectionType.Duplicate:
                return html`${nothing}`;
            case CheckCorrectionType.CheckNumber:
                return html`<tm-number-field
                    .value=${correction.correctedValue}
                    @value-changed=${(e: CustomEvent<{ value: string }>) =>
                        setCorrectedValue(CheckCorrectionType.CheckNumber, e.detail.value)}
                ></tm-number-field>`;
            case CheckCorrectionType.AccountNumber:
                return html`<tm-text-field
                    .value=${correction.correctedValue}
                    @value-changed=${(e: CustomEvent<{ value: string }>) =>
                        setCorrectedValue(CheckCorrectionType.AccountNumber, e.detail.value)}
                ></tm-text-field>`;
            case CheckCorrectionType.Payee:
                return html`<tm-text-field
                    .value=${correction.correctedValue}
                    @value-changed=${(e: CustomEvent<{ value: string }>) =>
                        setCorrectedValue(CheckCorrectionType.Payee, e.detail.value)}
                ></tm-text-field>`;

            default:
                return html`${nothing}`;
        }
    }

    renderRequestCorrectionButton(correction: CheckCorrection, idx: number) {
        return html`
            <tm-form-row label="Items to Correct">
                <tm-slider
                    .header=${renderHeaderText(
                        'Items to Correct',
                        `Check ${this.checkException.checkNumber}`
                    )}
                    .value=${this.corrections[idx].type}
                >
                    <check-exception-select-from-list
                        .list=${this.availableCorrections.filter(
                            (ac: CheckCorrectionType) =>
                                ac === this.corrections[idx].type ||
                                !this.corrections.map((c: CheckCorrection) => c.type).includes(ac)
                        )}
                        .changeItem=${(item: CheckCorrectionType) => {
                            const editingCorrection = this.corrections[idx];
                            editingCorrection.type = item;
                            editingCorrection.label = this.getCorrectionLabelFromType(item);
                            editingCorrection.previousValue =
                                this.getExceptionValueFromCorrectionType(item);
                            this.corrections = [...this.corrections];
                        }}
                        .selectedItem=${this.corrections[idx].type}
                    ></check-exception-select-from-list>
                </tm-slider>
                ${correction.type && correction.label
                    ? html`<div
                          slot="summary"
                          class="flex justify-between py-4 items-center w-full h-full "
                      >
                          <span class="font-medium mr-1 text-sm"
                              >${this.addCorrectionText(correction.label)}</span
                          >
                          ${this.renderCorrectionInputComponentFromType(correction)}
                      </div>`
                    : nothing}
            </tm-form-row>
        `;
    }

    renderEditableCorrections() {
        const correctionList = this.corrections.map(this.renderRequestCorrectionButton, this);
        if (this.corrections[0].type && this.corrections.length < CheckCorrectionTypeList.length)
            correctionList.push(
                html`<div class="w-full h-14 flex items-center p-5">
                    <button @click=${this.addNewCorrection} class="add-correction">
                        + Add another correction
                    </button>
                </div> `
            );

        return correctionList;
    }

    renderCommentEditor() {
        const commentChanged = (e: CustomEvent<string>) => {
            this.comment = e.detail;
        };
        const attachmentChanged = (e: CustomEvent<File>) => {
            this.attachment = e.detail;
        };
        return html`<check-exception-comment-editor
            .commentText=${this.comment}
            @value-changed=${commentChanged}
            @attachment-changed=${attachmentChanged}
        ></check-exception-comment-editor>`;
    }

    renderReviewCorrections() {
        const corrections = html`${this.corrections.map(correction => {
            if (correction.type === CheckCorrectionType.Duplicate)
                return html`<div class="font-thin">${correction.type}</div>
                    <hr />`;
            return html`
                <!-- to do: convert to tm-form-row and tm-labeled-list -->
                <div>
                    <div class="font-thin">${this.capitalizeCorrectionLabel(correction.label)}</div>
                    <div class="font-bold">
                        ${this.printValue(correction.type, correction.previousValue)}
                    </div>
                    <div class="font-thin">${this.addCorrectionText(correction.label)}</div>
                    <div class="font-bold">
                        ${this.printValue(correction.type, correction.correctedValue)}
                    </div>
                </div>
                <hr />
            `;
        })}`;

        return html`
            <tm-section>
                <div class="p-6">
                    <div class="mb-4">
                        <div>
                            Account: ${this.checkException.accountNumber}, Check Number:
                            ${this.checkException.checkNumber}
                        </div>
                        <br />
                        <div class="font-bold">Items to Correct</div>
                        ${corrections}
                        <br />
                        <div>Comment</div>
                        <div>${this.comment}</div>
                    </div>
                    <div class="attachment-area flex items-center py-3">
                        <jhd-icon .icon=${attachIcon} class="mr-3"></jhd-icon>
                        <div>${this.attachment?.name ?? 'no attachment found'}</div>
                    </div>
                </div>
            </tm-section>
        `;
    }

    render() {
        if (this.reviewingCorrectionRequest) return this.renderReviewCorrections();
        return html`<tm-section>
            ${this.renderEditableCorrections()} ${this.renderCommentEditor()}
        </tm-section>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                }

                .attachment-area {
                    border-top: 1px solid var(--secondary-text-color);
                }

                .add-correction {
                    border: none;
                    color: var(--color-primary);
                }
            `,
            checkExceptionStyleClasses,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: CheckExceptionCorrection;
    }
}
