/* eslint-disable import/no-duplicates */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-loader';
import '@treasury/presentation/components/tm-table-header';
import { InjectProperty } from '@treasury/utils';
import '@vaadin/grid';
import { columnBodyRenderer, GridColumnBodyLitRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { filterTableResults } from '../../../utilities/table-result-filter';
import '../../components/jhd-status';
import { getFormattedStatus, getStatusColorToken } from '../../components/jhd-status';
import {
    TransferPaymentTableViewModel,
    TransferPaymentViewModel,
} from '../data/transfer-payment-table-view-model';
import { TransferPaymentDto } from '../types/transfer-payment-dto.type';

export const tagName = 'transfer-payments-table';
@customElement(tagName)
export class TransferPaymentsTable extends TmBaseComponent {
    @InjectProperty()
    private declare featureFlagService: FeatureFlagService;

    @property({ type: Array })
    transferPayments = [];

    @property()
    loading = false;

    @state()
    private fieldsToFilter = ['toAccount', 'status', 'amount', 'fromAccount', 'transferDate'];

    @state()
    private transferPaymentViewModels: TransferPaymentViewModel[] = [];

    @state()
    private filteredTransferPaymentViewModels: TransferPaymentViewModel[] = [];

    updated(changedProps: Map<string, unknown>) {
        if (changedProps.has('loading') && !this.loading) {
            this.buildTransferViewModels();
        }
    }

    buildTransferViewModels() {
        this.transferPaymentViewModels = this.transferPayments.map(
            (transferPayment: TransferPaymentDto) =>
                new TransferPaymentTableViewModel(transferPayment)
        );
        this.filteredTransferPaymentViewModels = this.transferPaymentViewModels;
    }

    transferClicked(transferPayment: TransferPaymentDto) {
        if (!transferPayment) return;

        const selectedTransfer = this.transferPayments.find(
            (tp: TransferPaymentDto) => tp.id === transferPayment.id
        );
        this.dispatchEvent(new CustomEvent('transfer-clicked', { detail: selectedTransfer }));
    }

    filterResults(e: CustomEvent) {
        this.filteredTransferPaymentViewModels = filterTableResults(
            e,
            this.transferPaymentViewModels,
            this.fieldsToFilter
        ) as TransferPaymentViewModel[];
    }

    renderStatusIconColumn(transferPayment: TransferPaymentViewModel) {
        return html`<jhd-status .status=${transferPayment.status}></jhd-status>`;
    }

    renderToAccountAndStatusColumn(transferPayment: TransferPaymentViewModel) {
        return html`<div class="flex -ml-4">
            <div class="flex flex-col">
                <span class="payment-name text-sm font-medium">${transferPayment.toAccount}</span>
                <small class="payment-transaction-id text-xs text-gray-500"
                    >From ${transferPayment.fromAccount}</small
                >
                <small
                    class="payment-status text-xs"
                    style=${`color: var(${getStatusColorToken(transferPayment.status)})`}
                    >${getFormattedStatus(transferPayment.status)}</small
                >
            </div>
        </div>`;
    }

    renderAmountAndFromAccountColumn(transferPayment: TransferPaymentViewModel) {
        return html`<div class="flex flex-col">
            <span class="payment-amount text-right text-sm font-medium"
                >${transferPayment.amount}</span
            >
            <small class="payment-transaction-id text-right text-xs text-gray-500"
                >${transferPayment.transferDate}</small
            >
        </div>`;
    }

    renderTable() {
        if (this.loading) return html`<tm-loader card></tm-loader>`;
        if (!this.transferPayments.length)
            return html`<div class="mx-auto w-full text-center mt-5 empty-message">
                No Transfers Found
            </div>`;
        return html` <vaadin-grid
            class="border-y border-[--border-color]"
            .items=${this.filteredTransferPaymentViewModels}
            @active-item-changed=${({ detail }: CustomEvent) => this.transferClicked(detail.value)}
        >
            <vaadin-grid-column
                flex-grow="0"
                width="3.5rem"
                ${columnBodyRenderer(
                    this
                        .renderStatusIconColumn as GridColumnBodyLitRenderer<TransferPaymentViewModel>,
                    []
                )}
            ></vaadin-grid-column>
            <vaadin-grid-column
                flex-grow="1"
                auto-width
                ${columnBodyRenderer(
                    this
                        .renderToAccountAndStatusColumn as GridColumnBodyLitRenderer<TransferPaymentViewModel>,
                    []
                )}
            ></vaadin-grid-column>
            <vaadin-grid-column
                flex-grow="0"
                ${columnBodyRenderer(
                    this
                        .renderAmountAndFromAccountColumn as GridColumnBodyLitRenderer<TransferPaymentViewModel>,
                    []
                )}
            ></vaadin-grid-column>
        </vaadin-grid>`;
    }

    render() {
        return html`<div class="pwa-table-wrapper">
            <tm-table-header
                .filterButton=${true}
                @filterButtonClick=${() =>
                    this.dispatchEvent(new CustomEvent('filter-transfer-payments'))}
                @filterStringChange=${(e: CustomEvent) => this.filterResults(e)}
                ><h2 class="font-medium">Transfer Activity</h2></tm-table-header
            >
            ${this.renderTable()}
        </div>`;
    }

    static get styles() {
        return [
            css`
                .payment-amount {
                    text-align: right;
                }
                .table-header,
                .empty-message {
                    color: var(--header-text-color);
                }
                button[disabled] {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
                vaadin-grid::part(first-column-cell) {
                    border-top: none;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TransferPaymentsTable;
    }
}
