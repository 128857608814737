/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    CompanyOptionModelCollectionDto,
    ErrorHttpContentDto,
    ProductFeatureAccountModelCollectionDto,
    SavePositivePayCompanyConfigurationRequestModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class PositivePayCompanyConfigurationClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * @description Supported options: * PositivePay.ACHFilterManagement.AllowManageAchFilters : "0"/"1", indicates whether ACH Filters are enabled * PositivePay.ACHFilterManagement.ApproversRequired :0 (aka "None"), 1, 2, 3 * PositivePay.AllowIssuedItemActivityReview : "0"/"1" * PositivePay.ShowAchExceptions : "0"/"1" * PositivePay.SubstituteProductConfig.UseSubstituteProduct : "1"/"0".  Controls whether to use the substitute product.  Only present if have a substitute product installed (Centrix) * PositivePay.WorkAchExceptions : "1"/"0". * PositivePay.WorkCheckExceptions : "1"/"0". * PositivePay.CheckException.AllowReturnReason : "1"/"0". * PositivePay.CheckException.AllowCorrectionRequest : "1"/"0".
     *
     * @tags PositivePayCompanyConfiguration
     * @name PositivePayCompanyConfigurationGetAllOptions
     * @summary Retrieve all product options for a company Positive Pay product
     * @request GET:/positivePayCompanyConfiguration/{companyUniqueGuid}/allOptions
     */
    positivePayCompanyConfigurationGetAllOptions = (
        companyUniqueGuid: string,
        params: RequestParams = {}
    ) =>
        this.http.request<CompanyOptionModelCollectionDto, ErrorHttpContentDto>({
            path: `/positivePayCompanyConfiguration/${companyUniqueGuid}/allOptions`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * @description Feature names: * ACHFilterManagement * WorkACHExceptions * WorkCheckExceptions
     *
     * @tags PositivePayCompanyConfiguration
     * @name PositivePayCompanyConfigurationGetProductFeatureAccounts
     * @summary Retrieve account configuration for the company Positive Pay product
     * @request GET:/positivePayCompanyConfiguration/{companyUniqueGuid}/productFeatureAccounts
     */
    positivePayCompanyConfigurationGetProductFeatureAccounts = (
        companyUniqueGuid: string,
        params: RequestParams = {}
    ) =>
        this.http.request<ProductFeatureAccountModelCollectionDto, ErrorHttpContentDto>({
            path: `/positivePayCompanyConfiguration/${companyUniqueGuid}/productFeatureAccounts`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * @description The following product options are supported for save: * PositivePay.ACHFilterManagement.AllowManageAchFilters : "0"/"1", indicates whether ACH Filters are enabled * PositivePay.ACHFilterManagement.ApproversRequired : 0 (aka "None"), 1, 2, 3 * PositivePay.AllowIssuedItemActivityReview : "0"/"1". * PositivePay.ShowAchExceptions : "0"/"1" * PositivePay.SubstituteProductConfig.UseSubstituteProduct : "1"/"0".  Controls whether to use the substitute product (if setup for and allowed by FI). * PositivePay.WorkAchExceptions : "1"/"0". * PositivePay.WorkCheckExceptions : "1"/"0". * PositivePay.CheckException.AllowReturnReason : "1"/"0". * PositivePay.CheckException.AllowCorrectionRequest : "1"/"0". For product account feature participations, the following features (feature names) are supported : * ACHFilterManagement * WorkAchExceptions * WorkCheckExceptions For account feature participations: * Participations should only be passed if company configuration allows.  Setting a company option which disallows participations will result in HTTP 400. Example : saving option PositivePay.ACHFilterManagement.AllowManageAchFilters = "0" means should not pass participations for ACHFilterManagement. * If no accounts are selected : should submit entry with feature name but no accounts OR having all participation flags set to false. * Only the AccountId has to be set to identify the participating account.  Setting the other account properties like CifNumber is unnecessary as those will be ignored for save. * Not submitting an account for participation (when account is already participating) will be handled as if the account were passed with participation flag off.
     *
     * @tags PositivePayCompanyConfiguration
     * @name PositivePayCompanyConfigurationSaveProductConfiguration
     * @summary Saves product configuration for company Positive Pay product
     * @request POST:/positivePayCompanyConfiguration/{companyUniqueGuid}
     */
    positivePayCompanyConfigurationSaveProductConfiguration = (
        companyUniqueGuid: string,
        request: SavePositivePayCompanyConfigurationRequestModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, ErrorHttpContentDto>({
            path: `/positivePayCompanyConfiguration/${companyUniqueGuid}`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
