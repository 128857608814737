import { UserClaimModelDto } from '@treasury/api/channel';
import { getDisplayValue } from '@treasury/domain/users/helpers/get-display-value';
import { isEdited } from '@treasury/domain/users/helpers/is-edited';

export class UserClaimVm {
    constructor(readonly value: UserClaimModelDto) {}

    get name() {
        return this.value.claimName;
    }

    get hasClaim() {
        return getDisplayValue(this.value.hasUserClaim, this.value.hasPendingUserClaim);
    }

    get displayHasClaim() {
        return this.hasClaim ? 'Yes' : 'No';
    }

    get edited() {
        return isEdited(this.value.hasUserClaim, this.value.hasPendingUserClaim);
    }
}
