import { TransferTemplateDto } from '../types/transfer-template-dto.type';

export class TransferTemplateTableViewModel {
    constructor(dto: TransferTemplateDto) {
        this.templateName = dto.templateName;
        this.fromAccount = dto.fromAccount.accountDisplayLabel;
        this.toAccount = dto.toAccount.accountDisplayLabel;
        this.amount = dto.amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        this.id = dto.id;
        this.transferType = dto.transferType;
    }

    templateName: string;

    fromAccount: string;

    toAccount: string;

    amount: string;

    id: number;

    transferType: string;
}
