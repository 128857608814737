import { AchPaymentModelDto, AchRecipientModelDto } from '@treasury/api/channel';
import { NavigationService } from '@treasury/core/navigation';
import { AchPayment, AchService } from '@treasury/domain/ach';
import { AchPaymentsServices } from '@treasury/domain/channel/services/ach';
import { DashboardService } from '@treasury/domain/channel/services/dashboard/dashboard-service';
import { UsersService } from '@treasury/domain/channel/services/users/users-service';
import { LabeledList, TmBaseComponent } from '@treasury/presentation';
import { actionIcon, chevronDownIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-labeled-list';
import { InjectProperty } from '@treasury/utils';
import { GridActiveItemChangedEvent } from '@vaadin/grid';
import {
    GridColumnBodyLitRenderer,
    columnBodyRenderer,
    gridRowDetailsRenderer,
} from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { format } from 'date-fns';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/jhd-data-field';
import '../../components/jhd-status';
import '../../components/templates/treasury-pending-approval.template';
import '../../pending-approvals/partials/approve-or-reject-prompt';
import { HeaderBarService } from '../../services/jhd-header-bar.service';

export const tagName = 'ach-payment-container';
@customElement(tagName)
export class AchPaymentContainer extends TmBaseComponent {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare dashboardService: DashboardService;

    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare achService: AchService;

    @property({ type: Boolean, reflect: true })
    public hideHeader = false;

    @state()
    private loading = true;

    @state()
    private achPayment = {} as AchPayment;

    @state()
    private recipients = [] as AchRecipientModelDto[];

    @state()
    public paymentId = '';

    @state()
    private auditOpen = false;

    @state()
    private availableApprovers = [] as string[];

    @state()
    private completedApprovals = [] as { dateApproved: string; name: string }[];

    @state()
    private openedRecipients: AchRecipientModelDto[] = [];

    @state()
    private selectedAction = '';

    @state()
    private showApproveOrRejectPrompt = false;

    protected async firstUpdated() {
        this.paymentId = this.paymentId || window.location.pathname.split('/').pop() || '';
        this.loading = true;
        try {
            const response = await this.achService.getPaymentById(this.paymentId);
            this.achPayment = response;
            this.recipients = response.recipients;
            this.configureHeader();
            const approvals = await UsersService.fetchAvailableAndCompletedApprovers({
                approvalEntity: 'achPayment',
                productId: this.achPayment.id,
                updatedBy: parseInt(this.achPayment.updatedBy || '0'),
            });
            this.availableApprovers = approvals.availableApprovers;
            this.completedApprovals = approvals.completedApprovals;
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    private configureHeader() {
        if (this.hideHeader) return;
        this.headerService.configure({
            backAction: () => this.navService.navigate(`/ach`),
            title: 'ACH Payment Detail',
            menuItems: [{ title: 'Download', icon: actionIcon, action: () => alert('download') }],
        });
    }

    private toggleAudit() {
        this.auditOpen = !this.auditOpen;
    }

    private async approveOrReject({
        items,
        paymentType = 'ach',
    }: {
        items: any[];
        paymentType: string;
    }) {
        try {
            this.loading = true;
            await this.dashboardService.approveOrRejectPayment(items, paymentType);
            this.dispatchEvent(
                new CustomEvent('approve-or-reject', { bubbles: true, composed: true })
            );
            await this.firstUpdated();
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    private get renderStatusIcon() {
        return html`<jhd-status .status=${this.achPayment.status}></jhd-status>`;
    }

    renderApprovalInformation() {
        if (this.achPayment.status !== 'Pending Approval') return nothing;
        return html`<treasury-pending-approval-template
            class="mb-2 border-b-8"
            .payment=${this.achPayment}
            .approvals=${this.completedApprovals}
            .eligibleApprovers=${this.availableApprovers}
        ></treasury-pending-approval-template>`;
    }

    renderPaymentHeader() {
        return html`<div class="detail-header flex flex-row justify-between items-start w-full">
                <div class="detail-amount flex flex-col">
                    <div class="text-lg font-medium mb-1">${this.achPayment.name}</div>
                </div>
                <div class="flex flex-col items-end">
                    <span class="detail-status flex items-center my-1 text-xs"
                        >${this.renderStatusIcon}&nbsp;${this.achPayment.status}</span
                    ><span class="text-sm font-medium amount"
                        >${this.achPayment.formattedCreditAmount}&nbsp;<span
                            class="credit-debit-notation text-xs font-normal"
                            >CR</span
                        ></span
                    ><span class="text-sm font-medium amount"
                        >${this.achPayment.formattedDebitAmount}&nbsp;<span
                            class="credit-debit-notation text-xs font-normal"
                            >DR</span
                        ></span
                    >
                </div>
            </div>
            <div class="detail-content py-4 border-b border-dashed border-[--border-color]">
                <tm-labeled-list
                    .list=${new LabeledList(
                        {
                            companyName: this.achPayment.companyName,
                            companyId: this.achPayment.companyId,
                            secCode: this.achPayment.secCode,
                            frequency: this.achPayment.frequency.summary,
                            effectiveDate: format(
                                new Date(this.achPayment.effectiveDate),
                                'MM/dd/yyyy'
                            ),
                            transactionId: this.achPayment.transactionId,
                            offsetAccount: this.achPayment.offsetAccountPaymentName,
                            entryDescription: this.achPayment.entryDescription,
                            discretionaryData: this.achPayment.discretionaryData,
                            formattedRestricted: this.achPayment.formattedRestricted,
                        },
                        [
                            'companyName',
                            'companyId',
                            'secCode',
                            'frequency',
                            'effectiveDate',
                            'transactionId',
                            'offsetAccount',
                            'entryDescription',
                            'discretionaryData',
                            'formattedRestricted',
                        ],
                        {
                            companyName: 'ACH Company',
                            companyId: 'ACH Company ID',
                            secCode: 'SEC Code',
                            formattedRestricted: 'Restrict Payment',
                        }
                    )}
                >
                </tm-labeled-list>
            </div>`;
    }

    // TODO: remove DTO usage in favor of AchPayment entity (DTO usage may come from approvals -> view details)
    renderRecipientNameColumn(recipient: AchRecipientModelDto | AchPayment | any) {
        return html`<div>
            <span class="text-sm">${recipient.recipientName ?? recipient.name}</span>
        </div>`;
    }

    renderAmountColumn(recipient: AchRecipientModelDto) {
        const amountFormatted = recipient.amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return html`<div class="flex-shrink-0">
            <span class="text-sm font-medium"
                >${amountFormatted}
                <span class="font-normal text-xs">${recipient.transactionType}</span></span
            ><span class="recipient-chevron">${chevronDownIcon}</span>
        </div>`;
    }

    renderRecipientDetails(recipient: AchRecipientModelDto) {
        const amountFormatted = recipient.amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        const addendaFormatted = recipient.addenda ? recipient.addenda : 'None';
        return html`<div>
            <jhd-data-field label="ID Number" .value=${recipient.id}></jhd-data-field>
            <jhd-data-field
                label="Account Number"
                .value=${recipient.accountNumber}
            ></jhd-data-field>
            <jhd-data-field label="Account Type" .value=${recipient.accountType}></jhd-data-field>
            <jhd-data-field
                label="Routing Number"
                .value=${recipient.routingNumber}
            ></jhd-data-field>
            <jhd-data-field
                label="Credit/Debit"
                .value=${recipient.transactionType}
            ></jhd-data-field>
            <jhd-data-field label="Amount" .value=${amountFormatted}></jhd-data-field>
            <jhd-data-field label="Addenda" value=${addendaFormatted}></jhd-data-field>
        </div>`;
    }

    renderPaymentRecipients() {
        return html`<div class="recipients-table py-4">
            <h4 class="recipients-table-header font-medium pb-2">Payment Recipients</h4>
            <vaadin-grid
                class="min-h-0"
                .items=${this.recipients}
                .detailsOpenedItems=${this.openedRecipients}
                @active-item-changed=${(
                    event: GridActiveItemChangedEvent<AchRecipientModelDto>
                ) => {
                    const recipient = event.detail.value;
                    if (recipient) {
                        this.openedRecipients = [recipient];
                    } else {
                        this.openedRecipients = [];
                    }
                }}
                ${gridRowDetailsRenderer<AchRecipientModelDto>((recipient: AchRecipientModelDto) =>
                    this.renderRecipientDetails(recipient)
                )}
                ><vaadin-grid-column
                    ${columnBodyRenderer(
                        this
                            .renderRecipientNameColumn as GridColumnBodyLitRenderer<AchRecipientModelDto>,
                        []
                    )}
                ></vaadin-grid-column
                ><vaadin-grid-column
                    ${columnBodyRenderer(
                        this.renderAmountColumn as GridColumnBodyLitRenderer<AchRecipientModelDto>,
                        []
                    )}
                    text-align="end"
                ></vaadin-grid-column
            ></vaadin-grid>
        </div>`;
    }

    renderAuditData() {
        if (!this.auditOpen) return nothing;
        return html`<div class="pb-2">
            <div class="pb-2 font-medium">Audit</div>
            <div class="max-h-36 overflow-y-auto font-normal text-sm">${this.achPayment.audit}</div>
        </div>`;
    }

    renderAudit() {
        if (!this.achPayment.audit) return nothing;
        const auditButtonText = this.auditOpen ? 'Hide Audit' : 'View Audit';
        return html`
            ${this.renderAuditData()}
            <tm-button class="mt-2" @click=${() => this.toggleAudit()}>
                ${auditButtonText}
            </tm-button>
        `;
    }

    renderApproveOrRejectPrompt() {
        return html`<approve-or-reject-prompt
            .paymentType=${'ACH'}
            .selectedAction=${this.selectedAction}
            .selectedApprovals=${[this.achPayment]}
            .showApproveOrRejectPrompt=${this.showApproveOrRejectPrompt}
            @approve-or-reject=${(e: CustomEvent) => {
                const selectedState = this.selectedAction === 'approve' ? 'Approved' : 'Rejected';
                this.approveOrReject({
                    items: [{ ...this.achPayment, selectedState }],
                    paymentType: 'ach',
                });
                this.showApproveOrRejectPrompt = false;
            }}
            @close=${() => {
                this.showApproveOrRejectPrompt = false;
            }}
        ></approve-or-reject-prompt>`;
    }

    renderActionButtons() {
        if (this.achPayment.status !== 'Pending Approval') return nothing;
        const canApprove = this.achPayment.permissions.some(
            (p: { permission: string }) => p.permission === 'Approve'
        );
        const canReject = this.achPayment.permissions.some(
            (p: { permission: string }) => p.permission === 'RejectPayment'
        );

        const approveButton = canApprove
            ? html`
                  <tm-button
                      success
                      size="large"
                      importance="primary"
                      class="w-full m-1"
                      @click=${() => {
                          this.selectedAction = 'approve';
                          this.showApproveOrRejectPrompt = true;
                      }}
                  >
                      Approve
                  </tm-button>
              `
            : nothing;
        const rejectButton = canReject
            ? html`
                  <tm-button
                      error
                      size="large"
                      importance="primary"
                      class="w-full m-1"
                      @click=${() => {
                          this.selectedAction = 'reject';
                          this.showApproveOrRejectPrompt = true;
                      }}
                      >Reject
                  </tm-button>
              `
            : nothing;

        if (!canApprove && !canReject) return nothing;
        return html`<tm-footer direction="row"> ${rejectButton}${approveButton} </tm-footer>`;
    }

    render() {
        if (this.loading) return html`<tm-blocking-loader></tm-blocking-loader>`;
        return html`${this.renderApproveOrRejectPrompt()}${this.renderApprovalInformation()}
            <div class="detail-wrapper pl-6 pr-4 py-3">
                ${this.renderPaymentHeader()} ${this.renderPaymentRecipients()}${this.renderAudit()}
            </div>
            ${this.renderActionButtons()}`;
    }

    static get styles() {
        return [
            css`
                :host {
                    height: 100vh;
                }
                jha-data-field {
                    margin: 0;
                }
                vaadin-grid-cell-content {
                    padding: 0;
                }
                vaadin-grid::part(cell) {
                    border-top: none;
                    border-bottom: 1px solid var(--border-color);
                }
                .detail-wrapper {
                    background: var(--primary-background);
                }
                .detail-amount,
                .detail-status,
                .recipients-table-header,
                .amount {
                    color: var(--header-text-color);
                }
                .credit-debit-notation {
                    color: var(--secondary-text-color);
                }
                .recipient-chevron svg {
                    display: inline-block;
                }
                .max-h-36 {
                    max-height: 9rem;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchPaymentContainer;
    }
}
