/* eslint-disable @treasury/no-date */
import { clone } from '@treasury/utils/functions';
import { nullTransferTransactionDetails } from './transfer-transaction-details.dto';

export class TransferTransactionDetails {
    // eslint-disable-next-line no-useless-constructor
    constructor(protected dto = clone(nullTransferTransactionDetails)) {}

    public get amount() {
        return this.dto.amount || 0;
    }

    public set amount(amount) {
        this.dto.amount = amount;
    }

    public get fromAccount() {
        return this.dto.fromAccount;
    }

    public set fromAccount(fromAccount) {
        this.dto.fromAccount = fromAccount;
    }

    public get toAccount() {
        return this.dto.toAccount;
    }

    public set toAccount(toAccount) {
        this.dto.toAccount = toAccount;
    }

    public get frequency() {
        return this.dto.frequency;
    }

    public set frequency(frequency) {
        this.dto.frequency = frequency;
    }

    public get memo() {
        return this.dto.memo;
    }

    public set memo(memo) {
        this.dto.memo = memo;
    }

    public get transferDate() {
        return this.dto.transferDate;
    }

    public set transferDate(transferDate) {
        this.dto.transferDate = transferDate;
    }

    public toDto() {
        return this.dto;
    }

    public clone() {
        return new TransferTransactionDetails(clone(this.dto));
    }
}
