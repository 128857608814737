import { NavigationService } from '@treasury/core/navigation';
import { MessagesService } from '@treasury/domain/channel/services';
import { TmBaseComponent } from '@treasury/presentation';
import { actionIcon, circlePlusIcon, refreshIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-loader';
import '@treasury/presentation/components/tm-table-header';
import '@treasury/presentation/components/tm-tabs';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/jhd-route-action-item';
import '../../dashboard/components/cutoff-times-tooltip';
import '../../dashboard/components/notifications-tooltip';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import { MenuItem } from '../../services/types/jhd-header-bar.type';
import '../partials/message-detail';
import '../partials/messages-table';
import type { Message } from '../types/message.types';

export const tagName = 'message-center-container';
@customElement(tagName)
export class MessageCenterContainer extends TmBaseComponent {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare navService: NavigationService;

    @state()
    private showCutoffTooltip = false;

    @state()
    private showNotificationsTooltip = false;

    @InjectProperty()
    private declare readonly service: MessagesService;

    @state()
    private loading = false;

    @state()
    private creatingMessage = false;

    @state()
    private loadingMessageDetails = false;

    @property({ type: Array })
    private menuItems = this.createMenuItems();

    @state()
    private tabs = [
        { label: `Inbox`, id: 2 },
        {
            label: `Sent`,
            id: 3,
        },
        {
            label: `Archived`,
            id: 4,
        },
    ];

    @state()
    private activeTab = 2;

    @state()
    private messages: Message[] = [];

    @state()
    private filteredMessages: Message[] = [];

    @state()
    private messageDetailSheetIsOpen = false;

    @state()
    private selectedMessage: Message = {} as Message;

    @state()
    private messageDetails: Message[] = [];

    @state()
    downloading = false;

    @state()
    filterString = '';

    firstUpdated() {
        // eslint-disable-next-line no-restricted-globals
        this.showNotificationsTooltip = screen.width > 1100;
        this.headerService.configure({ title: 'Message Center', menuItems: this.menuItems });
    }

    private createMenuItems(): MenuItem[] {
        return [
            {
                title: 'Notifications',
                icon: html`<notifications-tooltip
                    .showNotificationsTooltip=${this.showNotificationsTooltip}
                ></notifications-tooltip>`,
                action: () => {
                    // eslint-disable-next-line no-unused-expressions
                    this.showNotificationsTooltip
                        ? null
                        : this.navService.navigate(`/notifications`);
                },
            },
            {
                title: 'Cutoff Times',
                icon: html`<cutoff-times-tooltip
                    .showCutoffTooltip=${this.showCutoffTooltip}
                ></cutoff-times-tooltip>`,
                action: () => {
                    this.showCutoffTooltip = true;
                },
            },
        ];
    }

    openComposeMessageSheet() {
        this.navService.navigate('/message-center/compose');
    }

    async messageSelected(message: Message) {
        this.selectedMessage = message;
        this.loadingMessageDetails = true;
        this.messageDetails = (await this.service.getMessage(message.id)) as unknown as Message[];
        this.messageDetailSheetIsOpen = true;
        this.loadingMessageDetails = false;
        this.switchMessageType(this.activeTab);
    }

    async archiveMessage() {
        this.loading = true;
        await this.service.archiveMessage(this.selectedMessage as any);
        this.messages = (await this.service.fetchMessages(this.activeTab)) as unknown as Message[];
        this.loading = false;
        this.messageDetailSheetIsOpen = false;
        this.switchMessageType(this.activeTab);
    }

    async downloadAttachment(detail: any) {
        this.downloading = true;
        await this.service.downloadAttachment(detail.attachmentId, detail.attachmentName);
        this.downloading = false;
    }

    async sendMessage(e: CustomEvent) {
        const message = e.detail;
        this.creatingMessage = true;
        await this.service.sendMessage(message);
        this.messages = (await this.service.fetchMessages(this.activeTab)) as unknown as Message[];
        this.creatingMessage = false;
        this.messageDetailSheetIsOpen = false;
        this.navService.navigate(`/message-center`);
    }

    async switchMessageType(messageType: number) {
        this.loading = true;
        this.filterString = '';
        this.messages = (await this.service.fetchMessages(messageType)) as unknown as Message[];
        this.filteredMessages = this.messages;
        this.loading = false;
    }

    filterResults(e: CustomEvent) {
        const { value } = e.detail;
        this.filterString = value;
        this.filteredMessages = this.messages.filter(
            (message: Message) =>
                message.subject.toLowerCase().includes(value.toLowerCase()) ||
                message.messageId.includes(value) ||
                message.sentDate.includes(value)
        );
    }

    renderMessagesTable() {
        if (this.loading) return html`<tm-loader card></tm-loader>`;
        return html`<messages-table
            .messages=${this.filteredMessages}
            @message-selected=${(event: CustomEvent) => {
                this.messageSelected(event.detail);
            }}
        ></messages-table>`;
    }

    renderBlockingLoader() {
        if (!this.creatingMessage && !this.loadingMessageDetails) return nothing;
        return html`<tm-blocking-loader></tm-blocking-loader>`;
    }

    renderTableHeader() {
        const tabLabel = this.tabs.find(tab => tab.id === this.activeTab)?.label;
        return html` <tm-table-header
            .placeholder=${tabLabel ? `Search ${tabLabel}` : 'Search'}
            .filterString=${this.filterString}
            @filterStringChange=${(e: CustomEvent) => this.filterResults(e)}
            ><button slot="header-end" @click=${() => this.switchMessageType(this.activeTab)}>
                ${refreshIcon}
            </button></tm-table-header
        >`;
    }

    render() {
        return html`
            ${this.renderBlockingLoader()}
            <jhd-route-action-item
                .action=${'New Message'}
                .icon=${circlePlusIcon}
                @click=${() => this.openComposeMessageSheet()}
            ></jhd-route-action-item>
            <div class="pwa-table-wrapper">
                <tm-tabs
                    .tabs=${this.tabs}
                    .activeTab=${this.activeTab}
                    @switchTab=${(event: CustomEvent) => {
                        this.activeTab = Number(event.detail.activeTab);
                        this.switchMessageType(this.activeTab);
                    }}
                ></tm-tabs>
                ${this.renderTableHeader()} ${this.renderMessagesTable()}
            </div>
            <tm-bottom-sheet
                .open=${this.messageDetailSheetIsOpen}
                @close=${() => {
                    this.messageDetailSheetIsOpen = false;
                }}
            >
                <div
                    slot="header-center"
                    class="flex flex-col items-center font-medium leading-tight"
                >
                    ${this.selectedMessage?.subject}
                    <div class="font-normal text-xs">${this.selectedMessage?.messageId}</div>
                </div>
                <div slot="header-right" id="detail-action">${actionIcon}</div>
                <message-detail
                    .messages=${this.messageDetails}
                    @archive-message=${() => this.archiveMessage()}
                    @download-attachment=${({ detail }: CustomEvent) =>
                        this.downloadAttachment(detail)}
                    .loading=${this.loading}
                    .downloading=${this.downloading}
                    @send-message=${(e: CustomEvent) => this.sendMessage(e)}
                ></message-detail>
            </tm-bottom-sheet>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
                .header-icon path {
                    fill: var(--nav-text-color);
                }
                h4 {
                    color: var(--header-text-color);
                }
                .card-content {
                    width: 100%;
                }
                .tabs {
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                }
                .tabs > ul {
                    display: flex;
                    align-items: center;
                }
                .tabs > ul > li {
                    margin-right: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    cursor: pointer;
                }

                messages-table {
                    margin-bottom: 50px;
                }
                path {
                    fill: var(--omega-primary);
                }
                .detail-action > path {
                    fill: var(--nav-text-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: MessageCenterContainer;
    }
}
