import { AchExceptionDetailDataModelDto } from '@treasury/api/channel';
import usd from '@treasury/policy/lib/formatters/usd.formatter';
import { format } from 'date-fns';

export class AchExceptionActivityVm {
    constructor(public exception: AchExceptionDetailDataModelDto) {}

    get decisionBy() {
        return this.exception.decisionBy;
    }

    get decisionDate() {
        if (!this.exception.decisionDate) return '';
        return format(new Date(this.exception.decisionDate), 'Pp');
    }

    get decision() {
        return this.exception.achExceptionDecisionStatusId;
    }

    get amount() {
        return usd(this.exception.amount ?? 0);
    }

    get achCompanyName() {
        return this.exception.achCompanyName;
    }
}
