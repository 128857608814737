import { CurrencyModelDto } from '@treasury/api/channel';
import { DiContainer, Injectable } from '@treasury/utils/dependency-injection';
import { currenciesList } from './currencies-list';

function createCurrencyModel(currencyCode: string, name: string): CurrencyModelDto {
    return { id: 0, updatedDate: '', currencyCode, name };
}

@Injectable()
export class CurrenciesService {
    getAll() {
        return Promise.resolve(
            currenciesList.map(([currencyCode, name]) => createCurrencyModel(currencyCode, name))
        );
    }

    static async getInstance() {
        const container = await DiContainer.getInstance();
        return container.get(CurrenciesService);
    }
}
