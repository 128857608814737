import {
    AddBillPayPaymentRequestModelDto,
    DayOfWeekDto,
    PaymentFrequencyDto,
    PaymentRecurrenceModelDto,
} from '@treasury/api/channel';
import {
    Frequency,
    FrequencyType,
    WeeklyFrequency,
} from '@treasury/presentation/components/tm-frequency.types';
import { format } from 'date-fns';
import { BillPayWorkflowData } from '../containers/business-bill-pay-workflow-container';

export const getDayOfWeekString = (day: DayOfWeekDto): string => {
    switch (day) {
        case 0:
            return 'Sunday';
        case 1:
            return 'Monday';
        case 2:
            return 'Tuesday';
        case 3:
            return 'Wednesday';
        case 4:
            return 'Thursday';
        case 5:
            return 'Friday';
        case 6:
            return 'Saturday';
        default:
            throw new Error('Invalid day of week');
    }
};

export const getPaymentFrequencyText = (frequency: PaymentFrequencyDto): string => {
    switch (frequency) {
        case PaymentFrequencyDto.Once:
            return 'One time';
        case PaymentFrequencyDto.Weekly:
            return 'Weekly';
        case PaymentFrequencyDto.EveryOtherWeek:
            return 'Every other week';
        case PaymentFrequencyDto.TwiceMonthly:
            return 'Twice a month';
        case PaymentFrequencyDto.Monthly:
            return 'Monthly';
        case PaymentFrequencyDto.Every3Months:
            return 'Every 3 months';
        case PaymentFrequencyDto.Every6Months:
            return 'Every 6 months';
        case PaymentFrequencyDto.Annual:
            return 'Annually';
        default:
            throw new Error('Invalid frequency type');
    }
};

export const getPaymentRecurrenceText = (recurrence: PaymentRecurrenceModelDto): string => {
    if (!recurrence) return 'One time';
    if (!recurrence.startProcessDate) return 'Ongoing';
    if (!recurrence.expirationDate) return 'Ongoing';
    let frequency = '';
    let dayOfWeek = '';
    let startOn = '';
    let endOn = '';

    if (recurrence.isFinite) {
        endOn = `Ends on ${format(new Date(recurrence.expirationDate), 'P')}`;
    }
    if (recurrence.startProcessDate) {
        startOn = `Starts on ${format(new Date(recurrence.startProcessDate), 'P')}`;
    }
    if (recurrence.frequency) {
        frequency = `${getPaymentFrequencyText(recurrence.frequency)}`;
    }
    if (recurrence.dayOfWeek) {
        dayOfWeek = `Every ${getDayOfWeekString(recurrence.dayOfWeek)}`;
    }
    return `${frequency} ${dayOfWeek} ${startOn} ${endOn}`.trim();
};

export const getPaymentFrequencyDto = (frequency: Frequency): PaymentFrequencyDto => {
    switch (frequency.type) {
        case FrequencyType.OneTime:
            return PaymentFrequencyDto.Once;
        case FrequencyType.Weekly:
            return PaymentFrequencyDto.Weekly;
        case FrequencyType.EveryTwoWeeks:
            return PaymentFrequencyDto.EveryOtherWeek;
        case FrequencyType.TwiceAMonth:
            return PaymentFrequencyDto.TwiceMonthly;
        case FrequencyType.Monthly:
            return PaymentFrequencyDto.Monthly;
        case FrequencyType.Quarterly:
            return PaymentFrequencyDto.Every3Months;
        case FrequencyType.EverySixMonths:
            return PaymentFrequencyDto.Every6Months;
        case FrequencyType.Yearly:
            return PaymentFrequencyDto.Annual;
        default:
            throw new Error('Invalid frequency type');
    }
};

export const getRecurrenceDto = (frequency: Frequency | undefined): PaymentRecurrenceModelDto => {
    if (!frequency) {
        return undefined as unknown as PaymentRecurrenceModelDto;
    }
    if (frequency.type === FrequencyType.OneTime) {
        return undefined as unknown as PaymentRecurrenceModelDto;
    }
    return {
        isFinite: !!frequency.endDate,
        dayOfWeek: (frequency as WeeklyFrequency).repeatOn
            ? (frequency as WeeklyFrequency).repeatOn
            : undefined,
        startProcessDate: format(frequency.startDate, 'P'),
        expirationDate: frequency.endDate ? format(frequency.endDate, 'P') : undefined,
        frequency: getPaymentFrequencyDto(frequency),
    } as unknown as PaymentRecurrenceModelDto;
};

export const transformBillPayFormData = (
    formData: BillPayWorkflowData
): AddBillPayPaymentRequestModelDto => ({
    payeeId: formData.payee.id,
    payFromAccountId: formData.fromAccount.id,
    amount: formData.amount,
    processDate: format(formData.frequency?.startDate ?? new Date(), 'P'),
    recurrence: getRecurrenceDto(formData.frequency),
    comment: formData.comment,
    overrideErrors: false,
});
