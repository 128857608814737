export const achTemplateStatusItems = [
    {
        label: 'All',
        value: 'All',
    },
    {
        label: 'Pending Approval',
        value: 'Pending Approval',
    },
    {
        label: 'Approval Rejected',
        value: 'Approval Rejected',
    },
    {
        label: 'Ready',
        value: 'Ready',
    },
];
