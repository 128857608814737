import { CompanyAccountModelDto, WireCompanyModelDto, WireModelDto } from '@treasury/api/channel';
import { NavigationService } from '@treasury/core/navigation';
import { EntitlementsService } from '@treasury/domain/channel/services';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { WiresService } from '@treasury/domain/wires';
import { NotificationService, TmContainer } from '@treasury/presentation';
import { helpIcon, templateIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-tabs';
import { InjectProperty } from '@treasury/utils';
import { css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../../components/jhd-route-action-item';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import './partials/wire-creation-options-sheet';
import './partials/wire-payments-filter-sheet';
import './partials/wire-payments-table';
import './partials/wire-recurring-payments-filter-sheet';

export const tagName = 'wires-container';
@customElement(tagName)
export class WiresContainer extends TmContainer {
    @InjectProperty()
    private declare readonly headerService: HeaderBarService;

    @InjectProperty()
    private declare readonly wireService: WiresService;

    @InjectProperty()
    private declare readonly featureFlagService: FeatureFlagService;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @state()
    wirePayments: WireModelDto[] = [];

    @state()
    domesticEntitlement = false;

    @state()
    internationalEntitlement = false;

    @state()
    bottomSheetOpen = false;

    @state()
    private filterSheetOpen = false;

    @state()
    private activeTab = 'One-Time';

    @state()
    private tabs = [
        { label: 'One Time', id: 'One-Time' },
        { label: 'Recurring', id: 'Recurring' },
    ];

    @state()
    private wireCompanies: Array<WireCompanyModelDto> = [];

    @state()
    private debitWireAccounts: Array<CompanyAccountModelDto> = [];

    private entitlementsService = new EntitlementsService();

    async firstUpdated() {
        this.headerService.configure({
            title: 'Wires',
            menuItems: [{ title: 'Help', icon: helpIcon, action: () => alert('TODO') }],
        });
        await this.tryFetch(
            () =>
                Promise.all([
                    this.entitlementsService.hasEntitlement('Wire, Domestic, Create Wire'),
                    this.entitlementsService.hasEntitlement('Wire, International, Create Wire'),
                ]),
            resp => {
                [this.domesticEntitlement, this.internationalEntitlement] = resp;
            },
            (err: any) => {
                this.notificationService.renderError(err);
            }
        );

        const accounts = await Promise.all([
            this.wireService.getWireCompanies(),
            this.wireService.fetchTransferAccounts(),
        ]);
        [this.wireCompanies, this.debitWireAccounts] = accounts;
    }

    async fetchWirePayments(filters?: any) {
        this.loading = true;
        try {
            this.wirePayments = await this.wireService.fetchWireActivity(filters, this.activeTab);
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    switchTab(tab: string) {
        this.activeTab = tab;
    }

    renderTabs() {
        return html`<tm-tabs
            .tabs=${this.tabs}
            .activeTab=${this.activeTab}
            @switchTab=${(e: CustomEvent) => {
                this.switchTab(e.detail.activeTab);
            }}
        ></tm-tabs>`;
    }

    renderTable() {
        return html`<tm-section class="h-full flex flex-col"
            >${this.renderTabs()}
            <wire-payments-table
                .loading=${this.loading}
                .wirePayments=${this.wirePayments}
                @filter-wire-payments=${() => {
                    this.filterSheetOpen = true;
                }}
            ></wire-payments-table
        ></tm-section>`;
    }

    renderCreateUsdFromTemplateActionItem() {
        if (this.domesticEntitlement || this.internationalEntitlement) {
            return html` <jhd-route-action-item
                .icon=${templateIcon}
                .action=${'Create Wire from Template'}
                .actionDescription=${'Create USD wire payment(s) from template'}
                @click=${() => {
                    this.navService.navigate('/wires/templates/initiate');
                }}
            ></jhd-route-action-item>`;
        }
        return nothing;
    }

    renderFilterSheet() {
        if (this.activeTab === 'One-Time') {
            return html`<wire-payments-filter-sheet
                .open=${this.filterSheetOpen}
                .wireCompanies=${this.wireCompanies}
                .debitAccounts=${this.debitWireAccounts}
                @close=${() => {
                    this.filterSheetOpen = false;
                }}
                @applyFilters=${(e: CustomEvent) => {
                    this.fetchWirePayments(e.detail);
                }}
            ></wire-payments-filter-sheet>`;
        }
        return html`<wire-recurring-payments-filter-sheet
            .open=${this.filterSheetOpen}
            .wireCompanies=${this.wireCompanies}
            .debitAccounts=${this.debitWireAccounts}
            @close=${() => {
                this.filterSheetOpen = false;
            }}
            @applyFilters=${(e: CustomEvent) => {
                this.fetchWirePayments(e.detail);
            }}
        ></wire-recurring-payments-filter-sheet>`;
    }

    /* When other wire creation workflows are ready this action item and wire type selection sheet should be used and the direct route above removed */
    /*
    renderActionItem() {
        return html`<jhd-route-action-item
            .icon=${circlePlusIcon}
            .action=${'Create Wire Payment'}
            @click=${() => {
                this.bottomSheetOpen = true;
            }}
        ></jhd-route-action-item>`;
    }

    renderCreateWireTypeBottomSheet() {
        return html`<create-wire-type-bottom-sheet
            .bottomSheetOpen=${this.bottomSheetOpen}
            @close=${() => {
                this.bottomSheetOpen = false;
            }}
        >
        </create-wire-type-bottom-sheet>`;
    } */

    render() {
        return [
            this.renderCreateUsdFromTemplateActionItem(),
            this.renderTable(),
            this.renderFilterSheet(),
            // this.renderActionItem(),
            // this.renderCreateWireTypeBottomSheet(),
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: WiresContainer;
    }
}
