import { CompanyAccountModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';
import { defaultCompanyAccountModelDto } from './data/ach.data';

export class OffsetAccount extends DomainEntity<CompanyAccountModelDto> {
    public createDefault(): this {
        return new OffsetAccount(defaultCompanyAccountModelDto) as this;
    }

    public get offsetAccountId() {
        return this.dto.id;
    }

    public get name() {
        return this.dto.name;
    }

    public get number() {
        return this.dto.number;
    }

    public get accountDisplayLabel() {
        return this.dto.accountDisplayLabel;
    }
}
