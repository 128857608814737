import { CompanyAccountModelDto } from '@treasury/api/channel';
import { clone } from '@treasury/utils';
import { DomainEntity } from '../shared';
import { nullAccount } from './null-account';

export class Account extends DomainEntity<CompanyAccountModelDto> {
    constructor(dto = clone(nullAccount)) {
        super(dto);
    }

    public createDefault(): this {
        return new Account(clone(nullAccount)) as this;
    }

    public get name() {
        return this.dto.name;
    }

    public get number() {
        return this.dto.number;
    }

    public get type() {
        return this.dto.type;
    }

    public get currentBalance() {
        return this.dto.currentBalance;
    }

    public get availableBalance() {
        return this.dto.availableBalance;
    }

    public get collectedBalance() {
        return this.dto.collectedBalance;
    }
}
