export enum MediaWidth {
    Phone,
    PortraitTablet,
    LandscapeTablet,
    Desktop,
}

export interface MediaWidthType {
    type: MediaWidth;
    minWidth: number;
    maxWidth: number;
    mediaQuery: string;
}

export const mediaWidths: MediaWidthType[] = [
    { type: MediaWidth.Phone, minWidth: 0, maxWidth: 600, mediaQuery: '(max-width: 600px)' },
    {
        type: MediaWidth.PortraitTablet,
        minWidth: 601,
        maxWidth: 768,
        mediaQuery: '(min-width: 600px)',
    },
    {
        type: MediaWidth.LandscapeTablet,
        minWidth: 769,
        maxWidth: 992,
        mediaQuery: '(min-width: 768px)',
    },
    {
        type: MediaWidth.Desktop,
        minWidth: 993,
        maxWidth: Number.MAX_SAFE_INTEGER,
        mediaQuery: '(min-width: 992px)',
    },
];
