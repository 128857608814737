/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import {
    combine,
    delay,
    fromEvent,
    isNonEmptyString,
    noOp,
    ObservationSource,
    WindowService,
} from '@treasury/utils';
import { HistoryEntry, NavigationItem } from './navigation.types';

const MAX_HISTORY_SIZE = 10;

export abstract class NavigationService<Opts extends Record<string, any> = Record<string, any>> {
    constructor(protected readonly window: WindowService) {
        const load$ = fromEvent(this.window, 'load');
        const popstate$ = fromEvent<PopStateEvent>(this.window, 'popstate');

        combine(load$, popstate$).subscribe(e => this.handleNavigationEvent(e));

        // Ensure that there is always at least 1 history item
        this.handleNavigationEvent();
    }

    protected outlet?: Node;

    /**
     * Character used to separate route path segments.
     */
    protected routeDelimiter = '/';

    /**
     * If `true`, prefixes routes with `routeDelimiter` when normalized.
     */
    protected prefixRoutes = true;

    /**
     * Route segment prefixed to all routes when normalized.
     */
    private routeBase?: string;

    /**
     * Storage for params set using `navigate()` which are awaiting a matching route change.
     */
    private pendingParams: Map<string, object> = new Map();

    private readonly history: Readonly<HistoryEntry>[] = [];

    private readonly navigationSource = new ObservationSource<NavigationItem>();

    protected readonly readySource = new ObservationSource<void>();

    public readonly routeChange$ = this.navigationSource.toObservable();

    public readonly ready$ = this.readySource.toObservable();

    private lastNavigate: Promise<void> = this.routeChange$.toPromise(false).then(noOp);

    /**
     * The most recent route data, if any.
     */
    private get currentRoute() {
        return this.history.length > 0 ? this.history[this.history.length - 1] : undefined;
    }

    public async getRouteData<Params>() {
        await this.lastNavigate;

        if (!this.currentRoute) {
            throw new Error('Cannot get route data. No navigations have occurred.');
        }

        // account for timing issues where pop state lags behind UI code
        let i = 0;
        while (this.currentRoute.url !== this.window.location.href) {
            i++;
            if (i > 10) {
                throw new Error('Could not get history entry in timely fashion');
            }
            // eslint-disable-next-line no-await-in-loop
            await delay(100);
        }

        return this.currentRoute as HistoryEntry<Params>;
    }

    protected abstract getActiveRoute(currentPath: string): Promise<string>;

    /**
     * Get the parameters bound to URL path segments.
     */
    protected abstract getUrlParams(currentPath: string): Promise<NavigationItem['params']>;

    /**
     * Implementation-specific navigation method provided by the derived class.
     */
    protected abstract performNavigation<Params extends Record<string, any>>(
        route: string,
        params?: NavigationItem<Params>['params'],
        options?: Opts
    ): Promise<void>;

    public navigate<Params extends Record<string, any>>(
        route: string,
        params?: NavigationItem<Params>['params'],
        options?: Opts
    ) {
        const normalizedRoute = this.normalizeRoute(route);
        if (params) {
            this.pendingParams.set(normalizedRoute, params);
        }

        this.lastNavigate = this.performNavigation<Params>(normalizedRoute, params, options);

        return this.lastNavigate;
    }

    public async setOutlet(outlet: Node | (() => Promise<Node>)) {
        if (!this.outlet) {
            this.outlet = typeof outlet === 'function' ? await outlet() : outlet;
        }

        this.readySource.emit();
        this.readySource.complete();
    }

    public navigateBack() {
        // pop off current state
        this.history.pop();

        // pop off the previous state to use to go back;
        // it will be replaced with an identical entry on successful navigation
        const previousState = this.history.pop();

        if (!previousState) {
            throw new Error('Could not navigate back.');
        }

        const { route, params } = previousState;
        return this.navigate(route, params);
    }

    public setRouteBase(routeBase: string) {
        this.routeBase = removeRoutePrefix(routeBase, this.routeDelimiter);
    }

    protected normalizeRoute(route: string) {
        const routeSegments = route.split(this.routeDelimiter).filter(isNonEmptyString);

        // if specified, append the route base
        if (this.routeBase && !route.match(new RegExp('^/?' + this.routeBase))) {
            routeSegments.unshift(this.routeBase);
        }

        route = routeSegments.join(this.routeDelimiter);

        if (this.prefixRoutes) {
            route = this.routeDelimiter + route;
        }

        return route;
    }

    protected async handleNavigationEvent(e?: Event | PopStateEvent) {
        // add artificial delay to allow for implementations to catch up with
        // browser-based forward/back history navigation; Vaadin reports
        // incorrectly without this
        await delay(1);
        const path = this.window.location.pathname;
        const route = await this.getActiveRoute(path);
        let params = await this.getUrlParams(path);

        if (this.pendingParams.has(route)) {
            const storedParams = this.pendingParams.get(route);
            params = {
                ...params,
                ...storedParams,
            };

            this.pendingParams.delete(route);
        }

        this.setActiveRoute(route, params, e);
    }

    private setActiveRoute<Params extends Record<string, any>>(
        route: string,
        // eslint-disable-next-line default-param-last
        params: NavigationItem<Params>['params'] = {} as Params,
        e?: Event
    ) {
        const routeData = Object.freeze({
            route,
            url: this.window.location.href,
            params,
        });

        const entry = new HistoryEntry(routeData, e);

        // ignore identical route changes
        // i.e., that register more than once due to both a pop state and navigation() call
        if (this.currentRoute && this.currentRoute.compare(entry)) {
            return;
        }

        // groom history memory
        if (this.history.length === MAX_HISTORY_SIZE) {
            this.history.shift();
        }

        this.history.push(entry);
        this.navigationSource.emit(routeData);
    }
}

/**
 * Normalize a route or route segment to account for possibility of leading delimiter.
 * Creates a route *without* a prefix.
 */
function removeRoutePrefix(route: string, routeDelimiter = '/') {
    return route.split(routeDelimiter).filter(isNonEmptyString).join(routeDelimiter);
}
