import { ExceptionDecisionStatus } from '@treasury/domain/arp';
import { CheckExceptionReturnReasons } from '@treasury/domain/channel/types';

export type CheckExceptionAudit = {
    username: string;
    action: ExceptionDecisionStatus.Pay | CheckExceptionReturnReasons;
    timestamp: string;
};
export interface CheckExceptionDto {
    id: number;
    acknowledged: boolean;
    accountNumber: string;
    arpExceptionDetailId: string;
    arpExceptionDetailUniqueId: string;
    attachmentFileName: string;
    audits: CheckExceptionAudit[];
    checkImageNumber: number;
    checkNumber: string;
    comment: string;
    companyId: number;
    companyName: string;
    companyUniqueId: string;
    ddaBatchNumber: string;
    ddaSequenceNumber: string;
    decisionChoice: string;
    decisionStatus: ExceptionDecisionStatus;
    exceptionReason: string;
    financialInstitutionId: string;
    fromWorkStation: string;
    initialDecision: string;
    isCorrectionRequestCreated: boolean;
    issuedAmount: number;
    issuedDate: Date;
    issuedPayee: string;
    lastDecisionByUserUniqueId: string;
    lastDecisionTakenBy: string;
    paidAmount: number;
    pendingDecision: string;
    posPayItemId: string;
    postedDate: Date;
    protected: string;
    returnReason: string;
    returnReasonDescription: string;
    returnReasonId: string;
    returnReasonUniqueId: string;
    sourceOfEntry: string;
    updatedBy: string;
}

export interface CheckImage {
    error?: boolean;
    backImage: string;
    frontImage: string;
    checkId: number;
}

export enum CheckExceptionFilterTabId {
    ItemsToDecision = 'To Decision',
    ItemsDecisionedToday = 'Decisioned Today',
    AllItems = 'All Items',
}

export enum JhaApprovalToggleStatus {
    Approved = 'Approved',
    Rejected = 'Rejected',
    NA = 'na',
}
