import { AlarmClock } from '@treasury/alarm-clock';
import { CheckExceptionModelDto, ProcessingCutoffDto } from '@treasury/api/channel';
import { CheckException, ExceptionDecisionStatus } from '@treasury/domain/arp';
import PositivePayService from '@treasury/domain/channel/services/positive-pay/positive-pay-service';
import { CheckExceptionReturnReasons } from '@treasury/domain/channel/types';
import {
    CHECK_EXCEPTION_CUTOFF,
    CHECK_EXCEPTION_CUTOFF_MINUS_30,
    CHECK_EXCEPTION_START,
} from '@treasury/domain/channel/types/arp/constants';
import { TmContainer } from '@treasury/presentation';
import { checkIcon, informationIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/layout/tm-section';
import '@treasury/presentation/components/tm-badge';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-table-header';
import '@treasury/presentation/components/tm-tabs';
import { InjectProperty } from '@treasury/utils';
import { formatUsdCurrency } from '@treasury/utils/functions/currency.helpers';
import { DeepReactive } from '@treasury/utils/lit-helpers';
import { MediaService } from '@treasury/utils/services/media-service';
import '@vaadin/grid';
import { columnBodyRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { TextFieldValueChangedEvent } from '@vaadin/text-field';
import { format } from 'date-fns';
import { css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { filterTableResults } from '../../../../utilities/table-result-filter';
import { HeaderBarService } from '../../../services/jhd-header-bar.service';
import { MenuItem } from '../../../services/types/jhd-header-bar.type';
import '../../components/decision-toggle';
import { CheckExceptionFilterTabId } from '../../types';
import { exceptionNeedsDecision, exceptionsDecidedToday } from '../util/check-exception-filters';
import './check-exceptions-decision-workflow';

export const tagName = 'check-exceptions-container';
@customElement(tagName)
export class PositivePayExceptionsContainer extends TmContainer {
    @InjectProperty()
    private declare mediaService: MediaService;

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare positivePayService: PositivePayService;

    @state()
    private showingExceptionDetail = false;

    @state()
    private selectedException?: CheckException;

    @state()
    isAfterCutoff = false;

    @state()
    selectedFilterTab: CheckExceptionFilterTabId = CheckExceptionFilterTabId.ItemsToDecision;

    @state()
    private menuItems: MenuItem[] = [
        { title: 'Info', icon: informationIcon, action: () => console.log('clicked') },
    ];

    @state()
    cutoffMessage?: string;

    @state()
    cutoffTime?: ProcessingCutoffDto;

    @DeepReactive()
    checkExceptions: CheckException[] = [];

    @state()
    returnReasons: Array<CheckExceptionReturnReasons> = [];

    @state()
    showReviewWorkflow = false;

    @state()
    showSelectAllOptions = false;

    @state()
    searchTerm: string = '';

    @state()
    tableHeaderExpanded = false;

    private get fiClock() {
        return AlarmClock.getInstance();
    }

    get exceptionType() {
        return window.location.pathname.split('/').pop();
    }

    get filteredExceptions() {
        // console.log('filtered exceptions getter accessed');

        let exceptions: CheckException[];

        switch (this.selectedFilterTab) {
            case CheckExceptionFilterTabId.AllItems:
                exceptions = this.checkExceptions;
                break;
            case CheckExceptionFilterTabId.ItemsDecisionedToday:
                exceptions = this.checkExceptions.filter(exceptionsDecidedToday);
                break;
            case CheckExceptionFilterTabId.ItemsToDecision:
                exceptions = this.checkExceptions.filter(exceptionNeedsDecision);
                break;
        }

        return filterTableResults(this.searchTerm, exceptions, [
            'accountNumber',
            'checkNumber',
            'paidAmount',
            'exceptionReason',
        ]);
    }

    /**
     * returns a list of items that are not modified
     */
    get undecisionedItems() {
        return this.filteredExceptions.filter(
            exception => !exception.protected && !exception.decisionModified
        );
    }

    /**
     * returns items with decision currently in pay state
     */
    get payItems() {
        return this.filteredExceptions?.filter(
            exception => exception.decisionStatus === ExceptionDecisionStatus.Pay
        );
    }

    /**
     * returns items with decision currently in return state
     */
    get returnItems() {
        return this.filteredExceptions.filter(
            exception => exception.decisionStatus === ExceptionDecisionStatus.Return
        );
    }

    /**
     * returns unprotected items that have had their decision toggled by the user
     */
    get modifiedItems() {
        return this.filteredExceptions.filter(
            exception => !exception.protected && exception.decisionModified
        );
    }

    async firstUpdated() {
        await this.setup();
    }

    async setup() {
        this.initialHeaderConfig();

        const cutoffTimes = (await this.positivePayService.getCutoffTimes()).processingCutoffs;
        this.cutoffTime = cutoffTimes?.find(cutoff => cutoff.productType === 'Check Exceptions');

        await this.fiClock.checkReadiness();
        if (
            this.fiClock.isAfter(CHECK_EXCEPTION_CUTOFF_MINUS_30) &&
            this.fiClock.isBefore(CHECK_EXCEPTION_CUTOFF)
        ) {
            this.isAfterCutoff = false;
        } else if (this.fiClock.isAfter(CHECK_EXCEPTION_CUTOFF)) {
            this.isAfterCutoff = true;
        }

        if (this.isAfterCutoff) {
            this.checkExceptions.forEach(checkException => {
                checkException.setPastCutoff();
            });
        }

        await this.fetchCheckExceptions();
        await this.fetchReturnReasons();
        if (this.isAfterCutoff) this.selectedFilterTab = CheckExceptionFilterTabId.AllItems;
        this.switchExceptionTab(this.selectedFilterTab);
    }

    async fetchCheckExceptions() {
        return this.tryFetch(
            () => this.positivePayService.getCheckExceptions(),
            async checkExceptions => {
                const { data } = checkExceptions;
                this.checkExceptions = data.map(
                    (item: CheckExceptionModelDto) => new CheckException(item)
                );
            }
        );
    }

    async fetchReturnReasons() {
        return this.tryFetch(
            () => this.positivePayService.getReturnReasons(),
            async returnReasons => {
                this.returnReasons = returnReasons;
            }
        );
    }

    toggleShowSelectAllOptions() {
        this.showSelectAllOptions = !this.showSelectAllOptions;
    }

    payAll() {
        console.log('pay all called');
        const exceptions = this.filteredExceptions;
        this.checkExceptions.forEach(exception => {
            if (exceptions.includes(exception)) {
                exception.pay();
                exception.modify();
            }
        });
    }

    returnAll() {
        console.log('return all called');
        const exceptions = this.filteredExceptions;
        this.checkExceptions.forEach(exception => {
            if (exceptions.includes(exception)) {
                exception.return();
                exception.modify();
            }
        });
    }

    clearAll() {
        const exceptions = this.filteredExceptions;
        this.checkExceptions.forEach(exception => {
            if (exceptions.includes(exception)) {
                exception.clearDecision();
            }
        });
    }

    initialHeaderConfig() {
        this.headerService.configure({ title: 'Positive Pay - Checks', menuItems: this.menuItems });
    }

    switchExceptionTab(tabId: CheckExceptionFilterTabId) {
        if (tabId === this.selectedFilterTab) return;
        this.selectedFilterTab = tabId;
    }

    setSelectedException(exception: CheckException) {
        this.selectedException = exception;
        this.showingExceptionDetail = true;
    }

    backToTableView() {
        this.initialHeaderConfig();
        this.showingExceptionDetail = false;
        this.showReviewWorkflow = false;
    }

    patchClonedExceptions(clonedExceptions: CheckException[]) {
        this.checkExceptions = this.checkExceptions.map(exception => {
            return clonedExceptions.find(clone => clone.guid === exception.guid) ?? exception;
        });
    }

    cellPartNameGen(col: HTMLElement, val: { item: CheckException }) {
        return val.item.decisionModified ? 'modified' : '';
    }

    renderReviewWorkflow() {
        const backToTableView = this.backToTableView.bind(this);
        return html`<check-exceptions-decision-workflow
            .checkExceptions=${this.modifiedItems}
            @endReviewWorkflow=${() => {
                backToTableView();
            }}
            @refresh=${() => {
                this.setup();
            }}
        >
        </check-exceptions-decision-workflow>`;
    }

    renderCheckExceptionDetail() {
        const backToTableView = this.backToTableView.bind(this);
        return html`<check-exception-detail-container
            .selectedException=${this.selectedException}
            .backToTableView=${backToTableView}
            .returnReasons=${this.returnReasons}
            .isAfterCutoff=${this.isAfterCutoff}
        ></check-exception-detail-container>`;
    }

    renderSelectAll() {
        if (this.isAfterCutoff) return nothing;

        return this.showSelectAllOptions
            ? html`<div class="flex mx-4 my-2 select-all-options">
                  <tm-button
                      class="mr-2"
                      @click=${() => {
                          this.toggleShowSelectAllOptions();
                          this.clearAll();
                      }}
                  >
                      Cancel
                  </tm-button>
                  <tm-button class="mx-2" importance="primary" success @click=${this.payAll}>
                      Pay All
                  </tm-button>
                  <tm-button class="mx-2" importance="primary" error @click=${this.returnAll}>
                      Return All
                  </tm-button>
              </div>`
            : html`<div class="flex mx-4 my-2 select-all-options">
                  <tm-button importance="primary" @click=${this.toggleShowSelectAllOptions}>
                      Select All
                  </tm-button>
              </div>`;
    }

    renderHeaderText() {
        if (this.isAfterCutoff && this.cutoffTime) {
            const timeArray = this.cutoffTime.cutoffTime.split(':');
            const [hours, minutes, seconds] = timeArray;
            const date = new Date();
            date.setHours(Number(hours));
            date.setMinutes(Number(minutes));
            date.setSeconds(Number(seconds));
            const formattedTime = format(date, 'h:mm a');
            return html`<div class="mr-1">
                <h1>Decisioned Today</h1>
                <h2 class="text-xs text-[--secondary-text-color]">Past Cut-off ${formattedTime}</h2>
            </div>`;
        }
        return html`<h1 class="mr-1">Check Exceptions</h1>`;
    }

    renderTotals() {
        const undecisioned =
            this.selectedFilterTab === CheckExceptionFilterTabId.ItemsDecisionedToday
                ? nothing
                : html`<div class="p-1 flex items-center undecisioned-total text-sm">
                      <tm-badge>${this.undecisionedItems?.length ?? 0}</tm-badge>
                      <span class="table-footer-label p-1">Undecisioned</span>
                  </div>`;

        return html`<div class="w-full h-12 flex items-end justify-end border-b grid-footer">
            <div class="flex items-center border-t h-full">
                <div class="p-1 flex items-center total-count text-sm">
                    <tm-badge>${this.filteredExceptions?.length ?? 0}</tm-badge>
                    <span class="table-footer-label p-1">Total</span>
                </div>
                ${undecisioned}
                <div class="p-1 flex items-center pay-total text-sm">
                    <tm-badge>${this.payItems?.length ?? 0}</tm-badge>
                    <span class="table-footer-label p-1">Pay</span>
                </div>
                <div class="p-1 flex items-center return-total text-sm">
                    <tm-badge>${this.returnItems?.length ?? 0}</tm-badge>
                    <span class="table-footer-label p-1">Return</span>
                </div>
            </div>
        </div>`;
    }

    renderTableContent() {
        return html`
            <tm-section class="border-b">
                ${this.isAfterCutoff
                    ? nothing
                    : html`<tm-tabs
                          .tabs=${[
                              CheckExceptionFilterTabId.ItemsToDecision,
                              CheckExceptionFilterTabId.ItemsDecisionedToday,
                              CheckExceptionFilterTabId.AllItems,
                          ].map(val => ({ id: val, label: val }))}
                          .activeTab=${this.selectedFilterTab}
                          @switchTab=${(event: CustomEvent) => {
                              this.switchExceptionTab(event.detail.activeTab);
                          }}
                      >
                      </tm-tabs>`}
                <tm-table-header
                    class="flex-1"
                    placeholder="Search"
                    @filterStringChange=${(e: TextFieldValueChangedEvent) => {
                        this.searchTerm = e.detail.value;
                    }}
                >
                    ${this.renderHeaderText()}
                </tm-table-header>
                <vaadin-grid
                    all-rows-visible
                    .items=${this.filteredExceptions}
                    class="${classMap({
                        'after-cutoff': !!this.isAfterCutoff,
                        'border-b': true,
                    })}"
                    .cellPartNameGenerator=${this.cellPartNameGen}
                >
                    <vaadin-grid-column
                        frozen
                        .width=${this.isAfterCutoff ? `56px` : `96px`}
                        ${columnBodyRenderer((exception: CheckException) => {
                            return html`
                                <decision-toggle
                                    class="cell-background"
                                    .exception=${exception}
                                    ?readonly=${this.isAfterCutoff}
                                    .decisionStatus=${exception.decisionStatus}
                                    .modified=${exception.decisionModified}
                                ></decision-toggle>
                            `;
                        })}
                    ></vaadin-grid-column>
                    <vaadin-grid-column
                        auto-width
                        ${columnBodyRenderer((exception: CheckException) => {
                            const checkNumber = exception.checkNumber
                                ? html` ${exception.checkNumber} <br />`
                                : nothing;
                            return html`
                                <button
                                    class="cell-background"
                                    @click=${() => this.setSelectedException(exception)}
                                >
                                    <div class="font-bold">${exception.accountNumber}</div>
                                    <div class="font-light text-sm text-left">
                                        ${checkNumber} Paid:
                                        ${formatUsdCurrency(exception.paidAmount)} | Issued:
                                        ${formatUsdCurrency(exception.issuedAmount)}
                                    </div>
                                </button>
                            `;
                        })}
                    >
                    </vaadin-grid-column>
                    <vaadin-grid-column
                        auto-width
                        ${columnBodyRenderer((exception: CheckException) => {
                            const checkImageIcon = exception.checkImageNumber
                                ? html`<jhd-icon .icon=${checkIcon}></jhd-icon>`
                                : nothing;
                            return html`
                                <button
                                    class="cell-background"
                                    @click=${() => this.setSelectedException(exception)}
                                >
                                    <div class="badge text-sm max-w-36">
                                        ${exception.exceptionReason}
                                    </div>
                                    <div class="text-right w-[80%]">${checkImageIcon}</div>
                                </button>
                            `;
                        })}
                    ></vaadin-grid-column>
                </vaadin-grid>
                ${this.renderTotals()}${this.renderSelectAll()}
            </tm-section>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: this.modifiedItems.length
                            ? `Review (${this.modifiedItems.length})`
                            : 'Review',
                        onClick: () => {
                            if (this.isAfterCutoff) return;
                            this.showReviewWorkflow = true;
                        },
                        disabled: !this.modifiedItems.length,
                    },
                ]}
                .visible=${!this.isAfterCutoff}
            ></tm-footer>
        `;
    }

    render() {
        if (this.loading) return html`${this.renderLoader()}`;
        if (this.showReviewWorkflow) return this.renderReviewWorkflow();
        if (this.showingExceptionDetail) return this.renderCheckExceptionDetail();
        return this.renderTableContent();
    }

    static get styles() {
        return [
            css`
                :host {
                    height: 100%;
                    --lumo-space-xs: 0;
                    --lumo-space-m: 0;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }
                .loader-container {
                    min-height: 100%;
                }
                .border-solid-top {
                    border-top: solid 1px var(--secondary-text-color);
                }
                .border-solid-bottom {
                    border-bottom: solid 1px var(--secondary-text-color);
                }
                h3 {
                    margin-top: 10px;
                }
                #no-exceptions {
                    margin: auto;
                }
                .badge {
                    color: #777;
                    background-color: rgba(230, 230, 230, 0.3);
                    padding: 3px 5px;
                    text-align: center;
                    width: 90%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .cell-background {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                }
                .header {
                    width: calc(100vw - 32px);
                    margin: 8px 16px;
                }
                .footer {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    padding: 10px;
                    position: fixed;
                    bottom: 0px;
                }
                .decision-icons {
                    display: flex;
                }

                vaadin-grid {
                    --vaadin-grid-cell: {
                        padding: 0;
                        height: 100px;
                    };
                }
                vaadin-grid {
                    max-height: calc(var(--below-navbar-view-height) - 282px);
                }
                vaadin-grid.after-cutoff {
                    max-height: calc(var(--below-navbar-view-height) - 105px);
                }

                tm-section:has(tm-table-header[searchBlockDisplay]) {
                    vaadin-grid {
                        max-height: calc(var(--below-navbar-view-height) - 282px - 62px);
                    }

                    vaadin-grid.after-cutoff {
                        max-height: calc(var(--below-navbar-view-height) - 105px - 62px);
                    }
                }

                vaadin-grid-cell-content {
                    height: 56px;
                }
                vaadin-grid::part(modified) {
                    background-color: var(--content-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: PositivePayExceptionsContainer;
    }
}
