import { TmHttpClient } from '@treasury/core/http';
import { NavigationService } from '@treasury/core/navigation';
import { LogoutManager } from '@treasury/domain/services/logout';
import { TmBaseComponent } from '@treasury/presentation';
import {
    achIcon,
    billsIcon,
    bulletedListIcon,
    checkRangeIcon,
    circleCheckmarkIcon,
    dashboardIcon,
    depositIcon,
    inboxIcon,
    informationIcon,
    logoutIcon,
    transferIcon,
    walletIcon,
    wiresIcon,
} from '@treasury/presentation/assets/icons';
import { InjectProperty } from '@treasury/utils';
import { MediaService } from '@treasury/utils/services/media-service';
import { MediaWidth } from '@treasury/utils/types/media.types';
import { css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../navigation/components/navigation-menu';
import { NavigationMenuItem } from '../navigation/types/navigation.types';
import { LayoutService } from '../services/layout.service';

export const tagName = 'jhd-layout';
@customElement(tagName)
export class JhdLayout extends TmBaseComponent {
    @InjectProperty()
    private declare layoutService: LayoutService;

    @InjectProperty()
    private declare mediaService: MediaService;

    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare http: TmHttpClient;

    @InjectProperty()
    private declare logoutManager: LogoutManager;

    @state()
    navigationOptions: NavigationMenuItem[] = [
        {
            label: '',
            options: [
                { label: 'Dashboard', url: `dashboard`, icon: dashboardIcon, id: 'dashboard' },
                { label: 'Accounts', url: `accounts`, icon: walletIcon, id: 'accounts' },
                {
                    label: 'Pending Approvals',
                    url: `pending-approvals/ach`,
                    icon: circleCheckmarkIcon,
                    id: '',
                },
            ],
        },
        {
            label: 'Payments',
            options: [
                { label: 'ACH Payments', url: `ach`, icon: achIcon, id: 'paymentList' },
                {
                    label: 'Business Bill Pay',
                    url: `business-bill-pay`,
                    icon: billsIcon,
                    id: 'businessBillPay_frame', //holdover from channel navigation naming
                },
                { label: 'Transfers', url: `transfers`, icon: transferIcon, id: 'transferList' },
                { label: 'Wires', url: `wires`, icon: wiresIcon, id: 'wireList' },
            ],
        },
        {
            label: 'Positive Pay',
            options: [
                {
                    label: 'ACH',
                    url: `positive-pay/ach-exceptions`,
                    icon: achIcon,
                    id: 'achExceptionsList',
                },
                {
                    label: 'Checks',
                    url: `positive-pay/check-exceptions`,
                    icon: checkRangeIcon,
                    id: 'checkExceptionsDecisionActivityList',
                },
            ],
        },
        {
            label: '',
            options: [
                { label: 'Deposits', url: `deposit`, icon: depositIcon, id: '' },
                {
                    label: 'Message Center',
                    url: `message-center`,
                    icon: inboxIcon,
                    id: '',
                },
                { label: 'About', url: `about`, icon: informationIcon, id: '' },
                { label: 'Log Out', url: `log-out`, icon: logoutIcon, id: '' },
            ],
        },
    ];

    @state()
    private navigationOpen = false;

    @state()
    private phoneScreen = false;

    @state()
    private landscapeWarningOpen = false;

    protected firstUpdated() {
        this.layoutService.isSidebarOpen$.subscribe(isSidebarOpen => {
            this.navigationOpen = isSidebarOpen;
        });

        this.mediaService.viewportResize$.subscribe(currentMediaWidth => {
            this.phoneScreen = currentMediaWidth?.type === MediaWidth.Phone;
        });

        this.mediaService.orientationChange$.subscribe(orientation => {
            if (orientation === 'landscape-primary') {
                this.landscapeWarningOpen = true;
            } else {
                this.landscapeWarningOpen = false;
            }
        });

        this.http.sessionExpired$.subscribe(async () => {
            await this.logoutManager.logOut('Forced');
            this.navService.navigate(`/login`);
        });
    }

    public scrollTopContent() {
        const contentPane = this.shadowRoot?.querySelector('.content-pane');
        if (contentPane && contentPane.scrollTop > 0) {
            contentPane.scrollTo(0, 0);
        }
    }

    private renderHeaderBar() {
        return html`<jhd-header-bar></jhd-header-bar>`;
    }

    private renderLandscapeWarning() {
        if (!this.landscapeWarningOpen) return nothing;
        return html`<div class="landscape-warning">
            <div class="landscape-warning-dialog p-4 rounded ">
                <div class="flex flex-col items-center">
                    <div class="text-3xl font-bold">Please rotate your device</div>
                    <div class="text-xl">This application is not optimized for landscape mode</div>
                </div>
            </div>
        </div>`;
    }

    public render() {
        return html`
            <div class="layout-container overflow-hidden h-full flex flex-col">
                ${this.renderHeaderBar()}${this.renderLandscapeWarning()}

                <div class="h-full flex overflow-hidden">
                    <navigation-menu
                        .navigationOptions=${this.navigationOptions}
                        class="overflow-hidden h-full top-0 z-20"
                    >
                    </navigation-menu>
                    <div
                        class="content-pane overflow-auto h-full flex-1"
                        @scrollTopContent=${this.scrollTopContent}
                    >
                        <slot></slot>
                    </div>
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    overflow: hidden;
                    height: 100%;
                }
                .content-pane {
                    background-color: var(--content-background);
                }
                navigation-menu {
                    position: fixed;
                }
                .landscape-warning {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    z-index: 50;
                    opacity: 70;
                    background-color: var(--primary-background);
                }
                .landscape-warning-dialog {
                    background-color: var(--landscape-card-background);
                    color: var(--landscape-card-text);
                }
                @media screen and (min-width: 768px) {
                    navigation-menu {
                        position: relative;
                    }
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: JhdLayout;
    }
}
