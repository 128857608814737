import '@vaadin/text-area';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { TmInputFieldBase } from './tm-input-field.base';

export type TmTextAreaValueChanged = CustomEvent<{ value: string }>;

export const tagName = 'tm-text-area';
@customElement(tagName)
export class TmTextArea extends TmInputFieldBase {
    @property({ type: String })
    allowedCharacters?: string;

    @property({ type: String })
    pattern?: string;

    @property({ type: Number })
    minLength?: number;

    @property({ type: Number })
    maxLength?: number;

    render() {
        return html`
            <vaadin-text-area
                @value-changed=${(e: TmTextAreaValueChanged) => {
                    const valueChangedEvent = new CustomEvent('value-changed', {
                        detail: e.detail,
                    });
                    this.dispatchEvent(valueChangedEvent);
                }}
                class="w-full"
                part="component"
                label=${this.label}
                value=${this.value}
                placeholder=${ifDefined(this.placeholder)}
                pattern=${ifDefined(this.pattern)}
                allowed-char-pattern=${ifDefined(this.allowedCharacters)}
                helper-text=${ifDefined(this.helperText)}
                .minlength=${this.minLength}
                .maxlength=${this.maxLength}
                ?required=${this.required}
                ?readonly=${this.readonly}
                ?disabled=${this.disabled}
                has-placeholder
            >
            </vaadin-text-area>
        `;
    }

    static get styles() {
        return [
            ...super.styles,
            css`
                :host {
                    display: flex;
                }
                textarea::placeholder {
                    opacity: 0.5;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmTextArea;
    }
}
