import { FrequencyModel2Dto, ProductPermissionModelDto } from '@treasury/api/channel';
import { Nullable } from '@treasury/utils';

export interface AuditModel {
    date: string;
    text: string;
    comments: string;
    createdBy: string;
}

export interface LoanAccount {
    name: string;
    number: string;
    status: string;
    account: {
        id: number;
        number: string;
        type: string;
        coreStatus: string | null;
        accountDisplayLabel: string | null;
        name: string | null;
    };
    currentBalance: number | null;
    collectedBalance: number | null;
    availableBalance: number | null;
    type: string;
    perTransactionLimit: number | null;
    dailyLimit: number | null;
    cifNumber: string | null;
    isDeleted: boolean;
    showAccountNickName: boolean;
    accountDisplayLabel: string;
    transferAmount: number;
    transferMemo: string | null;
    loanPaymentAmountDue: number | null;
    loanPaymentDueDate: number | null;
    allowLoanPaymentPastDue: boolean;
    accountUniqueId: string;
    id: number;
    updatedBy: string;
    updatedDate: string;
}

export interface LoanDto {
    transactionId: string;
    lastEditedDate: string;
    transferDate: string;
    amount: number;
    memo: string | null;
    status: string;
    confirmationNumber: string;
    batchId: string;
    message: string | null;
    messageType: string | null;
    numberOfApprovalsNeeded: number;
    comments: string | null;
    isEdited: boolean;
    fromAccount: LoanAccount;
    toAccount: LoanAccount;
    errorSummary: string | null;
    createdDate: string;
    frequency: FrequencyModel2Dto | null;
    frequencyDescription: string | null;
    nextTransferDate: string | null;
    endDate: string | null;
    startDate: string | null;
    audit: AuditModel[];
    permissions: ProductPermissionModelDto[];
    frequencyModel: string | null;
    parentFrequencyModel: string | null;
    completedApprovals: any[];
    availableApprovers: any[];
    completedApprovalCount: number;
    paymentOptionType: string;
    securityMessage: string | null;
    id: number;
    updatedBy: string;
    updatedDate: string;
}

export const nullLoan: Nullable<LoanDto> = {
    transactionId: '',
    lastEditedDate: '',
    transferDate: '',
    amount: 0,
    memo: null,
    status: '',
    confirmationNumber: '',
    batchId: '',
    message: null,
    messageType: null,
    numberOfApprovalsNeeded: 0,
    comments: null,
    isEdited: false,
    fromAccount: {
        name: '',
        number: '',
        status: '',
        account: {
            id: 0,
            number: '',
            type: '',
            coreStatus: null,
            accountDisplayLabel: null,
            name: null,
        },
        currentBalance: null,
        collectedBalance: null,
        availableBalance: null,
        type: '',
        perTransactionLimit: null,
        dailyLimit: null,
        cifNumber: null,
        isDeleted: false,
        showAccountNickName: false,
        accountDisplayLabel: '',
        transferAmount: 0,
        transferMemo: null,
        loanPaymentAmountDue: null,
        loanPaymentDueDate: null,
        allowLoanPaymentPastDue: false,
        accountUniqueId: '',
        id: 0,
        updatedBy: '',
        updatedDate: '',
    },
    toAccount: {
        name: '',
        number: '',
        status: '',
        account: {
            id: 0,
            number: '',
            type: '',
            coreStatus: null,
            accountDisplayLabel: null,
            name: null,
        },
        currentBalance: null,
        collectedBalance: null,
        availableBalance: null,
        type: '',
        perTransactionLimit: null,
        dailyLimit: null,
        cifNumber: null,
        isDeleted: false,
        showAccountNickName: false,
        accountDisplayLabel: '',
        transferAmount: 0,
        transferMemo: null,
        loanPaymentAmountDue: null,
        loanPaymentDueDate: null,
        allowLoanPaymentPastDue: false,
        accountUniqueId: '',
        id: 0,
        updatedBy: '',
        updatedDate: '',
    },
    errorSummary: null,
    createdDate: '',
    frequency: null,
    frequencyDescription: null,
    nextTransferDate: null,
    endDate: null,
    startDate: null,
    audit: [
        {
            date: '',
            text: '',
            comments: '',
            createdBy: '',
        },
    ],
    permissions: [],
    frequencyModel: null,
    parentFrequencyModel: null,
    completedApprovals: [],
    availableApprovers: [],
    completedApprovalCount: 0,
    paymentOptionType: '',
    securityMessage: null,
    id: 0,
    updatedBy: '',
    updatedDate: '',
};
