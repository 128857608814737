import { AchRecipientModelDto } from '@treasury/api/channel';
import { NavigationService } from '@treasury/core/navigation';
import { AchService, AchTemplate } from '@treasury/domain/ach';
import { DashboardService } from '@treasury/domain/channel/services/dashboard/dashboard-service';
import { UsersService } from '@treasury/domain/channel/services/users/users-service';
import { LabeledList, NotificationService, TmContainer } from '@treasury/presentation';
import { actionIcon, chevronDownIcon, chevronUpIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-labeled-list';
import { InjectProperty } from '@treasury/utils';
import { GridActiveItemChangedEvent } from '@vaadin/grid';
import { format } from 'date-fns';
import { css, html, nothing } from 'lit';
import {
    GridColumnBodyLitRenderer,
    columnBodyRenderer,
    gridRowDetailsRenderer,
} from 'lit-vaadin-helpers';
import { customElement, property, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import { AchWorkflowParameters } from '../types/ach-workflow-parameters.type';

export const tagName = 'ach-template-container';
@customElement(tagName)
export class AchTemplateContainer extends TmContainer {
    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @InjectProperty()
    private declare achService: AchService;

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare dashboardService: DashboardService;

    @property()
    public template?: AchTemplate;

    @state()
    private recipients: AchTemplate['recipients'] = [];

    @state()
    private availableApprovers = [] as string[];

    @state()
    private completedApprovals = [] as { dateApproved: string; name: string }[];

    @state()
    private auditOpen = false;

    @state()
    private openedRecipients: AchRecipientModelDto[] = [];

    @state()
    private selectedAction = '';

    @state()
    private showApproveOrRejectPrompt = false;

    async firstUpdated() {
        this.configureHeader();
        if (!this.template) {
            await this.getTemplate();
        } else {
            this.recipients = this.template.recipients;
        }

        await this.getApprovals();
    }

    private async getTemplate() {
        const { params } = await this.navService.getRouteData<{
            institution: string;
            id: string;
        }>();
        await this.tryFetch(
            () => this.achService.getTemplateById(params.id),
            t => {
                this.template = t;
                this.recipients = t.recipients;
            }
        );
    }

    private async getApprovals() {
        await this.tryFetch(
            () =>
                UsersService.fetchAvailableAndCompletedApprovers({
                    approvalEntity: 'template',
                    productId: this.template?.templateId,
                    updatedBy: parseInt(this.template?.toDto()?.updatedBy || '0'),
                }),
            approvers => {
                this.availableApprovers = approvers.availableApprovers;
                this.completedApprovals = approvers.completedApprovers;
            }
        );
    }

    private async configureHeader() {
        const { params } = await this.navService.getRouteData<AchWorkflowParameters>();
        if (params.action === 'initiate') return;
        this.headerService.configure({
            backAction: () => this.navService.navigate(`/ach`),
            title: 'ACH Template Detail',
            menuItems: [{ title: 'Download', icon: actionIcon, action: () => alert('download') }],
        });
    }

    private async approveOrReject({
        items,
        paymentType = 'ach',
    }: {
        items: any[];
        paymentType: string;
    }) {
        try {
            this.loading = true;
            await this.dashboardService.approveOrRejectPayment(items, paymentType);
            this.dispatchEvent(
                new CustomEvent('approve-or-reject', { bubbles: true, composed: true })
            );
            await this.getTemplate();
            await this.getApprovals();
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    private toggleAudit() {
        this.auditOpen = !this.auditOpen;
    }

    private get renderStatusIcon() {
        return html`<jhd-status .status=${this.template?.status}></jhd-status>`;
    }

    renderApprovalInformation() {
        if (this.template?.status !== 'Pending Approval') return nothing;
        return html`<treasury-pending-approval-template
            class="mb-2 border-b-8"
            .payment=${this.template}
            .approvals=${this.completedApprovals}
            .eligibleApprovers=${this.availableApprovers}
        ></treasury-pending-approval-template>`;
    }

    renderTemplateHeader() {
        if (!this.template) return nothing;
        return html`<ach-template-data .template=${this.template}></ach-template-data>`;
    }

    renderRecipientNameColumn(recipient: AchRecipientModelDto) {
        return html`<div>
            <span class="text-sm">${recipient.name}</span>
        </div>`;
    }

    renderAmountColumn(recipient: AchRecipientModelDto) {
        const amountFormatted = recipient.amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return html`<div class="flex-shrink-0">
            <span class="text-sm font-medium"
                >${amountFormatted}
                <span class="font-normal text-xs">${recipient.transactionType}</span></span
            ><span class="recipient-chevron"
                >${this.openedRecipients.indexOf(recipient) === -1
                    ? chevronDownIcon
                    : chevronUpIcon}</span
            >
        </div>`;
    }

    renderRecipientDetails(recipient: AchRecipientModelDto) {
        const amountFormatted = recipient.amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        const addendaFormatted = recipient.addenda ? recipient.addenda : 'None';
        const displayRecipient = {
            ...recipient,
            amount: amountFormatted,
            addenda: addendaFormatted,
        };

        return html`<div>
            <tm-labeled-list
                .list=${new LabeledList(
                    displayRecipient,
                    [
                        'id',
                        'accountNumber',
                        'accountType',
                        'routingNumber',
                        'transactionType',
                        'amount',
                        'addenda',
                    ],
                    {
                        idNumber: 'ID Number',
                        accountNumber: 'Account Number',
                        accountType: 'Account Type',
                        routingNumber: 'Routing Number',
                        transactionType: 'Credit/Debit',
                        amount: 'Amount',
                        addenda: 'Addenda',
                    }
                )}
            ></tm-labeled-list>
        </div>`;
    }

    renderTemplateRecipients() {
        return html`<div class="recipients-table py-4">
            <h4 class="recipients-table-header font-medium pb-2">Template Recipients</h4>
            <vaadin-grid
                all-rows-visible
                class="min-h-0"
                .items=${this.recipients}
                .detailsOpenedItems=${this.openedRecipients}
                @active-item-changed=${(
                    event: GridActiveItemChangedEvent<AchRecipientModelDto>
                ) => {
                    const recipient = event.detail.value;
                    if (recipient) {
                        this.openedRecipients = [recipient];
                    } else {
                        this.openedRecipients = [];
                    }
                }}
                ${gridRowDetailsRenderer<AchRecipientModelDto>((recipient: AchRecipientModelDto) =>
                    this.renderRecipientDetails(recipient)
                )}
                ><vaadin-grid-column
                    ${columnBodyRenderer(
                        this
                            .renderRecipientNameColumn as GridColumnBodyLitRenderer<AchRecipientModelDto>,
                        []
                    )}
                ></vaadin-grid-column
                ><vaadin-grid-column
                    ${columnBodyRenderer(
                        this.renderAmountColumn as GridColumnBodyLitRenderer<AchRecipientModelDto>,
                        []
                    )}
                    text-align="end"
                ></vaadin-grid-column
            ></vaadin-grid>
        </div>`;
    }

    renderAuditData() {
        if (!this.auditOpen) return nothing;
        if (!this.template?.audit) return nothing;
        return html`<div class="pb-2">
            <div class="pb-2 font-medium">Audit</div>
            <div class="max-h-36 overflow-y-auto font-normal text-sm">${this.template.audit}</div>
        </div>`;
    }

    renderAudit() {
        if (!this.template?.audit) return nothing;
        const auditButtonText = this.auditOpen ? 'Hide Audit' : 'View Audit';
        return html`
            ${this.renderAuditData()}
            <tm-button class="mt-2" @click=${() => this.toggleAudit()}>
                ${auditButtonText}
            </tm-button>
        `;
    }

    renderApproveOrRejectPrompt() {
        return html`<approve-or-reject-prompt
            .paymentType=${'ACH'}
            .selectedAction=${this.selectedAction}
            .selectedApprovals=${[this.template]}
            .showApproveOrRejectPrompt=${this.showApproveOrRejectPrompt}
            @approve-or-reject=${(e: CustomEvent) => {
                const selectedState = this.selectedAction === 'approve' ? 'Approved' : 'Rejected';
                this.approveOrReject({
                    items: [{ ...this.template, selectedState }],
                    paymentType: 'ach',
                });
                this.showApproveOrRejectPrompt = false;
            }}
            @close=${() => {
                this.showApproveOrRejectPrompt = false;
            }}
        ></approve-or-reject-prompt>`;
    }

    renderActionButtons() {
        if (!this.template) return nothing;
        if (this.template.status !== 'Pending Approval') return nothing;
        const canApprove = this.template.permissions.some(
            (p: { permission: string }) => p.permission === 'Approve'
        );
        const canReject = this.template.permissions.some(
            (p: { permission: string }) => p.permission === 'Reject'
        );

        const approveButton = canApprove
            ? html`
                  <tm-button
                      success
                      size="large"
                      importance="primary"
                      class="w-full m-1"
                      @click=${() => {
                          this.selectedAction = 'approve';
                          this.showApproveOrRejectPrompt = true;
                      }}
                  >
                      Approve
                  </tm-button>
              `
            : nothing;
        const rejectButton = canReject
            ? html`
                  <tm-button
                      error
                      size="large"
                      importance="primary"
                      class="w-full m-1"
                      @click=${() => {
                          this.selectedAction = 'reject';
                          this.showApproveOrRejectPrompt = true;
                      }}
                      >Reject
                  </tm-button>
              `
            : nothing;

        if (!canApprove && !canReject) return nothing;
        return html`<tm-footer direction="row"> ${rejectButton}${approveButton} </tm-footer>`;
    }

    render() {
        return html`${this.renderLoader()}${this.renderApproveOrRejectPrompt()}${this.renderApprovalInformation()}
            <div class="detail-wrapper pl-6 pr-4 py-3">
                ${this.renderTemplateHeader()}
                ${this.renderTemplateRecipients()}${this.renderAudit()}
            </div>
            ${this.renderActionButtons()}`;
    }

    static get styles() {
        return [
            css`
                jha-data-field {
                    margin: 0;
                }
                vaadin-grid-cell-content {
                    padding: 0;
                }
                vaadin-grid::part(cell) {
                    border-top: none;
                    border-bottom: 1px solid var(--border-color);
                }
                .detail-wrapper {
                    background: var(--primary-background);
                }
                .detail-amount,
                .detail-status,
                .recipients-table-header,
                .amount {
                    color: var(--header-text-color);
                }
                .credit-debit-notation {
                    color: var(--secondary-text-color);
                }
                .recipient-chevron svg {
                    display: inline-block;
                }
                .max-h-36 {
                    max-height: 9rem;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchTemplateContainer;
    }
}
