import { Injectable } from '../dependency-injection';
import { noOp } from '../functions';
import { MediaWidth, mediaWidths } from '../types/media.types';
import { fromEvent } from '../types/observable';

@Injectable()
export class MediaService {
    public readonly viewportResize$ = fromEvent(window, 'resize').pipe(
        () => this.currentMediaWidth
    );

    public readonly desktop$ = this.viewportResize$
        .filter(() => this.currentMediaWidth.type === MediaWidth.Desktop)
        .pipe(noOp);

    public readonly phone$ = this.viewportResize$
        .filter(() => this.currentMediaWidth.type === MediaWidth.Phone)
        .pipe(noOp);

    public readonly portraitTablet$ = this.viewportResize$
        .filter(() => this.currentMediaWidth.type === MediaWidth.PortraitTablet)
        .pipe(noOp);

    public readonly landscapeTablet$ = this.viewportResize$
        .filter(() => this.currentMediaWidth.type === MediaWidth.LandscapeTablet)
        .pipe(noOp);

    public readonly orientationChange$ = fromEvent(window.screen.orientation, 'change').pipe(e => {
        const target = e.currentTarget as ScreenOrientation;
        return target.type;
    });

    get currentMediaWidth() {
        const width = mediaWidths.find(
            mediaWidth =>
                window.innerWidth >= mediaWidth.minWidth && window.innerWidth <= mediaWidth.maxWidth
        );

        if (!width) {
            throw new Error('Invalid media with');
        }

        return width;
    }
}
