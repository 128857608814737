import { TransferAccount } from '@treasury/domain/transfers';

export class AccountViewModel {
    constructor(private source: TransferAccount) {}

    public get value() {
        return this.source.entityId;
    }

    public get name() {
        return this.source.name;
    }

    public get label() {
        return this.source.accountDisplayLabel;
    }

    public get balance() {
        return this.source.availableBalance;
    }
}
