import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-badge';
import { css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../../components/jhd-data-field';
import '../../components/jhd-timeline';
import { BillPayPaymentDetailViewModel } from '../data/bill-pay-payment-detail-view-model';

export const tagName = 'bill-pay-read-only-payment-detail';
@customElement(tagName)
export class BillPayReadOnlyPaymentDetail extends TmBaseComponent {
    @state()
    payment!: BillPayPaymentDetailViewModel;

    renderPaymentTypeBadge() {
        return html`<tm-badge>${this.payment.paymentMethod}</tm-badge>`;
    }

    renderMemo() {
        if (!this.payment.comment) return nothing;
        return html` <jhd-data-field label="Memo" .value=${this.payment.comment}></jhd-data-field>`;
    }

    render() {
        if (!this.payment) return nothing;
        return html`<div class="p-3 user-detail">
            <div class="flex justify-between">
                <span class="text-lg">${this.payment.payee}</span
                ><span>${this.renderPaymentTypeBadge()}</span>
            </div>
            <div class="text-xl">${this.payment.amount}</div>
            <div class="payment-details">
                <jhd-data-field label="From" .value=${this.payment.fromAccount}></jhd-data-field
                ><jhd-data-field
                    label="Frequency"
                    .value=${this.payment.recurrence}
                ></jhd-data-field
                ><jhd-data-field label="Status" .value=${this.payment.status}></jhd-data-field>
            </div>
            <div class="timeline">
                <jhd-timeline
                    .events=${[
                        {
                            date: this.payment.processDate,
                            eventName: 'Processing',
                            eventCompleted: false,
                        },
                        {
                            date: this.payment.estimatedArrivalDate,
                            eventName: 'Estimated arrival',
                            eventCompleted: true,
                        },
                    ]}
                ></jhd-timeline>
            </div>
            ${this.renderMemo()}
        </div>`;
    }

    static get styles() {
        return [
            css`
                .user-detail {
                    background-color: var(--primary-background);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: BillPayReadOnlyPaymentDetail;
    }
}
