/* eslint-disable no-restricted-globals */
/* eslint-disable lit/binding-positions */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { NavigationService } from '@treasury/core/navigation';
import AccountsService from '@treasury/domain/channel/services/accounts/accounts-service';
import { AccountDto } from '@treasury/domain/channel/types/arp/accounts.dto';
import { TmBaseComponent } from '@treasury/presentation';
import { depositIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-card';
import '@treasury/presentation/components/tm-loader';
import '@treasury/presentation/components/tm-table-header';
import '@treasury/presentation/components/tm-tabs';
import { InjectProperty } from '@treasury/utils';
import '@vaadin/grid';
import { columnBodyRenderer, GridColumnBodyLitRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-sort-column.js';
import { css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';

export const tagName = 'accounts-container';
@customElement(tagName)
export class AccountsContainer extends TmBaseComponent {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare readonly accountsService: AccountsService;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    @state()
    deposits: AccountDto[] = [];

    @state()
    timeDeposits: AccountDto[] = [];

    @state()
    loans: AccountDto[] = [];

    @state()
    tabs = [
        { label: 'All Accounts', id: 'all' },
        { label: 'Deposits', id: 'deposits' },
        { label: 'Time Deposits', id: 'timeDeposits' },
        { label: 'Loans', id: 'loans' },
    ];

    @state()
    loading = true;

    @state()
    activeTab = 'all';

    @state()
    activeList: AccountDto[] = [];

    @state()
    selectedAccounts: AccountDto[] = [];

    @state()
    showTooltip = false;

    get totalDisplayLabel() {
        return this.tabs.find(tab => tab.id === this.activeTab)?.label;
    }

    get totalForDisplayedAccounts() {
        const total = this.activeList.reduce(
            (acc, curr) => Number(acc) + Number(curr.currentBalance),
            0
        );
        const dollarUSLocale = Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return dollarUSLocale.format(total);
    }

    firstUpdated() {
        this.headerService.configure({ title: 'Accounts' });
        this.loading = true;
        Promise.all([
            this.accountsService.getAccountByType('Deposit'),
            this.accountsService.getAccountByType('TimeDeposit'),
            this.accountsService.getAccountByType('Loan'),
        ])
            .then((values: any) => {
                [this.deposits, this.timeDeposits, this.loans] = values;
                this.activeList = [...this.deposits, ...this.timeDeposits, ...this.loans];
            })
            .catch(e => {
                // eslint-disable-next-line no-console
                console.log(e);
            })
            .finally(() => {
                this.loading = false;
            });
    }

    getActiveList() {
        switch (this.activeTab) {
            case 'deposits':
                this.activeList = this.deposits;
                return;
            case 'timeDeposits':
                this.activeList = this.timeDeposits;
                return;
            case 'loans':
                this.activeList = this.loans;
                return;
            default:
                this.activeList = [...this.deposits, ...this.timeDeposits, ...this.loans];
        }
    }

    showHelpTooltip() {
        // TODO: no specs for what this icon is actually supposed to do
    }

    handleAction() {
        // TODO: no specs for what this icon is actually supposed to do
    }

    filterAccounts(event: CustomEvent) {
        const { value } = event.detail;
        this.getActiveList();
        this.activeList = this.activeList.filter(
            account =>
                account.name.toLowerCase().includes(value.toLowerCase()) ||
                account.number.includes(value)
        );
    }

    accountClicked(account: AccountDto) {
        this.selectedAccounts = account ? [account] : [];
        const [selectedAccount] = this.selectedAccounts;
        if (selectedAccount) {
            this.navService.navigate(`/accounts/${selectedAccount.type}/${selectedAccount.id}`);
        }
    }

    renderAccountColumn(account: AccountDto) {
        return html`<div class="account-column">
            <div class="account-name text-sm font-medium">${account.name}</div>
            <small class="account-number text-xs text-[--secondary-text-color]"
                >${account.number} | ${account.type}</small
            >
        </div>`;
    }

    renderBalanceColumn(account: AccountDto) {
        const isLoanOrTime = !!(account.type === 'Loan' || account.type === 'Time Deposit');
        const balanceType = isLoanOrTime ? 'Current' : 'Available';
        const amountDisplayed = isLoanOrTime
            ? account.currentBalanceFormatted
            : account.availableBalanceFormatted;
        return html`<div class="current-balance-column">
            <div class="available-balance text-sm font-medium">${amountDisplayed}</div>
            <small class="balance-type text-xs text-[--secondary-text-color]">${balanceType}</small>
        </div>`;
    }

    renderTable() {
        if (this.loading)
            return html`<div class="w-full pb-8"><tm-loader card class="mb-3"></tm-loader></div>`;
        if (!this.activeList.length)
            return html`<p class="no-accounts m-auto text-center py-9">
                <em>No ${this.totalDisplayLabel} Accounts found</em>
            </p>`;
        return html`
            <vaadin-grid
                .items=${this.activeList}
                .selectedItems=${this.selectedAccounts}
                @active-item-changed=${({ detail }: CustomEvent) =>
                    this.accountClicked(detail.value)}
            >
                <vaadin-grid-column
                    .hidden=${screen.width > 500}
                    ${columnBodyRenderer(
                        this.renderAccountColumn as GridColumnBodyLitRenderer<AccountDto>,
                        []
                    )}
                    auto-width
                    flex-grow="0"
                ></vaadin-grid-column>
                <vaadin-grid-sort-column
                    path="number"
                    auto-width
                    flex-grow="0"
                    .hidden=${screen.width < 500}
                ></vaadin-grid-sort-column>
                <vaadin-grid-sort-column
                    path="name"
                    header="Account Name"
                    .hidden=${screen.width < 500}
                ></vaadin-grid-sort-column>
                <vaadin-grid-sort-column
                    path="status"
                    .hidden=${screen.width < 500}
                ></vaadin-grid-sort-column>
                <vaadin-grid-column
                    text-align="end"
                    ${columnBodyRenderer(
                        this.renderBalanceColumn as GridColumnBodyLitRenderer<AccountDto>,
                        []
                    )}
                ></vaadin-grid-column>
                <vaadin-grid-sort-column
                    header="Available Balance"
                    path="availableBalanceFormatted"
                    .hidden=${screen.width < 500}
                ></vaadin-grid-sort-column>
                <vaadin-grid-sort-column
                    header="Collected Balance"
                    path="collectedBalanceFormatted"
                    .hidden=${screen.width < 500}
                ></vaadin-grid-sort-column>
            </vaadin-grid>
        `;
    }

    renderTotal() {
        if (this.loading || !this.activeList.length) return nothing;
        return html`<div class="amount-header pt-9 pb-6 flex flex-col w-full items-center">
            <div class="amount-container flex items-center">
                <span class="amount-icon mr-1">${depositIcon}</span>
                <span class="amount-total relative font-light text-xl text-[--header-text-color]">
                    <span
                        class="amount-label absolute -mt-4 left-0 uppercase font-normal text-xs text-[--hint-text-color] whitespace-nowrap"
                        >${this.totalDisplayLabel}</span
                    >${this.totalForDisplayedAccounts}
                </span>
            </div>
        </div>`;
    }

    renderTableHeader() {
        if (!this.activeList.length) return nothing;
        return html`<tm-table-header
            .placeholder=${`Search ${this.totalDisplayLabel}`}
            @filterStringChange=${(e: CustomEvent) => this.filterAccounts(e)}
        ></tm-table-header>`;
    }

    renderTabs() {
        return html`<tm-tabs
            .tabs=${this.tabs}
            .activeTab=${this.activeTab}
            @switchTab=${(e: CustomEvent) => {
                this.activeTab = e.detail.activeTab;
                this.getActiveList();
            }}
        ></tm-tabs>`;
    }

    render() {
        return [
            this.renderTabs(),
            this.renderTotal(),
            this.renderTableHeader(),
            this.renderTable(),
        ];
    }

    static get styles() {
        return [
            css`
                :host {
                    width: 100vw;
                    background-color: var(--primary-background);
                    box-shadow: var(--content-shadow);
                }
                vaadin-grid {
                    border-bottom: 1px solid var(--border-color);
                }
                vaadin-grid-cell-content {
                    padding: 6px 16px 6px 0;
                }
                vaadin-grid::part(first-column-cell) {
                    margin-left: 16px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AccountsContainer;
    }
}
