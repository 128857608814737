import { SecurityQuestionModelDto } from '@treasury/api/channel';
import '@treasury/omega/components/omega-select';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-text-field';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

export const tagName = 'security-questions';
@customElement(tagName)
export default class SecurityQuestions extends TmBaseComponent {
    @property({ type: Array })
    securityQuestions: SecurityQuestionModelDto[] = [];

    @property({ type: Boolean })
    loading = false;

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        if (changedProperties.has('loading')) {
            this.dispatchEvent(
                new CustomEvent('loadingStatusChange', {
                    bubbles: true,
                    composed: true,
                    detail: this.loading,
                })
            );
        }
    }

    submit() {
        this.loading = true;
        this.dispatchEvent(new CustomEvent('submit', { detail: this.securityQuestions }));
    }

    renderChallengeQuestions() {
        return html` ${this.securityQuestions.map(
            (val, index) => html`
                <div class="q-a-set">
                    <label for="security-question-${val.id}">${val.text}</label>
                    <tm-text-field
                        id="security-question-${val.id}"
                        @value-changed=${(e: CustomEvent) => {
                            val.answer = e.detail.value;
                            this.securityQuestions.splice(index, 1, val);
                            this.requestUpdate();
                        }}
                        required
                        autocapitalize="off"
                    >
                    </tm-text-field>
                </div>
            `
        )}`;
    }

    render() {
        return html`
            <div id="content" class="flex flex-col justify-end h-full">
                <div class="m-4 mb-0">${this.renderChallengeQuestions()}</div>
                <tm-footer
                    .buttonConfig=${[
                        {
                            text: 'Submit',
                            onClick: () => this.submit(),
                            disabled:
                                this.securityQuestions.some(question => question.answer === null) ||
                                this.loading,
                        },
                    ] as ButtonConfig[]}
                >
                </tm-footer>
            </div>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                :host() {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                :host([stickyheader]) {
                    display: block !important;
                }

                :host([stickyheader]) #content {
                    padding-bottom: var(--sticky-header-height, 0);
                }

                .info-box {
                    border: 1px solid #4384b5;
                    border-left: 4px solid #4384b5;
                    padding: 15px;
                    margin-bottom: 15px;
                    display: flex;
                    border-radius: 3px;
                }
                .q-a-set {
                    margin-bottom: 22px;
                }

                .q-a-set:last-of-type {
                    margin-bottom: 0px;
                }

                tm-footer {
                    padding-top: 1em;
                }

                @media only screen and (min-width: 640px) {
                    :host([stickyheader]) #content {
                        padding-bottom: 0px;
                    }
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: SecurityQuestions;
    }
}
