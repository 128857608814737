import { ProductFeatureType as Feature } from '@treasury/domain/users/product-features/productFeature.type';
import { ProductFeatures } from '@treasury/domain/users/types/user-detail.type';

export type UserProductFeature = {
    label: string;
    value: Feature;
    edited: boolean;
    visible: boolean;
};

export const getFeatures = (userFeatureList: ProductFeatures): UserProductFeature[] =>
    [
        {
            label: 'Account Access',
            value: Feature.AccountAccess,
            edited: userFeatureList.changedAccountAccess,
            visible: true,
        },
        {
            label: 'IP Access',
            value: Feature.IpRestrictions,
            edited: userFeatureList.changedIpRestrictions,
            visible: true,
        },
        {
            label: 'Time Access',
            value: Feature.TimeAccess,
            edited: userFeatureList.changedTimeAccess,
            visible: true,
        },
        {
            label: 'Account Reconciliation',
            value: Feature.AccountRecon,
            edited: userFeatureList.changedAccountReconciliationReporting,
            visible: userFeatureList.hasAccountReconciliationReporting,
        },
        {
            label: 'ACH Entitlements',
            value: Feature.AchEntitlements,
            edited: userFeatureList.changedAchEntitlements,
            visible: userFeatureList.hasAch,
        },
        {
            label: 'ACH User Limits',
            value: Feature.AchUserLimits,
            edited: userFeatureList.changedAchUserLimits,
            visible: userFeatureList.hasAch,
        },
        {
            label: 'Check Exceptions',
            value: Feature.ArpExceptions,
            edited: userFeatureList.changedStandardPosPayEntitlements,
            visible: userFeatureList.hasStandardPosPay,
        },
        {
            label: 'ACH Exceptions',
            value: Feature.AchExceptions,
            edited: userFeatureList.changedStandardPosPayEntitlements,
            visible: userFeatureList.hasStandardPosPay,
        },
        {
            label: 'ACH Filters',
            value: Feature.AchFilters,
            edited: userFeatureList.changedStandardPosPayEntitlements,
            visible: userFeatureList.hasStandardPosPay,
        },
        {
            label: 'Information Reporting',
            value: Feature.InformationReporting,
            edited: userFeatureList.changedInformationReportingEntitlements,
            visible: userFeatureList.hasInformationReporting,
        },
        {
            label: 'Transfer Entitlements',
            value: Feature.TransferEntitlements,
            edited: userFeatureList.changedInternalTransfersEntitlements,
            visible: userFeatureList.hasInternalTransfers,
        },
        {
            label: 'Transfer User Limits',
            value: Feature.TransferUserLimits,
            edited: userFeatureList.changedInternalTransfersUserLimits,
            visible: userFeatureList.hasInternalTransfers,
        },
        {
            label: 'Stop Payment',
            value: Feature.StopPayment,
            edited: userFeatureList.changedStopPaymentEntitlements,
            visible: userFeatureList.hasStopPayment,
        },
        {
            label: 'Wire International Entitlements',
            value: Feature.WireInternationalEntitlements,
            edited: userFeatureList.changedWireTransfersDliEntitlements,
            visible: userFeatureList.hasWireTransfersInternational,
        },
        {
            label: 'Wire Domestic Entitlements',
            value: Feature.WireDomesticEntitlements,
            edited: userFeatureList.changedWireTransfersEntitlements,
            visible: userFeatureList.hasWireTransfersDomestic,
        },
        {
            label: 'Wire User Limits',
            value: Feature.WireUserLimits,
            edited: userFeatureList.changedWireTransfersUserLimits,
            visible: userFeatureList.hasWireTransfers,
        },
        {
            label: 'FX Wire Entitlements',
            value: Feature.FxWireEntitlements,
            edited: userFeatureList.changedWireTransfersDliEntitlements,
            visible: userFeatureList.hasWireTransfersDli,
        },
        {
            label: 'FX Wire User Limits',
            value: Feature.FxWireUserLimits,
            edited: userFeatureList.changedWireTransfersDliUserLimits,
            visible: userFeatureList.hasWireTransfersDli,
        },
        {
            label: 'Receivables Entitlements',
            value: Feature.ReceivablesEntitlements,
            edited: userFeatureList.changedReceivables,
            visible: userFeatureList.hasReceivables,
        },
        {
            label: 'Integrated Services Entitlements',
            value: Feature.IntegratedServicesEntitlements,
            edited: userFeatureList.changedIntegratedServices,
            visible: userFeatureList.hasIntegratedServices,
        },
    ].filter(feature => feature.visible);
