import { EntitlementsClient } from '@treasury/api/channel';
import { Injectable } from '@treasury/utils';

@Injectable()
export class EntitlementsService {
    constructor(private readonly client: EntitlementsClient) {}

    async getEntitlements() {
        const response = await this.client.entitlementsGet({ maxAgeInSeconds: 600 });
        const entitlementsMap = new Map<string, boolean>();
        response.data.map(e => entitlementsMap.set(e.permission, true));
        return entitlementsMap;
    }
}
