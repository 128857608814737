import { SecurityQuestionModelDto } from '@treasury/api/channel';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-select';
import '@treasury/presentation/components/tm-text-field';
import { SelectItem } from '@vaadin/select/src/vaadin-select';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

export const tagName = 'security-questions-enrollment';
@customElement(tagName)
export default class SecurityQuestionsEnrollment extends TmBaseComponent {
    @property({ type: Array })
    securityQuestions: SecurityQuestionModelDto[] = [];

    @state()
    private enrollSecurityQuestions: SecurityQuestionModelDto[] = [];
    @property({ type: Boolean })
    loading = false;

    private questionTotal = 3;

    get securityQuestionOptions(): SelectItem[] {
        return this.securityQuestions.map(sq => ({
            label: sq.text,
            value: sq.id,
        }));
    }

    submit() {
        this.dispatchEvent(new CustomEvent('submit', { detail: this.enrollSecurityQuestions }));
    }

    determineAvailableQuestions(questionIndex: number) {
        let questionOptions = [];
        const questionsPerDropdown = Math.ceil(
            this.securityQuestionOptions.length / this.questionTotal
        );
        const startQuestion = questionIndex * questionsPerDropdown;
        questionOptions = this.securityQuestionOptions.slice(
            startQuestion,
            startQuestion + questionsPerDropdown
        );
        return questionOptions;
    }

    renderEnrollQuestions() {
        const questionInputs = [];
        for (let index = 0; index < this.questionTotal; index++) {
            questionInputs.push(html`
                <tm-select
                    label="Question ${index + 1}"
                    .items=${this.determineAvailableQuestions(index)}
                    required
                    @value-changed=${(e: CustomEvent) => {
                        if (e.detail.value === '') return;
                        const questionId = e.detail.value;
                        const question = this.securityQuestions.find(q => q.id === questionId);
                        if (question) this.enrollSecurityQuestions.splice(index, 1, question);
                        this.requestUpdate();
                    }}
                ></tm-select>
                <tm-text-field
                    required
                    @value-changed=${(e: CustomEvent) => {
                        if (e.detail.value === '') return;
                        const answer = e.detail.value;
                        this.enrollSecurityQuestions[index].answer = answer;
                        this.requestUpdate();
                    }}
                    .disabled=${!this.enrollSecurityQuestions?.[index]}
                    autocapitalize="off"
                ></tm-text-field>
            `);
        }
        return html`
            <div class="info-box">
                For additional authentication purposes, please select 3 security questions and
                provide an answer for each question. You may be prompted to answer security
                questions at login.
            </div>
            ${questionInputs}
        `;
    }

    render() {
        return html`
            <div id="content" class="m-4 mb-auto">${this.renderEnrollQuestions()}</div>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: 'Submit',
                        onClick: () => this.submit(),
                        disabled:
                            this.enrollSecurityQuestions.length !== this.questionTotal ||
                            this.enrollSecurityQuestions.some(question => question.answer === null),
                    },
                ] as ButtonConfig[]}
            >
            </tm-footer>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                #content {
                    padding-bottom: var(--sticky-header-height, 0);
                }
                .info-box {
                    border: 1px solid #4384b5;
                    border-left: 4px solid #4384b5;
                    padding: 15px;
                    margin-bottom: 15px;
                    display: flex;
                    border-radius: 3px;
                }
                .q-a-set {
                    margin-bottom: 32px;
                }

                tm-footer {
                    padding-top: 1em;
                }

                @media only screen and (min-width: 640px) {
                    #content {
                        padding-bottom: 0px;
                    }

                    tm-footer {
                        position: static;
                    }
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: SecurityQuestionsEnrollment;
    }
}
