/* eslint-disable lit/no-native-attributes */
import { GetUserProductEntitlementsModelDto as EntitlementsDto } from '@treasury/api/channel';
import { PendingApprovalsService } from '@treasury/domain/approvals/approvals.service';
import {
    AchEntitlements,
    UserTransferLimits,
    UserWireLimits,
} from '@treasury/domain/users/product-features';
import { IntegratedServicesEntitlements } from '@treasury/domain/users/product-features/integrated-services-entitlements.entity';
import {
    isStandardizedFeature,
    ProductFeatureType as Feature,
} from '@treasury/domain/users/product-features/productFeature.type';
import { UserAccountReconEntitlements } from '@treasury/domain/users/types/user-account-recon-entitlements.type';
import { UsersService } from '@treasury/domain/users/users.service';
import { NotificationService, TmContainer } from '@treasury/presentation';
import { chevronForwardIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-footer';
import '@treasury/presentation/components/tm-tabs';
import { InjectProperty, phoneFormatter } from '@treasury/utils';
import { columnBodyRenderer } from '@vaadin/grid/lit.js';
import { css, html, nothing, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import '../../components/jhd-list-selection-item';
import '../../components/jhd-status';
import '../../components/templates/treasury-pending-approval.template';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import { UserAccountReconViewModel as AccountReconVm } from '../data/user-account-recon-view-model';
import { UserAchEntitlementViewModel as AchEntitlementVm } from '../data/user-ach-entitlements-view-model';
import { UserAchLimitsViewModel } from '../data/user-ach-limits-view-model';
import { UserEntitlementsVm } from '../data/user-entitlements-view-model';
import { UserIntegratedServicesEntitlementsViewModel } from '../data/user-integrated-services-entitlements-view-model';
import { UserReceivablesEntitlementsViewModel as ReceivablesEntitlementsVm } from '../data/user-receivables-entitlements-view-model';
import { UserTransferLimitsViewModel as TransferLimitsVm } from '../data/user-transfer-limits-view-model';
import { UserViewModel } from '../data/user-view-model';
import { UserWireLimitsViewModel as WireLimitsVm } from '../data/user-wire-limits-view-model';
import { getFeatures, UserProductFeature } from '../helpers/map-user-product-features';
import { isSuperUserFeature } from '../helpers/super-user-features';
import '../partials/user-account-access';
import '../partials/user-account-recon';
import '../partials/user-ach-entitlements';
import '../partials/user-ach-limits';
import '../partials/user-entitlements';
import '../partials/user-fx-wire-entitlements';
import '../partials/user-fx-wire-limits';
import '../partials/user-integrated-services';
import '../partials/user-ip-restrictions';
import '../partials/user-receivables-entitlements';
import '../partials/user-time-access';
import '../partials/user-transfer-limits';
import '../partials/user-wire-limits';

type Config = {
    headerLabel: string | ((config: any) => TemplateResult<1>);
    tableLabel?: string;
    hasGlobalPermissions?: true;
};
const configs: Record<Feature, Config> = {
    [Feature.AccountAccess]: { headerLabel: 'Account Access' },
    [Feature.AccountRecon]: { headerLabel: 'Account Recon Reporting' },
    [Feature.AchEntitlements]: { headerLabel: 'Ach Entitlements' },
    [Feature.AchUserLimits]: { headerLabel: 'ACH Limits' },
    [Feature.FxWireEntitlements]: { headerLabel: 'FX Wire Entitlements' },
    // [Feature.FxWireUserLimits]: { headerLabel: 'FX Wire Limits'},
    [Feature.IntegratedServicesEntitlements]: { headerLabel: 'Integrated Services' },
    [Feature.IpRestrictions]: {
        headerLabel: c =>
            html`IP Restrictions <br />
                <small>${c?.restricted ? 'Restricted' : 'Unrestricted'}</small>`,
    },
    [Feature.ReceivablesEntitlements]: { headerLabel: 'Receivables Entitlements' },
    [Feature.TimeAccess]: {
        headerLabel: c =>
            html`Time Access <br />
                <small class="text-xs">${c?.isRestricted ? '' : 'Unrestricted'}</small>`,
    },
    [Feature.TransferUserLimits]: { headerLabel: 'Transfer Limits' },
    [Feature.WireUserLimits]: { headerLabel: 'Wire Limits' },
    [Feature.InformationReporting]: { headerLabel: 'Information Reporting' },
    [Feature.StopPayment]: { headerLabel: 'Stop Payment' },
    [Feature.ArpExceptions]: { headerLabel: 'Check Exceptions' },
    [Feature.AchExceptions]: { headerLabel: 'ACH Exceptions' },
    [Feature.AchFilters]: { headerLabel: 'ACH Filters' },
    [Feature.TransferEntitlements]: { headerLabel: 'Transfer Entitlements' },
    [Feature.WireInternationalEntitlements]: {
        headerLabel: 'Wire International Entitlements',
        tableLabel: 'International Wire',
        hasGlobalPermissions: true,
    },
    [Feature.WireDomesticEntitlements]: {
        headerLabel: 'Wire Domestic Entitlements',
        tableLabel: 'Domestic Wire',
        hasGlobalPermissions: true,
    },
};

export const tagName = 'user-container';
@customElement(tagName)
export class UserContainer extends TmContainer {
    protected verifiedPropNames: readonly (keyof this)[] = ['userId'];

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare approvalService: PendingApprovalsService;

    @InjectProperty()
    private declare usersService: UsersService;

    @property({ type: Boolean, reflect: true })
    public hideHeader = true;

    @property({ type: Number })
    public userId!: number;

    @state()
    private user?: UserViewModel;

    @state()
    private activeTab = 'all';

    @state()
    // TODO: remove use of any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private userProductFeatures: Record<string, any> = {};

    @state()
    private paneLoading = false;

    @state()
    private selectedFeature: Feature | '' = '';

    @state()
    private showApproveOrRejectPrompt = false;

    @state()
    private selectedAction = '';

    @InjectProperty()
    private declare notificationService: NotificationService;

    get selectedFeatureVm() {
        return this.userProductFeatures[this.selectedFeature];
    }

    get isSuperUser() {
        return this.user?.value.isSuperUser.value ?? false;
    }

    async firstUpdated() {
        if (!this.hideHeader) this.headerService.configure({ title: 'User Detail' });
        await this.tryFetch(
            () => this.usersService.getUserDetails(this.userId),
            u => (this.user = new UserViewModel(u))
        );
    }

    updated(changedProperties: Map<string, unknown>) {
        if (changedProperties.has('userId')) {
            this.fetchFeatureDetails();
        }
    }

    async fetchFeatureDetails() {
        if (!this.selectedFeature) return;

        const { userId, selectedFeature } = this;
        const getEntitlements = this.usersService.getUserProductEntitlements.bind(
            this.usersService
        );
        const s = this.usersService;
        let vm: any;

        this.paneLoading = true;
        switch (selectedFeature) {
            case Feature.AccountAccess:
                const { accounts } = await s.getUserAccountAccess(userId);
                this.userProductFeatures = { ...this.userProductFeatures, accounts };
                break;
            case Feature.IpRestrictions:
                const ipRestrictions = await s.getUserIpRestrictions(userId);
                this.userProductFeatures = { ...this.userProductFeatures, ipRestrictions };
                break;
            case Feature.TimeAccess:
                const timeAccess = await s.getUserTimeAccess(userId);
                this.userProductFeatures = { ...this.userProductFeatures, timeAccess };
                break;
            case Feature.AccountRecon:
                const recon = await getEntitlements(userId, selectedFeature);
                vm = new AccountReconVm(recon as UserAccountReconEntitlements);
                this.userProductFeatures = { ...this.userProductFeatures, accountRecon: vm };
                break;
            case Feature.AchEntitlements:
                const achEntitlements = await s.getUserAchProductEntitlements(userId);
                vm = new AchEntitlementVm(achEntitlements as AchEntitlements);
                this.userProductFeatures = { ...this.userProductFeatures, achEntitlements: vm };
                break;
            case Feature.AchUserLimits:
                const achLimits = await s.getUserAchLimits(userId);
                vm = new UserAchLimitsViewModel(achLimits);
                this.userProductFeatures = { ...this.userProductFeatures, achLimits: vm };
                break;
            case Feature.TransferUserLimits:
                const transferLimits = await s.getUserLimits(userId, 'InternalTransfer');
                vm = new TransferLimitsVm(transferLimits as UserTransferLimits);
                this.userProductFeatures = { ...this.userProductFeatures, [selectedFeature]: vm };
                break;
            case Feature.WireUserLimits:
                const wireLimits = await s.getUserLimits(userId, selectedFeature);
                vm = new WireLimitsVm(wireLimits as UserWireLimits);
                this.userProductFeatures = { ...this.userProductFeatures, [selectedFeature]: vm };
                break;
            case Feature.ReceivablesEntitlements:
                const receivablesEntitlements = await s.getUserReceivablesSettings(userId);
                vm = new ReceivablesEntitlementsVm(receivablesEntitlements);
                this.userProductFeatures = { ...this.userProductFeatures, [selectedFeature]: vm };
                break;
            case Feature.IntegratedServicesEntitlements:
                const integratedEntitlements = await getEntitlements(userId, selectedFeature);
                vm = new UserIntegratedServicesEntitlementsViewModel(
                    integratedEntitlements as IntegratedServicesEntitlements
                );
                this.userProductFeatures = { ...this.userProductFeatures, [selectedFeature]: vm };
                break;
            case Feature.InformationReporting:
            case Feature.WireInternationalEntitlements:
            case Feature.WireDomesticEntitlements:
            case Feature.StopPayment:
            case Feature.ArpExceptions:
            case Feature.AchExceptions:
            case Feature.AchFilters:
            case Feature.TransferEntitlements:
                const entitlements = await getEntitlements(userId, selectedFeature);
                vm = new UserEntitlementsVm(entitlements as EntitlementsDto);
                this.userProductFeatures = { ...this.userProductFeatures, [selectedFeature]: vm };
                break;
        }
        this.paneLoading = false;
    }

    private async approveOrReject({ user, comments = '' }: { user: any; comments: string }) {
        const event = new CustomEvent('approve-or-reject', { composed: true, bubbles: true });

        await this.tryFetch(
            () => this.approvalService.approveOrRejectUsers([user], comments),
            () => this.dispatchEvent(event),
            e => this.notificationService.renderError(e as Error)
        );
    }

    isSelectedFeature(feature: Feature) {
        if (!this.selectedFeature) return false;
        return this.selectedFeature === feature;
    }

    renderSuperUserMessage() {
        this.notificationService.renderWarning({
            header: 'Super User',
            content:
                'Super user has access to all accounts and product feature permissions that are enabled for the company. This will include accounts and product features enabled in the future.',
        });
    }

    renderSuperUserBadge() {
        if (!this.isSuperUser) return nothing;
        return html`<jha-chip small
            ><span class=${classMap({ edited: this.user?.isSuperUser.edited ?? false })}
                >Super User</span
            ></jha-chip
        >`;
    }

    renderAdminBadge() {
        if (!this.user?.value.isAdmin.value) return nothing;
        return html`<jha-chip small
            ><span class=${classMap({ edited: this.user?.isAdmin.edited ?? false })}
                >Admin</span
            ></jha-chip
        >`;
    }

    renderEnrollmentBadge() {
        if (!this.user?.value.isEnrolled) return html`<jha-chip small>Not Enrolled</jha-chip>`;
        return html`<jha-chip small>Enrolled</jha-chip>`;
    }

    renderApprovalInformation() {
        if (this.loading) return nothing;
        if (this.user?.value.pendingState !== 'Pending') return nothing;
        return html`<treasury-pending-approval-template
            class="mb-2"
            .payment=${this.user}
            .eligibleApproversFunction=${async () => {
                const approvers = (await UsersService.fetchAvailableApprovers({
                    approvalEntity: 'userPayment',
                    productId: this.userId,
                })) as unknown as string[];
                return approvers.filter(
                    (user: string) => user !== this.user?.value.editedByUserName
                );
            }}
        ></treasury-pending-approval-template>`;
    }

    renderUserDetails() {
        if (!this.user) return nothing;
        const { name, loginId } = this.user.value;
        const nameClasses = classMap({ 'text-lg': true, 'edited p-1': name.edited ?? false });
        const idClasses = classMap({ 'text-gray-500': true, 'edited p-1': loginId.edited });
        return html`<div id="user-details" class="px-2 py-3 mt-2">
            <div class="flex w-full justify-between">
                <div class="user-type-badges mb-2">
                    ${this.renderSuperUserBadge()}${this.renderAdminBadge()}
                </div>
                <div class="enrollment-badge">${this.renderEnrollmentBadge()}</div>
            </div>
            <div class="flex w-full justify-between">
                <div>
                    <p class=${nameClasses}>${this.user.value.name.value}</p>
                    <small class=${idClasses}>${this.user.value.loginId.value}</small>
                </div>
                <div>
                    <span class="status">
                        <jhd-status .status=${this.user.value.pendingState} showLabel></jhd-status>
                    </span>
                </div>
            </div>
            <jhd-data-field
                .label=${'Email'}
                .value=${this.user.value.email.value}
                .edited=${this.user.value.email.edited}
            ></jhd-data-field>
            <jhd-data-field
                .label=${'Phone'}
                .value=${phoneFormatter(this.user.value.phone.value || '')}
                .edited=${this.user.value.phone.edited}
            ></jhd-data-field>
            <jhd-data-field
                .label=${'Department'}
                .value=${this.user.value.department.value}
                .edited=${this.user.value.department.edited}
            ></jhd-data-field>
        </div>`;
    }

    renderSheetHeader() {
        if (!this.selectedFeature) return nothing;

        const { headerLabel } = configs[this.selectedFeature];
        const label =
            typeof headerLabel === 'function'
                ? headerLabel(this.userProductFeatures.ipRestrictions)
                : headerLabel;

        return html`<div slot="header-center" class="text-lg font-normal">${label}</div> `;
    }

    renderStandardizedComponent() {
        if (!this.selectedFeature || this.paneLoading) return nothing;
        const c = configs[this.selectedFeature];
        return html`<user-entitlements
            tableLabel=${ifDefined(c.tableLabel)}
            .accounts=${this.selectedFeatureVm?.accounts}
            .permissions=${c.hasGlobalPermissions ? this.selectedFeatureVm?.permissions : undefined}
        ></user-entitlements>`;
    }

    renderUniqueComponent() {
        if (!this.selectedFeature || this.paneLoading) return nothing;

        const f = this.userProductFeatures;
        const vm = this.selectedFeatureVm;
        switch (this.selectedFeature) {
            case Feature.AccountAccess:
                return html` <user-account-access .config=${f.accounts}></user-account-access> `;
            case Feature.AccountRecon:
                return html`<user-account-recon .config=${f.accountRecon}></user-account-recon> `;
            case Feature.AchEntitlements:
                return html`<user-ach-entitlements
                    .config=${f.achEntitlements}
                ></user-ach-entitlements>`;
            case Feature.AchUserLimits:
                return html`<user-ach-limits .config=${f.achLimits}></user-ach-limits> `;
            case Feature.FxWireEntitlements:
                return html`<user-fx-wire-entitlements></user-fx-wire-entitlements> `;
            case Feature.FxWireUserLimits:
                return html`<user-fx-wire-user-limits></user-fx-wire-user-limits> `;
            case Feature.IntegratedServicesEntitlements:
                return html`<user-integrated-services .config=${vm}></user-integrated-services>`;
            case Feature.IpRestrictions:
                return html`<user-ip-restrictions
                    .config=${f.ipRestrictions}
                ></user-ip-restrictions>`;
            case Feature.ReceivablesEntitlements:
                return html`<user-receivables-entitlements
                    .config=${vm}
                ></user-receivables-entitlements> `;
            case Feature.TimeAccess:
                return html` <user-time-access .config=${f.timeAccess}></user-time-access> `;
            case Feature.TransferUserLimits:
                return html`<user-transfer-limits .config=${vm}></user-transfer-limits> `;
            case Feature.WireUserLimits:
                return html`<user-wire-limits .config=${vm}></user-wire-limits> `;
        }
    }

    renderPaneDetails() {
        return html`<tm-bottom-sheet
            horizontal
            .open=${Boolean(this.selectedFeature)}
            .loading=${this.paneLoading}
            @close=${() => (this.selectedFeature = '')}
        >
            ${this.renderSheetHeader()}
            ${isStandardizedFeature(this.selectedFeature)
                ? this.renderStandardizedComponent()
                : this.renderUniqueComponent()}
        </tm-bottom-sheet>`;
    }

    renderProductFeature(product: UserProductFeature) {
        const editedBadge = product.edited ? html`<jha-badge>Edited</jha-badge>` : nothing;
        const showAll = this.isSuperUser && isSuperUserFeature(product.value);
        const chevronOrAll = showAll
            ? html`<span class="text-sm font-normal ml-2">All</span>`
            : chevronForwardIcon;
        return html`<button
            class="w-full"
            @click=${() => {
                if (showAll) {
                    this.renderSuperUserMessage();
                } else {
                    this.selectedFeature = product.value;
                    this.fetchFeatureDetails();
                }
            }}
        >
            <div class="flex justify-between items-center py-2">
                <div class="flex flex-col items-end">
                    <span class="text-sm font-medium">${product.label}</span>
                </div>
                <div class="action-icon flex justify-end w-10 ml-1">
                    ${editedBadge} <span>${chevronOrAll}</span>
                </div>
            </div>
        </button>`;
    }

    renderFeatureList() {
        if (!this.user) return nothing;
        let features = getFeatures(this.user.features);
        if (this.activeTab === 'edited') features = features.filter(f => f.edited);
        return html`<vaadin-grid class="border-y border-neutral-200" .items=${features}>
            <vaadin-grid-column
                ${columnBodyRenderer<UserProductFeature>(this.renderProductFeature, [])}
            ></vaadin-grid-column>
        </vaadin-grid>`;
    }

    renderUserProductFeatures() {
        if (this.loading) return nothing;
        return html`<div class="my-2 user-features">
            <p id="settings-header" class="py-3 ml-3">User Product Settings</p>
            <tm-tabs
                .activeTab=${this.activeTab}
                .tabs=${[
                    { label: 'All Settings', id: 'all' },
                    { label: 'Edited', id: 'edited' },
                ]}
                @switchTab=${(event: CustomEvent) => {
                    this.activeTab = event.detail.activeTab;
                }}
            ></tm-tabs>
            ${this.renderFeatureList()}
        </div>`;
    }

    renderApproveOrRejectPrompt() {
        const selectedState = this.selectedAction === 'approve' ? 'Approved' : 'Rejected';
        return html`<approve-or-reject-prompt
            .selectedAction=${this.selectedAction}
            .selectedApprovals=${[this.user?.value]}
            .showApproveOrRejectPrompt=${this.showApproveOrRejectPrompt}
            .paymentType=${'user'}
            @approve-or-reject=${() => {
                this.approveOrReject({ user: { ...this.user, selectedState }, comments: '' });
                this.showApproveOrRejectPrompt = false;
            }}
            @close=${() => (this.showApproveOrRejectPrompt = false)}
        ></approve-or-reject-prompt>`;
    }

    renderApproveRejectButtons() {
        if (this.user?.value.pendingState !== 'Pending') return nothing;
        if (this.user?.isEditor) return nothing;
        return html`
            <tm-footer
                direction="row"
                .buttonConfig=${[
                    {
                        error: true,
                        text: 'Reject',
                        classes: 'w-full m-1',
                        onClick: () => {
                            this.showApproveOrRejectPrompt = true;
                            this.selectedAction = 'reject';
                        },
                    },
                    {
                        success: true,
                        text: 'Approve',
                        classes: 'w-full m-1',
                        onClick: () => {
                            this.showApproveOrRejectPrompt = true;
                            this.selectedAction = 'approve';
                        },
                    },
                ]}
            >
            </tm-footer>
        `;
    }

    render() {
        return [
            this.renderLoader(),
            this.renderApprovalInformation(),
            this.renderUserDetails(),
            this.renderUserProductFeatures(),
            this.renderApproveRejectButtons(),
            this.renderApproveOrRejectPrompt(),
            this.renderPaneDetails(),
        ];
    }

    static get styles() {
        return [
            css`
                :host {
                    --jha-chip-border: gray;
                    --jha-chip-background: #f5f5f5;
                }
                #pending-approvals,
                #user-details,
                .user-features,
                .button-bar {
                    background-color: var(--primary-background);
                }
                *[button-reset] {
                    touch-action: manipulation;
                    background: none;
                    border: none;
                    color: var(--jha-text-dark);
                    cursor: pointer;
                    display: flex;
                    font-size: 14px;
                    margin: 0;
                    padding: 5px;
                    text-align: left;
                    width: 100%;
                    -webkit-appearance: none;
                    justify-content: space-between;
                    align-items: center;
                }
                jha-list-item {
                    border-bottom: 1px solid var(--content-background);
                .status svg {
                    display: inline-block;
                }
                .status,
                #settings-header {
                    color: var(--header-text-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: UserContainer;
    }
}
