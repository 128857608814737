import { svg } from 'lit';

export const filterIcon = svg`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.25 16.25H18.41C18.0697 14.6353 16.6451 13.4796 14.995 13.4796C13.3449 13.4796 11.9203 14.6353 11.58 16.25H2.75C2.33579 16.25 2 16.5858 2 17C2 17.4142 2.33579 17.75 2.75 17.75H11.59C11.9303 19.3646 13.3549 20.5203 15.005 20.5203C16.6551 20.5203 18.0797 19.3646 18.42 17.75H21.26C21.6742 17.75 22.01 17.4142 22.01 17C22.01 16.5858 21.6742 16.25 21.26 16.25H21.25ZM15 19C14.1202 19.0018 13.3426 18.4284 13.0842 17.5874C12.8258 16.7464 13.1473 15.8353 13.8762 15.3428C14.6052 14.8502 15.5705 14.8919 16.2543 15.4454C16.9381 15.999 17.1799 16.9344 16.85 17.75C16.5449 18.5041 15.8135 18.9983 15 19ZM12.41 6.24993H21.25H21.26C21.6742 6.24993 22.01 6.58572 22.01 6.99993C22.01 7.41415 21.6742 7.74993 21.26 7.74993H12.42C12.0797 9.3646 10.6551 10.5203 9.005 10.5203C7.35488 10.5203 5.93025 9.3646 5.59 7.74993H2.75C2.33579 7.74993 2 7.41415 2 6.99993C2 6.58572 2.33579 6.24993 2.75 6.24993H5.58C5.92025 4.63526 7.34488 3.47955 8.995 3.47955C10.6451 3.47955 12.0697 4.63526 12.41 6.24993ZM7 6.99993C7 8.10451 7.89543 8.99994 9 8.99994C9.53043 8.99994 10.0391 8.78922 10.4142 8.41415C10.7893 8.03908 11 7.53037 11 6.99993C11 5.89536 10.1046 4.99992 9 4.99992C7.89543 4.99992 7 5.89536 7 6.99993Z" fill="#786E5C"/>
<mask id="mask0_2_741" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="2" y="3" width="21" height="18">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.25 16.25H18.41C18.0697 14.6353 16.6451 13.4796 14.995 13.4796C13.3449 13.4796 11.9203 14.6353 11.58 16.25H2.75C2.33579 16.25 2 16.5858 2 17C2 17.4142 2.33579 17.75 2.75 17.75H11.59C11.9303 19.3646 13.3549 20.5203 15.005 20.5203C16.6551 20.5203 18.0797 19.3646 18.42 17.75H21.26C21.6742 17.75 22.01 17.4142 22.01 17C22.01 16.5858 21.6742 16.25 21.26 16.25H21.25ZM15 19C14.1202 19.0018 13.3426 18.4284 13.0842 17.5874C12.8258 16.7464 13.1473 15.8353 13.8762 15.3428C14.6052 14.8502 15.5705 14.8919 16.2543 15.4454C16.9381 15.999 17.1799 16.9344 16.85 17.75C16.5449 18.5041 15.8135 18.9983 15 19ZM12.41 6.24993H21.25H21.26C21.6742 6.24993 22.01 6.58572 22.01 6.99993C22.01 7.41415 21.6742 7.74993 21.26 7.74993H12.42C12.0797 9.3646 10.6551 10.5203 9.005 10.5203C7.35488 10.5203 5.93025 9.3646 5.59 7.74993H2.75C2.33579 7.74993 2 7.41415 2 6.99993C2 6.58572 2.33579 6.24993 2.75 6.24993H5.58C5.92025 4.63526 7.34488 3.47955 8.995 3.47955C10.6451 3.47955 12.0697 4.63526 12.41 6.24993ZM7 6.99993C7 8.10451 7.89543 8.99994 9 8.99994C9.53043 8.99994 10.0391 8.78922 10.4142 8.41415C10.7893 8.03908 11 7.53037 11 6.99993C11 5.89536 10.1046 4.99992 9 4.99992C7.89543 4.99992 7 5.89536 7 6.99993Z" fill="white"/>
</mask>
<g mask="url(#mask0_2_741)">
</g>
</svg>`;
