import { format } from 'date-fns';
import { DateRange, DateRangeType } from './tm-date-range';

export const mapDateRangeToDto = (dateRange?: DateRange, specificRange = true) => {
    if (!dateRange) {
        return {
            operator: undefined,
            dateFrom: undefined,
            dateTo: undefined,
            specificDate: undefined,
        };
    }
    const dateFrom = dateRange.startDate ? format(dateRange.startDate, 'MM/dd/yyyy') : undefined;
    const rangeValue = specificRange ? 'Date Range' : 'Range';
    return {
        operator:
            dateRange.rangeType === DateRangeType.SpecificDate ||
            dateRange.rangeType === DateRangeType.Today
                ? 'Specific Date'
                : rangeValue,
        dateFrom,
        dateTo: dateRange.endDate ? format(dateRange.endDate, 'MM/dd/yyyy') : undefined,
        specificDate:
            dateRange.rangeType === DateRangeType.SpecificDate ||
            dateRange.rangeType === DateRangeType.Today
                ? dateFrom
                : undefined,
    };
};
