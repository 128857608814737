import { IntegratedServicesEntitlements } from '@treasury/domain/users/product-features/integrated-services-entitlements.entity';

export class UserIntegratedServicesEntitlementsViewModel {
    constructor(readonly value: IntegratedServicesEntitlements) {
        this.value = value;
    }

    get permissions() {
        return this.value.permissions;
    }
}
