import { PaymentStatusDto } from '@treasury/api/channel';
import { PaymentListModel } from '@treasury/domain/bill-pay/payment-list.entity';
import { FiDate } from '@treasury/domain/dates';
import {
    checkIcon,
    chevronLeftIcon,
    circleCloseIcon,
    clockIcon,
} from '@treasury/presentation/assets/icons';
import { formatUsdCurrency } from '@treasury/utils/functions/currency.helpers';
import { format } from 'date-fns';
import { TemplateResult, html } from 'lit';

export class BillPayPaymentTableViewModel {
    constructor(payment: PaymentListModel) {
        this.status = payment.status;
        this.statusColor = this.getStatusColor(payment.toDto().status) ?? '';
        this.statusIcon = this.getStatusIcon(payment.toDto().status) ?? html``;
        this.payeeName = payment.payee?.name;
        this.amount = formatUsdCurrency(payment.amount);
        this.processDate = format(new FiDate(payment.processDate).date, 'P');
        this.id = payment.id;
    }

    getStatusIcon(status: PaymentStatusDto | undefined) {
        switch (status) {
            case PaymentStatusDto.Unknown:
                return clockIcon;
            case PaymentStatusDto.Processed:
                return checkIcon;
            case PaymentStatusDto.Paid:
                return checkIcon;
            case PaymentStatusDto.Stopped:
                return circleCloseIcon;
            case PaymentStatusDto.Cancelled:
                return circleCloseIcon;
            case PaymentStatusDto.Resubmitted:
                return clockIcon;
            case PaymentStatusDto.Refunded:
                return chevronLeftIcon;
            case PaymentStatusDto.Skipped:
                return clockIcon;
            case PaymentStatusDto.Approved:
                return checkIcon;
            case PaymentStatusDto.Scheduled:
                return clockIcon;
            case PaymentStatusDto.Pending:
                return clockIcon;
            case PaymentStatusDto.PendingSkip:
                return clockIcon;
            case PaymentStatusDto.ApprovalRequired:
                return clockIcon;
            case PaymentStatusDto.Settled:
                return clockIcon;
            case PaymentStatusDto.Returned:
                return chevronLeftIcon;
        }
    }

    getStatusColor(status: PaymentStatusDto | undefined) {
        switch (status) {
            case PaymentStatusDto.Unknown:
                return '#777777';
            case PaymentStatusDto.Processed:
                return '#047712';
            case PaymentStatusDto.Paid:
                return '#047712';
            case PaymentStatusDto.Stopped:
                return '#C91D07';
            case PaymentStatusDto.Cancelled:
                return '#C91D07';
            case PaymentStatusDto.Resubmitted:
                return '#777777';
            case PaymentStatusDto.Refunded:
                return '#777777';
            case PaymentStatusDto.Skipped:
                return '#777777';
            case PaymentStatusDto.Approved:
                return '#047712';
            case PaymentStatusDto.Scheduled:
                return '#EC9241';
            case PaymentStatusDto.Pending:
                return '#EC9241';
            case PaymentStatusDto.PendingSkip:
                return '#777777';
            case PaymentStatusDto.ApprovalRequired:
                return '#EC9241';
            case PaymentStatusDto.Settled:
                return '#777777';
            case PaymentStatusDto.Returned:
                return '#777777';
        }
    }

    status: string | undefined;

    statusColor: string;

    statusIcon: TemplateResult | undefined;

    payeeName: string | undefined;

    amount: string;

    processDate: string;

    id: string | undefined;
}
