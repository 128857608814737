import JhaFormTextInput from '@banno/jha-wc/src/forms/jha-form-text-input/JhaFormTextInput';
import '@banno/jha-wc/src/jha-tooltip/JhaTooltip.js';
import { informationIcon } from '@treasury/presentation/assets/icons';
import { StyleMixin } from '@treasury/presentation/mix-ins/style-mixin';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import './jhd-icon';

export const tagName = 'jhd-text-input';
@customElement(tagName)
export class JhdTextInput extends StyleMixin(JhaFormTextInput) {
    @property({ type: String })
    tooltip: string | undefined;

    @property({ type: String, reflect: true })
    characterLimit: string | undefined;

    @property({ type: String, reflect: true })
    pattern: string | undefined;

    renderTooltip() {
        if (!this.tooltip) return nothing;

        return html`<jha-tooltip text=${this.tooltip} top-left>
            <jhd-icon .icon=${informationIcon} .color=${this.disabled ? '#ccc' : '#666'}></jhd-icon>
        </jha-tooltip>`;
    }

    render() {
        return html`
            <div class="jhd-text-input">
                <jha-form-floating-group
                    ?outline=${this.outline}
                    ?small=${this.small}
                    ?filled=${this.filled}
                    ?always-float=${Boolean(this.placeholder)}
                    ?no-label=${this.noLabel}
                    ?has-value=${Boolean(this.value)}
                    .error=${this.showWarning_() ? this.warning : null}
                    .assistiveText=${this.assistiveText}
                >
                    <input
                        class="${classMap({
                            'pb-0': true,
                            'pl-2': true,
                            'pt-3': !!this.label,
                            'pt-0': !this.label,
                            'pr-2': !this.tooltip,
                            'pr-9': !!this.tooltip,
                        })}"
                        id=${this.id}
                        name=${this.id}
                        placeholder=${this.placeholder}
                        ?required=${this.required}
                        ?readonly=${this.readonly}
                        ?disabled=${this.disabled}
                        type="text"
                        minlength=${this.minlength}
                        maxlength=${this.characterLimit ? this.characterLimit : this.maxlength}
                        .value=${this.value}
                        aria-label=${this.label}
                        .title=${this.caption}
                        .pattern=${this.pattern}
                        @input=${this.onInput_}
                        @change=${this.onChange_}
                        @blur=${this.onBlur_}
                        autocomplete=${this.autocomplete}
                        list=${this.list}
                    />
                    ${this.renderTooltip()}
                    <label for="input" class="label"
                        >${this.required ? `${this.label} *` : this.label}</label
                    >
                </jha-form-floating-group>
                <div class="details">
                    <span>${this.caption}</span>
                    ${this.characterLimit
                        ? html` <span class="character-limit"
                              >${this.value.length}/${this.characterLimit}</span
                          >`
                        : ''}
                </div>
            </div>
        `;
    }

    static get styles() {
        return [
            ...super.styles,
            css`
                :host {
                    --jha-form-floating-group-label-top: 15px;
                    --jha-input-margin-bottom: 16px;
                    --jha-input-padding: 6px 0 3px;
                }
                input[disabled] {
                    color: var(
                        --jha-form-floating-group-input-disabled-text-color,
                        var(--jha-text-light)
                    );
                    opacity: var(--jhd-disabled-input-opacity, 0.7);
                }

                .jhd-text-input {
                    position: relative;
                    width: 100%;
                    margin: 0;
                }

                jha-tooltip {
                    position: absolute;
                    top: 25px;
                    right: 10px;
                    cursor: pointer;
                    z-index: 2;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: JhdTextInput;
    }
}
