/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { DownloadOptionDto } from '../';
import { HttpClient, RequestParams } from './http-client';

@Injectable()
export class DownloadClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Download
     * @name DownloadGetDownloadOptions
     * @summary Gets download options available for downloads.
     * @request GET:/Download/GetDownloadOptions
     */
    downloadGetDownloadOptions = (
        query: {
            /** The DownloadPage instance. */
            downloadPage:
                | 0
                | 1
                | 2
                | 3
                | 4
                | 5
                | 6
                | 7
                | 8
                | 9
                | 10
                | 11
                | 12
                | 13
                | 14
                | 15
                | 16
                | 17
                | 18
                | 19;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<DownloadOptionDto[], any>({
            path: `/Download/GetDownloadOptions`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
}
