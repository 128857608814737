import { TmBaseComponent } from '@treasury/presentation';
import { mapFrequencyModelDtoToFrequency } from '@treasury/presentation/components/tm-frequency.mappings';
import { summarizeFrequency } from '@treasury/presentation/components/tm-frequency.summarizer';
import { FrequencyType } from '@treasury/presentation/components/tm-frequency.types';
import { format } from 'date-fns';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { TransferViewModel } from '../types/transfer-view-model.type';

export const tagName = 'transfer-template-detail';
@customElement(tagName)
export class TransferTemplateDetail extends TmBaseComponent {
    @property({ type: Object })
    transfer = {} as TransferViewModel;

    get account() {
        return this.transfer.transferType === 'manyToOne'
            ? this.transfer.toAccount
            : this.transfer.fromAccount;
    }

    get direction() {
        return this.transfer.transferType === 'manyToOne' ? 'To' : 'From';
    }

    renderTemplateAccounts() {
        const direction = this.transfer.transferType === 'manyToOne' ? 'from' : 'to';
        if (this.transfer.transferType === 'one')
            return html`<div class="pt-7 pr-4 pb-2 text-base font-medium">
                    Transfer ${direction}
                </div>
                <div class="transfer-transaction flex justify-between py-2">
                    <div class="text-sm">
                        <div class="account-name">${this.transfer.toAccount.name}</div>
                        <div class="transaction-memo text-xs">${this.transfer.memo}</div>
                    </div>
                    <div class="font-medium">${this.transfer.amount}</div>
                </div>`;
        return html`<div class="pt-7 pr-4 pb-2 text-base font-medium">Transfer ${direction}</div>
            ${this.transfer.transactions.map(item => {
                const account =
                    this.transfer.transferType === 'manyToOne' ? item.fromAccount : item.toAccount;
                return html`<div class="transfer-transaction flex justify-between py-2">
                    <div class="text-sm">
                        <div class="account-name">${account.name}</div>
                        <div class="transaction-memo text-xs">${item.memo}</div>
                    </div>
                    <div class="font-medium">${item.amount}</div>
                </div>`;
            })}`;
    }

    render() {
        if (!this.transfer) return nothing;
        const frequency = mapFrequencyModelDtoToFrequency(this.transfer.frequency);
        return html`<div class="transfer-data-wrapper p-4">
            <div class="flex justify-between">
                <div class="template-label">${this.transfer.templateName}</div>
                <div class="template-badge text-xs uppercase">Template</div>
            </div>
            <div class="transfer-amount text-2xl">
                ${new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(this.transfer.transferTotal)}
            </div>
            <jhd-data-field .label=${this.direction} .value=${this.account.name}></jhd-data-field>
            <jhd-data-field
                .label=${frequency.type === FrequencyType.OneTime ? 'Transfer Date' : 'Frequency'}
                .value=${frequency.type === FrequencyType.OneTime
                    ? format(frequency.startDate, 'MM/dd/yyyy')
                    : summarizeFrequency(frequency)}
            ></jhd-data-field>
            <div class="divider"></div>
            ${this.renderTemplateAccounts()}
        </div>`;
    }

    static get styles() {
        return [
            css`
                .transfer-data-wrapper {
                    background-color: var(--primary-background);
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    min-height: 300px;
                }
                .divider {
                    border-bottom: 1px dashed var(--divider-color);
                }
                .amount-label {
                    color: var(--header-text-color);
                }
                .template-badge {
                    color: var(--icon-fill-color-secondary);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TransferTemplateDetail;
    }
}
