import { UsersClient } from '@treasury/api/channel';
import { WiresService, WireTemplate } from '@treasury/domain/wires';
import { LabeledList, TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-bottom-sheet';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-labeled-list';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../../components/jhd-chip';
import '../../../components/jhd-status';

export const tagName = 'wire-template-read-only';
@customElement(tagName)
export class WireTemplateReadOnly extends TmBaseComponent {
    @InjectProperty()
    private declare wireService: WiresService;

    @InjectProperty()
    private declare userClient: UsersClient;

    @property({ type: Object })
    wireTemplate = new WireTemplate();

    @property({ type: Boolean })
    showStatus = true;

    @property({ type: Boolean })
    showCreationDetails = true;

    @property({ type: Boolean })
    showAudit = true;

    @state()
    auditOpen = false;

    @state()
    createdBy?: string;

    public async firstUpdated() {
        if (this.showCreationDetails && this.wireTemplate.createdBy) {
            const userInfo = await this.userClient.usersGetUserInformation(
                Number(this.wireTemplate.createdBy)
            );
            this.createdBy = userInfo.data.user ? userInfo.data.user.name : '';
        }
    }

    private toggleAudit() {
        this.auditOpen = !this.auditOpen;
    }

    renderHeader() {
        return html`<div class="wire-template-header flex">
            <h2 class="wire-template-title text-xl font-normal">${this.wireTemplate.name}</h2>

            <div class="wire-template-status-chip">
                ${this.renderStatus()}${this.renderInternationalDomesticChip()}
            </div>
        </div>`;
    }

    renderStatus() {
        if (!this.showStatus || !this.wireTemplate.status) return nothing;
        return html`<div class="mb-1 text-sm">
            <jhd-status .status=${this.wireTemplate.status} showLabel></jhd-status>
        </div>`;
    }

    renderInternationalDomesticChip() {
        if (!this.wireTemplate.isInternational) return nothing;
        const chipText = this.wireTemplate.isInternational ? 'International' : 'Domestic';
        return html` <jhd-chip .value=${chipText}></jhd-chip> `;
    }

    renderDetails() {
        const hasNotes = this.wireTemplate.notes && this.wireTemplate.notes.length;
        const templateAccounts = {
            wireCompany: this.wireTemplate.wireCompany?.name,
            debitAccount: this.wireTemplate.debitAccount?.accountDisplayLabel,
            beneficiary: this.wireTemplate.beneficiary?.name,
        };
        const templateAccountsList = new LabeledList(
            templateAccounts,
            ['wireCompany', 'debitAccount', 'beneficiary'],
            {
                wireCompany: 'Wire Company',
                debitAccount: 'Debit Account',
                beneficiary: 'Beneficiary',
            },
            { beneficiary: 'beneficiaryClick' }
        );

        const templateDetailsList = new LabeledList(this.wireTemplate, [
            'purpose',
            'referenceBeneficiary',
            'notes',
        ]);
        return html` 
            <tm-labeled-list
                        class="py-4"
                        .list=${templateAccountsList}
                    ></tm-labeled-list>
            <hr class="border-t border-dashed border-[--border-color]" />
            <tm-labeled-list
                        class="py-4"
                        .list=${templateDetailsList}
                    ></tm-labeled-list>
            <hr class="border-t border-dashed border-[--border-color] mb-4" />
        </div>`;
    }

    renderCreationDetails() {
        if (!this.showCreationDetails) return nothing;
        return html` <jhd-data-field
            label="Created By"
            .value=${this.createdBy ?? ''}
        ></jhd-data-field>`;
    }

    renderAuditData() {
        if (!this.auditOpen) return nothing;

        return html`<div class="mt-6 pb-2">
            <div class="max-h-36 overflow-y-auto font-normal text-sm">
                ${this.wireTemplate.audit}
            </div>
        </div>`;
    }

    renderAuditButton() {
        if (!this.showAudit || !this.wireTemplate.audit) return nothing;
        const auditButtonText = this.auditOpen ? 'Hide Audit' : 'View Audit';
        return html`
            <tm-button
                importance="secondary"
                @click=${() => {
                    this.toggleAudit();
                }}
            >
                ${auditButtonText}
            </tm-button>
        `;
    }

    render() {
        return html`<div class="pl-6 pr-4 py-3">
            ${this.renderHeader()}${this.renderDetails()}${this.renderCreationDetails()}
            ${this.renderAuditButton()}${this.renderAuditData()}
        </div>`;
    }

    static get styles() {
        return [
            css`
                .wire-template-title {
                    flex: 3;
                }

                .wire-template-status-chip {
                    flex: 1 1 85px;
                    text-align: right;
                    position: relative;
                    bottom: 5px;
                }

                .secondary-field {
                    --jhd-field-label-width: 150px;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: WireTemplateReadOnly;
    }
}
