import { debounce } from '@treasury/utils';
import { css, html, nothing, PropertyValueMap, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { filterIcon, searchIcon } from '../assets/icons';
import { TmBaseComponent } from '../tm-base.component';

export const tagName = 'tm-table-header';
@customElement(tagName)
export class TmTableHeader extends TmBaseComponent {
    @property({ type: Boolean })
    filteringEnabled = true;

    @property({ type: Boolean })
    filterButton = false;

    @property({ type: String })
    filterString = '';

    @property({ type: String })
    placeholder = 'Search';

    @property({ type: Number })
    debounceInterval = 250;

    @state()
    typingTimeout?: number;

    @property({ type: Boolean, reflect: true })
    searchBlockDisplay = false;

    @state()
    hasHeaderText = false;

    protected firstUpdated(
        _changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
    ): void {
        super.firstUpdated(_changedProperties);

        const slot = this.shadowRoot?.querySelector(
            'slot:not([name="header-end"])'
        ) as HTMLSlotElement;
        this.hasHeaderText = !!slot && !!slot.assignedNodes().length;

        const inlineContainer = this.shadowRoot?.querySelector('.container') as HTMLElement;
        const ro = new ResizeObserver(i => {
            if (this.hasHeaderText && this.filteringEnabled) {
                const blockSearchField = this.shadowRoot?.querySelector(
                    '.block-search-field'
                ) as HTMLElement;
                if (blockSearchField) {
                    blockSearchField.style.display =
                        inlineContainer.offsetWidth >= 435 ? 'none' : 'block';
                }
            }
        });
        ro.observe(inlineContainer);
    }

    private debouncedFilterStringChange = debounce(
        () => this.dispatchFilterStringChange(),
        this.debounceInterval
    );

    private dispatchFilterStringChange() {
        this.dispatchEvent(
            new CustomEvent('filterStringChange', {
                bubbles: true,
                composed: true,
                detail: {
                    value: this.filterString,
                },
            })
        );
    }

    renderFilterButton() {
        if (!this.filterButton) return nothing;
        return html`<button
            title="Filter"
            @click=${() => this.dispatchEvent(new CustomEvent('filterButtonClick'))}
        >
            ${filterIcon}
        </button>`;
    }

    renderSearchButton() {
        return html`<button
            title="Search"
            class=${classMap({
                'search-button': true,
                'search-block-displayed': this.searchBlockDisplay,
            })}
            @click=${() => {
                this.searchBlockDisplay = !this.searchBlockDisplay;
            }}
        >
            ${searchIcon}
        </button>`;
    }

    renderSearchField() {
        return html`<tm-text-field
            clearButton
            large
            @value-changed=${(e: CustomEvent) => {
                this.filterString = e.detail.value;
                this.debouncedFilterStringChange();
            }}
            placeholder=${this.placeholder}
            .value=${this.filterString}
            autofocus
        >
            <div slot="prefix" class="ml-1 mr-2">${searchIcon}</div>
        </tm-text-field>`;
    }

    renderFilterBar() {
        if (!this.filteringEnabled) return nothing;
        return html`<div
            class=${classMap({
                'container flex justify-end': true,
                'flex-grow': !this.hasHeaderText,
            })}
        >
            <div class="inline-search-field text-input relative grow pr-2 text-sm">
                ${this.renderSearchField()}
            </div>
            ${this.renderSearchButton()} ${this.renderFilterButton()}
            <slot name="header-end"></slot>
        </div>`;
    }

    renderBlockSearchField() {
        if (!this.hasHeaderText || !this.filteringEnabled || !this.searchBlockDisplay)
            return nothing;
        return html`<div class="block-search-field flex-grow">${this.renderSearchField()}</div>`;
    }

    render() {
        return html`
            <div
                class=${classMap({
                    'w-full flex flex-wrap justify-end px-3 border-b': true,
                    'has-header-text': this.hasHeaderText,
                })}
            >
                <div class="flex basis-full items-center px-2 py-1">
                    <div class="header-text mr-1"><slot></slot></div>
                    ${this.renderFilterBar()}
                </div>
                ${this.renderBlockSearchField()}
            </div>
        `;
    }

    static get styles() {
        return [
            css`
                .container {
                    container-type: inline-size;
                }

                .header-text {
                    text-wrap: nowrap;
                }

                button {
                    padding: 0.625rem;
                }

                .inline-search-field {
                    max-width: 300px;
                }

                .search-button {
                    display: none;
                }

                .block-search-field tm-text-field::part(component) {
                    padding-top: 0px;
                }

                .block-search-field {
                    max-width: 435px;
                }

                @media (max-width: 435px) {
                    .inline-search-field {
                        max-width: 400px;
                    }
                }

                @container (max-width: 435px) {
                    .has-header-text .search-button {
                        display: inline-block;
                    }

                    .has-header-text .search-block-displayed path {
                        fill: var(--primary-action-color);
                    }

                    .has-header-text .inline-search-field {
                        display: none;
                    }
                }
            `,
        ];
    }
}
