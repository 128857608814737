import { NavigationService } from '@treasury/core/navigation';
import { TmBaseComponent } from '@treasury/presentation';
import { InjectProperty, ObservationSource } from '@treasury/utils';
import { css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';

/**
 * Observable that emits any time an `AppComponent` instance becomes connected to the DOM.
 */
// eslint-disable-next-line no-use-before-define
const readySource = new ObservationSource<AppComponent>();

export const tagName = 'app-component';
@customElement(tagName)
export class AppComponent extends TmBaseComponent {
    @InjectProperty()
    private declare readonly navigationService: NavigationService;

    private outletRef = createRef();

    static async getOutlet() {
        const app = await readySource.toPromise(true);

        return app.outletRef.value as Element;
    }

    protected firstUpdated(): void {
        readySource.emit(this);
    }

    render() {
        return html`<main
            ${ref(this.outletRef)}
            id="outlet"
            class="overflow-hidden h-full"
        ></main>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    --application-color: #00acc3;
                    overflow: hidden;
                    height: 100%;
                }
                slot {
                    display: block;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AppComponent;
    }
}
