import { TmBaseComponent } from '@treasury/presentation';
import { css, html, render } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

export const tagName = 'approve-or-reject-prompt';
@customElement(tagName)
export class ApproveOrRejectPrompt extends TmBaseComponent {
    @property({ type: String })
    public paymentType = '';

    @property({ type: Array })
    public selectedApprovals: any[] = [];

    @property({ type: Boolean })
    public showApproveOrRejectPrompt = false;

    @property({ type: String })
    public selectedAction = '';

    get action() {
        if (this.selectedAction) {
            return this.selectedAction;
        }
        const [payment] = this.selectedApprovals;
        return payment?.selectedState === 'Approved' ? 'approve' : 'reject';
    }

    get approvalText() {
        if (this.paymentType.toLowerCase() === 'user') {
            return 'user';
        }
        return `${this.paymentType.toLowerCase()} payment`;
    }

    render() {
        return html`<omega-alert
            class="apply-updates-to-payment"
            .width=${'235px'}
            type="warning"
            posture="assertive"
            .isVisible=${this.showApproveOrRejectPrompt}
            .closeable=${true}
            title="Are you sure?"
            @close=${() => {
                this.showApproveOrRejectPrompt = false;
                this.dispatchEvent(new CustomEvent('close'));
            }}
        >
            <p>Are you sure you want to ${this.action} this ${this.approvalText}?</p>
            <omega-button
                class="apply-updates"
                slot="actions"
                type="primary"
                @click=${() => {
                    this.dispatchEvent(
                        new CustomEvent('approve-or-reject', {
                            detail: {
                                items: this.selectedApprovals,
                                type: this.selectedAction,
                            },
                        })
                    );
                    this.showApproveOrRejectPrompt = false;
                    this.dispatchEvent(new CustomEvent('close'));
                }}
                >Yes</omega-button
            >
            <omega-button
                class="cancel"
                slot="actions"
                type="secondary"
                sync
                @click=${() => {
                    this.showApproveOrRejectPrompt = false;
                    this.dispatchEvent(new CustomEvent('close'));
                }}
                >No</omega-button
            >
        </omega-alert>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: ApproveOrRejectPrompt;
    }
}
