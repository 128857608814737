import { CompanyAccountModelDto } from '@treasury/api/channel';
import { NavigationService } from '@treasury/core/navigation';
import { DashboardService } from '@treasury/domain/channel/services/dashboard/dashboard-service';
import { UsersService } from '@treasury/domain/channel/services/users/users-service';
import { TransferService, TransferTemplateDetails } from '@treasury/domain/transfers/';
import { LabeledList, NotificationService, TmContainer } from '@treasury/presentation';
import { actionIcon, chevronDownIcon, chevronUpIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-labeled-list';
import { InjectProperty } from '@treasury/utils';
import '@vaadin/grid';
import { GridActiveItemChangedEvent } from '@vaadin/grid';
import { css, html, nothing } from 'lit';
import {
    GridColumnBodyLitRenderer,
    columnBodyRenderer,
    gridRowDetailsRenderer,
} from 'lit-vaadin-helpers';
import { customElement, property, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import { TransferWorkflowParameters } from '../types/transfer-workflow-parameters.type';

export const tagName = 'transfer-template-container';
@customElement(tagName)
export class TransferTemplateContainer extends TmContainer {
    @InjectProperty()
    private declare navService: NavigationService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @InjectProperty()
    private declare transferService: TransferService;

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare dashboardService: DashboardService;

    @property()
    template!: TransferTemplateDetails;

    @state()
    private transferAccounts?: CompanyAccountModelDto[] | null;

    @state()
    private availableApprovers = [] as string[];

    @state()
    private completedApprovals = [] as { dateApproved: string; name: string }[];

    @state()
    private auditOpen = false;

    @state()
    private openedAccounts: CompanyAccountModelDto[] = [];

    @state()
    private selectedAction = '';

    @state()
    private showApproveOrRejectPrompt = false;

    private get renderStatusIcon() {
        return html`<jhd-status .status=${this.template?.status}></jhd-status>`;
    }

    private get headerLabeledList() {
        let fields: (keyof TransferTemplateDetails)[] = [];
        switch (this.template.type?.toLowerCase()) {
            case 'one-to-one':
                fields = ['transferFromAccount', 'transferToAccount', 'memo'];
                break;
            case 'one-to-many':
                fields = ['transferFromAccount'];
                break;
            case 'many-to-one':
                fields = ['transferToAccount'];
                break;
        }
        return new LabeledList(this.template, fields, {
            transferFromAccount: 'Transfer From',
            transferToAccount: 'Transfer To',
        });
    }

    async firstUpdated() {
        this.configureHeader();
        if (!this.template) {
            await this.getTemplate();
        } else {
            this.transferAccounts = this.template.transferAccounts;
        }

        await this.getApprovals();
    }

    private async getTemplate() {
        const { params } = await this.navService.getRouteData<{
            institution: string;
            id: string;
        }>();
        await this.tryFetch(
            () => this.transferService.getTransferTemplateDetails(params.id),
            t => {
                this.template = t;
                this.transferAccounts = t.transferAccounts;
            }
        );
    }

    private async getApprovals() {
        await this.tryFetch(
            () =>
                UsersService.fetchAvailableAndCompletedApprovers({
                    approvalEntity: 'template',
                    productId: this.template.id as number,
                    updatedBy: parseInt(this.template.updatedBy || '0'),
                }),
            approvers => {
                this.availableApprovers = approvers.availableApprovers;
                this.completedApprovals = approvers.completedApprovers;
            }
        );
    }

    private async configureHeader() {
        const { params } = await this.navService.getRouteData<TransferWorkflowParameters>();
        if (params.workflow === 'initiate') return;
        this.headerService.configure({
            backAction: () => this.navService.navigate(`/transfers`),
            title: 'Transfer Template Detail',
            menuItems: [{ title: 'Download', icon: actionIcon, action: () => alert('download') }],
        });
    }

    private async approveOrReject({
        items,
        paymentType = 'transfer',
    }: {
        items: any[];
        paymentType: string;
    }) {
        try {
            this.loading = true;
            await this.dashboardService.approveOrRejectPayment(items, paymentType);
            this.dispatchEvent(
                new CustomEvent('approve-or-reject', { bubbles: true, composed: true })
            );
            await this.getTemplate();
            await this.getApprovals();
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    private toggleAudit() {
        this.auditOpen = !this.auditOpen;
    }

    renderApprovalInformation() {
        if (this.template?.status !== 'Pending Approval') return nothing;
        return html`<treasury-pending-approval-template
            class="mb-2 border-b-8"
            .payment=${this.template}
            .approvals=${this.completedApprovals}
            .eligibleApprovers=${this.availableApprovers}
        ></treasury-pending-approval-template>`;
    }

    renderTemplateHeader() {
        if (!this.template) return nothing;
        return html`<div class="detail-header flex flex-row justify-between items-start w-full">
                <div class="detail-amount flex flex-col">
                    <div class="text-lg font-medium mb-1">${this.template.name}</div>
                </div>
                <div class="flex flex-col items-end">
                    <span class="detail-status flex items-center my-1 text-xs"
                        >${this.renderStatusIcon}&nbsp;${this.template.status}</span
                    >
                </div>
            </div>
            <div class="detail-content py-4 border-b border-dashed border-[--border-color]">
                <tm-labeled-list .list=${this.headerLabeledList}></tm-labeled-list>
            </div>
            ${this.renderAudit()}`;
    }

    renderAccountNameColumn(account: CompanyAccountModelDto) {
        return html`<div>
            <span class="text-sm">${account.accountDisplayLabel}</span>
        </div>`;
    }

    renderAmountColumn(account: CompanyAccountModelDto) {
        const amountFormatted = account.transferAmount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return html`<div class="flex-shrink-0">
            <span class="text-sm font-medium">${amountFormatted} </span
            ><span class="recipient-chevron"
                >${this.openedAccounts.indexOf(account) === -1
                    ? chevronDownIcon
                    : chevronUpIcon}</span
            >
        </div>`;
    }

    renderAccountDetails(account: CompanyAccountModelDto) {
        const amountFormatted = account.transferAmount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const displayAccount = {
            ...account,
            amount: amountFormatted,
            memo: account.transferMemo,
        };

        return html`<div>
            <tm-labeled-list
                .list=${new LabeledList(displayAccount, ['type', 'transferMemo'], {
                    type: 'Account Type',
                    transferMemo: 'Memo',
                })}
            ></tm-labeled-list>
        </div>`;
    }

    renderTemplateAccounts() {
        if (this.template.type?.toLowerCase() === 'one-to-one') return nothing;
        return html`<div class="accounts-table py-4">
            <h4 class="accounts-table-header font-medium pb-2">
                ${this.template.type?.toLowerCase() === 'one-to-many'
                    ? 'To Accounts'
                    : 'From Accounts'}
            </h4>
            <vaadin-grid
                class="min-h-0"
                .items=${this.template.transferAccounts}
                .detailsOpenedItems=${this.openedAccounts}
                @active-item-changed=${(
                    event: GridActiveItemChangedEvent<CompanyAccountModelDto>
                ) => {
                    const account = event.detail.value;
                    if (account) {
                        this.openedAccounts = [account];
                    } else {
                        this.openedAccounts = [];
                    }
                }}
                ${gridRowDetailsRenderer<CompanyAccountModelDto>(
                    (account: CompanyAccountModelDto) => this.renderAccountDetails(account)
                )}
                ><vaadin-grid-column
                    ${columnBodyRenderer(
                        this
                            .renderAccountNameColumn as GridColumnBodyLitRenderer<CompanyAccountModelDto>,
                        []
                    )}
                ></vaadin-grid-column
                ><vaadin-grid-column
                    ${columnBodyRenderer(
                        this
                            .renderAmountColumn as GridColumnBodyLitRenderer<CompanyAccountModelDto>,
                        []
                    )}
                    text-align="end"
                ></vaadin-grid-column
            ></vaadin-grid>
        </div>`;
    }

    renderAuditData() {
        if (!this.auditOpen) return nothing;
        if (!this.template?.audit) return nothing;
        return html`<div class="mt-4 pb-2">
            <div class="pb-2 font-medium">Audit</div>
            <div class="max-h-36 overflow-y-auto font-normal text-sm">${this.template.audit}</div>
        </div>`;
    }

    renderAudit() {
        if (!this.template?.audit) return nothing;
        const auditButtonText = this.auditOpen ? 'Hide Audit' : 'View Audit';
        return html`
            ${this.renderAuditData()}
            <tm-button class="mt-2" @click=${() => this.toggleAudit()}>
                ${auditButtonText}
            </tm-button>
        `;
    }

    renderApproveOrRejectPrompt() {
        return html`<approve-or-reject-prompt
            .paymentType=${'Transfer'}
            .selectedAction=${this.selectedAction}
            .selectedApprovals=${[this.template]}
            .showApproveOrRejectPrompt=${this.showApproveOrRejectPrompt}
            @approve-or-reject=${(e: CustomEvent) => {
                const selectedState = this.selectedAction === 'approve' ? 'Approved' : 'Rejected';
                this.approveOrReject({
                    items: [{ ...this.template, selectedState }],
                    paymentType: 'transfer',
                });
                this.showApproveOrRejectPrompt = false;
            }}
            @close=${() => {
                this.showApproveOrRejectPrompt = false;
            }}
        ></approve-or-reject-prompt>`;
    }

    renderActionButtons() {
        if (!this.template) return nothing;
        if (this.template.status !== 'Pending Approval') return nothing;
        const canApprove = this.template.permissions.some(
            (p: { permission: string }) => p.permission === 'Approve'
        );
        const canReject = this.template.permissions.some(
            (p: { permission: string }) => p.permission === 'Reject'
        );

        const approveButton = canApprove
            ? html`
                  <tm-button
                      success
                      size="large"
                      importance="primary"
                      class="w-full m-1"
                      @click=${() => {
                          this.selectedAction = 'approve';
                          this.showApproveOrRejectPrompt = true;
                      }}
                  >
                      Approve
                  </tm-button>
              `
            : nothing;
        const rejectButton = canReject
            ? html`
                  <tm-button
                      error
                      size="large"
                      importance="primary"
                      class="w-full m-1"
                      @click=${() => {
                          this.selectedAction = 'reject';
                          this.showApproveOrRejectPrompt = true;
                      }}
                      >Reject
                  </tm-button>
              `
            : nothing;

        if (!canApprove && !canReject) return nothing;
        return html`<tm-footer direction="row"> ${rejectButton}${approveButton} </tm-footer>`;
    }

    render() {
        if (this.loading) return html`<tm-blocking-loader></tm-blocking-loader>`;
        return html`${this.renderApproveOrRejectPrompt()}${this.renderApprovalInformation()}
            <div class="detail-wrapper pl-6 pr-4 py-3">
                ${this.renderTemplateHeader()} ${this.renderTemplateAccounts()}
            </div>
            ${this.renderActionButtons()}`;
    }

    static get styles() {
        return [
            css`
                vaadin-grid-cell-content {
                    padding: 0;
                }
                vaadin-grid::part(cell) {
                    border-top: none;
                    border-bottom: 1px solid var(--border-color);
                }
                .detail-wrapper {
                    background: var(--primary-background);
                }
                .recipient-chevron svg {
                    display: inline-block;
                }
                .max-h-36 {
                    max-height: 9rem;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TransferTemplateContainer;
    }
}
