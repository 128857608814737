import { svg } from 'lit';

export const equalHousingLender = svg`<svg width="21px" height="15px" viewBox="0 0 21 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: sketchtool 41.2 (35397) - http://www.bohemiancoding.com/sketch -->
    <title>3E689F4F-8ABA-4761-9551-5ABD960179F4</title>
    <desc>Created with sketchtool.</desc>
    <defs></defs>
    <g id="FI-Branding" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Footer_v1" transform="translate(-421.000000, -12.000000)" fill="#221F20">
            <g id="EqualHousingLender-Logo" transform="translate(421.000000, 12.000000)">
                <path d="M10.2199332,0 L0,5.73181818 L0,7.80318182 L1.56587567,7.80318182 L1.56587567,14.9254545 L18.6175468,14.9254545 L18.6175468,7.80318182 L20.2115575,7.80318182 L20.2115575,5.78863636 L10.2199332,0 Z M3.64325535,5.96045455 L10.2190107,2.15818182 L16.6527072,5.96045455 L16.6527072,13.1109091 L3.64325535,13.1109091 L3.64325535,5.96045455 Z" id="Fill-1"></path>
                <polygon id="Fill-2" points="6.72196524 8.68345455 13.5255682 8.68345455 13.5255682 6.92436364 6.72196524 6.92436364"></polygon>
                <polygon id="Fill-3" points="6.72196524 11.3034545 13.5255682 11.3034545 13.5255682 9.54436364 6.72196524 9.54436364"></polygon>
            </g>
        </g>
    </g>
</svg>`;
