import { WirePaymentDto } from '@treasury/domain/channel/types/wire/wire-payment-dto.types';
import { FiDate } from '@treasury/domain/dates';
import { format } from 'date-fns';

export type WirePaymentViewModel = {
    beneficiaryName: string | undefined;
    debitAccount: string | undefined;
    status: string;
    amount: string;
    paymentDate: string;
    id: number;
};

export class WirePaymentTableViewModel {
    constructor(dto: WirePaymentDto) {
        this.beneficiaryName = dto.beneficiary.name;
        this.debitAccount = dto.debitAccount.accountDisplayLabel;
        this.status = dto.status;
        this.amount = dto.amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        this.paymentDate = dto.frequency.nextPaymentDate
            ? format(new FiDate(dto.frequency.nextPaymentDate).date, 'P')
            : '';
        this.id = dto.id;
    }

    beneficiaryName: string | undefined;

    debitAccount: string | undefined;

    status: string;

    amount: string;

    paymentDate: string;

    id: number;
}
