import { AchPayment, AchTemplate } from '@treasury/domain/ach';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import './ach-payment-data';
import './ach-template-data';

export const tagName = 'ach-payment-review';
@customElement(tagName)
export class AchPaymentReview extends TmBaseComponent {
    @property()
    public payment!: AchTemplate | AchPayment;

    @property()
    isEditedTemplate = false;

    @property({ type: Boolean })
    isFromTemplate = true;

    onSubmitPayment() {
        this.dispatchEvent(
            new CustomEvent('submitPayment', {
                detail: this.payment,
                bubbles: true,
                composed: true,
            })
        );
    }

    renderHeader() {
        if (this.isFromTemplate) {
            return html`<ach-template-data .template=${this.payment}></ach-template-data>`;
        }
        return html`<ach-payment-data .payment=${this.payment}></ach-payment-data>`;
    }

    renderRecipients() {
        const recipients = this.payment.recipients?.map(r => {
            return html`<div class="flex justify-between py-1">
                <div class="payment-recipient-name text-sm text-[--secondary-text-color]">
                    ${r.name}
                </div>
                <div class="payment-recipient-amount text-sm font-medium">
                    ${new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }).format(r.amount)}
                    <span class="text-xs font-normal">${r.transactionType}</span>
                </div>
            </div>`;
        });
        return html`<div
            class="payment-recipients py-4 border-t border-dashed border-[--border-color]"
        >
            <div class="pb-2 font-medium">Payment Recipients</div>
            ${recipients}
        </div>`;
    }

    renderApplyUpdateToTemplateOption() {
        if (!this.isFromTemplate) return nothing;
        return html`<tm-footer
            ><tm-checkbox
                .label=${'Apply updates to the Template'}
                @checked-changed=${(e: CustomEvent) => {
                    this.dispatchEvent(
                        new CustomEvent('updateTemplate', {
                            detail: e.detail.value,
                        })
                    );
                }}
            ></tm-checkbox
        ></tm-footer>`;
    }

    render() {
        return html`
            <tm-section class="pl-6 pr-4">
                ${this.renderHeader()} ${this.renderRecipients()}
            </tm-section>
            ${this.renderApplyUpdateToTemplateOption()}
            <tm-footer
                class="mt-0"
                .buttonConfig=${[
                    {
                        text: 'Initiate ACH Payment',
                        onClick: () => {
                            this.onSubmitPayment();
                        },
                    },
                ]}
            ></tm-footer>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchPaymentReview;
    }
}
