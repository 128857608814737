import {
    AchPaymentModelDto,
    DashboardClient,
    PaymentBatchModelDto,
    SecurityClient,
    UsersClient,
} from '@treasury/api/channel';
import { Injectable } from '@treasury/utils';
import { PendingAchPayment } from './pending-ach.entity';
import { PendingLoan } from './pending-loan.entity';
import { PendingTransfer } from './pending-transfer.entity';
import { PendingUser } from './pending-user.entity';
import { PendingWire } from './pending-wire.entity';
import { ApprovalActions } from './types/approval-action.type';
import { PaymentTypes } from './types/payment.type';

@Injectable()
export class PendingApprovalsService {
    constructor(
        private readonly dashboardClient: DashboardClient,
        private readonly securityClient: SecurityClient,
        private usersClient: UsersClient
    ) {}

    async getPendingApprovalPaymentsByType(type: string | null) {
        const response = await this.dashboardClient.dashboardGetPaymentPendingApproval({ type });
        const payments = response.data;

        switch (type) {
            case PaymentTypes.Transfer:
                return payments.map((payment: any) => new PendingTransfer(payment));
            case PaymentTypes.Loan:
                return payments.map((payment: any) => new PendingLoan(payment));
            case PaymentTypes.Wire:
                return payments.map((payment: any) => new PendingWire(payment));
            case PaymentTypes.ACH:
                return payments.map(
                    (payment: AchPaymentModelDto) => new PendingAchPayment(payment)
                );
            default:
                return null;
        }
    }

    async getPendingApprovalUsers() {
        const response = await this.usersClient.usersSearch({
            activeUsersOnly: false,
            enrolledUsersOnly: false,
            includeRoles: true,
        });
        const pendingUsers = response.data.filter(
            user => user.publishState === 'Pending' && !user.isEditor
        );
        return pendingUsers.map(user => new PendingUser(user));
    }

    async approveOrRejectUsers(users: any[], comments: string) {
        const securityResponse = await this.securityClient.securityCheckSecurity({
            actionType: 'Approve User',
            hasAlternate: false,
            challengeMethodTypeId: 0,
        });

        if (securityResponse.data.status === 'Success') {
            users.forEach(async (user: any) => {
                if (user.selectedState === 'Approved') {
                    await this.usersClient.usersApprove(user.value.toDto().id, { comments });
                } else {
                    await this.usersClient.usersReject(user.value.toDto().id, { comments });
                }
            });
        }
    }

    approveOrReject(items: any[], type: PaymentTypes, comments: string) {
        let actionType;
        switch (type) {
            case PaymentTypes.Transfer:
                actionType = ApprovalActions.ApproveTransfer;
                break;
            case PaymentTypes.Loan:
                actionType = ApprovalActions.ApproveLoanPayment;
                break;
            case PaymentTypes.Wire:
                actionType = ApprovalActions.ApproveWirePayment;
                break;
            case PaymentTypes.ACH:
                actionType = ApprovalActions.ApproveACHPayment;
                break;
            default:
                break;
        }
        if (!actionType) return this.approveOrRejectUsers(items, comments);
        const model = {
            batch: items.map((item: any) => ({
                key: item.value.paymentId,
                value: item.selectedState,
            })),
            securityMessage: {
                actionType,
                status: null,
                methodUsed: null,
                hasAlternate: false,
                errorCode: null,
                message: null,
                oneTimePassword: null,
            },
        };
        return this.dashboardClient.dashboardApproveOrReject(
            { type },
            model as unknown as PaymentBatchModelDto
        );
    }
}
