import { ExceptionCorrectionModelDto } from '@treasury/api/channel';
import { CheckException } from '../../../arp';
import { downloadBlob, toBase64 } from '../../../utilities/file-handling.js';
import CheckExceptionMappings from '../../mappings/positive-pay/check-exceptions/check-exception-mappings.js';
import { PositivePayExceptionRequests } from '../../requests/positive-pay/positive-pay-exception-requests.js';
import { mapExceptionsForSubmission } from './check-exception-decision-mapper';

export interface CheckExceptionHoldover {
    // this type is a holdover to avoid ts errors in the legacy saveCheckExceptions method
    decisionChoice: string;
    decisionTaken: string;
    returnReason: string;
    comment: string;
    initialDecision: string;
    arpExceptionDetailUniqueId: string;
}

interface CheckExceptionSearchParameters {
    account?: string[];
    checkNumber?: string;
    issuedAmount?: string;
    paidAmount?: string;
    issuedDate?: string;
    issuedPayee?: string | null;
    exceptionReasons?: string;
    decisionTaken?: string;
}

export default class CheckExceptionsServices {
    /**
     * @deprecated
     * use submitCheckExceptionDecisions instead
     */
    static async saveCheckExceptions(records: CheckExceptionHoldover[]) {
        // TODO: This should probably be cleaned up to only post the required fields.
        const exceptions = records.map(record => {
            const exception: any = {
                ...record,
                pay: record.decisionChoice === 'Pay',
                existingDecision: record.decisionChoice === record.decisionTaken,
                returnReasonUniqueId: record.returnReason,
                returnReasonComment: record.comment,
            };
            delete exception.returnReason;
            delete exception.initialDecision;
            delete exception.decisionChoice;
            delete exception.pendingDecision;
            return exception;
        });
        return PositivePayExceptionRequests.saveCheckExceptions(exceptions);
    }

    static async submitCheckExceptionDecisions(
        checkExceptionViewModels: CheckException[]
    ): Promise<boolean> {
        // submitCheckExceptionDecisions cleaner version of legacy code above
        const exceptions = mapExceptionsForSubmission(checkExceptionViewModels);
        return PositivePayExceptionRequests.saveCheckExceptions(exceptions) as Promise<boolean>;
    }

    static async saveAttachmentAndComment(
        checkException: CheckException,
        fileToUpload: File | undefined
    ) {
        const body: {
            Attachment?: File;
            FileName?: string;
            Comment?: string;
        } = {};
        if (fileToUpload) {
            body.Attachment = await toBase64(fileToUpload);
            body.FileName = fileToUpload.name;
        }
        if (checkException.comment) {
            body.Comment = checkException.comment;
        }

        if (Object.keys(body).length !== 0) {
            return PositivePayExceptionRequests.saveAttachmentAndComment(body, checkException.guid);
        }
        return false;
    }

    static async searchCheckExceptions(searchData: { parameters: CheckExceptionSearchParameters }) {
        let body = {};
        const { parameters } = searchData;
        if (Object.keys(parameters).length) {
            body = CheckExceptionMappings.mapSearchParams(parameters);
            body = {
                ...body,
                type: 'OpenItems',
            };
        }
        const searchCheckExceptionsResponse =
            await PositivePayExceptionRequests.searchCheckExceptions(body);

        if (!searchCheckExceptionsResponse.items) return { data: [], totalCount: 0 };

        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        const mapped = searchCheckExceptionsResponse.items.map((exception: any) => {
            const lastDecisionDateTime = new Date(exception.lastDecisionDate);
            const lastDecisionDate = new Date(
                lastDecisionDateTime.getFullYear(),
                lastDecisionDateTime.getMonth(),
                lastDecisionDateTime.getDate()
            );
            return {
                ...exception,
                decisionChoice: exception.decisionStatus,
                initialDecision: exception.decisionStatus,
                pendingDecision:
                    lastDecisionDate.getTime() !== today.getTime() && exception.protected !== 'Y'
                        ? 'true'
                        : 'false',
                returnReason: exception.returnReasonUniqueId,
            };
        });

        return {
            data: mapped,
            totalCount: searchCheckExceptionsResponse?.items.length,
        };
    }

    static async decisionActivity(searchData: { parameters: CheckExceptionSearchParameters }) {
        let body = {};
        const { parameters } = searchData;
        if (Object.keys(parameters).length) {
            body = CheckExceptionMappings.mapSearchParams(parameters);
            body = {
                ...body,
                decisionStatus: parameters.decisionTaken,
                type: 'History',
            };
        }

        const searchCheckExceptionsResponse =
            await PositivePayExceptionRequests.searchCheckExceptions(body);

        if (!searchCheckExceptionsResponse.items) return { data: [], totalCount: 0 };

        return {
            data: searchCheckExceptionsResponse.items,
            totalCount: searchCheckExceptionsResponse?.items.length,
        };
    }

    static async fetchCheckImages(checkImageNumber: string) {
        return PositivePayExceptionRequests.fetchCheckImages(checkImageNumber);
    }

    static async saveCorrectionRequest(file: File, correctionRequest: ExceptionCorrectionModelDto) {
        const body = { ...correctionRequest };
        if (file) {
            const encodedFile = await toBase64(file);
            body.attachment = encodedFile;
            body.attachmentFileName = file.name || '';
        }
        return PositivePayExceptionRequests.saveCorrectionRequest(body);
    }

    static async getCheckExceptionAttachment(arpExceptionDetailUniqueId: string) {
        const response = await PositivePayExceptionRequests.getCheckExceptionAttachment(
            arpExceptionDetailUniqueId
        );
        const filename = response.headers.get('x-filename');
        const contentType = response.headers.get('content-type');
        const buffer = await response.arrayBuffer();
        const blob = new Blob([buffer], { type: contentType ?? '' });

        return downloadBlob(blob, filename);
    }

    static async getCheckExceptionReturnReasons() {
        return PositivePayExceptionRequests.getReturnReasons();
    }
}
