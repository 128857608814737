/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-duplicates */
import { NavigationService } from '@treasury/core/navigation';
import { PendingApprovalsService } from '@treasury/domain/approvals/approvals.service';
import { PendingAchPayment } from '@treasury/domain/approvals/pending-ach.entity';
import { PendingLoan } from '@treasury/domain/approvals/pending-loan.entity';
import { PendingTransfer } from '@treasury/domain/approvals/pending-transfer.entity';
import { PendingUser } from '@treasury/domain/approvals/pending-user.entity';
import { PendingWire } from '@treasury/domain/approvals/pending-wire.entity';
import { UsersService } from '@treasury/domain/channel/services/users/users-service';
import { PaymentTypes } from '@treasury/domain/channel/types/payment';
import { AuthenticationService } from '@treasury/domain/services/authentication';
import { TmContainer } from '@treasury/presentation';
import { informationIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-tabs';
import { InjectProperty } from '@treasury/utils';
import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import { MenuItem } from '../../services/types/jhd-header-bar.type';
import { PendingAchPaymentViewModel } from '../data/pending-ach-view-model';
import { PendingLoanViewModel } from '../data/pending-loan-view-model';
import { PendingTransferViewModel } from '../data/pending-transfer-view-model';
import { PendingUserViewModel } from '../data/pending-user-view-model';
import { PendingWireViewModel } from '../data/pending-wire-view-model';
import '../partials/approval-confirmation-bottom-sheet';
import '../partials/approval-review-bottom-sheet';
import '../partials/approve-or-reject-prompt';
import '../partials/pending-approvals-table';

export const tagName = 'pending-approvals-container';
@customElement(tagName)
export class PendingApprovalsContainer extends TmContainer {
    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare navService: NavigationService;

    @property({ type: Array })
    private transferApprovals = [];

    @property({ type: Array })
    private loanApprovals = [];

    @property({ type: Array })
    private wireApprovals = [];

    @property({ type: Array })
    private achApprovals = [];

    @property({ type: Array })
    private userApprovals: PendingUserViewModel[] = [];

    @state()
    private selectedApprovals: any[] = [];

    @state()
    private selectedAction: PaymentTypes | '' = '';

    @state()
    private reviewSheetOpen = false;

    @InjectProperty()
    private declare readonly pendingApprovalService: PendingApprovalsService;

    @InjectProperty()
    private declare authService: AuthenticationService;

    @property({ type: Object })
    usersService = UsersService;

    @state()
    private isAuthorizing = true;

    @state()
    private isReviewing = false;

    @state()
    tabs = [
        { label: 'ACH', id: 'ach' },
        { label: 'TRANSFERS', id: 'transfer' },
        { label: 'WIRES', id: 'wire' },
        { label: 'LOANS', id: 'loanpayment' },
    ];

    @state()
    private menuItems: MenuItem[] = [
        { title: 'Info', icon: informationIcon, action: () => console.log('clicked') },
    ];

    @state()
    private approvalSuccess = false;

    @state()
    private showApproveOrRejectPrompt = false;

    @state()
    private isConfirming = false;

    get approvalType() {
        // TODO: use nav service
        return window.location.pathname.split('/').pop();
    }

    async connectedCallback() {
        super.connectedCallback();
        const { isAdmin } = await this.authService.authenticated$.toPromise(true);

        this.isAuthorizing = false;

        if (isAdmin) {
            this.tabs = [...this.tabs, { label: 'USERS', id: 'user' }];
        }

        await this.getApprovals();
    }

    firstUpdated() {
        this.headerService.configure({ title: 'Approvals', menuItems: this.menuItems });
    }

    getApprovals() {
        const approvalType = this.approvalType ?? '';

        if (approvalType === PaymentTypes.User) {
            const toUserVm = (u: PendingUser) => new PendingUserViewModel(u);
            this.tryFetch(
                () => this.pendingApprovalService.getPendingApprovalUsers(),
                users => (this.userApprovals = users.map(toUserVm))
            );
            return;
        }

        this.tryFetch(
            () => this.pendingApprovalService.getPendingApprovalPaymentsByType(approvalType),
            res => {
                switch (approvalType) {
                    case PaymentTypes.ACH:
                        this.achApprovals = res.map(
                            (payment: PendingAchPayment) => new PendingAchPaymentViewModel(payment)
                        );
                        return;
                    case PaymentTypes.Transfer:
                        this.transferApprovals = res.map(
                            (payment: PendingTransfer) => new PendingTransferViewModel(payment)
                        );
                        return;
                    case PaymentTypes.Wire:
                        this.wireApprovals = res.map(
                            (payment: PendingWire) => new PendingWireViewModel(payment)
                        );
                        return;
                    case PaymentTypes.Loan:
                        this.loanApprovals = res.map(
                            (payment: PendingLoan) => new PendingLoanViewModel(payment)
                        );
                        return;
                }
            }
        );
    }

    switchApprovalTab(tabId: PaymentTypes) {
        if (tabId === this.approvalType) return;
        this.navService.navigate(`/pending-approvals/${tabId}`);
    }

    approveOrRejectAllPrompt({ items, type }: { items: any[]; type: PaymentTypes }) {
        this.selectedApprovals = items;
        this.selectedAction = type;
        this.showApproveOrRejectPrompt = true;
    }

    async approveOrReject({ items, type }: { items: any[]; type: PaymentTypes }) {
        this.loading = true;
        try {
            await this.pendingApprovalService.approveOrReject(items, type, '');
            this.approvalSuccess = true;
            this.reviewSheetOpen = false;
            this.isConfirming = true;
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
            this.selectedAction = '';
            this.selectedApprovals = [];
        }
    }

    renderApproveOrRejectAllPrompt() {
        return html`<approve-or-reject-prompt
            .selectedAction=${this.selectedAction}
            .selectedApprovals=${this.selectedApprovals}
            .showApproveOrRejectPrompt=${this.showApproveOrRejectPrompt}
            @approve-or-reject=${(e: CustomEvent) => {
                this.approveOrReject({
                    items: this.selectedApprovals,
                    type: this.selectedAction as PaymentTypes,
                });
                this.showApproveOrRejectPrompt = false;
            }}
            @close=${() => {
                this.showApproveOrRejectPrompt = false;
            }}
        ></approve-or-reject-prompt>`;
    }

    renderReviewSheet() {
        return html`<approval-review-bottom-sheet
            .open=${this.reviewSheetOpen}
            .decisionedPayments=${this.selectedApprovals}
            .activeTab=${this.approvalType}
            .approvalSuccess=${this.approvalSuccess}
            .submittingApprovals=${this.loading}
            @approve-or-reject=${(e: CustomEvent) => {
                if (e.detail.items) {
                    this.approveOrRejectAllPrompt(e.detail);
                } else {
                    this.approveOrReject({ items: this.selectedApprovals, type: e.detail.type });
                }
            }}
            @close=${() => {
                this.reviewSheetOpen = false;
                this.isReviewing = false;
            }}
        ></approval-review-bottom-sheet>`;
    }

    renderConfirmationSheet() {
        return html`<approval-confirmation-bottom-sheet
            .open=${this.isConfirming}
            .activeTab=${this.approvalType}
            .decisionedPayments=${this.selectedApprovals}
            @view-approvals=${() => {
                this.reviewSheetOpen = false;
                this.approvalSuccess = false;
                this.isConfirming = false;
                this.isReviewing = false;
                this.getApprovals();
            }}
            @close=${() => {
                this.isConfirming = false;
                this.isReviewing = false;
            }}
        ></approval-confirmation-bottom-sheet>`;
    }

    render() {
        if (!this.approvalSuccess && !this.isConfirming) {
            return html`${this.isAuthorizing ? this.renderLoader() : nothing}
                <div class="h-full flex flex-col">
                        <tm-tabs
                        class="bg-[var(--primary-background)]"
                            .tabs=${this.tabs}
                            .activeTab=${this.approvalType}
                            @switchTab=${(event: CustomEvent) =>
                                this.switchApprovalTab(event.detail.activeTab)}
                        ></tm-tabs
                    >
                    <pending-approvals-table
                        class="grow"
                        .loading=${this.loading}
                        .isReviewing=${this.isReviewing}
                        .isConfirming=${this.isConfirming}
                        .transfers=${this.transferApprovals}
                        .loans=${this.loanApprovals}
                        .wires=${this.wireApprovals}
                        .ach=${this.achApprovals}
                        .users=${this.userApprovals}
                        .activeTab=${this.approvalType}
                        @approve-or-reject=${() => {
                            this.approvalSuccess = true;
                            this.reviewSheetOpen = false;
                            this.isConfirming = true;
                            this.loading = false;
                        }}
                        @review-approvals=${(e: CustomEvent) => {
                            this.selectedApprovals = e.detail;
                            this.reviewSheetOpen = true;
                            this.isReviewing = true;
                        }}
                    ></pending-approvals-table>
                </div>
                ${this.renderReviewSheet()}${this.renderConfirmationSheet()}${this.renderApproveOrRejectAllPrompt()} `;
        }
        return [this.renderConfirmationSheet(), this.renderLoader()];
    }

    static get styles() {
        return [
            css`
                :host {
                    height: 100%;
                    width: 100vw;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: PendingApprovalsContainer;
    }
}
