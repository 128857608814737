import { InternalTransferAddTransactionDetailModelDto } from '@treasury/api/channel';
import { Nullable } from '@treasury/utils';

export const nullTransferTransactionDetails: Nullable<InternalTransferAddTransactionDetailModelDto> =
    {
        transferDate: null,
        amount: 0,
        memo: null,
        fromAccount: null,
        toAccount: null,
        errorSummary: null,
        frequency: null,
        status: null,
        transactionId: null,
        paymentOptionType: null,
        id: null,
        updatedDate: null,
    };
