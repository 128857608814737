import { NavigationService } from '@treasury/core/navigation';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-footer';
import { InjectProperty } from '@treasury/utils';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../components/confirmation-container';
import { BillPayWorkflowPaymentViewModel } from '../data/bill-pay-payment-workflow-view-model';
import './read-only-payment-workflow-detail';

export const tagName = 'business-bill-pay-confirm-step';
@customElement(tagName)
export class BusinessBillPayConfirmStep extends TmBaseComponent {
    @property()
    private payment?: BillPayWorkflowPaymentViewModel;

    @InjectProperty()
    private declare navigationService: NavigationService;

    render() {
        return html`<confirmation-container .headingText=${'Payment Submitted'}>
                <business-bill-pay-payment-workflow-read-only-detail
                    .payment=${this.payment}
                ></business-bill-pay-payment-workflow-read-only-detail></confirmation-container
            ><tm-footer
                class="fixed bottom-0 w-full"
                .buttonConfig=${[
                    {
                        text: 'Make another payment',
                        onClick: () => {
                            this.dispatchEvent(new CustomEvent('create-another-payment'));
                        },
                    },
                    {
                        text: 'View payment activity',
                        onClick: () => {
                            this.navigationService.navigate(`/business-bill-pay`);
                        },
                    },
                ]}
            ></tm-footer>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: BusinessBillPayConfirmStep;
    }
}
