import {
    EntitlementFeatureFlag,
    FeatureFlagService,
    TmFeatureFlag,
} from '@treasury/domain/services/feature-flags';
import { exists } from '@treasury/utils';
import { DiContainer, InjectProperty } from '@treasury/utils/dependency-injection';
import { LitElement, css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { OmegaDialogService } from '../../services/omega-dialog';
import '../omega-checkbox';
import '../omega-icon';
import '../omega-toggle';

const TagName = 'feature-flag-overrides';
type ToggleEvent = CustomEvent<{ checked: boolean; value: TmFeatureFlag }>;
type CheckboxEvent = CustomEvent<{ value: undefined; checked: boolean; indeterminate: boolean }>;

/**
 * Minimum amount of characters needed to begin searching.
 */
const SEARCH_THRESHOLD = 3;

/**
 * Presents a UI for overriding feature flag state locally.
 */
@customElement(TagName)
export class FeatureFlagOverrides extends LitElement {
    @InjectProperty()
    private declare ffService: FeatureFlagService;

    @state()
    private hideBslFeatures = false;

    @state()
    private searchTerm = '';

    private onToggle({ detail }: ToggleEvent) {
        const { checked, value } = detail;
        this.ffService.setOverride(value.name, checked);
    }

    private onHideBslFeatures({ detail }: CheckboxEvent) {
        const { checked } = detail;
        this.hideBslFeatures = checked;
    }

    private getFilteredFeatureFlags() {
        const normalizedTerm = this.searchTerm.trim().toLowerCase();
        if (normalizedTerm.length < SEARCH_THRESHOLD) {
            return this.ffService.features;
        }

        return this.ffService.features.filter(f => {
            if (this.hideBslFeatures && f instanceof EntitlementFeatureFlag) {
                return false;
            }

            if (normalizedTerm === '') {
                return true;
            }

            const normalizedFeatureName = f.name.trim().toLowerCase();
            return normalizedFeatureName.includes(normalizedTerm);
        });
    }

    private onFlagSearch(e: InputEvent) {
        this.searchTerm = (e.currentTarget as HTMLInputElement)?.value;
    }

    private renderCheckmark() {
        return html`<omega-icon class="check-icon" icon="check-circle"></omega-icon>`;
    }

    private renderCross() {
        return html`<omega-icon class="x-icon" icon="times-circle"></omega-icon>`;
    }

    private renderRows() {
        const features = this.getFilteredFeatureFlags();

        if (features.length < 1) {
            return html`<p>No features to show.</p>`;
        }

        return features.map(f => {
            const overrideState = this.ffService.getOverride(f.name);
            const effectiveEnabled = exists(overrideState) ? overrideState : f.enabled;

            return html`<tr>
                <td>
                    <span class="feature-name">${f.name}</span>
                </td>
                <td class="center">${f.enabled ? this.renderCheckmark() : this.renderCross()}</td>
                <td class="right">
                    <omega-toggle
                        .checked=${effectiveEnabled}
                        activeText="On"
                        inactiveText="Off"
                        .value=${f}
                        @change=${(e: ToggleEvent) => this.onToggle(e)}
                    ></omega-toggle>
                </td>
            </tr>`;
        });
    }

    protected render() {
        return html` <div class="input-container">
                <input
                    @input="${(e: InputEvent) => this.onFlagSearch(e)}"
                    placeholder="Type to search for feature flags"
                />
            </div>
            <div style="padding: 10px 0;">
                <omega-checkbox
                    label="Hide BSL Features"
                    @toggle="${(e: CheckboxEvent) => this.onHideBslFeatures(e)}"
                ></omega-checkbox>
            </div>
            <table>
                <thead class="sticky">
                    <tr>
                        <th>Feature Name</th>
                        <th class="center">Original State</th>
                        <th class="right">Override</th>
                    </tr>
                </thead>
                <tbody>
                    ${this.renderRows()}
                </tbody>
            </table>`;
    }

    static styles = css`
        table {
            width: 100%;
        }

        th {
            font-weight: bold;
        }

        thead.sticky tr th {
            position: sticky;
            top: 0;
            background-color: var(--primary-background, white);
            z-index: 100;
            border-bottom: 1px solid var(--primary-text-color, black);
        }

        .x-icon {
            color: red;
        }

        .check-icon {
            color: green;
        }

        .feature-name {
            font-family: monospace;
        }

        .center {
            text-align: center;
        }

        .right {
            text-align: right;
        }
        .input-container {
            display: flex;
        }
        input {
            flex: 1;
            padding: 5px;
        }
    `;
}

// provide a way to manually invoke overrides view from JS console for non-dev builds
(window as any).showOverrides = async function () {
    const dialogService = (await DiContainer.getInstance()).get(OmegaDialogService);
    dialogService.open(
        html`<feature-flag-overrides></feature-flag-overrides>`,
        'Feature Flag Overrides',
        {
            buttons: {
                cancel: null,
                confirm: {
                    label: 'Close',
                },
            },
        }
    );
};
