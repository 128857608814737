import { TmBaseComponent } from '@treasury/presentation';
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

export const tagName = 'login-locked';
@customElement(tagName)
export class LoginLocked extends TmBaseComponent {
    render() {
        return html`
            <div>We were unable to authenticate your user credentials.</div>
            <br />
            <div>Please contact your system administrator for assistance.</div>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    padding: 20px;
                    color: var(--primary-text-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: LoginLocked;
    }
}
