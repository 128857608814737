export enum ReturnReasonsDescriptions {
    ReIssued = 'Re-issued',
    WrongOrInvalidAmount = 'Wrong or Invalid Amount',
    Duplicate = 'Duplicate',
    None = 'None',
    Fraud = 'Fraud',
    InsufficientFunds = 'Insufficient Funds',
    AccountClosed = 'Account Closed',
}

export interface CheckExceptionReturnReasons {
    description: ReturnReasonsDescriptions;
    isDefault: boolean;
    isDeleted: boolean;
    returnReasonUniqueId: string;
}
