/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import { AchNoticeOfChangeFilterModelDto, ErrorHttpContentDto } from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class AchNotificationOfChangeClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AchNotificationOfChange
     * @name AchNotificationOfChangeSearchAchNotificationOfChange
     * @summary Searches for ACH notification of change records.
     * @request POST:/AchNotificationOfChange/Search
     */
    achNotificationOfChangeSearchAchNotificationOfChange = (
        notificationOfChangeFilter: AchNoticeOfChangeFilterModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<any, ErrorHttpContentDto>({
            path: `/AchNotificationOfChange/Search`,
            method: 'POST',
            body: notificationOfChangeFilter,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags AchNotificationOfChange
     * @name AchNotificationOfChangeDownload
     * @summary Downloads an ACH notification of change record as a NACHA file
     * @request GET:/AchNotificationOfChange/Download
     */
    achNotificationOfChangeDownload = (
        query: {
            /** @format guid */
            AchNotificationOfChangeUniqueId: string;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<any, ErrorHttpContentDto>({
            path: `/AchNotificationOfChange/Download`,
            method: 'GET',
            query: query,
            ...params,
        });
}
