import { PendingWire } from '@treasury/domain/approvals/pending-wire.entity';
import { ApprovalOptions, ApprovalVm } from '@treasury/presentation';
import { formatUsdCurrency } from '@treasury/utils';

export class PendingWireViewModel extends ApprovalVm<PendingWire> {
    constructor(wire: PendingWire) {
        super(wire, ApprovalOptions.NA);
    }

    get amount() {
        return formatUsdCurrency(this.value.amount);
    }
}
