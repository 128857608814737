import { DateFormat } from './date-picker.types';

export class TextInputDateFormatter {
    constructor(text: string, format: DateFormat, isRange = false) {
        this.text = text.replace(/\D/g, '');
        this.format = format;
        this.isRange = isRange;
    }

    text: string;

    format: DateFormat;

    isRange: boolean;

    americanDateFormat() {
        const match =
            this.text.match(/^(\d{0,2})(\d{0,2})(\d{0,4})(\d{0,2})(\d{0,2})(\d{0,4})\d*$/) || [];

        if (this.isRange) {
            return [
                match[1],
                match[1].length === 2 ? '/' : '',
                match[2],
                match[2].length === 2 ? '/' : '',
                match[3],
                match[3].length === 4 ? ' - ' : '',
                match[4],
                match[4].length === 2 ? '/' : '',
                match[5],
                match[5].length === 2 ? '/' : '',
                match[6],
            ].join('');
        }
        return [
            match[1],
            match[1].length === 2 ? '/' : '',
            match[2],
            match[2].length === 2 ? '/' : '',
            match[3],
        ].join('');
    }

    isoDateFormat() {
        const match =
            this.text.match(/^(\d{0,4})(\d{0,2})(\d{0,4})(\d{0,4})(\d{0,2})(\d{0,2})\d*$/) || [];

        if (this.isRange) {
            return [
                match[1],
                match[1].length === 4 ? '-' : '',
                match[2],
                match[2].length === 2 ? '-' : '',
                match[3],
                match[3].length === 2 ? ' - ' : '',
                match[4],
                match[4].length === 4 ? '-' : '',
                match[5],
                match[5].length === 2 ? '-' : '',
                match[6],
            ].join('');
        }
        return [
            match[1],
            match[1].length === 4 ? '-' : '',
            match[2],
            match[2].length === 2 ? '-' : '',
            match[3],
        ].join('');
    }

    public toString() {
        if (this.format === DateFormat.ISO) {
            return this.isoDateFormat();
        }
        return this.americanDateFormat();
    }
}
