import { StatusRenderer, Task, TaskStatus } from '@lit/task';
import { TmApiError } from '@treasury/domain/shared';
import { OmegaAlertConfig } from '@treasury/omega/services/omega-alert';
import { exists } from '@treasury/utils';
import { TemplateResult, css, html, nothing } from 'lit';
import { state } from 'lit/decorators.js';
import './components/tm-blocking-loader';
import { TmBaseComponent } from './tm-base.component';

/**
 * Represents a component which has asynchronous work associated with its rendering lifecycle.
 * This class handles the boilerplate of displaying loading, error, and content states in a uniform way.
 *
 * Anything extending this component should call `tryFetch()` at least once.
 */
export abstract class TmContainer extends TmBaseComponent {
    /**
     * Defaults to `true` to prevent bad states until `tryFetch()` is called.
     * It isn't reflected in `outstandingRequestCount` because nothing is in flight yet.
     * This is simpler than implementing a separate "initialized" state.
     */
    @state()
    private isLoading = true;

    /**
     * Tracks the number of active requests so that the container only finishes loading
     * after the last response is received, instead of the first response
     */
    private remainingRequestCount = 0;

    private tasks: Task[] = [];

    private readonly renderers = new WeakMap<Task, StatusRenderer<unknown>>();

    private get hasOutstandingTasks() {
        return this.tasks.some(t => t.status === TaskStatus.PENDING);
    }

    protected get loading() {
        return this.isLoading || this.hasOutstandingTasks;
    }

    protected set loading(val) {
        this.isLoading = val;
    }

    private defaultErrorHandler(e: unknown) {
        let alert: Partial<OmegaAlertConfig> = {
            visible: true,
            type: 'error' as const,
        };

        if (e instanceof TmApiError) {
            const { message, errorCode: code, timestamp: time } = e;
            alert = {
                ...alert,
                message,
                code: code.toString(),
                time,
            };
        } else if (e instanceof Error) {
            alert.message = e.message;
        } else {
            alert.message = 'An error occurred. Please try again.';
        }

        this.renderAlert(alert);
    }

    protected async tryFetch<RetVal = void>(
        fetcher: () => Promise<RetVal>,
        callback: (data: RetVal) => void,
        errorHandler?: (e: unknown) => void
    ) {
        if (this.remainingRequestCount === 0) {
            this.isLoading = true;
        }
        this.remainingRequestCount++;

        try {
            callback(await fetcher());
        } catch (e) {
            const handler = errorHandler || ((e: unknown) => this.defaultErrorHandler(e));
            handler(e);
        } finally {
            this.remainingRequestCount--;
            if (this.remainingRequestCount === 0) {
                this.isLoading = false;
            }
        }
    }

    /**
     * Queue up asynchronous work for which to show appropriate, status-based UI state.
     *
     */
    protected enqueueWork<T, Deps extends ReadonlyArray<unknown> = ReadonlyArray<unknown>>(
        task: Task<Deps, T>,
        renderers: StatusRenderer<T> = {}
    ) {
        // we're using tasks, so allow their state to control the loading UI
        this.isLoading = false;

        if (!renderers.error) {
            renderers.error = e => this.defaultErrorHandler(e);
        }

        if (!renderers.pending) {
            renderers.pending = () => html`<tm-blocking-loader></tm-blocking-loader>`;
        }

        this.tasks.push(task);
        this.renderers.set(task, renderers as StatusRenderer<unknown>);
    }

    renderLoader() {
        if (this.isLoading) {
            return html`<tm-blocking-loader></tm-blocking-loader>`;
        }

        if (this.hasOutstandingTasks) {
            const pendingTasks = this.tasks.filter(t => t.status === TaskStatus.PENDING);
            const renderers = pendingTasks.map(t => this.renderers.get(t)).filter(exists);
            const pendingRenderers = renderers.map(renderers => renderers.pending).filter(exists);

            return pendingRenderers.map(renderPending => renderPending()) as TemplateResult[];
        }

        return nothing;
    }

    static get styles() {
        return [
            css`
                :host {
                    height: 100%;
                }
            `,
        ];
    }
}
