/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
    AccountModel3Dto,
    AddBillPayPaymentRequestModelDto,
    BillPayClient,
    PayeeModelDto,
    PaymentModelDto,
    PaymentTypeDto,
    UpdatePaymentRequestModelDto,
} from '@treasury/api/channel';
import { Injectable } from '@treasury/utils';
import { BillPayAccountModel } from './account.entity';
import { Payee } from './payee-detail.entity';
import { PayeeListModel } from './payee-list.entity';
import { PaymentDetail } from './payment-detail.entity';
import { PaymentListModel } from './payment-list.entity';

export enum PaymentTypes {
    Scheduled = 1,
    Past = 2,
    All = 0,
}

export type BillPaymentQuery = {
    endDate?: string | null;
    payeeId?: string | null;
    startDate?: string | null;
    type?: PaymentTypes;
};

@Injectable()
// eslint-disable-next-line @treasury/filename-match-export
export default class BillPayService {
    // eslint-disable-next-line no-empty-function
    constructor(private readonly billPayClient: BillPayClient) {}

    async getBillPayUrl() {
        const response = await this.billPayClient.billPayGetBillPayUrl();
        return response.data;
    }

    async getBillPayInstitution() {
        const response = await this.billPayClient.billPayGetInstitution();
        return response.data;
    }

    async getPaymentPayees(onlyIncludePayablePayees = true) {
        const response = await this.billPayClient.billPayGetPayees({ onlyIncludePayablePayees });
        /**
         * The contract here is incorrect, data.payee is expected but data.payees is returned
         */
        // @ts-ignore
        if (!response.data.payees) throw new Error('No payees returned');
        // @ts-ignore
        return response.data.payees?.map((payee: PayeeModelDto) => new PayeeListModel(payee));
    }

    async getPaymentPayee(payeeId: string) {
        const response = await this.billPayClient.billPayGetPayee(payeeId);
        if (!response.data.payee) throw new Error('No payee returned');
        return new Payee(response.data.payee);
    }

    async getBillPayments(query: BillPaymentQuery) {
        const response = await this.billPayClient.billPayGetPayments(query);
        if (!response.data.payments) throw new Error('No payments returned');
        return response.data.payments?.map(
            (payment: PaymentModelDto) => new PaymentListModel(payment)
        );
    }

    async getBillPayment(type: PaymentTypeDto, paymentId: string) {
        const response = await this.billPayClient.billPayGetPayment(paymentId, {
            includeFuturePayments: true,
            type,
        });
        return new PaymentDetail(response.data);
    }

    async createBillPayment(request: AddBillPayPaymentRequestModelDto) {
        const response = await this.billPayClient.billPayAddPayment(request);
        return response.data;
    }

    async updateBillPayment(request: UpdatePaymentRequestModelDto) {
        /** Note: 'async' misspelled in the Channel API */
        const response = await this.billPayClient.billPayUpdatePaymentAsnyc(request);
        return response.data;
    }

    async getBillPayAccounts() {
        const response = await this.billPayClient.billPayGetAccounts();
        return response.data.payFromAccounts?.map(
            (account: AccountModel3Dto) => new BillPayAccountModel(account)
        );
    }

    async getBillPayUser() {
        const response = await this.billPayClient.billPayGetUser();
        return response.data;
    }
}
