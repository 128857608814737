import { NavigationService } from '@treasury/core/navigation';
import { CheckException } from '@treasury/domain/arp';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/forms/tm-form-row';
import '@treasury/presentation/components/layout/tm-section';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-footer';
import { InjectProperty } from '@treasury/utils';
import '@vaadin/grid';
import '@vaadin/grid/vaadin-grid-selection-column.js';
import '@vaadin/grid/vaadin-grid-sort-column.js';
import { TextFieldValueChangedEvent } from '@vaadin/text-field';
import { css, html, PropertyValueMap } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { filterTableResults } from '../../../../../utilities/table-result-filter';
import '../../../../components/jhd-action-button';
import '../../../components/decision-toggle';
import './check-exceptions-review-table';

export const tagName = 'check-exceptions-decisions-review';
@customElement(tagName)
export class CheckExceptionsReview extends TmBaseComponent {
    @InjectProperty()
    private declare navService: NavigationService;

    @property({ type: Array })
    public checkExceptions!: Array<CheckException>;

    @state()
    private filteredItems = this.checkExceptions;

    @state()
    showSearch = false;

    @state()
    showFilters = false;

    @state()
    filters = [];

    @state()
    searchString = '';

    @state()
    showSelectAllOptions = false;

    @property()
    showTitle = true;

    @property()
    showSubmit = true;

    get fieldsToFilter() {
        return ['accountNumber', 'checkNumber', 'paidAmount', 'exceptionReason'];
    }

    toggleSearchBar() {
        this.showSearch = !this.showSearch;
    }

    toggleShowSelectAllOptions() {
        this.showSelectAllOptions = !this.showSelectAllOptions;
    }

    payAll() {
        const exceptions = this.filteredItems.map((exception: CheckException) => {
            exception.pay();
            return exception.clone();
        });
        this.filteredItems = exceptions;
    }

    returnAll() {
        this.filteredItems = this.filteredItems.map((exception: CheckException) => {
            exception.return();
            return exception.clone();
        });
    }

    search() {
        this.filteredItems = filterTableResults(
            new CustomEvent(this.searchString),
            this.checkExceptions,
            [...this.fieldsToFilter, 'issuedPayee']
        ) as CheckException[];
    }

    filter(e: TextFieldValueChangedEvent) {
        this.filteredItems = filterTableResults(
            e,
            this.checkExceptions,
            this.fieldsToFilter
        ) as any;
    }

    protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        if (_changedProperties.has('checkExceptions')) {
            this.filteredItems = this.checkExceptions;
            this.filters = [];
        }
    }

    async handleSubmitClick() {
        this.dispatchEvent(new CustomEvent('submit'));
    }

    render() {
        return html`
            <tm-section>
                <h1 class="border-b p-4">Check Exceptions</h1>
                <check-exceptions-review-table
                    .checkExceptions=${this.checkExceptions}
                ></check-exceptions-review-table>
            </tm-section>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: `Decision (${this.checkExceptions.length})`,
                        onClick: () => this.handleSubmitClick(),
                    },
                ]}
            >
            </tm-footer>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    --icon-svg-transform: scale(1) !important;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: CheckExceptionsReview;
    }
}
