/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    AccountModelDto,
    ToAccountPermissionModelDto,
    UnrelatedAccountSearchModelDto,
    UnrelatedAccountSearchResultsModelDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class AccountsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsGet2
     * @summary Returns all the Accounts associated with Company from CORE.
     * @request GET:/accounts/{companyId}
     */
    accountsGet2 = (
        companyId: number,
        query: {
            accountType: string | null;
            includeHoldingCompanies: boolean;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<AccountModelDto[], any>({
            path: `/accounts/${companyId}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsSearchUnrelatedAccounts
     * @summary Returns  all unrelated accounts.
     * @request POST:/accounts/{companyId}/searchunrelatedaccounts
     */
    accountsSearchUnrelatedAccounts = (
        companyId: number,
        model: UnrelatedAccountSearchModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<UnrelatedAccountSearchResultsModelDto, any>({
            path: `/accounts/${companyId}/searchunrelatedaccounts`,
            method: 'POST',
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsGetToAccountPermissions
     * @request GET:/accounts/{id}/toAccountPermissions
     */
    accountsGetToAccountPermissions = (id: number, params: RequestParams = {}) =>
        this.http.request<ToAccountPermissionModelDto[], any>({
            path: `/accounts/${id}/toAccountPermissions`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsPutToAccountPermissions
     * @request PUT:/accounts/{id}/toAccountPermissions
     */
    accountsPutToAccountPermissions = (
        id: number,
        toAccountPermissions: ToAccountPermissionModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<boolean, any>({
            path: `/accounts/${id}/toAccountPermissions`,
            method: 'PUT',
            body: toAccountPermissions,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
