// eslint-disable-next-line import/extensions
import { TmHttpClient } from '@treasury/core/http';

// eslint-disable-next-line @treasury/filename-match-export
export default class TermsAndConditionsRequests {
    static async get(blankIfUserAccepted) {
        const queryValue = blankIfUserAccepted || false;
        const http = await TmHttpClient.getInstance();

        return http.request(`termsAndConditions/?blankIfUserAccepted=${queryValue}`, {
            method: 'GET',
            maxAgeInSeconds: 5,
        });
    }

    static async accept() {
        const http = await TmHttpClient.getInstance();

        return http.request(`termsAndConditions/accept`, {
            method: 'GET',
        });
    }
}
