/* eslint-disable import/no-duplicates */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { NavigationService } from '@treasury/core/navigation';
import { FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-loader';
import '@treasury/presentation/components/tm-table-header';
import { InjectProperty } from '@treasury/utils';
import '@vaadin/grid';
import { GridActiveItemChangedEvent } from '@vaadin/grid';
import { columnBodyRenderer, GridColumnBodyLitRenderer } from '@vaadin/grid/lit.js';
import '@vaadin/grid/vaadin-grid-column.js';
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { filterTableResults } from '../../../utilities/table-result-filter';
import '../../components/jhd-status';
import { getStatusColorToken } from '../../components/jhd-status';
import { AchPaymentTableViewModel, AchPaymentViewModel } from '../data/ach-payment-view-model';
import { AchPayment } from '../types/ach-payment.type';

export const tagName = 'ach-payments-table';
@customElement(tagName)
export class AchPaymentsTable extends TmBaseComponent {
    @InjectProperty()
    private declare featureFlagService: FeatureFlagService;

    @InjectProperty()
    private declare navService: NavigationService;

    @property({ type: Array })
    achPayments = [];

    @state()
    filteredAchPayments: AchPayment[] = [];

    @state()
    private achPaymentViewModels: AchPaymentViewModel[] = [];

    @state()
    private filteredAchPaymentViewModels: AchPaymentViewModel[] = [];

    @state()
    private fieldsToFilter = [
        'name',
        'status',
        'creditAmount',
        'debitAmount',
        'transactionId',
        'paymentDate',
    ];

    @state()
    loading = false;

    updated(changedProperties: Map<string | number | symbol, unknown>) {
        if (changedProperties.has('achPayments')) {
            this.filteredAchPayments = this.achPayments;
            this.buildAchPaymentViewModels();
        }
    }

    buildAchPaymentViewModels() {
        this.achPaymentViewModels = this.achPayments.map(
            achPayment => new AchPaymentTableViewModel(achPayment)
        );
        this.filteredAchPaymentViewModels = this.achPaymentViewModels;
    }

    routeToAchPaymentDetail(achPayment: AchPaymentViewModel) {
        if (achPayment) {
            this.navService.navigate(`/ach/${achPayment.id}`);
        }
    }

    filterResults(e: CustomEvent) {
        this.filteredAchPaymentViewModels = filterTableResults(
            e,
            this.achPaymentViewModels,
            this.fieldsToFilter
        );
    }

    renderStatusIconColumn(achPayment: AchPaymentViewModel) {
        return html`<jhd-status .status=${achPayment.status}></jhd-status>`;
    }

    // TODO: add conditionally rendered columns for larger screens
    renderPaymentNameAndStatusColumn(achPayment: AchPaymentViewModel) {
        return html`<div class="flex -ml-4">
            <div class="flex flex-col">
                <span class="payment-name text-sm font-medium">${achPayment.name}</span>
                <small class="payment-transaction-id text-xs text-[--secondary-text-color]">
                    ${achPayment.transactionId}
                </small>
                <small
                    class="payment-status text-xs"
                    style=${`color: var(${getStatusColorToken(achPayment.status)})`}
                    >${achPayment.status}</small
                >
            </div>
        </div>`;
    }

    renderAmountAndAccountColumn(achPayment: AchPaymentViewModel) {
        const amounts = [];
        if (achPayment.creditAmount) {
            const creditAmount = html`
                <div class="font-medium text-sm ">
                    ${achPayment.creditAmount}
                    <span class="text-xs font-normal">CR</span>
                </div>
            `;
            amounts.push(creditAmount);
        }

        if (achPayment.debitAmount) {
            const debitAmount = html`
                <div class="font-medium text-sm ">
                    ${achPayment.debitAmount}
                    <span class="text-xs font-normal">DR</span>
                </div>
            `;
            amounts.push(debitAmount);
        }

        return html`
            <div class="flex flex-col pr-1">
                ${amounts}
                <small
                    class="payment-transaction-id text-right text-xs text-[--secondary-text-color]"
                >
                    ${achPayment.paymentDate}
                </small>
            </div>
        `;
    }

    renderTable() {
        if (this.loading) return html`<tm-loader card></tm-loader>`;
        if (!this.filteredAchPayments.length)
            return html`
                <div class="mx-auto w-full text-center mt-5 empty-message">
                    No ACH Payments Found
                </div>
            `;
        return html`
            <vaadin-grid
                class="border-y border-[--border-color]"
                .items=${this.filteredAchPaymentViewModels}
                @active-item-changed=${(e: GridActiveItemChangedEvent<AchPaymentViewModel>) => {
                    const { value } = e.detail;
                    if (value != null) this.routeToAchPaymentDetail(value);
                }}
            >
                <vaadin-grid-column
                    flex-grow="0"
                    width="3.5rem"
                    ${columnBodyRenderer(
                        this
                            .renderStatusIconColumn as GridColumnBodyLitRenderer<AchPaymentViewModel>,
                        []
                    )}
                ></vaadin-grid-column>
                <vaadin-grid-column
                    flex-grow="1"
                    ${columnBodyRenderer(
                        this
                            .renderPaymentNameAndStatusColumn as GridColumnBodyLitRenderer<AchPaymentViewModel>,
                        []
                    )}
                ></vaadin-grid-column>
                <vaadin-grid-column
                    text-align="end"
                    width="4em"
                    ${columnBodyRenderer(
                        this
                            .renderAmountAndAccountColumn as GridColumnBodyLitRenderer<AchPaymentViewModel>,
                        []
                    )}
                ></vaadin-grid-column>
            </vaadin-grid>
        `;
    }

    render() {
        return html`<div class="pwa-table-wrapper">
            <tm-table-header
                .filterButton=${true}
                @filterButtonClick=${() =>
                    this.dispatchEvent(new CustomEvent('filter-ach-payments'))}
                @filterStringChange=${(e: CustomEvent) => this.filterResults(e)}
            >
                <h2 class="font-medium">ACH Payment Activity</h2></tm-table-header
            >
            ${this.renderTable()}
        </div> `;
    }

    static get styles() {
        return [
            css`
                button[disabled] {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
                .table-header,
                .empty-message {
                    color: var(--header-text-color);
                }
                vaadin-grid::part(first-column-cell) {
                    border: none;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: AchPaymentsTable;
    }
}
