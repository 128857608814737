import '@treasury/domain-components/deposit/tm-check-capture';
import '@treasury/domain-components/login/login-container';
import '@treasury/domain-components/login/sso-callback-container';
import '@treasury/domain-components/login/uis-container';
import '@treasury/domain-components/terms-and-conditions/container';
import '@treasury/omega/components/feature-flags/feature-flag-button';

export function importTemplates() {
    return Promise.all([
        import('../about/containers/about-container'),
        import('../accounts/containers/account-container'),
        import('../accounts/containers/accounts-container'),
        import('../ach/containers/ach-payment-container'),
        import('../ach/containers/ach-payments-container'),
        import('../ach/containers/ach-workflow-container'),
        import('../ach/containers/ach-templates-container'),
        import('../business-bill-pay/containers/business-bill-pay-container'),
        import('../business-bill-pay/containers/business-bill-pay-workflow-container'),
        import('../business-bill-pay/containers/business-bill-pay-payees-container'),
        import('../business-bill-pay/containers/business-bill-pay-payee-detail-container'),
        import('../business-bill-pay/containers/business-bill-pay-payment-detail-container'),
        import('../components/app-component'),
        import('../components/jhd-layout'),
        import('../components/jhd-data-field'),
        import('../components/not-found'),
        import('../dashboard/containers/dashboard'),
        import('../deposits/containers/batch-deposit-container'),
        import('../deposits/containers/deposit-activity-container'),
        import('../deposits/containers/quick-deposit-container'),
        import('../logout/containers/logout-container'),
        import('../loans/containers/loan-container'),
        import('../message-center/containers/message-center-container'),
        import('../notifications/containers/notifications-container'),
        import('../pending-approvals/containers/pending-approvals-container'),
        import('../positive-pay/check-exceptions/containers/check-exceptions-container'),
        import('../positive-pay/check-exceptions/containers/check-exception-detail-container'),
        import('../positive-pay/ach-exceptions/containers/ach-exceptions-container'),
        import('../profile/containers/user-profile-container'),
        import('../transfers/containers/transfer-workflow-container'),
        import('../transfers/containers/transfers-container'),
        import('../transfers/containers/transfer-templates-container'),
        import('../transfers/partials/create-transfer-payment-step'),
        import('../wires/wire/wire-container'),
        import('../wires/wire-template/wire-template-container'),
        import('../wires/wire-templates/wire-templates-container'),
        import('../wires/wire-workflow/wire-workflow-container'),
        import('../wires/wires/wires-container'),
        import('../users/containers/user-container'),
    ]);
}
