export interface ActiveDateFilterFunction {
    (date: Date, ...args: any[]): boolean;
}

export interface DateTextInputFormatterFunction {
    (input: string, ...args: any[]): string;
}

export enum DateFormat {
    American,
    ISO,
}

export interface DatePickerInputOutputFormat {
    input: DateFormat;
    output: DateFormat;
}
