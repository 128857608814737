import { NavigationService } from '@treasury/core/navigation';
import { CheckException } from '@treasury/domain/arp';
import PositivePayService from '@treasury/domain/channel/services/positive-pay/positive-pay-service';
import { TmContainer } from '@treasury/presentation';
import { InjectProperty } from '@treasury/utils';
import { css, html, nothing, render } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../../services/jhd-header-bar.service';
import '../partials/decisions/check-exceptions-decision-confirmation';
import '../partials/decisions/check-exceptions-decisions-review';

export const tagName = 'check-exceptions-decision-workflow';
@customElement(tagName)
export class CheckExceptionsDecisionWorkflow extends TmContainer {
    @InjectProperty()
    private declare readonly navService: NavigationService;

    @InjectProperty()
    private declare readonly positivePayService: PositivePayService;

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @state()
    step = 1;

    @state()
    reviewSheetOpen = false;

    @property()
    checkExceptions!: Array<CheckException>;

    @state()
    successfulDecision = false;

    firstUpdated() {
        this.loading = false;
        this.headerService.configure({
            title: 'Review Decisions',
            backAction: this.endWorkflow.bind(this),
        });
    }

    endWorkflow() {
        this.step = 1;
        this.dispatchEvent(new CustomEvent('endReviewWorkflow'));
    }

    async submitCheckExceptionDecisions() {
        await this.tryFetch(
            async () => {
                this.headerService.configure({
                    title: 'Submitting Check Decisions...',
                });
                return this.positivePayService.submitCheckExceptions(this.checkExceptions);
            },
            async (res: boolean) => {
                this.successfulDecision = res;
                this.step = 2;
            }
        );
    }

    render() {
        if (this.loading) {
            return html` ${this.renderLoader()} `;
        }

        switch (this.step) {
            case 1:
                return html`
                    <check-exceptions-decisions-review
                        .checkExceptions=${this.checkExceptions}
                        @close=${this.endWorkflow}
                        @submit=${this.submitCheckExceptionDecisions.bind(this)}
                    >
                    </check-exceptions-decisions-review>
                `;
            case 2:
                return html`<check-exceptions-decision-confirmation
                    .checkExceptions=${this.checkExceptions}
                    @close=${this.endWorkflow}
                    .successfulDecision=${this.successfulDecision}
                >
                </check-exceptions-decision-confirmation>`;
            default:
                return nothing;
        }
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                    height: 100%;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: CheckExceptionsDecisionWorkflow;
    }
}
