/* eslint-disable no-use-before-define */
import { UserInformationModelDto } from '@treasury/api/channel';
import { clone } from '@treasury/utils';
import { DomainEntity } from '../../../../shared';

export class UserAccount extends DomainEntity<UserInformationModelDto> {
    constructor(dto = clone(defaultFormData) as UserInformationModelDto) {
        super(dto);
    }

    public get name() {
        return this.dto.name;
    }

    public set name(val) {
        this.dto.name = val;
    }

    public get loginId() {
        return this.dto.loginId;
    }

    public set loginId(val) {
        this.dto.loginId = val;
    }

    public get email() {
        return this.dto.email;
    }

    public set email(val) {
        this.dto.email = val;
    }

    public get extension() {
        return this.dto.extension;
    }

    public set extension(val) {
        this.dto.extension = val;
    }

    public get phone() {
        return this.dto.phone;
    }

    public set phone(val) {
        this.dto.phone = val;
    }

    public get mobile() {
        return this.dto.mobile || '';
    }

    public set mobile(val) {
        this.dto.mobile = val;
    }

    public get fax() {
        return this.dto.fax || '';
    }

    public set fax(val) {
        this.dto.fax = val;
    }

    public get accountNicknames() {
        return this.dto.accountNicknames ?? false;
    }

    public set accountNicknames(val) {
        this.dto.accountNicknames = val;
    }

    public get accountMasking() {
        return this.dto.accountMasking ?? false;
    }

    public set accountMasking(val) {
        this.dto.accountMasking = val;
    }

    public get verificationMethod() {
        return this.dto.verificationMethod;
    }

    public createDefault() {
        return new UserAccount() as this;
    }
}

const defaultFormData: Partial<UserInformationModelDto> = Object.freeze({
    name: '',
    loginId: '',
    email: '',
    phone: '',
    extension: '',
    mobile: '',
    fax: '',
    accountNicknames: false,
    accountMasking: false,
    verificationMethod: '',
});
