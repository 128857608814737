import { CheckCaptureService, MitekCaptureResult } from '@treasury/libraries';
import { TmBaseComponent } from '@treasury/presentation';
import { cameraIcon, closeIcon, informationIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-loader';
import '@treasury/presentation/components/tm-tooltip-rich';
import { InjectProperty, createUniqueId } from '@treasury/utils';
import { css, html, nothing, render } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

export const tagName = 'tm-check-capture';
@customElement(tagName)
export class TmCheckCapture extends TmBaseComponent {
    @InjectProperty()
    private declare checkCaptureService: CheckCaptureService;

    @property({ type: String })
    public label = '';

    @property({ type: String, reflect: true })
    public checkSide: 'front' | 'back' = 'front';

    @state()
    private imageId = createUniqueId();

    @state()
    private photo?: string | null;

    @state()
    private isCapturing = false;

    private generatePreviewImage(file: string) {
        this.photo = file;
    }

    private close() {
        this.checkCaptureService.stopCapture();
    }

    private overlayExists() {
        return document.querySelector('#mitekOverlayControls');
    }

    private addOverlay() {
        if (this.overlayExists()) {
            return;
        }
        const mitekDisplayContainer = document.querySelector('#mitekDisplayContainer');

        const overlayContainer = document.createElement('div');
        overlayContainer.setAttribute('id', 'mitekOverlayControls');
        overlayContainer.setAttribute(
            'style',
            'position: absolute; right: 0px; top: 0px; z-index: 101; background-color: transparent;'
        );
        const overlayControls = this.renderOverlayControls();
        render(overlayControls, overlayContainer);
        if (mitekDisplayContainer) {
            mitekDisplayContainer.appendChild(overlayContainer);
        }
    }

    private async handleCaptureClick() {
        const captureSubscription = this.checkCaptureService.captureCompleted$.subscribe(
            (result: MitekCaptureResult) => {
                this.generatePreviewImage(result.response.imageData);
                this.dispatchEvent(
                    new CustomEvent('image-uploaded', {
                        detail: {
                            checkSide: this.checkSide,
                            image: result.response.imageData,
                        },
                    })
                );
                captureSubscription.unsubscribe();
            }
        );
        const processSubscription = this.checkCaptureService.processingStarted$.subscribe(() => {
            this.addOverlay();
            this.isCapturing = false;
            processSubscription.unsubscribe();
        });

        this.isCapturing = true;
        this.checkCaptureService.startCapture(
            this.checkSide === 'front' ? 'CHECK_FRONT' : 'CHECK_BACK'
        );
    }

    private manualCapture() {
        this.checkCaptureService.manualCapture();
    }

    private renderHintTooltip() {
        return html`<div
            class="capture-hint-header flex flex-col items-left pb-1 font-medium text-[--header-text-color] border-[--border-color]"
        >
            <img
                class="w-1/3"
                src="/assets/images/graphic-check-orientation-${this.checkSide}.png"
                alt="Check ${this.checkSide} sample"
            />
            <div>
                <span class="text-gray-200 text-xl">Capture check ${this.checkSide}</span>
                <div class="text-lg font-normal text-gray-400">
                    Place check on a flat, well-lit, surface and tap the capture button to take the
                    photo.
                </div>
            </div>
        </div>`;
    }

    private renderOverlayControls() {
        const checkSideText = this.checkSide === 'front' ? 'Front' : 'Back';
        const tapToCapturePrompt = 'Wait for Auto-Capture or Tap to Capture';

        return html`
            <div
                id="top-controls"
                class="relative text-center flex items-center justify-between p-1 w-screen bg-gray-600 bg-opacity-75"
            >
                <button class="close-button mt-2" @click=${() => this.close()}>
                    <tm-icon color="#ccc" .icon=${closeIcon}></tm-icon>
                </button>
                <div class="check-side-label text-gray-300 portrait:text-xl landscape:text-2xl">
                    ${checkSideText} of Check
                </div>
                <tm-tooltip-rich direction="bottom-left" isClickable show>
                    <tm-icon color="#ccc" .icon=${informationIcon}></tm-icon>

                    <style>
                        .tooltip-content {
                            width: 350px;
                            margin-top: -7px;
                        }
                    </style>
                    <div class="tooltip-content" slot="content">${this.renderHintTooltip()}</div>
                </tm-tooltip-rich>
            </div>
            <div
                id="capture-button"
                class="relative text-center flex justify-center items-center min-h-screen min-w-screen bg-transparent"
            >
                <button
                    class="landscape:text-4xl portrait:text-2xl text-gray-100"
                    @click=${() => this.manualCapture()}
                >
                    ${tapToCapturePrompt}
                </button>
            </div>
        `;
    }

    private renderPhoto() {
        if (this.photo) {
            return html`
                <img id="photo" src=${this.photo} alt="Captured ${this.checkSide} image" />
            `;
        }
        return html` <div id="empty-photo">${this.renderCaptureLoading()}</div>`;
    }

    private renderCaptureLoading() {
        if (this.isCapturing) {
            return html`<tm-loader card></tm-loader>`;
        }
        return nothing;
    }

    private renderCapture() {
        return html`
            <button
                @click=${() => {
                    this.handleCaptureClick();
                }}
            >
                <div class="contentArea">
                    <div class="output">${this.renderPhoto()}</div>
                </div>
            </button>
        `;
    }

    public render() {
        return html`
            <div
                class="tm-check-capture-wrapper relative text-center flex items-center justify-between p-1"
            >
                <label
                    for=${this.imageId}
                    class="tm-check-capture-label flex items-center grow h-20 cursor-pointer rounded text-center"
                    >${cameraIcon}&nbsp;${this.label}
                </label>
                ${this.renderCapture()}
            </div>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: inline-block;
                }
                label {
                    color: #fff;
                    transition: all 0.25s;
                    cursor: pointer;
                    font-size: 14px;
                }
                input {
                    z-index: 1;
                    opacity: 0;
                    box-sizing: border-box;
                    height: 80px;
                    background: #f4f4f4;
                    border: 1px solid #dddddd;
                }
                input[disabled] + label {
                    cursor: not-allowed;
                    opacity: 0.8;
                }
                input::file-selector-button {
                    display: none;
                }

                #photo {
                    border: 1px solid black;
                    box-shadow: 2px 2px 3px black;
                    width: 140px;
                    height: 80px;
                }

                #empty-photo {
                    border: 1px solid black;
                    box-shadow: 2px 2px 3px black;
                    width: 140px;
                    height: 80px;
                }

                .output {
                    width: 140px;
                    display: inline-block;
                    vertical-align: top;
                }

                .capture-loading {
                    position: relative;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                }

                tm-loader {
                    position: absolute;
                    top: -55%;
                    left: 50%;
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: TmCheckCapture;
    }
}
