export enum CheckCorrectionType {
    AccountNumber = 'Receiving Bank - Account Number',
    DatePosted = 'Receiving Bank - Date Posted',
    CheckNumber = 'Receiving Bank - Check Number',
    Amount = 'Receiving Bank - Amount',
    Payee = 'Receiving Bank - Payee',
    Duplicate = 'Duplicate Check',
}

export enum CheckCorrectionLabel {
    AccountNumber = 'account number',
    DatePosted = 'date posted',
    CheckNumber = 'check number',
    Amount = 'amount',
    Payee = 'payee',
    None = '',
}

export const CheckCorrectionTypeList: Array<CheckCorrectionType> = [
    CheckCorrectionType.AccountNumber,
    CheckCorrectionType.DatePosted,
    CheckCorrectionType.CheckNumber,
    CheckCorrectionType.Amount,
    CheckCorrectionType.Payee,
    CheckCorrectionType.Duplicate,
];

export type CheckCorrection = {
    type: CheckCorrectionType | null;
    previousValue: any;
    correctedValue: string;
    label: CheckCorrectionLabel;
};
