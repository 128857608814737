import { TmBaseComponent } from '@treasury/presentation';
import { checkmarkIcon } from '@treasury/presentation/assets/icons';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import '../../../../components/jhd-icon';
import { checkExceptionStyleClasses } from '../../../styles/check-exception-styles';

export const tagName = 'check-exception-select-from-list';
@customElement(tagName)
export class CheckExceptionSelectFromList<T> extends TmBaseComponent {
    @property()
    list!: Array<T>;

    @property()
    selectedItem!: T | undefined;

    @property()
    changeItem!: (item: T) => void;

    @property()
    getDisplayNameFromListItem?: (item: T) => string;

    @property()
    getIdFromListItem?: (item: T) => string;

    itemClickHandler(newItem: T) {
        this.changeItem(newItem);
    }

    renderIcon(item: T) {
        let isSelected;
        if (this.getIdFromListItem && this.selectedItem) {
            isSelected = this.getIdFromListItem(item) === this.getIdFromListItem(this.selectedItem);
        } else {
            isSelected = item === this.selectedItem;
        }

        return isSelected ? html`<jhd-icon .icon=${checkmarkIcon}></jhd-icon>` : nothing;
    }

    renderList() {
        return this.list.map((item: T, idx) => {
            const clickHandler = () => this.itemClickHandler(item);
            const icon = this.renderIcon(item);

            const classes = classMap({
                'flex items-center  h-full w-full': true,
                'border-b': idx !== this.list.length - 1,
            });
            return html`<button
                class="w-full h-14 flex items-center item-to-select"
                @click=${clickHandler}
            >
                <div class="h-14 w-14 flex items-center justify-center">${icon}</div>
                <div class=${classes}>
                    ${this.getDisplayNameFromListItem
                        ? this.getDisplayNameFromListItem(item)
                        : item}
                </div>
            </button> `;
        });
    }

    render() {
        return html`<div>${this.renderList()}</div>`;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                }
            `,
            checkExceptionStyleClasses,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: CheckExceptionSelectFromList<unknown>;
    }
}
