import { OpenIdleDialogFn } from '@treasury/domain/services/logout/idle-signout-service';
import { DiContainer } from '@treasury/utils/dependency-injection';
import { OmegaDialogExitReason, OmegaDialogService } from '../../services/omega-dialog';

const continueLabel = 'Continue Working';
const logoutLabel = 'Log Out';
const prompt = `For your protection, you will be logged out automatically due to inactivity. If
you would like to remain logged in, please click '${continueLabel}' below to
keep your session active.`;

export const openIdleDialog: OpenIdleDialogFn = async function openIdleDialog(config) {
    const di = await DiContainer.getInstance();
    const dialogService = di.get(OmegaDialogService);
    const { logoSource } = config;
    const handle = dialogService.open(prompt, 'Session Timeout', {
        logoSource,
        buttons: {
            confirm: {
                label: logoutLabel,
            },
            cancel: {
                label: continueLabel,
            },
        },
    });

    return {
        getDetail: async () => {
            const { reason } = await handle.closed;
            return reason === OmegaDialogExitReason.Confirm ? 'logout' : 'continue';
        },
        close: () =>
            handle.close({
                reason: OmegaDialogExitReason.ForceClose,
            }),
    };
};
