import 'reflect-metadata';
import { Constructor } from '../../types';
import { di$ } from '../di-container';
import { TOKEN_INJECTABLES } from '../di.constants';
import { getTokenInjectables } from '../di.helpers';
import { InjectionToken, SymbolToken } from '../di.types';

/**
 * Decorate a constructor parameter with the name or token of a constant dependency
 * known to the DI container. This value will be fulfilled on instantiation.
 *
 * This is necessary when injecting a dependency that is registered with
 * the DI container using an injection token other than a class constructor.
 *
 * Examples of things that might be injected this way include constant values like strings and numbers,
 * or already existing objects with no available constructor such as `window`.
 *
 * @param token The name of the non-constructor dependency.
 */
export function InjectByToken<T, Class>(token: SymbolToken<T>) {
    return function (ctor: Constructor<Class>, paramName: string | undefined, index: number) {
        const injectables = getTokenInjectables(ctor) || {};

        injectables[index] = {
            index,
            token,
        };

        Reflect.defineMetadata(TOKEN_INJECTABLES, injectables, ctor);

        // if symbol token is an `InjectionToken` and not a string,
        // it can be registered on its own since it can produce its own value
        if (token instanceof InjectionToken) {
            // register token with any new container instances that are emitted
            di$.subscribe(newContainer => {
                if (!newContainer.has(token)) {
                    newContainer.provide(token);
                }
            }, true);
        }
    };
}
