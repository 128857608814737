import { GetPaymentResponseModelDto } from '@treasury/api/channel';
import { DomainEntity } from '../shared';
import { getPaymentMethodString, getStatusString } from './payment-list.entity';

export class PaymentDetail extends DomainEntity<GetPaymentResponseModelDto> {
    constructor(payment: GetPaymentResponseModelDto) {
        super(payment);
    }

    public createDefault() {
        return new PaymentDetail({} as GetPaymentResponseModelDto) as this;
    }

    public get futurePayments() {
        return this.dto.futurePayments;
    }

    public get amount() {
        return this.dto.payment?.amount;
    }

    public get comment() {
        return this.dto.payment?.comment;
    }

    public get estimatedArrivalDate() {
        return this.dto.payment?.estimatedArrivalDate;
    }

    public get fromAccount() {
        return this.dto.payment?.fromAccount;
    }

    public get payee() {
        return this.dto.payment?.payee;
    }

    public get paymentMethod() {
        return getPaymentMethodString(this.dto.payment?.paymentMethod || 0);
    }

    public get paymentType() {
        return this.dto.payment?.paymentType;
    }

    public get processDate() {
        return this.dto.payment?.processDate;
    }

    public get recurrence() {
        return this.dto.payment?.recurrence || 'One Time';
    }

    public get status() {
        return getStatusString(this.dto.payment?.status || 0);
    }
}
