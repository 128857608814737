import { NavigationService } from '@treasury/core/navigation';
import { PayeeStatus } from '@treasury/domain/bill-pay';
import BillPayService from '@treasury/domain/bill-pay/bill-pay.service';
import { LabeledList, TmBaseComponent } from '@treasury/presentation';
import { informationIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-badge';
import '@treasury/presentation/components/tm-blocking-loader';
import '@treasury/presentation/components/tm-labeled-list';
import { InjectProperty } from '@treasury/utils';
import '@vaadin/grid';
import '@vaadin/grid/vaadin-grid-column';
import { html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { HeaderBarService } from '../../services/jhd-header-bar.service';
import { BillPayPayeeViewModel } from '../data/bill-pay-payee-detail-view-model';
import { BillPayPaymentDetailViewModel } from '../data/bill-pay-payment-detail-view-model';

interface RouteParams {
    id: string;
}

export const tagName = 'business-bill-pay-payee-detail-container';
@customElement(tagName)
export class BusinessBillPayPayeeDetailContainer extends TmBaseComponent {
    @InjectProperty()
    private declare billPayService: BillPayService;

    @InjectProperty()
    private declare headerService: HeaderBarService;

    @InjectProperty()
    private declare navigationService: NavigationService;

    @state()
    loading = true;

    @state()
    payeeId = '';

    @state()
    payee?: any;

    firstUpdated() {
        this.configureHeader();
        this.fetchPayee();
    }

    async fetchPayee() {
        this.loading = true;
        try {
            const { params } = await this.navigationService.getRouteData<RouteParams>();
            this.payeeId = params.id;
            const payee = await this.billPayService.getPaymentPayee(this.payeeId);
            this.payee = new BillPayPayeeViewModel(payee);
        } catch (error) {
            console.error(error);
        } finally {
            this.loading = false;
        }
    }

    async configureHeader() {
        this.headerService.configure({
            title: 'Payee Detail',
            backAction: () => this.navigationService.navigate(`/business-bill-pay/payees`),
            menuItems: [
                {
                    title: 'Info',
                    icon: informationIcon,
                    action: () => console.log('Info clicked'),
                },
            ],
        });
    }

    renderPayeeDetail() {
        return html`<div class="flex w-full justify-between items-center pb-5">
            <span class="text-xl">${this.payee.name}</span>
            <tm-badge>${this.payee.paymentMethod}</tm-badge>
        </div>`;
    }

    renderLabeledList() {
        return html`<tm-section class="px-3 py-5">
            ${this.renderPayeeDetail()}
            <tm-labeled-list
                .list=${new LabeledList(
                    this.payee,
                    [
                        'address',
                        'phoneNumber',
                        'defaultPayFromAccount',
                        'accountNumber',
                        'accountType',
                    ],
                    { fromAccount: 'Default Pay From' }
                )}
            ></tm-labeled-list
        ></tm-section>`;
    }

    renderFooter() {
        if (this.payee.status !== PayeeStatus.Activated) return nothing;
        return html`<tm-footer
            class="fixed bottom-0 w-full"
            .buttonConfig=${[
                {
                    text: 'Create Payment',
                    onClick: () => {
                        this.navigationService.navigate(`/business-bill-pay/payment/create/new`);
                    },
                },
            ]}
        ></tm-footer>`;
    }

    render() {
        if (this.loading) {
            return html`<tm-blocking-loader></tm-blocking-loader>`;
        }
        return html`${this.renderLabeledList()}${this.renderFooter()}`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: BusinessBillPayPayeeDetailContainer;
    }
}
