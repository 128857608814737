/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TmHttpClient } from '@treasury/core/http';
import { Injectable } from '@treasury/utils/dependency-injection';
import { createSwaggerClient } from '../../utils/tm-swagger-client';

import {
    AchEditablePaymentModelDto,
    AchFederalTaxPaymentModelDto,
    AchPaymentFilterModelDto,
    AchPaymentListModelDto,
    AchPaymentModelDto,
    AchPaymentWithTemplatesModelDto,
    AchStateTaxPaymentModelDto,
    AchUninitiateModelDto,
    CancelAchPaymentModelDto,
    ChildSupportEditableAchPaymentModelDto,
    ChildSupportPayment2Dto,
    ChildSupportPaymentResponseDto,
    CreateInternationalAchPaymentRequestDto,
    CreateInternationalAchPaymentResponseDto,
    ErrorHttpContentDto,
    GetInternationalAchCountriesResponse2Dto,
    GetInternationalAchPaymentResponse2Dto,
    InternationalAchPaymentDto,
    InternationalEditableAchPaymentModelDto,
    LookupListModelDto,
    LookupModelDto,
    RoutingNumberStatusModelDto,
    SecurityBaseDto,
    UpdateInternationalAchPaymentRequestDto,
} from '../';
import { ContentType, HttpClient, RequestParams } from './http-client';

@Injectable()
export class AchPaymentsClient {
    private readonly http: HttpClient;

    constructor(tmHttp: TmHttpClient) {
        this.http = createSwaggerClient(tmHttp, HttpClient);
    }

    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsGetAll
     * @summary Provides the endpoint to get all the ach  payments.
     * @request GET:/AchPayments
     */
    achPaymentsGetAll = (params: RequestParams = {}) =>
        this.http.request<AchPaymentModelDto[], ErrorHttpContentDto>({
            path: `/AchPayments`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsPostAll
     * @summary Provides an endpoint to search for ACH Payments.
     * @request POST:/AchPayments
     */
    achPaymentsPostAll = (
        query: {
            /**
             * Type: OneTime, Recurring.
             *
             */
            type: string | null;
        },
        achPaymentFilter: AchPaymentFilterModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<AchPaymentModelDto[], ErrorHttpContentDto>({
            path: `/AchPayments`,
            method: 'POST',
            query: query,
            body: achPaymentFilter,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsGet
     * @summary Provides the endpoint to verify a routing number.
     * @request GET:/achPayments/verifyRoutingNumber
     */
    achPaymentsGet = (
        query: {
            /**
             * Routing number to verify.
             *
             */
            routingNumber: string | null;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<RoutingNumberStatusModelDto, ErrorHttpContentDto>({
            path: `/achPayments/verifyRoutingNumber`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsGetPaymentDrafts
     * @summary Provides the endpoint to return ACH payment drafts.
     * @request POST:/achPayments/paymentDrafts
     */
    achPaymentsGetPaymentDrafts = (
        paymentDraftIds: LookupModelDto[] | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchPaymentModelDto, ErrorHttpContentDto>({
            path: `/achPayments/paymentDrafts`,
            method: 'POST',
            body: paymentDraftIds,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsGetOffsetAccounts
     * @summary Provides the endpoint to get all offset accounts.
     * @request GET:/achPayments/offsetAccounts
     */
    achPaymentsGetOffsetAccounts = (params: RequestParams = {}) =>
        this.http.request<LookupModelDto[], ErrorHttpContentDto>({
            path: `/achPayments/offsetAccounts`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsPost
     * @summary Provides the endpoint to create ACH payments.
     * @request POST:/AchPayments/Payment
     */
    achPaymentsPost = (achPayment: AchPaymentModelDto | null, params: RequestParams = {}) =>
        this.http.request<AchEditablePaymentModelDto, ErrorHttpContentDto>({
            path: `/AchPayments/Payment`,
            method: 'POST',
            body: achPayment,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsCreateTaxPaymentsFromTemplates
     * @summary Provides the endpoint to create ACH tax payments.
     * @request POST:/achPayments/taxPayments
     */
    achPaymentsCreateTaxPaymentsFromTemplates = (
        achPayment: AchPaymentWithTemplatesModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchEditablePaymentModelDto, ErrorHttpContentDto>({
            path: `/achPayments/taxPayments`,
            method: 'POST',
            body: achPayment,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsAchStateTaxPayment
     * @summary Provides the endpoint to create ACH state tax payments.
     * @request POST:/achPayments/stateTaxPayment
     */
    achPaymentsAchStateTaxPayment = (
        stateTaxPayment: AchStateTaxPaymentModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchEditablePaymentModelDto, ErrorHttpContentDto>({
            path: `/achPayments/stateTaxPayment`,
            method: 'POST',
            body: stateTaxPayment,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsAchFederalTaxPayment
     * @summary Provides the endpoint to create ACH federal tax payments.
     * @request POST:/achPayments/federalTaxPayment
     */
    achPaymentsAchFederalTaxPayment = (
        federalTaxPayment: AchFederalTaxPaymentModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchEditablePaymentModelDto, ErrorHttpContentDto>({
            path: `/achPayments/federalTaxPayment`,
            method: 'POST',
            body: federalTaxPayment,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsGet2
     * @summary Provides the end point to get the details for a specific id.
     * @request GET:/achPayments/{id}
     */
    achPaymentsGet2 = (id: number, params: RequestParams = {}) =>
        this.http.request<AchEditablePaymentModelDto, ErrorHttpContentDto>({
            path: `/achPayments/${id}`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsPut
     * @summary Provides the endpoint to update an ACH payment.
     * @request PUT:/achPayments/{id}
     */
    achPaymentsPut = (
        id: number,
        achPayment: AchPaymentModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchEditablePaymentModelDto, ErrorHttpContentDto>({
            path: `/achPayments/${id}`,
            method: 'PUT',
            body: achPayment,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsPost2
     * @summary Provides the endpoint to approve an ACH payment.
     * @request POST:/achPayments/{id}/approve
     */
    achPaymentsPost2 = (id: number, comment: LookupModelDto, params: RequestParams = {}) =>
        this.http.request<AchPaymentModelDto, ErrorHttpContentDto>({
            path: `/achPayments/${id}/approve`,
            method: 'POST',
            body: comment,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsPost3
     * @summary Provides the endpoint to reject an ACH payment.
     * @request POST:/achPayments/{id}/reject
     */
    achPaymentsPost3 = (id: number, comment: LookupModelDto, params: RequestParams = {}) =>
        this.http.request<AchPaymentModelDto, ErrorHttpContentDto>({
            path: `/achPayments/${id}/reject`,
            method: 'POST',
            body: comment,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsCancel
     * @summary Provides the endpoint to cancel a payment.
     * @request PUT:/achPayments/cancel
     */
    achPaymentsCancel = (
        cancelAchPaymentModel: CancelAchPaymentModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<SecurityBaseDto, ErrorHttpContentDto>({
            path: `/achPayments/cancel`,
            method: 'PUT',
            body: cancelAchPaymentModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsUninitiate
     * @summary Provides the endpoint to uninitiate a payment.
     * @request PUT:/achPayments/uninitiate
     */
    achPaymentsUninitiate = (
        achUninitiateModel: AchUninitiateModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchUninitiateModelDto, ErrorHttpContentDto>({
            path: `/achPayments/uninitiate`,
            method: 'PUT',
            body: achUninitiateModel,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsPostAll3
     * @summary Provides an endpoint to search for ACH Payments.
     * @request POST:/AchPayments/FilterPayments
     */
    achPaymentsPostAll3 = (
        query: {
            /**
             * Type: OneTime, Recurring.
             *
             */
            type: string | null;
        },
        achPaymentFilter: AchPaymentFilterModelDto,
        params: RequestParams = {}
    ) =>
        this.http.request<AchPaymentModelDto[], ErrorHttpContentDto>({
            path: `/AchPayments/FilterPayments`,
            method: 'POST',
            query: query,
            body: achPaymentFilter,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsPost4
     * @summary Provides the endpoint to upload an ACH Payment File.
     * @request POST:/achPayments/upload
     */
    achPaymentsPost4 = (params: RequestParams = {}) =>
        this.http.request<AchPaymentModelDto, ErrorHttpContentDto>({
            path: `/achPayments/upload`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsUploadChildSupport
     * @summary Provides the endpoint to upload child support ACH Payment file.
     * @request POST:/achPayments/uploadChildSupport
     */
    achPaymentsUploadChildSupport = (params: RequestParams = {}) =>
        this.http.request<AchPaymentModelDto, ErrorHttpContentDto>({
            path: `/achPayments/uploadChildSupport`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsUploadInternational
     * @summary Provides the endpoint to upload international ACH Payment file.
     * @request POST:/achPayments/uploadInternational
     */
    achPaymentsUploadInternational = (params: RequestParams = {}) =>
        this.http.request<InternationalAchPaymentDto, ErrorHttpContentDto>({
            path: `/achPayments/uploadInternational`,
            method: 'POST',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsBatch
     * @summary Provides the endpoint to bulk approve or reject payments.
     * @request POST:/AchPayments/Batch
     */
    achPaymentsBatch = (
        query: {
            /**
             * Approve/Reject.
             *
             */
            action: string | null;
        },
        model: LookupListModelDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<AchPaymentListModelDto, ErrorHttpContentDto>({
            path: `/AchPayments/Batch`,
            method: 'POST',
            query: query,
            body: model,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsCreateChildSupportPayment
     * @summary Provides the endpoint to add child support payment.
     * @request POST:/AchPayments/ChildSupportPayment
     */
    achPaymentsCreateChildSupportPayment = (
        payment: ChildSupportPayment2Dto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ChildSupportPaymentResponseDto, ErrorHttpContentDto>({
            path: `/AchPayments/ChildSupportPayment`,
            method: 'POST',
            body: payment,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsChildSupportPayment
     * @summary Provides the endpoint to update an ACH payment for child support.
     * @request PUT:/achPayments/ChildSupportPayment/{id}
     */
    achPaymentsChildSupportPayment = (
        id: number,
        childSupportPayment: ChildSupportPayment2Dto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<ChildSupportEditableAchPaymentModelDto, ErrorHttpContentDto>({
            path: `/achPayments/ChildSupportPayment/${id}`,
            method: 'PUT',
            body: childSupportPayment,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsGetChildSupportPayment
     * @summary Provides the end point to get the details for a specific child support payment by ID.
     * @request GET:/AchPayments/GetChildSupportPayment
     */
    achPaymentsGetChildSupportPayment = (
        query: {
            /**
             * Child support payment ID.
             *
             * @format int32
             */
            paymentId: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<ChildSupportPaymentResponseDto, ErrorHttpContentDto>({
            path: `/AchPayments/GetChildSupportPayment`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsGetInternationalAchCountries
     * @summary Provides the endpoint to get the list of countries used with international ACH payments.
     * @request GET:/AchPayments/GetInternationalAchCountries
     */
    achPaymentsGetInternationalAchCountries = (params: RequestParams = {}) =>
        this.http.request<GetInternationalAchCountriesResponse2Dto, ErrorHttpContentDto>({
            path: `/AchPayments/GetInternationalAchCountries`,
            method: 'GET',
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsGetInternationalAchPayment
     * @summary Provides the endpoint to get details about a specific international ACH payment.
     * @request GET:/AchPayments/GetInternationalAchPayment
     */
    achPaymentsGetInternationalAchPayment = (
        query: {
            /**
             * The ACH international payment ID.
             * @format int32
             */
            id: number;
        },
        params: RequestParams = {}
    ) =>
        this.http.request<GetInternationalAchPaymentResponse2Dto, ErrorHttpContentDto>({
            path: `/AchPayments/GetInternationalAchPayment`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsCreateInternationalAchPayment
     * @summary Provides the endpoint to add international ach payment.
     * @request POST:/AchPayments/CreateInternationalAchPayment
     */
    achPaymentsCreateInternationalAchPayment = (
        request: CreateInternationalAchPaymentRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<CreateInternationalAchPaymentResponseDto, ErrorHttpContentDto>({
            path: `/AchPayments/CreateInternationalAchPayment`,
            method: 'POST',
            body: request,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags AchPayments
     * @name AchPaymentsInternationalAchPayment
     * @summary Provides the endpoint to update an ACH payment for international.
     * @request PUT:/achPayments/InternationalAchPayment/{id}
     */
    achPaymentsInternationalAchPayment = (
        id: number,
        updateInternationalAchPaymentRequest: UpdateInternationalAchPaymentRequestDto | null,
        params: RequestParams = {}
    ) =>
        this.http.request<InternationalEditableAchPaymentModelDto, ErrorHttpContentDto>({
            path: `/achPayments/InternationalAchPayment/${id}`,
            method: 'PUT',
            body: updateInternationalAchPaymentRequest,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
}
