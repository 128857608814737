/* eslint-disable import/no-extraneous-dependencies */
import { Injectable } from '@treasury/utils';
import { format } from 'date-fns';
import { AccountDto } from '../../types';
/* eslint-disable import/extensions */
import { TmHttpClient } from '@treasury/core/http';

type AccountType = 'Deposit' | 'TimeDeposit' | 'Loan';

type BalanceDto = {
    date: string;
    availableBalance: number;
    currentBalance: number;
    collectedBalance: number;
};

export type Transaction = {
    amount: number | bigint | string;
    balance: number;
    checkImageNumber: number;
    checkNumber: string;
    date: string;
    description: string;
    id: number;
    isDebit: boolean;
    postingSequence: number;
    tranCodeDescription: string;
    tranCodeType: string;
    debit: number;
    credit: number;
    runningBalance: number;
};

type AccountTransactionsDto = {
    transactions: Array<Transaction>;
};

type BalanceHistoryDto = {
    balanceHistory: BalanceDto[];
    transactions: Transaction[];
};

const dollarUSLocale = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

@Injectable()
export default class AccountsService {
    // eslint-disable-next-line no-useless-constructor
    constructor(private readonly http: TmHttpClient) {}

    getCoreDataAccounts(accountIds: string[]) {
        const query = accountIds.map((account: string) => `accountIds=${account}`);
        const queryString = [
            ...query,
            `&includeCollectedBalance=true`,
            `&includePermissions=true`,
        ].join('&');
        return this.http.request(`accounttransactions/accounts/coredata?${queryString}`, {
            method: 'GET',
        });
    }

    async getAccountByType(type: AccountType) {
        const response = await this.http.request<AccountDto[]>(
            `accounttransactions/?category=${type}`,
            {
                method: 'GET',
            }
        );
        return response.map((account: AccountDto) => {
            const dollarUSLocale = Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });
            const accountViewModel = { ...account };
            accountViewModel.currentBalanceFormatted = dollarUSLocale.format(
                account.currentBalance
            );
            accountViewModel.availableBalanceFormatted = dollarUSLocale.format(
                account.availableBalance
            );
            accountViewModel.collectedBalanceFormatted = dollarUSLocale.format(
                account.collectedBalance
            );
            return accountViewModel;
        });
    }

    async getAccountBalanceHistory(accountId: string, accountType: string) {
        const response = await this.http.request<BalanceHistoryDto>(
            `dashboard/transactiondetail/?accountId=${accountId}&accountType=${accountType}`,
            {
                method: 'GET',
            }
        );
        const balances = response.balanceHistory.map(
            (balanceSnapshot: BalanceDto) => balanceSnapshot.currentBalance
        );
        const dates = response.balanceHistory.map(balanceSnapshot => balanceSnapshot.date);
        const transactions = response.transactions.map((transaction: Transaction) => ({
            ...transaction,
            amount: dollarUSLocale.format(transaction.amount as number | bigint),
            // eslint-disable-next-line @treasury/no-date
            date: format(new Date(transaction.date), 'PP'),
        }));

        return { balances, dates, transactions };
    }

    async getAccountTotals(accountId: string) {
        const { availableBalance, collectedBalance, currentBalance } =
            await this.http.request<BalanceDto>(
                `dashboard/depositTransactionDetail/?accountId=${accountId}`,
                {
                    method: 'GET',
                }
            );
        return {
            availableBalance: dollarUSLocale.format(availableBalance),
            collectedBalance: dollarUSLocale.format(collectedBalance),
            currentBalance: dollarUSLocale.format(currentBalance),
        };
    }

    async getAccountTransactions(accountId: string, accountType: string) {
        return this.http.request(`AccountTransactions/${accountId}/${accountType}`, {
            method: 'GET',
        });
    }

    async getAccountDetailsByDate(
        accountId: string,
        accountType: string,
        startDate: string,
        endDate: string
    ) {
        return this.http.request(
            `accounttransactions/GetByDateRange/${accountId}/${accountType}?fromDate=${startDate}&toDate=${endDate}`,
            {
                method: 'GET',
            }
        );
    }

    async getAccountTransaction(accountId: string, accountType: string, transactionId: number) {
        const response = (await this.getAccountTransactions(
            accountId,
            accountType
        )) as AccountTransactionsDto;

        return response.transactions.find(t => t.id === transactionId);
    }
}
