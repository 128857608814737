import { ConfigurationService } from '@treasury/core/config';
import { NavigationService } from '@treasury/core/navigation';
import { TmApiError } from '@treasury/domain/shared';
import { NotificationService, TmBaseComponent } from '@treasury/presentation';
import '@treasury/presentation/components/tm-body';
import '@treasury/presentation/components/tm-button';
import '@treasury/presentation/components/tm-email-field';
import '@treasury/presentation/components/tm-footer';
import { ButtonConfig } from '@treasury/presentation/components/tm-footer.types';
import '@treasury/presentation/components/tm-text-field';
import { InjectProperty } from '@treasury/utils/dependency-injection';
import { css, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { LoginClient } from '../login-client';

export const tagName = 'forgot-password';
@customElement(tagName)
export default class ForgotPassword extends TmBaseComponent {
    @state()
    private companyId = '';

    @state()
    private loginId = '';

    @state()
    private email = '';

    @state()
    private loading = false;

    @InjectProperty()
    private declare readonly client: LoginClient;

    @InjectProperty()
    private declare readonly config: ConfigurationService;

    @InjectProperty()
    private declare notificationService: NotificationService;

    @InjectProperty()
    private declare navigationService: NavigationService;

    async forgotPassword() {
        const { companyId, loginId, email } = this;
        const formData = {
            companyId,
            loginId,
            email,
            institution: this.config.institutionId,
            id: 0,
            useClientBiometricsAuthentication: false,
        };
        try {
            this.loading = true;
            await this.client.forgotPassword(formData);
            this.notificationService.renderSuccess({
                header: 'Email Sent!',
                content: html`
                    <p class="mb-2">
                        Please check your email for further information about your password change.
                    </p>
                    <p>The instructions provided in the email will be valid for two hours.</p>
                `,
            });
        } catch (err) {
            if (err instanceof TmApiError) {
                this.notificationService.renderError(err);
            } else {
                this.notificationService.renderError('Forgotten password request failed.');
                console.error(err);
            }
        } finally {
            this.loading = false;
        }
    }

    renderBlockingLoader() {
        if (!this.loading) return nothing;
        return html`<tm-blocking-loader></tm-blocking-loader>t`;
    }

    renderForgotPasswordForm() {
        return html`
            <tm-body id="content" .shadow=${false}>
                <p>
                    If you have forgotten your password, you can reset it by verifying the
                    information below. You must be an active user and the credentials entered below
                    must match your user profile exactly. If the information is entered correctly,
                    an email will be sent to the address on record.
                </p>
                <br />
                <strong>
                    You will have two hours from the time the email is sent to take the next steps.
                </strong>
                <tm-text-field
                    label="Company ID"
                    .value=${this.companyId}
                    @value-changed=${(e: CustomEvent) => {
                        this.companyId = e.detail.value;
                    }}
                    required
                    class="pb-1"
                    autocapitalize="off"
                >
                </tm-text-field>
                <tm-text-field
                    label="Login ID"
                    .value=${this.loginId}
                    @value-changed=${(e: CustomEvent) => {
                        this.loginId = e.detail.value;
                    }}
                    required
                    class="pb-1"
                    autocapitalize="off"
                >
                </tm-text-field>
                <tm-email-field
                    label="Email"
                    .value=${this.email}
                    @value-changed=${(e: CustomEvent) => {
                        this.email = e.detail.value;
                    }}
                    required
                    class="pb-1"
                    autocapitalize="off"
                >
                </tm-email-field>
            </tm-body>
            <tm-footer
                .buttonConfig=${[
                    {
                        text: 'Forgot Password',
                        onClick: () => {
                            this.forgotPassword();
                        },
                        disabled: !this.companyId || !this.loginId || !this.email || this.loading,
                    },
                    {
                        importance: 'tertiary',
                        text: 'Return to Login',
                        onClick: () => {
                            this.navigationService.navigate(`/login`);
                        },
                    },
                ] as ButtonConfig[]}
            >
            </tm-footer>
        `;
    }

    render() {
        return html`
            ${this.renderBlockingLoader()}
            <login-layout headerText="Forgot Password">
                <span slot="alert">${this.renderAlert()}</span>
                <span slot="phase">${this.renderForgotPasswordForm()}</span>
            </login-layout>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                .success-container {
                    background-color: #fff;
                    padding: 15px;
                }

                .success-text {
                    font-size: 24px;
                    color: #2caa55;
                    font-weight: 600;
                }

                .success-heading {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 52px;
                    background-color: #2caa55;
                    display: flex;
                    align-items: center;
                    padding-left: 15px;
                }

                #content {
                    padding-bottom: var(--sticky-header-height, 0);
                }

                tm-footer {
                    padding-top: 1em;
                }

                @media only screen and (min-width: 640px) {
                    #content {
                        padding-bottom: 0px;
                    }
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: ForgotPassword;
    }
}
