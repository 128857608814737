import { TmBaseComponent } from '@treasury/presentation';
import { css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import './components/partials/login-layout';

export const tagName = 'login-error';
@customElement(tagName)
export class LoginError extends TmBaseComponent {
    render() {
        return html`
            <login-layout headerText="Error" .stickyHeader=${false}>
                <span slot="phase">
                    <div class="pl-4">
                        <p>Something went wrong processing your request.</p>
                        <p>Please try again later.</p>
                    </div>
                </span>
            </login-layout>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: block;
                    overflow: hidden;
                    height: 100%;
                }
                .error omega-icon {
                    color: var(--body-text-alert-color);
                }
                .error {
                    border: 1px solid var(--body-text-alert-color);
                    border-left: 8px solid var(--body-text-alert-color);
                    border-radius: 4px;
                    padding: 7px 15px;
                    margin: 15px;
                    display: flex;
                    align-items: center;
                    color: var(--primary-text-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: LoginError;
    }
}
