export async function registerWorker(institutionId: string, version: string) {
    if (!('serviceWorker' in navigator)) {
        throw new Error('Service workers not supported.');
    }

    if (!institutionId || !version) {
        throw new Error('Could not initialize service worker. Missing data.');
    }

    try {
        const registration = await navigator.serviceWorker.register('../service-worker.js', {
            scope: `/${institutionId}`,
            type: 'module',
        });
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
        registration.active?.postMessage({
            version,
        });

        return registration;
    } catch (err) {
        console.error('ServiceWorker registration failed: ', err);
    }

    return undefined;
}
