import { WireDto } from '@treasury/domain/shared';
import { TmBaseComponent } from '@treasury/presentation';
import { actionIcon } from '@treasury/presentation/assets/icons';
import '@treasury/presentation/components/tm-bottom-sheet';
import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../components/jhd-icon';
import '../../wire/wire-container';

export const tagName = 'wire-detail-bottom-sheet';
@customElement(tagName)
export class WireDetailBottomSheet extends TmBaseComponent {
    @property({ type: Object })
    public wire: WireDto | undefined;

    @property({ type: Boolean })
    public bottomSheetOpen = false;

    renderWireDetails() {
        if (!this.wire) return nothing;
        return html`<wire-container .wireId=${this.wire.id} .hideHeader=${true}></wire-container>`;
    }

    render() {
        return html`<tm-bottom-sheet
            .open=${this.bottomSheetOpen}
            @close=${() => {
                this.bottomSheetOpen = false;
                this.wire = undefined;
                this.dispatchEvent(new CustomEvent('close'));
            }}
        >
            <span slot="header-center">Wire Detail</span
            ><span slot="header-right" class="header-icon"
                ><jhd-icon class="action-icon" .icon=${actionIcon}></jhd-icon
            ></span>
            ${this.renderWireDetails()}</tm-bottom-sheet
        >`;
    }

    static get styles() {
        return [
            css`
                .button-bar {
                    bottom: 0;
                }
                .action-icon {
                    --icon-fill-color: var(--navigation-bar-button-color);
                }
            `,
        ];
    }
}

declare global {
    interface HTMLElementTagNameMap {
        [tagName]: WireDetailBottomSheet;
    }
}
