import {
    AuthenticationClient,
    SsoViaUisResponseDto,
    UisLoginUrlResponseDto,
} from '@treasury/api/channel';
import { AppType, ConfigurationService } from '@treasury/core/config';
import { LocalStorageService, ObservationSource, exists } from '@treasury/utils';
import { DiContainer, Injectable } from '@treasury/utils/dependency-injection';
import { AccountService } from '../../channel/services/account/account.service';
import { Feature } from '../feature-flags';
import { AuthPayload } from './authentication.service.types';

type UisStorage = UisLoginUrlResponseDto & { institutionId: string };

/**
 * Future home of all things related to authenticating the current user.
 */
@Injectable()
export class AuthenticationService {
    constructor(
        private readonly authenticationClient: AuthenticationClient,
        private readonly channelAccountService: AccountService,
        private readonly config: ConfigurationService,
        private readonly localStorageService: LocalStorageService
    ) {
        this.authenticated$.subscribe(() => {
            this._authenticated = true;
        });
    }

    public readonly uisMetadataPromise =
        this.config.app !== AppType.BackOffice
            ? this.getUisMetadata()
            : Promise.resolve<void>(undefined);

    private _authenticated = false;

    private authStream = new ObservationSource<AuthPayload>();

    public authenticated$ = this.authStream.toObservable();

    public get authenticated() {
        return this._authenticated;
    }

    public async authenticate() {
        if (this._authenticated) {
            return Promise.resolve();
        }

        const { isAdmin } = await this.getCurrentUser();
        this._authenticated = true;
        this.authStream.emit({
            isAdmin,
        });

        return this.authenticated$.toPromise(true);
    }

    public invalidate() {
        this._authenticated = false;
    }

    public onAuthenticated(...args: Parameters<(typeof this.authenticated$)['subscribe']>) {
        return this.authenticated$.subscribe(...args);
    }

    static async getInstance() {
        const container = await DiContainer.getInstance();
        return container.get(AuthenticationService);
    }

    private async getUisMetadata() {
        const uisStorageValue: UisStorage | boolean | undefined = this.localStorageService.get(
            Feature.UisEnabled
        );
        const usesDeprecatedStorageType = typeof uisStorageValue === 'boolean';

        // if the institutionId has changed, we need to fetch the metadata again
        const institutionChanged =
            (uisStorageValue as UisStorage)?.institutionId !== this.config.institutionId;

        if (exists(uisStorageValue) && !usesDeprecatedStorageType && !institutionChanged) {
            return uisStorageValue;
        }

        // metadata is not in storage or is invalid, so we need to fetch it
        const { institutionId, uisRedirectUrl, app } = this.config;
        let uisMetadata: UisStorage;

        // FAILSAFE: If the call to check if UIS is not available, fall back to legacy auth
        try {
            const respData = (
                await this.authenticationClient.authenticationUisLoginUrl({
                    fiId: institutionId,
                    isMobile: app === AppType.Pwa,
                    returnUrl: uisRedirectUrl,
                })
            ).data;
            uisMetadata = { ...respData, institutionId: this.config.institutionId };
        } catch (e) {
            console.error(`Error fetching UIS metadata for: ${this.config.institutionId}.`, e);
            uisMetadata = {
                isUisEnabled: false,
                institutionId: this.config.institutionId,
            };
        }
        this.localStorageService.set(Feature.UisEnabled, uisMetadata);
        return uisMetadata;
    }

    public async authenticateSelectedCompanyUser(userCompanyUniqueId: string) {
        const resp = await this.authenticationClient.authenticationCompanyLogin({
            userCompanyUniqueId,
        });

        return resp.data;
    }

    public async authenticationSsoViaUis(authorizationCode: string, state: string) {
        const { institutionId, uisRedirectUrl } = this.config;
        const resp = await this.authenticationClient.authenticationSsoViaUis({
            fiId: institutionId,
            authorizationCode,
            state,
            isEnrollment: false,
            isMobile: false,
            returnUrl: uisRedirectUrl,
        });
        return resp.data as SsoViaUisResponseDto;
    }

    private async getCurrentUser() {
        if (this.config.app === AppType.BackOffice) {
            return {
                isAdmin: true,
            };
        }

        const { isAdmin } = await this.channelAccountService.getCurrentUser();

        return {
            isAdmin,
        };
    }
}
